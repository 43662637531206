import React from 'react';
import './Register.css';
import OtpInput from 'react-otp-input';
import { useTranslation } from 'react-i18next';
import { userServices } from '../../services/userServices';
import { SpinnerLoading } from '../LoadingTest/SpinnerLoading';
import { ToastContainer } from 'react-toastify';

interface StepperProps {
  setPage: any;
  otp: any;
  setOtp: any;
  inputRegister: any;
  handleTwoFactor: any;
  setsecondButton: any;
  buttonLoader: any;

}

const LocationInfo = (props: StepperProps) => {
  const {
    setPage,
    otp,
    setOtp,
    inputRegister,
    handleTwoFactor,
    setsecondButton,
    buttonLoader,

  } = props;
  const { t } = useTranslation();
  
  
  // MISSING SNACKBAR
  const resendCode = () => {
    userServices
      .resendVerification()
      .then((response) => {
        // console.log(response);
      })
      .catch((error: any) => {
        // console.log(error);
      });
  };
  return (
    <div className='registerContainer'>
      <ToastContainer autoClose={7000} />
      <div className='flex items-center justify-center py-[64px] px-4 sm:px-6 lg:px-8 font-avenir55'>
        <div className='max-w-3xl w-full space-y-8 bg-white'>
          <div className='pb-4 pt-4 sm:pt-[41px] pl-4 sm:pl-[30px]'>
            <p className='text-[25px]'>{t('register.createAcc')}</p>
          </div>
          <div
            id='form1'
            className='grid grid-cols-1 sm:grid-cols-2 gap-8 sm:gap-10 px-10 sm:px-16'
          >
            <div className='space-y-8 sm:space-y-5 text-gray-400'>
              <div className='block'>
                <label className='pr-2 '>
                  {t('register.locationInfo.address')}
                </label>
                <input
                  type='text'
                  disabled
                  name='street'
                  value={inputRegister?.address?.street}
                  className=' py-2 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-black peer required '
                />
              </div>
              <div className='block'>
                <label className='pr-2'>
                  {t('register.locationInfo.street')}
                </label>
                <input
                  type='text'
                  disabled
                  name='city'
                  value={inputRegister?.address?.city}
                  className=' py-2 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer required'
                />
              </div>
              <div className='block'>
                <label className='pr-2 '>Zip / {t('register.city')}</label>
                <input
                  type='text'
                  disabled
                  name='zip'
                  value={inputRegister?.address?.zip}
                  className=' py-2 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-black peer required '
                />
              </div>
            </div>
            <div className='space-y-8 sm:space-y-10 '>
              <div className='space-y-8 sm:space-y-10 text-gray-400'>
                <div className='block'>
                  <label className='pr-2 '>{t('register.phone')}</label>
                  <input
                    type='tel'
                    disabled
                    name='phone'
                    value={inputRegister.phone}
                    className='py-2 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-black peer required '
                  />
                </div>
              </div>
              <div className='space-y-5 relative'>
                <p className='text-center text-xs'>
                  {t('register.confirmCode')}
                </p>
                <div className='flex justify-center '>
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    isInputNum={true}
                    numInputs={6}
                    inputStyle={{
                      width: '2.4rem',
                      height: '2.4rem',
                      margin: '0 0.2rem 0',
                      fontSize: '1.8rem',
                      borderRadius: 4,
                      border: '1px solid rgba(0,0,0,0.3)',
                      flex: 'justify-evenly',
                    }}
                    shouldAutoFocus
                    className=''
                  />
                </div>
                <p className='text-center text-xs '>
                  <sup>*</sup>
                  {t('register.enterCode')}
                </p>
                <p className='flex justify-center absolute left-0 right-0'>
                  <button
                    className='text-darkGray text-xs font-light flex space-x-3'
                    onClick={resendCode}
                  >
                    <span>{t('register.resendVerification')}</span>
                    <svg
                      viewBox='0 0 512 512'
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-3 w-3 self-center '
                      fill='#353535'
                    >
                      <path d='M480 256c0 123.4-100.5 223.9-223.9 223.9c-48.86 0-95.19-15.58-134.2-44.86c-14.14-10.59-17-30.66-6.391-44.81c10.61-14.09 30.69-16.97 44.8-6.375c27.84 20.91 61 31.94 95.89 31.94C344.3 415.8 416 344.1 416 256s-71.67-159.8-159.8-159.8C205.9 96.22 158.6 120.3 128.6 160H192c17.67 0 32 14.31 32 32S209.7 224 192 224H48c-17.67 0-32-14.31-32-32V48c0-17.69 14.33-32 32-32s32 14.31 32 32v70.23C122.1 64.58 186.1 32.11 256.1 32.11C379.5 32.11 480 132.6 480 256z' />
                    </svg>
                  </button>
                </p>
              </div>
            </div>
          </div>
          <div className='px-4 sm:px-[30px] pb-4 sm:pb-[34px] pt-4 sm:pt-[50px] flex justify-between'>
            <button
              disabled={true}
              onClick={() => {
                {
                  setPage((currPage: number) => currPage - 1);
                }
                {
                  setsecondButton(false);
                }
              }}
              className='bg-white hover:bg-gray-200 px-2 py-2 mt-3 rounded-md border-2 border-black transition-all ease-in-out duration-150'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-6 w-6 '
                fill='none'
                viewBox='0 0 24 24'
                stroke='black'
              >
                <path d='M10 19l-7-7m0 0l7-7m-7 7h18' />
              </svg>
            </button>
            <div
              id='dots'
              className='w-full h-full mt-8 text-base flex justify-center space-x-1 sm:space-x-16'
            >
              <div className='bg-gray-900 border border-black text-center  w-[15%]'></div>
              <div className='bg-white border border-black text-center   w-[15%]  '></div>
              <div className='bg-white border border-black text-center  w-[15%] '></div>
            </div>
            <button
              onClick={handleTwoFactor}
              className='bg-gray-900 hover:bg-gray-700 hover:scale-105 px-2 py-2 mt-3 rounded-md border-2 border-black transition-all ease-in-out duration-150'
            >{buttonLoader ? <SpinnerLoading /> :
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='white'
                >
                  <path d='M14 5l7 7m0 0l-7 7m7-7H3' />
                </svg>
              }
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export { LocationInfo };
