import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { userServices } from '../../services';
import { Link, useNavigate } from 'react-router-dom';
import { SpinnerLoading } from '../LoadingTest/SpinnerLoading';
import { toast, ToastContainer } from 'react-toastify';

const Other = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [buttonLoader,setButtonLoader] = useState(false);
  const [password, setPassword] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const handlePasswordShow = () => {
    setPasswordShow(!passwordShow);
  };

  const handleLogout = () => {
    userServices.userLogout()
      .then(() => {
        localStorage.clear();
        navigate('/login');
      }).catch((error: any) => {
        console.error('Logout error', error);
      });
  };

  const handleTerminate = () => {
    setButtonLoader(true);
    setIsDisabled(true);
    userServices.terminateAcc(password)
      .then(() => {
        setButtonLoader(false);
        setTimeout(() => {
          handleLogout();
        }, 3000);
      })
      .catch((error:any) => {
        setButtonLoader(false);
        setIsDisabled(false);
        if (error.response.status == 401) {
          toast.error(t('error.freshLogin'), {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
        else {
          toast.error(t(`${error.response.data.msg}`), {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      });
  };

  const { t } = useTranslation();
  return (
    <div className='flex justify-center sm:justify-start'>
      <div className='pt-[52px] text-darkGray'>
        <div className='flex space-x-[12px]'>
          <a href='/terms/conditions' target='_blank' className='flex text-[16px] items-center'>
            <span className='text-[16px] items-center mr-2'>{t('dashboard.terms')}</span>
            <button className='bg-darkGray p-1 rounded-lg self-center'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-6 w-6'
                fill='none'
                viewBox='0 0 24 24'
                stroke='white'
                strokeWidth='2'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M17 8l4 4m0 0l-4 4m4-4H3'
                />
              </svg>
            </button>
          </a>
        </div>
        <div className='pt-[24px]'>
          <button className='bg-auctionicRed rounded-lg text-[16px] text-white px-5 py-2' onClick={handleLogout}
          >
            {t('dashboard.logOut')}
          </button>
        </div>
        <div className='pt-[83px]'>
          <button className='text-auctionicRed text-[14px]' onClick={() => setShowModal(true)}>
            {t('dashboard.terminate')}
          </button>
          {showModal ? (
            <>
              <div className="opacity-70 fixed inset-0 z-40 bg-darkGray" ></div>
              <ToastContainer autoClose={2000} />
              <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none px-3' onClick={() => setShowModal(false)}>
                <div className='relative w-auto my-6 mx-auto max-w-xl'>
                  <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none' onClick={(e) => e.stopPropagation()}>
                    <div className='flex items-start justify-between px-5 pt-3 pb-1'>
                      <p className='text-lg font-bold text-darkGray'>
                        {t('otherModal.areYouSure')}
                      </p>
                    </div>
                    <div className='flex items-start justify-between px-5 pt-3 pb-1'>
                      <p className='text-md text-darkGray'>
                        {t('otherModal.enterPass')}
                      </p>
                    </div>
                    <div className='flex items-start justify-between px-5 pt-3 pb-1'>
                      <input
                        type={passwordShow ? 'text' : 'password'}
                        placeholder={t('otherModal.password')}
                        name='password'
                        onChange={(e) => setPassword(e.target.value)}
                        className='py-2 pl-4 pr-8 w-full text-base sm:text-sm text-gray-900 bg-transparent border-b-2 outline-black'
                      />
                      {passwordShow ? (
                        <button onClick={handlePasswordShow}>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            strokeWidth='1.5'
                            stroke='gray'
                            className='w-5 h-5 absolute mt-2 -ml-7 '
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z'
                            />
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
                            />
                          </svg>
                        </button>
                      ) : (
                        <button onClick={handlePasswordShow}>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            strokeWidth='1.5'
                            stroke='gray'
                            className='w-5 h-5 absolute mt-2 -ml-7 '
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88'
                            />
                          </svg>
                        </button>
                      )}
                    </div>
                    <div className='flex items-center justify-center px-5 pb-3 pt-3'>
                      <button
                        className='background-transparent font-semibold text-[16px] text-darkGray px-5 py-2 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                        onClick={() => setShowModal(false)}
                      >
                        {t('otherModal.closeButton')}
                      </button>
                      <button
                        className="bg-auctionicRed uppercase text-[16px] text-white px-5 py-2 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        onClick={handleTerminate}
                        disabled={isDisabled}
                      >
                        {buttonLoader ? (
                          <div className='flex justify-center w-full'>
                            <SpinnerLoading />
                          </div>
                        ) : (
                          t('otherModal.terminateButton')
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export { Other };
