import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEn from './locales/en/translationEn.json';
import translationDe from './locales/de/translationDe.json';
import translationFr from './locales/fr/translationFr.json';
import translationIt from './locales/it/translationIt.json';

const resources = {
  en: {
    translation: translationEn,
  },
  de: {
    translation: translationDe,
  },
  fr: {
    translation: translationFr,
  },
  it: {
    translation: translationIt,
  },
};

const locale = localStorage.getItem('locale') || 'de';

i18n.use(initReactI18next).init({
  resources,
  lng: locale,

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
