
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper';
import Stepper from './Stepper';
import { propertyServices } from '../../services/propertyServices';
import { utils } from '../../helpers/utils';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PropertyInput } from '../../config';

interface Props {
  propertyInput: any;
  paymentTypeData: any;
  images: any;
  handleClickPrev: any;
  steps: any;
  t: any;
  documents: any;
  prevImages: any;
  prevDocuments:any;
  handleClickNext:any;
  video: any;
  propertyChar: Array<any>;
  prevVideo:any;
  invoice: any;
  setInvoice: any;
  setLoader1: any;
  setLoader2: any;
  setLoader3: any;
  setLoader4: any;
  setCreatingProperty: any;
  setUploadingImages: any;
  setUploadingDocuments: any;
  setUploadingVideos: any;
  setError:any;
  setAdvertisementInfo:any;
}

const Preview = (props: Props) => {
  const {
    handleClickPrev,
    steps,
    t,
    propertyInput,
    paymentTypeData,
    images,
    documents,
    prevImages,
    prevDocuments,
    handleClickNext,
    video,
    propertyChar,
    prevVideo,
    setInvoice,
    setLoader1,
    setLoader2,
    setLoader3,
    setLoader4,
    setCreatingProperty,
    setUploadingImages,
    setUploadingDocuments,
    setUploadingVideos,
    setError,
    setAdvertisementInfo,
  } = props;

  const address = utils.formatAddress(propertyInput.address);
  const [step] = useState(4);
  const navigate = useNavigate();
  const handleSubmit = () => {
    handleClickNext();
    const errorList: string[] = [];
    setCreatingProperty(true);
    setLoader1(true);
    const fixBody: PropertyInput = {
      ...propertyInput,
      address: utils.formatAddress(propertyInput.address) as any,
      available_to_enter: utils.localDateToUTC(propertyInput.available_to_enter),
      available_to_visit: utils.localDateToUTC(propertyInput.available_to_visit)
    } as PropertyInput;
    const fixBodyPaymentType = {
      ...paymentTypeData,
      start_date:utils.localDateToUTC(paymentTypeData.start_date)
    };
    propertyServices
      .createProperty(fixBody, fixBodyPaymentType)
      .then((res: any) => {
        setAdvertisementInfo(res.data);
        setLoader1(false);
        if (images.length != 0) {
          setUploadingImages(true);
          setLoader2(true);
          propertyServices
            .addImages(res.data.property_id, images)
            .then(() => {
              setLoader2(false);
            })
            .catch(() => {
              setLoader2(false);
              errorList.push('upload_images');
              setError(errorList);
            }).finally(()=>{
              setLoader2(false);
            });
        }
        if (documents.length != 0) {
          setUploadingDocuments(true);
          setLoader3(true);
          propertyServices
            .addDocuments(res.data.property_id, documents)
            .then(() => {
              setLoader3(false);
            })
            .catch(() => {
              setLoader3(false);
              errorList.push('upload_documents');
              setError(errorList);
            }).finally(()=>{
              setLoader3(false);
            });
        }
        if (video.length != 0) {
          setUploadingVideos(true);
          setLoader4(true);
          propertyServices
            .addVideo(res.data.property_id, video)
            .then(() => {
              setLoader4(false);
            })
            .catch(() => {
              setLoader4(false);
              errorList.push('upload_video');
              setError(errorList);
            }).finally(()=>{
              setLoader4(false);
            });
        }
        // toast.success(t('errors.createPropertyS'), {
        //   position: toast.POSITION.BOTTOM_LEFT,
        // });
        handleClickNext();
        setInvoice(res.data.payment_id);
      })
      .catch(() => {
        // toast.error(t('errors.createPropertyE'), {
        //   position: toast.POSITION.BOTTOM_LEFT,
        // });
        setLoader1(false);
        errorList.push('creating_property');
        setError(errorList);
      }).finally(()=>{
        setLoader1(false);
      });
  };

  const toArr = (name: any) => {
    const newArr = name.split('&');
    return newArr;
  };
  
  return (
    <div className='bg-white sm:px-10 md:px-24 px-2 sm:py-12 py-2 font-press-start'>
      <ToastContainer autoClose={4000} />
      <div className='flex justify-between sm:pb-10 sm:pt-20 pb-5 pt-10'>
        <div className=''>
          <p className='text-[32px]'>{t('listingItem.createListing')}</p>
        </div>
        <div className='pt-2'>
          <button
            onClick={() => navigate(-1)}
            className='bg-darkGray w-[100px] h-[34px] text-center text-white rounded-md'
          >
            {t('listingItem.buttonCancel')}
          </button>
        </div>
      </div>
      <div className='border border-darkGray w-full ' />
      <div className='sm:grid sm:grid-cols-1 md:grid md:grid-cols-1 lg:grid lg:grid-cols-3 '>
        <div className='col-span-2 '>
          <p className='text-[20px] text-darkGray py-8 font-medium'>
            {' '}
            {t('listingItem.preview')}
          </p>
          <div className='bg-[#F7F7F7] sm:p-4 p-2 rounded-lg'>
            <Swiper
              slidesPerView={1}
              modules={[Autoplay, Navigation]}
              autoplay={true}
              navigation={true}
              className='sm:max-h-[550px] rounded-lg'
            >
              {prevImages &&
                prevImages.map((image: any, index: number) => {
                  return (
                    <SwiperSlide key={index}>
                      <img src={image} alt='' className='w-full h-full' />
                    </SwiperSlide>
                  );
                })}
              {prevVideo && prevVideo.map((videos:any, indexi:number)=>{
                return (
                  <div key={indexi} className='' >
                    <SwiperSlide>
                      <video className='rounded-xl' controls >
                        <source src={videos} />
                      </video>
                    </SwiperSlide>
                  </div>
                );
              })}
            </Swiper>
            <div className='sm:grid sm:grid-cols- md:grid md:grid-cols-1 lg:grid lg:grid-cols-3 '>
              <div className='flex justify-between sm:col-span-1 md:col-span-1 lg:col-span-2 '>
                <div className='py-4'>
                  <p className='text-[24px] font-bold'>
                    {propertyInput.size_rooms != '0' && propertyInput.size_rooms} {propertyInput.size_rooms != '0' && t('listingItem.fifthForm.rooms')}
                    <>
                      {propertyInput.property_types?.includes(8) || propertyInput.property_types?.includes(11) ? (
                        <>
                          {propertyInput.size_rooms != '0' && propertyInput.plot_area != 0 && <>, </>}
                          {propertyInput.plot_area != 0 && <>{propertyInput.plot_area}</>}
                          {propertyInput.plot_area != 0 && <> m</>}
                          {propertyInput.plot_area != 0 && <><sup className='text-lg'>2</sup></>}
                        </>
                      ):(
                        <>
                          {(propertyInput.size_rooms != '0' && propertyInput.living_space != 0) ? (<>, </>):''}
                          {propertyInput.living_space != 0 && <>{propertyInput.living_space}</>}
                          {propertyInput.living_space != 0 && <> m</>}
                          {propertyInput.living_space != 0 && <><sup className='text-lg'>2</sup></>}
                        </>
                      )}
                    </>
                  </p>
                  <p className='text-[18px]'>{propertyInput.title}</p>
                  <p className='sm:pt-4 text-[24px] border-b-2 border-red-600 mr-24 font-bold flex space-x-1'>
                    <p>CHF</p>
                    {paymentTypeData.type === 'auction'? <p>{utils.numberWithComma(paymentTypeData.starting_price)}</p> : paymentTypeData.type === 'sale'? <p>{utils.numberWithComma(paymentTypeData.price)}</p>: paymentTypeData.type === 'rent'? <p>{utils.numberWithComma(paymentTypeData.price)}</p>: null}.–
                  </p>
                </div>
              </div>
            </div>
            <div className='border border-darkGray ' />
            <div className='py-7 space-y-5'>
              <p className='text-[16px] font-bold'>{t('listingItem.mainInformation')}</p>
              <div className=' text-[14px] space-y-1'>
                <div className='grid grid-cols-2 sm:grid-cols-4'>
                  <p> {t('listingItem.address')}</p>
                  <p className='font-bold sm:col-span-2'>{address.address}</p>
                </div>
                <div className='grid grid-cols-2 sm:grid-cols-4'>
                  <p>{propertyInput.floor && <span>{t('listingItem.floor')}</span>}</p>
                  <p className='font-bold'>
                    {propertyInput.floor && t(`${propertyInput.floor}`)}
                  </p>
                </div>
                <div className='grid grid-cols-2 sm:grid-cols-4'>
                  <p> {t('listingItem.available')}</p>
                  {propertyInput?.available_to_enter == null || propertyInput?.available_to_enter == '' ?
                    (
                      <p className='font-bold'>
                        {t('propertyDetails.ask')}
                      </p>
                    ):(
                      <>
                        {paymentTypeData?.start_date == propertyInput?.available_to_enter ? (
                          <p className='font-bold'>
                            {t('propertyDetails.immediately')}
                          </p>
                        ):(
                          <p className='font-bold'>
                            {utils.formatDate(
                              propertyInput?.available_to_enter, t
                            )}
                          </p>
                        )}
                      </>)
                  }
                </div>
              </div>
            </div>
            <div className='border border-darkGray ' />
            <div className='py-7 space-y-5'>
              <p className='text-[16px] font-bold'>{t('listingItem.price')}</p>
              <div className='text-[14px]'>
                <div className='space-y-1 grid grid-cols-2 sm:grid-cols-4'>
                  <p>{paymentTypeData.type == 'rent' ? t('listingItem.rentPrice') : t('listingItem.selling')}</p>
                  <p className='font-bold flex space-x-1'>
                    CHFF&nbsp;
                    {paymentTypeData.type === 'auction'? utils.numberWithComma(paymentTypeData.starting_price) : paymentTypeData.type === 'sale'? utils.numberWithComma(paymentTypeData.price): paymentTypeData.type === 'rent'? utils.numberWithComma(paymentTypeData.price): null}
                    .–
                  </p>
                  
                </div>
                {paymentTypeData.type == 'rent' &&
                  <>
                    <div className='grid grid-cols-2 sm:grid-cols-4'>
                      <p> {t('listingItem.additional')} </p>
                      <p className='font-bold'>CHF {paymentTypeData.additional ? utils.numberWithComma(paymentTypeData.additional) : 0}.–</p>
                    </div>
                    <div className='grid grid-cols-2 sm:grid-cols-4'>
                      <p> {t('listingItem.rentAdditional')} </p>
                      <p className='font-bold'>CHF {paymentTypeData.additional ? utils.numberWithComma(paymentTypeData.price + paymentTypeData.additional):utils.numberWithComma(paymentTypeData.price)}.–</p>
                    </div>
                  </>
                }
                <div className='grid grid-cols-2 sm:grid-cols-4'>
                  {paymentTypeData.type === 'auction' && paymentTypeData.buy_now_price ? (
                    <p> {t('listingItem.buyNowPrice')} </p>
                  ) : null}
                  {paymentTypeData.type === 'auction' && paymentTypeData.buy_now_price ? (
                    <p className='font-bold'>CHF {utils.numberWithComma(paymentTypeData.buy_now_price)}.–</p>
                  ) : null}
                </div>
                <div className='grid grid-cols-2 sm:grid-cols-4'>
                  {paymentTypeData.type === 'auction' ? (
                    <p>{t('listingItem.increasingPrice')} </p>
                  ) : null}
                  {paymentTypeData.type === 'auction' ? (
                    <p className='font-bold'>CHF {utils.numberWithComma(paymentTypeData.minimum_increasing_amount)}.–</p>
                  ) : null}
                </div>
              </div>
            </div>
            {(propertyInput.description != undefined && propertyInput.description != '')?(
              <>
                <div className='border border-darkGray ' />
                <div className='py-7 space-y-5'>
                  <p className='text-[16px] font-bold'> {t('listingItem.description')}</p>
                  <div className='flex text-[14px]'>
                    <p>{propertyInput.description}</p>
                  </div>
                </div>
              </>):''}
            <div>
              {prevDocuments.length != 0 ? (
                <>
                  <div className='border border-darkGray mb-5' />
                  <div className='space-y-5'>
                    <p className='text-[16px] font-bold'> {t('listingItem.documents')}</p>
                  </div>
                </>
              ):''}
              {prevDocuments &&
                prevDocuments.map((documents:any, index:number)=> {
                  return (
                    <ul key={index} className='font-normal italic mt-5 self-center mb-3'>
                      <li className='flex space-x-2 w-[204px] py-1 rounded-md hover:hover:text-blue-400 transition-all ease-in-out duration-200'>
                        <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth='1.5' stroke='currentColor' className='w-6 h-6'>
                          <path strokeLinecap='round' strokeLinejoin='round' d='M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z' />
                        </svg>
                        <a href={documents} target='_blank' rel='noreferrer' className='border-b-2 text-sm self-center'>Filename {index+1}</a>
                      </li>
                    </ul>
                  );
                })}
            </div>
            <>
              <div className='border border-darkGray ' />
              <div className='py-7 space-y-5'>
                <p className='text-[16px] font-bold'> {t('listingItem.dimensions')}</p>
                <div className='grid grid-cols-2 sm:grid-cols-4 text-[14px]'>
                  <div className='space-y-1'>
                    <p> {propertyInput.floor ? t('listingItem.floor'):''}</p>
                    <p> {propertyInput.year_built ? t('listingItem.yearBuilt'):''}</p>
                    <p> {propertyInput.plot_area != 0 ? t('listingItem.plotArea'):''}</p>
                    <p> {propertyInput.living_space != 0 ? t('listingItem.livingSpace'):''}</p>
                    <p> {propertyInput.size_rooms != 0 ? t('listingItem.numberOfRooms'):''}</p>
                    <p> {propertyInput.living_room != 0 ? t('listingItem.livingRoom'):''}</p>
                    <p> {propertyInput.bathroom != 0 ? t('listingItem.bathroom'):''}</p>
                    <p> {propertyInput.shower != 0 ? t('listingItem.shower'):''}</p>
                    <p> {propertyInput.bedroom != 0 ? t('listingItem.bedrooms'):''}</p>
                    <p> {propertyInput.kitchen != 0 ? t('listingItem.kitchen'):''}</p>
                    <p> {propertyInput.garage != 0 ? t('listingItem.garage'):''}</p>
                  </div>
                  <div className='font-bold space-y-1'>
                    <p>{propertyInput.floor != null ? t(propertyInput.floor) : ''}</p>
                    <p>{propertyInput.year_built != null ? propertyInput.year_built : ''}</p>
                    <p>{propertyInput.plot_area != 0 ? <>{propertyInput.plot_area} (m²)</>  : '' }</p>
                    <p>{propertyInput.living_space != 0 ? <>{propertyInput.living_space} (m²)</> :''}</p>
                    <p>{propertyInput.size_rooms != 0 ? propertyInput.size_rooms : ''}</p>
                    <p>{propertyInput.living_room != 0 ? propertyInput.living_room : ''}</p>
                    <p>{propertyInput.bathroom != 0 ? propertyInput.bathroom : ''}</p>
                    <p>{propertyInput.shower != 0 ? propertyInput.shower : ''}</p>
                    <p>{propertyInput.bedroom != 0 ? propertyInput.bedroom : ''}</p>
                    <p>{propertyInput.kitchen != 0 ? propertyInput.kitchen : ''}</p>
                    <p>{propertyInput.garage != 0 ? propertyInput.garage : ''}</p>
                  </div>
                </div>
              </div>
            </>
            {propertyInput.property_chars.length != 0 ? (
              <>
                <div className='border border-darkGray' />
                <div className='py-7 space-y-5 '>
                  <p className='text-[16px] font-bold'>{t('listingItem.characteristics')}</p>
                  <div className='block text-[14px]'>
                    {/* <div className=''>
                  <p> {t('listingItem.exterior')}</p>
                </div> */}
                    <div>
                      <div className='block'>
                        {propertyChar.filter((char: any) => propertyInput.property_chars.includes(char.id)).map((chars: any, index)=>{
                          return (
                            <div className='grid grid-cols-2 sm:grid-cols-4 text-[14px] font-bold text-gray-500' key={index}>
                              <div>
                                {localStorage.getItem('locale') === 'de' ? (
                                  <>{toArr(chars.char)[0]}</>
                                ) : localStorage.getItem('locale') === 'en' ? (
                                  <>{toArr(chars.char)[1]}</>
                                ) : localStorage.getItem('locale') === 'fr' ? (
                                  <>{toArr(chars.char)[2]}</>
                                ) : localStorage.getItem('locale') === 'fr' ? (
                                  <>{toArr(chars.char)[3]}</>
                                ) :
                                  <>{toArr(chars.char)[0]}</>}
                              </div>
                              <div className=''>
                                <svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5' viewBox='0 0 448 512' fill='red'>
                                  <path d='M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z' />
                                </svg>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </>):''}
            {(propertyInput?.available_to_visit != null) ?
              (propertyInput?.available_to_visit != '') ?
                <>
                  <div className='border border-darkGray ' />
                  <div className='py-7 space-y-3'>
                    <p className='text-[16px] font-bold'>{t('propertyDetails.availability')}</p>
                    <div className=''>
                      <div className='text-[14px] space-y-2'>
                        {(paymentTypeData.type === 'sale' || paymentTypeData.type === 'rent') && propertyInput.available_to_visit != '' ? (
                          propertyInput.available_to_visit != null ?
                            <div className='grid grid-cols-2 sm:grid-cols-4'>
                              <div>
                                <p className='font-bold'>{t('propertyDetails.availableToVisit')}</p>
                                <p className='mx-4'>
                                  <p>{t('listingItem.availableDate')}</p>
                                  <p>{t('propertyDetails.availableHours')}</p>
                                </p>
                              </div>
                              <div>
                                <p><br/></p>
                                <p>{utils.formatDate(propertyInput.available_to_visit, t)}</p>
                                <p>{utils.formatTimewithoutSeconds(propertyInput.available_to_visit)}</p>
                              </div>
                            </div>
                            : ''
                        ) : null}
                      </div>
                    </div>
                  </div>
                </>
                : ''
              : ''
            }
          </div>
          <div className='mt-4 ml-4'>{t('listingItem.acceptTerms1')}<a className='cursor-pointer text-auctionicRed border-b-2 border-gray-900'  href='/terms/conditions' target='_blank'>{t('listingItem.acceptTerms2')}</a>{t('listingItem.acceptTerms3')}<a className='cursor-pointer text-auctionicRed border-b-2 border-gray-900' href='/privacy/policy' target='_blank'>{t('listingItem.acceptTerms4')}</a>{t('listingItem.acceptTerms5')}</div>
        </div>
        
        <div className='col-span-0 sm:col-span-1 hidden sm:block'>
          <Stepper steps={steps} activeStep={step} />
        </div>
      </div>
      <div className='pt-12 flex justify-between'>
        <button
          className='hover:scale-125 hover:ease-in duration-300 '
          onClick={handleClickPrev}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-6 w-6'
            fill='none'
            viewBox='0 0 24 24'
            stroke='black'
          >
            <path d='M10 19l-7-7m0 0l7-7m-7 7h18' />
          </svg>
        </button>
        <button
          onClick={handleSubmit}
          className='bg-darkGray px-5 py-2 text-center text-white rounded-md hover:scale-105 hover:ease-in duration-300 '
        >
          {t('listingItem.publishButton')}
        </button>
      </div>
      <div className='col-span-0 sm:col-span-1 block sm:hidden'>
        <Stepper steps={steps} activeStep={step} />
      </div>
    </div>
  );
};

export { Preview };

