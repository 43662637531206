import React, { useEffect, useState } from 'react';
import { PropertySearch } from './PropertySearch';
import { useTranslation } from 'react-i18next';
import 'swiper/css';
import 'swiper/css/navigation';
import { useLocation, useNavigate } from 'react-router-dom';
import './PropertyListing.css';
import { propertyServices } from '../../services/propertyServices';
import { Property } from '../../config';
import { PropertyCard } from '../PropertyDetails/PropertyCard';
import { ListingsLoading } from '../LoadingTest';
import { PropertyNotFound } from './PropertyNotFound';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Login } from '../Login';
const PropertyListing = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isLogged = localStorage.getItem('access_token');
  const [properties, setProperties] = useState<Array<Property>>();
  const [favoriteProperties, setFavoriteProperties] = useState([]);
  const { state } = useLocation() as any;
  const [loading, setLoading] = useState(true);
  const [fav, setFav] = useState([]);


  useEffect(() => {
    setLoading(true);
    if (localStorage.getItem('user_id')) {
      propertyServices.getFavouriteProperties()
        .then((response) => {
          setFavoriteProperties(response.data.data);
          setLoading(false);
        })
        .catch((error) => {
          // console.log('Failed', error);
        })
        .finally(() => setLoading(false));
    }
    else {
      const fav1 = localStorage.getItem('favorite-not-synced');
      if (fav1 == null ) {
        setFav([]);
        localStorage.setItem('favorite-not-synced', JSON.stringify([]));
      }
      else {
        const parsedFav = JSON.parse(fav1);
        setFav(parsedFav);
      }
      setLoading(false);
    }

  },[]);

  if (state.filter == undefined) {
    switch (state.type) {
    case 'auction':
      useEffect(() => {
        if (localStorage.getItem('user_id')) {
          setLoading(true);
          propertyServices
            .getAuctionProperties()
            .then((response) => {
              setLoading(false);
              setProperties(response.data);
              return response;
            })
            .catch(() => {
              toast.error(t('errors.propertyListingE'), {
                position: 'bottom-left',
              });
              // console.log('Failed', error);
            })
            .finally(() => setLoading(false));
        } else {
          navigate('/login');
        }
      }, [state.type]);
      break;
    case 'rent':
      useEffect(() => {
        setLoading(true);
        propertyServices
          .getRentProperties()
          .then((response) => {
            setLoading(false);
            setProperties(response.data);
            return response;
          })
          .catch(() => {
            toast.error(t('errors.propertyListingE'), {
              position: 'bottom-left',
            });
            // console.log('Failed rent fetch', error);
          })
          .finally(() => setLoading(false));
      }, [state.type]);
      break;
    case 'sale':
      useEffect(() => {
        setLoading(true);
        propertyServices
          .getSaleProperties()
          .then((response) => {
            setLoading(false);
            setProperties(response.data);
            return response;
          })
          .catch(() => {
            toast.error(t('errors.propertyListingE'), {
              position: 'bottom-left',
            });
            // console.log('Failed sale fetch', error);
          })
          .finally(() => setLoading(false));
      }, [state.type]);
      break;
    default:
      break;
    }
  }
  else {
    switch (state.type) {
    case 'auction':
      useEffect(() => {
        if (localStorage.getItem('user_id')) {
          setLoading(true);
          propertyServices
            .filterAuctionProperties(state.filter)
            .then((response: any) => {
              setLoading(false);
              setProperties(response.data);
            })
            .catch((error: any) => {
              toast.error(t('errors.propertyListingE'), {
                position: 'bottom-left',
              });
              // console.error('Login error', error);
            })
            .finally(() => setLoading(false));
        } else {
          navigate('/login');
        }
      }, []);
      break;
    case 'rent':
      useEffect(() => {
        setLoading(true);
        propertyServices
          .filterRentProperties(state.filter)
          .then((response: any) => {
            setLoading(false);
            setProperties(response.data);
          })
          .catch((error: any) => {
            toast.error(t('errors.propertyListingE'), {
              position: 'bottom-left',
            });
            console.error('Login error', error);
          })
          .finally(() => setLoading(false));
      }, []);
      break;
    case 'sale':
      useEffect(() => {
        setLoading(true);
        propertyServices
          .filterSaleProperties(state.filter)
          .then((response: any) => {
            setLoading(false);
            setProperties(response.data);
          })
          .catch((error: any) => {
            toast.error(t('errors.propertyListingE'), {
              position: 'bottom-left',
            });
            // console.error('Login error', error);
          })
          .finally(() => setLoading(false));
      }, []);
      break;
    default:
      break;
    }
  }

  if (!isLogged && state.type == 'auction') {
    return <Login />;
  }

  const handleFavouriteProperty = (id: number) => {
    if (isLogged) {
      propertyServices
        .addPropertyToFavourites(id)
        .then((response) => {
          setFavoriteProperties(response.data.data);
          toast.success(t('errors.favoriteS'), {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        })
        .catch(() => {
          toast.error(t('errors.favoriteE'), {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        });
    }
    else {
      // propertyServices.addPropertyToFavourites(id).then((res:any)=>{
      //   localStorage.setItem('fav', JSON.stringify(fav));
      //   setFavoriteProperties(res.data.data);
      // }).catch((err)=>{
      //   console.log('not logged in error favourite property', err);
      // });
      const fav1 = localStorage.getItem('favorite-not-synced');
      // console.log(fav1);
      if (fav1 != null) {
        const parsedFav = JSON.parse(fav1);
        parsedFav.push({ id: id, type: state.type });
        setFav(parsedFav);
        // console.log('ID:',id);
        localStorage.setItem('favorite-not-synced', JSON.stringify(parsedFav));
      }
    }
  };

  const handleFavouriteDelete = (id: number) => {
    if (isLogged) {
      propertyServices
        .removePropertyToFavourites(id)
        .then((response) => {
          setFavoriteProperties(response.data.data);
          toast.success(t('errors.removeFavoriteS'), {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        })
        .catch((error) => {
          toast.error(t('errors.removeFavoriteE'), {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          // console.log('Failed', error);
        });
    }
    else {
      const fav1 = [...fav];
      if (fav1 != null) {
        // console.log(fav1,'fav1');
        const deletedPropIndex = fav1.findIndex((deletedProp:any) =>  {
          return deletedProp.id == id;
        });
        // console.log('iindexi idelete', deletedPropIndex);
        fav1.splice(deletedPropIndex, 1);
        setFav(fav1);
        // console.log('ID:',id);
        localStorage.setItem('favorite-not-synced', JSON.stringify(fav1));
      }
    }
  };

  const handleNavigation = (id: number) => {
    if (state.type === 'auction') {
      navigate(`/property/auction/details/${id}`);
    }
    else if (state.type === 'rent') {
      navigate(`/property/rent/details/${id}`);
    }
    else if (state.type === 'sale') {
      navigate(`/property/sale/details/${id}`);
    }
  };

  const filterFavoriteProperties = (properties: Array<Property> | any) => {
    if (properties) {
      if (localStorage.getItem('user_id')) {
        properties.forEach((property: any) => {
          property.isFavorite = favoriteProperties.some((favProperty: any) => favProperty.property_id === property.property_id);

        });
      }
      else {
        const notSyncedFav = localStorage.getItem('favorite-not-synced');
        if (notSyncedFav != null) {
          const parsedNotSyncedFav = JSON.parse(notSyncedFav);
          properties.forEach((property: any) => {
            property.isFavorite = parsedNotSyncedFav.some((favProp:any) => favProp.id === property.property_id);
          });
        }
      }
    }
    return properties;
  };
  // if (loading) {
  //   return <LoadingTest />;
  // }
  return (
    <div className='min-h-screen'>
      <ToastContainer autoClose={4000}/>
      <div className='sm:ml-[15%] ml-6 my-7'>
        <button
          onClick={() => navigate(-1)}
          className='backButton bg-darkGray font-press-start rounded px-1 hover:text-gray-400 text-sm'
        >
          <span className='backIcon'>
            <svg
              width='15'
              height='10'
              viewBox='0 0 15 10'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M4.10354 0.19887L0.18055 4.51989C-0.0601831 4.78505 -0.0601831 5.21495 0.18055 5.48011L4.10354 9.80113C4.34428 10.0663 4.73458 10.0663 4.97532 9.80113C5.21605 9.53597 5.21605 9.10606 4.97532 8.8409L2.10465 5.67898L15 5.67898L15 4.32102L2.10465 4.32102L4.97532 1.1591C5.21605 0.893937 5.21605 0.46403 4.97532 0.19887C4.73458 -0.0662889 4.34427 -0.0662889 4.10354 0.19887Z'
                fill='white'
              />
            </svg>
          </span>
          <span className='backText text-white '>
            {t('propertyListing.back')}
          </span>
        </button>
      </div>{' '}
      <div className=''>
        <p className='sm:ml-[15%] m-6 text-lg font-medium font-press-start'>
          {properties?.length} {t('propertyListing.foundProperties')}
        </p>
        <PropertySearch state={state} setProperties={setProperties} setLoading={setLoading} />
        {loading ? <ListingsLoading/> : (
          properties?.length === 0 ?
            <PropertyNotFound name={t('propertyListing.noProperty')}/> :
            <div>
              { filterFavoriteProperties(properties)?.map((property: any) => {
                return (
                  <PropertyCard key={property.property_id} property={property}  handleFavouriteProperty={handleFavouriteProperty} handleNavigation={handleNavigation} state={state} handleFavouriteDelete={handleFavouriteDelete} />
                );
              })}
            </div>
        )}

      </div>
    </div>
  );
};

export { PropertyListing };
