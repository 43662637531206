import { Property } from '../../config';

export const utilsProperty = { generateTitle, generateTitleList, generateTitleAgreement };

function generateTitle(propertyDetails: Property, t:any) {
  let title = '';
  const type = propertyDetails?.property?.property_types[0]?.type_of_property;
  let size_rooms = 0;
  if (
    propertyDetails?.property?.size_rooms != null &&
    propertyDetails?.property?.size_rooms != 0
  ) {
    size_rooms = propertyDetails?.property?.size_rooms;
  } else {
    size_rooms = 0;
  }
  let plot_area = 0;
  if (
    propertyDetails?.property?.plot_area != null &&
    propertyDetails?.property?.plot_area != 0
  ) {
    plot_area = propertyDetails?.property?.plot_area;
  } else {
    plot_area = 0;
  }
  let living_space = 0;
  if (
    propertyDetails?.property?.living_space != null &&
    propertyDetails?.property?.living_space != 0
  ) {
    living_space = propertyDetails?.property?.living_space;
  } else {
    living_space = 0;
  }

  if (size_rooms != 0) {
    title = size_rooms + ' ' + t('trendingEstate.rooms');
  }
  if (type == 8 || type == 11) {
    if (size_rooms != 0 && plot_area != 0) {
      title = title + ', ';
    }
    if (plot_area != 0) {
      title = title + plot_area + ' m²';
    }
  } else {
    if (size_rooms != 0 && living_space != 0) {
      title = title + ', ';
    }
    if (living_space != 0) {
      title = title + living_space + ' m²';
    }
  }
  return title;
}
function generateTitleList(propertyDetails: any, t:any) {
  let title = '';
  const type = propertyDetails?.property_type;
  let size_rooms = 0;
  if (propertyDetails?.size_rooms != null && propertyDetails?.size_rooms != 0) {
    size_rooms = propertyDetails?.size_rooms;
  } else {
    size_rooms = 0;
  }
  let plot_area = 0;
  if (propertyDetails?.plot_area != null && propertyDetails?.plot_area != 0) {
    plot_area = propertyDetails?.plot_area;
  } else {
    plot_area = 0;
  }
  let living_space = 0;
  if (
    propertyDetails?.living_space != null &&
    propertyDetails?.living_space != 0
  ) {
    living_space = propertyDetails?.living_space;
  } else {
    living_space = 0;
  }

  if (size_rooms != 0) {
    title = size_rooms + ' ' + t('trendingEstate.rooms');
  }
  if (type == 8 || type == 11) {
    if (size_rooms != 0 && plot_area != 0) {
      title = title + ', ';
    }
    if (plot_area != 0) {
      title = title + plot_area + ' m²';
    }
  } else {
    if (size_rooms != 0 && living_space != 0) {
      title = title + ', ';
    }
    if (living_space != 0) {
      title = title + living_space + ' m²';
    }
  }
  return title;
}

function generateTitleAgreement(propertyDetails: any, t:any) {
  let title = '';
  const type = propertyDetails?.property_on_auction?.property_type;
  let size_rooms = 0;
  if (
    propertyDetails?.property_on_auction?.size_rooms != null &&
    propertyDetails?.property_on_auction?.size_rooms != 0
  ) {
    size_rooms = propertyDetails?.property_on_auction?.size_rooms;
  } else {
    size_rooms = 0;
  }
  let plot_area = 0;
  if (
    propertyDetails?.property_on_auction?.plot_area != null &&
    propertyDetails?.property_on_auction?.plot_area != 0
  ) {
    plot_area = propertyDetails?.property_on_auction?.plot_area;
  } else {
    plot_area = 0;
  }
  let living_space = 0;
  if (
    propertyDetails?.property_on_auction?.living_space != null &&
    propertyDetails?.property_on_auction?.living_space != 0
  ) {
    living_space = propertyDetails?.property_on_auction?.living_space;
  } else {
    living_space = 0;
  }

  if (size_rooms != 0) {
    title = size_rooms + ' ' + t('trendingEstate.rooms');
  }
  if (type == 8 || type == 11) {
    if (size_rooms != 0 && plot_area != 0) {
      title = title + ', ';
    }
    if (plot_area != 0) {
      title = title + plot_area + ' m²';
    }
  } else {
    if (size_rooms != 0 && living_space != 0) {
      title = title + ', ';
    }
    if (living_space != 0) {
      title = title + living_space + ' m²';
    }
  }
  return title;
}
