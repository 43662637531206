import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigationStepper } from './NavigationStepper';
interface Props {
  handleBack: any;
  handleNext: any;
  steps: any;
  user: any;
  input: any;
}
function UserDetails(props: Props) {
  const { handleBack, handleNext, steps, user, input } = props;
  const [step] = useState(2);
  const { t } = useTranslation();
  return (
    <div className='min-h-screen'>
      <NavigationStepper steps={steps} activeStep={step} />
      <div className='mt-6 mb-2'>
        <p className='font-bold text-[22px]'>
          {t('debtCollection.orderDebtCollection')}
        </p>
      </div>
      <div className='space-y-6'>
        <p>{t('debtCollection.userDetails.checkInformation')}</p>
        <p className='font-bold'>{t('debtCollection.userDetails.applicant')}</p>
      </div>
      <div>
        <div className='flex space-x-3 font-bold text-md pt-2 pb-2'>
          <p>{t('debtCollection.userDetails.firstLastName')}</p>
          <p>{user?.first_name} {user?.last_name}</p>
        </div>
        <div className='flex space-x-3 font-bold text-md pt-2 pb-2'>
          <p>{t('debtCollection.userDetails.streetNumber')}</p>
          <p>{user?.address?.street} {user?.address?.street_no}</p>
        </div>
      </div>
      <div className='flex space-x-3 font-bold text-md pt-2 pb-2'>
        <p>{t('debtCollection.userDetails.postalAndCity')}</p>
        <p>{user?.address?.zip_code} {user?.address?.city}</p>
      </div>
      <div className='flex space-x-3 font-bold text-md pt-2 pb-2'>
        <p>{t('debtCollection.userInformation.dateOfBirth')}</p>
        <p>{input?.birthday}</p>
      </div>
      <hr className='mt-6' />
      <div className='flex justify-between mt-5'>
        <button
          className='text-darkGray shadow-md flex  hover:bg-gray-100 rounded-lg self-center px-4 py-2 font-black'
          onClick={handleBack}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth='2'
            stroke='currentColor'
            className='w-5 h-5'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M15.75 19.5L8.25 12l7.5-7.5'
            />
          </svg>
          <p className=''>{t('debtCollection.back')}</p>
        </button>
        <button
          className='px-4 py-2 bg-auctionicRed text-white font-bold rounded-md cursor-pointer hover:bg-darkGray transition-all ease-in-out duration-100'
          onClick={handleNext}
        >
          {t('debtCollection.next')}
        </button>
      </div>
    </div>
  );
}

export { UserDetails };
