import { auctionicApi } from '../helpers/api';
import { headers } from '../helpers/headers';
import { UserInput } from '../config';

const userLogin = (email: string, password: string) => {
  const body = { email, password };
  const config = { headers: headers.getHeaders() };
  return auctionicApi.post('/login', body, config);
};

const userLogin2FA = (code: string) => {
  const body = { code };
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.post('/login_2fa', body, config);
};

const userRegister = (userData: UserInput) => {
  const body = userData;
  const config = { headers: headers.getHeaders() };
  return auctionicApi.post('/register', body, config);
};

const userAuth = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/auth', config);
};

const userIdenfy = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/idenfy/kyc', config);
};

const userLogout = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get(`/logout/`, config);
};

const passwordReset = (old_password: string, new_password: string) => {
  const body = { old_password, new_password };
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.patch('/user/password', body, config);
};

const getInvoiceByPaymentId = (payment_id: number) => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get(`/invoice/${payment_id}`, config);
};

const resendVerification = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/phone/resend/code/', config);
};

const postConfirm = () => {
  const body = { };
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.post('/post_confirm', body, config);
};

const postPhoneCodeConfirm = (code: string) => {
  const body = { code };
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.patch('/post_confirm', body, config);
};

const checkPostConfirm = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/check_post_confirm',  config);
};

const editUser = (body:any) => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.patch('/user', body, config);
};

const debtRequest = (input: any) => {
  const formData = new FormData();
  input.images.forEach((file: any) => formData.append('images', file));
  if (input.id_document == 'id_card') {
    input.images_back.forEach((file: any) => formData.append('images', file));
  }
  formData.append('id_document', input.id_document);
  formData.append('signature', input.signature);
  formData.append('birthday', input.birthday);
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.post('/request_debt', formData, config);
};

const subscribeLetter = (email: any) => {
  const body = { email };
  return auctionicApi.post('/subscribe', body);
};

const unsubscribeLetter = (email: any) => {
  const body = { email };
  return auctionicApi.post('/unsubscribe', body);
};

const resendEmailVerification = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/email/resend/confirmation/', config);
};

const phoneConfirmation = (code: string) => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get(`/phone/confirmation/${code}/`, config);
};

const forgetPassword = (input: any) => {
  const body = input;
  const config = { headers: headers.getHeaders() };
  return auctionicApi.post('/password/reset/request/', body, config);
};

const resetPass = (input: any) => {
  const body = input;
  const config = { headers: headers.getHeaders() };
  return auctionicApi.post('/password/reset/', body, config);
};

const terminateAcc = (password: any) => {
  const body = { password };
  const config = { headers: headers.getHeaders() };
  return auctionicApi.post('/user/terminate', body, config);
};

const requestSuppport = (body: any) => {
  const config = { headers:  headers.getHeaders() };
  return auctionicApi.post('/user/ai/support', body, config);
};

export const userServices = {
  userLogin,
  userAuth,
  userLogin2FA,
  userRegister,
  userIdenfy,
  userLogout,
  passwordReset,
  getInvoiceByPaymentId,
  resendVerification,
  postConfirm,
  postPhoneCodeConfirm,
  checkPostConfirm,
  editUser,
  debtRequest,
  subscribeLetter,
  unsubscribeLetter,
  resendEmailVerification,
  phoneConfirmation,
  forgetPassword,
  resetPass,
  terminateAcc,
  requestSuppport,
};
