import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
const DebtCollection = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className='my-10 sm:my-[115px]'>
      <div>
        <div className='text-xl sm:text-[45px] text-center'>{t('services.debtCollection')}</div>
      </div>
      <div className='mt-5 sm:mt-0'>
        <p className='text-base font-bold'>{t('debtCollection.howItWorks')}</p>
        <p>
          {t('debtCollection.onlineOrder')}
        </p>
      </div>
      <div className='grid grid-cols-5 justify-center mt-28'>
        <div className='flex justify-center'>
          <div className='flex '>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='black'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='currentColor'
              className='w-[85px] h-[85px]'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z'
              />
            </svg>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='2.5'
              stroke='currentColor'
              className='w-10 h-10 absolute mt-10 ml-[60px]'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z'
              />
            </svg>
          </div>
        </div>

        <div className='flex justify-center self-center'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth='1.5'
            stroke='currentColor'
            className='w-20 h-20'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M8.25 4.5l7.5 7.5-7.5 7.5'
            />
          </svg>
        </div>
        <div className='flex justify-center'>
          <div className='flex'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 512 512'
              className='w-20 h-20'
              fill='black'
            >
              <path d='M152 120c-26.51 0-48 21.49-48 48s21.49 48 48 48s48-21.49 48-48S178.5 120 152 120zM447.1 32h-384C28.65 32-.0091 60.65-.0091 96v320c0 35.35 28.65 64 63.1 64h384c35.35 0 64-28.65 64-64V96C511.1 60.65 483.3 32 447.1 32zM463.1 409.3l-136.8-185.9C323.8 218.8 318.1 216 312 216c-6.113 0-11.82 2.768-15.21 7.379l-106.6 144.1l-37.09-46.1c-3.441-4.279-8.934-6.809-14.77-6.809c-5.842 0-11.33 2.529-14.78 6.809l-75.52 93.81c0-.0293 0 .0293 0 0L47.99 96c0-8.822 7.178-16 16-16h384c8.822 0 16 7.178 16 16V409.3z' />
            </svg>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='4.0'
              stroke='currentColor'
              className='w-10 h-10 bg-white absolute ml-14 -mt-3'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M4.5 10.5L12 3m0 0l7.5 7.5M12 3v18'
              />
            </svg>
          </div>
        </div>
        <div className='flex justify-center self-center'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth='1.5'
            stroke='currentColor'
            className='w-20 h-20'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M8.25 4.5l7.5 7.5-7.5 7.5'
            />
          </svg>
        </div>
        <div className='flex justify-center'>
          <div className='flex'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 512 512'
              stroke='1.5'
              className='w-20 h-20 '
            >
              <path d='M0 128C0 92.65 28.65 64 64 64H448C483.3 64 512 92.65 512 128V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V128zM48 128V150.1L220.5 291.7C241.1 308.7 270.9 308.7 291.5 291.7L464 150.1V127.1C464 119.2 456.8 111.1 448 111.1H64C55.16 111.1 48 119.2 48 127.1L48 128zM48 212.2V384C48 392.8 55.16 400 64 400H448C456.8 400 464 392.8 464 384V212.2L322 328.8C283.6 360.3 228.4 360.3 189.1 328.8L48 212.2z' />
            </svg>
          </div>
        </div>
      </div>
      <div className='grid grid-cols-5 justify-center mb-28'>
        <div className='text-center'>
          <p>1. {t('debtCollection.personalInfo')}</p>
        </div>
        <div></div>
        <div className='text-center'>
          <p>2. {t('debtCollection.idPassport')}</p>
        </div>
        <div></div>
        <div className='text-center'>
          <p>3. {t('debtCollection.getEmail')}</p>
        </div>
      </div>
      <div className='grid grid-cols-1 sm:grid-cols-2 gap-10'>
        <div className='text-center space-y-5'>
          <p className=''>
            {t('debtCollection.saveYourself')}
          </p>
          <button className='bg-auctionicRed px-8 py-3 uppercase text-white rounded-lg text-lg hover:bg-red-700 hover:shadow-sm hover:shadow-black transition-all ease-in-out duration-150' onClick={() => navigate('/debtCollection')}>
            {t('debtCollection.orderNow')}
          </button>
        </div>
        <div className='bg-darkGray text-white shadow-lg shadow-black px-5 py-10 text-xl'>
          <div className='space-y-5'>
            <div className='border-b border-white flex justify-between'>
              <p>{t('debtCollection.officialFee')}</p>
              <p>CHF 17.00</p>
            </div>
            <div className='border-b border-white flex justify-between'>
              <p>{t('debtCollection.processingFee')}</p>
              <p>CHF 11.00</p>
            </div>
            <div className='border-b border-white flex justify-between'>
              <p>{t('debtCollection.postageFee')}</p>
              <p>CHF 1.00</p>
            </div>
          </div>
          <div className='border-b border-white flex justify-between mt-10'>
            <p>{t('debtCollection.total')}</p>
            <p>CHF 29.00</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export { DebtCollection };
