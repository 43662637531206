import React from 'react';
import { useTranslation } from 'react-i18next';
import bg from '../../images/kuoni.jpg';
import logo from '../../images/kuoni_transporte.png';

const KuoniTransport = ( { setSelectedRegion } :any) => {
  const { t } = useTranslation();
  const handleBack = () => {
    setSelectedRegion('');
  };
  return (
    <div className='sm:mt-[115px] mt-10 my-10 text-darkGray'>
      <div className='space-y-6'>
        <div>
          <button
            onClick={handleBack}
            className='backButton bg-darkGray font-press-start rounded px-1 hover:text-gray-400 text-sm'>
            <span className='backIcon'>
              <svg
                width='15'
                height='10'
                viewBox='0 0 15 10'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M4.10354 0.19887L0.18055 4.51989C-0.0601831 4.78505 -0.0601831 5.21495 0.18055 5.48011L4.10354 9.80113C4.34428 10.0663 4.73458 10.0663 4.97532 9.80113C5.21605 9.53597 5.21605 9.10606 4.97532 8.8409L2.10465 5.67898L15 5.67898L15 4.32102L2.10465 4.32102L4.97532 1.1591C5.21605 0.893937 5.21605 0.46403 4.97532 0.19887C4.73458 -0.0662889 4.34427 -0.0662889 4.10354 0.19887Z'
                  fill='white'
                />
              </svg>
            </span>
            <span className='backText text-white '>
              {t('propertyListing.back')}
            </span>
          </button>
        </div>
        <div className='text-xl sm:text-[45px] text-center'>
          {t('services.relocationAssistance.title')}
        </div>
        <div className='text-lg sm:text-[35px] text-center'>
          KUONI Transport
        </div>
        <div className='relative flex items-center bg-white xl:full lg:h-80 md:h-64 h-72'>
          <img src={bg}  className='relative opacity-60 object object-cover lg:object-center md:object-right object-right h-full w-full' alt="" />
          <div className='absolute grid grid-flow-col xl:p-10 lg:p-5 md:p-6 p-5'>
            <div className='md:hidden hidden lg:block'>
              <div className='xl:4xl lg:text-3xl md:text-2xl text-xl font-bold'>
                {t('kuoni.title')}
              </div>
              <p className='sm:text-lg leading-1'>
                {t('kuoni.dsc1')}
              </p>
            </div>
            <div className='flex self-center justify-center'>
              <img src={logo} className='w-[70%]' alt="" />
            </div>
          </div>
        </div>

        {/* phone */}
        <div className='lg:hidden px-3'>
          <div className='lg:text-4xl md:text-2xl text-2xl font-bold'>
            {t('kuoni.title')}
          </div>
          <p className='sm:text-lg leading-1'>
            {t('kuoni.dsc1')}
          </p>
        </div>

        <div className='xl:p-10 lg:p-0 md:p-3 p-3 xl:pr-96 lg:pr-0'>
          <div className='sm:text-4xl text-2xl'>
            {t('kuoni.title2')}
          </div>
          <div>
            {t('kuoni.dsc2')}
          </div>
        </div>
        <div className='xl:px-32 lg:px-14 md:px-3 px-3 text-center'>
          <div className='sm:text-4xl text-2xl'>
            {t('kuoni.title3')}
          </div>
          <div>
            {t('kuoni.dsc3')}
          </div>
        </div>
        <div className='xl:p-10 lg:p-0 md:p-3 p-3'>
          <div className='sm:text-4xl text-2xl'>
            {t('kuoni.title4')}
          </div>
          <div>
            {t('kuoni.dsc4')}
          </div>
          <div>
            <ul className='list-disc list-inside'>
              <li>
                {t('kuoni.list1')}
              </li>
              <li>
                {t('kuoni.list2')}
              </li>
              <li>
                {t('kuoni.list3')}
              </li>
              <li>
                {t('kuoni.list4')}
              </li>
              <li>
                {t('kuoni.list5')}
              </li>
              <li>
                {t('kuoni.list6')}
              </li>
              <li>
                {t('kuoni.list7')}
              </li>
              <li>
                {t('kuoni.list8')}
              </li>
            </ul>
          </div>
        </div>
        <div className='flex justify-center'>
          <div
            className='
            bg-[#bd2634] hover:bg-white
              hover:outline hover:outline-1 hover:outline-[#bd2634]
            text-white hover:text-[#bd2634]
              rounded-lg text-2xl text-center px-4 py-2' >
            <a href="https://www.kuoni-gr.ch/de">{t('kuoni.visitUs')}</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export { KuoniTransport };
