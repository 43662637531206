import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddressInput, UserInput, } from '../../config';
import useAuth from '../../context/AuthContext';
import { userServices } from '../../services';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { utils } from '../../helpers/utils';
import { cantons } from '../Register/cantons';
import { SpinnerLoading } from '../LoadingTest/SpinnerLoading';

const Account = () => {
  const { user } = useAuth();
  const [editLoader,setEditLoader] = useState(false);
  const [input, setInput] = useState({
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    phone: user.phone,
    telephone: user.telephone,
    address: {
      city: user.address.city,
      street: user.address.street,
      canton: user.address.canton,
      zip_code: user.address.zip_code,
    } as AddressInput,
    // type: user.type,
    name: user.name,
  });
  const validZip = new RegExp(/^(\d{4})$/);
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setInput((prevInput) => {
      return {
        ...prevInput,
        [name]: value,
      };
    });
  };

  const handleEdit = () => {
    const fixBody = {
      ...input,
      address: utils.formatAddressEdit(input.address) as any
    } as UserInput;
    setEditLoader(true);
    userServices
      .editUser(fixBody)
      .then((response: any) => {
        setEditLoader(false);
        toast.success(t('dashboard.settingsSection.successEdit'), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      })
      .catch(() => {
        setEditLoader(false);
        toast.error(t('errors.editPropertyE'), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };
  const { t } = useTranslation();
  return (
    <div className='bg-[#F5F5F5] rounded-lg mx-4 sm:mx-0 px-10 py-[24px] mt-10 font-press-start'>
      <ToastContainer autoClose={2000} />
      <p className='text-[20px] border-b border-darkGray font-press-start mb-3'>
        {t('dashboard.settingsSection.profileInfo')}
      </p>
      {user.type === 'individual' ? (
        <div className='flex flex-wrap -mx-3 mb-6 mt-6'>
          <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
            <label
              className='block tracking-wide text-gray-700 italic mb-2'
              htmlFor='grid-first-name'
            >
              {t('dashboard.settingsSection.firstName')}
            </label>
            <input
              type='text'
              readOnly
              placeholder={t('dashboard.settingsSection.firstName')}
              defaultValue={input.first_name}
              name='first_name'
              onChange={handleChange}
              className='font-semibold italic w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none '
            />
          </div>
          <div className='w-full md:w-1/2 px-3'>
            <label
              className='block tracking-wide text-gray-700 italic mb-2'
              htmlFor='grid-last-name'
            >
              {t('dashboard.settingsSection.lastName')}
            </label>
            <input
              type='text'
              placeholder={t('dashboard.settingsSection.lastName')}
              defaultValue={input.last_name}
              readOnly
              name='last_name'
              className='font-semibold italic w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none'
            />
          </div>
        </div>
      ) : (
        <div className='w-full mb-6'>
          <label
            className='block tracking-wide text-gray-700 italic mb-2'
            htmlFor='grid-first-name'
          >
            {t('dashboard.settingsSection.companyName')}
          </label>
          <input
            type='text'
            placeholder={t('dashboard.settingsSection.companyName')}
            defaultValue={input.name}
            name='name'
            readOnly
            onChange={handleChange}
            className='font-semibold italic w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer required'
          />
        </div>
      )}
      <div className='flex flex-wrap -mx-3 mb-2'>
        <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
          <label
            className='block tracking-wide text-gray-700 italic mb-2'
            htmlFor='grid-email'
          >
            {t('dashboard.settingsSection.street')}
          </label>
          <div className='flex space-x-1.5'>
            <input
              type='text'
              placeholder={t('dashboard.settingsSection.street')}
              defaultValue={user.address.street + (user.address.street_no != null ? ' ' + user.address.street_no : '')}
              onChange={(e) => {
                setInput({
                  ...input,
                  address: {
                    ...input.address,
                    street: e.target.value,
                  }
                });
              }}
              name='street'
              className='font-semibold italic w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer required'
            />
            <div className='mb-2'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='#FF002B'
                viewBox='0 0 24 22'
                strokeWidth='0.5'
                stroke='currentColor'
                className='w-6 h-6'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M18 13.45V20H2V8H8.743L10.721 6H0V22H20V11.427L18 13.45ZM19.473 2.835L21.18 4.542L11.899 13.92L9.669 14.392L10.181 12.223L19.473 2.835ZM19.465 0L8.361 11.216L7 17L12.898 15.752L24.001 4.534L19.465 0Z'
                />
              </svg>
            </div>
          </div>
        </div>
        <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
          <label
            className='block tracking-wide text-gray-700 italic mb-2'
            htmlFor='grid-phone'
          >
            {t('dashboard.settingsSection.zip')}
          </label>
          <div className='flex space-x-2'>
            <input
              type='text'
              placeholder={t('dashboard.settingsSection.zip')}
              defaultValue={user.address.zip_code}
              onChange={(e) => {
                setInput({
                  ...input,
                  address: {
                    ...input.address,
                    zip_code: e.target.value,
                  }
                });
              }}
              name='zip_code'
              className='font-semibold italic w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer required'
            />
            <div className='mb-2'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='#FF002B'
                viewBox='0 0 24 22'
                strokeWidth='0.5'
                stroke='currentColor'
                className='w-6 h-6'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M18 13.45V20H2V8H8.743L10.721 6H0V22H20V11.427L18 13.45ZM19.473 2.835L21.18 4.542L11.899 13.92L9.669 14.392L10.181 12.223L19.473 2.835ZM19.465 0L8.361 11.216L7 17L12.898 15.752L24.001 4.534L19.465 0Z'
                />
              </svg>
            </div>
          </div>
          { !validZip.test(input.address.zip_code)  && (
            <p className='text-xs text-red-500 absolute'>
              *{t('listingItem.propertyForm.invalidZip')}
            </p>)}
        </div>
      </div>
      <div className='flex flex-wrap -mx-3 mb-2 '>
        <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
          <label
            className='block tracking-wide text-gray-700 italic mb-2'
            htmlFor='grid-email'
          >
            {t('dashboard.settingsSection.city')}
          </label>
          <div className='flex space-x-1.5'>
            <input
              type='text'
              placeholder={t('dashboard.settingsSection.city')}
              defaultValue={user.address.city}
              onChange={(e) => {
                setInput({
                  ...input,
                  address: {
                    ...input.address,
                    city: e.target.value,
                  }
                });
              }}
              name='city'
              className='font-semibold italic w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer required'
            />
            <div className='mb-2'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='#FF002B'
                viewBox='0 0 24 22'
                strokeWidth='0.5'
                stroke='currentColor'
                className='w-6 h-6'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M18 13.45V20H2V8H8.743L10.721 6H0V22H20V11.427L18 13.45ZM19.473 2.835L21.18 4.542L11.899 13.92L9.669 14.392L10.181 12.223L19.473 2.835ZM19.465 0L8.361 11.216L7 17L12.898 15.752L24.001 4.534L19.465 0Z'
                />
              </svg>
            </div>
          </div>
        </div>
        <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
          <label
            className='block tracking-wide text-gray-700 italic mb-2'
            htmlFor='grid-phone'
          >
            {t('dashboard.settingsSection.canton')}
          </label>
          <div className='flex space-x-2'>
            <select
              name='canton'
              defaultValue={user.address.canton}
              onChange={(e) => {
                setInput({
                  ...input,
                  address: {
                    ...input.address,
                    canton: e.target.value,
                  }
                });
              }}
              className='font-semibold italic w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer required'
            >
              <option defaultChecked className=''>
                {t('register.basicInformation.chooseCanton')}
              </option>
              {cantons.map((canton) => {
                return (
                  <option key={canton.id} value={canton.location}>
                    {canton.location}
                  </option>
                );
              })}
            </select>
            <div className='mb-2'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='#FF002B'
                viewBox='0 0 24 22'
                strokeWidth='0.5'
                stroke='currentColor'
                className='w-6 h-6'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M18 13.45V20H2V8H8.743L10.721 6H0V22H20V11.427L18 13.45ZM19.473 2.835L21.18 4.542L11.899 13.92L9.669 14.392L10.181 12.223L19.473 2.835ZM19.465 0L8.361 11.216L7 17L12.898 15.752L24.001 4.534L19.465 0Z'
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-wrap -mx-3 mb-2 '>
        <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
          <label
            className='block tracking-wide text-gray-700 italic mb-2'
            htmlFor='grid-email'
          >
            {t('dashboard.settingsSection.email')}
          </label>
          <div className='flex space-x-1.5'>
            <input
              type='text'
              placeholder='Email'
              defaultValue={user.email}
              onChange={handleChange}
              name='email'
              className='font-semibold italic w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer required'
            />
            <div className='mb-2'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='#FF002B'
                viewBox='0 0 24 22'
                strokeWidth='0.5'
                stroke='currentColor'
                className='w-6 h-6'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M18 13.45V20H2V8H8.743L10.721 6H0V22H20V11.427L18 13.45ZM19.473 2.835L21.18 4.542L11.899 13.92L9.669 14.392L10.181 12.223L19.473 2.835ZM19.465 0L8.361 11.216L7 17L12.898 15.752L24.001 4.534L19.465 0Z'
                />
              </svg>
            </div>
          </div>
        </div>
        <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
          <label
            className='block tracking-wide text-gray-700 italic mb-2'
            htmlFor='grid-phone'
          >
            {t('dashboard.settingsSection.phone')}
          </label>
          <div className='flex space-x-2'>
            <input
              type='text'
              placeholder={t('dashboard.settingsSection.phone')}
              defaultValue={user.phone}
              onChange={handleChange}
              name='phone'
              className='font-semibold italic w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer required'
            />
            <div className='mb-2'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='#FF002B'
                viewBox='0 0 24 22'
                strokeWidth='0.5'
                stroke='currentColor'
                className='w-6 h-6'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M18 13.45V20H2V8H8.743L10.721 6H0V22H20V11.427L18 13.45ZM19.473 2.835L21.18 4.542L11.899 13.92L9.669 14.392L10.181 12.223L19.473 2.835ZM19.465 0L8.361 11.216L7 17L12.898 15.752L24.001 4.534L19.465 0Z'
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-wrap -mx-3 mb-2 '>
        <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
          <label
            className='block tracking-wide text-gray-700 italic mb-2'
            htmlFor='grid-phone'
          >
            {t('dashboard.settingsSection.telephone')}
          </label>
          <div className='flex space-x-2'>
            <input
              type='text'
              placeholder={t('dashboard.settingsSection.telephonePlaceholder')}
              defaultValue={user.telephone}
              onChange={handleChange}
              name='telephone'
              className='font-semibold italic w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer required'
            />
            <div className='mb-2'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='#FF002B'
                viewBox='0 0 24 22'
                strokeWidth='0.5'
                stroke='currentColor'
                className='w-6 h-6'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M18 13.45V20H2V8H8.743L10.721 6H0V22H20V11.427L18 13.45ZM19.473 2.835L21.18 4.542L11.899 13.92L9.669 14.392L10.181 12.223L19.473 2.835ZM19.465 0L8.361 11.216L7 17L12.898 15.752L24.001 4.534L19.465 0Z'
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className='text-center mt-10'>
        <button
          className='bg-auctionicRed px-7 py-2 text-white rounded-lg'
          onClick={handleEdit}
          disabled={!validZip.test(input.address.zip_code)}
        >
          {editLoader ? (
            <SpinnerLoading/>
          ):(
            <>
              {t('dashboard.settingsSection.updateProfile')}
            </>)}
        </button>
      </div>
    </div>
  );
};

export { Account };
