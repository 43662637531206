import React, { useState } from 'react';
import { PropertyDetailsStep } from './PropertyDetailsStep';
import { AdvertisingDetailsStep } from './AdvertisingDetailsStep';
import { UserInfoStep } from './UserInfoStep';
import { PaymentStep } from './PaymentStep';
import Close from '../../../images/x_Black.svg';
import { Property, ReAdvertiseInput } from '../../../config';
import { propertyServices } from '../../../services/propertyServices';
import { AxiosError, AxiosResponse } from 'axios';
import { utils } from '../../../helpers/utils';
import { useTranslation } from 'react-i18next';
import logo from '../../../images/logoRed.svg';


interface StepperProps {
  currentStep: number;
}

const Stepper: React.FC<StepperProps> = ({ currentStep }) => {
  return (
    <div className="flex px-5 mb-4 w-full">
      <div className='flex w-full'>
        <div
          className={`w-10 h-10 sm:w-16 sm:h-16 flex items-center justify-center rounded-xl ${
            currentStep === 1 ? 'bg-darkGray text-white' : 'border-2 border-darkGray text-darkGray'
          }`}
        >
            1
        </div>
        <div className="h-[1px] flex-grow self-center bg-darkGray"></div>
      </div>
      <div className='flex w-full'>
        <div
          className={`w-10 h-10 sm:w-16 sm:h-16 flex items-center justify-center rounded-xl ${
            currentStep === 2 ? 'bg-darkGray text-white' : 'border-2 border-darkGray text-darkGray'
          }`}
        >
            2
        </div>
        <div className="h-[1px] w-full self-center bg-darkGray"></div>
      </div>
      <div className='flex w-full'>
        <div
          className={`w-10 h-10 sm:w-16 sm:h-16 flex items-center justify-center rounded-xl ${
            currentStep === 3 ? 'bg-darkGray text-white' : 'border-2 border-darkGray text-darkGray'
          }`}
        >
            3
        </div>
        <div className="h-[1px] w-full self-center bg-darkGray"></div>
      </div>
      <div className='flex'>
        <div
          className={`w-10 h-10 sm:w-16 sm:h-16 flex items-center justify-center rounded-xl ${
            currentStep === 4 ? 'bg-darkGray text-white' : 'border-2 border-darkGray text-darkGray'
          }`}
        >
            4
        </div>
      </div>
    </div>
  );
};


interface ReAdvertiseModalProps {
  propertyData: any;
  onClose: any;
}

const ReAdvertiseModal: React.FC<ReAdvertiseModalProps> = ({ propertyData, onClose, }) => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(1);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [property, setProperty] = useState<Property>();
  const [error, setError] = useState(false);

  const [reAdvertiseInput, setReAdvertiseInput] = useState<ReAdvertiseInput>({
    property_id: propertyData?.property_id,
    seller_id: propertyData?.property?.id,
    duration: propertyData?.duration,
    start_date: new Date(),
    price: propertyData?.price,
    starting_price: propertyData?.current_bid,
    payment: {
      price: propertyData?.payment?.plan?.price,
      discount: '0',
      plan_id: propertyData?.payment?.plan?.id,
      method: propertyData?.payment?.method,
    },
    type: propertyData?.type,
    minimum_increasing_amount: propertyData.minimum_increasing_amount,
    buy_now: propertyData?.buy_now,
    buy_now_price: propertyData?.buy_now_price,
  });

  const handleSubmit = () => {
    setSubmitLoading(true);
    const body = {
      ...reAdvertiseInput,
      start_date: utils.localDateToUTC(reAdvertiseInput.start_date),
    };
    propertyServices.reAdvertise(body).then(
      (response: AxiosResponse<Property>) => {
        console.log('response', response.data);
        setProperty(response.data);
      }
    ).catch((error: AxiosError) => {
      console.log(error.response?.data);
      setError(true);
    }
    ).finally(() => {
      setSubmitLoading(false);
    });
  };

  console.log('reAdvertisement Info:  ', reAdvertiseInput);

  const renderStep = () => {
    switch (currentStep) {
    case 1:
      return <PropertyDetailsStep onNext={() => setCurrentStep(2)} propertyData={propertyData} />;
    case 2:
      return <AdvertisingDetailsStep onBack={() => setCurrentStep(1)} onNext={() => setCurrentStep(3)} propertyData={propertyData} reAdvertiseInput={reAdvertiseInput} />;
    case 3:
      return <UserInfoStep onBack={() => setCurrentStep(2)} onNext={() => { setCurrentStep(4); handleSubmit();}}  propertyData={propertyData} reAdvertiseInput={reAdvertiseInput} setReadvertiseInput={setReAdvertiseInput} />;
    case 4:
      return <PaymentStep submitLoading={submitLoading} property={property} reAdvertiseInput={reAdvertiseInput} error={error}/>;
    default:
      return null;
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 overflow-y-auto">
      <div className="bg-white m-2 p-2 sm:p-6 pb-4 rounded-lg shadow-lg w-full sm:w-3/4 max-h-[90vh] min-h-[90vh] overflow-y-auto">
        <div className='flex mx-5 items-center justify-between mb-3'>
          <div className='text-2xl'>
            {t('dashboard.listingSection.reAdvertise.reAdvertiseTitle')}
          </div>
          <div>
            <img src={logo} alt="" />
          </div>
          <img
            className='rounded-full w-8 h-8 cursor-pointer mr-4'
            onClick={() => onClose()}
            src={Close}
          />
        </div>
        <Stepper currentStep={currentStep} />
        {renderStep()}
      </div>
    </div>
  );
};

export { ReAdvertiseModal };
