import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IdentityCard } from './IdentityCard';
import { NavigationStepper } from './NavigationStepper';
import { Passport } from './Passport';

interface Props {
  handleBack: any;
  handleNext: any;
  steps: any;
  input: any;
  setInput: any;
}

const ProofIdentity = (props: Props) => {
  const { handleBack, handleNext, steps, input, setInput } = props;
  const [step] = useState(1);
  const [identity, setIdentity] = useState('id_card');
  const { t } = useTranslation();

  return (
    <div className='min-h-screen'>
      <div>
        <NavigationStepper steps={steps} activeStep={step} />
        <div className='mt-6 mb-2'>
          <p className='font-bold text-[22px]'>
            {t('debtCollection.orderDebtCollection')}
          </p>
        </div>
        <div className='space-y-3'>
          <p className='font-bold'>{t('debtCollection.identityCheck.title')}</p>
          <p className=''>{t('debtCollection.identityCheck.text')}</p>
          <p className=''>{t('debtCollection.identityCheck.description')}</p>
        </div>
        <div className='my-6 space-y-2 '>
          <p className='font-semibold'>
            {t('debtCollection.identityCheck.chooseIdentity')}
          </p>
          <div>
            <label className='label'>
              {t('debtCollection.identityCheck.identityCard')}
              <input
                type='radio'
                name='type'
                className='h-6 w-6 '
                value=''
                defaultChecked
                onClick={() => {
                  setInput({
                    ...input,
                    id_document: 'id_card',
                  });
                  setIdentity('id_card');
                }}              />
              <span className='checkmark'></span>
            </label>
          </div>
          <div>
            <label className='label'>
              {t('debtCollection.identityCheck.passport')}
              <input
                type='radio'
                name='type'
                className='h-6 w-6 '
                value=''
                onClick={() => {
                  setInput({
                    ...input,
                    id_document: 'passport',
                  });
                  setIdentity('passport');
                }}                  />
              <span className='checkmark'></span>
            </label>
          </div>
        </div>
        {/* Identity check */}
        <div >
          {identity === 'id_card' && (
            <IdentityCard handleBack={handleBack} handleNext={handleNext} input={input} setInput={setInput} />
          )}
          {identity === 'passport' && (
            <Passport handleBack={handleBack} input={input} setInput={setInput} handleNext={handleNext} />
          )}
        </div>
      </div>
    </div>
  );
};

export { ProofIdentity };
