import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar } from 'swiper';
import './Landing.css';
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TrendingProperties } from '../../config';
import { propertyServices } from '../../services/propertyServices';
import { BidCountdown } from './BidCountdown';
import { utils } from '../../helpers/utils';
import { utilsProperty } from '../PropertyDetails/utils';

const TrendingEstate = () => {
  const isLogged = localStorage.getItem('access_token');
  const { t } = useTranslation();
  const [trendingProperties, setTrendingProperties] =
    useState<Array<TrendingProperties>>();

  useEffect(() => {
    propertyServices
      .getTrendingProperties()
      .then((response) => {
        window.scrollTo(0, 0);
        setTrendingProperties(response.data);
      })
      .catch((error) => {
        // console.log('Failed', error);
      });
  }, []);

  return (
    <div className='px-5 sm:px-0 lg:px-24 pb-20 '>
      <Swiper
        modules={[Scrollbar, Navigation, Pagination]}
        slidesPerView={3}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          // when window width is <= 480px
          480: {
            slidesPerView: 2,
            spaceBetween: 20,
          },

          // when window width is <= 640px
          640: {
            slidesPerView: 2,
            spaceBetween: 30,
          },

          1024: {
            slidesPerView:3,
            spaceBetween:30
          }

        }}
        navigation={true}
        scrollbar={{ draggable: true }}
      >
        {trendingProperties?.map((trends) => {
          return (
            <SwiperSlide
              key={trends.id}
              className='py-10 flex justify-center px-10'
            >
              <div className='relative text-center text-white'>
                <img
                  className='rounded-md sm:w-[255px] h-[255px]'
                  src={trends.header_pic}
                  alt=''
                />
                <div className='absolute left-[89%] sm:left-[86%] top-0 w-9 h-9 rounded-bl-lg right-0 bg-white text-red-600 pl-2'>
                  <svg
                    width='17'
                    height='22'
                    viewBox='0 0 17 22'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M5.63293 0.547729C6.16392 6.8055 0.736572 8.9599 0.736572 14.4717C0.736572 18.2155 3.40803 21.411 8.57075 21.4389C13.7335 21.4667 16.4049 17.5966 16.4049 13.643C16.4049 10.0384 14.61 6.63924 11.2239 4.52575C12.0282 6.79505 10.9576 8.86763 9.91736 9.58141C9.97829 6.67667 8.93808 2.36613 5.63293 0.547729ZM9.73369 11.8638C13.0023 15.336 10.995 19.6979 8.36967 19.6979C6.77237 19.6979 5.95065 18.5968 5.95936 17.4548C5.9759 15.3369 8.34182 15.3352 9.73369 11.8638Z'
                      fill='#FF002B'
                    />
                  </svg>
                </div>
                <p className='text-darkGray text-base font-secondfont font-normal text-left pt-1'>
                  {utilsProperty.generateTitleList(trends,t)}
                </p>
                <p className='text-darkGray font-normal text-sm text-left'>
                  {trends.property.address.zip_code}{' '}
                  {trends.property.address.city},{' '}
                  {trends.property.address.canton}
                </p>
                <div className='flex justify-between sm:w-[250px] pt-4'>
                  {isLogged ?  <p className='text-darkGray'> CHF {trends?.type == 'auction' ? utils.numberWithComma(trends.current_bid): utils.numberWithComma(trends.price)} </p> : <p className='text-darkGray text-sm self-center'>{t('trendingEstate.pricePlease')} <Link to='/login' className='text-auctionicRed hover:border-b-2 hover:border-auctionicRed'> {t('trendingEstate.login')} </Link> {t('trendingEstate.or')} <Link to='/register' className='text-auctionicRed hover:border-b-2 hover:border-auctionicRed'>{t('trendingEstate.register')}</Link></p> }
                  <div className='flex justify-end'>
                    <Link
                      to={`/property/${trends.type}/details/${trends.id}`}
                      replace={true}
                      reloadDocument={true}
                      className='backButtonAtTrending bg-darkGray font-press-start rounded px-1 hover:text-gray-400 text-sm '
                    >
                      <button
                        className='backButtonAtTrending bg-darkGray font-press-start rounded px-1 hover:text-gray-400 text-sm '
                        // onClick={() =>
                        //   navigate(`/property/auction/details/${trends.id}`)
                        // }
                      >
                        <span className='backIconAtTrending'>
                          <svg
                            width='12'
                            height='10'
                            viewBox='0 0 12 10'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              fillRule='evenodd'
                              clipRule='evenodd'
                              d='M7.12488 0.590106L10.9872 4.45229C11.2243 4.6893 11.2243 5.07356 10.9872 5.31056L7.12488 9.17275C6.88787 9.40975 6.50359 9.40975 6.26658 9.17275C6.02956 8.93574 6.02956 8.55148 6.26658 8.31448L9.09288 5.48831L0.2406 5.48831L0.2406 4.27454L9.09288 4.27454L6.26658 1.44837C6.02957 1.21137 6.02957 0.827109 6.26658 0.590106C6.50359 0.353103 6.88787 0.353103 7.12488 0.590106Z'
                              fill='white'
                            />
                          </svg>
                        </span>
                        <span className='backTextAtTrending text-white '>
                          {t('propertyListing.moreInfo')}
                        </span>
                      </button>
                    </Link>
                  </div>
                </div>
                <BidCountdown trends={trends} />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export { TrendingEstate };
