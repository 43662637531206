import { t } from 'i18next';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import warning from '../../images/warning.png';

const  VerificationAlert = () => {
  const navigate = useNavigate();

  return (
    <div role="alert">
      <div className="bg-yellow-500 text-white font-bold rounded-t px-4 py-2">
        <div className='flex space-x-2 items-center'>
          <img src={warning} alt={''} className='h-7 w-7'/>
          <p className='text-darkGray text-base'>{t('common.warning')}</p>
        </div>
      </div>
      <div className="border flex space-x-10 border-t-0 border-yellow-500 rounded-b bg-orange-200 px-4 py-3 text-darkGray justify-between items-center">
        <p>{t('common.verifyProfile')}</p>
        <button onClick={() => navigate('/dashboard/1')} className='px-3 bg-white py-2 text-darkGray font-avenir55 font-semibold rounded-md'>{t('common.completeButton')}</button>
      </div>
    </div>
  );
};


const  VerificationAlertBid = () => {
  const navigate = useNavigate();

  return (
    <div role="alert" className='mb-5'>
      <div className="bg-yellow-500 text-white font-bold rounded-t px-4 py-2">
        <div className='flex space-x-2 items-center'>
          <img src={warning} alt={''} className='h-7 w-7'/>
          <p className='text-darkGray text-base'>{t('common.warning')}</p>
        </div>
      </div>
      <div className="border flex space-x-10 border-t-0 border-yellow-500 rounded-b bg-orange-200 px-4 py-3 text-darkGray justify-between items-center">
        <p>{t('common.verifyProfileBid')}</p>
        <button onClick={() => navigate('/dashboard/1')} className='px-3 bg-white py-2 text-darkGray font-avenir55 font-semibold rounded-md'>{t('common.completeButton')}</button>
      </div>
    </div>
  );
};

export { VerificationAlertBid };
export { VerificationAlert };
