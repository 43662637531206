import React, { useState } from 'react';
import './Register.css';
import { useTranslation } from 'react-i18next';
import { UserInput } from '../../config';
import { StrengthBar1 } from '../StrengthBar1';

interface StepperProps {
  setPage: any;
  setClickIndividual: any;
  inputRegister: any;
  handleChangeRegister: any;
  setInputRegister: any;
  handleSalutationChange: any;
  error: any;
  setError: any;
}

const RegisterAs = (props: StepperProps) => {
  const {
    setPage,
    handleChangeRegister,
    inputRegister,
    setInputRegister,
    handleSalutationChange,
    error,
    setError,
  } = props;
  const { t } = useTranslation();
  const { setClickIndividual } = props;
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [score, setScore] = useState(0);
  const [passwordShow, setPasswordShow] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [emailErrorMessage, setErrorEmailMessage] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);
  const handlePasswordShow = () => {
    setPasswordShow(!passwordShow);
  };

  const onChangeScore = (score1: number) => {
    setScore(score1);
  };

  const handleNext = () => {
    const errorList = [];
    if (!inputRegister.salutation) {
      errorList.push('salutation');
    } else if (!inputRegister.password || score < 3) {
      errorList.push('password');
    } else if (!inputRegister.first_name) {
      errorList.push('first_name');
    } else if (!inputRegister.last_name) {
      errorList.push('last_name');
    } else if (!inputRegister.email || emailErrorMessage) {
      errorList.push('email');
    }
    else {
      setPage((currPage: number) => currPage + 1);
    }
    setError(errorList);
  };

  const passwordMeter = [
    t('register.weakPassword'),
    t('register.weakPassword'),
    t('register.okayPassword'),
    t('register.goodPassword'),
    t('register.strongPassword'),
  ];

  const checkEmailFormat = (email: any) => {
    return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
  };
  const handleEmailFormat = (e: any) => {
    const emailValue = e.target.value;
    if (emailValue != '' && emailValue != undefined) {
      if (!checkEmailFormat(emailValue)) {
        setErrorEmailMessage(true);
      } else {
        setErrorEmailMessage(false);
      }
    }
    setInputRegister((inputs: any) => ({
      ...inputs,
      email: emailValue,
    }));
  };


  return (
    <div className='registerContainer '>
      <div className='flex items-center justify-center py-[64px] px-4 sm:px-6 lg:px-8 font-avenir55'>
        <div className='max-w-3xl w-full space-y-8 bg-white'>
          <div className='pb-4 sm:pt-[41px] pl-4 sm:pl-[30px] pt-4'>
            <p className='text-[25px] font-press-start'>
              {' '}
              {t('register.createAcc')}
            </p>
            <p className='text-[15px] font-press-start'>
              {' '}
              {t('register.basicInfo')}
            </p>
          </div>
          <div className='sm:flex px-10 sm:px-16 font-press-start'>
            <div className='space-y-8 sm:space-y-5 '>
              <div className='block'>
                <p title={t('reqField.requiredField')}>{t('register.salutation')}*</p>
                <div className='flex justify-between space-x-5'>
                  <div>
                    <label className='label'>
                      {t('register.mr')}
                      <input
                        type='radio'
                        name='salutation'
                        className='h-6 w-6 pt-1'
                        value={1}
                        checked={inputRegister?.salutation==1}
                        onChange={(e) => handleSalutationChange(e, 1)}
                      />
                      <span className='checkmark'></span>
                    </label>
                  </div>
                  <div className=''>
                    <label className='label'>
                      {t('register.ms')}
                      <input
                        type='radio'
                        name='salutation'
                        className='h-6 w-6 '
                        value={2}
                        checked={inputRegister?.salutation==2}
                        onChange={(e) => handleSalutationChange(e, 2)}
                      />
                      <span className='checkmark'></span>
                    </label>
                  </div>
                </div>
                {error?.includes('salutation') ? (
                  <p className='text-xs text-red-500 absolute '>
                    {t('register.registerAs.chooseStatus')}
                  </p>
                ) : (
                  ''
                )}
              </div>
              <div className='block'>
                <label className='pr-2 ' title={t('reqField.requiredField')}> {t('register.name')}*</label>
                <input
                  type='text'
                  name='first_name'
                  onChange={handleChangeRegister}
                  value={inputRegister.first_name}
                  className={`py-2 w-full text-base sm:text-sm text-gray-900 bg-transparent ${
                    error?.includes('first_name')
                      ? 'border-0 border-b-2 border-auctionicRed'
                      : 'border-0 border-b-2 border-gray-300'
                  } appearance-none focus:outline-none focus:ring-0 focus:border-black peer required`}
                />
                {error?.includes('first_name') ? (
                  <p className='text-xs text-red-500 absolute'>
                    {t('register.registerAs.firstNameRequired')}
                  </p>
                ) : (
                  ''
                )}
              </div>
              <div className='block'>
                <label className='pr-2 ' title={t('reqField.requiredField')}>{t('register.surname')}*</label>
                <input
                  type='text'
                  name='last_name'
                  value={inputRegister.last_name}
                  onChange={handleChangeRegister}
                  className={` py-2 w-full text-base sm:text-sm text-gray-900 bg-transparent ${
                    error?.includes('last_name')
                      ? 'border-0 border-b-2 border-auctionicRed'
                      : 'border-0 border-b-2 border-gray-300'
                  } appearance-none  focus:outline-none focus:ring-0 focus:border-black peer required `}
                />
                {error?.includes('last_name') ? (
                  <p className='text-xs text-red-500 absolute '>
                    {t('register.registerAs.lastNameRequired')}
                  </p>
                ) : (
                  ''
                )}
              </div>
            </div>
            {/* password section */}
            <div className='pt-8 sm:pt-0 pl-0 sm:pl-[100px]'>
              <div className='space-y-8 sm:space-y-5'>
                <div className='block'>
                  <label className='pr-2 ' title={t('reqField.requiredField')}>E-mail*</label>
                  <input
                    type='email'
                    name='email'
                    value={inputRegister.email}
                    onChange={handleEmailFormat}
                    className={`py-2 w-full text-base sm:text-sm text-gray-900 bg-transparent ${
                      error?.includes('email')
                        ? 'border-0 border-b-2 border-auctionicRed'
                        : 'border-0 border-b-2 border-gray-300'
                    } appearance-none  focus:outline-none focus:ring-0 focus:border-black peer required `}
                  />
                  {error?.includes('email') || emailErrorMessage ? (
                    <p className='text-xs text-red-500 absolute'>
                      {t('register.registerAs.emailRequired')}
                    </p>
                  ) : (
                    ''
                  )}
                </div>
                <div className='block'>
                  <label className='pr-2 '
                    onMouseEnter={() => setIsShown(true)}
                    onMouseLeave={() => setIsShown(false)}>{t('register.password')}*
                  </label>
                  {isShown && (
                    <div className='absolute text-auctionicRed text-xs bg-white pr-2'>
                      {t('reqField.requiredFieldPassword1')}<br/>
                      {t('reqField.requiredFieldPassword2')}<br/>
                      {t('reqField.requiredFieldPassword3')}
                    </div>
                  )}
                  <input
                    type={passwordShow ? 'text' : 'password'}
                    name='password'
                    onFocus={() => setPasswordFocus(true)}
                    onBlur={() => setPasswordFocus(false)}
                    value={inputRegister.password}
                    onChange={handleChangeRegister}
                    className={` py-2 w-full text-base sm:text-sm text-gray-900 bg-transparent ${
                      error?.includes('password')
                        ? 'border-0 border-b-2 border-auctionicRed'
                        : 'border-0 border-b-2 border-gray-300'
                    } appearance-none  focus:outline-none focus:ring-0 focus:border-black peer required `}
                  />
                  {passwordShow ? (
                    <button onClick={handlePasswordShow}>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth='1.5'
                        stroke='currentColor'
                        className='w-5 h-5 absolute -mt-3 -ml-6'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z'
                        />
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
                        />
                      </svg>
                    </button>
                  ) : (
                    <button onClick={handlePasswordShow}>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth='1.5'
                        stroke='currentColor'
                        className='w-5 h-5 absolute -mt-3 -ml-6'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88'
                        />
                      </svg>
                    </button>
                  )}
                  {error?.includes('password') ? (
                    <p className='text-xs text-red-500 absolute'>
                      {t('register.registerAs.passwordFill')}
                    </p>
                  ) : (
                    ''
                  )}
                </div>
                <StrengthBar1
                  password={inputRegister.password}
                  onChangeScore={onChangeScore}
                  minLength={8}
                  scoreWords={passwordMeter}
                  shortScoreWord={t('register.tooShortPassword')}
                />
                {passwordFocus && score < 3 &&
                <div className='absolute text-auctionicRed text-xs bg-white pr-2'>
                  {t('reqField.requiredFieldPassword1')}<br/>
                  {t('reqField.requiredFieldPassword2')}<br/>
                  {t('reqField.requiredFieldPassword3')}
                </div>}
                <div className='block '>
                  <label className='pr-2 ' title={t('reqField.requiredField')}>
                    {t('register.confirmPassword')}*
                  </label>
                  <input
                    type={passwordShow ? 'text' : 'password'}
                    name='confirm_password'
                    onChange={(e) => {
                      setPasswordConfirm(e.target.value);
                    }}
                    className=' py-2 w-full text-base sm:text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-black peer required '
                  />
                  {inputRegister.password !== passwordConfirm && (
                    <p className='text-red-500 text-xs pt-3 absolute'>
                      {t('register.passwordMatch')}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='px-4 sm:px-[30px] pb-4 sm:pb-[34px] pt-4 sm:pt-[50px] flex justify-between'>
            <button
              onClick={() => {
                setPage((currPage: number) => currPage - 1);
                setClickIndividual(false);
                setInputRegister({} as UserInput);
              }}
              className='bg-white hover:bg-gray-200 px-2 py-2 mt-3 rounded-md border-2 border-black transition-all ease-in-out duration-150'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-6 w-6 '
                fill='none'
                viewBox='0 0 24 24'
                stroke='black'
              >
                <path d='M10 19l-7-7m0 0l7-7m-7 7h18' />
              </svg>
            </button>
            <div
              id='bottomNavigation'
              className='w-full h-full mt-8 text-base flex justify-center  space-x-6 sm:space-x-16'
            >
              <div className='bg-gray-900 border border-black text-center  w-[15%]'></div>
              <div className='bg-white border border-gray-300 text-center   w-[15%]  '></div>
              <div className='bg-white border border-gray-300   text-center  w-[15%] '></div>
            </div>
            <button
              onClick={handleNext}
              disabled={inputRegister.password !== passwordConfirm}
              className='bg-gray-900 hover:bg-gray-700 hover:scale-105 px-2 py-2 mt-3 rounded-md border-2 border-black transition-all ease-in-out duration-150 '
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-6 w-6'
                fill='none'
                viewBox='0 0 24 24'
                stroke='white'
              >
                <path d='M14 5l7 7m0 0l-7 7m7-7H3' />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export { RegisterAs };
