import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface Props {
    title: string;
    image: string;
    size_rooms: string;
    living_space: string;
    address: string;
    id: number;
}

export const MortgagePropertyCard = (props : Props) => {
  const { t } = useTranslation();
  // const { state } = useLocation() as any;
  const navigate = useNavigate();
  window.scrollTo(0, 0);
  //
  // const handleNavigation = (id: number) => {
  //   if (state.type === 'auction') {
  //     navigate(`/property/auction/details/${id}`);
  //   }
  //   else if (state.type === 'rent') {
  //     navigate(`/property/rent/details/${id}`);
  //   }
  //   else if (state.type === 'sale') {
  //     navigate(`/property/sale/details/${id}`);
  //   }
  // };
  return (
    <>
      <div
        className='mortgage cursor-pointer mt-10 sm:mt-7 flex flex-col items-center bg-white rounded-lg  md:flex-row'
      >
        <img
          className='object-cover w-full h-96 rounded-t-lg md:h-auto md:w-48 md:rounded-none md:rounded-l-lg'
          src={props.image}
          alt=''
        />
        <div className='flex flex-col justify-between leading-normal px-3'>
          <p className='font-avenir55 font-semibold pb-5 sm:pb-2 text-md text-darkGray'>
            {props.title}
          </p>
          <h5 className='mb-2 pt-5 sm:pt-0 text-xl font-press-start font-bold tracking-tight text-gray-900'>
            {props.size_rooms} rooms, {props.living_space} m2
          </h5>
          <p className=' font-normal pb-5 sm:pb-0 font-secondfont text-sm text-darkGray'>
            {props.address}
          </p>
        </div>
      </div>
      <div
        className='py-10 text-center sm:text-right'
      >
        <button className='hover:text-red-500' onClick={() => navigate(-1)}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 512 512'
            fill='black'
            className='w-6 h-6 absolute'
          >
            <path d='M9.375 233.4l128-128c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L109.3 224H480c17.69 0 32 14.31 32 32s-14.31 32-32 32H109.3l73.38 73.38c12.5 12.5 12.5 32.75 0 45.25c-12.49 12.49-32.74 12.51-45.25 0l-128-128C-3.125 266.1-3.125 245.9 9.375 233.4z' />
          </svg>
          <p className='pl-8 '>{t('mortgage.backProperty')}</p>
        </button>
      </div>
    </>

  );
};
