import React, { useState } from 'react';
import Logo from '../../images/logoRed.svg';
import OtpInput from 'react-otp-input';
import '../LisitingItem/ListingItem.css';
import { useTranslation } from 'react-i18next';
import { utils } from '../../helpers/utils';
import { userServices } from '../../services/userServices';
import { SpinnerLoading } from '../LoadingTest/SpinnerLoading';

interface Props {
  handleDeclineBid: any;
  handleRequestDeclineBid: any;
  handleConfirmDeclineBid: any;
  form: any;
  setDataInput: any;
  propertyDetails: any;
  buttonLoader1: any;
}

const TermsAndConditions = (props: any) => {
  const { handleRequestDeclineBid,buttonLoader } = props;
  const { t } = useTranslation();
  return (
    <div className=''>
      <div className='bg-white text-darkGray font-press-start rounded-lg '>
        <h1 className='text-2xl text-center pt-4'>
          {t('propertyDetails.terms')}
        </h1>
        <hr className='' />
        <div className='mx-5 my-4'>
          <div className='text-left '>
            <p>
              {t('propertyDetails.declineBidTermsAndConditions')}
            </p>
          </div>
          <div className='space-y-3 mt-5 '>
            <div className='text-left  font-semibold text-lg'>
              <a href='/terms/conditions' target='_blank' className='text-auctionicRed'>
                {t('propertyDetails.termsAndConditions')}
              </a>
            </div>
            <div className='text-left text-auctionicRed font-semibold text-lg'>
              <a href='/privacy/policy' target='_blank' className='text-auctionicRed'>
                {t('propertyDetails.privacyPolicy')}
              </a>
            </div>
            <div className='text-xs mt-5'>
              <p>{t('propertyDetails.continueTextTerms')}.</p>
            </div>
          </div>
        </div>
      </div>
      <hr className='border border-white my-3 ' />
      <div className='flex justify-end '>
        <button
          className='bg-white rounded-lg px-4 py-4'
          onClick={handleRequestDeclineBid}
        >{buttonLoader ? <SpinnerLoading/>:
            <svg
              width='16'
              height='14'
              viewBox='0 0 16 14'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M9.97115 0.278417L15.7347 6.32784C16.0884 6.69906 16.0884 7.30094 15.7347 7.67216L9.97115 13.7216C9.61747 14.0928 9.04404 14.0928 8.69035 13.7216C8.33667 13.3504 8.33667 12.7485 8.69035 12.3773L12.9079 7.95058H0V6.04942H12.9079L8.69035 1.62273C8.33667 1.25151 8.33667 0.64964 8.69035 0.278417C9.04404 -0.0928058 9.61747 -0.0928058 9.97115 0.278417Z'
                fill='black'
              />
            </svg>}
        </button>
      </div>
    </div>
  );
};

const ConfirmPhoneCode = (props: any) => {
  const { setDataInput, handleConfirmAcceptBid, propertyDetails,buttonLoader } = props;
  const [otpBid, setOtpBid] = useState('');
  const handleOtpChange = (e: any) => {
    setOtpBid(e);
    setDataInput((inputData: any) => ({ ...inputData, code: e }));
  };
  const { t } = useTranslation();

  const resendCode = () => {
    userServices
      .resendVerification()
      .then((response) => {
        // console.log(response);
      })
      .catch((error: any) => {
        // console.log(error);
      });
  };

  return (
    <div className='transition-all ease-in-out duration-150 font-avenir55'>
      <div className='text-[24px]'>{t('propertyDetails.bidInfo')}</div>
      <div className='pt-2 sm:pt-[49px]'>
        <p className='text-[24px]'>
          CHF {utils.numberWithComma(propertyDetails.current_bid)}
        </p>
      </div>
      <div className='text-[14px] mt-4'>
        {t('propertyDetails.verificationSent')}
        <br />
        {t('propertyDetails.code')}
      </div>
      <div className='flex flex-row justify-start text-center text-white mt-5'>
        <OtpInput
          value={otpBid}
          onChange={handleOtpChange}
          isInputNum={true}
          numInputs={6}
          inputStyle={{
            width: '2.5rem',
            height: '2.5rem',
            margin: '0 0.7rem',
            fontSize: '2rem',
            borderRadius: 4,
            border: '1px solid rgba(0,0,0,0.3)',
            color: 'black',
          }}
          shouldAutoFocus={true}
        />
      </div>
      <div className='flex justify-start mt-2'>
        <button
          className='text-white font-light flex space-x-3'
          onClick={resendCode}
        >
          <span>{t('propertyDetails.resendVerification')}</span>
          <svg
            viewBox='0 0 512 512'
            xmlns='http://www.w3.org/2000/svg'
            className='h-4 w-4 self-center '
            fill='#FFFFFF'
          >
            <path d='M480 256c0 123.4-100.5 223.9-223.9 223.9c-48.86 0-95.19-15.58-134.2-44.86c-14.14-10.59-17-30.66-6.391-44.81c10.61-14.09 30.69-16.97 44.8-6.375c27.84 20.91 61 31.94 95.89 31.94C344.3 415.8 416 344.1 416 256s-71.67-159.8-159.8-159.8C205.9 96.22 158.6 120.3 128.6 160H192c17.67 0 32 14.31 32 32S209.7 224 192 224H48c-17.67 0-32-14.31-32-32V48c0-17.69 14.33-32 32-32s32 14.31 32 32v70.23C122.1 64.58 186.1 32.11 256.1 32.11C379.5 32.11 480 132.6 480 256z' />
          </svg>
        </button>
      </div>
      <hr className='border border-white my-3 ' />
      <div className='flex justify-start'>
        <button
          className='bg-white rounded-lg px-3 py-3 text-darkGray flex'
          onClick={handleConfirmAcceptBid}
        >
          {t('propertyDetails.continueButton')}
          {buttonLoader ? <SpinnerLoading/>:
            <svg
              className='self-center ml-3'
              width='16'
              height='14'
              viewBox='0 0 16 14'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M9.97115 0.278417L15.7347 6.32784C16.0884 6.69906 16.0884 7.30094 15.7347 7.67216L9.97115 13.7216C9.61747 14.0928 9.04404 14.0928 8.69035 13.7216C8.33667 13.3504 8.33667 12.7485 8.69035 12.3773L12.9079 7.95058H0V6.04942H12.9079L8.69035 1.62273C8.33667 1.25151 8.33667 0.64964 8.69035 0.278417C9.04404 -0.0928058 9.61747 -0.0928058 9.97115 0.278417Z'
                fill='black'
              />
            </svg>}
        </button>
      </div>
    </div>
  );
};
const Success = (props: any) => {
  const { handleDeclineBid } = props;
  const { t } = useTranslation();
  return (
    <div className='bg-white text-darkGray font-avenir55 rounded-lg sm:my-4 py-px'>
      <div className='self-center'>
        <div className='flex justify-center'>
          <img src={Logo} alt='Logo' className='mt-[49px]' />
        </div>
        <div className='block space-y-[14px] mt-[98px] px-16'>
          <p className='text-[20px] text-center'>
            {t('propertyDetails.messageDecline')}
          </p>
        </div>
        <div className='mt-[103px] mb-[41px] flex justify-center'>
          <button
            className='px-12 py-2  bg-darkGray text-white text-[14px] rounded-md'
            onClick={handleDeclineBid}
          >
            {t('propertyDetails.closeButton')}
          </button>
        </div>
      </div>
    </div>
  );
};

const Error = (props: any) => {
  const { handleOpenBid } = props;
  const { t } = useTranslation();
  return (
    <div className='bg-white text-darkGray font-avenir55 rounded-lg sm:my-4 py-px max-w-sm'>
      <div className='self-center'>
        <div className='flex justify-center'>
          <img src={Logo} alt='Logo' className='mt-[49px]' />
        </div>
        <div className='block space-y-[14px] mt-[98px] px-16'>
          <p className='text-[20px] text-center'>
            {t('propertyDetails.declineBidError')}
          </p>
        </div>
        <div className='mt-[103px] mb-[41px] flex justify-center'>
          <button
            className='px-12 py-2  bg-darkGray text-white text-[14px] rounded-md'
            onClick={handleOpenBid}
          >
            {t('propertyDetails.closeButton')}
          </button>
        </div>
      </div>
    </div>
  );
};
const DeclineBid = (props: Props) => {
  const {
    handleRequestDeclineBid,
    handleConfirmDeclineBid,
    handleDeclineBid,
    form,
    setDataInput,
    propertyDetails,
    buttonLoader1,
  } = props;
  return (
    <div className='relative z-10'>
      <div className='fixed inset-0 bg-black bg-opacity-95 transition-opacity '></div>
      <div
        className='fixed z-10 inset-0 overflow-y-auto transition delay-300 duration-300 ease-in-out'
        onClick={handleDeclineBid}
      >
        <div className='flex justify-center text-center sm:mx-[208px] sm:mt-[137px]'>
          <div
            className='grid grid-cols-1 text-white my-2 mx-2 sm:mx-0 sm:my-0'
            onClick={(e) => e.stopPropagation()}
          >
            <div className='mt-3 sm:mt-0'>
              <div className='flex justify-center text-left'>
                <div className=''>
                  {form === 0 ? (
                    <TermsAndConditions
                      handleRequestDeclineBid={handleRequestDeclineBid}
                      buttonLoader={buttonLoader1}
                    />
                  ) : form === 100 ? (
                    <Error handleDeclineBid={handleDeclineBid} />
                  ) : form === 1 ? (
                    <ConfirmPhoneCode
                      handleConfirmAcceptBid={handleConfirmDeclineBid}
                      setDataInput={setDataInput}
                      propertyDetails={propertyDetails}
                      buttonLoader={buttonLoader1}
                    />
                  ) : (
                    <Success handleDeclineBid={handleDeclineBid} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { DeclineBid };
