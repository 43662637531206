import axios from 'axios';

export const baseUrl = 'https://auctionic.ch/';
// server link http://142.132.239.1:8888/
// http://192.168.1.108:5000/
// https://api.auctionic.ch/



export const auctionicApiWa = axios.create({
  baseURL: `${baseUrl}api`,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const auctionicApi = axios.create({
  baseURL: `${baseUrl}api`,
  headers: {
    'Content-Type': 'application/json',
  },
});

auctionicApi.interceptors.response.use((response) => {
  return response;
}, async function (error) {
  const originalRequest = error.config;
  if (error.response?.status === (401 || 422) && !originalRequest._retry && !originalRequest.url.includes('login')) {
    originalRequest._retry = true;
    try {
      const refresh_token = localStorage.getItem('refresh_token');
      auctionicApi.defaults.headers.common['Authorization'] = `Bearer ${refresh_token}`;
      const res = await auctionicApi.post(`${baseUrl}api/user/refresh`);
      const { access_token, } = res.data;
      auctionicApi.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
      originalRequest.headers['Authorization'] = `Bearer ${access_token}`;
      localStorage.setItem('access_token', access_token);
      return auctionicApi(originalRequest);
    } catch (_error: any) {
      return Promise.reject(_error);
    }
  }
  return Promise.reject(error);
});
