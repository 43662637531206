import { t } from 'i18next';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const PropertyNotFound = (props: any) => {
  const navigate = useNavigate();
  return (
    <div className='flex justify-center my-28'>
      <div className=''>
        <div className='flex justify-center '>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth='1.5'
            stroke='#FF002B'
            className='w-48 h-48 '
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205l3 1m1.5.5l-1.5-.5M6.75 7.364V3h-3v18m3-13.636l10.5-3.819'
            />
          </svg>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='#FF002B'
            viewBox='0 0 320 512'
            strokeWidth='0.5'
            stroke='#FF002B'
            className='w-20 h-20 absolute ml-[180px] -mt-10'
          >
            <path d='M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z' />
          </svg>
        </div>
        <div className='flex justify-center'>
          <p className='mt-10 font-bold text-center text-lg'>
            {props.name}
            {/* {t('propertyListing.noProperty')} */}
          </p>
        </div>
        <div className='flex justify-center mt-6'>
          <button
            onClick={() => navigate('/')}
            className='flex hover:text-white hover:bg-auctionicRed transition-all ease-in-out duration-300 p-2 rounded-lg space-x-2 '
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='currentColor'
              className='w-6 h-6 '
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
              />
            </svg>
            {t('propertyListing.goBackToLanding')}
          </button>
        </div>
      </div>
    </div>
  );
};

export { PropertyNotFound };
