import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

interface Props {
  handleBack: any;
  handleNext: any;
  input: any;
  setInput: any;
}
const Passport = (props: Props) => {
  const { handleBack, handleNext, input, setInput } = props;
  const { t } = useTranslation();
  const [images, setImages] = useState<Array<File>>([]);
  const [prevImages, setPrevImages] = useState<Array<File>>([]);
  const [characters, updateCharacters] = useState(prevImages);

  useEffect(() => {
    updateCharacters(prevImages);
  },[prevImages]);

  const handleImageChange = (e: any) => {
    const { files } = e.target;
    const img = Array.from(files);
    const imagesArray = img.map((img: any) => {
      return URL.createObjectURL(img);
    });
    setPrevImages((previousImages: any) => previousImages.concat(imagesArray));
    setImages((previousImages: any) => previousImages.concat(img));
    setInput({
      ...input,
      images: images,
    });
  };

  const handleImageDelete = (image: any) => {
    const previewImages = prevImages.filter(
      (item: any, index: any) => index !== image
    );
    const mainImages = images.filter(
      (item: any, index: any) => index !== image
    );
    setPrevImages(previewImages);
    setImages(mainImages);
  };

  useEffect(() => {
    setInput({
      ...input,
      images: images,
    });
  }, [images]);
  return (
    <form className='' onSubmit={handleNext}>
      <div className='space-y-4'>
        <p className='font-bold'>
          {t('debtCollection.identityCheck.passportInsidePages')}
        </p>
        <p>{t('debtCollection.identityCheck.passportText')}</p>
      </div>
      <section className='grid sm:grid-cols-2'>
        <div>
          {input.images == 0 ?
            <div className='border-2 border-dashed border-gray-600 flex justify-center relative py-16 hover:bg-gray-100'>
              <div className='block space-y-3'>
                <div className='flex justify-center'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth='1.5'
                    stroke='currentColor'
                    className='w-10 h-10'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z'
                    />
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z'
                    />
                  </svg>
                </div>
                <p className='font-bold'>
                  {t('debtCollection.identityCheck.selectImage')}
                </p>
              </div>
              <input
                type='file'
                className='cursor-pointer opacity-0 absolute w-full h-full bg-blue-100 inset-0 '
                onChange={handleImageChange}
                required
              />
            </div>
            :
            <DragDropContext onDragEnd={() => console.log('frontimage')}>
              <Droppable droppableId="characters" direction='horizontal'>
                {(provided) => (
                  <div className="characters grid grid-cols-1 sm:grid-cols-4 gap-4  w-full"
                    {...provided.droppableProps} ref={provided.innerRef}>
                    {characters &&
                              characters.slice(0, 5).map((image: any, index: number) => {
                                return (
                                  <Draggable key={index} draggableId={index.toLocaleString()} index={index}>
                                    {(provided) => (
                                      <div key={index} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        <div key={index}>
                                          <div className='relative flex w-[420px]'>
                                            <img
                                              src={image}
                                              alt='upload'
                                              className='h-[204px] w-[550px] rounded-lg shadow-md shadow-black'
                                            />
                                            <button
                                              className='absolute p-2 rounded-br-lg rounded-tl-lg bg-white hover:bg-auctionicRed hover:text-white hover:scale-105 shadow-md shadow-black transition-all ease-in-out duration-150'
                                              onClick={() => handleImageDelete(index)}
                                            >
                                              <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                fill='none'
                                                viewBox='0 0 24 24'
                                                strokeWidth='1.5'
                                                stroke='currentColor'
                                                className='w-6 h-6'
                                              >
                                                <path
                                                  strokeLinecap='round'
                                                  strokeLinejoin='round'
                                                  d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
                                                />
                                              </svg>
                                            </button>
                                            {index == 0 ?
                                              <button
                                                className='absolute bottom-0 right-0 h-8 w-[130px] rounded-br-lg rounded-tl-lg text-white bg-auctionicRed shadow-md shadow-black'
                                              >Passport view
                                              </button>
                                              : ''}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              })
                    }
                  </div>
                )}
              </Droppable>
            </DragDropContext>}
        </div>
      </section>
      <hr className='mt-6' />
      <div className='flex justify-between mt-5'>
        <button
          className='text-darkGray shadow-md flex  hover:bg-gray-100 rounded-lg self-center px-4 py-2 font-bold'
          onClick={handleBack}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth='2'
            stroke='currentColor'
            className='w-5 h-5'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M15.75 19.5L8.25 12l7.5-7.5'
            />
          </svg>
          <p className=''>{t('debtCollection.back')}</p>
        </button>
        <button
          type='submit'
          className='px-4 py-2 bg-auctionicRed text-white font-bold rounded-md cursor-pointer hover:bg-darkGray transition-all ease-in-out duration-100'
        >
          {t('debtCollection.next')}
        </button>
      </div>
    </form>
  );
};

export { Passport };
