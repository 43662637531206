const numberOfRooms = [
  {
    id: 0,
    room: 0,
  },
  {
    id: 1,
    room: 1.0,
  },
  {
    id: 2,
    room: 1.5,
  },
  {
    id: 3,
    room: 2.0,
  },
  {
    id: 4,
    room: 2.5,
  },
  {
    id: 5,
    room: 3.0,
  },
  {
    id: 6,
    room: 3.5,
  },
  {
    id: 7,
    room: 4.0,
  },
  {
    id: 8,
    room: 4.5,
  },
  {
    id: 9,
    room: 5.0,
  },
  {
    id: 10,
    room: 5.5,
  },
  {
    id: 11,
    room: 6.0,
  },
  {
    id: 12,
    room: 6.5,
  },
  {
    id: 13,
    room: 7.0,
  },
  {
    id: 14,
    room: 7.5,
  },
  {
    id: 15,
    room: 8.0,
  },
  {
    id: 16,
    room: 8.5,
  },
  {
    id: 17,
    room: 9.0,
  },
  {
    id: 18,
    room: 9.5,
  },
  {
    id: 19,
    room: 10.0,
  },
  {
    id: 20,
    room: 10.5,
  },
  {
    id: 21,
    room: 11.0,
  },
  {
    id: 22,
    room: 11.5,
  },
  {
    id: 23,
    room: 12.0,
  },
  {
    id: 24,
    room: 12.5,
  },
  {
    id: 25,
    room: 13.0,
  },
  {
    id: 26,
    room: 13.5,
  },
  {
    id: 27,
    room: 14.0,
  },
  {
    id: 28,
    room: 14.5,
  },
  {
    id: 29,
    room: 15.0,
  },
  {
    id: 30,
    room: 15.5,
  },
  {
    id: 31,
    room: 16.0,
  },
  {
    id: 32,
    room: 16.5,
  },
  {
    id: 33,
    room: 17.0,
  },
  {
    id: 34,
    room: 17.5,
  },
  {
    id: 35,
    room: 18.0,
  },
  {
    id: 36,
    room: 18.5,
  },
  {
    id: 37,
    room: 19.0,
  },
  {
    id: 38,
    room: 19.5,
  },
  {
    id: 39,
    room: 20.0,
  },
];
export { numberOfRooms };
