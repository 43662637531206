import React from 'react';
import { useTranslation } from 'react-i18next';
import img from '../../images/ZurichInsuranceIMG.webp';
import img1 from '../../images/scaleZurich.svg';
import img2 from '../../images/depositZurich.svg';
import img3 from '../../images/financeZurich.svg';

const RentalDeposit = () => {
  const { t } = useTranslation();
  return (
    <div className='my-10 sm:my-[115px]'>
      <div>
        <div className='text-xl sm:text-[45px] text-center'>{t('services.rentalDeposit')}</div>
        <div className='sm:text-[30px] text-center pb-5'>Zurich Insurance Company Ltd</div>
        <div className='sm:flex items-center '>
          <img src={img} alt='' className='max-h-[260px] border rounded-[130px] self-center' />
          <p className='pl-5'>{t('services.rentalDepositDsc')}</p>
        </div>
        <div className='flex grid-cols pt-20 justify-between'>
          <div className='items-center'>
            <img src={img1} alt='' className='max-h-[150px] min-h-[150px] mx-auto pb-3' />
            <p className='text-[15px] text-center'>{t('services.rentalDepositFirstIcon')}</p>
          </div>
          <div className='self-center'>
            <img src={img2} alt='' className='max-h-[150px] min-h-[150px] mx-auto pb-3' />
            <p className='text-[15px] text-center'>{t('services.rentalDepositSecondIcon')}</p>
          </div>
          <div className='items-center'>
            <img src={img3} alt='' className='max-h-[150px] min-h-[150px] mx-auto pb-3' />
            <p className='text-[15px] text-center'>{t('services.rentalDepositThirdIcon')}</p>
          </div>
        </div>
        <div className='pt-20 text-center pb-20'>
          <h1 className='text-[25px] pb-3'>{t('services.rentalDepositMoreDetails')}</h1>
          <p className='pb-10'>{t('services.rentalDepositDsc2')}</p>
          <a href="https://www.zurich.ch/" className="bg-auctionicRed text-white font-bold py-2 px-4 rounded">
            {t('services.rentalDepositVisitButton')}
          </a>
        </div>
      </div>
    </div>
  );
};

export { RentalDeposit };
