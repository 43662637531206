import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
interface Props {
  setPaymentTypeData: any;
  handlePaymentDataChange: any;
  error: any;
  setError: any;
  paymentTypeData: any;
  handleBuyNowChecked: any;
  buyNowChecked: any;
  setBuyNowChecked:any;
}

const Auction = (props: Props) => {
  const {
    handlePaymentDataChange,
    error,
    buyNowChecked,
    handleBuyNowChecked,
    paymentTypeData,
    setBuyNowChecked
  } = props;
  const { t } = useTranslation();
  const [formattedStartingPrice, setFormattedStartingPrice] = useState('');
  const [formattedMinIncrAmount, setFormattedMinIncrAmount] = useState('');
  const [formattedBuyNowPrice, setFormattedBuyNowPrice] = useState('');
  const [tempPaymentData, setTempPaymentData] = useState(paymentTypeData);

  const handlePaymentDataChange1 = (e: any) => {
    const { name, value } = e.target;
    if (name == 'starting_price') {
      const formattedValue = (Number(value.replace(/\D/g, '')) || '')
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, '\'');
      const unformattedValue = Number(value.replace(/\D/g, '')) || '';
      props.setPaymentTypeData((prevState: any) => ({
        ...prevState,
        starting_price: unformattedValue,
      }));
      setFormattedStartingPrice(formattedValue);
    }
    if (name == 'minimum_increasing_amount') {
      const formattedValue = (Number(value.replace(/\D/g, '')) || '')
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, '\'');
      const unformattedValue = Number(value.replace(/\D/g, '')) || '';
      props.setPaymentTypeData((prevState: any) => ({
        ...prevState,
        minimum_increasing_amount: unformattedValue,
      }));
      setFormattedMinIncrAmount(formattedValue);
    }
    if (name == 'buy_now_price') {
      const formattedValue = (Number(value.replace(/\D/g, '')) || '')
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, '\'');
      const unformattedValue = Number(value.replace(/\D/g, '')) || '';
      props.setPaymentTypeData((prevState: any) => ({
        ...prevState,
        buy_now_price: unformattedValue,
      }));
      setFormattedBuyNowPrice(formattedValue);
    }
  };

  useEffect(() => {
    if (paymentTypeData.type ==='auction' && paymentTypeData.starting_price != null)  {
      const value = paymentTypeData.starting_price.toString();
      const formattedValue = (Number(value.replace(/\D/g, '')) || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\'');
      setFormattedStartingPrice(formattedValue);
      setTempPaymentData(paymentTypeData);

    }
    if (paymentTypeData.type ==='auction' && paymentTypeData.minimum_increasing_amount != null) {
      const value = paymentTypeData.minimum_increasing_amount.toString();
      const formattedValue = (Number(value.replace(/\D/g, '')) || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\'');
      setFormattedMinIncrAmount(formattedValue);
    }
    if (paymentTypeData.type ==='auction' && paymentTypeData.buy_now === true) {
      setBuyNowChecked(true);
    }
    if (paymentTypeData.type ==='auction' && paymentTypeData.buy_now ===true && paymentTypeData.buy_now_price != null) {
      const value = paymentTypeData.buy_now_price.toString();
      const formattedValue = (Number(value.replace(/\D/g, '')) || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\'');
      setFormattedBuyNowPrice(formattedValue);
    }
  }, []);
  useEffect(()=>{
    props.setPaymentTypeData(tempPaymentData);
  },[tempPaymentData]);
  return (
    <div className='sm:py-12 py-2 font-press-start space-y-5'>
      <div className='sm:grid sm:grid-cols-1 font-press-start '>
        <label className='w-full self-center font-bold' title={t('reqField.requiredField')}>
          {t('listingItem.startDate')}*
        </label>
        <input
          value={paymentTypeData?.start_date != undefined ? paymentTypeData.start_date : ''}
          name='start_date'
          type='datetime-local'
          min={new Date().toISOString().slice(0, -8)}
          className={`form-control  block w-full sm:w-1/2 px-3 py-1 mt-3 text-base font-normal text-gray-700 bg-white bg-clip-padding ${
            error?.includes('start_date')
              ? 'border-2 border-solid border-auctionicRed'
              : 'border-2 border-solid border-gray-300'
          } focus:border-gray-800 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none w-full`}
          placeholder='Starting date'
          onChange={handlePaymentDataChange}
        />
        {error?.includes('start_date') ? (
          <p className='text-xs text-red-500 transition-all ease-in-out duration-200'>
            {t('listingItem.paymentForm.auction.startingDateRequired')}
          </p>
        ) : (
          ''
        )}
      </div>
      <div className='sm:grid sm:grid-cols-1 font-press-start'>
        <label className='w-full self-center font-bold' title={t('reqField.requiredField')}>
          {t('listingItem.startPrice')}*
        </label>
        <input
          name='starting_price'
          type='text'
          className={`form-control  block sm:w-1/2 w-full px-3 py-1.5 mt-3 text-base font-normal text-gray-700 bg-white bg-clip-padding ${
            error?.includes('starting_price') || error?.includes('starting_price_max')
              ? 'border-2 border-solid border-auctionicRed'
              : 'border-2 border-solid border-gray-300'
          } focus:border-gray-800 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none`}
          placeholder={`${t('listingItem.startingPrice')} (CHF)`}
          value={formattedStartingPrice}
          onChange={handlePaymentDataChange1}
          min={0}
        />
        {error?.includes('starting_price') ? (
          <p className='text-xs text-red-500  '>
            {t('listingItem.paymentForm.auction.startingPriceRequired')}
          </p>
        ) : (
          error?.includes('starting_price_max') && (
            <p className='text-xs text-red-500'>
              {t('listingItem.paymentForm.auction.priceOverMax')}
            </p>
          )
        )}
      </div>
      <div className='sm:grid sm:grid-cols-1 font-press-start'>
        <label className='w-full self-center font-bold' title={t('reqField.requiredField')}>
          {t('listingItem.minimum_increasing_amount')}*
        </label>
        <input
          name='minimum_increasing_amount'
          type='text'
          className={`form-control  block sm:w-1/2 w-full px-3 py-1.5 mt-3 text-base font-normal text-gray-700 bg-white bg-clip-padding ${
            error?.includes('minimum_increasing_amount')
              ? 'border-2 border-solid border-auctionicRed'
              : 'border-2 border-solid border-gray-300'
          } focus:border-gray-800 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none`}
          placeholder={`${t('listingItem.minimum_increasing_amount')} (CHF)`}
          value={formattedMinIncrAmount}
          onChange={handlePaymentDataChange1}
        />
        {error?.includes('minimum_increasing_amount') ? (
          <p className='text-xs text-red-500 transition-all ease-in-out duration-200'>
            {t('listingItem.paymentForm.auction.minimumIncreasingAmount')}
          </p>
        ) : (
          ''
        )}
      </div>
      <div className='flex justify-between'>
        <div className='text-[18px] mt-5'>
          <label className='labels font-bold' title={t('reqField.requiredField')}>
            {t('listingItem.buyNow')}
            <input
              // value={`${paymentTypeData.buy_now}`}
              type='checkbox'
              name='buy_now'
              className='h-6 w-6 absolute'
              onChange={handleBuyNowChecked}
              checked={paymentTypeData?.buy_now != undefined ? paymentTypeData.buy_now : false}
            />
            <span className='checks'></span>
          </label>
        </div>
      </div>
      {buyNowChecked ? (
        <div className=''>
          <label className='w-full self-center font-bold' title={t('reqField.requiredField')}>
            {t('listingItem.buyNowPrice')}*
          </label>
          <input
            type='text'
            name='buy_now_price'
            value={paymentTypeData != undefined ? formattedBuyNowPrice : '' }
            onChange={handlePaymentDataChange1}
            placeholder={`${t('listingItem.buyNowPrice')} (CHF)`}
            className={`form-control  block sm:w-1/2 px-3 py-1.5  mt-3 text-base font-normal text-gray-700 bg-white bg-clip-padding  ${
              error?.includes('buy_now') || error?.includes('buy_now_price') || error?.includes('buy_now_max')
                ? 'border-2 border-solid border-auctionicRed'
                : 'border-2 border-solid border-gray-300'
            } focus:border-gray-800 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none w-full`}
          />
          {error?.includes('buy_now') ? (
            <p className='text-xs text-red-500 py-1'>{t('listingItem.buyNowError1')}</p>
          ) : error.includes('buy_now_price') ? (
            <p className='text-xs text-red-500 py-1'>{t('listingItem.buyNowError2')}</p>
          ) :
            error?.includes('buy_now_max') ? (
              <p className='text-xs text-red-500'>
                {t('listingItem.paymentForm.auction.priceOverMax')}
              </p>
            ) : (
              ''
            )}
        </div>
      ) : null}
    </div>
  );
};

export { Auction };
