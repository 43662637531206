import React from 'react';
import Gif from '../../images/intro_loader.mp4';

interface Props {
    loading: any;
    setLoading: any;
}


function Loading1(props: Props) {
  const { loading, setLoading } = props;

  return (
    <div
      style={{
        backgroundColor: 'white',
        textAlign: 'center',
        height: '100vh',
        overflow: 'hidden',
        background: 'transparent',
        mixBlendMode: 'multiply',
      }}
    >
      {/* <div className='flex justify-center'>*/}
      {/*  <img src={logo} alt='' style={{ marginTop: '50px' }} />*/}
      {/* </div>*/}
      <video
        playsInline
        autoPlay
        muted
        className='h-full w-full bg-white bg-transparent bg-blend-color-burn'
        preload={'none'}
        onEnded={() => {
          setLoading(false);
          sessionStorage.setItem('first_landing', 'True');}}
      >
        {/* <source src='https://d2z63oo0n3zckk.cloudfront.net/banner_animated_compressed.mp4' />*/}
        <source src={Gif} />
      </video>
      {/* <img src={Gif} alt='' style={{ width: '420px', marginTop: '25px' }} />*/}
    </div>
  );
}
export { Loading1 };
