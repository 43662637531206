import React, { useState } from 'react';
import { SpinnerLoading } from '../../LoadingTest/SpinnerLoading';
import { Property, ReAdvertiseInput } from '../../../config/interfaces';
import { useTranslation } from 'react-i18next';
import Logo from '../../../images/logoRed.svg';
import { propertyServices } from '../../../services/propertyServices';
import { toast } from 'react-toastify';

interface Props {
    submitLoading: boolean;
    property: Property | undefined;
    reAdvertiseInput: ReAdvertiseInput;
    error: boolean;
  }


  

const PaymentStep = ({ submitLoading, property, reAdvertiseInput, error, }:Props) => {
  const { t } = useTranslation();
  const [invoiceLoader, setInvoiceLoader] = useState(false);


  const redirectToInvoice = () => {
    const newTab = window.open();
    setInvoiceLoader(true);
    // console.log('invoice', invoice);
    if (property) {
      propertyServices
        .generateInvoice(property?.payment?.id)
        .then((response) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          newTab.location.href = response?.data.link;
        })
        .catch(() => {
          setInvoiceLoader(false);
          // console.log('Failed to get the invoice', error);
        }).finally(() => {
          setInvoiceLoader(false);
        });
    }
    else {
      toast.error(t('errors.invoiceLinkError'), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  return (
    <div className='ml-4 h-[100%] sm:ml-[10%] mr-4 sm:mr-[10%] lg:ml-[15%] lg:mr-[15%] mt-10 lg:mt-[35px] mb-4 overflow-hidden'>
      <div className='grid grid-cols-2 space-y-4 border-b-2 border-white py-5 bg-gray-100 p-5 rounded-lg'>
        <div className='text-center self-center items-center p-4 mr-4'>
          <div className='self-center'>
            <p className=''>
              {t('dashboard.listingSection.reAdvertise.thankYou')}
            </p>
          </div>
          <div className='mt-4'>
            <img src={Logo} alt='logo' className='h-full w-full' />
          </div>
        </div>
        <div>
          <div className='self-center bg-white rounded-md
            lg:px-14 md:px-10 px-8
            lg:py-7 md:py-5 py-5'>
            <div className='space-y-3'>
              <div className='py-3 text-2xl'>
                {t('dashboard.listingSection.reAdvertise.status')}
              </div>
              <div>
                <div className='flex justify-between '>
                  <p>
                    {t('dashboard.listingSection.reAdvertise.creatingProperty')}
                  </p>
                  {submitLoading ? (
                    <SpinnerLoading />
                  ) : (
                    <div className=''>
                      {error ? (
                        <button className='bg-auctionicRed' disabled>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            strokeWidth='1.5'
                            stroke='white'
                            className='w-6 h-6'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                            />
                          </svg>
                        </button>
                      ) : (
                        <button className='bg-green-500 rounded-full' disabled>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            strokeWidth='1.5'
                            stroke='white'
                            className='w-6 h-6'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                            />
                          </svg>
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div>
                {reAdvertiseInput?.payment?.discount != '100' ? (
                  <div>
                    <div className='flex space-x-1'>
                      <p>{t('dashboard.listingSection.reAdvertise.pleaseCheck')}</p>
                    </div>
                    <div className='flex justify-center py-3'>
                      <button
                        className='bg-auctionicRed text-white px-4 py-2 rounded-md ease-in-out'
                        onClick={() => {redirectToInvoice();}}
                      >
                        {invoiceLoader ? (
                          <SpinnerLoading />
                        )
                          :(
                            t('dashboard.listingSection.reAdvertise.openInvoice')
                          )}
                      </button>
                    </div>
                  </div>
                ):''}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

};

export { PaymentStep };
