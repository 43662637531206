import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { utils } from '../../helpers/utils';
import { userServices } from '../../services';
import { propertyServices } from '../../services/propertyServices';
import { PropertyNotFound } from '../PropertyListing/PropertyNotFound';
import { toast, ToastContainer } from 'react-toastify';
import { SpinnerLoading } from '../LoadingTest/SpinnerLoading';
import { DashboardLoading } from '../LoadingTest/DashboardLoading';
import { utilsProperty } from '../PropertyDetails/utils';
import { ReAdvertiseModal } from './ReAdvertiseModal/ReAdvertiseModal';

interface Props {
  handleNavigation: any;
  userProperties: any;
  loading:any;
}

const Listings = (props: Props) => {
  const { handleNavigation, loading, userProperties } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [viewPropertyData, setViewPropertyData] = useState<Array<string>>([]);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [showReadvertisemntModal, setShowReAdvertiseModal] = useState<any>({});

  const getInvoice = (id: number) => {
    const newTab = window.open();
    userServices
      .getInvoiceByPaymentId(id)
      .then((response: any) => {
        // console.log('LINK RESPONES:', response);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        newTab.location.href = response?.data.link;
      })
      .catch((err:any) => {
        // console.log(err);
      });
  };

  const deactivateRent = (id: number) => {
    setButtonLoader(true);
    setIsDisabled(true);
    propertyServices
      .deactivateRentProperty(id)
      .then(() => {
        setButtonLoader(false);
        toast.success(t('errors.deactivateS'), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setTimeout(() => {
          navigate(0);
        }, 3000);
      })
      .catch((error) => {
        setButtonLoader(false);
        toast.error(t('errors.deactivateE'), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        // console.log('Error', error);
      });
  };

  const deactivateSale = (id: number) => {
    setButtonLoader(true);
    setIsDisabled(true);
    propertyServices
      .deactivateSaleProperty(id)
      .then(() => {
        setButtonLoader(false);
        toast.success(t('errors.deactivateS'), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setTimeout(() => {
          navigate(0);
        }, 3000);
      })
      .catch((error) => {
        setButtonLoader(false);
        toast.success(t('errors.deactivateE'), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        // console.log('Error', error);
      });
  };

  const reactivateSale = (id: number) => {
    setButtonLoader(true);
    setIsDisabled(true);
    propertyServices
      .reactivateSaleProperty(id)
      .then(() => {
        setButtonLoader(false);
        toast.success(t('errors.reactivateS'), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setTimeout(() => {
          navigate(0);
        }, 3000);
      })
      .catch((error) => {
        setButtonLoader(false);
        toast.success(t('errors.reactivateE'), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        // console.log('Error', error);
      });
  };

  const reactivateRent = (id: number) => {
    setButtonLoader(true);
    setIsDisabled(true);
    propertyServices
      .reactivateRentProperty(id)
      .then(() => {
        setButtonLoader(false);
        toast.success(t('errors.reactivateS'), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setTimeout(() => {
          navigate(0);
        }, 3000);
      })
      .catch(() => {
        setButtonLoader(false);
        toast.error(t('errors.reactivateE'), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };

  return (
    <div className='mt-10 sm:mt-0 mx-4 sm:mx-0 text-darkGray'>
      <ToastContainer />
      <div className='bg-[#F5F5F5] rounded-lg px-2 sm:px-10 py-[24px]'>
        <div className='text-[20px] font-press-start border-b border-darkGray text-center sm:text-justify'>
          {t('dashboard.currentListings')}
        </div>
        <div className='grid grid-cols-1 mt-3'>
          {loading ? <>
            <DashboardLoading />
            <DashboardLoading />
            <DashboardLoading />
          </>:
            <>
              {userProperties?.length !== 0 ? (
                <div className=''>
                  {Object.keys(showReadvertisemntModal).length > 0 &&  <ReAdvertiseModal propertyData={showReadvertisemntModal} onClose={() => setShowReAdvertiseModal({})}/>}
                  {userProperties?.map((dat: any) => {
                    return (
                      <div className='py-2' key={dat.id}>
                        <div
                          className={`block rounded-md cursor-pointer shadow border ${
                            viewPropertyData.includes(dat.id)
                              ? 'bg-darkGray  text-white transition-all ease-in-out duration-500'
                              : 'transition-all ease-in-out duration-100 hover:bg-white'
                          }`}
                        >
                          <div className='mx-4 sm:mx-0 block sm:flex py-5'>
                            <div className='grid grid-cols-1 sm:grid-cols-8 my-1 sm:my-0 sm:ml-3'>
                              <div className='flex justify-center max-h-[200px]'>
                                {dat.header_pic !== '' ?
                                  <img
                                    src={dat.header_pic}
                                    alt=''
                                    className='rounded-md sm:h-[100px] sm:w-[100px] object-contain sm:object-fill '
                                  />
                                  :
                                  <div className='sm:self-center bg-lightGray rounded-md'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-full h-full ">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"/>
                                    </svg>
                                  </div>
                                }
                              </div>
                              <div className='self-center space-y-1 sm:col-span-2 my-4 sm:my-0 ml-0 sm:ml-3'>
                                <p className='text-[15px] font-bold '>
                                  {utilsProperty.generateTitleList(dat,t)}
                                </p>
                                <p className='text-[15px] text-red-500 font-press-start'>
                                  {dat.type === 'auction' ? (
                                    <p>
                                  CHF {utils.numberWithComma(dat.current_bid)}{' '}
                                    </p>
                                  ) : (
                                    <p>CHF {utils.numberWithComma(dat.price)} </p>
                                  )}
                                </p>
                                {dat.title !== '' && (
                                  <p className='text-[15px] sm:w-[441px] h-[42px] font-press-start'>
                                    {dat.title}
                                  </p>
                                )}
                              </div>
                              <div className='self-center flex sm:justify-center'>
                                <p className='capitalize font-bold sm:text-center'>
                                  {t(`dashboard.listingSection.${dat.type}`)}
                                </p>
                              </div>
                              <div className='flex space-x-1 sm:justify-center sm:col-span-3'>
                                {dat.status === 1 ? (
                                  <span className='font-bold self-center'>
                                    {t(
                                      'dashboard.listingSection.paymentAndStartingDate'
                                    )}
                                  </span>
                                ) : dat.status === 2 ? (
                                  <span className='font-bold self-center'>
                                    {t('dashboard.listingSection.paymentDone')}
                                  </span>
                                ) : dat.status === 3 ? (
                                  <span className='font-bold self-center'>
                                    {t(
                                      'dashboard.listingSection.waitingForPayment'
                                    )}
                                  </span>
                                ) : dat.status === 4 ? (
                                  <span className='font-bold self-center animate-pulse text-green-600'>
                                    {t(
                                      'dashboard.listingSection.activeLiveAuction'
                                    )}
                                  </span>
                                ) : dat.status === 5 && (dat.type != 'auction') ? (
                                  <span onClick={ () => setShowReAdvertiseModal(dat) } className='font-bold self-center text-white hover:bg-auctionicRed bg-darkGray p-2 rounded-xl'>
                                    {t('dashboard.listingSection.adFinished')}
                                  </span>
                                ) : dat.status === 5 && (dat.type == 'auction') ? (
                                  <span onClick={ () => setShowReAdvertiseModal(dat) } className='font-bold self-center text-white hover:bg-auctionicRed bg-darkGray p-2 rounded-xl'>
                                    {t('dashboard.listingSection.adFinishedAuction')}
                                  </span>
                                ) : dat.status === 99 ? (
                                  <span className='font-bold self-center'>
                                    {t('dashboard.listingSection.adDeactivated')}
                                  </span>
                                ) : (
                                  <span className='font-thin self-center border-b'>
                                    {t('dashboard.listingSection.noStatusYet')}
                                  </span>
                                )}
                              </div>
                              <div className='self-center mt-7 sm:mt-0 flex justify-end sm:px-4'>
                                <button
                                  className={`px-3 py-3 text-white rounded-lg bg-white ${
                                    viewPropertyData.includes(dat.id)
                                      ? 'bg-white transition-all ease-in-out duration-500'
                                      : 'bg-darkGray'
                                  }`}
                                  onClick={() => {
                                    if (viewPropertyData.includes(dat.id)) {
                                      setViewPropertyData(
                                        viewPropertyData.filter(
                                          (_id: any) => dat.id !== _id
                                        )
                                      );
                                    } else {
                                      setViewPropertyData([
                                        ...viewPropertyData,
                                        dat.id,
                                      ]);
                                    }
                                  }}
                                >
                                  {viewPropertyData.includes(dat.id) ? (
                                    <svg
                                      xmlns='http://www.w3.org/2000/svg'
                                      fill='none'
                                      viewBox='0 0 24 24'
                                      strokeWidth='2.5'
                                      stroke='currentColor'
                                      className={`w-4 h-4 ${
                                        viewPropertyData.includes(dat.id) &&
                                    'text-darkGray transition-all ease-in-out duration-1000'
                                      }`}
                                    >
                                      <path
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        d='M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75'
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns='http://www.w3.org/2000/svg'
                                      fill='none'
                                      viewBox='0 0 24 24'
                                      strokeWidth='2.5'
                                      stroke='currentColor'
                                      className={`w-4 h-4 ${
                                        viewPropertyData.includes(dat.id) &&
                                    'text-darkGray  transition-all ease-in-out duration-1000'
                                      }`}
                                    >
                                      <path
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        d='M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75'
                                      />
                                    </svg>
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                          {viewPropertyData.includes(dat.id) && (
                            <div>
                              <div className='px-5 rounded-b-md text-white space-y-2 grid grid-cols-1 sm:grid-cols-3 divide-y sm:divide-y-0 divide-x-0 sm:divide-x '>
                                <div className=''>
                                  <div className=''>
                                    <p className='text-sm'>
                                      {t('dashboard.listingSection.address')}
                                    </p>
                                  </div>
                                  <div className='ml-2'>
                                    <div className='flex'>
                                      <span className='font-bold self-center'>
                                        {dat.property.address.street +
                                      ' ' +
                                      dat.property.address.street_no}
                                    ,
                                      </span>
                                    </div>
                                    <div className=''>
                                      <span className='font-bold self-center'>
                                        {dat.property.address.zip_code +
                                      ' ' +
                                      dat.property.address.city}
                                    ,
                                      </span>
                                    </div>
                                    <div className=''>
                                      <span className='font-bold self-center'>
                                        {dat.property.address.country}
                                      </span>
                                    </div>
                                  </div>
                                  <div className='flex space-x-1'>
                                    <p className='text-sm self-center'>
                                      {t('dashboard.listingSection.description')}
                                    </p>
                                    <span className='font-bold self-center text-ellipsis overflow-hidden whitespace-nowrap'>
                                      {dat.description}
                                    </span>
                                  </div>
                                </div>
                                <div className='space-y-3 sm:px-3'>
                                  <div className='space-y-3 mt-2 sm:mt-0'>
                                    <div className='space-y-3 sm:space-y-4'>
                                      <div className='flex'>
                                        <p className='text-sm place-self-center'>
                                          {t('dashboard.listingSection.startDate')}
                                        </p>
                                        <span className='font-bold self-center'>
                                          {utils.formatDate(dat.start_date, t)}
                                        </span>
                                      </div>
                                      <div className='flex'>
                                        <p className='text-sm place-self-center'>
                                          {t(
                                            'dashboard.listingSection.finishingDate'
                                          )}
                                        </p>
                                        <span className='font-bold self-center'>
                                          {utils.formatDate(dat.finishing_date, t)}
                                        </span>
                                      </div>
                                    </div>
                                    <div className='flex space-x-1'>
                                      <p className='text-sm place-self-center'>
                                        {t('dashboard.listingSection.type')}
                                      </p>
                                      <span className='font-bold self-center capitalize'>
                                        {dat.type}
                                      </span>
                                    </div>
                                    <div className='flex space-x-1'>
                                      <p className='text-sm place-self-center'>
                                        {t('dashboard.listingSection.tier')}
                                      </p>
                                      <span className='font-bold self-center capitalize'>
                                        {dat.payment.plan.tier}
                                      </span>
                                    </div>
                                    <div className='flex space-x-1'>
                                      <p className='text-sm place-self-center'>
                                        {t('dashboard.listingSection.duration')}
                                      </p>
                                      <span className='font-bold self-center'>
                                        {dat.payment.plan.duration}{' '}
                                        {t('dashboard.listingSection.days')}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className='sm:px-3'>
                                  <div className='my-2 sm:my-0 space-y-3'>
                                    <div className='flex space-x-1'>
                                      <p className='text-sm place-self-center'>
                                        {t(
                                          'dashboard.listingSection.paymentStatus'
                                        )}
                                      </p>
                                      <span
                                        className={`font-bold self-center px-2 py-1 rounded-lg text-sm ${
                                          dat.payment.status === 'PAID'? 'bg-[#00FFCD] text-darkGray ': dat.payment.status === 'DRAFT'? 'bg-orange-500 text-darkGray': dat.payment.status === 'PENDING'? 'bg-yellow-500 text-darkGray': dat.payment.status === 'CANCELED'? 'bg-auctionicRed text-white': ''
                                        }`}
                                      >
                                        {t(`dashboard.paymentStatus.${dat.payment.status}`)}
                                      </span>
                                    </div>
                                    { dat.payment.discount != 100 &&
                                      <div className='flex space-x-1'>
                                        <p className='text-sm place-self-center'>
                                          {t('dashboard.listingSection.paymentPrice')}
                                        </p>
                                        <span className='font-bold self-center bg-white px-2 py-1 text-darkGray rounded-lg'>
                                      CHF {dat?.payment?.price.toFixed(2)}
                                        </span>
                                      </div>
                                    }
                                    {dat.payment.method === 'card' && dat.payment.status !=='PAID' && dat.payment.status !=='CANCELED' && (
                                      <div className='flex justify-center'>
                                        <button
                                          className='bg-gray-50 px-3 py-1 rounded-md text-darkGray'
                                          onClick={() => getInvoice(dat.payment.id)}
                                        >
                                          {t('dashboard.listingSection.doPayment')}
                                        </button>
                                      </div>
                                    )}
                                    {dat.payment.method === 'bill' && dat.payment.invoice_issued !== false ? (
                                      <div className='flex justify-center'>
                                        <button
                                          className='bg-gray-50 px-3 py-1 rounded-md text-darkGray'
                                          onClick={() => getInvoice(dat.payment.id)}
                                        >
                                          {t('dashboard.listingSection.seeInvoice')}
                                        </button>
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className='sm:flex justify-between mx-4 border-t border-white '>
                                {dat.payment.plan.tier !== 'silver' ?
                                  <div className='flex justify-center sm:justify-start space-x-1 mt-4 sm:mt-0'>
                                    <p className='text-sm self-center'>
                                      {t('dashboard.listingSection.propertyVisitors')}{' '}
                                    </p>
                                    <span className='flex space-x-1 font-bold'>
                                      <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        fill='none'
                                        viewBox='0 0 24 24'
                                        strokeWidth='2'
                                        stroke='currentColor'
                                        className='w-6 h-6 self-center'
                                      >
                                        <path
                                          strokeLinecap='round'
                                          strokeLinejoin='round'
                                          d='M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z'
                                        />
                                        <path
                                          strokeLinecap='round'
                                          strokeLinejoin='round'
                                          d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
                                        />
                                      </svg>
                                      <p className='flex mt-0.5 self-center'>
                                        {dat.views}{' '}
                                        {t('dashboard.listingSection.views')}{' '}
                                      </p>
                                    </span>
                                  </div>
                                  : ''}
                                <div className='flex justify-end mx-3 p-3 space-x-3'>
                                  <button
                                    className='mt-2 w-full sm:w-auto sm:px-4 py-2 bg-auctionicRed text-white rounded-md hover:bg-white hover:text-darkGray transition-all ease-out duration-150'
                                    onClick={() => handleNavigation(dat)}
                                  >
                                    {t('dashboard.listingSection.seeProperty')}
                                  </button>
                                  {dat.type =='sale' && dat.status == 4 ?
                                    <button
                                      className='mt-2 w-full sm:w-auto sm:px-4 py-2 bg-white text-darkGray rounded-md hover:bg-white hover:text-darkGray transition-all ease-out duration-150'
                                      onClick={() => deactivateSale(dat.id)}
                                      disabled={isDisabled}
                                    >
                                      {buttonLoader ? (
                                        <div className='flex justify-center w-full'>
                                          <SpinnerLoading />
                                        </div>
                                      ) : (
                                        t('dashboard.listingSection.deactivateAd')
                                      )}
                                    </button>
                                    :  dat.type == 'rent' && dat.status == 4 ?
                                      <button
                                        className='mt-2 w-full sm:w-auto sm:px-4 py-2 bg-white text-darkGray rounded-md hover:bg-white hover:text-darkGray transition-all ease-out duration-150'
                                        onClick={() => deactivateRent(dat.id)}
                                        disabled={isDisabled}
                                      >
                                        {buttonLoader ? (
                                          <div className='flex justify-center w-full'>
                                            <SpinnerLoading />
                                          </div>
                                        ) : (
                                          t('dashboard.listingSection.deactivateAd')
                                        )}
                                      </button>
                                      : '' }
                                  {dat.type =='sale' && dat.status == 99?
                                    <button
                                      className='mt-2 w-full sm:w-auto sm:px-4 py-2 bg-white text-darkGray rounded-md hover:bg-white hover:text-darkGray transition-all ease-out duration-150'
                                      onClick={() => reactivateSale(dat.id)}
                                      disabled={isDisabled}
                                    >
                                      {buttonLoader ? (
                                        <div className='flex justify-center w-full'>
                                          <SpinnerLoading />
                                        </div>
                                      ) : (
                                        t('dashboard.listingSection.reactivateAd')
                                      )}
                                    </button>
                                    :  dat.type == 'rent' && dat.status == 99 ?
                                      <button
                                        className='mt-2 w-full sm:w-auto sm:px-4 py-2 bg-white text-darkGray rounded-md hover:bg-white hover:text-darkGray transition-all ease-out duration-150'
                                        onClick={() => reactivateRent(dat.id)}
                                        disabled={isDisabled}
                                      >
                                        {buttonLoader ? (
                                          <div className='flex justify-center w-full'>
                                            <SpinnerLoading />
                                          </div>
                                        ) : (
                                          t('dashboard.listingSection.reactivateAd')
                                        )}
                                      </button>
                                      : '' }
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <PropertyNotFound
                  name={t('dashboard.listingSection.noCurrentPropertiesListed')}
                />
              )}</>}
        </div>
      </div>
      <div className='flex justify-center mt-10 pb-10'>
        <button
          className='bg-darkGray px-8 py-2 text-white rounded-md font-press-start'
          onClick={() => navigate('/listingItem', { state: { type: 'auction' } })}
        >
          {t('dashboard.createListing')}
        </button>
      </div>
    </div>
  );
};

export { Listings };
