import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import landing from '../../images/mety_hauswartungen.jpg';

const RealEstateManagement = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className='sm:mt-[115px] my-10'>
      <div className='space-y-6 pb-6'>
        <div className='text-xl sm:text-[45px] text-center leading-normal'>
          {t('services.realEstateManagement.title')}
        </div>
        <div className='text-lg sm:text-[35px] text-center'>
          {t('services.realEstateManagement.companyName')} - {t('services.realEstateManagement.companyService')}
        </div>
      </div>
      <div className='flex justify-center pt-10'>
        <div className='relative lg:h-full md:h-56 h-80 w-full flex self-center'>
          <img src={landing} className="opacity-75 object-cover w-full" alt="" />
          <div className='absolute h-full w-full bg-gradient-to-r from-white to-transparent'></div>
          <div className='absolute xl:left-20 md:left-4 left-4 grid grid-flow-row self-center space-y-3 lg:space-y-4'>
            <div>
              <h1 className='xl:text-5xl lg:text-3xl md:text-xl text-xl font-bold text-black'>{t('services.realEstateManagement.companyName')}</h1>
              <h2 className='xl:text-3xl lg:text-2xl md:text-2xl text-xl font-bold text-gray-700'>{t('services.realEstateManagement.companyService')}</h2>
            </div>
            <div className='xl:text-2xl lg:text-xl md:text-sm text-base w-[85%] md:w-[70%]'>
              <p className='text-green-600 font-bold'>{t('services.realEstateManagement.dsc1')}</p>
              <p className='text-gray-700'>{t('services.realEstateManagement.dsc2')}</p>
            </div>
            <div className='space-x-0 md:space-x-5 md:flex grid grid-flow-row space-y-1 md:space-y-0'>
              <a
                href='https://www.mety-hauswartungen.ch/#kontakt'
                className='text-white bg-[#557df3] hover:bg-white hover:text-[#557df3] hover:outline hover:outline-1 hover:outline-[#557df3] px-2 md:px-5 py-1 lg:py-2 xl:py-3 w-fit'>
                {t('services.realEstateManagement.button1')}
              </a>
              <a
                href='https://www.mety-hauswartungen.ch/#angebot'
                className='text-white bg-green-600 hover:bg-white hover:text-green-600 hover:outline hover:outline-1 hover:outline-green-600 px-2 md:px-5 py-1 lg:py-2 xl:py-3 w-fit'>
                {t('services.realEstateManagement.button2')}
              </a>
            </div>
          </div>
          <div className='absolute top-[105%] lg:top-[95%] xl:top-[90%] right-0 lg:right-3 w-full lg:w-[85%] h-28 lg:h-24 xl:h-32 bg-gradient-to-r from-[#557df3] to-[#8ce8ff] rounded-md grid md:grid-flow-col grid-flow-row justify-around items-center text-white'>
            <div className='flex'>
              <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" className='h-5 w-5 mr-2' fill='white'>
                <g data-name="10-House" id="_10-House">
                  <path d="M31.62,12.22l-15-12a1,1,0,0,0-1.25,0l-15,12A1,1,0,0,0,1,14H3V31a1,1,0,0,0,1,1H28a1,1,0,0,0,1-1V14h2a1,1,0,0,0,.62-1.78ZM18,30H14V22h4ZM28,12a1,1,0,0,0-1,1V30H20V21a1,1,0,0,0-1-1H13a1,1,0,0,0-1,1v9H5V14H22V12H3.85L16,2.28,28.15,12Z"/>
                </g>
              </svg>
              {t('services.realEstateManagement.icon1')}
            </div>
            <div className='flex'>
              <svg className='h-6 w-6 mr-2' viewBox="0 -8 72 72" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" fill='white'>
                <path className="cls-1" d="M13.32,44.31a4.5,4.5,0,0,0,6.36,6.36L34.44,35.91l-6.36-6.36Z"/>
                <polygon className="cls-1" points="50.2 16.96 56.63 13.63 60 7.13 56.86 3.99 50.36 7.36 47.03 13.78 39.21 21.61 42.38 24.79 50.2 16.96"/>
                <path className="cls-1" d="M51.61,34,51,34a8.9,8.9,0,0,0-3.11.58L29.45,16.09A8.9,8.9,0,0,0,30,13l-.06-.6A8.9,8.9,0,0,0,17.19,4.89l6,6a3,3,0,0,1,.68,1.08A3,3,0,0,1,21,16a2.92,2.92,0,0,1-1-.21,3.15,3.15,0,0,1-1.08-.67l-6-6a8.9,8.9,0,0,0,7.49,12.78L21,22a8.9,8.9,0,0,0,3.11-.58L42.6,39.84A8.9,8.9,0,0,0,42,43l.06.6A9,9,0,0,0,51,51.94a8.72,8.72,0,0,0,3.85-.9l-6-6A3.08,3.08,0,0,1,48.21,44,3,3,0,0,1,51,40a2.92,2.92,0,0,1,1,.21,2.72,2.72,0,0,1,1.08.67l6,6A8.9,8.9,0,0,0,51.61,34Z"/>
              </svg>
              {t('services.realEstateManagement.icon2')}
            </div>
            <div className='flex'>
              <svg className='h-5 w-5 mr-2' viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g>
                  <path d="M21 28V2C21 1.447 20.553 1 20 1H2C1.447 1 1 1.447 1 2V31H8V25H14V31H31V8C31 8 31 7 30 7H24M16 6V8M26 12V14M26 18V20M11 6V8M6 6V8M16 12V14M11 12V14M6 12V14M16 18V20M11 18V20M6 18V20" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                  <clipPath>
                    <rect width="32" height="32" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              {t('services.realEstateManagement.icon3')}
            </div>
          </div>
        </div>
      </div>
      <div className='mt-28 lg:px-16'>
        <h1 className='text-center text-2xl font-bold py-10'>{t('services.realEstateManagement.about')}</h1>
        <div className='grid grid-cols-1 lg:grid-cols-2'>
          <div className='mx-6 lg:mx-10'>
            <p className='text-2xl font-bold'>{t('services.realEstateManagement.key1')}</p>
            <p>{t('services.realEstateManagement.key1dsc')}</p>
          </div>
          <div className='mx-6 lg:mx-10 mt-10 lg:mt-0'>
            <p className='text-2xl font-bold'>{t('services.realEstateManagement.key2')}</p>
            <p>{t('services.realEstateManagement.key2dsc')}</p>
          </div>
          <div className='mx-6 lg:mx-10 mt-10 lg:mt-5'>
            <p className='text-2xl font-bold'>{t('services.realEstateManagement.key3')}</p>
            <p>{t('services.realEstateManagement.key3dsc')}</p>
          </div>
          <div className='mx-6 lg:mx-10 mt-10 lg:mt-5'>
            <p className='text-2xl font-bold'>{t('services.realEstateManagement.key4')}</p>
            <p>{t('services.realEstateManagement.key4dsc')}</p>
          </div>
        </div>
      </div>
      <div className='flex justify-center my-20'>
        <a
          href='https://www.mety-hauswartungen.ch/#ueber_uns'
          className='px-3 py-2 text-xl text-white hover:text-[#557df3] bg-[#557df3] hover:bg-white hover:outline hover:outline-1 hover:outline-[#557df3]'>
          {t('services.realEstateManagement.visitUs')}
        </a>
      </div>
    </div>
  );
};

export { RealEstateManagement };
