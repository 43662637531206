const radius = [
  {
    id: 1,
    radius: 5,
  },
  {
    id: 2,
    radius: 10,
  },
  {
    id: 3,
    radius: 15,
  },
  {
    id: 4,
    radius: 20,
  },

  {
    id: 5,
    radius: 25,
  },
  {
    id: 6,
    radius: 50,
  },
  {
    id: 7,
    radius: 60,
  },
  {
    id: 8,
    radius: 70,
  },
  {
    id: 9,
    radius: 75,
  },
  {
    id: 10,
    radius: 80,
  },
  {
    id: 11,
    radius: 90,
  },
  {
    id: 12,
    radius: 100,
  },
];
export { radius };
