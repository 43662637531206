import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Logo from '../../images/logo.svg';
import { propertyServices } from '../../services/propertyServices';
import { SpinnerLoading } from '../LoadingTest/SpinnerLoading';
import bgImage from '../../images/thankYouBg1.jpeg';
import iazilogo from '../../images/iazi-logo.svg';
import rslogo from '../../images/rs-logo.svg';
import kuoniLogo from '../../images/kuoni_transporte.png';
import { toast } from 'react-toastify';

interface Props {
  invoice: any;
  loader1: any;
  loader2: any;
  loader3: any;
  loader4: any;
  creatingProperty: any;
  uploadingImages: any;
  uploadingDocuments: any;
  uploadingVideos: any;
  error: any;
  advertisementInfo: any;
}

const PropertyConfirmationForm = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    invoice,
    loader1,
    loader2,
    loader3,
    loader4,
    creatingProperty,
    uploadingImages,
    uploadingDocuments,
    uploadingVideos,
    error,
    advertisementInfo,
  } = props;
  
  const [invoiceLoader, setInvoiceLoader] = useState(false);
  
  const redirectToInvoice = () => {
    const newTab = window.open();
    setInvoiceLoader(true);
    // console.log('invoice', invoice);
    if (invoice != null) {
      propertyServices
        .generateInvoice(invoice)
        .then((response) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          newTab.location.href = response?.data.link;
        })
        .catch((error: any) => {
          setInvoiceLoader(false);
          // console.log('Failed to get the invoice', error);
        }).finally(() => {
          setInvoiceLoader(false);
        });
    }
    else {
      toast.error(t('errors.invoiceLinkError'), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='bg-darkGray relative flex justify-center min-h-screen'>
      <img src={bgImage} className='object-cover object-top-center 2xl:h-[1200px] lg:h-[800px] md:h-[1100px] h-[1100px] w-full opacity-30' alt="" />
      <div className='absolute self-center xl:mt-0 lg:mt-[-100px] md:mt-[-100px] mt-[-50px] px-5'>
        <div className='sm:grid sm:grid-cols-2 xl:space-x-48 lg:space-x-20 md:space-x-10 space-x-0'>
          <div className='text-center self-center '>
            <div className='self-center'>
              <p className='text-[40px] text-white'>
                {t('listingItem.propertyConfirmationForm.thankYou')}
              </p>
            </div>
            <div className='mb-12 mt-4'>
              <img src={Logo} alt='logo' className='h-full w-full' />
            </div>
          </div>
          <div className='self-center bg-white rounded-md
            lg:px-14 md:px-10 px-8
            lg:py-7 md:py-5 py-5'>
            <div className='space-y-3'>
              <div className='py-3 text-2xl'>
                {t('listingItem.propertyConfirmationForm.status')}
              </div>
              <div>
                {creatingProperty ? (
                  <div className='flex justify-between '>
                    <p>
                      {t('listingItem.propertyConfirmationForm.creatingProperty')}
                    </p>
                    {loader1 ? (
                      <SpinnerLoading />
                    ) : (
                      <div className=''>
                        {error.includes('creating_property') ? (
                          <button className='bg-auctionicRed' disabled>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth='1.5'
                              stroke='white'
                              className='w-6 h-6'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                              />
                            </svg>
                          </button>
                        ) : (
                          <button className='bg-green-500 rounded-full' disabled>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth='1.5'
                              stroke='white'
                              className='w-6 h-6'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                              />
                            </svg>
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div>
                {uploadingImages ? (
                  <div className='flex justify-between '>
                    <p>
                      {t('listingItem.propertyConfirmationForm.uploadingImages')}
                    </p>
                    {loader2 ? (
                      <SpinnerLoading />
                    ) : (
                      <div className=''>
                        {error.includes('upload_images') ? (
                          <button className='bg-auctionicRed' disabled>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth='1.5'
                              stroke='white'
                              className='w-6 h-6'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                              />
                            </svg>
                          </button>
                        ) : (
                          <button className='bg-green-500 rounded-full' disabled>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth='1.5'
                              stroke='white'
                              className='w-6 h-6'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                              />
                            </svg>
                          </button>
                        )}
                      </div>
                    )}{' '}
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div>
                {uploadingDocuments ? (
                  <div className='flex justify-between '>
                    <p>
                      {t('listingItem.propertyConfirmationForm.uploadingDocuments')}
                    </p>
                    {loader3 ? (
                      <SpinnerLoading />
                    ) : (
                      <div className=''>
                        {error.includes('upload_documents') ? (
                          <button className='bg-auctionicRed' disabled>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth='1.5'
                              stroke='white'
                              className='w-6 h-6'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                              />
                            </svg>
                          </button>
                        ) : (
                          <button className='bg-green-500 rounded-full' disabled>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth='1.5'
                              stroke='white'
                              className='w-6 h-6'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                              />
                            </svg>
                          </button>
                        )}
                      </div>
                    )}{' '}
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div>
                {uploadingVideos ? (
                  <div className='flex justify-between'>
                    <p>
                      {t('listingItem.propertyConfirmationForm.uploadingVideos')}
                    </p>
                    {loader4 ? (
                      <SpinnerLoading />
                    ) : (
                      <div className=''>
                        {error.includes('upload_video') ? (
                          <button className='bg-auctionicRed' disabled>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth='1.5'
                              stroke='white'
                              className='w-6 h-6'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                              />
                            </svg>
                          </button>
                        ) : (
                          <button className='bg-green-500 rounded-full' disabled>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth='1.5'
                              stroke='white'
                              className='w-6 h-6'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                              />
                            </svg>
                          </button>
                        )}
                      </div>
                    )}{' '}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div>
              {advertisementInfo?.payment?.discount != 100 ? (
                <div>
                  <div className='flex space-x-1'>
                    <p>{t('listingItem.propertyConfirmationForm.pleaseCheck')}</p>
                  </div>
                  <div className='flex justify-center py-3'>
                    <button
                      className='bg-auctionicRed text-white px-4 py-2 rounded-md ease-in-out'
                      onClick={() => {redirectToInvoice();}}
                    >
                      {invoiceLoader ? (
                        <SpinnerLoading />
                      )
                        :(
                          t('listingItem.propertyConfirmationForm.openInvoice')
                        )}
                    </button>
                  </div>
                </div>
              ):''}
              <div className='flex justify-center'>
                <button
                  onClick={() => navigate('/')}
                  className='flex hover:text-white hover:bg-auctionicRed transition-all ease-in-out duration-300 p-2 rounded-lg space-x-2 '
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth='1.5'
                    stroke='currentColor'
                    className='w-6 h-6 '
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                    />
                  </svg>
                  {t('propertyListing.goBackToLanding')}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='grid sm:grid-flow-col grid-flow-row justify-center sm:space-x-10 xl:pt-20 lg:pt-10 md:pt-20 pt-20 space-y-6 sm:space-y-0'>
          <div className='text-center text-3xl text-white self-center'>
            {t('listingItem.propertyConfirmationForm.ourPartners')}
          </div>
          <div className='flex justify-center'>
            <img src={iazilogo} className='h-[100px]' alt="" />
          </div>
          <div className='self-center'>
            <img src={rslogo} className='h-[70px]' alt="" />
          </div>
          <div className='self-center'>
            <img src={kuoniLogo} className='h-[70px]' alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export { PropertyConfirmationForm };
