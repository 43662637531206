import React from 'react';
import Countdown from 'react-countdown';
import { useTranslation } from 'react-i18next';
import { utils } from '../../helpers/utils';

interface Props {
    trends: any;
}

const BidCountdown = (props: Props) => {
  const { trends } = props;
  const { t } = useTranslation();
  const d1 = new Date(trends.finishing_date);
  const d2 = utils.convertUTCDateToLocalDate(d1);
  const finish = d2.getTime();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const renderer = ({ days, hours, minutes, seconds }) => {
    return <span>{days} {t('landing.days')}, {hours}:{minutes}:{seconds}</span>;
  };

  return (
    <>
      <p className='text-right text-darkGray pt-3 text-sm font-light'>
        {t('trendingEstate.timeLeft')}&nbsp;
        <span className='text-auctionicRed text-base font-normal'>
          <Countdown
            autoStart={true}
            daysInHours={false}
            date={finish}
            renderer={renderer}
          />
        </span>
      </p>
    </>
  );
};

export { BidCountdown };
