import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { utilsProperty } from '../../PropertyDetails/utils';
import { utils } from '../../../helpers/utils';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface Props {
  onNext: () => void;
  propertyData: any;
}

const PropertyDetailsStep: React.FC<Props> = ({ onNext, propertyData }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleNavigation = (id: number) => {
    if (propertyData.type === 'auction') {
      navigate(`/property/auction/details/${id}`);
    }
    else if (propertyData.type === 'rent') {
      navigate(`/property/rent/details/${id}`);
    }
    else if (propertyData.type === 'sale') {
      navigate(`/property/sale/details/${id}`);
    }
  };

  return (
    <div>
      {/* Form fields for property details */}
      <div
        className='ml-4 sm:ml-[10%] mr-4 sm:mr-[10%] lg:ml-[15%] lg:mr-[15%] mt-10 lg:mt-[35px] mb-4 overflow-hidden'
      >
        <div className='border-b-2 border-white bg-gray-100 p-5 rounded-lg'>
          <p className='text-[25px] font-semibold' >{t('dashboard.listingSection.reAdvertise.propertyInfo')}</p>
          <div className=' pb-0.5 p-[8px] pt-o '>
            <div className='grid grid-cols-1 sm:grid-cols-2 '>
              <div className='relative text-center rel'>
                <Swiper
                  modules={[Navigation]}
                  slidesPerView={1}
                  navigation={true}
                  className='rounded-xl'
                >
                  {propertyData.header_pic ? (
                    <SwiperSlide>
                      <img
                        className='sm:w-[483px] sm:h-[364px]'
                        src={propertyData.header_pic}
                        alt=''
                      />
                    </SwiperSlide>
                  ) : (
                    <SwiperSlide className='flex justify-center bg-gray-100 '>
                      <div className='sm:w-[483px] sm:h-[364px]'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                          strokeWidth='1.5'
                          stroke='gray'
                          className='w-fit flex justify-center'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z'
                          />
                        </svg>
                        <p className='text-center text-gray-500 font-thin -mt-6'>
                          No images for this property.
                        </p>
                      </div>
                    </SwiperSlide>
                  )}
                </Swiper>
              </div>
              <div className='sm:ml-8 sm:mt-5 py-4 sm:max-w-[350px] '>
                <p className='text-base font-normal leading-4'>
                  {utilsProperty.generateTitleList(propertyData, t)}
                </p>
                <p className='text-sm leading-3 mt-3'>{propertyData.address}</p>
                <p className='text-sm sm:max-w-[350px] mt-6'>{propertyData.title}</p>
                {propertyData.type === 'auction' ? (
                  <p className='mt-3 text-[18px]'>
                    CHF {utils.numberWithComma(propertyData.current_bid)}.—
                  </p>
                ) : propertyData.type === 'sale' ? (
                  <p className='mt-3 text-[18px]'>
                    CHF {utils.numberWithComma(propertyData.price)}.—
                  </p>
                ) : propertyData.type === 'rent' ? (
                  <p className='mt-3 text-[18px]'>
                    CHF {utils.numberWithComma(propertyData.price)}.—
                  </p>
                ) : (
                  ''
                )}
                <div className='border border-red-600 w-36' />
                <div className='flex justify-between mt-20'>
                  <div>
                    <button
                      type='button'
                      onClick={() => handleNavigation(propertyData.id)}
                      className='inline-block px-6 py-2.5 bg-darkGray text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-600 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out'
                    >
                      {t('propertyListing.viewDetails')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end mt-4">
        <button className="px-4 py-2 bg-darkGray text-white rounded" onClick={onNext}>
          {t('dashboard.listingSection.reAdvertise.next')}
        </button>
      </div>
    </div>
  );
};

export { PropertyDetailsStep };
