import React, { useEffect, useState } from 'react';
import './Register.css';
import { BasicInformation } from './BasicInformation';
import { LocationInfo } from './LocationInfo';
import { RegisterAs } from './RegisterAs';
import { CreatedAccount } from './CreatedAccount';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RegisterAsAgency } from './RegisterAsAgency';
import { AddressInput, UserInput } from '../../config';
import { userServices } from '../../services';
import { RegisterFailed } from './RegisterFailed';
import { utils } from '../../helpers/utils';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Register = () => {
  const [isActive, setisActive] = useState('');
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [otp, setOtp] = useState('');
  const [secondButton, setsecondButton] = useState(false);
  const [inputRegister, setInputRegister] = useState<UserInput>({
    password: '',
    language: 'de',
    terms_and_conditions: false,
    address: {
      city: '',
      street: '',
      zip: '',
      canton: ''
    } as AddressInput,
  } as UserInput);
  const [buttonLoader, setButtonLoader] = useState(false);


  useEffect(() => {
    if (localStorage.getItem('locale')) {
      setInputRegister({
        ...inputRegister,
        language: localStorage.getItem('locale') as any,
      });
    }
  }, [localStorage.getItem('locale')]);

  const [error, setError] = useState([]);

  const handleChangeRegister = (e: any) => {
    const { name, value } = e.target;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore=
    if (error.includes(name)) {
      setError(error.filter((e) => e !== name));
    }
    setInputRegister((prevInputRegister) => {
      return {
        ...prevInputRegister,
        [name]: value,
      };
    });
  };


  const handleRegister = () => {
    setButtonLoader(true);
    const fixBody: UserInput = {
      ...inputRegister,
      address: utils.formatAddress(inputRegister.address) as any
    } as UserInput;
    userServices
      .userRegister(fixBody)
      .then((response: any) => {
        setButtonLoader(false);
        setPage((currPage: number) => currPage + 1);
        localStorage.setItem('access_token', response.data.access_token);
        setsecondButton(true);
        toast.success(t('errors.registerS'), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        // console.log(response);
      })
      .catch((error: any) => {
        setButtonLoader(false);
        if (error.response.data.msg == 'email_exists') {
          // Add toast
          toast.error(t(`errors.${error.response.data.msg}`), {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
        else if (error.response.data.msg == 'phone_exists') {
          // Add toast
          toast.error(t(`errors.${error.response.data.msg}`), {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
        else {
          toast.error(t('errors.registerE'), {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
        // console.error('Login error', error);
      }).finally(()=>{
        setButtonLoader(false);
      });
  };

  const handleTwoFactor = () => {
    if (otp?.length == 6) {
      setButtonLoader(true);
      userServices
        .userLogin2FA(otp)
        .then((response: any) => {
          // console.log(response);
          setButtonLoader(false);
          setPage((currPage: number) => currPage + 1);
          return response;
        })
        .catch((error: any) => {
          console.error('Login error', error);
          setPage((currPage: number) => currPage + 2);
        });
    }
  };

  const handleClick = (e: any, param: any) => {
    handleClickIndividual('individual');
    setInputRegister((inputs) => ({
      ...inputs,
      type: param,
    }));
  };

  const handleClickAgency = (e: any, param: any) => {
    handleClickIndividual('company');
    setInputRegister((inputs) => ({
      ...inputs,
      type: param,
    }));
  };

  const handleSalutationChange = (e:any, param:number) => {
    setInputRegister((radio) =>({
      ...radio,
      salutation:param
    }));
  };


  if (page === 1) {
    if (isActive === 'individual') {
      return (
        <RegisterAs
          inputRegister={inputRegister}
          handleChangeRegister={handleChangeRegister}
          setPage={setPage}
          setClickIndividual={setisActive}
          setInputRegister={setInputRegister}
          handleSalutationChange={handleSalutationChange}
          error={error}
          setError={setError}
        />
      );
    } else {
      if (isActive === 'company') {
        return (
          <RegisterAsAgency
            inputRegister={inputRegister}
            handleChangeRegister={handleChangeRegister}
            setPage={setPage}
            setClickAgency={setisActive}
            setInputRegister={setInputRegister}
            error={error}
            setError={setError}
          />
        );
      }
    }
  } else if (page === 2) {
    return (
      <BasicInformation
        handleChangeRegister={handleChangeRegister}
        setPage={setPage}
        handleTwoFactor={handleTwoFactor}
        handleRegister={handleRegister}
        secondButton={secondButton}
        setInputRegister={setInputRegister}
        error={error}
        setError={setError}
        inputRegister={inputRegister}
        buttonLoader={buttonLoader}
      />
    );
  } else if (page === 3) {
    return (
      <LocationInfo
        inputRegister={inputRegister}
        setPage={setPage}
        otp={otp}
        setOtp={setOtp}
        handleTwoFactor={handleTwoFactor}
        setsecondButton={setsecondButton}
        buttonLoader={buttonLoader}
      />
    );
  } else if (page === 4) {
    return <CreatedAccount />;
  } else if (page === 5) {
    return <RegisterFailed />;
  }
  const handleClickIndividual = (event: string) => {
    setisActive(event);
  };

  return (
    <div className='registerContainer'>
      <ToastContainer autoClose={7000} />
      <div className='flex items-center justify-center py-[64px] px-4 sm:px-6 lg:px-8 font-avenir55'>
        <div className='max-w-3xl w-full space-y-8 bg-white'>
          <div className='px-4 sm:px-[30px] pt-[35px]'>
            <p
              id='showMe'
              className='sm:text-[1.5rem] text-center font-braga'
            >
              {t('register.message')}
            </p>
          </div>
          <div
            id='showMe'
            className='grid sm:flex sm:justify-center sm:space-x-40 space-y-5 sm:space-y-0 sm:grid-cols-2 text-center justify-items-center sm:pt-12'
          >
            {/* Individual */}
            <object
              name='individual'
              id='type'
              onClick={(e) => {
                handleClick(e, 'individual');
              }}
            >
              <div
                className=' sm:w-[155px] w-[180px] rounded-xl cursor-pointer'
                style={{
                  backgroundColor:
                    isActive === 'individual' ? '#353535' : '#F5F5F5',
                  color: isActive === 'individual' ? 'white' : '#353535',
                }}
              >
                <div className='text-center flex justify-center pt-11'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='65'
                    height='66'
                    fill='none'
                    viewBox='0 0 65 66'
                  >
                    <path
                      fill={isActive === 'individual' ? 'white' : '#353535'}
                      d='M51.458 19.253c0 10.628-8.488 19.25-18.958 19.25-10.47 0-18.958-8.622-18.958-19.25C13.542 8.618 22.03 0 32.5 0c10.47 0 18.958 8.618 18.958 19.253ZM47.13 38.998c-4.078 3.127-9.138 5.005-14.63 5.005-5.498 0-10.56-1.884-14.644-5.01C6.828 43.922 0 59.275 0 66h65c0-6.663-7.042-22.017-17.87-27.002Z'
                    />
                  </svg>{' '}
                </div>
                <p className='text-center text-sm leading-4 font-medium font-press-start pt-[84px] pb-5 '>
                  {t('register.privatePerson')}
                </p>
              </div>
            </object>
            {/* Agency */}
            <object
              name='individual'
              id='type'
              onClick={(e) => {
                handleClickAgency(e, 'company');
              }}
            >
              <div
                style={{
                  backgroundColor:
                    isActive === 'company' ? '#353535' : '#F5F5F5',
                  color: isActive === 'company' ? 'white' : '#353535',
                }}
                className='bg-gray-100 w-[180px] rounded-xl cursor-pointer'
                id='company showMe'
              >
                <div className='text-center flex justify-center pt-11'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='145'
                    height='76'
                    fill='none'
                    viewBox='0 0 145 76'
                  >
                    <path
                      fill={isActive === 'company' ? 'white' : '#353535'}
                      d='M43.429 16.841c0 8.834-7.164 16-16 16-8.837 0-16-7.166-16-16 0-8.839 7.163-16.002 16-16.002 8.836 0 16 7.163 16 16.002Zm-3.653 16.411c-3.442 2.6-7.712 4.16-12.347 4.16-4.64 0-8.912-1.565-12.36-4.164C5.763 37.346 0 50.108 0 55.696h54.857c0-5.538-5.943-18.3-15.081-22.444Zm93.017-16.411c0 8.834-7.164 16-16 16-8.837 0-16-7.166-16-16 0-8.839 7.163-16.002 16-16.002 8.836 0 16 7.163 16 16.002Zm-3.653 16.411c-3.442 2.6-7.712 4.16-12.347 4.16-4.64 0-8.912-1.565-12.359-4.164-9.308 4.098-15.07 16.86-15.07 22.448h54.857c0-5.538-5.943-18.3-15.081-22.444Z'
                    />
                    <path
                      fill={isActive === 'company' ? 'white' : '#353535'}
                      d='M91.65 28.786c0 10.544-8.55 19.097-19.097 19.097S53.456 39.33 53.456 28.786c0-10.55 8.55-19.1 19.097-19.1s19.097 8.55 19.097 19.1Zm-4.36 19.588c-4.108 3.102-9.204 4.965-14.737 4.965-5.538 0-10.637-1.869-14.75-4.97C46.692 53.26 39.815 68.49 39.815 75.16h65.474c0-6.61-7.093-21.84-18-26.787Z'
                    />
                  </svg>
                </div>
                <p className='text-center text-sm leading-4 font-medium font-press-start pt-[74px] pb-5'>
                  {t('register.company')}
                </p>
              </div>
            </object>
          </div>
          <div className='px-4 sm:px-[30px] pb-4 sm:pb-[34px] pt-4 sm:pt-[43px] flex justify-between'>
            <button
              id='elementToAnimate'
              onClick={() => navigate('/login')}
              className='bg-white hover:bg-gray-200 px-2 py-2 mt-3 rounded-md border-2 border-black hover:transition-all hover:ease-in-out hover:duration-150'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-6 w-6 '
                fill='none'
                viewBox='0 0 24 24'
                stroke='black'
              >
                <path d='M10 19l-7-7m0 0l7-7m-7 7h18' />
              </svg>
            </button>
            {isActive === 'individual' || isActive === 'company' ? (
              <button
                onClick={() => {
                  setPage((currPage) => currPage + 1);
                }}
                className='bg-darkGray hover:bg-gray-700 hover:scale-105 px-2 py-2 mt-3 rounded-md border-2 border-black z-10 transition-all ease-in-out duration-150'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='white'
                >
                  <path d='M14 5l7 7m0 0l-7 7m7-7H3' />
                </svg>
              </button>
            ) : (
              <button
                disabled
                className='bg-gray-200 px-2 py-2 mt-3 rounded-md border-2 border-gray-200 z-10 transition-all ease-in-out duration-150'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='white'
                >
                  <path d='M14 5l7 7m0 0l-7 7m7-7H3' />
                </svg>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export { Register };
