import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { userServices } from '../../services';
import { StrengthBar1 } from '../StrengthBar1';
import { Link } from 'react-router-dom';


const Security = () => {
  const { t } = useTranslation();
  const [userIden, setUserIden] = useState();
  const [score, setScore] = useState(0);
  const [passwordShow, setPasswordShow] = useState(false);
  // console.log(userIden);
  const [input, setInput] = useState({
    old_password: '',
    new_password: ''
  });
  // console.log(score);


  const userIdenfy = () => {
    userServices
      .userIdenfy()
      .then((response: any) => {
        setUserIden(response);
        window.open(response.data.link);
        return response;
      })
      .catch((error: any) => {
        console.error('Login error', error);
      });
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setInput((prevInput) => {
      return {
        ...prevInput,
        [name]: value,
      };
    });
  };

  const onChangeScore = (score1: number) => {
    setScore(score1);
  };

  const handlePasswordShow = () => {
    setPasswordShow(!passwordShow);
  };

  const resetPassword = () => {
    userServices
      .passwordReset(input.old_password, input.new_password)
      .then((response: any) => {
        setInput(response.data);
        return response;
      })
      .catch((error: any) => {
        console.error('Login error', error);
      });
  };

  // const handleReset = () => {
  //   const errorList : string[] = [];
  //   if (!input.old_password) {
  //     errorList.push('old_password');
  //   } else if (!input.new_password || score < 3) {
  //     errorList.push('password');
  //   }
  //   else {
  //     resetPassword();
  //   }
  //   setError(errorList);
  // };

  const passwordMeter = [
    t('register.weakPassword'),
    t('register.weakPassword'),
    t('register.okayPassword'),
    t('register.goodPassword'),
    t('register.strongPassword'),
  ];

  // console.log(userIdenfy, 'testest');

  return (
    <div className='pt-[48px] px-3 sm:px-0 text-darkGray'>
      {/* <div className='block sm:flex bg-[#F5F5F5] rounded-lg px-10 py-[20px]'>
        <div className=''>
          <div className='flex space-x-2'>
            <p className='text-[16px]'>{t('dashboard.passwordStrength')}</p>
            <span className='w-[20px] h-[20px] bg-darkGray rounded-md' />
            <span className='w-[20px] h-[20px] bg-darkGray rounded-md' />
            <span className='w-[20px] h-[20px] bg-darkGray rounded-md' />
            <span className='w-[20px] h-[20px] bg-white border-2 border-red-500 rounded-md' />
          </div>
          <p className='text-[12px] text-gray-400 pt-[19px]'>
            {t('dashboard.passwordLastChanged')}
            {utils.formatDate(user?.password_changed_date)}
          </p>
          <button className='text-[14px] text-red-500 pt-[28px]'>
            <a href=''>{t('dashboard.forgotPassword')} </a>
          </button>
        </div>
        <div className='sm:pl-[100px] pt-10 sm:pt-0 '>
          <p className='text-[16px]'>{t('dashboard.twoStep')} </p>
          <div className='flex space-x-[50px] pt-[10px]'>
            <div className='flex space-x-2'>
              <p className='text-[14px] pt-1'>{t('dashboard.emailAddress')} </p>
              {user?.email_confirmed === true ? (
                <button className='rounded-full p-1 bg-green-500 '>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-6 w-6 '
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='white'
                    strokeWidth='2'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M5 13l4 4L19 7'
                    />
                  </svg>
                </button>
              ) : (
                <button className='rounded-full p-1 bg-auctionicRed '>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth='1.5'
                    stroke='white'
                    className='w-6 h-6'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M4.5 19.5l15-15m-15 0l15 15'
                    />
                  </svg>
                </button>
              )}
            </div>
            <div className='flex space-x-2'>
              <p className='text-[14px] pt-1'>{t('dashboard.phoneNumber')}</p>
              {user?.phone_confirmed === true ? (
                <button className='rounded-full p-1 bg-green-500 '>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-6 w-6 '
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='white'
                    strokeWidth='2'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M5 13l4 4L19 7'
                    />
                  </svg>
                </button>
              ) : (
                <button className='rounded-full p-1 bg-auctionicRed '>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth='1.5'
                    stroke='white'
                    className='w-6 h-6'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M4.5 19.5l15-15m-15 0l15 15'
                    />
                  </svg>
                </button>
              )}
            </div>
          </div>
        </div>
      </div> */}
      <div className='sm:pt-[10px]'>
        <div className='bg-[#F5F5F5] rounded-lg px-10 py-[24px]'>
          <p className='text-[20px] border-b-2 border-darkGray font-press-start'>{t('dashboard.changePassword')}</p>
          <div className='block sm:flex justify-evenly sm:space-x-[30px] pt-[45px] sm:pr-5 space-y-6 sm:space-y-0'>
            <div>
              <div>
                <div className='flex'>
                  <input
                    type={passwordShow ? 'text' : 'password'}
                    className=' pl-2 pr-7 h-9 rounded-lg w-full border-2 border-darkGray '
                    placeholder={t('dashboard.oldPassword')}
                    name='old_password'
                    value={input.old_password}
                    onChange={handleChange}
                  />
                  {passwordShow ? (
                    <button onClick={handlePasswordShow}>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth='1.5'
                        stroke='currentColor'
                        className='w-5 h-5 absolute -mt-2 -ml-6'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z'
                        />
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
                        />
                      </svg>
                    </button>
                  ) : (
                    <button onClick={handlePasswordShow}>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth='1.5'
                        stroke='currentColor'
                        className='w-5 h-5 absolute -mt-2 -ml-6'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88'
                        />
                      </svg>
                    </button>
                  )}
                </div>
                <button className='text-[14px] text-red-500 pt-[5px]'>
                  <Link to='/forgot/password'>{t('dashboard.forgotPassword')} </Link>
                </button>
              </div>
            </div>
            <div>
              <div className='flex'>
                <input
                  type={passwordShow ? 'text' : 'password'}
                  className='pl-2 pr-7 h-9 rounded-lg w-full border-2 border-darkGray'
                  placeholder={t('dashboard.newPassword')}
                  name='new_password'
                  value={input.new_password}
                  onChange={handleChange}
                />
                {passwordShow ? (
                  <button onClick={handlePasswordShow}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth='1.5'
                      stroke='currentColor'
                      className='w-5 h-5 absolute -mt-2 -ml-6'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z'
                      />
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
                      />
                    </svg>
                  </button>
                ) : (
                  <button onClick={handlePasswordShow}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth='1.5'
                      stroke='currentColor'
                      className='w-5 h-5 absolute -mt-2 -ml-6'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88'
                      />
                    </svg>
                  </button>
                )}
              </div>
              <StrengthBar1
                password={input.new_password}
                onChangeScore={onChangeScore}
                userInputs={[
                  input.old_password,
                ]}
                scoreWords={passwordMeter}
                shortScoreWord={t('register.tooShortPassword')}
              />
            </div>
            <div>
              <div className='flex'>
                <input
                  type={passwordShow ? 'text' : 'password'}
                  className='pl-2 pr-7 h-9 rounded-lg w-full border-2 border-darkGray '
                  placeholder={t('dashboard.confirmPassword')}
                />
                {passwordShow ? (
                  <button onClick={handlePasswordShow}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth='1.5'
                      stroke='currentColor'
                      className='w-5 h-5 absolute -mt-2 -ml-6'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z'
                      />
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
                      />
                    </svg>
                  </button>
                ) : (
                  <button onClick={handlePasswordShow}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth='1.5'
                      stroke='currentColor'
                      className='w-5 h-5 absolute -mt-2 -ml-6'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88'
                      />
                    </svg>
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className='flex justify-between pt-[17px]'>
            <p className='text-[12px]'>
              {t('dashboard.passwordInfo')}
              <br />
              {t('dashboard.passwordInfo2')}
            </p>
            <button onClick={resetPassword} className='rounded-xl px-3 py-1 mr-5 bg-auctionicRed text-white'>
              {t('dashboard.settingsSection.resetPassword')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export { Security };
