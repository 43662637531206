import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { numberOfRooms } from '../LisitingItem/numberOfRooms';
import { CharTypes, FilterInput, PropertyTypes } from '../../config';
import { propertyServices } from '../../services/propertyServices';
import rightArrow from '../../images/rightArrow.svg';
import { radius } from './radius';
import { data } from '../Landing/data';
import DatalistInput, { Item } from 'react-datalist-input';
import { useNavigate } from 'react-router-dom';

const prices = [
  {
    id: 1,
    price: 1000000,
    value: '1,000,000',
  },
  {
    id: 2,
    price: 2000000,
    value: '2,000,000',
  },
  {
    id: 3,
    price: 3000000,
    value: '3,000,000',
  },
  {
    id: 4,
    price: 4000000,
    value: '4,000,000',
  },
  {
    id: 5,
    price: 5000000,
    value: '5,000,000',
  },
  {
    id: 6,
    price: 10000000,
    value: '10,000,000',
  },
];

const prices_rent = [
  {
    id: 1,
    price: 500,
    value: '500',
  },
  {
    id: 2,
    price: 700,
    value: '700',
  },
  {
    id: 3,
    price: 900,
    value: '900',
  },
  {
    id: 4,
    price: 1000,
    value: '1,000',
  },
  {
    id: 5,
    price: 1100,
    value: '1,100',
  },
  {
    id: 6,
    price: 1200,
    value: '1,200',
  },
  {
    id: 7,
    price: 1300,
    value: '1,300',
  },
  {
    id: 8,
    price: 1400,
    value: '1,400',
  },
  {
    id: 9,
    price: 1500,
    value: '1,500',
  },
  {
    id: 10,
    price: 1600,
    value: '1,600',
  },
  {
    id: 11,
    price: 1700,
    value: '1,700',
  },
  {
    id: 12,
    price: 1800,
    value: '1,800',
  },
  {
    id: 13,
    price: 1900,
    value: '1,900',
  },
  {
    id: 14,
    price: 2000,
    value: '2,000',
  },
  {
    id: 15,
    price: 2200,
    value: '2,200',
  },
  {
    id: 16,
    price: 2400,
    value: '2,400',
  },
  {
    id: 17,
    price: 2600,
    value: '2,600',
  },
  {
    id: 18,
    price: 2800,
    value: '2,800',
  },
  {
    id: 19,
    price: 3000,
    value: '3,000',
  },
  {
    id: 20,
    price: 3200,
    value: '3,200',
  },
  {
    id: 21,
    price: 3400,
    value: '3,400',
  },
  {
    id: 22,
    price: 3600,
    value: '3,600',
  },
  {
    id: 23,
    price: 3800,
    value: '3,800',
  },
  {
    id: 24,
    price: 4000,
    value: '4,000',
  },
  {
    id: 25,
    price: 4500,
    value: '4,500',
  },
  {
    id: 26,
    price: 5000,
    value: '5,000',
  },
  {
    id: 27,
    price: 6000,
    value: '6,000',
  },
  {
    id: 28,
    price: 7000,
    value: '7,000',
  },
  {
    id: 29,
    price: 8000,
    value: '8,000',
  },
  {
    id: 30,
    price: 9000,
    value: '9,000',
  },
  {
    id: 31,
    price: 10000,
    value: '10,000',
  },
];

interface Props {
  state: any;
  setProperties: any;
  setLoading:any;
}

const PropertySearch = (props: Props) => {
  const navigate = useNavigate();
  const [location, setLocation] = useState('');
  // const [location_type, setLocationType] = useState('');
  const { state, setProperties, setLoading } = props;
  const [openFilter, setOpenFiler] = useState(false);
  const handleOpenFilter = () => {
    setOpenFiler(!openFilter);
  };
  const { t } = useTranslation();
  const [openAdvancedSearch, setOpenAdvancedSearch] = useState(false);
  const handleAdvancedSearch = () => {
    setOpenAdvancedSearch(!openAdvancedSearch);
  };
  const [types, setTypes] = useState<Array<PropertyTypes>>();
  const [propertyChars, setPropertyChars] = useState<Array<CharTypes>>([]);
  const [checkedVals, setChecked] = useState([] as Array<number>);
  const [selected, setSelected] = useState(Number);
  const [formattedPriceMin, setFormattedPriceMin] = useState('');
  const [formattedPriceMax, setFormattedPriceMax] = useState('');
  const [filterInput, setFilterInput] = useState<FilterInput>({
  } as FilterInput);
  const [index, setIndex] = useState(6);

  useEffect(() => {
    propertyServices
      .getCharachteristics()
      .then((response) => {
        setPropertyChars(response.data.data);
        return response;
      })
      .catch((error) => {
        // console.log('Error getting the properties', error);
      });
  }, []);
  const  handleFilterChange = (e: any) => {
    const { name, value } = e.target;
    if (e.target === 'location') {
      setFilterInput((prev:any)=>({
        ...prev,
        location:e
      }));
    }
    else if (name == 'property_types') {
      setFilterInput((prevState: any) => ({
        ...prevState,
        property_types: [parseInt(value)],
      }));
    }
    if (name == 'property_types' && value == 100) {
      setFilterInput((prevState: any) => ({
        ...prevState,
        property_types: [],
      }));
    }
    else if (name == 'price_min') {
      const formattedValue = (Number(value.replace(/\D/g, '')) || '')
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, '\'');
      // console.log('formated', formattedValue);
      const unformattedValue = Number(value.replace(/\D/g, '')) || '';
      // console.log('unformated', unformattedValue);
      setFilterInput((prevState: any) => ({
        ...prevState,
        price_min: unformattedValue,
      }));
      setFormattedPriceMin(formattedValue);
    } else if (name == 'price_max') {
      const formattedValue = (Number(value.replace(/\D/g, '')) || '')
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, '\'');
      const unformattedValue = Number(value.replace(/\D/g, '')) || '';
      setFilterInput((prevState: any) => ({
        ...prevState,
        price_max: unformattedValue,
      }));
      setFormattedPriceMax(formattedValue);
    }
    if (name == 'price_max' && value == '-1') {
      const formattedValue = (Number(value.replace(/\D/g, '')) || '')
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, '\'');
      const unformattedValue = Number(value.replace(/\D/g, '')) || '';
      setFilterInput((prevState: any) => ({
        ...prevState,
        price_max: null,
      } ));
      setFormattedPriceMax(formattedValue);
      // console.log(unformattedValue);
    }
    else if (name === 'size_rooms_min' && value === '100') {
      // console.log(name,value,'Mini');
      setFilterInput((prevState: any) => ({
        ...prevState,
        size_rooms_min: null,
      } ));
    }
    else if (name === 'size_rooms_max' && value === '100') {
      // console.log(name,value,'Maxi');
      setFilterInput((prevState: any) => ({
        ...prevState,
        size_rooms_max: null,
      } ));
    }
    else {
      if (name != 'property_types' && name != 'location' && name != 'price_min' && name != 'price_max') {
        setFilterInput((prevState: any) => ({
          ...prevState,
          [name]: value,
        }));
      }
    }

  };

  useEffect(() => {
    if (filterInput.property_types != null) {
      const propTypes = filterInput?.property_types[0];
      setSelected(propTypes);
    }
  }, [filterInput]);

  const handleCheckedChars = (e: any) => {
    const char_id = e.target.value;
    // console.log(typeof(char_id), 'type of char');
    const newState = [...checkedVals];
    if (e.target.checked) {
      newState.push(parseInt(char_id));
    } else {
      if (newState.indexOf(parseInt(char_id)) != -1) {
        newState.splice(newState.indexOf(parseInt(char_id)),1);
      }
    }
    setChecked(newState);
    setFilterInput((prevState: any) => ({
      ...prevState,
      property_chars: newState,
    }));
  };
  Array.prototype.push.apply([filterInput], state.filter);
  useEffect(() => {
    propertyServices
      .getListingProperties()
      .then((response) => {
        setTypes(response.data.data);
        return response;
      })
      .catch((error) => {
        // console.log('Error getting the properties', error);
      });
  }, []);
  const toArr = (name: any) => {
    const newArr = name.split('&');
    return newArr;
  };
  const handleFilter = () => {
    // console.log(state.type, 'o tipi');
    switch (state.type) {
    case 'auction':
      if (localStorage.getItem('user_id')) {
        setLoading(true);
        propertyServices
          .filterAuctionProperties([filterInput])
          .then((response: any) => {
            setLoading(false);
            setProperties(response.data);
            setOpenFiler(false);
          })
          .catch((error: any) => {
            console.error('Login error', error);
          })
          .finally(() => setLoading(false));
      } else {
        navigate('/login/redirect');
      }
      break;
    case 'rent':
      setLoading(true);
      propertyServices
        .filterRentProperties([filterInput])
        .then((response: any) => {
          setLoading(false);
          setProperties(response.data);
          setOpenFiler(false);
        })
        .catch((error: any) => {
          setLoading(false);
          console.error('Login error', error);
        })
        .finally(() => setLoading(false));
      break;
    case 'sale':
      setLoading(true);
      propertyServices
        .filterSaleProperties([filterInput])
        .then((response: any) => {
          setLoading(false);
          setProperties(response.data);
          setOpenFiler(false);
        })
        .catch((error: any) => {
          setLoading(false);
          console.error('Login error', error);
        })
        .finally(() => setLoading(false));
      break;
    default:
      break;
    }
  };

  const dataItems = useMemo(
    () =>
      data.map((location1: any) => ({
        id: location1.id,
        value:location1.location +`${ location1?.location_name != null ? ' ' + location1?.location_name : '' }`,
        ...location1
      })),
    [location]
  );
  const items: Array<Item> = dataItems;
  const CustomItem = ({ item }: { item: Item }) => {
    return (
      <div className='z-20 flex justify-between font-avenir55 '>
        {item.location_type === 'zip_code' ? (
          <p className='text-[#676767]'>
            {item.location + ' ' + item?.location_name}
          </p>
        ) : (
          <p className='text-[#676767]'>{item.location}</p>
        )}
        <span className='text-[#676767] text-sm font-normal'>
          {t('landing.' + item.location_type)}
        </span>
      </div>
    );
  };
  const customItems = items.slice(0, index).map((item) => ({
    ...item,
    node: <CustomItem item={item} />,
  }));
  return (
    <div className='mx-4 sm:mx-0 block p-6  shadow-lg bg-darkGray text-center sm:ml-[11%] sm:mr-[11%] font-press-start'>
      <div className='grid grid-cols-1 lg:grid-cols-4 sm:gap-x-10'>
        <div className=''>
          <label className='block text-white text-left text-lg pb-2'>
            {t('propertyListing.type')}
          </label>
          <div className='flex bg-white rounded-md'>
            <select
              name='property_types'
              className='bg-transparent w-full appearance-none px-3 z-10'
              onChange={handleFilterChange}
              value={filterInput?.property_types != undefined ? filterInput?.property_types[0]: 100}
            >
              {/* <option defaultChecked disabled>*/}
              {/*  {t('propertyListing.type')}*/}
              {/* </option>*/}
              <option value={'100'}>
                {t('propertyListing.all')}
              </option>
              {types?.map((prop) => {
                return (
                  <option key={prop.id} value={prop.id}>
                    {localStorage.getItem('locale') === 'de' ? (
                      <>{toArr(prop.name)[0]}</>
                    ) : localStorage.getItem('locale') === 'en' ? (
                      <>{toArr(prop.name)[1]}</>
                    ) : localStorage.getItem('locale') === 'fr' ? (
                      <>{toArr(prop.name)[2]}</>
                    ) : localStorage.getItem('locale') === 'it' ? (
                      <>{toArr(prop.name)[3]}</>
                    ) : (
                      <>{toArr(prop.name)[0]}</>
                    )}
                  </option>
                );
              })}
            </select>
            <div className='z-0'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='2.0'
                stroke='currentColor'
                className='w-6 h-6 -ml-7 mt-0.5'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M19.5 8.25l-7.5 7.5-7.5-7.5'
                />
              </svg>
            </div>
          </div>
        </div>
        <div>
          <label className='block text-white text-left text-lg pb-2'>
            (CHF) {t('propertyListing.priceSearch')}
          </label>
          <div className='flex bg-white rounded-md'>
            <select
              name='price_max'
              className='bg-transparent w-full appearance-none px-3 z-10'
              onChange={handleFilterChange}
              value={filterInput?.price_max == undefined ? '-1' : filterInput?.price_max}
            >
              <option value={'-1'}>
                {t('propertyListing.all')}
              </option>
              {state.type == 'rent' && prices_rent.map((price) => {
                return <option key={price.id} value={price.price}>{price.value}</option>;
              })}
              {state.type == 'sale' && prices.map((price) => {
                return <option key={price.id} value={price.price}>{price.value}</option>;
              })}
              {state.type == 'auction' && prices.map((price) => {
                return <option key={price.id} value={price.price}>{price.value}</option>;
              })}
              {filterInput?.price_max !== undefined && !prices.some(p => p.price === filterInput.price_max) &&
                <option value={filterInput.price_max}>{formattedPriceMax}</option>
              }
            </select>
            <div className='z-0'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='2.0'
                stroke='currentColor'
                className='w-6 h-6 -ml-7 mt-1'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M19.5 8.25l-7.5 7.5-7.5-7.5'
                />
              </svg>
            </div>
          </div>
        </div>
        <div>
          {filterInput != null &&
            (selected === 8 || selected === 11 ? (
              <div>
                <label className='block text-white text-left text-lg pb-2'>
                  {t('propertyListing.plotArea')}
                </label>
                <div className='flex bg-white rounded-md'>
                  <select
                    name='plot_area_min'
                    id=''
                    className='bg-transparent w-full appearance-none px-3 z-10'
                    onChange={handleFilterChange}
                    value={filterInput?.plot_area_min != undefined ? filterInput.plot_area_min : -1}
                  >
                    <option disabled value={-1}>
                      {t('propertyListing.plotArea')}
                    </option>
                    <option value='250'>250 m²</option>
                    <option value='500'>500 m²</option>
                    <option value='750'>750 m²</option>
                    <option value='1000'>1,000 m²</option>
                    <option value='1250'>1,250 m²</option>
                    <option value='1500'>1,500 m²</option>
                    <option value='2000'>2,000 m²</option>
                    <option value='2500'>2,500 m²</option>
                    <option value='3000'>3,000 m²</option>
                  </select>
                  <div className='z-0'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth='2.0'
                      stroke='currentColor'
                      className='w-6 h-6 -ml-7 mt-1'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M19.5 8.25l-7.5 7.5-7.5-7.5'
                      />
                    </svg>
                  </div>
                </div>
              </div>
            ) : selected === 9 ? (
              <div className=''>
                <label className='block text-white text-left text-lg pb-2'>
                  {t('propertyListing.area')} (m²)
                </label>
                <input
                  type='number'
                  name='living_space_min'
                  onChange={handleFilterChange}
                  className='bg-white w-full appearance-none rounded-md py-0.5 px-3 z-10'
                />
              </div>
            ) : (
              <div>
                <label className='block text-white text-left text-lg pb-2'>
                  {t('propertyListing.roomSearch')}
                </label>
                <div className='flex bg-white rounded-md'>
                  <select
                    name='size_rooms_min'
                    id='cars'
                    className='bg-transparent w-full appearance-none px-3 z-10'
                    onChange={handleFilterChange}
                    value={filterInput?.size_rooms_min == undefined ? '100' : filterInput.size_rooms_min}
                  >
                    <option value={'100'}>{t('propertyListing.all')}</option>
                    {numberOfRooms.map((rooms) => {
                      return (
                        <option key={rooms.id} value={rooms.room}>
                          {rooms.room}
                        </option>
                      );
                    })}
                  </select>
                  <div className='z-0'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth='2.0'
                      stroke='currentColor'
                      className='w-6 h-6 -ml-7 mt-1'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M19.5 8.25l-7.5 7.5-7.5-7.5'
                      />
                    </svg>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className='self-end sm:space-x-2 flex justify-end sm:flex space-x-3 mt-5 lg:mt-0'>
          <button
            className='inline-block px-7 py-2 bg-white text-darkGray border border-white font-medium text-xs leading-tight capitalize rounded-lg shadow-md hover:shadow-2xl focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out '
            onClick={handleOpenFilter}
          >
            {t('propertyListing.filter')}
          </button>
          {openFilter ? (
            <div className='relative z-20'>
              <div className='fixed inset-0 bg-black bg-opacity-80 transition-opacity z-20 '></div>
              <div
                className='fixed z-20  inset-0 sm:mt-10'
                onClick={handleOpenFilter}
              >
                <div className='flex justify-center text-center sm:mx-[208px] sm:mb-5'>
                  <div className='' onClick={(e) => e.stopPropagation()}>
                    <div className='bg-white flex justify-between px-[25px] pt-[8px]'>
                      <p className='text-xl pt-2 font-bold'>
                        {t('propertyListing.filter')}
                      </p>
                      <button onClick={handleOpenFilter}>
                        <svg
                          width='16'
                          height='16'
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M16 13.4587L10.4567 7.986L15.9233 2.46467L13.4587 0L7.984 5.54533L2.444 0.0766667L0 2.52067L5.54733 8.014L0.0766667 13.556L2.52067 16L8.012 10.4547L13.5353 15.9233L16 13.4587Z'
                            fill='black'
                          />
                        </svg>
                      </button>
                    </div>
                    <section className='bg-white text-darkGray sm:w-[700px] px-[25px] pt-[15px] pb-[25px] h-[570px] overflow-y-auto'>
                      <div className='pb-[5px]'>
                        <div className='block'>
                          <label className='block text-left font-bold'>
                            {t('propertyListing.location')}
                          </label>
                          <div className='flex z-20 relative bg-white data-list-filter-location'>
                            <DatalistInput
                              className='z-10'
                              label=''
                              items={customItems}
                              onClick={() => setIndex(6)}
                              onSelect={(e:any) => {
                                setFilterInput((prev:any)=>({
                                  ...prev,
                                  location: e.location,
                                  location_type: e.location_type
                                }));
                              }}
                              onChange={(e:any) => {
                                setFilterInput((prev:any)=>({
                                  ...prev,
                                  location: e.location,
                                  location_type: e.location_type
                                }));
                                setIndex(index + 5000);
                              }}
                              value={filterInput?.location}
                            />
                            <div className='absolute right-0'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                                strokeWidth='2.0'
                                stroke='currentColor'
                                className='w-6 h-6 -ml-8 mt-3'
                              >
                                <path
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  d='M19.5 8.25l-7.5 7.5-7.5-7.5'
                                />
                              </svg>
                            </div>
                          </div>
                          {/* <div className='flex'>
                            <select
                              onChange={handleFilterChange}
                              name='location'
                              className='appearance-none bg-transparent block border border-[#D6D6D6] focus:outline-none focus:border-black rounded-md w-full sm:h-[33px]  cursor-pointer'
                            >
                              <option disabled selected hidden></option>
                              <option value='Bern'>Bern</option>
                              <option value='Zürich'>Zürich</option>
                              <option value='Geneva'>Geneva</option>
                              <option value='Lausanne'>Lausanne</option>
                              <option value='Basel'>Basel</option>
                              <option value='Lucerne'>Lucerne</option>
                              <option value='St.Gallen'>St.Gallen</option>
                              <option value='Zermatt'>Zermatt</option>
                              <option value='Romanshorn'>Romanshorn</option>
                            </select>
                            <div className='z-0'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                                strokeWidth='2.0'
                                stroke='currentColor'
                                className='w-6 h-6 -ml-6 mt-1'
                              >
                                <path
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  d='M19.5 8.25l-7.5 7.5-7.5-7.5'
                                />
                              </svg>
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div>
                        <label className='block text-left font-bold'>
                          Radius
                        </label>
                        <div className='grid grid-cols-2'>
                          <div className='flex relative bg-white'>
                            <select
                              name='radius'
                              className='bg-transparent z-10 w-full appearance-none px-3 block border border-[#D6D6D6] focus:outline-none focus:border-black rounded-md  sm:h-[33px]  cursor-pointer'
                              onChange={handleFilterChange}
                              value={filterInput?.radius != undefined ? filterInput.radius : -1}
                            >
                              <option value={-1} disabled  hidden></option>
                              {radius.map((radius) => {
                                return (
                                  <option key={radius.id} value={radius.radius}>
                                    {radius.radius}
                                  </option>
                                );
                              })}
                            </select>
                            <div className='absolute right-0 z-0'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                                strokeWidth='2.0'
                                stroke='currentColor'
                                className='w-6 h-6 -ml-7 mt-0.5'
                              >
                                <path
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  d='M19.5 8.25l-7.5 7.5-7.5-7.5'
                                />
                              </svg>
                            </div>
                          </div>
                          <div></div>
                        </div>
                      </div>
                      <div className='pt-5 sm:pt-[30px] sm:space-y-[30px] '>
                        <div className='block sm:flex space-y-5 sm:space-y-0 justify-between'>
                          <div>
                            <label className='block text-left font-bold'>
                              {t('propertyListing.minPrice')}
                            </label>
                            <input
                              type='text'
                              name='price_min'
                              value={formattedPriceMin}
                              onChange={handleFilterChange}
                              className='border w-full space-x-3 sm:w-[300px] sm:h-[33px] border-[#D6D6D6] focus:outline-none focus:border-black rounded-md'
                            />
                          </div>
                          <div>
                            <label className='block text-left font-bold'>
                              {t('propertyListing.maxPrice')}
                            </label>
                            <input
                              type='text'
                              name='price_max'
                              value={filterInput?.price_max == null ? '' : formattedPriceMax}
                              onChange={handleFilterChange}
                              className='border w-full space-x-3 sm:w-[300px] sm:h-[33px] border-[#D6D6D6] focus:outline-none focus:border-black rounded-md'
                            />
                          </div>
                        </div>
                        <div className='pt-5 sm:pt-0 block sm:flex space-y-5 sm:space-y-0 justify-between'>
                          <div>
                            <label className='block text-left font-bold'>
                              {t('propertyListing.minRooms')}
                            </label>
                            <div className='flex relative bg-white'>
                              <select
                                name='size_rooms_min'
                                className='bg-transparent z-10 w-full appearance-none px-3 block border border-[#D6D6D6] focus:outline-none focus:border-black rounded-md sm:w-[300px] sm:h-[33px]  cursor-pointer'
                                onChange={handleFilterChange}
                                value={filterInput?.size_rooms_min != undefined ? filterInput.size_rooms_min : '100'}
                              >
                                <option value={'100'}>
                                  {t('propertyListing.all')}
                                </option>
                                {numberOfRooms.map((rooms) => {
                                  return (
                                    <option key={rooms.id} value={rooms.room}>
                                      {rooms.room}
                                    </option>
                                  );
                                })}
                              </select>
                              <div className='z-0 absolute right-0'>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  fill='none'
                                  viewBox='0 0 24 24'
                                  strokeWidth='2.0'
                                  stroke='currentColor'
                                  className='w-6 h-6 -ml-7 mt-1'
                                >
                                  <path
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    d='M19.5 8.25l-7.5 7.5-7.5-7.5'
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                          <div>
                            <label className='block text-left font-bold'>
                              {t('propertyListing.maxRooms')}
                            </label>
                            <div className='flex relative bg-white'>
                              <select
                                name='size_rooms_max'
                                className='z-10 bg-transparent w-full appearance-none px-3 block border border-[#D6D6D6] focus:outline-none focus:border-black rounded-md sm:w-[300px] sm:h-[33px]  cursor-pointer'
                                onChange={handleFilterChange}
                                value={filterInput?.size_rooms_max != undefined ? filterInput?.size_rooms_max : '100'}
                              >
                                <option value={'100'}>
                                  {t('propertyListing.all')}
                                </option>
                                {numberOfRooms.map((rooms) => {
                                  return (
                                    <option key={rooms.id} value={rooms.room}>
                                      {rooms.room}
                                    </option>
                                  );
                                })}
                              </select>
                              <div className='z-0 absolute right-0'>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  fill='none'
                                  viewBox='0 0 24 24'
                                  strokeWidth='2.0'
                                  stroke='currentColor'
                                  className='w-6 h-6 -ml-7 mt-1'
                                >
                                  <path
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    d='M19.5 8.25l-7.5 7.5-7.5-7.5'
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='pt-5 sm:pt-0 sm:flex space-y-5 sm:space-y-0 justify-between'>
                          <div className=''>
                            <label className='block text-left font-bold'>
                              {t('propertyListing.minSpace')} (m²)
                            </label>
                            <input
                              type='number'
                              name='living_space_min'
                              onChange={handleFilterChange}
                              className='border w-full sm:w-[300px] px-3 sm:h-[33px] border-[#D6D6D6] focus:outline-none focus:border-black rounded-md'
                            />
                          </div>
                          <div className=''>
                            <label className='block text-left font-bold'>
                              {t('propertyListing.maxSpace')} (m²)
                            </label>
                            <input
                              type='number'
                              name='living_space_max'
                              onChange={handleFilterChange}
                              className='border w-full sm:w-[300px] px-3 sm:h-[33px] border-[#D6D6D6] focus:outline-none focus:border-black rounded-md'
                            />
                          </div>
                        </div>
                      </div>
                      {openAdvancedSearch ? (
                        <div className=''>
                          <hr className='border border-[#D6D6D6] mt-8 sm:mt-[34px]' />
                          <div className=''>
                            <div className='text-left'>
                              <p className='pt-[34px] font-bold'>
                                {t('propertyListing.characteristics')}
                              </p>
                            </div>
                            <div className='space-y-3'>
                              <div className='pt-[11px] space-x-0 sm:space-x-4 block sm:flex justify-between'>
                                <div className='grid grid-cols-1 sm:grid-cols-2 gap-1 gap-x-10 text-left'>
                                  {filterInput?.property_types?.includes(8) ?
                                    propertyChars?.filter((char: { id: number; }) => (char.id == 5) || (char.id == 8) || (char.id == 14)).map((filteredChars: any) => {
                                      return (
                                        <label
                                          key={filteredChars.id}
                                          className='labels pr-10 text-darkGray font-sm font-bold'
                                        >
                                          {localStorage.getItem('locale') === 'de' ? (
                                            <p>{toArr(filteredChars.char)[0]}</p>
                                          ) : localStorage.getItem('locale') === 'en' ? (
                                            <p>{toArr(filteredChars.char)[1]}</p>
                                          ) : localStorage.getItem('locale') === 'fr' ? (
                                            <p>{toArr(filteredChars.char)[2]}</p>
                                          ) : localStorage.getItem('locale') === 'it' ? (
                                            <p>{toArr(filteredChars.char)[3]}</p>
                                          ) : <>{toArr(filteredChars.char)[0]}</>}
                                          <input
                                            type='checkbox'
                                            value={filteredChars.id}
                                            className='h-6 w-6 absolute'
                                            name='property_chars'
                                            onChange={handleCheckedChars}
                                            checked={filterInput?.property_chars?.includes(filteredChars.id)}
                                          />
                                          <span className='checks'></span>
                                        </label>
                                      );
                                    }) : filterInput?.property_types?.includes(9) ?
                                      propertyChars?.filter((char: { id: number; }) => (char.id == 1) || (char.id == 2) || (char.id == 3) || (char.id == 14)).map((filteredChars: any) => {
                                        return (
                                          <label
                                            key={filteredChars.id}
                                            className='labels pr-10 text-darkGray font-sm font-bold'
                                          >
                                            {localStorage.getItem('locale') === 'de' ? (
                                              <p>{toArr(filteredChars.char)[0]}</p>
                                            ) : localStorage.getItem('locale') === 'en' ? (
                                              <p>{toArr(filteredChars.char)[1]}</p>
                                            ) : localStorage.getItem('locale') === 'fr' ? (
                                              <p>{toArr(filteredChars.char)[2]}</p>
                                            ) : localStorage.getItem('locale') === 'it' ? (
                                              <p>{toArr(filteredChars.char)[3]}</p>
                                            ) : <>{toArr(filteredChars.char[0])}</>}
                                            <input
                                              type='checkbox'
                                              value={filteredChars.id}
                                              className='h-6 w-6 absolute'
                                              name='property_chars'
                                              onChange={handleCheckedChars}
                                              checked={filterInput?.property_chars?.includes(filteredChars.id)}
                                            />
                                            <span className='checks'></span>
                                          </label>
                                        );
                                      })
                                      : filterInput?.property_types?.includes(10) ?
                                        propertyChars?.filter((char: { id: number; }) => (char.id != 4) && (char.id != 11)).map((filteredChars: any) => {
                                          return (
                                            <label
                                              key={filteredChars.id}
                                              className='labels pr-10 text-darkGray font-sm font-bold'
                                            >
                                              {localStorage.getItem('locale') === 'de' ? (
                                                <p>{toArr(filteredChars.char)[0]}</p>
                                              ) : localStorage.getItem('locale') === 'en' ? (
                                                <p>{toArr(filteredChars.char)[1]}</p>
                                              ) : localStorage.getItem('locale') === 'fr' ? (
                                                <p>{toArr(filteredChars.char)[2]}</p>
                                              ) : localStorage.getItem('locale') === 'it' ? (
                                                <p>{toArr(filteredChars.char)[3]}</p>
                                              ) : <>{toArr(filteredChars.char[0])}</>}
                                              <input
                                                type='checkbox'
                                                value={filteredChars.id}
                                                className='h-6 w-6 absolute'
                                                name='property_chars'
                                                onChange={handleCheckedChars}
                                                checked={filterInput?.property_chars?.includes(filteredChars.id)}
                                              />
                                              <span className='checks'></span>
                                            </label>
                                          );
                                        }) :
                                        filterInput?.property_types?.includes(11) ?
                                          propertyChars?.filter((char: { id: number; }) => (char.id == 8) || (char.id == 5) || (char.id == 14)).map((filteredChars: any) => {
                                            return (
                                              <label
                                                key={filteredChars.id}
                                                className='labels pr-10 text-darkGray font-sm font-bold'
                                              >
                                                {localStorage.getItem('locale') === 'de' ? (
                                                  <p>{toArr(filteredChars.char)[0]}</p>
                                                ) : localStorage.getItem('locale') === 'en' ? (
                                                  <p>{toArr(filteredChars.char)[1]}</p>
                                                ) : localStorage.getItem('locale') === 'fr' ? (
                                                  <p>{toArr(filteredChars.char)[2]}</p>
                                                ) : localStorage.getItem('locale') === 'it' ? (
                                                  <p>{toArr(filteredChars.char)[3]}</p>
                                                ) : <>{toArr(filteredChars.char)[0]}</>}
                                                <input
                                                  type='checkbox'
                                                  value={filteredChars.id}
                                                  className='h-6 w-6 absolute'
                                                  name='property_chars'
                                                  onChange={handleCheckedChars}
                                                  checked={filterInput?.property_chars?.includes(filteredChars.id)}
                                                />
                                                <span className='checks'></span>
                                              </label>
                                            );
                                          })
                                          :
                                          propertyChars?.map((char: any) => {
                                            return (
                                              <label
                                                key={char.id}
                                                className='labels pr-10 text-darkGray font-sm font-bold'
                                              >
                                                {localStorage.getItem('locale') === 'de' ? (
                                                  <p>{toArr(char.char)[0]}</p>
                                                ) : localStorage.getItem('locale') === 'en' ? (
                                                  <p>{toArr(char.char)[1]}</p>
                                                ) : localStorage.getItem('locale') === 'fr' ? (
                                                  <p>{toArr(char.char)[2]}</p>
                                                ) : localStorage.getItem('locale') === 'it' ? (
                                                  <p>{toArr(char.char)[3]}</p>
                                                ) : <>{toArr(char.char)[0]}</>}
                                                <input
                                                  type='checkbox'
                                                  value={char.id}
                                                  className='h-6 w-6 absolute'
                                                  name='property_chars'
                                                  onChange={handleCheckedChars}
                                                  checked={filterInput?.property_chars?.includes(char.id)}
                                                />
                                                <span className='checks'></span>
                                              </label>
                                            );
                                          })
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='mt-5'>
                            <div className='text-left font-bold'>
                              {t('propertyListing.availability')}
                            </div>
                            <div className='mt-5 text-left grid grid-cols-1 sm:grid-cols-2 space-y-5 sm:space-y-0'>
                              <div className='inline-block'>
                                <div>
                                  <label>{t('propertyListing.from')}</label>
                                </div>
                                <input
                                  onChange={handleFilterChange}
                                  type='datetime-local'
                                  name='available_from'
                                  className='border-b-2 w-full border-gray-200 focus:text-darkGray focus:bg-white focus:outline-none focus:border-b-black'
                                />
                              </div>
                            </div>
                          </div>
                          <div className='flex space-x-8 sm:justify-between mt-12'>
                            <div className='self-center'>
                              <button
                                className='font-medium'
                                onClick={handleAdvancedSearch}
                              >
                                {t('propertyListing.closeSearch')}
                              </button>
                            </div>
                            <div className=''>
                              <button
                                onClick={handleFilter}
                                className='flex bg-darkGray text-white space-x-3 px-6 py-3 rounded-lg'
                              >
                                <p className='text-xs'>
                                  {t('propertyListing.showProperties')}
                                </p>
                                <img
                                  src={rightArrow}
                                  alt=''
                                  className='self-center'
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className='flex space-x-8 justify-between mt-10 sm:mt-[85px]'>
                          <div className='self-center'>
                            <button
                              className='font-medium'
                              onClick={handleAdvancedSearch}
                            >
                              {t('propertyListing.advancedsearch')}
                            </button>
                          </div>
                          <div className=''>
                            <button
                              onClick={handleFilter}
                              className='flex bg-darkGray text-white space-x-3 px-6 py-3 rounded-lg'
                            >
                              <p className='text-xs'>
                                {t('propertyListing.showProperties')}
                              </p>
                              <img
                                src={rightArrow}
                                alt=''
                                className='self-center'
                              />
                            </button>
                          </div>
                        </div>
                      )}
                    </section>
                    <div className='flex justify-center pt-5'>
                      <button
                        onClick={() => {
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          setFilterInput(()=>({
                          }));
                        }
                        }
                        className='text-white'
                      >
                        {t('propertyListing.reset')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <button
            onClick={handleFilter}
            className='inline-block px-7 py-2 bg-transparent text-white border border-white font-medium text-xs leading-tight capitalize rounded-lg shadow-md s hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out '
          >
            {t('propertyListing.search')}
          </button>
        </div>
      </div>
    </div>
  );
};

export { PropertySearch };
