import React, { useState, useEffect } from 'react';
import './PropertySaleDetails.css';
import { TrendingEstate } from '../Landing/TrendingEstate';
import Image1 from '../../images/ArrowTop.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { EditClub, Property } from '../../config';
import { propertyServices } from '../../services/propertyServices';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Keyboard } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import {
  DistanceMatrixService,
  GoogleMap,
  LoadScript,
  Marker,
} from '@react-google-maps/api';
import { utils } from '../../helpers/utils';
import { PrefferedLocationModal } from '../PropertyDetails/PrefferedLocationModal';
import { LoadingTest } from '../LoadingTest';
import { userServices } from '../../services';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { utilsProperty } from '../PropertyDetails/utils';

interface ParamsType {
  id: string;
}

const PropertySaleDetails = () => {
  const [propertySaleDetails, setPropertySaleDetails] = useState<Property>(
      {} as Property
  );
  const navigate = useNavigate();
  const { t } = useTranslation();

  const params = useParams<Required<ParamsType>>();
  const [modal, setModal] = useState(false);
  const [scriptLoaded, setScriptLoaded] = useState(false);

  const [prefferedLocation, setPrefferedLocation] = useState({
    lat: 0,
    lng: 0,
    name: '',
  });
  const [drivingDistance, setDrivingDistance] = useState('');
  const [transitDistance, setTransitDistance] = useState('');
  const [walkingDistance, setWalkingDistance] = useState('');
  const [loading, setLoading] = useState(true);

  const handleDistance = (data: any, type: string) => {
    if (data != null) {
      const { rows } = data;
      if (rows[0].elements[0].status !== 'ZERO_RESULTS') {
        if (type === 'driving') {
          const driving = rows[0].elements[0].duration.text.replace(/days?/g, 'd').replace(/hours?/g, 'h').replace(/mins?/g, 'min');
          setDrivingDistance(driving);
        }
        if (type === 'transit') {
          const transit = rows[0].elements[0].duration.text.replace(/days?/g, 'd').replace(/hours?/g, 'h').replace(/mins?/g, 'min');
          setTransitDistance(transit);
        }
        if (type === 'walking') {
          const walking = rows[0].elements[0].duration.text.replace(/days?/g, 'd').replace(/hours?/g, 'h').replace(/mins?/g, 'min');
          setWalkingDistance(walking);
        }
      }
    }
  };


  useEffect(() => {
    setLoading(true);
    if (!localStorage.getItem('user_id')) {
      propertyServices
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .getSaleDetailsProperties(parseInt(params.id!))
        .then((response) => {
          // window.scrollTo(0, 0);
          setPropertySaleDetails(response.data);
          setLoading(false);
        })
        .catch((error) => {
          // console.log('Failed', error);
          navigate('/property/error');
        })
        .finally(() => setLoading(false));
    } else {
      propertyServices
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .getSaleDetailsAuth(parseInt(params.id!))
        .then((response) => {
          // window.scrollTo(0, 0);
          setPropertySaleDetails(response.data);
          setLoading(false);
        })
        .catch((error) => {
          // console.log('Failed', error);
          navigate('/property/error');
        })
        .finally(() => setLoading(false));
    }
  }, []);

  const initialState = {
    full_name: '',
    email: '',
    phone: '',
    message: '',
    city: '',
    zip: '',
    property_id: '',
    type: 'sale'
  };
  const [emailContent, setEmailContent] = React.useState(initialState);
  const [error, setError] = useState<string[]>([]);

  const handleChanges = (e: any) => {
    const errorList:string[] = [];
    setError(errorList);
    setEmailContent({ ...emailContent, [e.target.name]: e.target.value });
  };
  const validZip = new RegExp(/^(\d{4})$/);
  const checkEmailFormat = (email: any) => {
    return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
  };
  
  
  const submitContact = () => {
    const errorList:string[] = [];
    if (!emailContent.full_name) {
      errorList.push('full_name');
    }  else if (!emailContent.email) {
      errorList.push('email');
    } else if (!checkEmailFormat(emailContent?.email)) {
      errorList.push('email_incorrect');
    } else if (!emailContent.phone) {
      errorList.push('phone');
    } else if (!emailContent.zip) {
      errorList.push('zip');
    } else if (!emailContent.city) {
      errorList.push('city');
    } else if (!emailContent.message) {
      errorList.push('message');
    }
    else {
      handleContactSale();
    }
    setError(errorList);
  };

  const handleContactSale = () => {
    const fixBody = {
      ...emailContent,
      property_id: propertySaleDetails.id };
    propertyServices
      .contactSale(fixBody)
      .then((response: any) => {
        setEmailContent(response.data);
        toast.success(t('errors.contactS'), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setEmailContent(initialState);
        // setTimeout(() => {
        //   navigate(0);
        // }, 3000);
      })
      .catch((error: any) => {
        // console.log('error pra', error);
        toast.error(t('errors.contactE'), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };

  const [editModeTitle, setEditModeTitle] = useState(false);
  const [editModePrice, setEditModePrice] = useState(false);
  const [editModeDescription, setEditModeDescription] = useState(false);

  const [editDataSale, setEditDataSale] = useState<EditClub>({
  } as EditClub);


  const [userSale, setUserSale] = useState({
    id: null,
  });

  useEffect(() => {
    userServices
      .userAuth()
      .then((response) => {
        setUserSale(response.data);
        return response;
      })
      .catch((error: any) => {
        console.error('User could not found: ', error);
      });
  }, []);

  const toArr = (name: any) => {
    const newArr = name.split('&');
    return newArr;
  };

  const handleEdit = () => {
    propertyServices
      .editSaleProperty(propertySaleDetails.id, editDataSale)
      .then((response: any) => {
        setEditDataSale(response.data);
        toast.success(t('errors.editPropertyS'), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        navigate(0);
      })
      .catch((error: any) => {
        // console.log('error pra', error);
        toast.error(t('errors.editPropertyE'), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };

  if (loading) {
    return <LoadingTest />;
  }


  return (
    <div className='bg-black font-press-start'>
      <ToastContainer autoClose={4000} />
      <div className='bg-white space-y-2 sm:space-y-6 text-darkGray'>
        <div className='px-5 sm:px-28 pt-5 pb-10 sm:pb-7 sm:pt-6'>
          <button
            onClick={() => navigate(-1)}
            className='backButton bg-darkGray rounded px-1 hover:text-gray-400 text-sm'
          >
            <span className='backIcon'>
              <svg
                width='15'
                height='10'
                viewBox='0 0 15 10'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M4.10354 0.19887L0.18055 4.51989C-0.0601831 4.78505 -0.0601831 5.21495 0.18055 5.48011L4.10354 9.80113C4.34428 10.0663 4.73458 10.0663 4.97532 9.80113C5.21605 9.53597 5.21605 9.10606 4.97532 8.8409L2.10465 5.67898L15 5.67898L15 4.32102L2.10465 4.32102L4.97532 1.1591C5.21605 0.893937 5.21605 0.46403 4.97532 0.19887C4.73458 -0.0662889 4.34427 -0.0662889 4.10354 0.19887Z'
                  fill='white'
                />
              </svg>
            </span>
            <span className='backText text-white'>
              {t('propertyDetails.back')}
            </span>
          </button>
        </div>
        <div className='block sm:hidden mx-4'>
          <div className='flex justify-between border-b border-darkGray'>
            <div className='pb-4'>
              <p className='text-[24px] font-bold'>
                {utilsProperty.generateTitle(propertySaleDetails, t)}
              </p>
              {editModeTitle ?
                <div className='flex'>
                  <input name='title' defaultValue={propertySaleDetails.property.title}
                    className='block my-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-black peer required'
                    onChange={(e: any) => setEditDataSale({
                      ...editDataSale,
                      title: e.target.value,
                    })}
                  />
                  <div className='self-center'>
                    <button onClick={handleEdit} className='ml-3 p-2 bg-darkGray rounded-md self-center hover:bg-opacity-95'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='white'
                        viewBox='0 0 512 512'
                        strokeWidth='0.5'
                        stroke='currentColor'
                        className='w-4 h-4'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z'
                        />
                      </svg>
                    </button>
                  </div>
                  <div className='self-center'>
                    <button onClick={() => setEditModeTitle(false)} className='ml-2 p-2 bg-darkGray rounded-md hover:bg-opacity-95'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='white'
                        viewBox='0 0 320 512'
                        strokeWidth='0.5'
                        stroke='currentColor'
                        className='w-4 h-4'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z'
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                : <div className='flex space-x-3'><p className='text-[18px]'>
                  {propertySaleDetails.property?.title}
                </p>
                {userSale.id == propertySaleDetails.seller_id ?
                  <button onClick={() => setEditModeTitle(true)}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='#FF002B'
                      viewBox='0 0 24 22'
                      strokeWidth='0.5'
                      stroke='currentColor'
                      className='w-6 h-6'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M18 13.45V20H2V8H8.743L10.721 6H0V22H20V11.427L18 13.45ZM19.473 2.835L21.18 4.542L11.899 13.92L9.669 14.392L10.181 12.223L19.473 2.835ZM19.465 0L8.361 11.216L7 17L12.898 15.752L24.001 4.534L19.465 0Z'
                      />
                    </svg>
                  </button>
                  : '' }
                </div>
              }
              {editModePrice ?
                <div className='flex'>
                  <input name='price' defaultValue={propertySaleDetails.price}
                    className='block my-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-black peer required'
                    onChange={(e: any) => setEditDataSale({
                      ...editDataSale,
                      price: e.target.value,
                    })}
                  />
                  <div className='self-center'>
                    <button onClick={handleEdit} className='ml-3 p-2 bg-darkGray rounded-md hover:bg-opacity-95'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='white'
                        viewBox='0 0 512 512'
                        strokeWidth='0.5'
                        stroke='currentColor'
                        className='w-4 h-4'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z'
                        />
                      </svg>
                    </button>
                  </div>
                  <div className='self-center'>
                    <button onClick={() => setEditModePrice(false)} className='ml-2 p-2 bg-darkGray rounded-md hover:bg-opacity-95'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='white'
                        viewBox='0 0 320 512'
                        strokeWidth='0.5'
                        stroke='currentColor'
                        className='w-4 h-4'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z'
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                :
                <div className='flex space-x-3'>
                  <p className='sm:pt-4 text-[24px] border-b-2 border-red-600 font-bold'>
                      CHF {utils.numberWithComma(propertySaleDetails.price)}.—
                  </p>
                  {userSale.id == propertySaleDetails.seller_id ?
                    <button onClick={() => setEditModePrice(true)}>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='#FF002B'
                        viewBox='0 0 24 22'
                        strokeWidth='0.5'
                        stroke='currentColor'
                        className='w-6 h-6'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M18 13.45V20H2V8H8.743L10.721 6H0V22H20V11.427L18 13.45ZM19.473 2.835L21.18 4.542L11.899 13.92L9.669 14.392L10.181 12.223L19.473 2.835ZM19.465 0L8.361 11.216L7 17L12.898 15.752L24.001 4.534L19.465 0Z'
                        />
                      </svg>
                    </button>
                    : '' }
                </div>
              }
            </div>
          </div>
        </div>
        <div className='px-4 grid grid-cols-1 gap-0 space-y-2 sm:grid sm:grid-cols-1 md:grid md:grid-cols-1 lg:grid lg:grid-cols-3 sm:gap-10 sm:px-28'>
          <div className='rounded-lg col-span-2'>
            <Swiper
              modules={[Autoplay, Navigation, Keyboard]}
              navigation={true}
              keyboard={true}
              className='rounded-md'
              spaceBetween={50}
            >
              {propertySaleDetails.images?.map((images, index) => {
                return (
                  <SwiperSlide key={index} className='m-auto'>
                    <img
                      className='sm:h-[580px] m-auto rounded-md'
                      src={images.url}
                      alt=''
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <div>
            <LoadScript
              googleMapsApiKey="AIzaSyCh4EhLz4T2hhTU6afaw0cpNZ-11HBIyg8" // Replace with your actual API Key
              libraries={['places']} // Add other libraries as needed
              onLoad={() => setScriptLoaded(true)}
            >
              <GoogleMap
                center={{
                  lat: propertySaleDetails.property?.address.latitude,
                  lng: propertySaleDetails.property?.address.longitude,
                }}
                mapContainerClassName='map-container'
                zoom={14}
              >
                <Marker
                  position={{
                    lat: propertySaleDetails.property?.address.latitude,
                    lng: propertySaleDetails.property?.address.longitude,
                  }}
                />
                {prefferedLocation != null && scriptLoaded && (
                  <div>
                    <DistanceMatrixService
                      options={{
                        destinations: [prefferedLocation],
                        origins: [
                          {
                            lng: propertySaleDetails.property?.address.longitude,
                            lat: propertySaleDetails.property?.address.latitude,
                          },
                        ],
                        travelMode: google.maps.TravelMode.DRIVING,
                      }}
                      callback={(response: any) => {
                        handleDistance(response, 'driving');
                      }}
                    />
                    <DistanceMatrixService
                      options={{
                        destinations: [prefferedLocation],
                        origins: [
                          {
                            lng: propertySaleDetails.property?.address.longitude,
                            lat: propertySaleDetails.property?.address.latitude,
                          },
                        ],
                        travelMode: google.maps.TravelMode.WALKING,
                      }}
                      callback={(response: any) => {
                        handleDistance(response, 'walking');
                      }}
                    />
                    <DistanceMatrixService
                      options={{
                        destinations: [prefferedLocation],
                        origins: [
                          {
                            lng: propertySaleDetails.property?.address.longitude,
                            lat: propertySaleDetails.property?.address.latitude,
                          },
                        ],
                        travelMode: google.maps.TravelMode.TRANSIT,
                      }}
                      callback={(response: any) => {
                        handleDistance(response, 'transit');
                      }}
                    />
                  </div>
                )}
              </GoogleMap>
            </LoadScript>
          </div>
        </div>
        <div className='px-4 grid grid-cols-1 gap-2 sm:grid sm:grid-cols-1 md:grid md:grid-cols-1 lg:grid lg:grid-cols-3 sm:px-28 sm:gap-10'>
          <div className='hidden sm:flex justify-between divide-x-2 border-b border-darkGray sm:col-span-1 md:col-span-1 lg:col-span-2 '>
            <div className='pb-4'>
              <p className='text-[24px] font-bold'>
                {utilsProperty.generateTitle(propertySaleDetails, t)}
              </p>
              {editModeTitle ?
                <div className='flex'>
                  <input name='title' defaultValue={propertySaleDetails.property.title}
                    className='block my-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-black peer required'
                    onChange={(e: any) => setEditDataSale({
                      ...editDataSale,
                      title: e.target.value,
                    })}
                  />
                  <div className='self-center'>
                    <button onClick={handleEdit} className='ml-3 p-2 bg-darkGray rounded-md hover:bg-opacity-95'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='white'
                        viewBox='0 0 512 512'
                        strokeWidth='0.5'
                        stroke='currentColor'
                        className='w-4 h-4'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z'
                        />
                      </svg>
                    </button>
                  </div>
                  <div className='self-center'>
                    <button onClick={() => setEditModeTitle(false)} className='ml-2 p-2 bg-darkGray rounded-md hover:bg-opacity-95'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='white'
                        viewBox='0 0 320 512'
                        strokeWidth='0.5'
                        stroke='currentColor'
                        className='w-4 h-4'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z'
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                : <div className='flex space-x-3'><p className='text-[18px]'>
                  {propertySaleDetails.property?.title}
                </p>
                {userSale.id == propertySaleDetails.seller_id ?
                  <button onClick={() => setEditModeTitle(true)}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='#FF002B'
                      viewBox='0 0 24 22'
                      strokeWidth='0.5'
                      stroke='currentColor'
                      className='w-6 h-6'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M18 13.45V20H2V8H8.743L10.721 6H0V22H20V11.427L18 13.45ZM19.473 2.835L21.18 4.542L11.899 13.92L9.669 14.392L10.181 12.223L19.473 2.835ZM19.465 0L8.361 11.216L7 17L12.898 15.752L24.001 4.534L19.465 0Z'
                      />
                    </svg>
                  </button>
                  : '' }
                </div>
              }
              {editModePrice ?
                <div className='flex'>
                  <input name='price' defaultValue={propertySaleDetails.price}
                    className='block my-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-black peer required'
                    onChange={(e: any) => setEditDataSale({
                      ...editDataSale,
                      price: e.target.value,
                    })}
                  />
                  <div className='self-center'>
                    <button onClick={handleEdit} className='ml-3 p-2 bg-darkGray rounded-md hover:bg-opacity-95'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='white'
                        viewBox='0 0 512 512'
                        strokeWidth='0.5'
                        stroke='currentColor'
                        className='w-4 h-4'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z'
                        />
                      </svg>
                    </button>
                  </div>
                  <div className='self-center'>
                    <button onClick={() => setEditModePrice(false)} className='ml-2 p-2 bg-darkGray rounded-md hover:bg-opacity-95'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='white'
                        viewBox='0 0 320 512'
                        strokeWidth='0.5'
                        stroke='currentColor'
                        className='w-4 h-4'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z'
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                :
                <div className='flex space-x-3'>
                  <p className='sm:pt-4 text-[24px] border-b-2 border-red-600 font-bold'>
                      CHF {utils.numberWithComma(propertySaleDetails.price)}.—
                  </p>
                  {userSale.id == propertySaleDetails.seller_id ?
                    <button onClick={() => setEditModePrice(true)}>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='#FF002B'
                        viewBox='0 0 24 22'
                        strokeWidth='0.5'
                        stroke='currentColor'
                        className='w-6 h-6'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M18 13.45V20H2V8H8.743L10.721 6H0V22H20V11.427L18 13.45ZM19.473 2.835L21.18 4.542L11.899 13.92L9.669 14.392L10.181 12.223L19.473 2.835ZM19.465 0L8.361 11.216L7 17L12.898 15.752L24.001 4.534L19.465 0Z'
                        />
                      </svg>
                    </button>
                    : '' }
                </div>
              }
            </div>
          </div>
          <div className='advertiser text-center py-2 my-4 sm:my-0 sm:col-span-1 sm:py-7 '>
            <p className='font-serif text-xl'>
              {t('propertyDetails.advertiser')}
            </p>
            <button className='px-6 py-2 rounded-md text-white text-2xl bg-darkGray '>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-7 w-7 absolute '
                viewBox='0 0 20 20'
                fill='currentColor'
              >
                <path d='M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z' />
              </svg>
              <p className='pl-10 text-xl'>
                {propertySaleDetails.seller_info?.phone}
              </p>
            </button>
          </div>
        </div>
        <div className='px-4 grid grid-cols-1 sm:grid sm:grid-cols-1 md:grid md:grid-cols-1 lg:grid lg:grid-cols-3 sm:gap-10 sm:px-28 rounded-lg  '>
          <div className='sm:col-span-1 md:col-span-1 lg:col-span-2 '>
            <div className='py-5 sm:py-0 sm:pb-4 flex justify-between border-b border-darkGray'>
              <div className='flex text-[16px] font-bold'>
                {propertySaleDetails.property?.address.formatted}
              </div>
            </div>
            {modal && (
              <PrefferedLocationModal
                setModal={setModal}
                prefferdLocation={prefferedLocation}
                setPrefferedLocation={setPrefferedLocation}
              />
            )}
            {prefferedLocation.name == '' ? (
              <div className='flex items-center space-x-5 py-7  border-b border-darkGray'>
                <div
                  className='flex hover:bg-gray-100 font-bold rounded-md px-2 py-2 transition-all ease-in-out duration-200 cursor-pointer text-gray-500'
                  onClick={() => setModal(true)}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth='1.5'
                    stroke='currentColor'
                    className='w-6 h-6'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125'
                    />
                  </svg>
                  <p className='ml-1'>
                    {t('propertyDetails.calculateDistance')}
                  </p>
                </div>
              </div>
            ) : (
              <div className='flex justify-between space-x-5 py-7  border-b border-darkGray'>
                <button onClick={() => setModal(true)}>
                  {t('propertyDetails.from')} {prefferedLocation.name}
                </button>
                <div className='flex space-x-7 sm:mr-40 '>
                  <div className='block'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 320 512'
                      fill='black'
                      className='w-6 h-10 flex m-auto'
                    >
                      <path d='M208 48C208 74.51 186.5 96 160 96C133.5 96 112 74.51 112 48C112 21.49 133.5 0 160 0C186.5 0 208 21.49 208 48zM152 352V480C152 497.7 137.7 512 120 512C102.3 512 88 497.7 88 480V256.9L59.43 304.5C50.33 319.6 30.67 324.5 15.52 315.4C.3696 306.3-4.531 286.7 4.573 271.5L62.85 174.6C80.2 145.7 111.4 128 145.1 128H174.9C208.6 128 239.8 145.7 257.2 174.6L315.4 271.5C324.5 286.7 319.6 306.3 304.5 315.4C289.3 324.5 269.7 319.6 260.6 304.5L232 256.9V480C232 497.7 217.7 512 200 512C182.3 512 168 497.7 168 480V352L152 352z' />
                    </svg>
                    <p className='text-xs text-center'>{walkingDistance}</p>
                  </div>
                  <div className='block'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 576 512'
                      fill='black'
                      className='w-9 h-10 flex m-auto'
                    >
                      <path d='M288 0C422.4 0 512 35.2 512 80V128C529.7 128 544 142.3 544 160V224C544 241.7 529.7 256 512 256L512 416C512 433.7 497.7 448 480 448V480C480 497.7 465.7 512 448 512H416C398.3 512 384 497.7 384 480V448H192V480C192 497.7 177.7 512 160 512H128C110.3 512 96 497.7 96 480V448C78.33 448 64 433.7 64 416L64 256C46.33 256 32 241.7 32 224V160C32 142.3 46.33 128 64 128V80C64 35.2 153.6 0 288 0zM128 256C128 273.7 142.3 288 160 288H272V128H160C142.3 128 128 142.3 128 160V256zM304 288H416C433.7 288 448 273.7 448 256V160C448 142.3 433.7 128 416 128H304V288zM144 400C161.7 400 176 385.7 176 368C176 350.3 161.7 336 144 336C126.3 336 112 350.3 112 368C112 385.7 126.3 400 144 400zM432 400C449.7 400 464 385.7 464 368C464 350.3 449.7 336 432 336C414.3 336 400 350.3 400 368C400 385.7 414.3 400 432 400zM368 64H208C199.2 64 192 71.16 192 80C192 88.84 199.2 96 208 96H368C376.8 96 384 88.84 384 80C384 71.16 376.8 64 368 64z' />
                    </svg>
                    <p className='text-xs text-center'>{transitDistance}</p>
                  </div>
                  <div className='block'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 512 512'
                      fill='black'
                      className='w-8 h-10 flex m-auto'
                    >
                      <path d='M39.61 196.8L74.8 96.29C88.27 57.78 124.6 32 165.4 32H346.6C387.4 32 423.7 57.78 437.2 96.29L472.4 196.8C495.6 206.4 512 229.3 512 256V448C512 465.7 497.7 480 480 480H448C430.3 480 416 465.7 416 448V400H96V448C96 465.7 81.67 480 64 480H32C14.33 480 0 465.7 0 448V256C0 229.3 16.36 206.4 39.61 196.8V196.8zM109.1 192H402.9L376.8 117.4C372.3 104.6 360.2 96 346.6 96H165.4C151.8 96 139.7 104.6 135.2 117.4L109.1 192zM96 256C78.33 256 64 270.3 64 288C64 305.7 78.33 320 96 320C113.7 320 128 305.7 128 288C128 270.3 113.7 256 96 256zM416 320C433.7 320 448 305.7 448 288C448 270.3 433.7 256 416 256C398.3 256 384 270.3 384 288C384 305.7 398.3 320 416 320z' />
                    </svg>
                    <p className='text-xs text-center'>{drivingDistance}</p>
                  </div>
                </div>
              </div>
            )}
            <div className='py-7 space-y-5 border-b border-darkGray'>
              <div className='text-[16px] font-bold'>
                {t('propertyDetails.infos')}
              </div>
              <div className='grid grid-cols-2 sm:grid-cols-4 text-[14px]   '>
                <div className=''>
                  <p>{t('propertyDetails.municipality')}</p>
                  <p>{t('propertyDetails.availability')}</p>
                  <p>{t('propertyDetails.propertyType')}</p>
                </div>
                <div className='font-bold'>
                  <p className='font-bold'>
                    {propertySaleDetails.property?.address.city},{' '}
                    {propertySaleDetails.property?.address.canton}
                  </p>
                  {propertySaleDetails?.property?.available_to_enter == null ?
                    (
                      <p className='font-bold'>
                        {t('propertyDetails.ask')}
                      </p>
                    ):(
                      <>
                        {propertySaleDetails?.start_date == propertySaleDetails?.property?.available_to_enter ? (
                          <p className='font-bold'>
                            {t('propertyDetails.immediately')}
                          </p>
                        ):(
                          <p className='font-bold'>
                            {utils.formatDate(
                              propertySaleDetails?.property?.available_to_enter, t
                            )}
                          </p>
                        )}
                      </>)
                  }
                  <div className='font-bold'>
                    {propertySaleDetails.property?.property_types.map(
                      (data: any, index: any) => {
                        return (
                          <div key={index}>
                            {localStorage.getItem('locale') === 'de' ? (
                              <p>{toArr(data.type_string)[0]}</p>
                            ) : localStorage.getItem('locale') === 'en' ? (
                              <p>{toArr(data.type_string)[1]}</p>
                            ) : localStorage.getItem('locale') === 'fr' ? (
                              <p>{toArr(data.type_string)[2]}</p>
                            ) : localStorage.getItem('locale') === 'it' ? (
                              <p>{toArr(data.type_string)[3]}</p>
                            ) : (
                              <>{toArr(data.type_string)[0]}</>
                            )}
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='py-7 space-y-5 border-b border-darkGray'>
              <p className='text-[16px] font-bold'>
                {t('propertyDetails.price')}
              </p>
              <div className='grid grid-cols-2 sm:grid-cols-4 text-[14px] '>
                <div className=''>
                  <p>{t('propertyDetails.sellingPrice')}</p>
                  <p>{t('propertyDetails.afford')}</p>
                </div>
                <div className='font-bold'>
                  <p>
                    CHF {utils.numberWithComma(propertySaleDetails.price)}.—
                  </p>
                  <p
                    onClick={() => navigate('/mortgage')}
                    className='text-red-500 cursor-pointer font-medium'
                  >
                    {t('propertyDetails.mortgage')}
                  </p>
                </div>
              </div>
            </div>
            <div className='py-7 space-y-5 border-b border-darkGray'>
              <div className='flex space-x-3'>
                <p className='text-[16px] font-bold'>
                  {t('propertyDetails.description')}
                </p>
                {userSale.id == propertySaleDetails.seller_id ?
                  <button onClick={() => setEditModeDescription(true)}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='#FF002B'
                      viewBox='0 0 24 22'
                      strokeWidth='0.5'
                      stroke='currentColor'
                      className='w-6 h-6'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M18 13.45V20H2V8H8.743L10.721 6H0V22H20V11.427L18 13.45ZM19.473 2.835L21.18 4.542L11.899 13.92L9.669 14.392L10.181 12.223L19.473 2.835ZM19.465 0L8.361 11.216L7 17L12.898 15.752L24.001 4.534L19.465 0Z'
                      />
                    </svg>
                  </button>
                  : '' }
              </div>
              {editModeDescription ?
                <div className='block'>
                  <textarea
                    name='description'
                    onChange={(e: any) => setEditDataSale({
                      ...editDataSale,
                      description: e.target.value,
                    })}
                    defaultValue={propertySaleDetails.property?.description}
                    className='text-[14px] w-full h-96 border-2 rounded-md px-1'
                    placeholder={t('listingItem.describeListing')}
                  />
                  <div className='flex space-x-2'>
                    <button className='p-2 bg-darkGray rounded-md hover:bg-opacity-95' onClick={handleEdit}>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='white'
                        viewBox='0 0 512 512'
                        strokeWidth='0.5'
                        stroke='currentColor'
                        className='w-4 h-4'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z'
                        />
                      </svg>
                    </button>
                    <button className='p-2 bg-darkGray rounded-md hover:bg-opacity-95' onClick={() => setEditModeDescription(false)}>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='white'
                        viewBox='0 0 320 512'
                        strokeWidth='0.5'
                        stroke='currentColor'
                        className='w-4 h-4'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z'
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                :
                <div className='flex text-[14px]'>
                  <p>{propertySaleDetails.property?.description}</p>
                </div>
              }
            </div>
            {propertySaleDetails.document?.values != null ? (
              <div className='py-7 space-y-5 border-b border-darkGray'>
                <p className='font-bold'>
                  {t('propertyDetails.additionalInfo')}
                </p>
                <div>
                  {propertySaleDetails.document.map((docs, index) => {
                    return (
                      <div key={index}>
                        <ul className='font-normal italic mt-5 self-center'>
                          <li className='flex space-x-2 w-[204px] py-1 rounded-md hover:hover:text-blue-400 transition-all ease-in-out duration-200'>
                            <svg
                              width='24'
                              height='24'
                              viewBox='0 0 16 18'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M8.61425 10.8202C8.66225 11.0205 8.672 11.3295 8.5985 11.5312C8.5025 11.7945 8.31275 11.9272 8.033 11.9272H7.55525V10.3372H7.92725C8.28275 10.3372 8.5295 10.467 8.61425 10.8202ZM10.9325 4.33275C12.4677 3.9735 14.5363 4.542 15.5 5.217C14.3795 3.71925 11.5775 0.936 9.926 0.0555C10.7937 0.9315 11.0982 3.23175 10.9325 4.33275ZM5.33975 10.299H4.93025V11.0063H5.33975C5.516 11.0063 5.69 10.986 5.77175 10.836C5.822 10.7437 5.822 10.5615 5.77175 10.4692C5.69 10.3207 5.516 10.299 5.33975 10.299ZM15.5 8.25V18H0.5V0H6.80675C10.4503 0 9.30725 6 9.30725 6C11.5655 5.44125 15.5 5.685 15.5 8.25ZM6.419 10.3207C6.299 9.95775 5.9645 9.75 5.501 9.75H4.25V12.5145H4.93025V11.5568H5.501C5.96525 11.5568 6.299 11.349 6.419 10.9845C6.4895 10.7655 6.4895 10.5367 6.419 10.3207ZM8.97425 10.0935C8.7515 9.86925 8.441 9.75 8.075 9.75H6.87575V12.5145H8.075C8.47775 12.5145 8.79575 12.3787 9.0215 12.1132C9.437 11.619 9.461 10.587 8.97425 10.0935ZM11.75 9.75H9.779V12.5145H10.4593V11.4105H11.5767V10.8615H10.4593V10.338H11.75V9.75Z'
                                fill='gray'
                              />
                            </svg>
                            <a
                              href={docs.url}
                              target='_blank'
                              rel='noreferrer'
                              className='border-b text-sm self-center'
                            >
                              Filename {index + 1}
                            </a>
                          </li>
                        </ul>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              ''
            )}
            <div className='py-7 space-y-5 border-b border-darkGray'>
              <p className='text-[16px] font-bold'>
                {t('propertyDetails.dimensions')}
              </p>
              <div className='grid grid-cols-2 sm:grid-cols-4 text-[14px]'>
                <div className=''>
                  <p>
                    {propertySaleDetails.property?.floor != null
                      ? t('propertyDetails.floor')
                      : null}
                  </p>
                  <p>
                    {propertySaleDetails.property?.year_built != null && propertySaleDetails?.property?.year_built != 0
                      ? t('propertyDetails.yearBuilt')
                      : null}
                  </p>
                  <p>
                    {propertySaleDetails?.property?.plot_area != null && propertySaleDetails?.property?.plot_area != 0
                      ? t('propertyDetails.plotArea')
                      : null}
                  </p>
                  <p>
                    {propertySaleDetails?.property?.living_space != null && propertySaleDetails?.property?.living_space != 0
                      ? t('propertyDetails.livingSpace')
                      : null}
                  </p>
                  <p>
                    {propertySaleDetails.property?.size_rooms != null && propertySaleDetails?.property?.size_rooms != 0
                      ? t('propertyDetails.numberOfRooms')
                      : null}
                  </p>
                  <p>
                    {propertySaleDetails?.property?.bathroom != null && propertySaleDetails?.property?.bathroom != 0
                      ? t('propertyDetails.bathroom')
                      : null}
                  </p>
                  <p>
                    {propertySaleDetails?.property?.shower != null && propertySaleDetails?.property?.shower != 0
                      ? t('propertyDetails.shower')
                      : null}
                  </p>
                  <p>
                    {propertySaleDetails?.property?.bedroom != null && propertySaleDetails?.property?.bedroom != 0
                      ? t('propertyDetails.bedroom')
                      : null}
                  </p>
                  <p>
                    {propertySaleDetails?.property?.kitchen != null && propertySaleDetails?.property?.kitchen != 0
                      ? t('propertyDetails.kitchen')
                      : null}
                  </p>
                  <p>
                    {propertySaleDetails?.property?.garage != null && propertySaleDetails?.property?.garage != 0
                      ? t('propertyDetails.garage')
                      : null}
                  </p>
                </div>
                <div className='font-bold'>
                  <p className='font-bold'>
                    {propertySaleDetails?.property?.floor != null
                      ? t(propertySaleDetails.property?.floor)
                      : null}
                  </p>
                  <p className='font-bold'>
                    {propertySaleDetails?.property?.year_built != null && propertySaleDetails?.property?.year_built != 0
                      ? propertySaleDetails?.property?.year_built
                      : null}
                  </p>
                  <p className='font-bold'>
                    {propertySaleDetails?.property?.plot_area != null && propertySaleDetails?.property?.plot_area != 0
                      ? <>{propertySaleDetails?.property?.plot_area} (m²) </>
                      : null}
                  </p>
                  <p className='font-bold'>
                    {propertySaleDetails?.property?.living_space != null && propertySaleDetails?.property?.living_space != 0
                      ? <>{propertySaleDetails?.property?.living_space} (m²) </>
                      : null}
                  </p>
                  <p className='font-bold'>
                    {propertySaleDetails?.property?.size_rooms != null && propertySaleDetails?.property?.size_rooms != 0
                      ? propertySaleDetails?.property?.size_rooms
                      : null}
                  </p>
                  <p className='font-bold'>
                    {propertySaleDetails?.property?.bathroom != null && propertySaleDetails?.property?.bathroom != 0
                      ? propertySaleDetails?.property?.bathroom
                      : null}
                  </p>
                  <p className='font-bold'>
                    {propertySaleDetails?.property?.shower != null && propertySaleDetails?.property?.shower != 0
                      ? propertySaleDetails?.property?.shower
                      : null}
                  </p>
                  <p className='font-bold'>
                    {propertySaleDetails?.property?.bedroom != null && propertySaleDetails?.property?.bedroom != 0
                      ? propertySaleDetails?.property?.bedroom
                      : null}
                  </p>
                  <p className='font-bold'>
                    {propertySaleDetails?.property?.kitchen != null && propertySaleDetails?.property?.kitchen != 0
                      ? propertySaleDetails?.property?.kitchen
                      : null}
                  </p>
                  <p className='font-bold'>
                    {propertySaleDetails?.property?.garage != null && propertySaleDetails?.property?.garage != 0
                      ? propertySaleDetails?.property?.garage
                      : null}
                  </p>
                </div>
              </div>
            </div>
            <div className='py-7 space-y-5 border-b border-darkGray'>
              <p className='text-[16px] font-bold'>
                {t('propertyDetails.characteristics')}
              </p>
              <div className=''>
                <div className='text-[14px]'>
                  {propertySaleDetails.property?.property_chars != null ? (
                    <div>
                      {propertySaleDetails.property?.property_chars.map(
                        (chars: any, index: any) => {
                          return (
                            <div
                              key={index}
                              className='font-bold text-gray-500 grid grid-cols-2 sm:grid-cols-4'
                            >
                              <div className=''>
                                {localStorage.getItem('locale') === 'de' ? (
                                  <p className=''>
                                    {toArr(chars.type_string)[0]}
                                  </p>
                                ) : localStorage.getItem('locale') === 'en' ? (
                                  <p className=''>
                                    {toArr(chars.type_string)[1]}
                                  </p>
                                ) : localStorage.getItem('locale') === 'fr' ? (
                                  <p className=''>
                                    {toArr(chars.type_string)[2]}
                                  </p>
                                ) : localStorage.getItem('locale') === 'it' ? (
                                  <p className=''>
                                    {toArr(chars.type_string)[3]}
                                  </p>
                                ) : (
                                  <>{toArr(chars.type_string)[0]}</>
                                )}
                              </div>
                              <div className='flex justify-center'>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  fill='none'
                                  viewBox='0 0 24 24'
                                  strokeWidth='1.5'
                                  stroke='red'
                                  className='w-6 h-6'
                                >
                                  <path
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    d='M4.5 12.75l6 6 9-13.5'
                                  />
                                </svg>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
            {propertySaleDetails?.property?.available_to_visit != null ? (
              <div className='py-7 space-y-5 border-b border-darkGray'>
                <p className='text-[16px] font-bold'>
                  {t('propertyDetails.availableVisit')}
                </p>
                <div className='grid grid-cols-2 sm:grid-cols-4 text-[14px]'>
                  <div className=''>
                    <p className=''>{t('listingItem.availableDate')}</p>
                    <p>{t('propertyDetails.availableHours')}</p>
                  </div>
                  <div className=''>
                    <p className='font-bold'>
                      {utils.formatDate(
                        propertySaleDetails.property?.available_to_visit, t
                      )}
                    </p>
                    <p className='font-bold'>
                      {utils.formatTimewithoutSeconds(
                        propertySaleDetails.property?.available_to_visit
                      )}
                    </p>
                  </div>
                </div>
              </div>
            ):''}
            <div className='py-7 space-y-5 border-b border-darkGray'>
              <p className='text-[16px] font-bold'>
                {t('propertyDetails.shareListing')}
              </p>
              <div className='sm:flex text-[14px] sm:space-x-16 '>
                <div className='space-y-3'>
                  {/* <div className='flex space-x-2'>*/}
                  {/*  <a href='#' className='self-center'>*/}
                  {/*    PDF*/}
                  {/*  </a>*/}
                  {/*  <svg*/}
                  {/*    width='24'*/}
                  {/*    height='24'*/}
                  {/*    viewBox='0 0 16 18'*/}
                  {/*    fill='none'*/}
                  {/*    xmlns='http://www.w3.org/2000/svg'*/}
                  {/*    className=''*/}
                  {/*  >*/}
                  {/*    <path*/}
                  {/*      d='M8.61425 10.8202C8.66225 11.0205 8.672 11.3295 8.5985 11.5312C8.5025 11.7945 8.31275 11.9272 8.033 11.9272H7.55525V10.3372H7.92725C8.28275 10.3372 8.5295 10.467 8.61425 10.8202ZM10.9325 4.33275C12.4677 3.9735 14.5363 4.542 15.5 5.217C14.3795 3.71925 11.5775 0.936 9.926 0.0555C10.7937 0.9315 11.0982 3.23175 10.9325 4.33275ZM5.33975 10.299H4.93025V11.0063H5.33975C5.516 11.0063 5.69 10.986 5.77175 10.836C5.822 10.7437 5.822 10.5615 5.77175 10.4692C5.69 10.3207 5.516 10.299 5.33975 10.299ZM15.5 8.25V18H0.5V0H6.80675C10.4503 0 9.30725 6 9.30725 6C11.5655 5.44125 15.5 5.685 15.5 8.25ZM6.419 10.3207C6.299 9.95775 5.9645 9.75 5.501 9.75H4.25V12.5145H4.93025V11.5568H5.501C5.96525 11.5568 6.299 11.349 6.419 10.9845C6.4895 10.7655 6.4895 10.5367 6.419 10.3207ZM8.97425 10.0935C8.7515 9.86925 8.441 9.75 8.075 9.75H6.87575V12.5145H8.075C8.47775 12.5145 8.79575 12.3787 9.0215 12.1132C9.437 11.619 9.461 10.587 8.97425 10.0935ZM11.75 9.75H9.779V12.5145H10.4593V11.4105H11.5767V10.8615H10.4593V10.338H11.75V9.75Z'*/}
                  {/*      fill='gray'*/}
                  {/*    />*/}
                  {/*  </svg>*/}
                  {/* </div>*/}
                  <div className='flex space-x-2'>
                    <a href={`https://www.facebook.com/sharer/sharer.php?u=${location.href}`}
                      className='self-center'>
                      Facebook
                    </a>
                    <a href={`https://www.facebook.com/sharer/sharer.php?u=${location.href}`}>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 512 512'
                        className='h-6 w-6'
                        fill='gray'
                      >
                        <path d='M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z' />
                      </svg>
                    </a>
                  </div>
                </div>
                <div className='space-y-3 pt-3 sm:pt-0'>
                  <div className='flex space-x-2' onClick={() => window.print()}>
                    <a href='#' className='self-center'>
                      Print
                    </a>
                    <a href='#'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 512 512'
                        className='h-6 w-6 '
                        fill='gray'
                      >
                        <path d='M448 192H64C28.65 192 0 220.7 0 256v96c0 17.67 14.33 32 32 32h32v96c0 17.67 14.33 32 32 32h320c17.67 0 32-14.33 32-32v-96h32c17.67 0 32-14.33 32-32V256C512 220.7 483.3 192 448 192zM384 448H128v-96h256V448zM432 296c-13.25 0-24-10.75-24-24c0-13.27 10.75-24 24-24s24 10.73 24 24C456 285.3 445.3 296 432 296zM128 64h229.5L384 90.51V160h64V77.25c0-8.484-3.375-16.62-9.375-22.62l-45.25-45.25C387.4 3.375 379.2 0 370.8 0H96C78.34 0 64 14.33 64 32v128h64V64z' />
                      </svg>
                    </a>
                  </div>
                  <div className='flex space-x-2'>
                    <a href={`whatsapp://send?text=${t('propertyDetails.whatsappBody')} ${location.href}`} data-action="share/whatsapp/share">
                      Whatsapp
                    </a>
                    <a href={`whatsapp://send?text=${t('propertyDetails.whatsappBody')} ${location.href}`} data-action="share/whatsapp/share">
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 448 512'
                        className='h-6 w-6 '
                        fill='gray'
                      >
                        <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z' />
                      </svg>
                    </a>
                  </div>
                </div>
                <div className='space-y-3 pt-3 sm:pt-0'>
                  <div className='flex space-x-2'>
                    <a  href={`mailto:?body=${t('propertyDetails.emailBody')} ${location.href}&amp;`}>
                      E-mail
                    </a>
                    <a href={`mailto:?body=${t('propertyDetails.emailBody')} ${location.href}&amp;`}>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 512 512'
                        className='h-6 w-6 '
                        fill='gray'
                      >
                        <path d='M464 64C490.5 64 512 85.49 512 112C512 127.1 504.9 141.3 492.8 150.4L275.2 313.6C263.8 322.1 248.2 322.1 236.8 313.6L19.2 150.4C7.113 141.3 0 127.1 0 112C0 85.49 21.49 64 48 64H464zM217.6 339.2C240.4 356.3 271.6 356.3 294.4 339.2L512 176V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V176L217.6 339.2z' />
                      </svg>
                    </a>
                  </div>
                  <div className='flex space-x-2' onClick={() =>  navigator.clipboard.writeText(location.href)}>
                    <a href='#' className='self-center'>
                      {t('propertyDetails.copyLink')}
                    </a>
                    <a href='#'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 640 512'
                        className='h-6 w-6 '
                        fill='gray'
                      >
                        <path d='M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z' />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='py-7 space-y-5 border-b-2 '>
              <div className='grid grid-rows sm:w-[60%] bg-[#f8f8f8] border rounded-lg shadow-xl p-5'>
                <div className='pb-1'>
                  <p className='text-[16px] font-bold'>
                    {t('propertyDetails.quickContact')}
                  </p>
                </div>
                <div className='sm:flex sm:space-x-[7%]'>
                  <div className='block sm:w-[90%] pt-3'>
                    <label className='text-[14px]' title={t('contact.fieldRequired')}>
                      {t('propertyDetails.fullName')}*
                    </label>
                    <div>
                      <input
                        name='full_name'
                        value={emailContent?.full_name}
                        onChange={handleChanges}
                        type='text'
                        className='pb-1 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-black peer required '
                      />
                    </div>
                    {error?.includes('full_name') ? (
                      <p className='text-xs text-red-500 absolute '>
                        {t('contact.fullNameRequired')}
                      </p>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className='block sm:w-[90%] pt-3'>
                    <label className='text-[14px]' title={t('contact.fieldRequired')}>
                      {t('propertyDetails.email')}*
                    </label>
                    <div>
                      <input
                        name='email'
                        value={emailContent?.email}
                        onChange={handleChanges}
                        type='text'
                        className='pb-1 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-black peer required '
                      />
                    </div>
                    {error?.includes('email') ? (
                      <p className='text-xs text-red-500 absolute '>
                        {t('contact.emailRequired')}
                      </p>
                    ) : (
                      ''
                    )}
                    {error?.includes('email_incorrect') ? (
                      <p className='text-xs text-red-500 absolute '>
                        {t('contact.emailNotValid')}
                      </p>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className='sm:flex sm:space-x-[7%]'>
                  <div className='block sm:w-[90%] pt-3'>
                    <label className='text-[14px]' title={t('contact.fieldRequired')}>
                      {t('propertyDetails.phone')}*
                    </label>
                    <div>
                      <input
                        name='phone'
                        value={emailContent?.phone}
                        onChange={handleChanges}
                        type='text'
                        className='pb-1 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-black peer required '
                      />
                    </div>
                    {error?.includes('phone') ? (
                      <p className='text-xs text-red-500 absolute '>
                        {t('contact.phoneRequired')}
                      </p>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className='block sm:w-[90%] pt-3'>
                    <label className='text-[14px]' title={t('contact.fieldRequired')}>
                      {t('propertyDetails.city')}*
                    </label>
                    <div>
                      <input
                        name='zip'
                        onChange={handleChanges}
                        value={emailContent?.zip}
                        type='text'
                        className='mr-[5%] pb-1 w-[25%] text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-black peer required '
                      />
                      <input
                        name='city'
                        value={emailContent?.city}
                        onChange={handleChanges}
                        type='text'
                        className='pb-1 w-[70%] text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-black peer required '
                      />
                    </div>
                    {error?.includes('zip') || error?.includes('city') ? (
                      <p className='text-xs text-red-500 absolute '>
                        {t('contact.fieldRequired')}
                      </p>
                    ) : (
                      ''
                    )}
                    { !validZip.test(emailContent?.zip) && emailContent?.zip != '' && (
                      <p className='text-xs text-red-500 absolute'>
                        *{t('listingItem.propertyForm.invalidZip')}22222222
                      </p>)}
                    {/* { emailContent?.zip != '' && (*/}
                    {/*  <p className='text-xs text-red-500 absolute'>*/}
                    {/*    *{t('listingItem.propertyForm.invalidZip')}1111111*/}
                    {/*  </p>)}*/}
                  </div>
                </div>
                <div className='block pt-3'>
                  <label className='text-[14px]' title={t('contact.fieldRequired')}>
                    {t('propertyDetails.message')}*
                  </label>
                  <textarea
                    name='message'
                    value={emailContent?.message}
                    onChange={handleChanges}
                    className='sm:w-full w-full h-36 text-sm border-2 rounded-md appearance-none focus:border-black focus:outline-none focus:ring-0'
                  />
                  {error?.includes('message') ? (
                    <p className='text-xs text-red-500 absolute '>
                      {t('contact.messageRequired')}
                    </p>
                  ) : (
                    ''
                  )}
                </div>
                <div className='flex justify-end'>
                  <button onClick={submitContact} className='px-16 py-3 rounded bg-darkGray text-white'>
                    {t('propertyDetails.buttonSend')}
                  </button>
                </div>
              </div>
            </div>
            <div className='py-7 space-y-5 border-b border-darkGray sm:px-0 px-2 '>
              <p className='text-[16px] font-bold'>{t('propertyDetails.ad')}</p>
              <div className='text-[14px] space-y-4 sm:space-y-2 '>
                <div className='grid sm:grid-cols-4'>
                  <div>{t('propertyDetails.posted')}</div>
                  {propertySaleDetails.seller_info?.type === 'individual' ? (
                    <div className='font-bold'>
                      <p>
                        {propertySaleDetails.seller_info?.first_name +
                          ' ' +
                          propertySaleDetails.seller_info?.last_name}
                      </p>
                      <p>{propertySaleDetails.seller_info?.address}</p>
                    </div>
                  ) : propertySaleDetails.seller_info?.type === 'company' ? (
                    <div className='font-bold'>
                      <p>{propertySaleDetails.seller_info?.name}</p>
                      <p>{propertySaleDetails.seller_info?.address}</p>
                    </div>)  : (
                    ''
                  )}
                </div>
                <div className='grid sm:grid-cols-4 '>
                  <p>Email</p>
                  <p className='font-bold'>
                    {propertySaleDetails.seller_info?.email}
                  </p>
                </div>
                <div className='grid sm:grid-cols-4'>
                  <p>{t('propertyDetails.contact')}</p>
                  <p className='flex font-bold'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-4 w-4 mr-1'
                      viewBox='0 0 20 20 '
                      fill=''
                    >
                      <path d='M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z' />
                    </svg>
                    {propertySaleDetails.seller_info?.phone}
                  </p>
                </div>
                {propertySaleDetails.seller_info?.telephone != null &&
                <div className='grid sm:grid-cols-4'>
                  <p>{t('propertyDetails.telephone')}</p>
                  <p className='flex font-bold'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-4 w-4 mr-1'
                      viewBox='0 0 20 20 '
                      fill=''
                    >
                      <path d='M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z' />
                    </svg>
                    {propertySaleDetails.seller_info?.telephone}
                  </p>
                </div>}
                {/* <div className='grid sm:grid-cols-4 '>
                  <p>{t('propertyDetails.auctionicCode')}</p>
                  <p className='font-bold'>7017442</p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className='py-7 px-2 '>
          <p className='text-[16px] sm:px-24 font-bold'>
            {t('propertyDetails.listing')}
          </p>
          <div className=''>
            <TrendingEstate />
          </div>
        </div>
        <div className='mt-24 pb-16 text-center mb-'>
          <button onClick={() => window.scrollTo(0,0)} className='bg-[#353535] rounded px-4 py-3'>
            <img src={Image1} alt='' />
          </button>
          <p className='pt-3 text-sm'>{t('propertyDetails.up')}</p>
        </div>
      </div>
    </div>
  );
};

export { PropertySaleDetails };
