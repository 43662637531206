import { auctionicApi } from '../helpers/api';
import { headers } from '../helpers/headers';


const getInterestRate = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/interest', config);
};

export const mortgageServices = {
  getInterestRate,
};
