import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PostIdentification } from '../../config';
import useAuth from '../../context/AuthContext';
import { userServices } from '../../services';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import OtpInput from 'react-otp-input';
import { SpinnerLoading } from '../LoadingTest/SpinnerLoading';

const Identification = () => {
  const { t } = useTranslation();
  // const [userIden, setUserIden] = useState();
  const { user } = useAuth();
  const [checkConfirm, setCheckConfirm] = useState<PostIdentification>();
  const [inputData, setInputData] = useState({
    code: '',
  });
  const [openPhoneModal, setOpenPhoneModal] = useState(false);
  const [otpBid, setOtpBid] = useState('');
  const navigate = useNavigate();
  const [phoneLoader,setPhoneLoader] = useState(false);
  const [emailLoader,setEmailLoader] = useState(false);
  const [phoneConfirmationLoader,setPhoneConfirmationLoader] = useState(false);
  const [requestLoader, setRequestLoader] = useState(false);
  const userIdenfy = () => {
    const newTab = window.open();
    userServices
      .userIdenfy()
      .then((response: any) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        newTab.location.href = response?.data.link;
        // window.open(response.data.link);
        // return response;
      })
      .catch((error: any) => {
        console.error('Login error', error);
      });
  };
  const [confirmByPost, setConfirmByPost] = useState(false);
  const handleOpenConfirmByPost = () => {
    setConfirmByPost(!confirmByPost);
    handleCheckPostConfirm();
  };

  // MISSING SNACKBAR ON ERROR POST CONFIRM

  const requestPostConfirm = () => {
    setRequestLoader(true);
    userServices
      .postConfirm()
      .then(() => {
        setConfirmByPost(false);
        setRequestLoader(false);
        toast.success(t('dashboard.identificationRequests.requestPostConfirm'), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setTimeout(() => {
          navigate(0);
        },3000);

      })
      .catch(() => {
        setRequestLoader(false);
      });
  };


  const resendPhoneCode = () => {
    setPhoneLoader(true);
    userServices
      .resendVerification()
      .then(() => {
        setPhoneLoader(false);
        toast.success(t('dashboard.identificationRequests.phoneCodeSentSuccess'), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setTimeout(() => {
          setOpenPhoneModal(true);
        }, 1500);
      })
      .catch(() => {
        setPhoneLoader(false);
        toast.error(t('dashboard.identificationRequests.phoneCodeSentError'), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };

  const resendEmail = () => {
    setEmailLoader(true);
    userServices
      .resendEmailVerification()
      .then(() => {
        setEmailLoader(false);
        toast.success(t('dashboard.identificationRequests.emailVerificationSuccess'), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      })
      .catch(() => {
        setEmailLoader(false);
        toast.error(t('dashboard.identificationRequests.emailVerificationError'), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };

  // MISSING SNACKBAR

  const handleCheckPostConfirm = () => {
    userServices
      .checkPostConfirm()
      .then((response) => {
        setCheckConfirm(response.data);
      })
      .catch((error) => {
        // console.log('error getting check post', error);
      });
  };

  const handlePhonePostConfirm = () => {
    // console.log(inputData.code);
    setRequestLoader(true);
    userServices
      .postPhoneCodeConfirm(inputData.code)
      .then(() => {
        // console.log('serv');
        setRequestLoader(false);
        toast.success(t('dashboard.identificationRequests.confirmS'), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setTimeout(() => {
          navigate(0);
        },3000);
      })
      .catch((err) => {
        setRequestLoader(false);
        console.log(err);
      });
  };

  const phoneConfirmation = () => {
    setPhoneConfirmationLoader(true);
    userServices
      .phoneConfirmation(otpBid)
      .then((response: any) => {
        setPhoneConfirmationLoader(false);
        toast.success(t('dashboard.identificationRequests.phoneConfirmSuccess'), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        return response;
      })
      .catch(() => {
        setPhoneConfirmationLoader(false);
        toast.error(t('dashboard.identificationRequests.phoneConfirmError'), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };

  return (
    <div className='bg-[#F5F5F5] text-darkGray rounded-lg my-1 mt-10 sm:mt-2 sm:mb-10 space-y-5 py-4 mx-2 sm:mx-0'>
      <ToastContainer />
      <div className='flex justify-center pt-[16px] pb-[6px] border-b border-darkGray mx-10'>
        <p className='text-[20px] pl-3 font-avenir55  '>
          {t('dashboard.overviewSection.identificationTracker')}
        </p>
      </div>
      <div className='mx-3 sm:mx-10 space-y-5 sm:space-y-0 grid grid-cols-1 sm:grid-cols-3 '>
        <div className='flex justify-center'>
          <div className='flex place-items-center space-x-2'>
            <p className='text-[14px] text-darkGray'>{t('dashboard.emailAddress')} </p>
            {user?.email_confirmed === true ? (
              <span className='rounded-full p-1 bg-green-500 '>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6 '
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='white'
                  strokeWidth='2'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M5 13l4 4L19 7'
                  />
                </svg>
              </span>
            ) : (
              <span className='rounded-full p-1 bg-auctionicRed cursor-pointer' title={t('dashboard.identificationRequests.requestEmailConfirm')} onClick={resendEmail}>
                {emailLoader ? (
                  <SpinnerLoading />
                ):(
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth='1.5'
                    stroke='white'
                    className='w-6 h-6'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M4.5 19.5l15-15m-15 0l15 15'
                    />
                  </svg>
                )}
              </span>
            )}
          </div>
        </div>
        <div className='flex justify-center'>
          <div className='flex place-items-center space-x-2'>
            <p className='text-[14px] text-darkGray'>{t('dashboard.phoneNumber')}</p>
            {user?.phone_confirmed === true ? (
              <span className='rounded-full p-1 bg-green-500 '>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6 '
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='white'
                  strokeWidth='2'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M5 13l4 4L19 7'
                  />
                </svg>
              </span>
            ) : (
              <span className='rounded-full p-1 bg-auctionicRed cursor-pointer' title={t('dashboard.identificationRequests.requestPhoneConfirm')} onClick={resendPhoneCode}>
                {phoneLoader ? (
                  <SpinnerLoading/>
                ):(
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth='1.5'
                    stroke='white'
                    className='w-6 h-6'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M4.5 19.5l15-15m-15 0l15 15'
                    />
                  </svg>
                )}
              </span>
            )}
          </div>
        </div>
        {openPhoneModal ?
          <div id="defaultModal" aria-hidden="true"
            className="fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden flex justify-center items-center overflow-y-auto md:inset-0 h-modal md:h-full">
            <div className="relative w-full h-full max-w-2xl md:h-auto">
              <div className="relative bg-white rounded-lg shadow dark:bg-darkGray">
                <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                  <h3 className="text-xl font-semibold text-darkGray dark:text-white">
                    <span className=''>AUCTIONIC</span> {t('dashboard.phoneConfirmModal.phoneConfirmation')}
                  </h3>
                  <button type="button"
                    onClick={() => setOpenPhoneModal(false)}
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-toggle="defaultModal">
                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"></path>
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                <p className='text-sm text-darkGray dark:text-white text-center p-2'>{t('dashboard.phoneConfirmModal.writeYourCode')}</p>
                <div className="p-5 space-y-5 flex justify-center items-center">
                  <OtpInput
                    value={otpBid}
                    onChange={setOtpBid}
                    isInputNum={true}
                    numInputs={6}
                    inputStyle={{
                      width: '2.5rem',
                      height: '2.5rem',
                      margin: '0 0.2rem',
                      fontSize: '1.52rem',
                      borderRadius: 4,
                      border: '1px solid rgba(0,0,0,0.3)',
                      color: 'black',
                    }}
                  />
                </div>
                <div
                  className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                  <button onClick={phoneConfirmation} data-modal-toggle="defaultModal" type="button"
                    className="text-white bg-auctionicRed focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-blue-800">
                    {phoneConfirmationLoader ? (
                      <SpinnerLoading/>
                    ):(
                      <>{t('dashboard.phoneConfirmModal.verifyPhoneButton')}</>)}
                  </button>
                </div>
              </div>
            </div>
          </div>
          : '' }
        <div className='flex justify-center space-x-2 items-center'>
          {user.type === 'individual' ?
            <p className='text-[14px] text-darkGray'>{t('dashboard.identificationModal.idCard')}</p>
            : <p>{t('dashboard.identificationModal.confirmedByPost')}</p> }
          <div>
            {user?.type === 'individual' ? (
              <div className='flex place-items-center space-x-2'>
                {user?.id_card_confirmed === false ? (
                  <button onClick={userIdenfy} className='bg-auctionicRed px-5 py-2 rounded-md text-white'>
                    {t('dashboard.confirmIdentity')}
                  </button>
                ) : (
                  <button className='rounded-full p-1 bg-green-500 '>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-6 w-6 '
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='white'
                      strokeWidth='2'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M5 13l4 4L19 7'
                      />
                    </svg>
                  </button>
                )}
              </div>
            ) : user?.type === 'company' && user.address_confirmed == true ? (
              <>
                <button className='rounded-full p-1 bg-green-500 '>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-6 w-6 '
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='white'
                    strokeWidth='2'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M5 13l4 4L19 7'/>
                  </svg>
                </button>
              </>
            ) : (
              <div>
                <div className='flex place-items-center space-x-2'>
                  <button
                    className='bg-auctionicRed px-5 py-2 rounded-md text-white'
                    onClick={handleOpenConfirmByPost}
                  >
                    {t('dashboard.identificationModal.confirmByPost')}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        {confirmByPost ? (
          <>
            <div
              className='fixed inset-0 bg-black bg-opacity-90 transition-transform z-10'
              onClick={() => setConfirmByPost(false)}
            ></div>
            <div className='fixed font-press-start max-w-md justify-center rounded-md z-20'>
              <div className='flex min-h-full items-center justify-center '>
                <div className='w-full max-w-md space-y-8 bg-gray-50 rounded-lg p-10 relative'>
                  <div className='space-y-5'>
                    <h2 className='text-center text-3xl font-bold tracking-tight text-gray-900'>
                      {t('dashboard.identificationModal.requestPostConfirm')}
                    </h2>
                    {checkConfirm?.request_exists === false ? (
                      <div className='flex justify-center'>
                        <button
                          className='bg-darkGray text-white rounded-md px-5 py-1 hover:bg-opacity-70 transition-all ease-in-out duration-200'
                          onClick={requestPostConfirm}
                        >
                          {
                            requestLoader ?  (
                              <SpinnerLoading />
                            ):(
                              <>{t('dashboard.identificationModal.request')}</>
                            )
                          }
                        </button>
                      </div>
                    ) : (
                      <p className='text-center'>
                        {t('dashboard.identificationModal.alreadyMadeRequest')}
                      </p>
                    )}
                  </div>
                  {/* Set a hook to control the code */}
                  {checkConfirm?.request_exists === false ? (
                    ''
                  ) : (
                    <div className='block space-y-5'>
                      <label className='block'>
                        {t('dashboard.identificationModal.confirmationCode')}
                      </label>
                      <input
                        type='text'
                        name='code'
                        onChange={(e: any) =>
                          setInputData((inputData: any) => ({
                            ...inputData,
                            code: e.target.value,
                          }))
                        }
                        className='appearance-none border-b-2 border-black bg-transparent w-full outline-none focus:border-b-2 focus:border-gray-500'
                      />
                      <div className='flex justify-center'>
                        <button
                          className='bg-darkGray text-white rounded-md px-5 py-1 hover:bg-opacity-70 transition-all ease-in-out duration-200'
                          onClick={handlePhonePostConfirm}
                        >
                          {requestLoader ? (
                            <SpinnerLoading />
                          ):
                            <>{t('dashboard.identificationModal.confirm')}</>
                          }
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export { Identification };
