import React, { useState } from 'react';
import Logo from '../../images/logoRed.svg';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { userServices } from '../../services';
import { toast, ToastContainer } from 'react-toastify';
import { SpinnerLoading } from '../LoadingTest/SpinnerLoading';
import { StrengthBar1 } from '../StrengthBar1';


const ResetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [score, setScore] = useState(0);
  const [isShown, setIsShown] = useState(false);
  const [error, setError] = useState('');
  const [passwordShow, setPasswordShow] = useState(false);
  const { token } = useParams();
  const [buttonLoader,setButtonLoader] = useState(false);
  const [inputReset, setInputReset] = React.useState({
    password: '',
    token: token,
  });
  const [inputResetConfirm, setInputResetConfirm] = React.useState('');
  const handlePasswordShow = () => {
    setPasswordShow(!passwordShow);
  };


  const handleClick = () => {
    if (!inputReset.password || score < 3) {
      setError('password');
    }
    else {
      handleReset();
    }
  };

  const handleReset = () => {
    setButtonLoader(true);
    userServices.resetPass(inputReset).then((res) => {
      setButtonLoader(false);
      setInputReset(res.data);
      toast.success(t('resetPassword.passReset'), {
        position: 'bottom-left',
      });
      setTimeout(() => {
        navigate('/login');
      } , 3000);
    }
    ).catch(() => {
      toast.error(t('resetPassword.error'), {
        position: 'bottom-left',
      });
      setButtonLoader(false);
    });
  };

  const onChangeScore = (score1: number) => {
    setScore(score1);
  };

  const passwordMeter = [
    t('register.weakPassword'),
    t('register.weakPassword'),
    t('register.okayPassword'),
    t('register.goodPassword'),
    t('register.strongPassword'),
  ];

  return (
    <>
      <ToastContainer autoClose={3000} />
      <div className='bg-white flex justify-center pt-8'>
        <img src={Logo} alt='' />
      </div>
      <div className='bg-white py-28 sm:px-32 sm:py-48 block sm:grid sm:grid-cols-2 min-h-screen'>
        <div id='error' className='px-8 sm:px-0 self-center'>
          <h1 className='sm:text-[45px] text-[26px] font-medium font-braga text-darkGray'>
            {t('resetPassword.resetButton')}
          </h1>
          <hr className='sm:mr-48 border-red-600' />
          <p className=' pt-5 font-press-start text-darkGray'>{t('forgotPassword.changeMessage')}</p>
        </div>
        <div className='block pt-8 px-10 sm:px-0 self-center'>
          <label className='pr-2 text-darkGray font-press-start'
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}>{t('forgotPassword.writeNew')}*
          </label>
          {isShown && (
            <div className='absolute text-auctionicRed text-xs bg-white pr-2'>
              {t('reqField.requiredFieldPassword1')}<br/>
              {t('reqField.requiredFieldPassword2')}<br/>
              {t('reqField.requiredFieldPassword3')}
            </div>
          )}
          <input
            type={passwordShow ? 'text' : 'password'}
            name='password'
            value={inputReset.password}
            onChange={(e) => setInputReset({ ...inputReset, password: e.target.value })}
            className=' py-2 mb-5 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer required'
          />
          {passwordShow ? (
            <button onClick={handlePasswordShow}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                stroke='darkGray'
                className='w-5 h-5 absolute -mt-3 -ml-6'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z'
                />
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
                />
              </svg>
            </button>
          ) : (
            <button onClick={handlePasswordShow}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                stroke='darkGray'
                className='w-5 h-5 absolute -mt-3 -ml-6'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88'
                />
              </svg>
            </button>
          )}
          {error == 'password' && (
            <p className='text-red-500 text-xs pt-3 absolute'>
              {t('register.registerAs.passwordFill')}
            </p>
          )}
          <StrengthBar1
            password={inputReset.password}
            onChangeScore={onChangeScore}
            minLength={8}
            scoreWords={passwordMeter}
            shortScoreWord={t('register.tooShortPassword')}
          />
          <label className='pr-2 text-darkGray font-press-start'>
            {' '}
            {t('forgotPassword.confirmNew')}
          </label>
          <input
            type={passwordShow ? 'text' : 'password'}
            value={inputResetConfirm}
            onChange={(e) => {
              setInputResetConfirm(e.target.value);
            }}
            className=' py-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer required'
          />
          {passwordShow ? (
            <button onClick={handlePasswordShow}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                stroke='darkGray'
                className='w-5 h-5 absolute -mt-3 -ml-6'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z'
                />
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
                />
              </svg>
            </button>
          ) : (
            <button onClick={handlePasswordShow}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                stroke='darkGray'
                className='w-5 h-5 absolute -mt-3 -ml-6'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88'
                />
              </svg>
            </button>
          )}
          {inputReset.password !== inputResetConfirm && (
            <p className='text-red-500 text-xs pt-3 absolute'>
              {t('register.passwordMatch')}
            </p>
          )}
          <div className='text-center pt-6'>
            <button onClick={handleClick} className='bg-red-500 hover:bg-gray-600 px-14 py-2 rounded-md text-white font-press-start'
              disabled={inputReset.password !== inputResetConfirm}>
              {buttonLoader ? <SpinnerLoading /> : <p>{t('forgotPassword.resetButton')}</p>}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export { ResetPassword };

