import React from 'react';
import { useTranslation } from 'react-i18next';
import img1 from '../../images/ruckstuhl_landing.jpeg';

const RuckstuhlTransport = ( { setSelectedRegion } :any) => {
  const { t } = useTranslation();
  const handleBack = () => {
    setSelectedRegion('');
  };
  return (
    <div className='sm:mt-[115px] mt-10 my-10'>
      <div className='space-y-6'>
        <div>
          <button
            onClick={handleBack}
            className='backButton bg-darkGray font-press-start rounded px-1 hover:text-gray-400 text-sm'>
            <span className='backIcon'>
              <svg
                width='15'
                height='10'
                viewBox='0 0 15 10'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M4.10354 0.19887L0.18055 4.51989C-0.0601831 4.78505 -0.0601831 5.21495 0.18055 5.48011L4.10354 9.80113C4.34428 10.0663 4.73458 10.0663 4.97532 9.80113C5.21605 9.53597 5.21605 9.10606 4.97532 8.8409L2.10465 5.67898L15 5.67898L15 4.32102L2.10465 4.32102L4.97532 1.1591C5.21605 0.893937 5.21605 0.46403 4.97532 0.19887C4.73458 -0.0662889 4.34427 -0.0662889 4.10354 0.19887Z'
                  fill='white'
                />
              </svg>
            </span>
            <span className='backText text-white '>
              {t('propertyListing.back')}
            </span>
          </button>
        </div>
        <div className='text-xl sm:text-[45px] text-center'>
          {t('services.relocationAssistance.title')}
        </div>
        <div className='text-lg sm:text-[35px] text-center'>
          Ruckstuhl Transport
        </div>
        <div className='text-base sm:block'>
          <div className=''>
            <img
              src={img1}
              alt=''
              className='sm:h-[400px] m-auto rounded-t-xl bg-opacity-100 bg-black'
            />
            <div className='hidden sm:block bg-auctionicRed text-white p-5 rounded-b-xl'>
              <h1 className='text-[40px] font-bold my-4 font-braga'>
                {t('services.relocationAssistance.slogan')}
              </h1>
              <p className='text-[20px]'>
                {t('services.relocationAssistance.promo')}
              </p>
            </div>
          </div>
          <div className='sm:hidden block text-darkGray mt-5 space-y-3 '>
            <h1 className='text-xl sm:text-[45px] font-bold my-2'>
              {t('services.relocationAssistance.slogan')}
            </h1>
            <p className='sm:text-[28px]'>
              {t('services.relocationAssistance.promo')}
            </p>
          </div>
        </div>

        <div className='grid sm:grid-cols-2 sm:gap-10'>
          <div className='border p-2 rounded-lg shadow-lg hover:shadow-2xl transition-all duration-150 ease-in-out'>
            <img src='https://ruckstuhl-sg.ch/wp-content/themes/yootheme/cache/ruckstuhl_umzug_zuegelfirma_st_gallen-594ccee5.jpeg'  className='rounded-2xl'/>
            <div className='m-3 space-y-3'>
              <p className='text-lg font-extrabold'>
                {t('services.relocationAssistance.adventage')}
              </p>
              <p>
                {t('services.relocationAssistance.adventageSlogan')}
              </p>
              <ul className='list-inside list-disc'>
                <li>{t('services.relocationAssistance.offer1')}</li>
                <li>{t('services.relocationAssistance.offer2')}</li>
                <li>{t('services.relocationAssistance.offer3')}</li>
                <li>{t('services.relocationAssistance.offer4')}</li>
                <li>{t('services.relocationAssistance.offer5')}</li>
                <li>{t('services.relocationAssistance.offer6')}</li>
                <li>{t('services.relocationAssistance.offer7')}</li>
              </ul>
              <button className='text-auctionicRed'>{t('services.relocationAssistance.movingBtn')}</button>
            </div>
          </div>
          <div className='border p-2 rounded-lg shadow-lg hover:shadow-2xl transition-all duration-150 ease-in-out'>
            <img src='https://ruckstuhl-sg.ch/wp-content/themes/yootheme/cache/ruckstuhl_umzug_st_gallen_moebel_montage_aufbau-39b820e7.jpeg'  className='rounded-2xl'/>
            <div className='m-3 space-y-3'>
              <p className='text-lg font-extrabold'>
                {t('services.relocationAssistance.adventage1Slogan')}
              </p>
              <p>
                {t('services.relocationAssistance.adventage2Slogan')}
              </p>
              <ul className='list-inside list-disc'>
                <li>{t('services.relocationAssistance.offer8')}</li>
                <li>{t('services.relocationAssistance.offer9')}</li>
                <li>{t('services.relocationAssistance.offer10')}</li>
                <li>{t('services.relocationAssistance.offer11')}</li>
                <li>{t('services.relocationAssistance.offer12')}</li>
                <li>{t('services.relocationAssistance.offer13')}</li>
                <li>{t('services.relocationAssistance.offer14')}</li>
              </ul>
              <p>
                {t('services.relocationAssistance.adventage3Slogan')}
              </p>
              <button className='text-auctionicRed'>{t('services.relocationAssistance.movingBtn')}</button>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export { RuckstuhlTransport };
