import React from 'react';
import { Link,useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import img1 from '../../images/IntroListingItem3.jpg';
import Logo from '../../images/logoRed.svg';
import Bid from '../../images/bidding.svg';
import Secure from '../../images/secure.svg';
import Swiss from '../../images/mapOfSwiss.svg';
import { ChatCard } from '../Support';
const Intro = () => {
  window.scrollTo(0, 0);
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className='min-h-screen font-press-start'>
      <div className='relative max-h-[400px] bg-darkGray'>
        <img src={img1} className='w-full object-cover sm:object-[center_-190px] md:object-[center_-105px] 2xl:object-[center_-150px] h-[400px] opacity-40'/>
        <div className=''>
          <div className='grid sm:grid-flow-col grid-flow-row text-white text-[27px] sm:text-5xl font-bold absolute bottom-[250px] sm:bottom-[230px] left-[15px] sm:left-[130px]'>
            <span>{t('listingItem.createAd.yourAdOn')}</span>
            <img src={Logo} className='sm:ml-4 sm:w-[300px]'/>
          </div>
          <p className='absolute bottom-[150px] sm:bottom-[180px] text-white text-[22px] left-[15px] sm:left-[130px]'>{t('listingItem.createAd.findTenant')}</p>
          <button onClick={() =>
            navigate('/listingItem', {
              state: { type: 'auction' },
            })
          }
          className='text-white font-medium absolute bottom-24 left-[15px] sm:left-[130px] bg-auctionicRed border border-red-600 px-5 py-2 rounded-md'>{t('listingItem.createAd.createAd')}</button>
        </div>
        {/* navigate('/listingItem')*/}
      </div>
      <div className='px-[15px] sm:px-[200px]'>
        <div className='flex justify-start'>
          <p className='text-3xl font-bold mt-24 mb-7 text-darkGray'>{t('listingItem.createAd.elevateRealEstate')}</p>
        </div>
        <div className='grid grid-cols-1 lg:grid-cols-3 md:grid-cols-1 justify-between sm:space-x-14 space-x-0'>
          <div className='mb-5'>
            <div className={'flex justify-center'}>
              <img src={Bid} className='mb-5'/>
            </div>
            <p className='flex justify-start font-bold mb-3 text-darkGray'>{t('listingItem.createAd.onlineAuction')}</p>
            <p className='flex text-darkGray'>{t('listingItem.createAd.advertiseRealEstate')}</p>
            <ul className='list-disc pt-2 pl-5'>
              <li>{t('listingItem.createAd.li1')}</li>
              <li>{t('listingItem.createAd.li2')}</li>
              <li>{t('listingItem.createAd.li3')}</li>
              <li>{t('listingItem.createAd.li4')}</li>
              <li>{t('listingItem.createAd.li5')}</li>
              <li>{t('listingItem.createAd.li6')}</li>
              <li>{t('listingItem.createAd.li7')}</li>
              <li>{t('listingItem.createAd.li8')}</li>
              <li>{t('listingItem.createAd.li9')}</li>
            </ul>
          </div>
          <div className='mb-5'>
            <div className={'flex justify-center'}>
              <img src={Secure} className='mb-5'/>
            </div>
            <p className='flex justify-start font-bold mb-3 text-darkGray'>{t('listingItem.createAd.security')}</p>
            <p className='flex text-darkGray'>{t('listingItem.createAd.ultimateSecurity')}</p>
          </div>
          <div>
            <div className={'flex justify-center'}>
              <img src={Swiss} className='mb-5'/>
            </div>
            <p className='flex justify-start font-bold mb-3 text-darkGray'>{t('listingItem.createAd.fromSwiss')}</p>
            <p className='flex text-darkGray'>{t('listingItem.createAd.discorverReliability')}</p>
          </div>
        </div>
      </div>
      <div className='px-[15px] sm:px-[200px] mb-16'>
        <div className='flex justify-start'>
          <p className='text-3xl font-bold mt-6 sm:mt-24 mb-5 text-darkGray'>{t('listingItem.createAd.adCost')}</p>
        </div>
        <div>
          <p className='sm:mr-20 text-darkGray'>{t('listingItem.createAd.weOffer')} <Link  to={'/price/list'} className='text-auctionicRed hover:underline'>{t('listingItem.createAd.priceOverview')}</Link></p>
        </div>
        <div className='flex justify-center'>
          <ChatCard />
        </div>
      </div>
    </div>
  );
};
export { Intro };
