import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AcceptBid } from './AcceptPropertyBid';
import { Bidding } from './Bidding';
import { DeclineBid } from './DeclinePropertyBid';
import { useStompClient, useSubscription } from 'react-stomp-hooks';
import { propertyServices } from '../../services/propertyServices';
import { utils } from '../../helpers/utils';
import { BidTimer } from './BidTimer';
import Logo from '../../images/logoRed.svg';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Bid = ({
  propertyDetails,
  setPropertyDetails,
  isSeller,
  loggedUserId,
  user
}: any) => {
  const { bids, minimum_increasing_amount, current_bid } = propertyDetails;
  const stompClient = useStompClient();
  const [form, setForm] = useState(0);
  const [buttonLoader,setButtonLoader] = useState(false);
  const [inputData, setInputData] = useState({
    price: 0,
    code: '',
    property_on_auction_id: propertyDetails.id,
  });
  const [dataInput, setDataInput] = useState({
    property_on_auction_id: propertyDetails.id,
    code: '',
  });

  const handleBidding = () => {
    if (inputData.code.length == 6) {
      setButtonLoader(true);
      propertyServices
        .confirmRequestBid(
          inputData.property_on_auction_id,
          inputData.price,
          inputData.code
        )
        .then((res) => {
          setButtonLoader(false);
          setForm((form: any) => form + 1);
          stompClient?.publish({
            destination: `/app/property/${propertyDetails.id}`,
            body: JSON.stringify(res.data[0]),
          });
        })
        .catch((err) => {
          setButtonLoader(false);
          if (err.response.data.msg =='failed_verification') {
            // console.log('failed_verification', 'The code you entered is incorrect');
            // Add toast
            toast.error(t(`errors.${err.response.data.msg}`), {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          }
          if (err.response.data.msg =='request_passed') {
            // console.log('request_passed', 'The request time to make a bid has passed please try again');
            // Add toast
            toast.error(t(`errors.${err.response.data.msg}`), {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            setForm(100);
          }
          if (err.response.data.msg !='failed_verification' && err.response.data.msg != 'request_passed') {
            // console.log(err.response.data.msg);
            setForm(100);
          }
        });
    }
  };
  const [openBid, setOpenBid] = useState(false);
  const [openBidID, setopenBidID] = useState(false);
  const [showAllBids, setShowAllBids] = useState(false);
  const [expandedBids, setExpandedBids] = useState<Array<string>>([]);

  const handleExpand = () => {
    setopenBidID(!openBidID);
  };

  const handleClick = () => {
    setShowAllBids(!showAllBids);
  };

  const handleOpenBid = () => {
    setOpenBid(!openBid);
    setForm(0);
  };

  const [acceptBid, setAcceptBid] = useState(false);
  const handleAcceptBid = () => {
    setAcceptBid(!acceptBid);
  };

  const handleRequestAcceptBid = () => {
    setButtonLoader(true);
    propertyServices
      .requestAcceptBid(dataInput)
      .then((response: any) => {
        setButtonLoader(false);
        setDataInput(response.data);
        setForm((form: any) => form + 1);
      })
      .catch((error: any) => {
        // console.log(error);
        setForm(100);
        setButtonLoader(false);
      });
  };

  const handleConfirmAcceptBid = () => {
    if (dataInput.code.length == 6) {
      setButtonLoader(true);
      propertyServices
        .confirmAcceptBid(dataInput)
        .then((response: any) => {
          setButtonLoader(false);
          setDataInput(response.data);
          setForm((form: any) => form + 1);
        })
        .catch((error: any) => {
          setButtonLoader(false);
          if (error.response.data.msg == 'failed_verification') {
            // console.log('failed_verification', 'The code you entered is incorrect');
            // Add toast
            toast.error(t(`errors.${error.response.data.msg}`), {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          }
          if (error.response.data.msg != 'failed_verification') {
            // console.log(error.response.data.msg);
            setForm(100);
          }
          
        });
    }
  };

  const handleRequestDeclineBid = () => {
    setButtonLoader(true);
    propertyServices
      .requestDeclineBid(dataInput)
      .then((response: any) => {
        setButtonLoader(false);
        setDataInput(response.data);
        setForm((form: any) => form + 1);
      })
      .catch((error: any) => {
        // console.log(error);
        setForm(100);
        setButtonLoader(false);
      });
  };

  const handleConfirmDeclineBid = () => {
    if (dataInput.code.length == 6) {
      setButtonLoader(true);
      propertyServices
        .confirmDeclineBid(dataInput)
        .then((response: any) => {
          setButtonLoader(false);
          setDataInput(response.data);
          setForm((form: any) => form + 1);
        })
        .catch((error: any) => {
          setButtonLoader(false);
          if (error.response.data.msg == 'failed_verification') {
            // console.log('failed_verification', 'The code you entered is incorrect');
            // Add toast
            toast.error(t(`errors.${error.response.data.msg}`), {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          }
          if (error.response.data.msg != 'failed_verification') {
            // console.log(error.response.data.msg);
            setForm(100);
          }
        });
    }
  };

  const [declineBid, setDeclineBid] = useState(false);
  const handleDeclineBid = () => {
    setDeclineBid(!declineBid);
  };

  const increasingAmount = minimum_increasing_amount + current_bid;

  useSubscription(`/topic/property/${propertyDetails.id}`, (message) => {
    const data = JSON.parse(message.body);
    setPropertyDetails((prevState: any) => ({
      ...prevState,
      current_bid: data.bid_amount,
      bids: [...prevState.bids, data],
    }));
    setForm((form: any) => form + 1);
  });

  const reversedBids = [...bids].reverse();
  const reversedBidsfrom = [...bids].reverse();
  reversedBidsfrom.slice(1);

  const { t } = useTranslation();
  return (
    <aside className='bg-gradient-to-b from-darkGray to-white border border-black rounded-md  side-content lg:relative'>
      <div className='bg-darkGray drop-shadow-lg'>
        <div className='text-center mt-3'>
          <h6 className='text-white'>{t('trendingEstate.timeLeft')}</h6>
          <h1 className='text-red-600 text-xl pb-2' title={`${t('propertyDetails.thisBid')} ${utils.formatDate(propertyDetails.finishing_date, t)}`}>
            <BidTimer property={propertyDetails} />
          </h1>
        </div>
      </div>
      <div className='overflow-y-auto px-3 space-y-4 h-[250px] sm:min-h-[420px] sm:max-h-[420px] w-auto bg-darkGray py-2 text-sm items-center'>
        <div className=''>
          {bids.length === 0 ? (
            <div className='grid align-center justify-center bg-lightGray px-4 py-4 border-2 border-lightGray rounded-md cursor-pointer'>
              <p className='self-center text-xl'>
                {' '}
                {t('propertyDetails.noBid')}
              </p>
            </div>
          ) : (
            <div className=''>
              <div
                className='grid grid-cols-8 gap-4 align-center justify-end bg-lightGray px-4 py-4 border-2 border-lightGray rounded-md cursor-pointer hover:bg-gray-50 transition-all ease-in-out duration-150'
                onClick={handleExpand}
              >
                <div className='col-span-1'></div>{' '}
                <div className='col-span-6 self-center'>
                  <p className='flex justify-center text-xl font-semibold text-auctionicRed'>
                   CHF {utils.numberWithComma(reversedBids[0].bid_amount)}
                  </p>
                </div>
                <div className='col-span-1 justify-end'>
                  <button
                    className='bg-darkGray py-1 rounded-md hover:scale-110 transition-all ease-in-out duration-150 z-50'
                    onClick={handleClick}
                    title={t('propertyDetails.clickToSee')}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth='1.5'
                      stroke='white'
                      className='w-6 h-6 hover:scale-110 transition-all ease-in-out duration-150'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9'
                      />
                    </svg>
                  </button>
                </div>
                {openBidID && (
                  <div className='col-span-8'>
                    <div className='col-span-8'>
                      <p className='text-right text-darkGray font-bold'>
                        {utils.formatTimewithoutSeconds(
                          reversedBids[0].created_at
                        )}
                      </p>
                    </div>
                    <div className='grid grid-cols-8 pb--4'>
                      <div className='col-span-4'>
                        <p className='text-left text-darkGray font-bold'>
                          ID:{reversedBids[0].id}
                        </p>
                      </div>
                      <div className='col-span-4'>
                        <p className='text-right textdarktext-darkGray font-bolde'>
                          {utils.formatDate(reversedBids[0].created_at, t)}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {showAllBids &&
                reversedBidsfrom.slice(1).map((bid: any) => (
                  <div key={bid.id}>
                    <div
                      className='bg-darkGray mt-3 px-3 py-4 border border-lightGray rounded-md cursor-pointer hover:bg-[#3f3f3f]'
                      onClick={() => {
                        if (expandedBids.includes(bid.id)) {
                          setExpandedBids(
                            expandedBids.filter((_id: any) => bid.id !== _id)
                          );
                        } else {
                          setExpandedBids([...expandedBids, bid.id]);
                        }
                      }}
                    >
                      <p className='text-center text-white text-lg'>
                       CHF {utils.numberWithComma(bid.bid_amount)}
                      </p>
                      {expandedBids.includes(bid.id) && (
                        <div>
                          <div className='grid row'>
                            <p className='text-right text-white font-bold'>
                              {utils.formatTimewithoutSeconds(bid.created_at)}
                            </p>
                          </div>
                          <div className='grid grid-cols-2'>
                            <div key={bid.id}>
                              <p className='text-left text-white font-bold'>
                                ID:{bid.id}
                              </p>
                            </div>
                            <div key={bid.id}>
                              <p className='text-right text-white font-bold'>
                                {utils.formatDate(bid.created_at, t)}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
      <div className='lg:absolute sm:bottom-2 sm:left-0 sm:right-0 p-4 sm:p-2'>
        {!isSeller(loggedUserId) ? (
          <>
            {propertyDetails.status === 4 && (
              <div className='flex justify-center'>
                <button
                  onClick={handleOpenBid}
                  disabled={user.type== 'individual' && (!user.email_confirmed || !user.phone_confirmed || !user.id_card_confirmed) || user.type === 'company' && (!user.email_confirmed || !user?.phone_confirmed || !user.address_confirmed)}
                  className='bidNowBtn px-8 py-3 rounded-md bg-white hover:bg-red-600 hover:text-white shadow shadow-black transition-all ease-out duration-150 text-lg'
                >
                  {t('propertyDetails.bidButton')}
                </button>
                {openBid ? (
                  <Bidding
                    property={propertyDetails.property}
                    form={form}
                    setForm={setForm}
                    handleOpenBid={handleOpenBid}
                    increasingAmount={increasingAmount}
                    handleBidding={handleBidding}
                    inputData={inputData}
                    setInputData={setInputData}
                    propertyDetails={propertyDetails}
                    buttonLoader1={buttonLoader}
                  />
                ) : null}
              </div>
            )}
          </>
        ) : (
          <div className=''>
            {propertyDetails.status <= 5 ? (
              <div className='flex justify-center'>
                <div className='lg:absolute lg:-mt-10'>
                  <img src={Logo} alt='' className='animate-pulse' />
                </div>
              </div>
            ) : propertyDetails.status === 6 ? (
              <div>
                <div className='flex justify-center space-x-3 '>
                  <button
                    className='px-8 py-3 rounded-md bg-white hover:bg-red-600 hover:text-white shadow shadow-black transition-all ease-out duration-150 text-lg'
                    onClick={handleAcceptBid}
                  >
                    {t('propertyDetails.acceptButton')}
                  </button>
                  {acceptBid ? (
                    <AcceptBid
                      propertyDetails={propertyDetails}
                      form={form}
                      handleOpenBid={handleAcceptBid}
                      handleRequestAcceptBid={handleRequestAcceptBid}
                      handleConfirmAcceptBid={handleConfirmAcceptBid}
                      setDataInput={setDataInput}
                      buttonLoader1={buttonLoader}
                    />
                  ) : null}
                  <button
                    className='text-white px-8 py-3 rounded-md bg-red-500 hover:bg-darkGray hover:text-white shadow shadow-black transition-all ease-out duration-150 text-lg'
                    onClick={handleDeclineBid}
                  >
                    {t('propertyDetails.declineButton')}
                  </button>
                  {declineBid ? (
                    <DeclineBid
                      propertyDetails={propertyDetails}
                      form={form}
                      handleDeclineBid={handleDeclineBid}
                      handleRequestDeclineBid={handleRequestDeclineBid}
                      handleConfirmDeclineBid={handleConfirmDeclineBid}
                      setDataInput={setDataInput}
                      buttonLoader1={buttonLoader}
                    />
                  ) : null}
                </div>
              </div>
            ) : (
              <div className='flex justify-center mt-9 '>
                <div>
                  <img src={Logo} alt='' className='animate-pulse' />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </aside>
  );
};

export { Bid };
