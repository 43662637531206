import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FavoriteProperties } from './FavoriteProperties';
import Listing from '../../images/dash_listing.svg';
import Clients from '../../images/dash_clients.svg';
import FavProp from '../../images/likeuncheck.svg';
import Properties from '../../images/dash_properties.svg';
import Visitors from '../../images/dash_overview.svg';
import { t } from 'i18next';
import './Dashboard.css';
import { utils } from '../../helpers/utils';
import { DashboardLoading } from '../LoadingTest/DashboardLoading';
import { useNavigate } from 'react-router-dom';
import { utilsProperty } from '../PropertyDetails/utils';

interface Message {
  handleNavigation: any;
  loadingFavorite: any;
  agreements: any;
  favProperty: any;
  setActiveTab: any;
  propertiesbyId: any;
  loadingOverviewListing:any;
}
interface Props {
  propertiesbyId: any;
  handleNavigation: any;
}

const ListingItems = (props: Props) => {
  const { propertiesbyId, handleNavigation } = props;

  return (
    <div className='grid grid-cols-1 p-4'>
      {propertiesbyId?.length !== 0 ? (
        <div>
          {propertiesbyId?.slice(0, 3).map((properties: any) => {
            return (
              <div className='py-2' key={properties.id}>
                <div className='block rounded-xl transition-all ease-out duration-100 hover:bg-white hover:shadow-lg border-[0.5px] shadow-lg'>
                  <div className='block sm:flex py-5 px-4'>
                    <div className='self-center'>
                      <img
                        src={properties.header_pic}
                        alt=''
                        className='rounded-md bg-black w-full sm:h-[110px] sm:w-[120px] '
                      />
                    </div>
                    <div className='block sm:flex justify-center sm:justify-between pl-3 mt-4 sm:mt-0 sm:pr-3 sm:w-full'>
                      <div className='self-center space-y-1.5 sm:col-span-2 my-4 sm:my-0 ml-0 sm:ml-3'>
                        <div className='text-[15px] font-bold text-darkGray'>
                          {utilsProperty.generateTitleList(properties,t)}
                        </div>
                        <div className='text-[15px] text-red-500 font-press-start text-darkGray'>
                          {properties.type === 'auction' ? (
                            <p>
                              CHF{' '}
                              {utils.numberWithComma(properties.current_bid)}
                            </p>
                          ) : (
                            <p>CHF {utils.numberWithComma(properties.price)}</p>
                          )}
                        </div>
                        {properties.title !== '' && (
                          <p className='text-xs sm:text-[15px] truncate font-press-start text-darkGray'>
                            {properties.title}
                          </p>
                        )}
                        <div className='sm:flex justify-center sm:justify-start space-x-1 text-darkGray'>
                          <p className='text-xs sm:text-[15px] self-center'>
                            {t(`dashboard.overviewSection.${properties.type}`)}
                          </p>
                        </div>
                        {properties.payment.plan.tier !== 'silver' &&
                          <div className='flex justify-start space-x-1 mt-4 sm:mt-0 text-xs'>
                            <p className='self-center truncate text-darkGray'>
                              {t('dashboard.favouriteSection.propertyVisitors')}
                            </p>
                            <span className='flex space-x-1 font-bold self-center'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                                strokeWidth='2'
                                stroke='currentColor'
                                className='w-5 h-5  self-center'
                              >
                                <path
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  d='M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z'
                                />
                                <path
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
                                />
                              </svg>
                              <p className='flex mt-1 self-center truncate text-darkGray'>
                                {properties.views} {t('dashboard.favouriteSection.views')}
                              </p>
                            </span>
                          </div>
                        }
                      </div>
                      <div className='self-center mt-7 sm:mt-0'>
                        <button
                          className='seeBtn text-white rounded-md bg-darkGray'
                          onClick={() => handleNavigation(properties)}
                        >
                          <span className='seeInfo'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth='1.5'
                              stroke='currentColor'
                              className='w-6 h-6'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75'
                              />
                            </svg>
                          </span>
                          <span className='moreInfo text-white '>
                            {t('dashboard.overviewSection.seeProperty')}
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className='flex justify-center my-6'>
          <div className='relative flex'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='#A3A3A3'
              className='w-6 h-6 absolute ml-5 mt-[20px]'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205l3 1m1.5.5l-1.5-.5M6.75 7.364V3h-3v18m3-13.636l10.5-3.819'
              />
            </svg>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='0.5'
              stroke='#A3A3A3'
              className='w-16 h-16 rotate-45'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M19.5 12h-15'
              />
            </svg>
          </div>
          <p className='text-sm self-center text-gray-400 text-darkGray'>
            {t('dashboard.overviewSection.noCurrentListedProperties')}
          </p>
        </div>
      )}
    </div>
  );
};
const Overview = (props: Message) => {
  const { t } = useTranslation();
  const { favProperty, handleNavigation, loadingFavorite, agreements, setActiveTab, propertiesbyId, loadingOverviewListing } = props;
  const [loading] = useState(true);
  // console.log(loading);
  const navigate = useNavigate();

  const overviewData = favProperty.length > 1 ? [favProperty[0], favProperty[1]] : favProperty.length == 1 ? [favProperty[0]] : [];

  const trends = propertiesbyId?.filter((prop:any) => {
    return prop.payment.plan.tier == 'platinum';
  });

  const sumOfViews = propertiesbyId?.map((prop: any) => {
    return prop?.views;
  }).reduce(function(prev: any, current:any) {
    return prev + +current;
  }, 0);

  return (
    <div className='px-2 sm:px-0  mt-10 sm:mt-0 font-secondfont text-darkGray'>
      <div className='grid grid-cols-1 sm:grid-cols-1 '>
        <div className='bg-[#F5F5F5] rounded-xl overflow-y-auto shadow-sm shadow-gray-300 font-press-start'>
          <div className='flex justify-center pt-[16px] pb-[6px] border-b border-darkGray mx-10 '>
            <p className='text-[20px] pl-3  '>
              {t('dashboard.currentListings')}
            </p>
          </div>
          <div className='max-[450px] overflow-y-auto'>
            {loadingOverviewListing ?
              <><DashboardLoading />
                <DashboardLoading />
                <DashboardLoading />
              </>:
              <>
                <ListingItems
                  propertiesbyId={propertiesbyId}
                  handleNavigation={handleNavigation}
                />
                <div className='text-center pb-5'>
                  <button onClick={() => {navigate('/dashboard/2'); setActiveTab(2);}}
                    className='bg-darkGray px-7 py-2 text-white rounded-lg hover:bg-auctionicRed transition-all duration-200 ease-in-out'
                  >
                    {t('dashboard.overviewSection.seeAll')} {propertiesbyId?.length}{' '}
                    {t('dashboard.overviewSection.properties')}
                  </button>
                </div>
              </>}
          </div>
        </div>
        <div className='grid grid-cols-1 sm:grid-cols-1 sm:gap-5 pt-2 sm:mt-10 font-press-start gap-y-2 sm:gap-y-0'>
          <div className=''>
            <FavoriteProperties
              favProperty={overviewData}
              handleNavigation={handleNavigation}
              loadingFavorite={loadingFavorite}
            />
          </div>
          <div className='bg-[#F5F5F5] rounded-lg px-10 mt-10 py-[24px]'>
            <div className='text-[20px] border-b-2 border-darkGray font-avenir55'>
              {t('dashboard.overviewSection.profileStats')}
            </div>
            <div className='flow-root'>
              <ul role='list' className='divide-y divide-darkGray'>
                <li className='py-3 sm:py-4'>
                  <div className='flex items-center space-x-4'>
                    <div className='flex-shrink-0'>
                      <img className='w-8 h-8' src={Listing} alt='' />
                    </div>
                    <div className='flex-1 min-w-0'>
                      <p className='text-sm font-medium text-darkGray truncate '>
                        {t('dashboard.overviewSection.listedProperties')}
                      </p>
                    </div>
                    <div className='inline-flex items-center text-base font-semibold text-darkGray '>
                      {propertiesbyId?.length}
                    </div>
                  </div>
                </li>
                <li className='py-3 sm:py-4'>
                  <div className='flex items-center space-x-4'>
                    <div className='flex-shrink-0'>
                      <img
                        className='w-8 h-8 rounded-full'
                        src={FavProp}
                        alt=''
                      />
                    </div>
                    <div className='flex-1 min-w-0'>
                      <p className='text-sm font-medium text-darkGray truncate dark:text-darkGray'>
                        {t('dashboard.overviewSection.favouriteProperties')}
                      </p>
                    </div>
                    <div className='inline-flex items-center text-base font-semibold text-darkGray '>
                      {favProperty.length}
                    </div>
                  </div>
                </li>
                <li className='py-3 sm:py-4'>
                  <div className='flex items-center space-x-4'>
                    <div className='flex-shrink-0'>
                      <img
                        className='w-8 h-8 rounded-full'
                        src={Clients}
                        alt=''
                      />
                    </div>
                    <div className='flex-1 min-w-0'>
                      <p className='text-sm font-medium text-darkGray truncate '>
                        {t('dashboard.overviewSection.agreements')}
                      </p>
                    </div>
                    <div className='inline-flex items-center text-base font-semibold text-darkGray '>
                      {agreements.length}
                    </div>
                  </div>
                </li>
                <li className='py-3 sm:py-4'>
                  <div className='flex items-center space-x-4'>
                    <div className='flex-shrink-0'>
                      <img
                        className='w-8 h-8 rounded-full'
                        src={Properties}
                        alt=''
                      />
                    </div>
                    <div className='flex-1 min-w-0'>
                      <p className='text-sm font-medium text-darkGray truncate '>
                        {t('dashboard.overviewSection.trendingProperties')}
                      </p>
                    </div>
                    <div className='inline-flex items-center text-base font-semibold text-darkGray '>
                      {trends?.length}
                    </div>
                  </div>
                </li>
                <li className='pt-3 pb-0 sm:pt-4'>
                  <div className='flex items-center space-x-4'>
                    <div className='flex-shrink-0'>
                      <img
                        className='w-8 h-8 rounded-full'
                        src={Visitors}
                        alt=''
                      />
                    </div>
                    <div className='flex-1 min-w-0'>
                      <p className='text-sm font-medium text-darkGray truncate '>
                        {t('dashboard.overviewSection.propertyVisitors')}
                      </p>
                    </div>
                    <div className='inline-flex items-center text-base font-semibold text-darkGray '>
                      {sumOfViews}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Overview };
