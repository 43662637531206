import React from 'react';
import { BrowserRouter as Router, Outlet, Route, Routes } from 'react-router-dom';
import './App.css';
import {
  Landing,
  PropertyListing,
  Footer,
  ListingItem,
  Login,
  Register,
  Dashboard,
  Navbar,
  ForgotPassword,
  Mortgage,
  Error404,
  PropertyDetails,
  ResetPassword,
  PropertyRentDetails,
  PropertySaleDetails,
  LoadingTest, PrivacyPolicy, TermsConditions, LoginRedirect,
  PropertyError, Newsletter, Unsubscribe, Auctionic, ListingsLoading,
  DashboardLoading, Jobs
} from './components';
import { ContactForm } from './components/Contact/ContactForm';
import { DebtCollectionForm } from './components/DebtCollectionForm';
import { LearnMore } from './components/LearnMore/LearnMore';
import { HowItWorks } from './components/HowItWorks/HowItWorks';
import { Services } from './components/Services';
import { AuthProvider } from './context/AuthProvider';
import { AuthWithoutLogin } from './context/AuthWithoutLogin';
import { Intro } from './components/LisitingItem/Intro';
import { PriceList } from './components/LisitingItem/PriceList';
import { Media } from './components/Media';
import { CancelPayment, ErrorPayment, SuccessfulPayment } from './components/Payment';
import { ErrorVerifivation, SuccessfulVerification, Unverified } from './components/Idenfy';
import { Support } from './components/Support';
import { Impressum } from './components/Impressum';
const HeaderLayout = () => (
  <>
    <Navbar/>
    <Outlet/>
  </>
);
function App() {
  return (
    <Router>
      <Routes>
        <Route element={<HeaderLayout />}>
          <Route path='/listing' element={<PropertyListing />} />
          <Route path='/listingLoadingTest' element={<ListingsLoading />} />
          <Route path='/listingDashboardTest' element={<DashboardLoading />} />
          <Route path='/listingItem' element={<AuthProvider><ListingItem /></AuthProvider>} />
          <Route path='/price/list' element={<PriceList />} />
          <Route path='/footer' element={<Footer />} />
          <Route path='/property/auction/details/:id' element={<AuthProvider><PropertyDetails /></AuthProvider>} />
          <Route path='*' element={<Error404 />} />
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/dashboard/:tab' element={<AuthProvider><Dashboard /></AuthProvider>} />
          <Route path='/navbar' element={<Navbar />} />
          <Route path='/forgot/password' element={<ForgotPassword />} />
          <Route path='/mortgage' element={<Mortgage />} />
          <Route path='/reset/password/:token' element={<ResetPassword />} />
          <Route path='/learnMore' element={<LearnMore />} />
          <Route path='/how_it_works' element={<HowItWorks />} />
          <Route path='/contact' element={<ContactForm />} />
          <Route path='/services' element={<Services />} />
          <Route path='/debtCollection' element={<AuthProvider><DebtCollectionForm /></AuthProvider>} />
          <Route path='/login/redirect' element={<LoginRedirect />} />
          <Route
            path='/property/rent/details/:id'
            element={<PropertyRentDetails />}
          />
          <Route
            path='/property/sale/details/:id'
            element={<PropertySaleDetails />}
          />
          <Route path='/property/error' element={<PropertyError />} />
          <Route path='/newsletter' element={<Newsletter />} />
          <Route path='/unsubscribe' element={<Unsubscribe />} />
          <Route path='/auctionic' element={<Auctionic />} />
          <Route path='/create-ad' element={<Intro />}/>
          <Route path='/privacy/policy' element={<PrivacyPolicy />} />
          <Route path='/terms/conditions' element={<TermsConditions />} />
          <Route path='/jobs' element={<Jobs />} />
          <Route path='/media' element={<Media />} />
          <Route path='/payment/success' element={<SuccessfulPayment />} />
          <Route path='/payment/error' element={<ErrorPayment />} />
          <Route path='/payment/cancel' element={<CancelPayment />} />
          <Route path='/verify/unverified' element={<Unverified />} />
          <Route path='/verify/success' element={<SuccessfulVerification />} />
          <Route path='/verify/error' element={<ErrorVerifivation />} />
          <Route path='/support' element={<Support />} />
          <Route path='/impressum' element={<Impressum />} />
        </Route>
        <Route path='/' element={<AuthWithoutLogin><Landing /></AuthWithoutLogin>} />
        <Route path='/loader' element={<LoadingTest />} />
      </Routes>
      <Footer />
    </Router>
  );
}
export default App;
