import React, { useEffect, } from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../../images/auctionic_logo_red.svg';
import Logo from '../../images/logoRed.svg';
import { useNavigate } from 'react-router-dom';

const Impressum = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getCountry = () => {
    const language = localStorage.getItem('locale');
    let country = 'Schweiz';
    if (language != null) {
      if (language == 'de') {
        country = 'Schweiz';
      }
      else if (language == 'en') {
        country = 'Switzerland';
      }
      else if (language == 'fr') {
        country = 'Suisse';
      }
      else if (language == 'it') {
        country = 'Svizzera';
      }
    } else {
      country = 'Schweiz';
    }
    return country;
  };

  return (
    <div className='min-h-screen sm:py-12 py-2'>
      <div className='flex justify-center'>
        <button onClick={() => navigate('/')}>
          <img src={Logo} alt='' />
        </button>
      </div>
      <div className='flex justify-center text-lg py-20 sm:py-0 '>
        <div className='sm:px-20'>
          <h1 className='m-auto sm:pt-20 text-5xl'>Impressum</h1>
          <div className='sm:flex'>
            
            <div className='mt-10'>
              <div className='text-xl font-bold'>AUCTIONIC AG</div>
              <div>Auweg 2</div>
              <div>8371 Busswil TG</div>
              <div>{getCountry()}</div>
              <br></br>
              <div>UID: CHE-223.456.369</div>
              <br></br>
              <div className='flex absolute'>
                <p>{t('contact.email')}:</p>
                <a
                  href='mailto:support@auctionic.ch'
                  className='hover:border-b hover:border-red-500 transtion-all ease-in-out duration-100 ml-2'
                >
                  info@auctionic.ch
                </a>
              </div>
            </div>
            <div className='sm:ml-20 mt-10 sm:mt0 w-[200px] h-[200px]'>
              <img src={logo} alt='LOGO' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Impressum };
