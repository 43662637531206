import React from 'react';
import Logo from '../../images/logoRed.svg';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SuccessfulVerification = () => {
  const navigate = useNavigate();
  window.scrollTo(0, 0);
  const { t } = useTranslation();
  return (
    <div className='sm:px-10 md:px-24 px-2 sm:py-12 py-2 min-h-screen font-press-start bg-gray-50'>
      <div className='flex justify-center m-7'>
        <Link to='/'>
          <img src={Logo} className='w-[250px] md:w-[400px]' alt='' />
        </Link>
      </div>
      <div className='mx-auto max-w-xl border border-gray-300 p-8 rounded-lg'>
        <div className='flex items-center justify-center'>
          <button className='bg-green-500 rounded-full' disabled>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='white'
              className='w-10 h-10'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
              />
            </svg>
          </button>
        </div>
        <h1 className='sm:text-4xl font-bold text-xl text-center text-gray-500 font-avenir55 border-b border-red-500 py-5'>
          {t('verify.successfull')}
        </h1>
        <div className='pt-14'>
          <p className='sm:text-xl text-center font-normal text-xl text-darkGray font-avenir55'>
            {t('verify.successfull_message')}
          </p>
        </div>
        
      </div>

      <div className='flex justify-center m-10'>
        <button
          onClick={() => navigate('/')}
          className='flex hover:text-white border-2 hover:border-auctionicRed border-black hover:bg-auctionicRed transition-all ease-in-out duration-300 p-2 rounded-lg space-x-2 '
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth='1.5'
            stroke='currentColor'
            className='w-6 h-6 '
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
            />
          </svg>
          {t('propertyListing.goBackToLanding')}
        </button>
      </div>
    </div>
  );
};
export { SuccessfulVerification };
