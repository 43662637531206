import React, { useEffect, useRef, useState } from 'react';
import './ChatBot.css';
import Logo from '../../images/logoRed.svg';
import Close from '../../images/x_Black.svg';
import { useTranslation } from 'react-i18next';
import { Cookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';


const chatBotMessages = [
  {
    id: 1,
    problem: 'chat.login',  // FAiled to login
    answer: 'chat.answer_login', // Please check your email and password
  },
  {
    id: 2,
    problem: 'chat.register', // Failed to register
    answer: 'chat.answer_register' // Please check your email if it is taken',
  },
  {
    id: 3,
    problem: 'chat.password_reset', // Failed to reset password
    answer: 'chat.answer_password_reset' // Please check your otp code
  },
  {
    id: 4,
    problem: 'chat.list',
    answer: 'chat.answer_list'
  },
  {
    id: 5,
    problem: 'chat.verify',
    answer: 'chat.answer_verify'
  },
  // {
  //   id: 7,
  //   problem: 'chat.ai',
  //   answer: ''
  // },
  {
    id: 6,
    problem: 'chat.other',
    answer: ''
  },
];

function ChatBot() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [userSelectedProblem, setUserSelectedProblem] = useState('');
  const [userMessage, setUserMessage] = useState('');
  const openChat = () => {
    setOpen(!open);
  };

  const onFormSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
  };
  
  const dummy = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setTimeout(() => dummy.current !== null && dummy.current.scrollIntoView({ behavior: 'smooth' }), 300);
  },[chatBotMessages.map((message) => message.id)]);

  const { t } = useTranslation();
  const cookies = new Cookies();



  return (
    <div>
      {!open && (
        <button
          onClick={openChat}
          title='Auctionic support team'
          className={`z-50 fixed ${!cookies.get('our_site_cookies') ? 'bottom-[5rem]' : 'bottom-6' }  right-8 bg-auctionicRed w-20 h-20 rounded-full hover:drop-shadow-lg flex justify-center items-center text-white text-4xl hover:bg-auctionicRed hover:scale-105 duration-300`}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='28'
            height='28'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='28'
              height='28'
              viewBox='0 0 32 32'
            >
              <path
                fill='#fff'
                d='M27.91 11.79c.176.792.278 1.606.278 2.442 0 6.456-5.48 11.838-12.696 12.994 1.526.616 3.236.976 5.058.976 1.718 0 3.34-.316 4.804-.866 1.854.632 3.878.796 5.552.796A14.237 14.237 0 0 1 29 25.164c1.856-1.656 3-3.85 3-6.266 0-2.856-1.594-5.402-4.09-7.108zM1.472 25.418c.174.33.516.536.886.536.016 0 .034 0 .048-.002 1.058-.05 3.162-.752 5.39-2.166 1.192.21 3.862.606 4.794.606 7.034 0 12.542-4.898 12.542-11.152 0-6.15-5.624-11.152-12.542-11.152S.052 7.088.052 13.238c0 2.862 1.26 5.628 3.478 7.698a14.896 14.896 0 0 1-1.964 3.408 1.002 1.002 0 0 0-.094 1.074zm.58-12.18c0-5.046 4.728-9.152 10.542-9.152s10.542 4.106 10.542 9.152c0 5.216-4.532 9.152-10.542 9.152-.79 0-3.934-.458-4.798-.64a1 1 0 0 0-.758.146 16.74 16.74 0 0 1-2.37 1.314c.382-.718.712-1.462.988-2.23.142-.398.02-.842-.304-1.11-2.128-1.75-3.3-4.106-3.3-6.632z'
                className='color000 svgShape'
              />
            </svg>
          </svg>
        </button>
      )}
      {open && (
        <div className='flex fixed z-[100] -bottom-[0rem] sm:-bottom-[-1rem] right-0 sm:right-7 flex-col items-end justify-end px-4 m-auto sm:px-0'>
          <div className='w-auto sm:w-[21rem] h-[28.5rem] flex flex-col border rounded-lg shadow-md bg-white chatBotForLargerScreens'>
            <div className='flex justify-between border-b p-3 mt-2'>
              <img className='rounded-full' src={Logo} />
              <div className='pl-2 self-center cursor-pointer'>
                <img
                  className='rounded-full w-5 h-5'
                  onClick={() => setOpen(false)}
                  src={Close}
                />
              </div>
            </div>
            <div className='flex-1 px-4 py-4 overflow-y-auto'>
              <div className='flex items-center mb-4'>
                <div className='flex-none flex flex-col items-center space-y-1 mr-4'>
                  <img className='rounded-full w-10 h-10' src={Logo} />
                </div>
                <div className='flex-1 bg-red-400 text-white p-2 rounded-lg mb-2 relative font-press-start'>
                  <div className='pb-3'>
                    {t('chat.welcome')}
                    <p>{t('chat.welcome_1')}</p>
                    {/* Hello, welcome to auctionic chat bot, please explain your
                    problem. For addittional info and faster reply please reach
                    out to our email: info@auctionic.ch */}
                    <button
                      onClick={() => navigate('/support')}
                      className="bg-auctionicRed hover:bg-white hover:text-auctionicRed border-2 border-auctionicRed text-white font-bold py-2 px-4 rounded-lg"
                    >
                      {t('support.startChat')}
                    </button>
                  </div>
                  <div>
                    {chatBotMessages.map((message) => (
                      <div
                        key={message.id}
                        className='cursor-pointer'
                      >
                        <button onClick={message.id == 7 ? () => {navigate('/support'); }:() => {setUserSelectedProblem(message.problem); }}
                          className='bg-gray-100 text-gray-600 p-2 rounded-lg mb-2 relative font-press-start'> {t(`${message.problem}`)} </button>
                      </div>
                    ))}
                  </div>
                  <div className='absolute left-0 top-1/2 transform -translate-x-1/2 rotate-45 w-2 h-2 bg-red-400'></div>
                </div>
              </div>
              {userSelectedProblem != '' && (
                <div>
                  <div className='flex items-center flex-row-reverse mb-4'>
                    <div className='flex-none flex flex-col items-center space-y-1 ml-4'>
                      {/* <img*/}
                      {/*  className='rounded-full w-10 h-10'*/}
                      {/*  src='https://icons.veryicon.com/png/o/internet--web/prejudice/user-128.png'*/}
                      {/*  alt='user'*/}
                      {/* />*/}
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth='1.5'
                        stroke='red'
                        className='w-6 h-6'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z'
                        />
                      </svg>
                    </div>
                    <div className='flex-1 bg-gray-200 text-gray-800 p-2 rounded-lg mb-2 relative font-press-start'>
                      <div>
                        {t(`${userSelectedProblem}`)}
                      </div>
                      <div className='absolute right-0 top-1/2 transform translate-x-1/2 rotate-45 w-2 h-2 bg-indigo-100'></div>
                    </div>
                  </div>
                  
                  <div className='flex-1 px-4 py-4 overflow-y-auto'>
                    <div className='flex items-center mb-4'>
                      <div className='flex-none flex flex-col items-center space-y-1 mr-4'>
                        <img className='rounded-full w-10 h-10' src={Logo} />
                      </div>
                      <div className='flex-1 bg-red-400 text-white p-2 rounded-lg mb-2 relative font-press-start'>
                        <div>
                          {chatBotMessages.filter((item) => item.problem === userSelectedProblem).length == 0 ?(
                            <p ref={dummy}>
                              {t('chat.sorry_contact_1')}
                              {t('chat.sorry_contact_2')}
                              {/* //Sorry, we dont have an answer for this problem yet.
                              //  Please reach out to our email:support@auctionic.ch */}
                            </p>
                          ):(
                            chatBotMessages.map((message) => (
                              message.problem == userSelectedProblem && (
                                <div>
                                  <p ref={dummy}>{t(`${message.answer}`)}</p>
                                  <p>{t('chat.answer_other')}</p>
                                </div>
                              )
                            )
                            ))}
                            
                        </div>
                        <div className='absolute left-0 top-1/2 transform -translate-x-1/2 rotate-45 w-2 h-2 bg-red-400'></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <form onSubmit={onFormSubmit}>
              <div className='flex items-center border-t p-2'>
                <div className='w-full mx-3'>
                  <input
                    className='w-full py-2 px-2 text-sm rounded-md border border-gray-200 text-gray-900'
                    type='text'
                    value={userMessage}
                    placeholder={t('chat.enter_message')}
                    onChange={(e) => setUserMessage(e.target.value)}
                  />
                </div>
                <div>
                  <button
                    className='inline-flex hover:bg-indigo-50 rounded-full p-2'
                    type='submit'
                    onClick={() => { setUserMessage(''); setUserSelectedProblem(userMessage);}}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='28'
                      height='28'
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='28'
                        height='28'
                        viewBox='0 0 24 24'
                      >
                        <path fill='none' d='M0 0h24v24H0V0z' />
                        <path
                          fill='#ff002b'
                          d='M3.4 20.4l17.45-7.48c.81-.35.81-1.49 0-1.84L3.4 3.6c-.66-.29-1.39.2-1.39.91L2 9.12c0 .5.37.93.87.99L17 12 2.87 13.88c-.5.07-.87.5-.87 1l.01 4.61c0 .71.73 1.2 1.39.91z'
                          className='color000 svgShape'
                        />
                      </svg>
                    </svg>
                  </button>
                  
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
export { ChatBot };
