const data = [
  {
    location: 'Z\u00fcrich',
    location_type: 'canton',
    id: 1,
  },
  {
    location: 'Bern',
    location_type: 'canton',
    id: 2,
  },
  {
    location: 'Luzern',
    location_type: 'canton',
    id: 3,
  },
  {
    location: 'Uri',
    location_type: 'canton',
    id: 4,
  },
  {
    location: 'Schwyz',
    location_type: 'canton',
    id: 5,
  },
  {
    location: 'Obwalden',
    location_type: 'canton',
    id: 6,
  },
  {
    location: 'Glarus',
    location_type: 'canton',
    id: 7,
  },
  {
    location: 'Zug',
    location_type: 'canton',
    id: 8,
  },
  {
    location: 'Freiburg',
    location_type: 'canton',
    id: 9,
  },
  {
    location: 'Solothurn',
    location_type: 'canton',
    id: 10,
  },
  {
    location: 'Basel',
    location_type: 'canton',
    id: 11,
  },
  {
    location: 'Schaffhausen',
    location_type: 'canton',
    id: 12,
  },
  {
    location: 'Appenzell',
    location_type: 'canton',
    id: 13,
  },
  {
    location: 'Sankt Gallen',
    location_type: 'canton',
    id: 14,
  },
  {
    location: 'Graub\u00fcnden',
    location_type: 'canton',
    id: 15,
  },
  {
    location: 'Aargau',
    location_type: 'canton',
    id: 16,
  },
  {
    location: 'Thurgau',
    location_type: 'canton',
    id: 17,
  },
  {
    location: 'Ticino',
    location_type: 'canton',
    id: 18,
  },
  {
    location: 'Vaud',
    location_type: 'canton',
    id: 19,
  },
  {
    location: 'Valais',
    location_type: 'canton',
    id: 20,
  },
  {
    location: 'Neuch\u00e2tel',
    location_type: 'canton',
    id: 21,
  },
  {
    location: 'Gen\u00e8ve',
    location_type: 'canton',
    id: 22,
  },
  {
    location: 'Jura',
    location_type: 'canton',
    id: 23,
  },
  {
    location: 'Z\u00fcrich',
    location_type: 'city',
    id: 24,
  },
  {
    location: 'Geneva',
    location_type: 'city',
    id: 25,
  },
  {
    location: 'Basel',
    location_type: 'city',
    id: 26,
  },
  {
    location: 'Lausanne',
    location_type: 'city',
    id: 27,
  },
  {
    location: 'Bern',
    location_type: 'city',
    id: 28,
  },
  {
    location: 'Winterthur',
    location_type: 'city',
    id: 29,
  },
  {
    location: 'Lucerne',
    location_type: 'city',
    id: 30,
  },
  {
    location: 'Sankt Gallen',
    location_type: 'city',
    id: 31,
  },
  {
    location: 'Lugano',
    location_type: 'city',
    id: 32,
  },
  {
    location: 'Biel/Bienne',
    location_type: 'city',
    id: 33,
  },
  {
    location: 'Thun',
    location_type: 'city',
    id: 34,
  },
  {
    location: 'Bellinzona',
    location_type: 'city',
    id: 35,
  },
  {
    location: 'K\u00f6niz',
    location_type: 'city',
    id: 36,
  },
  {
    location: 'La Chaux-de-Fonds',
    location_type: 'city',
    id: 37,
  },
  {
    location: 'Fribourg',
    location_type: 'city',
    id: 38,
  },
  {
    location: 'Schaffhausen',
    location_type: 'city',
    id: 39,
  },
  {
    location: 'Vernier',
    location_type: 'city',
    id: 40,
  },
  {
    location: 'Chur',
    location_type: 'city',
    id: 41,
  },
  {
    location: 'Sion',
    location_type: 'city',
    id: 42,
  },
  {
    location: 'Uster',
    location_type: 'city',
    id: 43,
  },
  {
    location: 'Neuch\u00e2tel',
    location_type: 'city',
    id: 44,
  },
  {
    location: 'Landecy',
    location_type: 'city',
    id: 45,
  },
  {
    location: 'Zug',
    location_type: 'city',
    id: 46,
  },
  {
    location: 'Yverdon-les-Bains',
    location_type: 'city',
    id: 47,
  },
  {
    location: 'Emmen',
    location_type: 'city',
    id: 48,
  },
  {
    location: 'D\u00fcbendorf',
    location_type: 'city',
    id: 49,
  },
  {
    location: 'Kriens',
    location_type: 'city',
    id: 50,
  },
  {
    location: 'Rapperswil-Jona',
    location_type: 'city',
    id: 51,
  },
  {
    location: 'Dietikon',
    location_type: 'city',
    id: 52,
  },
  {
    location: 'Montreux',
    location_type: 'city',
    id: 53,
  },
  {
    location: 'Wetzikon',
    location_type: 'city',
    id: 54,
  },
  {
    location: 'Baar',
    location_type: 'city',
    id: 55,
  },
  {
    location: 'Meyrin',
    location_type: 'city',
    id: 56,
  },
  {
    location: 'Wil',
    location_type: 'city',
    id: 57,
  },
  {
    location: 'Bulle',
    location_type: 'city',
    id: 58,
  },
  {
    location: 'Horgen',
    location_type: 'city',
    id: 59,
  },
  {
    location: 'Carouge',
    location_type: 'city',
    id: 60,
  },
  {
    location: 'Frauenfeld',
    location_type: 'city',
    id: 61,
  },
  {
    location: 'Kreuzlingen',
    location_type: 'city',
    id: 62,
  },
  {
    location: 'W\u00e4denswil',
    location_type: 'city',
    id: 63,
  },
  {
    location: 'Riehen',
    location_type: 'city',
    id: 64,
  },
  {
    location: 'Aarau',
    location_type: 'city',
    id: 65,
  },
  {
    location: 'Allschwil',
    location_type: 'city',
    id: 66,
  },
  {
    location: 'Renens',
    location_type: 'city',
    id: 67,
  },
  {
    location: 'Wettingen',
    location_type: 'city',
    id: 68,
  },
  {
    location: 'Nyon',
    location_type: 'city',
    id: 69,
  },
  {
    location: 'B\u00fclach',
    location_type: 'city',
    id: 70,
  },
  {
    location: 'Vevey',
    location_type: 'city',
    id: 71,
  },
  {
    location: 'Opfikon',
    location_type: 'city',
    id: 72,
  },
  {
    location: 'Kloten',
    location_type: 'city',
    id: 73,
  },
  {
    location: 'Reinach',
    location_type: 'city',
    id: 74,
  },
  {
    location: 'Baden',
    location_type: 'city',
    id: 75,
  },
  {
    location: 'Onex',
    location_type: 'city',
    id: 76,
  },
  {
    location: 'Adliswil',
    location_type: 'city',
    id: 77,
  },
  {
    location: 'Schlieren',
    location_type: 'city',
    id: 78,
  },
  {
    location: 'Volketswil',
    location_type: 'city',
    id: 79,
  },
  {
    location: 'Pully',
    location_type: 'city',
    id: 80,
  },
  {
    location: 'Regensdorf',
    location_type: 'city',
    id: 81,
  },
  {
    location: 'Gossau',
    location_type: 'city',
    id: 82,
  },
  {
    location: 'Muttenz',
    location_type: 'city',
    id: 83,
  },
  {
    location: 'Thalwil',
    location_type: 'city',
    id: 84,
  },
  {
    location: 'Monthey',
    location_type: 'city',
    id: 85,
  },
  {
    location: 'Ostermundigen',
    location_type: 'city',
    id: 86,
  },
  {
    location: 'Littau',
    location_type: 'city',
    id: 87,
  },
  {
    location: 'Grenchen',
    location_type: 'city',
    id: 88,
  },
  {
    location: 'Olten',
    location_type: 'city',
    id: 89,
  },
  {
    location: 'Sierre',
    location_type: 'city',
    id: 90,
  },
  {
    location: 'Solothurn',
    location_type: 'city',
    id: 91,
  },
  {
    location: 'Pratteln',
    location_type: 'city',
    id: 92,
  },
  {
    location: 'Burgdorf',
    location_type: 'city',
    id: 93,
  },
  {
    location: 'Freienbach',
    location_type: 'city',
    id: 94,
  },
  {
    location: 'Wohlen',
    location_type: 'city',
    id: 95,
  },
  {
    location: 'Locarno',
    location_type: 'city',
    id: 96,
  },
  {
    location: 'Wallisellen',
    location_type: 'city',
    id: 97,
  },
  {
    location: 'Morges',
    location_type: 'city',
    id: 98,
  },
  {
    location: 'Steffisburg',
    location_type: 'city',
    id: 99,
  },
  {
    location: 'Herisau',
    location_type: 'city',
    id: 100,
  },
  {
    location: 'Langenthal',
    location_type: 'city',
    id: 101,
  },
  {
    location: 'Binningen',
    location_type: 'city',
    id: 102,
  },
  {
    location: 'Einsiedeln',
    location_type: 'city',
    id: 103,
  },
  {
    location: 'Lyss',
    location_type: 'city',
    id: 104,
  },
  {
    location: 'Mendrisio',
    location_type: 'city',
    id: 105,
  },
  {
    location: 'Arbon',
    location_type: 'city',
    id: 106,
  },
  {
    location: 'Liestal',
    location_type: 'city',
    id: 107,
  },
  {
    location: 'St\u00e4fa',
    location_type: 'city',
    id: 108,
  },
  {
    location: 'K\u00fcsnacht',
    location_type: 'city',
    id: 109,
  },
  {
    location: 'Horw',
    location_type: 'city',
    id: 110,
  },
  {
    location: 'Schwyz',
    location_type: 'city',
    id: 111,
  },
  {
    location: 'Th\u00f4nex',
    location_type: 'city',
    id: 112,
  },
  {
    location: 'Meilen',
    location_type: 'city',
    id: 113,
  },
  {
    location: 'Oftringen',
    location_type: 'city',
    id: 114,
  },
  {
    location: 'Amriswil',
    location_type: 'city',
    id: 115,
  },
  {
    location: 'Versoix',
    location_type: 'city',
    id: 116,
  },
  {
    location: 'Richterswil',
    location_type: 'city',
    id: 117,
  },
  {
    location: 'Rheinfelden',
    location_type: 'city',
    id: 118,
  },
  {
    location: 'Brig-Glis',
    location_type: 'city',
    id: 119,
  },
  {
    location: 'Gland',
    location_type: 'city',
    id: 120,
  },
  {
    location: 'K\u00fcssnacht',
    location_type: 'city',
    id: 121,
  },
  {
    location: 'Muri',
    location_type: 'city',
    id: 122,
  },
  {
    location: 'Zollikon',
    location_type: 'city',
    id: 123,
  },
  {
    location: 'Ecublens',
    location_type: 'city',
    id: 124,
  },
  {
    location: 'Spiez',
    location_type: 'city',
    id: 125,
  },
  {
    location: 'Del\u00e9mont',
    location_type: 'city',
    id: 126,
  },
  {
    location: 'Buchs',
    location_type: 'city',
    id: 127,
  },
  {
    location: 'Prilly',
    location_type: 'city',
    id: 128,
  },
  {
    location: 'Ch\u00eane-Bougeries',
    location_type: 'city',
    id: 129,
  },
  {
    location: 'Le Grand-Saconnex',
    location_type: 'city',
    id: 130,
  },
  {
    location: 'R\u00fcti',
    location_type: 'city',
    id: 131,
  },
  {
    location: 'M\u00fcnchenstein',
    location_type: 'city',
    id: 132,
  },
  {
    location: 'Villars-sur-Gl\u00e2ne',
    location_type: 'city',
    id: 133,
  },
  {
    location: 'Affoltern am Albis',
    location_type: 'city',
    id: 134,
  },
  {
    location: 'Arth',
    location_type: 'city',
    id: 135,
  },
  {
    location: 'La Tour-de-Peilz',
    location_type: 'city',
    id: 136,
  },
  {
    location: 'Pf\u00e4ffikon',
    location_type: 'city',
    id: 137,
  },
  {
    location: 'Spreitenbach',
    location_type: 'city',
    id: 138,
  },
  {
    location: 'Altst\u00e4tten',
    location_type: 'city',
    id: 139,
  },
  {
    location: 'Zofingen',
    location_type: 'city',
    id: 140,
  },
  {
    location: 'Veyrier',
    location_type: 'city',
    id: 141,
  },
  {
    location: 'Bassersdorf',
    location_type: 'city',
    id: 142,
  },
  {
    location: 'Weinfelden',
    location_type: 'city',
    id: 143,
  },
  {
    location: 'Belp',
    location_type: 'city',
    id: 144,
  },
  {
    location: 'Worb',
    location_type: 'city',
    id: 145,
  },
  {
    location: 'Hinwil',
    location_type: 'city',
    id: 146,
  },
  {
    location: 'Romanshorn',
    location_type: 'city',
    id: 147,
  },
  {
    location: 'Oberwil',
    location_type: 'city',
    id: 148,
  },
  {
    location: 'Brugg',
    location_type: 'city',
    id: 149,
  },
  {
    location: 'M\u00f6hlin',
    location_type: 'city',
    id: 150,
  },
  {
    location: 'M\u00e4nnedorf',
    location_type: 'city',
    id: 151,
  },
  {
    location: 'Davos',
    location_type: 'city',
    id: 152,
  },
  {
    location: 'Plan-les-Ouates',
    location_type: 'city',
    id: 153,
  },
  {
    location: 'Waltikon',
    location_type: 'city',
    id: 154,
  },
  {
    location: 'Lenzburg',
    location_type: 'city',
    id: 155,
  },
  {
    location: 'Flawil',
    location_type: 'city',
    id: 156,
  },
  {
    location: 'Neuhausen am Rheinfall',
    location_type: 'city',
    id: 157,
  },
  {
    location: 'Le Locle',
    location_type: 'city',
    id: 158,
  },
  {
    location: 'Suhr',
    location_type: 'city',
    id: 159,
  },
  {
    location: 'Sarnen',
    location_type: 'city',
    id: 160,
  },
  {
    location: 'Aesch',
    location_type: 'city',
    id: 161,
  },
  {
    location: 'Birsfelden',
    location_type: 'city',
    id: 162,
  },
  {
    location: 'Lutry',
    location_type: 'city',
    id: 163,
  },
  {
    location: 'Bernex',
    location_type: 'city',
    id: 164,
  },
  {
    location: 'Maur',
    location_type: 'city',
    id: 165,
  },
  {
    location: 'Aigle',
    location_type: 'city',
    id: 166,
  },
  {
    location: 'Naters',
    location_type: 'city',
    id: 167,
  },
  {
    location: 'Steinhaus',
    location_type: 'city',
    id: 168,
  },
  {
    location: 'Therwil',
    location_type: 'city',
    id: 169,
  },
  {
    location: 'Payerne',
    location_type: 'city',
    id: 170,
  },
  {
    location: 'Gossau',
    location_type: 'city',
    id: 171,
  },
  {
    location: 'Sursee',
    location_type: 'city',
    id: 172,
  },
  {
    location: 'Hochdorf',
    location_type: 'city',
    id: 173,
  },
  {
    location: 'Urdorf',
    location_type: 'city',
    id: 174,
  },
  {
    location: 'Wald',
    location_type: 'city',
    id: 175,
  },
  {
    location: 'Estavayer-le-Lac',
    location_type: 'city',
    id: 176,
  },
  {
    location: 'Wittenbach',
    location_type: 'city',
    id: 177,
  },
  {
    location: 'Widnau',
    location_type: 'city',
    id: 178,
  },
  {
    location: 'Epalinges',
    location_type: 'city',
    id: 179,
  },
  {
    location: 'Rorschach',
    location_type: 'city',
    id: 180,
  },
  {
    location: 'Embrach',
    location_type: 'city',
    id: 181,
  },
  {
    location: 'Altdorf',
    location_type: 'city',
    id: 182,
  },
  {
    location: 'Langnau',
    location_type: 'city',
    id: 183,
  },
  {
    location: 'Goldach',
    location_type: 'city',
    id: 184,
  },
  {
    location: 'Sch\u00fcbelbach',
    location_type: 'city',
    id: 185,
  },
  {
    location: 'Arlesheim',
    location_type: 'city',
    id: 186,
  },
  {
    location: 'Niederhasli',
    location_type: 'city',
    id: 187,
  },
  {
    location: 'Rothrist',
    location_type: 'city',
    id: 188,
  },
  {
    location: 'Aadorf',
    location_type: 'city',
    id: 189,
  },
  {
    location: 'Ingenbohl',
    location_type: 'city',
    id: 190,
  },
  {
    location: 'Oberriet',
    location_type: 'city',
    id: 191,
  },
  {
    location: 'Zuchwil',
    location_type: 'city',
    id: 192,
  },
  {
    location: 'Unter\u00e4geri',
    location_type: 'city',
    id: 193,
  },
  {
    location: 'Landquart',
    location_type: 'city',
    id: 194,
  },
  {
    location: 'Neuenhof',
    location_type: 'city',
    id: 195,
  },
  {
    location: 'H\u00fcnenberg',
    location_type: 'city',
    id: 196,
  },
  {
    location: 'Fully',
    location_type: 'city',
    id: 197,
  },
  {
    location: 'Lachen',
    location_type: 'city',
    id: 198,
  },
  {
    location: 'Wattwil',
    location_type: 'city',
    id: 199,
  },
  {
    location: 'Bussy',
    location_type: 'city',
    id: 200,
  },
  {
    location: 'Conthey',
    location_type: 'city',
    id: 201,
  },
  {
    location: 'Mels',
    location_type: 'city',
    id: 202,
  },
  {
    location: 'Egg',
    location_type: 'city',
    id: 203,
  },
  {
    location: 'Hombrechtikon',
    location_type: 'city',
    id: 204,
  },
  {
    location: 'F\u00e4llanden',
    location_type: 'city',
    id: 205,
  },
  {
    location: 'Biberist',
    location_type: 'city',
    id: 206,
  },
  {
    location: 'Obersiggenthal',
    location_type: 'city',
    id: 207,
  },
  {
    location: 'Le Mont-sur-Lausanne',
    location_type: 'city',
    id: 208,
  },
  {
    location: 'Reinach',
    location_type: 'city',
    id: 209,
  },
  {
    location: 'Kilchberg',
    location_type: 'city',
    id: 210,
  },
  {
    location: 'Stans',
    location_type: 'city',
    id: 211,
  },
  {
    location: 'Oberentfelden',
    location_type: 'city',
    id: 212,
  },
  {
    location: 'Murten',
    location_type: 'city',
    id: 213,
  },
  {
    location: 'Bagnes',
    location_type: 'city',
    id: 214,
  },
  {
    location: 'Aarburg',
    location_type: 'city',
    id: 215,
  },
  {
    location: 'Domat/Ems',
    location_type: 'city',
    id: 216,
  },
  {
    location: 'Chiasso',
    location_type: 'city',
    id: 217,
  },
  {
    location: 'Buchs',
    location_type: 'city',
    id: 218,
  },
  {
    location: 'D\u00fcdingen',
    location_type: 'city',
    id: 219,
  },
  {
    location: 'Crissier',
    location_type: 'city',
    id: 220,
  },
  {
    location: 'R\u00fcmlang',
    location_type: 'city',
    id: 221,
  },
  {
    location: 'Visp',
    location_type: 'city',
    id: 222,
  },
  {
    location: 'Muri',
    location_type: 'city',
    id: 223,
  },
  {
    location: 'Au',
    location_type: 'city',
    id: 224,
  },
  {
    location: 'Willisau',
    location_type: 'city',
    id: 225,
  },
  {
    location: 'Bex',
    location_type: 'city',
    id: 226,
  },
  {
    location: 'Sirnach',
    location_type: 'city',
    id: 227,
  },
  {
    location: 'Bremgarten',
    location_type: 'city',
    id: 228,
  },
  {
    location: 'Gr\u00e4nichen',
    location_type: 'city',
    id: 229,
  },
  {
    location: 'Chavannes-pr\u00e8s-Renens',
    location_type: 'city',
    id: 230,
  },
  {
    location: 'Savi\u00e8se',
    location_type: 'city',
    id: 231,
  },
  {
    location: 'Dietlikon',
    location_type: 'city',
    id: 232,
  },
  {
    location: 'Windisch',
    location_type: 'city',
    id: 233,
  },
  {
    location: 'Rothenburg',
    location_type: 'city',
    id: 234,
  },
  {
    location: 'D\u00fcrnten',
    location_type: 'city',
    id: 235,
  },
  {
    location: 'Langnau am Albis',
    location_type: 'city',
    id: 236,
  },
  {
    location: 'Ollon',
    location_type: 'city',
    id: 237,
  },
  {
    location: 'Hirslen',
    location_type: 'city',
    id: 238,
  },
  {
    location: 'Moutier',
    location_type: 'city',
    id: 239,
  },
  {
    location: 'Villmergen',
    location_type: 'city',
    id: 240,
  },
  {
    location: 'Minusio',
    location_type: 'city',
    id: 241,
  },
  {
    location: 'Seuzach',
    location_type: 'city',
    id: 242,
  },
  {
    location: 'Rorschacherberg',
    location_type: 'city',
    id: 243,
  },
  {
    location: 'Wollerau',
    location_type: 'city',
    id: 244,
  },
  {
    location: 'Untersiggenthal',
    location_type: 'city',
    id: 245,
  },
  {
    location: 'Meggen',
    location_type: 'city',
    id: 246,
  },
  {
    location: 'Herzogenbuchsee',
    location_type: 'city',
    id: 247,
  },
  {
    location: 'Bubikon',
    location_type: 'city',
    id: 248,
  },
  {
    location: 'Reiden',
    location_type: 'city',
    id: 249,
  },
  {
    location: 'Altendorf',
    location_type: 'city',
    id: 250,
  },
  {
    location: 'Grabs',
    location_type: 'city',
    id: 251,
  },
  {
    location: 'Orbe',
    location_type: 'city',
    id: 252,
  },
  {
    location: 'Ch\u00e2tel-Saint-Denis',
    location_type: 'city',
    id: 253,
  },
  {
    location: 'Oberglatt',
    location_type: 'city',
    id: 254,
  },
  {
    location: 'Frutigen',
    location_type: 'city',
    id: 255,
  },
  {
    location: 'Ruswil',
    location_type: 'city',
    id: 256,
  },
  {
    location: 'Schwarzenburg',
    location_type: 'city',
    id: 257,
  },
  {
    location: 'Heimberg',
    location_type: 'city',
    id: 258,
  },
  {
    location: 'Saanen',
    location_type: 'city',
    id: 259,
  },
  {
    location: 'Nidau',
    location_type: 'city',
    id: 260,
  },
  {
    location: 'Dornach',
    location_type: 'city',
    id: 261,
  },
  {
    location: 'Losone',
    location_type: 'city',
    id: 262,
  },
  {
    location: 'Bottmingen',
    location_type: 'city',
    id: 263,
  },
  {
    location: 'Sissach',
    location_type: 'city',
    id: 264,
  },
  {
    location: 'Porrentruy',
    location_type: 'city',
    id: 265,
  },
  {
    location: 'Berom\u00fcnster',
    location_type: 'city',
    id: 266,
  },
  {
    location: 'Thal',
    location_type: 'city',
    id: 267,
  },
  {
    location: 'Oberengstringen',
    location_type: 'city',
    id: 268,
  },
  {
    location: 'Trimbach',
    location_type: 'city',
    id: 269,
  },
  {
    location: 'Wiesendangen',
    location_type: 'city',
    id: 270,
  },
  {
    location: 'Derendingen',
    location_type: 'city',
    id: 271,
  },
  {
    location: 'W\u00fcrenlos',
    location_type: 'city',
    id: 272,
  },
  {
    location: 'Diepoldsau',
    location_type: 'city',
    id: 273,
  },
  {
    location: 'Frenkendorf',
    location_type: 'city',
    id: 274,
  },
  {
    location: 'Uznach',
    location_type: 'city',
    id: 275,
  },
  {
    location: 'Oberuzwil',
    location_type: 'city',
    id: 276,
  },
  {
    location: 'Birmensdorf',
    location_type: 'city',
    id: 277,
  },
  {
    location: 'Fehraltorf',
    location_type: 'city',
    id: 278,
  },
  {
    location: 'V\u00e9troz',
    location_type: 'city',
    id: 279,
  },
  {
    location: 'Kerns',
    location_type: 'city',
    id: 280,
  },
  {
    location: 'Menziken',
    location_type: 'city',
    id: 281,
  },
  {
    location: 'Buchs',
    location_type: 'city',
    id: 282,
  },
  {
    location: 'Herrliberg',
    location_type: 'city',
    id: 283,
  },
  {
    location: 'Oensingen',
    location_type: 'city',
    id: 284,
  },
  {
    location: 'Teufen',
    location_type: 'city',
    id: 285,
  },
  {
    location: 'Bolligen',
    location_type: 'city',
    id: 286,
  },
  {
    location: 'Rolle',
    location_type: 'city',
    id: 287,
  },
  {
    location: 'Massagno',
    location_type: 'city',
    id: 288,
  },
  {
    location: 'Gelterkinden',
    location_type: 'city',
    id: 289,
  },
  {
    location: 'K\u00fcttigen',
    location_type: 'city',
    id: 290,
  },
  {
    location: 'Blonay',
    location_type: 'city',
    id: 291,
  },
  {
    location: 'Boudry',
    location_type: 'city',
    id: 292,
  },
  {
    location: 'Uetikon am See',
    location_type: 'city',
    id: 293,
  },
  {
    location: 'Moudon',
    location_type: 'city',
    id: 294,
  },
  {
    location: 'Balsthal',
    location_type: 'city',
    id: 295,
  },
  {
    location: 'Buchrain',
    location_type: 'city',
    id: 296,
  },
  {
    location: 'Biasca',
    location_type: 'city',
    id: 297,
  },
  {
    location: 'Obererli',
    location_type: 'city',
    id: 298,
  },
  {
    location: 'Alpnach',
    location_type: 'city',
    id: 299,
  },
  {
    location: 'Bischofszell',
    location_type: 'city',
    id: 300,
  },
  {
    location: 'Glarus',
    location_type: 'city',
    id: 301,
  },
  {
    location: 'Kirchberg',
    location_type: 'city',
    id: 302,
  },
  {
    location: 'Dielsdorf',
    location_type: 'city',
    id: 303,
  },
  {
    location: 'Zell',
    location_type: 'city',
    id: 304,
  },
  {
    location: 'Saxon',
    location_type: 'city',
    id: 305,
  },
  {
    location: 'Uetendorf',
    location_type: 'city',
    id: 306,
  },
  {
    location: 'Sankt Margrethen',
    location_type: 'city',
    id: 307,
  },
  {
    location: 'R\u00fcschlikon',
    location_type: 'city',
    id: 308,
  },
  {
    location: 'Peseux',
    location_type: 'city',
    id: 309,
  },
  {
    location: 'Hergiswil',
    location_type: 'city',
    id: 310,
  },
  {
    location: 'Villeneuve',
    location_type: 'city',
    id: 311,
  },
  {
    location: 'Unterseen',
    location_type: 'city',
    id: 312,
  },
  {
    location: 'Jegenstorf',
    location_type: 'city',
    id: 313,
  },
  {
    location: 'Echallens',
    location_type: 'city',
    id: 314,
  },
  {
    location: 'Neftenbach',
    location_type: 'city',
    id: 315,
  },
  {
    location: 'Saint-Prex',
    location_type: 'city',
    id: 316,
  },
  {
    location: 'Walenstadt',
    location_type: 'city',
    id: 317,
  },
  {
    location: 'Appenzell',
    location_type: 'city',
    id: 318,
  },
  {
    location: 'Zermatt',
    location_type: 'city',
    id: 319,
  },
  {
    location: 'Mellingen',
    location_type: 'city',
    id: 320,
  },
  {
    location: 'Lindau',
    location_type: 'city',
    id: 321,
  },
  {
    location: 'Interlaken',
    location_type: 'city',
    id: 322,
  },
  {
    location: 'Sennwald',
    location_type: 'city',
    id: 323,
  },
  {
    location: 'N\u00fcrensdorf',
    location_type: 'city',
    id: 324,
  },
  {
    location: 'Kaiseraugst',
    location_type: 'city',
    id: 325,
  },
  {
    location: 'Cologny',
    location_type: 'city',
    id: 326,
  },
  {
    location: 'Erlenbach',
    location_type: 'city',
    id: 327,
  },
  {
    location: 'Frick',
    location_type: 'city',
    id: 328,
  },
  {
    location: 'Laufen',
    location_type: 'city',
    id: 329,
  },
  {
    location: 'Colombier',
    location_type: 'city',
    id: 330,
  },
  {
    location: 'Fislisbach',
    location_type: 'city',
    id: 331,
  },
  {
    location: 'Bonstetten',
    location_type: 'city',
    id: 332,
  },
  {
    location: 'Ascona',
    location_type: 'city',
    id: 333,
  },
  {
    location: 'Dagmersellen',
    location_type: 'city',
    id: 334,
  },
  {
    location: 'Rupperswil',
    location_type: 'city',
    id: 335,
  },
  {
    location: 'Thayngen',
    location_type: 'city',
    id: 336,
  },
  {
    location: 'Courtepin',
    location_type: 'city',
    id: 337,
  },
  {
    location: 'Schattdorf',
    location_type: 'city',
    id: 338,
  },
  {
    location: 'Gebenstorf',
    location_type: 'city',
    id: 339,
  },
  {
    location: 'Adligenswil',
    location_type: 'city',
    id: 340,
  },
  {
    location: 'M\u00fcnchwilen',
    location_type: 'city',
    id: 341,
  },
  {
    location: 'Greifensee',
    location_type: 'city',
    id: 342,
  },
  {
    location: 'Obfelden',
    location_type: 'city',
    id: 343,
  },
  {
    location: 'Buochs',
    location_type: 'city',
    id: 344,
  },
  {
    location: 'Vechigen',
    location_type: 'city',
    id: 345,
  },
  {
    location: 'Seon',
    location_type: 'city',
    id: 346,
  },
  {
    location: 'Pr\u00e9verenges',
    location_type: 'city',
    id: 347,
  },
  {
    location: 'Konolfingen Dorf',
    location_type: 'city',
    id: 348,
  },
  {
    location: 'Romont',
    location_type: 'city',
    id: 349,
  },
  {
    location: 'Feusisberg',
    location_type: 'city',
    id: 350,
  },
  {
    location: 'Lengnau',
    location_type: 'city',
    id: 351,
  },
  {
    location: 'Bellach',
    location_type: 'city',
    id: 352,
  },
  {
    location: 'Lausen',
    location_type: 'city',
    id: 353,
  },
  {
    location: 'Gerlafingen',
    location_type: 'city',
    id: 354,
  },
  {
    location: 'Hitzkirch',
    location_type: 'city',
    id: 355,
  },
  {
    location: 'Glattfelden',
    location_type: 'city',
    id: 356,
  },
  {
    location: 'Saint L\u00e9gier-La Chi\u00e9saz',
    location_type: 'city',
    id: 357,
  },
  {
    location: 'Eglisau',
    location_type: 'city',
    id: 358,
  },
  {
    location: 'Gommiswald',
    location_type: 'city',
    id: 359,
  },
  {
    location: 'Ettingen',
    location_type: 'city',
    id: 360,
  },
  {
    location: 'Sachseln',
    location_type: 'city',
    id: 361,
  },
  {
    location: 'H\u00e4gendorf',
    location_type: 'city',
    id: 362,
  },
  {
    location: 'Zumikon',
    location_type: 'city',
    id: 363,
  },
  {
    location: 'Fraubrunnen',
    location_type: 'city',
    id: 364,
  },
  {
    location: 'Sevelen',
    location_type: 'city',
    id: 365,
  },
  {
    location: 'Schwerzenbach',
    location_type: 'city',
    id: 366,
  },
  {
    location: 'Oberrieden',
    location_type: 'city',
    id: 367,
  },
  {
    location: 'Wangen bei Olten',
    location_type: 'city',
    id: 368,
  },
  {
    location: 'Wangen',
    location_type: 'city',
    id: 369,
  },
  {
    location: 'Dulliken',
    location_type: 'city',
    id: 370,
  },
  {
    location: 'Sumiswald',
    location_type: 'city',
    id: 371,
  },
  {
    location: 'Root',
    location_type: 'city',
    id: 372,
  },
  {
    location: 'Ebnat-Kappel',
    location_type: 'city',
    id: 373,
  },
  {
    location: 'B\u00e4retswil',
    location_type: 'city',
    id: 374,
  },
  {
    location: 'Chavornay',
    location_type: 'city',
    id: 375,
  },
  {
    location: 'Saint Moritz',
    location_type: 'city',
    id: 376,
  },
  {
    location: 'Bauma',
    location_type: 'city',
    id: 377,
  },
  {
    location: 'Geroldswil',
    location_type: 'city',
    id: 378,
  },
  {
    location: 'Niederglatt',
    location_type: 'city',
    id: 379,
  },
  {
    location: 'Sch\u00f6nenwerd',
    location_type: 'city',
    id: 380,
  },
  {
    location: 'Bettlach',
    location_type: 'city',
    id: 381,
  },
  {
    location: 'Elgg',
    location_type: 'city',
    id: 382,
  },
  {
    location: 'Strengelbach',
    location_type: 'city',
    id: 383,
  },
  {
    location: 'Mettmenstetten',
    location_type: 'city',
    id: 384,
  },
  {
    location: 'Sainte-Croix',
    location_type: 'city',
    id: 385,
  },
  {
    location: 'Flums',
    location_type: 'city',
    id: 386,
  },
  {
    location: 'Kaltbrunn',
    location_type: 'city',
    id: 387,
  },
  {
    location: 'Huttwil',
    location_type: 'city',
    id: 388,
  },
  {
    location: 'Sigriswil',
    location_type: 'city',
    id: 389,
  },
  {
    location: 'Ehrendingen',
    location_type: 'city',
    id: 390,
  },
  {
    location: 'Ennetb\u00fcrgen',
    location_type: 'city',
    id: 391,
  },
  {
    location: 'Balgach',
    location_type: 'city',
    id: 392,
  },
  {
    location: 'Zuzwil',
    location_type: 'city',
    id: 393,
  },
  {
    location: 'Niederlenz',
    location_type: 'city',
    id: 394,
  },
  {
    location: 'Berikon',
    location_type: 'city',
    id: 395,
  },
  {
    location: 'Weiningen',
    location_type: 'city',
    id: 396,
  },
  {
    location: 'Oetwil am See',
    location_type: 'city',
    id: 397,
  },
  {
    location: 'Turbenthal',
    location_type: 'city',
    id: 398,
  },
  {
    location: 'Cortaillod',
    location_type: 'city',
    id: 399,
  },
  {
    location: 'W\u00e4ngi',
    location_type: 'city',
    id: 400,
  },
  {
    location: 'Niederbipp',
    location_type: 'city',
    id: 401,
  },
  {
    location: 'Egnach',
    location_type: 'city',
    id: 402,
  },
  {
    location: 'Oberkirch',
    location_type: 'city',
    id: 403,
  },
  {
    location: 'W\u00fcrenlingen',
    location_type: 'city',
    id: 404,
  },
  {
    location: 'Gordola',
    location_type: 'city',
    id: 405,
  },
  {
    location: 'Troistorrents',
    location_type: 'city',
    id: 406,
  },
  {
    location: 'Saint-Sulpice',
    location_type: 'city',
    id: 407,
  },
  {
    location: 'Triengen',
    location_type: 'city',
    id: 408,
  },
  {
    location: 'Le Landeron',
    location_type: 'city',
    id: 409,
  },
  {
    location: 'Beringen',
    location_type: 'city',
    id: 410,
  },
  {
    location: 'T\u00e4gerwilen',
    location_type: 'city',
    id: 411,
  },
  {
    location: 'Aarberg',
    location_type: 'city',
    id: 412,
  },
  {
    location: 'Confignon',
    location_type: 'city',
    id: 413,
  },
  {
    location: 'Meiringen',
    location_type: 'city',
    id: 414,
  },
  {
    location: 'Stabio',
    location_type: 'city',
    id: 415,
  },
  {
    location: 'Scuol',
    location_type: 'city',
    id: 416,
  },
  {
    location: 'Le Chenit',
    location_type: 'city',
    id: 417,
  },
  {
    location: 'Stansstad',
    location_type: 'city',
    id: 418,
  },
  {
    location: 'Morbio Inferiore',
    location_type: 'city',
    id: 419,
  },
  {
    location: 'Saint-Maurice',
    location_type: 'city',
    id: 420,
  },
  {
    location: 'Tramelan',
    location_type: 'city',
    id: 421,
  },
  {
    location: 'Menzingen',
    location_type: 'city',
    id: 422,
  },
  {
    location: 'Monte Ceneri',
    location_type: 'city',
    id: 423,
  },
  {
    location: 'Bronschhofen',
    location_type: 'city',
    id: 424,
  },
  {
    location: 'Aarwangen',
    location_type: 'city',
    id: 425,
  },
  {
    location: 'Winkel',
    location_type: 'city',
    id: 426,
  },
  {
    location: 'F\u00fcllinsdorf',
    location_type: 'city',
    id: 427,
  },
  {
    location: 'Zufikon',
    location_type: 'city',
    id: 428,
  },
  {
    location: 'K\u00f6lliken',
    location_type: 'city',
    id: 429,
  },
  {
    location: 'Rafz',
    location_type: 'city',
    id: 430,
  },
  {
    location: 'Oberb\u00fcren',
    location_type: 'city',
    id: 431,
  },
  {
    location: 'Agno',
    location_type: 'city',
    id: 432,
  },
  {
    location: 'Klosters Platz',
    location_type: 'city',
    id: 433,
  },
  {
    location: 'Sch\u00f6tz',
    location_type: 'city',
    id: 434,
  },
  {
    location: 'Sch\u00f6ftland',
    location_type: 'city',
    id: 435,
  },
  {
    location: 'Bubendorf',
    location_type: 'city',
    id: 436,
  },
  {
    location: 'Br\u00fcgg',
    location_type: 'city',
    id: 437,
  },
  {
    location: 'Rebstein',
    location_type: 'city',
    id: 438,
  },
  {
    location: 'Speicher',
    location_type: 'city',
    id: 439,
  },
  {
    location: 'Eschlikon',
    location_type: 'city',
    id: 440,
  },
  {
    location: 'Weggis',
    location_type: 'city',
    id: 441,
  },
  {
    location: 'Gachnang',
    location_type: 'city',
    id: 442,
  },
  {
    location: 'Caslano',
    location_type: 'city',
    id: 443,
  },
  {
    location: 'Russikon',
    location_type: 'city',
    id: 444,
  },
  {
    location: 'Birr',
    location_type: 'city',
    id: 445,
  },
  {
    location: 'Cheseaux-sur-Lausanne',
    location_type: 'city',
    id: 446,
  },
  {
    location: 'Wolhusen',
    location_type: 'city',
    id: 447,
  },
  {
    location: 'Utzenstorf',
    location_type: 'city',
    id: 448,
  },
  {
    location: 'Kehrsatz',
    location_type: 'city',
    id: 449,
  },
  {
    location: 'Wichtrach',
    location_type: 'city',
    id: 450,
  },
  {
    location: 'Avenches',
    location_type: 'city',
    id: 451,
  },
  {
    location: 'Sins',
    location_type: 'city',
    id: 452,
  },
  {
    location: 'Bad Zurzach',
    location_type: 'city',
    id: 453,
  },
  {
    location: 'Uitikon',
    location_type: 'city',
    id: 454,
  },
  {
    location: 'Sch\u00fcpfheim',
    location_type: 'city',
    id: 455,
  },
  {
    location: 'Lucens',
    location_type: 'city',
    id: 456,
  },
  {
    location: 'Lens',
    location_type: 'city',
    id: 457,
  },
  {
    location: 'Vouvry',
    location_type: 'city',
    id: 458,
  },
  {
    location: 'Satigny',
    location_type: 'city',
    id: 459,
  },
  {
    location: 'Engelberg',
    location_type: 'city',
    id: 460,
  },
  {
    location: 'Sempach',
    location_type: 'city',
    id: 461,
  },
  {
    location: 'Gross H\u00f6chstetten',
    location_type: 'city',
    id: 462,
  },
  {
    location: 'Heiden',
    location_type: 'city',
    id: 463,
  },
  {
    location: 'Unterentfelden',
    location_type: 'city',
    id: 464,
  },
  {
    location: 'L\u00fctzelfl\u00fch',
    location_type: 'city',
    id: 465,
  },
  {
    location: 'Paradiso',
    location_type: 'city',
    id: 466,
  },
  {
    location: 'Degersheim',
    location_type: 'city',
    id: 467,
  },
  {
    location: 'Niederrohrdorf',
    location_type: 'city',
    id: 468,
  },
  {
    location: 'Matten',
    location_type: 'city',
    id: 469,
  },
  {
    location: 'D\u00e4llikon',
    location_type: 'city',
    id: 470,
  },
  {
    location: 'Roggwil',
    location_type: 'city',
    id: 471,
  },
  {
    location: 'Oberrohrdorf',
    location_type: 'city',
    id: 472,
  },
  {
    location: 'Ayent',
    location_type: 'city',
    id: 473,
  },
  {
    location: 'D\u00f6ttingen',
    location_type: 'city',
    id: 474,
  },
  {
    location: 'Hilterfingen',
    location_type: 'city',
    id: 475,
  },
  {
    location: 'Prangins',
    location_type: 'city',
    id: 476,
  },
  {
    location: 'Leysin',
    location_type: 'city',
    id: 477,
  },
  {
    location: 'Hunzenschwil',
    location_type: 'city',
    id: 478,
  },
  {
    location: 'Diessenhofen',
    location_type: 'city',
    id: 479,
  },
  {
    location: 'Seengen',
    location_type: 'city',
    id: 480,
  },
  {
    location: 'B\u00fcrglen',
    location_type: 'city',
    id: 481,
  },
  {
    location: 'Berneck',
    location_type: 'city',
    id: 482,
  },
  {
    location: 'Brittnau',
    location_type: 'city',
    id: 483,
  },
  {
    location: 'B\u00f6ttstein',
    location_type: 'city',
    id: 484,
  },
  {
    location: 'Lostorf',
    location_type: 'city',
    id: 485,
  },
  {
    location: 'Leuk',
    location_type: 'city',
    id: 486,
  },
  {
    location: 'Magden',
    location_type: 'city',
    id: 487,
  },
  {
    location: 'Chamoson',
    location_type: 'city',
    id: 488,
  },
  {
    location: 'Dottikon',
    location_type: 'city',
    id: 489,
  },
  {
    location: 'Muhen',
    location_type: 'city',
    id: 490,
  },
  {
    location: 'Sulgen',
    location_type: 'city',
    id: 491,
  },
  {
    location: 'Niederg\u00f6sgen',
    location_type: 'city',
    id: 492,
  },
  {
    location: 'Nottwil',
    location_type: 'city',
    id: 493,
  },
  {
    location: 'Breitenbach',
    location_type: 'city',
    id: 494,
  },
  {
    location: 'Pfungen',
    location_type: 'city',
    id: 495,
  },
  {
    location: 'Safenwil',
    location_type: 'city',
    id: 496,
  },
  {
    location: 'B\u00fcrglen',
    location_type: 'city',
    id: 497,
  },
  {
    location: 'Vallorbe',
    location_type: 'city',
    id: 498,
  },
  {
    location: 'Sch\u00e4nis',
    location_type: 'city',
    id: 499,
  },
  {
    location: 'Unterengstringen',
    location_type: 'city',
    id: 500,
  },
  {
    location: 'Grindelwald',
    location_type: 'city',
    id: 501,
  },
  {
    location: 'Bevaix',
    location_type: 'city',
    id: 502,
  },
  {
    location: 'Langendorf',
    location_type: 'city',
    id: 503,
  },
  {
    location: 'Founex',
    location_type: 'city',
    id: 504,
  },
  {
    location: 'Jonschwil',
    location_type: 'city',
    id: 505,
  },
  {
    location: 'Steckborn',
    location_type: 'city',
    id: 506,
  },
  {
    location: 'Reichenburg',
    location_type: 'city',
    id: 507,
  },
  {
    location: 'Erlen',
    location_type: 'city',
    id: 508,
  },
  {
    location: 'La Neuveville',
    location_type: 'city',
    id: 509,
  },
  {
    location: 'Hedingen',
    location_type: 'city',
    id: 510,
  },
  {
    location: 'Sch\u00fcpfen',
    location_type: 'city',
    id: 511,
  },
  {
    location: 'Belmont-sur-Lausanne',
    location_type: 'city',
    id: 512,
  },
  {
    location: 'M\u00f6nchaltorf',
    location_type: 'city',
    id: 513,
  },
  {
    location: 'Schmerikon',
    location_type: 'city',
    id: 514,
  },
  {
    location: 'Widen',
    location_type: 'city',
    id: 515,
  },
  {
    location: 'Port',
    location_type: 'city',
    id: 516,
  },
  {
    location: 'Stallikon',
    location_type: 'city',
    id: 517,
  },
  {
    location: 'Egerkingen',
    location_type: 'city',
    id: 518,
  },
  {
    location: 'Beckenried',
    location_type: 'city',
    id: 519,
  },
  {
    location: 'Walchwil',
    location_type: 'city',
    id: 520,
  },
  {
    location: 'Elsau',
    location_type: 'city',
    id: 521,
  },
  {
    location: 'Giswil',
    location_type: 'city',
    id: 522,
  },
  {
    location: 'R\u00fcti',
    location_type: 'city',
    id: 523,
  },
  {
    location: 'Hausen am Albis',
    location_type: 'city',
    id: 524,
  },
  {
    location: 'Feuerthalen',
    location_type: 'city',
    id: 525,
  },
  {
    location: 'Hausen',
    location_type: 'city',
    id: 526,
  },
  {
    location: 'Tavannes',
    location_type: 'city',
    id: 527,
  },
  {
    location: 'Laufenburg',
    location_type: 'city',
    id: 528,
  },
  {
    location: 'Reichenbach im Kandertal',
    location_type: 'city',
    id: 529,
  },
  {
    location: 'Hittnau',
    location_type: 'city',
    id: 530,
  },
  {
    location: 'Stein',
    location_type: 'city',
    id: 531,
  },
  {
    location: 'Plaffeien',
    location_type: 'city',
    id: 532,
  },
  {
    location: 'Eschenbach',
    location_type: 'city',
    id: 533,
  },
  {
    location: 'M\u00f6rschwil',
    location_type: 'city',
    id: 534,
  },
  {
    location: 'B\u00fcren an der Aare',
    location_type: 'city',
    id: 535,
  },
  {
    location: 'Ins',
    location_type: 'city',
    id: 536,
  },
  {
    location: 'Erlinsbach',
    location_type: 'city',
    id: 537,
  },
  {
    location: 'Merenschwand',
    location_type: 'city',
    id: 538,
  },
  {
    location: 'Chalais',
    location_type: 'city',
    id: 539,
  },
  {
    location: 'Attalens',
    location_type: 'city',
    id: 540,
  },
  {
    location: 'Poschiavo',
    location_type: 'city',
    id: 541,
  },
  {
    location: 'Waldkirch',
    location_type: 'city',
    id: 542,
  },
  {
    location: 'Ennetbaden',
    location_type: 'city',
    id: 543,
  },
  {
    location: 'Klingnau',
    location_type: 'city',
    id: 544,
  },
  {
    location: 'Luterbach',
    location_type: 'city',
    id: 545,
  },
  {
    location: 'Gams',
    location_type: 'city',
    id: 546,
  },
  {
    location: 'Steinmaur',
    location_type: 'city',
    id: 547,
  },
  {
    location: 'Ch\u00e2teau-d\'Oex',
    location_type: 'city',
    id: 548,
  },
  {
    location: 'Ermatingen',
    location_type: 'city',
    id: 549,
  },
  {
    location: 'Selzach',
    location_type: 'city',
    id: 550,
  },
  {
    location: 'M\u00fcnsterlingen',
    location_type: 'city',
    id: 551,
  },
  {
    location: 'Grimisuat',
    location_type: 'city',
    id: 552,
  },
  {
    location: 'Zizers',
    location_type: 'city',
    id: 553,
  },
  {
    location: 'Yvonand',
    location_type: 'city',
    id: 554,
  },
  {
    location: 'Rheineck',
    location_type: 'city',
    id: 555,
  },
  {
    location: 'Steinen',
    location_type: 'city',
    id: 556,
  },
  {
    location: 'Berg',
    location_type: 'city',
    id: 557,
  },
  {
    location: 'B\u00f6singen',
    location_type: 'city',
    id: 558,
  },
  {
    location: 'Vacallo',
    location_type: 'city',
    id: 559,
  },
  {
    location: 'Thunstetten',
    location_type: 'city',
    id: 560,
  },
  {
    location: 'Corsier-sur-Vevey',
    location_type: 'city',
    id: 561,
  },
  {
    location: 'Stein am Rhein',
    location_type: 'city',
    id: 562,
  },
  {
    location: 'Adelboden',
    location_type: 'city',
    id: 563,
  },
  {
    location: 'Rickenbach',
    location_type: 'city',
    id: 564,
  },
  {
    location: 'Gr\u00fcningen',
    location_type: 'city',
    id: 565,
  },
  {
    location: 'Grandson',
    location_type: 'city',
    id: 566,
  },
  {
    location: 'Savigny',
    location_type: 'city',
    id: 567,
  },
  {
    location: 'Staufen',
    location_type: 'city',
    id: 568,
  },
  {
    location: 'Bonaduz',
    location_type: 'city',
    id: 569,
  },
  {
    location: 'Entlebuch',
    location_type: 'city',
    id: 570,
  },
  {
    location: 'Courroux',
    location_type: 'city',
    id: 571,
  },
  {
    location: 'Romanel-sur-Lausanne',
    location_type: 'city',
    id: 572,
  },
  {
    location: 'Tuggen',
    location_type: 'city',
    id: 573,
  },
  {
    location: 'Trimmis',
    location_type: 'city',
    id: 574,
  },
  {
    location: 'Weisslingen',
    location_type: 'city',
    id: 575,
  },
  {
    location: 'Bellevue',
    location_type: 'city',
    id: 576,
  },
  {
    location: 'Buttisholz',
    location_type: 'city',
    id: 577,
  },
  {
    location: 'Penthalaz',
    location_type: 'city',
    id: 578,
  },
  {
    location: 'Belfaux',
    location_type: 'city',
    id: 579,
  },
  {
    location: 'Madiswil',
    location_type: 'city',
    id: 580,
  },
  {
    location: 'Beinwil am See',
    location_type: 'city',
    id: 581,
  },
  {
    location: 'Balerna',
    location_type: 'city',
    id: 582,
  },
  {
    location: 'R\u00fcegsbach',
    location_type: 'city',
    id: 583,
  },
  {
    location: 'B\u00e4tterkinden',
    location_type: 'city',
    id: 584,
  },
  {
    location: 'Aubonne',
    location_type: 'city',
    id: 585,
  },
  {
    location: 'Grosswangen',
    location_type: 'city',
    id: 586,
  },
  {
    location: 'Kappel',
    location_type: 'city',
    id: 587,
  },
  {
    location: 'Thusis',
    location_type: 'city',
    id: 588,
  },
  {
    location: 'Ardon',
    location_type: 'city',
    id: 589,
  },
  {
    location: 'Saint-Blaise',
    location_type: 'city',
    id: 590,
  },
  {
    location: 'Leytron',
    location_type: 'city',
    id: 591,
  },
  {
    location: 'Subingen',
    location_type: 'city',
    id: 592,
  },
  {
    location: 'Stein',
    location_type: 'city',
    id: 593,
  },
  {
    location: 'Ursy',
    location_type: 'city',
    id: 594,
  },
  {
    location: 'Arosa',
    location_type: 'city',
    id: 595,
  },
  {
    location: 'Orsi\u00e8res',
    location_type: 'city',
    id: 596,
  },
  {
    location: 'Kirchlindach',
    location_type: 'city',
    id: 597,
  },
  {
    location: 'Hofstetten',
    location_type: 'city',
    id: 598,
  },
  {
    location: 'Le Mouret',
    location_type: 'city',
    id: 599,
  },
  {
    location: 'Coppet',
    location_type: 'city',
    id: 600,
  },
  {
    location: 'Niederhelfenschwil',
    location_type: 'city',
    id: 601,
  },
  {
    location: 'Hettlingen',
    location_type: 'city',
    id: 602,
  },
  {
    location: 'Neerach',
    location_type: 'city',
    id: 603,
  },
  {
    location: 'Laupen',
    location_type: 'city',
    id: 604,
  },
  {
    location: 'Oberdorf',
    location_type: 'city',
    id: 605,
  },
  {
    location: 'Seedorf',
    location_type: 'city',
    id: 606,
  },
  {
    location: 'Stettlen',
    location_type: 'city',
    id: 607,
  },
  {
    location: 'Lupfig',
    location_type: 'city',
    id: 608,
  },
  {
    location: 'Brienz',
    location_type: 'city',
    id: 609,
  },
  {
    location: 'Torricella',
    location_type: 'city',
    id: 610,
  },
  {
    location: 'Unterkulm',
    location_type: 'city',
    id: 611,
  },
  {
    location: 'Gais',
    location_type: 'city',
    id: 612,
  },
  {
    location: 'Roggwil',
    location_type: 'city',
    id: 613,
  },
  {
    location: 'Echichens',
    location_type: 'city',
    id: 614,
  },
  {
    location: 'Benken',
    location_type: 'city',
    id: 615,
  },
  {
    location: 'Niederweningen',
    location_type: 'city',
    id: 616,
  },
  {
    location: 'Zweisimmen',
    location_type: 'city',
    id: 617,
  },
  {
    location: 'Grossaffoltern',
    location_type: 'city',
    id: 618,
  },
  {
    location: 'Turgi',
    location_type: 'city',
    id: 619,
  },
  {
    location: 'Schafisheim',
    location_type: 'city',
    id: 620,
  },
  {
    location: 'Courrendlin',
    location_type: 'city',
    id: 621,
  },
  {
    location: 'Birmenstorf',
    location_type: 'city',
    id: 622,
  },
  {
    location: 'M\u00fcllheim',
    location_type: 'city',
    id: 623,
  },
  {
    location: 'M\u00fchleberg',
    location_type: 'city',
    id: 624,
  },
  {
    location: 'Murgenthal',
    location_type: 'city',
    id: 625,
  },
  {
    location: 'Meisterschwanden',
    location_type: 'city',
    id: 626,
  },
  {
    location: 'Chardonne',
    location_type: 'city',
    id: 627,
  },
  {
    location: 'Geuensee',
    location_type: 'city',
    id: 628,
  },
  {
    location: 'Coldrerio',
    location_type: 'city',
    id: 629,
  },
  {
    location: 'Schenkon',
    location_type: 'city',
    id: 630,
  },
  {
    location: 'Quarten',
    location_type: 'city',
    id: 631,
  },
  {
    location: 'Wattenwil',
    location_type: 'city',
    id: 632,
  },
  {
    location: 'Oberburg',
    location_type: 'city',
    id: 633,
  },
  {
    location: 'Waltenschwil',
    location_type: 'city',
    id: 634,
  },
  {
    location: 'Cadenazzo',
    location_type: 'city',
    id: 635,
  },
  {
    location: 'Samedan',
    location_type: 'city',
    id: 636,
  },
  {
    location: 'Matzingen',
    location_type: 'city',
    id: 637,
  },
  {
    location: 'Faido',
    location_type: 'city',
    id: 638,
  },
  {
    location: 'Sarmenstorf',
    location_type: 'city',
    id: 639,
  },
  {
    location: 'Etoy',
    location_type: 'city',
    id: 640,
  },
  {
    location: 'M\u00e4rstetten',
    location_type: 'city',
    id: 641,
  },
  {
    location: 'Othmarsingen',
    location_type: 'city',
    id: 642,
  },
  {
    location: 'Mosnang',
    location_type: 'city',
    id: 643,
  },
  {
    location: 'Menznau',
    location_type: 'city',
    id: 644,
  },
  {
    location: 'Puidoux',
    location_type: 'city',
    id: 645,
  },
  {
    location: 'Commugny',
    location_type: 'city',
    id: 646,
  },
  {
    location: 'T\u00e4uffelen',
    location_type: 'city',
    id: 647,
  },
  {
    location: 'D\u00e4niken',
    location_type: 'city',
    id: 648,
  },
  {
    location: 'Rorbas',
    location_type: 'city',
    id: 649,
  },
  {
    location: 'Boswil',
    location_type: 'city',
    id: 650,
  },
  {
    location: 'Flims',
    location_type: 'city',
    id: 651,
  },
  {
    location: 'Otelfingen',
    location_type: 'city',
    id: 652,
  },
  {
    location: 'Niederwil',
    location_type: 'city',
    id: 653,
  },
  {
    location: 'Bergdietikon',
    location_type: 'city',
    id: 654,
  },
  {
    location: 'Ennenda',
    location_type: 'city',
    id: 655,
  },
  {
    location: 'Orpund',
    location_type: 'city',
    id: 656,
  },
  {
    location: 'Fischingen',
    location_type: 'city',
    id: 657,
  },
  {
    location: 'Ballwil',
    location_type: 'city',
    id: 658,
  },
  {
    location: 'Gretzenbach',
    location_type: 'city',
    id: 659,
  },
  {
    location: 'Genthod',
    location_type: 'city',
    id: 660,
  },
  {
    location: 'Riaz',
    location_type: 'city',
    id: 661,
  },
  {
    location: 'Rickenbach bei Wil',
    location_type: 'city',
    id: 662,
  },
  {
    location: 'Rain',
    location_type: 'city',
    id: 663,
  },
  {
    location: 'Horn',
    location_type: 'city',
    id: 664,
  },
  {
    location: 'Ettiswil',
    location_type: 'city',
    id: 665,
  },
  {
    location: 'Lengnau',
    location_type: 'city',
    id: 666,
  },
  {
    location: 'Cugy',
    location_type: 'city',
    id: 667,
  },
  {
    location: 'Lauterbrunnen',
    location_type: 'city',
    id: 668,
  },
  {
    location: 'Vionnaz',
    location_type: 'city',
    id: 669,
  },
  {
    location: 'H\u00f6ri',
    location_type: 'city',
    id: 670,
  },
  {
    location: 'Oberkulm',
    location_type: 'city',
    id: 671,
  },
  {
    location: 'Corminboeuf',
    location_type: 'city',
    id: 672,
  },
  {
    location: 'Muralto',
    location_type: 'city',
    id: 673,
  },
  {
    location: 'Montanaire',
    location_type: 'city',
    id: 674,
  },
  {
    location: 'Schiers',
    location_type: 'city',
    id: 675,
  },
  {
    location: 'Pfaffnau',
    location_type: 'city',
    id: 676,
  },
  {
    location: 'Evilard',
    location_type: 'city',
    id: 677,
  },
  {
    location: 'Kaisten',
    location_type: 'city',
    id: 678,
  },
  {
    location: 'Signau',
    location_type: 'city',
    id: 679,
  },
  {
    location: 'Rickenbach',
    location_type: 'city',
    id: 680,
  },
  {
    location: 'Wilderswil',
    location_type: 'city',
    id: 681,
  },
  {
    location: 'Mont-sur-Rolle',
    location_type: 'city',
    id: 682,
  },
  {
    location: 'Broc',
    location_type: 'city',
    id: 683,
  },
  {
    location: 'Riva San Vitale',
    location_type: 'city',
    id: 684,
  },
  {
    location: 'Lenzerheide',
    location_type: 'city',
    id: 685,
  },
  {
    location: 'Nebikon',
    location_type: 'city',
    id: 686,
  },
  {
    location: 'Bioggio',
    location_type: 'city',
    id: 687,
  },
  {
    location: 'Hauterive',
    location_type: 'city',
    id: 688,
  },
  {
    location: 'Felsberg',
    location_type: 'city',
    id: 689,
  },
  {
    location: 'Saillon',
    location_type: 'city',
    id: 690,
  },
  {
    location: 'Rapperswil',
    location_type: 'city',
    id: 691,
  },
  {
    location: 'Lauperswil',
    location_type: 'city',
    id: 692,
  },
  {
    location: 'Court\u00e9telle',
    location_type: 'city',
    id: 693,
  },
  {
    location: 'Saignel\u00e9gier',
    location_type: 'city',
    id: 694,
  },
  {
    location: 'La Sarraz',
    location_type: 'city',
    id: 695,
  },
  {
    location: 'Lonay',
    location_type: 'city',
    id: 696,
  },
  {
    location: 'Maggia',
    location_type: 'city',
    id: 697,
  },
  {
    location: 'Buchegg',
    location_type: 'city',
    id: 698,
  },
  {
    location: 'Froideville',
    location_type: 'city',
    id: 699,
  },
  {
    location: 'Saint-Cergue',
    location_type: 'city',
    id: 700,
  },
  {
    location: 'Endingen',
    location_type: 'city',
    id: 701,
  },
  {
    location: 'Neyruz',
    location_type: 'city',
    id: 702,
  },
  {
    location: 'Lotzwil',
    location_type: 'city',
    id: 703,
  },
  {
    location: 'Ringgenberg',
    location_type: 'city',
    id: 704,
  },
  {
    location: 'Zunzgen',
    location_type: 'city',
    id: 705,
  },
  {
    location: 'Inwil',
    location_type: 'city',
    id: 706,
  },
  {
    location: 'Ottenbach',
    location_type: 'city',
    id: 707,
  },
  {
    location: 'Riggisberg',
    location_type: 'city',
    id: 708,
  },
  {
    location: 'Affeltrangen',
    location_type: 'city',
    id: 709,
  },
  {
    location: 'Kemmen',
    location_type: 'city',
    id: 710,
  },
  {
    location: 'B\u00fcron',
    location_type: 'city',
    id: 711,
  },
  {
    location: 'Wimmis',
    location_type: 'city',
    id: 712,
  },
  {
    location: 'B\u00f6nigen',
    location_type: 'city',
    id: 713,
  },
  {
    location: 'H\u00f6lstein',
    location_type: 'city',
    id: 714,
  },
  {
    location: 'Sant\u2019 Antonino',
    location_type: 'city',
    id: 715,
  },
  {
    location: 'Roveredo',
    location_type: 'city',
    id: 716,
  },
  {
    location: 'Wilen',
    location_type: 'city',
    id: 717,
  },
  {
    location: 'Untervaz',
    location_type: 'city',
    id: 718,
  },
  {
    location: 'Toffen',
    location_type: 'city',
    id: 719,
  },
  {
    location: 'Fischenthal',
    location_type: 'city',
    id: 720,
  },
  {
    location: 'Troinex',
    location_type: 'city',
    id: 721,
  },
  {
    location: 'Vand\u0153uvres',
    location_type: 'city',
    id: 722,
  },
  {
    location: 'Corcelles-pr\u00e8s-Payerne',
    location_type: 'city',
    id: 723,
  },
  {
    location: 'Hohenrain',
    location_type: 'city',
    id: 724,
  },
  {
    location: 'Puplinge',
    location_type: 'city',
    id: 725,
  },
  {
    location: 'Oetwil an der Limmat',
    location_type: 'city',
    id: 726,
  },
  {
    location: 'Meikirch',
    location_type: 'city',
    id: 727,
  },
  {
    location: 'Wigoltingen',
    location_type: 'city',
    id: 728,
  },
  {
    location: 'Saint-L\u00e9onard',
    location_type: 'city',
    id: 729,
  },
  {
    location: 'Eggiwil',
    location_type: 'city',
    id: 730,
  },
  {
    location: 'Oberdorf',
    location_type: 'city',
    id: 731,
  },
  {
    location: 'Thierachern',
    location_type: 'city',
    id: 732,
  },
  {
    location: 'H\u00e4gglingen',
    location_type: 'city',
    id: 733,
  },
  {
    location: 'Gr\u00f4ne',
    location_type: 'city',
    id: 734,
  },
  {
    location: 'Rothenthurm',
    location_type: 'city',
    id: 735,
  },
  {
    location: 'Vuadens',
    location_type: 'city',
    id: 736,
  },
  {
    location: 'Ani\u00e8res',
    location_type: 'city',
    id: 737,
  },
  {
    location: 'Zwingen',
    location_type: 'city',
    id: 738,
  },
  {
    location: 'Saint-Aubin-Sauges',
    location_type: 'city',
    id: 739,
  },
  {
    location: 'R\u00fcthi',
    location_type: 'city',
    id: 740,
  },
  {
    location: 'Unteriberg',
    location_type: 'city',
    id: 741,
  },
  {
    location: 'R\u00fcderswil',
    location_type: 'city',
    id: 742,
  },
  {
    location: 'Novazzano',
    location_type: 'city',
    id: 743,
  },
  {
    location: 'Malans',
    location_type: 'city',
    id: 744,
  },
  {
    location: 'Wiedlisbach',
    location_type: 'city',
    id: 745,
  },
  {
    location: 'Freienstein',
    location_type: 'city',
    id: 746,
  },
  {
    location: 'Ueberstorf',
    location_type: 'city',
    id: 747,
  },
  {
    location: 'Lufingen',
    location_type: 'city',
    id: 748,
  },
  {
    location: 'Krauchthal',
    location_type: 'city',
    id: 749,
  },
  {
    location: 'Pfeffingen',
    location_type: 'city',
    id: 750,
  },
  {
    location: 'Hildisrieden',
    location_type: 'city',
    id: 751,
  },
  {
    location: 'Gersau',
    location_type: 'city',
    id: 752,
  },
  {
    location: 'Courgenay',
    location_type: 'city',
    id: 753,
  },
  {
    location: 'Vuisternens-devant-Romont',
    location_type: 'city',
    id: 754,
  },
  {
    location: 'Reconvilier',
    location_type: 'city',
    id: 755,
  },
  {
    location: 'Urn\u00e4sch',
    location_type: 'city',
    id: 756,
  },
  {
    location: 'Martigny-Combe',
    location_type: 'city',
    id: 757,
  },
  {
    location: 'Wangen an der Aare',
    location_type: 'city',
    id: 758,
  },
  {
    location: 'Riedholz',
    location_type: 'city',
    id: 759,
  },
  {
    location: 'Zeiningen',
    location_type: 'city',
    id: 760,
  },
  {
    location: 'Knonau',
    location_type: 'city',
    id: 761,
  },
  {
    location: 'Bardonnex',
    location_type: 'city',
    id: 762,
  },
  {
    location: 'B\u00fcsserach',
    location_type: 'city',
    id: 763,
  },
  {
    location: 'Oberbuchsiten',
    location_type: 'city',
    id: 764,
  },
  {
    location: 'Eiken',
    location_type: 'city',
    id: 765,
  },
  {
    location: 'Neunkirch',
    location_type: 'city',
    id: 766,
  },
  {
    location: 'Worben',
    location_type: 'city',
    id: 767,
  },
  {
    location: 'Corseaux',
    location_type: 'city',
    id: 768,
  },
  {
    location: 'Udligenswil',
    location_type: 'city',
    id: 769,
  },
  {
    location: 'Wolfwil',
    location_type: 'city',
    id: 770,
  },
  {
    location: 'Stadel bei Niederglatt',
    location_type: 'city',
    id: 771,
  },
  {
    location: 'Henggart',
    location_type: 'city',
    id: 772,
  },
  {
    location: 'Neuheim',
    location_type: 'city',
    id: 773,
  },
  {
    location: 'Schinznach Dorf',
    location_type: 'city',
    id: 774,
  },
  {
    location: 'Canobbio',
    location_type: 'city',
    id: 775,
  },
  {
    location: 'Diemtigen',
    location_type: 'city',
    id: 776,
  },
  {
    location: 'Wauwil',
    location_type: 'city',
    id: 777,
  },
  {
    location: 'Sankt Niklaus',
    location_type: 'city',
    id: 778,
  },
  {
    location: 'Chexbres',
    location_type: 'city',
    id: 779,
  },
  {
    location: 'Gimel',
    location_type: 'city',
    id: 780,
  },
  {
    location: 'Siviriez',
    location_type: 'city',
    id: 781,
  },
  {
    location: 'Neuendorf',
    location_type: 'city',
    id: 782,
  },
  {
    location: 'Knutwil',
    location_type: 'city',
    id: 783,
  },
  {
    location: 'Dintikon',
    location_type: 'city',
    id: 784,
  },
  {
    location: 'Bottighofen',
    location_type: 'city',
    id: 785,
  },
  {
    location: 'Cazis',
    location_type: 'city',
    id: 786,
  },
  {
    location: 'Hallau',
    location_type: 'city',
    id: 787,
  },
  {
    location: 'Ormalingen',
    location_type: 'city',
    id: 788,
  },
  {
    location: 'Savosa',
    location_type: 'city',
    id: 789,
  },
  {
    location: 'Altnau',
    location_type: 'city',
    id: 790,
  },
  {
    location: 'Deitingen',
    location_type: 'city',
    id: 791,
  },
  {
    location: 'Oberg\u00f6sgen',
    location_type: 'city',
    id: 792,
  },
  {
    location: 'Stetten',
    location_type: 'city',
    id: 793,
  },
  {
    location: 'Andelfingen',
    location_type: 'city',
    id: 794,
  },
  {
    location: 'Gruy\u00e8res',
    location_type: 'city',
    id: 795,
  },
  {
    location: 'Ennetmoos',
    location_type: 'city',
    id: 796,
  },
  {
    location: 'Gontenschwil',
    location_type: 'city',
    id: 797,
  },
  {
    location: 'Leuggern',
    location_type: 'city',
    id: 798,
  },
  {
    location: 'Pontresina',
    location_type: 'city',
    id: 799,
  },
  {
    location: 'Castel San Pietro',
    location_type: 'city',
    id: 800,
  },
  {
    location: 'Aegerten',
    location_type: 'city',
    id: 801,
  },
  {
    location: 'Itingen',
    location_type: 'city',
    id: 802,
  },
  {
    location: 'Auw',
    location_type: 'city',
    id: 803,
  },
  {
    location: 'Seftigen',
    location_type: 'city',
    id: 804,
  },
  {
    location: 'Fahrwangen',
    location_type: 'city',
    id: 805,
  },
  {
    location: 'Meinier',
    location_type: 'city',
    id: 806,
  },
  {
    location: 'Villigen',
    location_type: 'city',
    id: 807,
  },
  {
    location: 'Werthenstein',
    location_type: 'city',
    id: 808,
  },
  {
    location: 'Lungern',
    location_type: 'city',
    id: 809,
  },
  {
    location: 'M\u00e4genwil',
    location_type: 'city',
    id: 810,
  },
  {
    location: 'Corsier',
    location_type: 'city',
    id: 811,
  },
  {
    location: 'Jonen',
    location_type: 'city',
    id: 812,
  },
  {
    location: 'Marbach',
    location_type: 'city',
    id: 813,
  },
  {
    location: 'Wolfenschiessen',
    location_type: 'city',
    id: 814,
  },
  {
    location: 'Koppigen',
    location_type: 'city',
    id: 815,
  },
  {
    location: 'Mies',
    location_type: 'city',
    id: 816,
  },
  {
    location: 'Forel',
    location_type: 'city',
    id: 817,
  },
  {
    location: 'Kleinandelfingen',
    location_type: 'city',
    id: 818,
  },
  {
    location: 'Sankt Antoni',
    location_type: 'city',
    id: 819,
  },
  {
    location: 'Gr\u00fcsch',
    location_type: 'city',
    id: 820,
  },
  {
    location: 'Killwangen',
    location_type: 'city',
    id: 821,
  },
  {
    location: 'Ersigen',
    location_type: 'city',
    id: 822,
  },
  {
    location: 'Wynigen',
    location_type: 'city',
    id: 823,
  },
  {
    location: 'Oberlunkhofen',
    location_type: 'city',
    id: 824,
  },
  {
    location: 'Zell',
    location_type: 'city',
    id: 825,
  },
  {
    location: 'Comano',
    location_type: 'city',
    id: 826,
  },
  {
    location: 'Alterswil',
    location_type: 'city',
    id: 827,
  },
  {
    location: 'Gorgier',
    location_type: 'city',
    id: 828,
  },
  {
    location: 'Voll\u00e8ges',
    location_type: 'city',
    id: 829,
  },
  {
    location: 'Br\u00fctten',
    location_type: 'city',
    id: 830,
  },
  {
    location: 'Pfyn',
    location_type: 'city',
    id: 831,
  },
  {
    location: 'Andwil',
    location_type: 'city',
    id: 832,
  },
  {
    location: 'Recherswil',
    location_type: 'city',
    id: 833,
  },
  {
    location: 'Remetschwil',
    location_type: 'city',
    id: 834,
  },
  {
    location: 'Silenen',
    location_type: 'city',
    id: 835,
  },
  {
    location: 'Aeugst am Albis',
    location_type: 'city',
    id: 836,
  },
  {
    location: 'Fl\u00fceln',
    location_type: 'city',
    id: 837,
  },
  {
    location: 'Walzenhausen',
    location_type: 'city',
    id: 838,
  },
  {
    location: 'Wila',
    location_type: 'city',
    id: 839,
  },
  {
    location: 'Barbengo',
    location_type: 'city',
    id: 840,
  },
  {
    location: 'Val-d\u2019Illiez',
    location_type: 'city',
    id: 841,
  },
  {
    location: 'Fl\u00fchli',
    location_type: 'city',
    id: 842,
  },
  {
    location: 'Vordemwald',
    location_type: 'city',
    id: 843,
  },
  {
    location: 'Begnins',
    location_type: 'city',
    id: 844,
  },
  {
    location: 'Marthalen',
    location_type: 'city',
    id: 845,
  },
  {
    location: 'Churwalden',
    location_type: 'city',
    id: 846,
  },
  {
    location: 'Servion',
    location_type: 'city',
    id: 847,
  },
  {
    location: 'Raron',
    location_type: 'city',
    id: 848,
  },
  {
    location: 'Sattel',
    location_type: 'city',
    id: 849,
  },
  {
    location: 'Genolier',
    location_type: 'city',
    id: 850,
  },
  {
    location: 'Tolochenaz',
    location_type: 'city',
    id: 851,
  },
  {
    location: 'Dachsen',
    location_type: 'city',
    id: 852,
  },
  {
    location: 'Hochfelden',
    location_type: 'city',
    id: 853,
  },
  {
    location: 'Avry-sur-Matran',
    location_type: 'city',
    id: 854,
  },
  {
    location: 'Grolley',
    location_type: 'city',
    id: 855,
  },
  {
    location: 'Winznau',
    location_type: 'city',
    id: 856,
  },
  {
    location: 'Kallnach',
    location_type: 'city',
    id: 857,
  },
  {
    location: 'Oberegg',
    location_type: 'city',
    id: 858,
  },
  {
    location: 'Vezia',
    location_type: 'city',
    id: 859,
  },
  {
    location: 'Marsens',
    location_type: 'city',
    id: 860,
  },
  {
    location: 'Ponte Capriasca',
    location_type: 'city',
    id: 861,
  },
  {
    location: 'Malleray',
    location_type: 'city',
    id: 862,
  },
  {
    location: 'Cressier',
    location_type: 'city',
    id: 863,
  },
  {
    location: 'Vernayaz',
    location_type: 'city',
    id: 864,
  },
  {
    location: 'Nunningen',
    location_type: 'city',
    id: 865,
  },
  {
    location: 'Lichtensteig',
    location_type: 'city',
    id: 866,
  },
  {
    location: 'D\u00e4nikon',
    location_type: 'city',
    id: 867,
  },
  {
    location: 'Waldstatt',
    location_type: 'city',
    id: 868,
  },
  {
    location: 'Dardagny',
    location_type: 'city',
    id: 869,
  },
  {
    location: 'R\u00f6schenz',
    location_type: 'city',
    id: 870,
  },
  {
    location: 'Seedorf',
    location_type: 'city',
    id: 871,
  },
  {
    location: 'Uttwil',
    location_type: 'city',
    id: 872,
  },
  {
    location: 'Laax',
    location_type: 'city',
    id: 873,
  },
  {
    location: 'Charrat',
    location_type: 'city',
    id: 874,
  },
  {
    location: 'Alle',
    location_type: 'city',
    id: 875,
  },
  {
    location: 'Le Noirmont',
    location_type: 'city',
    id: 876,
  },
  {
    location: 'Grellingen',
    location_type: 'city',
    id: 877,
  },
  {
    location: 'Dallenwil',
    location_type: 'city',
    id: 878,
  },
  {
    location: 'Kirchdorf',
    location_type: 'city',
    id: 879,
  },
  {
    location: 'Sch\u00f6nenberg',
    location_type: 'city',
    id: 880,
  },
  {
    location: 'Arni',
    location_type: 'city',
    id: 881,
  },
  {
    location: 'Wolfhalden',
    location_type: 'city',
    id: 882,
  },
  {
    location: 'Veyras',
    location_type: 'city',
    id: 883,
  },
  {
    location: 'Pont-en-Ogoz',
    location_type: 'city',
    id: 884,
  },
  {
    location: 'Laupersdorf',
    location_type: 'city',
    id: 885,
  },
  {
    location: 'Biglen',
    location_type: 'city',
    id: 886,
  },
  {
    location: 'Vex',
    location_type: 'city',
    id: 887,
  },
  {
    location: 'Acquarossa',
    location_type: 'city',
    id: 888,
  },
  {
    location: 'Kestenholz',
    location_type: 'city',
    id: 889,
  },
  {
    location: 'Melide',
    location_type: 'city',
    id: 890,
  },
  {
    location: 'Saint-Aubin',
    location_type: 'city',
    id: 891,
  },
  {
    location: 'B\u00fchler',
    location_type: 'city',
    id: 892,
  },
  {
    location: 'Oberweningen',
    location_type: 'city',
    id: 893,
  },
  {
    location: 'Niederdorf',
    location_type: 'city',
    id: 894,
  },
  {
    location: 'R\u00fceggisberg',
    location_type: 'city',
    id: 895,
  },
  {
    location: 'Massongex',
    location_type: 'city',
    id: 896,
  },
  {
    location: 'Amden',
    location_type: 'city',
    id: 897,
  },
  {
    location: 'Fulenbach',
    location_type: 'city',
    id: 898,
  },
  {
    location: 'Sorengo',
    location_type: 'city',
    id: 899,
  },
  {
    location: 'Brissago',
    location_type: 'city',
    id: 900,
  },
  {
    location: 'R\u00f6merswil',
    location_type: 'city',
    id: 901,
  },
  {
    location: 'Oberbipp',
    location_type: 'city',
    id: 902,
  },
  {
    location: 'Walkringen',
    location_type: 'city',
    id: 903,
  },
  {
    location: 'Avully',
    location_type: 'city',
    id: 904,
  },
  {
    location: 'Cugy',
    location_type: 'city',
    id: 905,
  },
  {
    location: 'Weiach',
    location_type: 'city',
    id: 906,
  },
  {
    location: 'Penthaz',
    location_type: 'city',
    id: 907,
  },
  {
    location: 'K\u00fcnten',
    location_type: 'city',
    id: 908,
  },
  {
    location: 'Rehetobel',
    location_type: 'city',
    id: 909,
  },
  {
    location: 'Erlenbach im Simmental',
    location_type: 'city',
    id: 910,
  },
  {
    location: 'Eschenz',
    location_type: 'city',
    id: 911,
  },
  {
    location: 'Hasle',
    location_type: 'city',
    id: 912,
  },
  {
    location: 'Lamone',
    location_type: 'city',
    id: 913,
  },
  {
    location: 'Wilchingen',
    location_type: 'city',
    id: 914,
  },
  {
    location: 'Trogen',
    location_type: 'city',
    id: 915,
  },
  {
    location: 'Lengwil',
    location_type: 'city',
    id: 916,
  },
  {
    location: 'H\u00fcttwilen',
    location_type: 'city',
    id: 917,
  },
  {
    location: 'Attinghausen',
    location_type: 'city',
    id: 918,
  },
  {
    location: 'Weesen',
    location_type: 'city',
    id: 919,
  },
  {
    location: 'Oberdorf',
    location_type: 'city',
    id: 920,
  },
  {
    location: 'Starrkirch',
    location_type: 'city',
    id: 921,
  },
  {
    location: 'La Roche',
    location_type: 'city',
    id: 922,
  },
  {
    location: 'Schwarzenberg',
    location_type: 'city',
    id: 923,
  },
  {
    location: 'Corg\u00e9mont',
    location_type: 'city',
    id: 924,
  },
  {
    location: 'Wagenhausen',
    location_type: 'city',
    id: 925,
  },
  {
    location: 'Bellmund',
    location_type: 'city',
    id: 926,
  },
  {
    location: 'Chancy',
    location_type: 'city',
    id: 927,
  },
  {
    location: 'Fontenais',
    location_type: 'city',
    id: 928,
  },
  {
    location: 'Evol\u00e8ne',
    location_type: 'city',
    id: 929,
  },
  {
    location: 'Schleitheim',
    location_type: 'city',
    id: 930,
  },
  {
    location: 'Teufenthal',
    location_type: 'city',
    id: 931,
  },
  {
    location: 'Nieder\u00f6nz',
    location_type: 'city',
    id: 932,
  },
  {
    location: 'Langwiesen',
    location_type: 'city',
    id: 933,
  },
  {
    location: 'R\u00fcschegg',
    location_type: 'city',
    id: 934,
  },
  {
    location: 'Koblenz',
    location_type: 'city',
    id: 935,
  },
  {
    location: 'Diegten',
    location_type: 'city',
    id: 936,
  },
  {
    location: 'Gunzgen',
    location_type: 'city',
    id: 937,
  },
  {
    location: 'Brislach',
    location_type: 'city',
    id: 938,
  },
  {
    location: 'Roche',
    location_type: 'city',
    id: 939,
  },
  {
    location: 'H\u00e4rkingen',
    location_type: 'city',
    id: 940,
  },
  {
    location: 'Arisdorf',
    location_type: 'city',
    id: 941,
  },
  {
    location: 'Giffers',
    location_type: 'city',
    id: 942,
  },
  {
    location: 'Villnachern',
    location_type: 'city',
    id: 943,
  },
  {
    location: 'Cudrefin',
    location_type: 'city',
    id: 944,
  },
  {
    location: 'Wynau',
    location_type: 'city',
    id: 945,
  },
  {
    location: 'Eich',
    location_type: 'city',
    id: 946,
  },
  {
    location: 'Magliaso',
    location_type: 'city',
    id: 947,
  },
  {
    location: 'Chippis',
    location_type: 'city',
    id: 948,
  },
  {
    location: 'Sisseln',
    location_type: 'city',
    id: 949,
  },
  {
    location: 'Denges',
    location_type: 'city',
    id: 950,
  },
  {
    location: 'Eysins',
    location_type: 'city',
    id: 951,
  },
  {
    location: 'Saas-Fee',
    location_type: 'city',
    id: 952,
  },
  {
    location: 'Z\u00e4ziwil',
    location_type: 'city',
    id: 953,
  },
  {
    location: 'Arch',
    location_type: 'city',
    id: 954,
  },
  {
    location: 'G\u00fcttingen',
    location_type: 'city',
    id: 955,
  },
  {
    location: 'Hirschthal',
    location_type: 'city',
    id: 956,
  },
  {
    location: 'Bi\u00e8re',
    location_type: 'city',
    id: 957,
  },
  {
    location: 'Ziefen',
    location_type: 'city',
    id: 958,
  },
  {
    location: 'Berg',
    location_type: 'city',
    id: 959,
  },
  {
    location: 'Reigoldswil',
    location_type: 'city',
    id: 960,
  },
  {
    location: 'Luzein',
    location_type: 'city',
    id: 961,
  },
  {
    location: 'Auenstein',
    location_type: 'city',
    id: 962,
  },
  {
    location: 'Heimiswil',
    location_type: 'city',
    id: 963,
  },
  {
    location: 'Porza',
    location_type: 'city',
    id: 964,
  },
  {
    location: 'Altishofen',
    location_type: 'city',
    id: 965,
  },
  {
    location: 'L\u00fctisburg',
    location_type: 'city',
    id: 966,
  },
  {
    location: 'Tannay',
    location_type: 'city',
    id: 967,
  },
  {
    location: 'Schwellbrunn',
    location_type: 'city',
    id: 968,
  },
  {
    location: 'Biberstein',
    location_type: 'city',
    id: 969,
  },
  {
    location: 'Ossingen',
    location_type: 'city',
    id: 970,
  },
  {
    location: 'Wohlenschwil',
    location_type: 'city',
    id: 971,
  },
  {
    location: 'Pf\u00e4fers',
    location_type: 'city',
    id: 972,
  },
  {
    location: 'Cornaux',
    location_type: 'city',
    id: 973,
  },
  {
    location: 'Bellikon',
    location_type: 'city',
    id: 974,
  },
  {
    location: 'Seeberg',
    location_type: 'city',
    id: 975,
  },
  {
    location: 'Auvernier',
    location_type: 'city',
    id: 976,
  },
  {
    location: 'Bedano',
    location_type: 'city',
    id: 977,
  },
  {
    location: 'Dinhard',
    location_type: 'city',
    id: 978,
  },
  {
    location: 'Salgesch',
    location_type: 'city',
    id: 979,
  },
  {
    location: 'Herznach',
    location_type: 'city',
    id: 980,
  },
  {
    location: 'Homburg',
    location_type: 'city',
    id: 981,
  },
  {
    location: 'Duggingen',
    location_type: 'city',
    id: 982,
  },
  {
    location: 'Perroy',
    location_type: 'city',
    id: 983,
  },
  {
    location: 'Lommiswil',
    location_type: 'city',
    id: 984,
  },
  {
    location: 'Jongny',
    location_type: 'city',
    id: 985,
  },
  {
    location: 'Oberr\u00fcti',
    location_type: 'city',
    id: 986,
  },
  {
    location: 'Cadempino',
    location_type: 'city',
    id: 987,
  },
  {
    location: 'Wikon',
    location_type: 'city',
    id: 988,
  },
  {
    location: 'Airolo',
    location_type: 'city',
    id: 989,
  },
  {
    location: 'Zernez',
    location_type: 'city',
    id: 990,
  },
  {
    location: 'Les Breuleux',
    location_type: 'city',
    id: 991,
  },
  {
    location: 'Rue',
    location_type: 'city',
    id: 992,
  },
  {
    location: 'Buchholterberg',
    location_type: 'city',
    id: 993,
  },
  {
    location: 'Eichberg',
    location_type: 'city',
    id: 994,
  },
  {
    location: 'Matran',
    location_type: 'city',
    id: 995,
  },
  {
    location: 'Melchnau',
    location_type: 'city',
    id: 996,
  },
  {
    location: 'Niederb\u00fcren',
    location_type: 'city',
    id: 997,
  },
  {
    location: 'Lumino',
    location_type: 'city',
    id: 998,
  },
  {
    location: 'T\u00e4gerig',
    location_type: 'city',
    id: 999,
  },
  {
    location: 'Cottens',
    location_type: 'city',
    id: 1000,
  },
  {
    location: 'Guggisberg',
    location_type: 'city',
    id: 1001,
  },
  {
    location: 'Bossonnens',
    location_type: 'city',
    id: 1002,
  },
  {
    location: 'Paudex',
    location_type: 'city',
    id: 1003,
  },
  {
    location: 'Attiswil',
    location_type: 'city',
    id: 1004,
  },
  {
    location: 'Aristau',
    location_type: 'city',
    id: 1005,
  },
  {
    location: 'Rh\u00e4z\u00fcns',
    location_type: 'city',
    id: 1006,
  },
  {
    location: 'Rohrbach',
    location_type: 'city',
    id: 1007,
  },
  {
    location: 'Messen',
    location_type: 'city',
    id: 1008,
  },
  {
    location: 'L\u2019Abbaye',
    location_type: 'city',
    id: 1009,
  },
  {
    location: 'Les Montets',
    location_type: 'city',
    id: 1010,
  },
  {
    location: 'L\u00f6hningen',
    location_type: 'city',
    id: 1011,
  },
  {
    location: 'Treyvaux',
    location_type: 'city',
    id: 1012,
  },
  {
    location: 'Ramsen',
    location_type: 'city',
    id: 1013,
  },
  {
    location: 'Apples',
    location_type: 'city',
    id: 1014,
  },
  {
    location: 'Mumpf',
    location_type: 'city',
    id: 1015,
  },
  {
    location: 'Veltheim',
    location_type: 'city',
    id: 1016,
  },
  {
    location: 'Wahlen',
    location_type: 'city',
    id: 1017,
  },
  {
    location: 'Schneisingen',
    location_type: 'city',
    id: 1018,
  },
  {
    location: 'R\u00fcttenen',
    location_type: 'city',
    id: 1019,
  },
  {
    location: 'T\u00fcbach',
    location_type: 'city',
    id: 1020,
  },
  {
    location: 'Meierskappel',
    location_type: 'city',
    id: 1021,
  },
  {
    location: 'Mauensee',
    location_type: 'city',
    id: 1022,
  },
  {
    location: 'Origlio',
    location_type: 'city',
    id: 1023,
  },
  {
    location: 'Salmsach',
    location_type: 'city',
    id: 1024,
  },
  {
    location: 'Dotzigen',
    location_type: 'city',
    id: 1025,
  },
  {
    location: 'Egliswil',
    location_type: 'city',
    id: 1026,
  },
  {
    location: 'Gonten',
    location_type: 'city',
    id: 1027,
  },
  {
    location: 'Holziken',
    location_type: 'city',
    id: 1028,
  },
  {
    location: 'Riniken',
    location_type: 'city',
    id: 1029,
  },
  {
    location: 'Lyssach',
    location_type: 'city',
    id: 1030,
  },
  {
    location: 'Melano',
    location_type: 'city',
    id: 1031,
  },
  {
    location: 'Witterswil',
    location_type: 'city',
    id: 1032,
  },
  {
    location: 'Flurlingen',
    location_type: 'city',
    id: 1033,
  },
  {
    location: 'Thundorf',
    location_type: 'city',
    id: 1034,
  },
  {
    location: 'Courtelary',
    location_type: 'city',
    id: 1035,
  },
  {
    location: 'Lupsingen',
    location_type: 'city',
    id: 1036,
  },
  {
    location: 'Yens',
    location_type: 'city',
    id: 1037,
  },
  {
    location: 'Emmetten',
    location_type: 'city',
    id: 1038,
  },
  {
    location: 'Trin',
    location_type: 'city',
    id: 1039,
  },
  {
    location: 'Boniswil',
    location_type: 'city',
    id: 1040,
  },
  {
    location: 'Court',
    location_type: 'city',
    id: 1041,
  },
  {
    location: 'Seegr\u00e4ben',
    location_type: 'city',
    id: 1042,
  },
  {
    location: 'Wil',
    location_type: 'city',
    id: 1043,
  },
  {
    location: 'Stein',
    location_type: 'city',
    id: 1044,
  },
  {
    location: 'Courgevaux',
    location_type: 'city',
    id: 1045,
  },
  {
    location: 'Kappelen',
    location_type: 'city',
    id: 1046,
  },
  {
    location: 'Avusy',
    location_type: 'city',
    id: 1047,
  },
  {
    location: 'Unterlunkhofen',
    location_type: 'city',
    id: 1048,
  },
  {
    location: 'Semsales',
    location_type: 'city',
    id: 1049,
  },
  {
    location: 'M\u00fcnstschemier',
    location_type: 'city',
    id: 1050,
  },
  {
    location: 'Heitenried',
    location_type: 'city',
    id: 1051,
  },
  {
    location: 'M\u00fchlethurnen',
    location_type: 'city',
    id: 1052,
  },
  {
    location: 'Tr\u00e9lex',
    location_type: 'city',
    id: 1053,
  },
  {
    location: 'Sch\u00f6fflisdorf',
    location_type: 'city',
    id: 1054,
  },
  {
    location: 'Eriswil',
    location_type: 'city',
    id: 1055,
  },
  {
    location: 'Salvan',
    location_type: 'city',
    id: 1056,
  },
  {
    location: 'H\u00e9r\u00e9mence',
    location_type: 'city',
    id: 1057,
  },
  {
    location: 'Andermatt',
    location_type: 'city',
    id: 1058,
  },
  {
    location: 'Gisikon',
    location_type: 'city',
    id: 1059,
  },
  {
    location: 'Gravesano',
    location_type: 'city',
    id: 1060,
  },
  {
    location: 'Cureglia',
    location_type: 'city',
    id: 1061,
  },
  {
    location: 'Sch\u00f6nenbuch',
    location_type: 'city',
    id: 1062,
  },
  {
    location: 'Erlach',
    location_type: 'city',
    id: 1063,
  },
  {
    location: 'Vitznau',
    location_type: 'city',
    id: 1064,
  },
  {
    location: 'Grono',
    location_type: 'city',
    id: 1065,
  },
  {
    location: 'Flaach',
    location_type: 'city',
    id: 1066,
  },
  {
    location: 'Leukerbad',
    location_type: 'city',
    id: 1067,
  },
  {
    location: 'Boppelsen',
    location_type: 'city',
    id: 1068,
  },
  {
    location: 'H\u00e4ggenschwil',
    location_type: 'city',
    id: 1069,
  },
  {
    location: 'Gryon',
    location_type: 'city',
    id: 1070,
  },
  {
    location: 'Th\u00fcrnen',
    location_type: 'city',
    id: 1071,
  },
  {
    location: 'Develier',
    location_type: 'city',
    id: 1072,
  },
  {
    location: 'Stetten',
    location_type: 'city',
    id: 1073,
  },
  {
    location: 'Trub',
    location_type: 'city',
    id: 1074,
  },
  {
    location: 'Mi\u00e8ge',
    location_type: 'city',
    id: 1075,
  },
  {
    location: 'Schinznach Bad',
    location_type: 'city',
    id: 1076,
  },
  {
    location: 'Mesocco',
    location_type: 'city',
    id: 1077,
  },
  {
    location: 'Sankt Ursen',
    location_type: 'city',
    id: 1078,
  },
  {
    location: 'Visperterminen',
    location_type: 'city',
    id: 1079,
  },
  {
    location: 'Le P\u00e2quier',
    location_type: 'city',
    id: 1080,
  },
  {
    location: 'Bassins',
    location_type: 'city',
    id: 1081,
  },
  {
    location: 'Seewis im Pr\u00e4tigau',
    location_type: 'city',
    id: 1082,
  },
  {
    location: 'Pura',
    location_type: 'city',
    id: 1083,
  },
  {
    location: 'Aesch',
    location_type: 'city',
    id: 1084,
  },
  {
    location: 'Sankt Stephan',
    location_type: 'city',
    id: 1085,
  },
  {
    location: 'Dietwil',
    location_type: 'city',
    id: 1086,
  },
  {
    location: 'Champ\u00e9ry',
    location_type: 'city',
    id: 1087,
  },
  {
    location: 'Uerkheim',
    location_type: 'city',
    id: 1088,
  },
  {
    location: 'Matzendorf',
    location_type: 'city',
    id: 1089,
  },
  {
    location: 'Baltschieder',
    location_type: 'city',
    id: 1090,
  },
  {
    location: 'Leibstadt',
    location_type: 'city',
    id: 1091,
  },
  {
    location: 'Manno',
    location_type: 'city',
    id: 1092,
  },
  {
    location: 'Salenstein',
    location_type: 'city',
    id: 1093,
  },
  {
    location: 'Gilly',
    location_type: 'city',
    id: 1094,
  },
  {
    location: 'Holderbank',
    location_type: 'city',
    id: 1095,
  },
  {
    location: 'Birwinken',
    location_type: 'city',
    id: 1096,
  },
  {
    location: 'T\u00e4sch',
    location_type: 'city',
    id: 1097,
  },
  {
    location: 'Le Vaud',
    location_type: 'city',
    id: 1098,
  },
  {
    location: 'Rodersdorf',
    location_type: 'city',
    id: 1099,
  },
  {
    location: 'Hochwald',
    location_type: 'city',
    id: 1100,
  },
  {
    location: 'Wittnau',
    location_type: 'city',
    id: 1101,
  },
  {
    location: 'Villaz-Saint-Pierre',
    location_type: 'city',
    id: 1102,
  },
  {
    location: 'Zetzwil',
    location_type: 'city',
    id: 1103,
  },
  {
    location: 'Meinisberg',
    location_type: 'city',
    id: 1104,
  },
  {
    location: 'Lavertezzo',
    location_type: 'city',
    id: 1105,
  },
  {
    location: 'Seltisberg',
    location_type: 'city',
    id: 1106,
  },
  {
    location: 'Kandersteg',
    location_type: 'city',
    id: 1107,
  },
  {
    location: 'Linden',
    location_type: 'city',
    id: 1108,
  },
  {
    location: 'Vufflens-la-Ville',
    location_type: 'city',
    id: 1109,
  },
  {
    location: 'Rheinau',
    location_type: 'city',
    id: 1110,
  },
  {
    location: 'Bottens',
    location_type: 'city',
    id: 1111,
  },
  {
    location: 'Kriegstetten',
    location_type: 'city',
    id: 1112,
  },
  {
    location: 'L\u00e4ufelfingen',
    location_type: 'city',
    id: 1113,
  },
  {
    location: 'Langrickenbach',
    location_type: 'city',
    id: 1114,
  },
  {
    location: 'Chavannes-de-Bogis',
    location_type: 'city',
    id: 1115,
  },
  {
    location: 'Venth\u00f4ne',
    location_type: 'city',
    id: 1116,
  },
  {
    location: 'Radelfingen',
    location_type: 'city',
    id: 1117,
  },
  {
    location: 'Staffelbach',
    location_type: 'city',
    id: 1118,
  },
  {
    location: 'Arbaz',
    location_type: 'city',
    id: 1119,
  },
  {
    location: 'Jussy',
    location_type: 'city',
    id: 1120,
  },
  {
    location: 'Luthern',
    location_type: 'city',
    id: 1121,
  },
  {
    location: 'Hendschiken',
    location_type: 'city',
    id: 1122,
  },
  {
    location: 'Evionnaz',
    location_type: 'city',
    id: 1123,
  },
  {
    location: 'Boltigen',
    location_type: 'city',
    id: 1124,
  },
  {
    location: 'Oberhelfenschwil',
    location_type: 'city',
    id: 1125,
  },
  {
    location: 'Kleinl\u00fctzel',
    location_type: 'city',
    id: 1126,
  },
  {
    location: 'Ferenbalm',
    location_type: 'city',
    id: 1127,
  },
  {
    location: 'Leuzigen',
    location_type: 'city',
    id: 1128,
  },
  {
    location: 'D\u00fcrren\u00e4sch',
    location_type: 'city',
    id: 1129,
  },
  {
    location: 'Gr\u00e4chen',
    location_type: 'city',
    id: 1130,
  },
  {
    location: 'Les Ponts-de-Martel',
    location_type: 'city',
    id: 1131,
  },
  {
    location: 'Bettwiesen',
    location_type: 'city',
    id: 1132,
  },
  {
    location: 'Blumenstein',
    location_type: 'city',
    id: 1133,
  },
  {
    location: 'Reitnau',
    location_type: 'city',
    id: 1134,
  },
  {
    location: 'Les Bois',
    location_type: 'city',
    id: 1135,
  },
  {
    location: 'Frauenkappelen',
    location_type: 'city',
    id: 1136,
  },
  {
    location: 'Rochefort',
    location_type: 'city',
    id: 1137,
  },
  {
    location: 'Bercher',
    location_type: 'city',
    id: 1138,
  },
  {
    location: 'Sonvilier',
    location_type: 'city',
    id: 1139,
  },
  {
    location: 'Buttwil',
    location_type: 'city',
    id: 1140,
  },
  {
    location: 'Boncourt',
    location_type: 'city',
    id: 1141,
  },
  {
    location: 'Orvin',
    location_type: 'city',
    id: 1142,
  },
  {
    location: 'Ch\u00e9serex',
    location_type: 'city',
    id: 1143,
  },
  {
    location: 'Gingins',
    location_type: 'city',
    id: 1144,
  },
  {
    location: 'Tamins',
    location_type: 'city',
    id: 1145,
  },
  {
    location: 'Gerzensee',
    location_type: 'city',
    id: 1146,
  },
  {
    location: 'Lommis',
    location_type: 'city',
    id: 1147,
  },
  {
    location: 'Bettingen',
    location_type: 'city',
    id: 1148,
  },
  {
    location: 'Ecl\u00e9pens',
    location_type: 'city',
    id: 1149,
  },
  {
    location: 'Villars-le-Terroir',
    location_type: 'city',
    id: 1150,
  },
  {
    location: 'Marbach',
    location_type: 'city',
    id: 1151,
  },
  {
    location: 'Aesch',
    location_type: 'city',
    id: 1152,
  },
  {
    location: 'Stettfurt',
    location_type: 'city',
    id: 1153,
  },
  {
    location: 'Muolen',
    location_type: 'city',
    id: 1154,
  },
  {
    location: 'Oberstammheim',
    location_type: 'city',
    id: 1155,
  },
  {
    location: 'Beatenberg',
    location_type: 'city',
    id: 1156,
  },
  {
    location: 'La Sonnaz',
    location_type: 'city',
    id: 1157,
  },
  {
    location: 'Tegerfelden',
    location_type: 'city',
    id: 1158,
  },
  {
    location: 'La Verrerie',
    location_type: 'city',
    id: 1159,
  },
  {
    location: 'B\u00e4ttwil',
    location_type: 'city',
    id: 1160,
  },
  {
    location: 'Zuoz',
    location_type: 'city',
    id: 1161,
  },
  {
    location: 'Aire-la-Ville',
    location_type: 'city',
    id: 1162,
  },
  {
    location: 'Ried bei Kerzers',
    location_type: 'city',
    id: 1163,
  },
  {
    location: 'Choulex',
    location_type: 'city',
    id: 1164,
  },
  {
    location: 'Crassier',
    location_type: 'city',
    id: 1165,
  },
  {
    location: 'Obergerlafingen',
    location_type: 'city',
    id: 1166,
  },
  {
    location: 'Niederbuchsiten',
    location_type: 'city',
    id: 1167,
  },
  {
    location: 'M\u00fchlau',
    location_type: 'city',
    id: 1168,
  },
  {
    location: 'G\u00fcnsberg',
    location_type: 'city',
    id: 1169,
  },
  {
    location: 'Zeihen',
    location_type: 'city',
    id: 1170,
  },
  {
    location: 'Twann',
    location_type: 'city',
    id: 1171,
  },
  {
    location: 'Gettnau',
    location_type: 'city',
    id: 1172,
  },
  {
    location: 'Trun',
    location_type: 'city',
    id: 1173,
  },
  {
    location: 'Schmiedrued',
    location_type: 'city',
    id: 1174,
  },
  {
    location: 'Birrwil',
    location_type: 'city',
    id: 1175,
  },
  {
    location: 'La Rippe',
    location_type: 'city',
    id: 1176,
  },
  {
    location: 'La Roche',
    location_type: 'city',
    id: 1177,
  },
  {
    location: 'Morschach',
    location_type: 'city',
    id: 1178,
  },
  {
    location: 'Cevio',
    location_type: 'city',
    id: 1179,
  },
  {
    location: 'Sumvitg',
    location_type: 'city',
    id: 1180,
  },
  {
    location: 'Jenaz',
    location_type: 'city',
    id: 1181,
  },
  {
    location: 'Heimenhausen',
    location_type: 'city',
    id: 1182,
  },
  {
    location: 'Lully',
    location_type: 'city',
    id: 1183,
  },
  {
    location: 'Remaufens',
    location_type: 'city',
    id: 1184,
  },
  {
    location: 'Wyssachen',
    location_type: 'city',
    id: 1185,
  },
  {
    location: 'Schnottwil',
    location_type: 'city',
    id: 1186,
  },
  {
    location: 'Remigen',
    location_type: 'city',
    id: 1187,
  },
  {
    location: 'Borex',
    location_type: 'city',
    id: 1188,
  },
  {
    location: 'Krattigen',
    location_type: 'city',
    id: 1189,
  },
  {
    location: 'Liesberg',
    location_type: 'city',
    id: 1190,
  },
  {
    location: 'Schlatterlehn',
    location_type: 'city',
    id: 1191,
  },
  {
    location: 'Waldenburg',
    location_type: 'city',
    id: 1192,
  },
  {
    location: 'Kappel am Albis',
    location_type: 'city',
    id: 1193,
  },
  {
    location: 'Leissigen',
    location_type: 'city',
    id: 1194,
  },
  {
    location: 'Greppen',
    location_type: 'city',
    id: 1195,
  },
  {
    location: 'Noville',
    location_type: 'city',
    id: 1196,
  },
  {
    location: 'Etagni\u00e8res',
    location_type: 'city',
    id: 1197,
  },
  {
    location: 'Pampigny',
    location_type: 'city',
    id: 1198,
  },
  {
    location: 'Welschenrohr',
    location_type: 'city',
    id: 1199,
  },
  {
    location: 'Brusio',
    location_type: 'city',
    id: 1200,
  },
  {
    location: 'Wuppenau',
    location_type: 'city',
    id: 1201,
  },
  {
    location: 'Silvaplauna',
    location_type: 'city',
    id: 1202,
  },
  {
    location: 'B\u00fcnzen',
    location_type: 'city',
    id: 1203,
  },
  {
    location: 'M\u00fclligen',
    location_type: 'city',
    id: 1204,
  },
  {
    location: 'Hagenbuch',
    location_type: 'city',
    id: 1205,
  },
  {
    location: 'Rechthalten',
    location_type: 'city',
    id: 1206,
  },
  {
    location: 'Lauerz',
    location_type: 'city',
    id: 1207,
  },
  {
    location: 'Sorens',
    location_type: 'city',
    id: 1208,
  },
  {
    location: 'Affoltern im Emmental',
    location_type: 'city',
    id: 1209,
  },
  {
    location: 'Rifferswil',
    location_type: 'city',
    id: 1210,
  },
  {
    location: 'Burgistein',
    location_type: 'city',
    id: 1211,
  },
  {
    location: 'Ependes',
    location_type: 'city',
    id: 1212,
  },
  {
    location: 'Stalden',
    location_type: 'city',
    id: 1213,
  },
  {
    location: 'Duillier',
    location_type: 'city',
    id: 1214,
  },
  {
    location: 'Morrens',
    location_type: 'city',
    id: 1215,
  },
  {
    location: 'Herdern',
    location_type: 'city',
    id: 1216,
  },
  {
    location: 'Assens',
    location_type: 'city',
    id: 1217,
  },
  {
    location: 'Obermumpf',
    location_type: 'city',
    id: 1218,
  },
  {
    location: 'Untereggen',
    location_type: 'city',
    id: 1219,
  },
  {
    location: 'Oberembrach',
    location_type: 'city',
    id: 1220,
  },
  {
    location: 'Gletterens',
    location_type: 'city',
    id: 1221,
  },
  {
    location: 'Wegenstetten',
    location_type: 'city',
    id: 1222,
  },
  {
    location: 'Buus',
    location_type: 'city',
    id: 1223,
  },
  {
    location: 'Yvorne',
    location_type: 'city',
    id: 1224,
  },
  {
    location: 'Ballaigues',
    location_type: 'city',
    id: 1225,
  },
  {
    location: 'Vaulruz',
    location_type: 'city',
    id: 1226,
  },
  {
    location: 'Innertkirchen',
    location_type: 'city',
    id: 1227,
  },
  {
    location: 'Hermance',
    location_type: 'city',
    id: 1228,
  },
  {
    location: 'B\u00e4riswil',
    location_type: 'city',
    id: 1229,
  },
  {
    location: 'D\u00fcrrenroth',
    location_type: 'city',
    id: 1230,
  },
  {
    location: 'F\u00e9tigny',
    location_type: 'city',
    id: 1231,
  },
  {
    location: 'W\u00e4ldi',
    location_type: 'city',
    id: 1232,
  },
  {
    location: 'St\u00fcsslingen',
    location_type: 'city',
    id: 1233,
  },
  {
    location: 'Giebenach',
    location_type: 'city',
    id: 1234,
  },
  {
    location: 'Tr\u00fcllikon',
    location_type: 'city',
    id: 1235,
  },
  {
    location: 'Eggenwil',
    location_type: 'city',
    id: 1236,
  },
  {
    location: 'Freienwil',
    location_type: 'city',
    id: 1237,
  },
  {
    location: 'W\u00f6lflinswil',
    location_type: 'city',
    id: 1238,
  },
  {
    location: 'Plasselb',
    location_type: 'city',
    id: 1239,
  },
  {
    location: 'M\u00e9zi\u00e8res',
    location_type: 'city',
    id: 1240,
  },
  {
    location: 'Les Brenets',
    location_type: 'city',
    id: 1241,
  },
  {
    location: 'Schongau',
    location_type: 'city',
    id: 1242,
  },
  {
    location: 'B\u00fcren',
    location_type: 'city',
    id: 1243,
  },
  {
    location: 'Sembrancher',
    location_type: 'city',
    id: 1244,
  },
  {
    location: 'Sullens',
    location_type: 'city',
    id: 1245,
  },
  {
    location: 'Vich',
    location_type: 'city',
    id: 1246,
  },
  {
    location: 'Champagne',
    location_type: 'city',
    id: 1247,
  },
  {
    location: 'Gansingen',
    location_type: 'city',
    id: 1248,
  },
  {
    location: 'Haldenstein',
    location_type: 'city',
    id: 1249,
  },
  {
    location: 'Saint George',
    location_type: 'city',
    id: 1250,
  },
  {
    location: 'D\u00f6rflingen',
    location_type: 'city',
    id: 1251,
  },
  {
    location: 'Daillens',
    location_type: 'city',
    id: 1252,
  },
  {
    location: 'Baulmes',
    location_type: 'city',
    id: 1253,
  },
  {
    location: 'Givrins',
    location_type: 'city',
    id: 1254,
  },
  {
    location: 'Oeschgen',
    location_type: 'city',
    id: 1255,
  },
  {
    location: 'Quinto',
    location_type: 'city',
    id: 1256,
  },
  {
    location: 'Augst',
    location_type: 'city',
    id: 1257,
  },
  {
    location: 'Burg',
    location_type: 'city',
    id: 1258,
  },
  {
    location: 'Essertines-sur-Yverdon',
    location_type: 'city',
    id: 1259,
  },
  {
    location: 'H\u00fcntwangen',
    location_type: 'city',
    id: 1260,
  },
  {
    location: 'La Sagne',
    location_type: 'city',
    id: 1261,
  },
  {
    location: 'Vorderthal',
    location_type: 'city',
    id: 1262,
  },
  {
    location: 'Rickenbach',
    location_type: 'city',
    id: 1263,
  },
  {
    location: 'D\u00e4gerlen',
    location_type: 'city',
    id: 1264,
  },
  {
    location: 'Cornol',
    location_type: 'city',
    id: 1265,
  },
  {
    location: 'Seewen',
    location_type: 'city',
    id: 1266,
  },
  {
    location: 'Grub',
    location_type: 'city',
    id: 1267,
  },
  {
    location: 'Vals',
    location_type: 'city',
    id: 1268,
  },
  {
    location: 'Saint Martin',
    location_type: 'city',
    id: 1269,
  },
  {
    location: 'Vuarrens',
    location_type: 'city',
    id: 1270,
  },
  {
    location: 'Cressier',
    location_type: 'city',
    id: 1271,
  },
  {
    location: 'L\u2019Isle',
    location_type: 'city',
    id: 1272,
  },
  {
    location: 'M\u00f6rel-Filet',
    location_type: 'city',
    id: 1273,
  },
  {
    location: 'Igis',
    location_type: 'city',
    id: 1274,
  },
  {
    location: 'Tafers',
    location_type: 'city',
    id: 1275,
  },
  {
    location: 'Cernier',
    location_type: 'city',
    id: 1276,
  },
  {
    location: 'Haslen',
    location_type: 'city',
    id: 1277,
  },
  {
    location: 'M\u00fcnster-Geschinen',
    location_type: 'city',
    id: 1278,
  },
  {
    location: 'Tiefencastel',
    location_type: 'city',
    id: 1279,
  },
  {
    location_type: 'zip_code',
    location: '5000',
    location_name: 'Aarau',
    latitude: 47.389,
    longitude: 8.0487,
    id: 1280,
  },
  {
    location_type: 'zip_code',
    location: '5001',
    location_name: 'Aarau',
    latitude: 47.3922,
    longitude: 8.0497,
    id: 1281,
  },
  {
    location_type: 'zip_code',
    location: '5004',
    location_name: 'Aarau',
    latitude: 47.4005,
    longitude: 8.0606,
    id: 1282,
  },
  {
    location_type: 'zip_code',
    location: '5017',
    location_name: 'Barmelweid',
    latitude: 47.4216,
    longitude: 7.97,
    id: 1283,
  },
  {
    location_type: 'zip_code',
    location: '5018',
    location_name: 'Erlinsbach',
    latitude: 47.4126,
    longitude: 8.0089,
    id: 1284,
  },
  {
    location_type: 'zip_code',
    location: '5022',
    location_name: 'Rombach',
    latitude: 47.4057,
    longitude: 8.0466,
    id: 1285,
  },
  {
    location_type: 'zip_code',
    location: '5023',
    location_name: 'Biberstein',
    latitude: 47.4195,
    longitude: 8.0827,
    id: 1286,
  },
  {
    location_type: 'zip_code',
    location: '5024',
    location_name: 'K\u00fcttigen',
    latitude: 47.4204,
    longitude: 8.0461,
    id: 1287,
  },
  {
    location_type: 'zip_code',
    location: '5025',
    location_name: 'Asp',
    latitude: 47.4418,
    longitude: 8.0539,
    id: 1288,
  },
  {
    location_type: 'zip_code',
    location: '5026',
    location_name: 'Densb\u00fcren',
    latitude: 47.4555,
    longitude: 8.0572,
    id: 1289,
  },
  {
    location_type: 'zip_code',
    location: '5032',
    location_name: 'Aarau Rohr',
    latitude: 47.4045,
    longitude: 8.0807,
    id: 1290,
  },
  {
    location_type: 'zip_code',
    location: '5033',
    location_name: 'Buchs AG',
    latitude: 47.3931,
    longitude: 8.0756,
    id: 1291,
  },
  {
    location_type: 'zip_code',
    location: '5034',
    location_name: 'Suhr',
    latitude: 47.3747,
    longitude: 8.0859,
    id: 1292,
  },
  {
    location_type: 'zip_code',
    location: '5035',
    location_name: 'Unterentfelden',
    latitude: 47.3686,
    longitude: 8.0412,
    id: 1293,
  },
  {
    location_type: 'zip_code',
    location: '5036',
    location_name: 'Oberentfelden',
    latitude: 47.3541,
    longitude: 8.0396,
    id: 1294,
  },
  {
    location_type: 'zip_code',
    location: '5037',
    location_name: 'Muhen',
    latitude: 47.3341,
    longitude: 8.0632,
    id: 1295,
  },
  {
    location_type: 'zip_code',
    location: '5042',
    location_name: 'Hirschthal',
    latitude: 47.3175,
    longitude: 8.0678,
    id: 1296,
  },
  {
    location_type: 'zip_code',
    location: '5722',
    location_name: 'Gr\u00e4nichen',
    latitude: 47.3543,
    longitude: 8.1057,
    id: 1297,
  },
  {
    location_type: 'zip_code',
    location: '5300',
    location_name: 'Turgi',
    latitude: 47.4934,
    longitude: 8.2525,
    id: 1298,
  },
  {
    location_type: 'zip_code',
    location: '5301',
    location_name: 'Siggenthal Station',
    latitude: 47.5149,
    longitude: 8.2398,
    id: 1299,
  },
  {
    location_type: 'zip_code',
    location: '5303',
    location_name: 'W\u00fcrenlingen',
    latitude: 47.531,
    longitude: 8.2477,
    id: 1300,
  },
  {
    location_type: 'zip_code',
    location: '5400',
    location_name: 'Baden',
    latitude: 47.4716,
    longitude: 8.2925,
    id: 1301,
  },
  {
    location_type: 'zip_code',
    location: '5401',
    location_name: 'Baden',
    latitude: 47.4667,
    longitude: 8.3,
    id: 1302,
  },
  {
    location_type: 'zip_code',
    location: '5402',
    location_name: 'Baden',
    latitude: 47.4667,
    longitude: 8.3,
    id: 1303,
  },
  {
    location_type: 'zip_code',
    location: '5404',
    location_name: 'Baden',
    latitude: 47.4541,
    longitude: 8.2782,
    id: 1304,
  },
  {
    location_type: 'zip_code',
    location: '5405',
    location_name: 'D\u00e4ttwil AG',
    latitude: 47.4545,
    longitude: 8.2843,
    id: 1305,
  },
  {
    location_type: 'zip_code',
    location: '5406',
    location_name: 'R\u00fctihof',
    latitude: 47.4383,
    longitude: 8.2658,
    id: 1306,
  },
  {
    location_type: 'zip_code',
    location: '5408',
    location_name: 'Ennetbaden',
    latitude: 47.4798,
    longitude: 8.3277,
    id: 1307,
  },
  {
    location_type: 'zip_code',
    location: '5412',
    location_name: 'Gebenstorf',
    latitude: 47.48,
    longitude: 8.244,
    id: 1308,
  },
  {
    location_type: 'zip_code',
    location: '5413',
    location_name: 'Birmenstorf AG',
    latitude: 47.4517,
    longitude: 8.2668,
    id: 1309,
  },
  {
    location_type: 'zip_code',
    location: '5415',
    location_name: 'Nussbaumen AG',
    latitude: 47.4954,
    longitude: 8.2956,
    id: 1310,
  },
  {
    location_type: 'zip_code',
    location: '5416',
    location_name: 'Kirchdorf AG',
    latitude: 47.4995,
    longitude: 8.2798,
    id: 1311,
  },
  {
    location_type: 'zip_code',
    location: '5417',
    location_name: 'Untersiggenthal',
    latitude: 47.5038,
    longitude: 8.2562,
    id: 1312,
  },
  {
    location_type: 'zip_code',
    location: '5420',
    location_name: 'Ehrendingen',
    latitude: 47.4956,
    longitude: 8.3446,
    id: 1313,
  },
  {
    location_type: 'zip_code',
    location: '5423',
    location_name: 'Freienwil',
    latitude: 47.4984,
    longitude: 8.3205,
    id: 1314,
  },
  {
    location_type: 'zip_code',
    location: '5430',
    location_name: 'Wettingen',
    latitude: 47.4629,
    longitude: 8.3321,
    id: 1315,
  },
  {
    location_type: 'zip_code',
    location: '5431',
    location_name: 'Wettingen Sonderdienste',
    latitude: 47.4556,
    longitude: 8.3333,
    id: 1316,
  },
  {
    location_type: 'zip_code',
    location: '5432',
    location_name: 'Neuenhof',
    latitude: 47.4446,
    longitude: 8.3253,
    id: 1317,
  },
  {
    location_type: 'zip_code',
    location: '5436',
    location_name: 'W\u00fcrenlos',
    latitude: 47.4474,
    longitude: 8.3622,
    id: 1318,
  },
  {
    location_type: 'zip_code',
    location: '5442',
    location_name: 'Fislisbach',
    latitude: 47.4364,
    longitude: 8.2906,
    id: 1319,
  },
  {
    location_type: 'zip_code',
    location: '5443',
    location_name: 'Niederrohrdorf',
    latitude: 47.4228,
    longitude: 8.3019,
    id: 1320,
  },
  {
    location_type: 'zip_code',
    location: '5444',
    location_name: 'K\u00fcnten',
    latitude: 47.3891,
    longitude: 8.3264,
    id: 1321,
  },
  {
    location_type: 'zip_code',
    location: '5452',
    location_name: 'Oberrohrdorf',
    latitude: 47.4239,
    longitude: 8.3228,
    id: 1322,
  },
  {
    location_type: 'zip_code',
    location: '5453',
    location_name: 'Remetschwil',
    latitude: 47.4073,
    longitude: 8.3351,
    id: 1323,
  },
  {
    location_type: 'zip_code',
    location: '5454',
    location_name: 'Bellikon',
    latitude: 47.3919,
    longitude: 8.3483,
    id: 1324,
  },
  {
    location_type: 'zip_code',
    location: '5506',
    location_name: 'M\u00e4genwil',
    latitude: 47.4138,
    longitude: 8.2335,
    id: 1325,
  },
  {
    location_type: 'zip_code',
    location: '5507',
    location_name: 'Mellingen',
    latitude: 47.4205,
    longitude: 8.2727,
    id: 1326,
  },
  {
    location_type: 'zip_code',
    location: '5512',
    location_name: 'Wohlenschwil',
    latitude: 47.4182,
    longitude: 8.2529,
    id: 1327,
  },
  {
    location_type: 'zip_code',
    location: '5608',
    location_name: 'Stetten AG',
    latitude: 47.4036,
    longitude: 8.3023,
    id: 1328,
  },
  {
    location_type: 'zip_code',
    location: '8109',
    location_name: 'Kloster Fahr',
    latitude: 47.4083,
    longitude: 8.4391,
    id: 1329,
  },
  {
    location_type: 'zip_code',
    location: '8956',
    location_name: 'Killwangen',
    latitude: 47.4275,
    longitude: 8.3409,
    id: 1330,
  },
  {
    location_type: 'zip_code',
    location: '8957',
    location_name: 'Spreitenbach',
    latitude: 47.4174,
    longitude: 8.3666,
    id: 1331,
  },
  {
    location_type: 'zip_code',
    location: '8962',
    location_name: 'Bergdietikon',
    latitude: 47.3885,
    longitude: 8.3793,
    id: 1332,
  },
  {
    location_type: 'zip_code',
    location: '5445',
    location_name: 'Eggenwil',
    latitude: 47.3711,
    longitude: 8.338,
    id: 1333,
  },
  {
    location_type: 'zip_code',
    location: '5522',
    location_name: 'T\u00e4gerig',
    latitude: 47.3976,
    longitude: 8.2789,
    id: 1334,
  },
  {
    location_type: 'zip_code',
    location: '5524',
    location_name: 'Nesselnbach',
    latitude: 47.3929,
    longitude: 8.2943,
    id: 1335,
  },
  {
    location_type: 'zip_code',
    location: '5525',
    location_name: 'Fischbach-G\u00f6slikon',
    latitude: 47.3687,
    longitude: 8.3121,
    id: 1336,
  },
  {
    location_type: 'zip_code',
    location: '5605',
    location_name: 'Dottikon',
    latitude: 47.3747,
    longitude: 8.2454,
    id: 1337,
  },
  {
    location_type: 'zip_code',
    location: '5607',
    location_name: 'H\u00e4gglingen',
    latitude: 47.3908,
    longitude: 8.2569,
    id: 1338,
  },
  {
    location_type: 'zip_code',
    location: '5610',
    location_name: 'Wohlen AG',
    latitude: 47.3507,
    longitude: 8.2859,
    id: 1339,
  },
  {
    location_type: 'zip_code',
    location: '5611',
    location_name: 'Anglikon',
    latitude: 47.3651,
    longitude: 8.2618,
    id: 1340,
  },
  {
    location_type: 'zip_code',
    location: '5612',
    location_name: 'Villmergen',
    latitude: 47.3479,
    longitude: 8.2387,
    id: 1341,
  },
  {
    location_type: 'zip_code',
    location: '5613',
    location_name: 'Hilfikon',
    latitude: 47.3308,
    longitude: 8.2436,
    id: 1342,
  },
  {
    location_type: 'zip_code',
    location: '5614',
    location_name: 'Sarmenstorf',
    latitude: 47.3112,
    longitude: 8.2509,
    id: 1343,
  },
  {
    location_type: 'zip_code',
    location: '5619',
    location_name: 'Uezwil',
    latitude: 47.3148,
    longitude: 8.2772,
    id: 1344,
  },
  {
    location_type: 'zip_code',
    location: '5620',
    location_name: 'Bremgarten AG',
    latitude: 47.355,
    longitude: 8.3294,
    id: 1345,
  },
  {
    location_type: 'zip_code',
    location: '5621',
    location_name: 'Zufikon',
    latitude: 47.3471,
    longitude: 8.3566,
    id: 1346,
  },
  {
    location_type: 'zip_code',
    location: '5626',
    location_name: 'Hermetschwil-Staffeln',
    latitude: 47.3299,
    longitude: 8.3468,
    id: 1347,
  },
  {
    location_type: 'zip_code',
    location: '8905',
    location_name: 'Arni AG',
    latitude: 47.3154,
    longitude: 8.4196,
    id: 1348,
  },
  {
    location_type: 'zip_code',
    location: '8916',
    location_name: 'Jonen',
    latitude: 47.2997,
    longitude: 8.4008,
    id: 1349,
  },
  {
    location_type: 'zip_code',
    location: '8917',
    location_name: 'Oberlunkhofen',
    latitude: 47.3132,
    longitude: 8.4001,
    id: 1350,
  },
  {
    location_type: 'zip_code',
    location: '8918',
    location_name: 'Unterlunkhofen',
    latitude: 47.3226,
    longitude: 8.3916,
    id: 1351,
  },
  {
    location_type: 'zip_code',
    location: '8964',
    location_name: 'Rudolfstetten',
    latitude: 47.3683,
    longitude: 8.3878,
    id: 1352,
  },
  {
    location_type: 'zip_code',
    location: '8965',
    location_name: 'Berikon',
    latitude: 47.3531,
    longitude: 8.3869,
    id: 1353,
  },
  {
    location_type: 'zip_code',
    location: '8966',
    location_name: 'Oberwil-Lieli',
    latitude: 47.3398,
    longitude: 8.3915,
    id: 1354,
  },
  {
    location_type: 'zip_code',
    location: '8967',
    location_name: 'Widen',
    latitude: 47.3693,
    longitude: 8.3568,
    id: 1355,
  },
  {
    location_type: 'zip_code',
    location: '5076',
    location_name: 'B\u00f6zen',
    latitude: 47.4957,
    longitude: 8.0891,
    id: 1356,
  },
  {
    location_type: 'zip_code',
    location: '5077',
    location_name: 'Elfingen',
    latitude: 47.5101,
    longitude: 8.1026,
    id: 1357,
  },
  {
    location_type: 'zip_code',
    location: '5078',
    location_name: 'Effingen',
    latitude: 47.4934,
    longitude: 8.1127,
    id: 1358,
  },
  {
    location_type: 'zip_code',
    location: '5105',
    location_name: 'Auenstein',
    latitude: 47.4186,
    longitude: 8.1306,
    id: 1359,
  },
  {
    location_type: 'zip_code',
    location: '5106',
    location_name: 'Veltheim AG',
    latitude: 47.4323,
    longitude: 8.1469,
    id: 1360,
  },
  {
    location_type: 'zip_code',
    location: '5107',
    location_name: 'Schinznach Dorf',
    latitude: 47.455,
    longitude: 8.1326,
    id: 1361,
  },
  {
    location_type: 'zip_code',
    location: '5108',
    location_name: 'Oberflachs',
    latitude: 47.4376,
    longitude: 8.1233,
    id: 1362,
  },
  {
    location_type: 'zip_code',
    location: '5112',
    location_name: 'Thalheim AG',
    latitude: 47.4408,
    longitude: 8.0907,
    id: 1363,
  },
  {
    location_type: 'zip_code',
    location: '5116',
    location_name: 'Schinznach Bad',
    latitude: 47.4536,
    longitude: 8.1698,
    id: 1364,
  },
  {
    location_type: 'zip_code',
    location: '5200',
    location_name: 'Brugg AG',
    latitude: 47.4828,
    longitude: 8.2037,
    id: 1365,
  },
  {
    location_type: 'zip_code',
    location: '5201',
    location_name: 'Brugg AG',
    latitude: 47.4905,
    longitude: 8.1857,
    id: 1366,
  },
  {
    location_type: 'zip_code',
    location: '5210',
    location_name: 'Windisch',
    latitude: 47.4764,
    longitude: 8.2202,
    id: 1367,
  },
  {
    location_type: 'zip_code',
    location: '5212',
    location_name: 'Hausen AG',
    latitude: 47.4628,
    longitude: 8.2083,
    id: 1368,
  },
  {
    location_type: 'zip_code',
    location: '5213',
    location_name: 'Villnachern',
    latitude: 47.4707,
    longitude: 8.1575,
    id: 1369,
  },
  {
    location_type: 'zip_code',
    location: '5222',
    location_name: 'Umiken',
    latitude: 47.4808,
    longitude: 8.1851,
    id: 1370,
  },
  {
    location_type: 'zip_code',
    location: '5223',
    location_name: 'Riniken',
    latitude: 47.4947,
    longitude: 8.1863,
    id: 1371,
  },
  {
    location_type: 'zip_code',
    location: '5224',
    location_name: 'Gallenkirch',
    latitude: 47.48,
    longitude: 8.1227,
    id: 1372,
  },
  {
    location_type: 'zip_code',
    location: '5225',
    location_name: 'Oberb\u00f6zberg',
    latitude: 47.5019,
    longitude: 8.1532,
    id: 1373,
  },
  {
    location_type: 'zip_code',
    location: '5232',
    location_name: 'Villigen PSI',
    latitude: 47.5167,
    longitude: 8.2083,
    id: 1374,
  },
  {
    location_type: 'zip_code',
    location: '5233',
    location_name: 'Stilli',
    latitude: 47.5185,
    longitude: 8.2313,
    id: 1375,
  },
  {
    location_type: 'zip_code',
    location: '5234',
    location_name: 'Villigen',
    latitude: 47.5294,
    longitude: 8.1974,
    id: 1376,
  },
  {
    location_type: 'zip_code',
    location: '5235',
    location_name: 'R\u00fcfenach AG',
    latitude: 47.504,
    longitude: 8.2125,
    id: 1377,
  },
  {
    location_type: 'zip_code',
    location: '5236',
    location_name: 'Remigen',
    latitude: 47.5169,
    longitude: 8.1795,
    id: 1378,
  },
  {
    location_type: 'zip_code',
    location: '5237',
    location_name: 'M\u00f6nthal',
    latitude: 47.5154,
    longitude: 8.1356,
    id: 1379,
  },
  {
    location_type: 'zip_code',
    location: '5242',
    location_name: 'Birr',
    latitude: 47.4326,
    longitude: 8.2112,
    id: 1380,
  },
  {
    location_type: 'zip_code',
    location: '5243',
    location_name: 'M\u00fclligen',
    latitude: 47.4524,
    longitude: 8.2406,
    id: 1381,
  },
  {
    location_type: 'zip_code',
    location: '5244',
    location_name: 'Birrhard',
    latitude: 47.4358,
    longitude: 8.2431,
    id: 1382,
  },
  {
    location_type: 'zip_code',
    location: '5245',
    location_name: 'Habsburg',
    latitude: 47.4618,
    longitude: 8.1858,
    id: 1383,
  },
  {
    location_type: 'zip_code',
    location: '5246',
    location_name: 'Scherz',
    latitude: 47.4486,
    longitude: 8.1877,
    id: 1384,
  },
  {
    location_type: 'zip_code',
    location: '5318',
    location_name: 'Mandach',
    latitude: 47.5518,
    longitude: 8.1885,
    id: 1385,
  },
  {
    location_type: 'zip_code',
    location: '5040',
    location_name: 'Sch\u00f6ftland',
    latitude: 47.3061,
    longitude: 8.0581,
    id: 1386,
  },
  {
    location_type: 'zip_code',
    location: '5043',
    location_name: 'Holziken',
    latitude: 47.3198,
    longitude: 8.0334,
    id: 1387,
  },
  {
    location_type: 'zip_code',
    location: '5044',
    location_name: 'Schlossrued',
    latitude: 47.2873,
    longitude: 8.0907,
    id: 1388,
  },
  {
    location_type: 'zip_code',
    location: '5046',
    location_name: 'Walde AG',
    latitude: 47.254,
    longitude: 8.1164,
    id: 1389,
  },
  {
    location_type: 'zip_code',
    location: '5708',
    location_name: 'Birrwil',
    latitude: 47.292,
    longitude: 8.1977,
    id: 1390,
  },
  {
    location_type: 'zip_code',
    location: '5712',
    location_name: 'Beinwil am See',
    latitude: 47.2649,
    longitude: 8.2076,
    id: 1391,
  },
  {
    location_type: 'zip_code',
    location: '5723',
    location_name: 'Teufenthal AG',
    latitude: 47.3286,
    longitude: 8.1285,
    id: 1392,
  },
  {
    location_type: 'zip_code',
    location: '5724',
    location_name: 'D\u00fcrren\u00e4sch',
    latitude: 47.3192,
    longitude: 8.1526,
    id: 1393,
  },
  {
    location_type: 'zip_code',
    location: '5725',
    location_name: 'Leutwil',
    latitude: 47.3077,
    longitude: 8.174,
    id: 1394,
  },
  {
    location_type: 'zip_code',
    location: '5726',
    location_name: 'Unterkulm',
    latitude: 47.319,
    longitude: 8.1093,
    id: 1395,
  },
  {
    location_type: 'zip_code',
    location: '5727',
    location_name: 'Oberkulm',
    latitude: 47.2971,
    longitude: 8.1242,
    id: 1396,
  },
  {
    location_type: 'zip_code',
    location: '5728',
    location_name: 'Gontenschwil',
    latitude: 47.2677,
    longitude: 8.1431,
    id: 1397,
  },
  {
    location_type: 'zip_code',
    location: '5732',
    location_name: 'Zetzwil',
    latitude: 47.2888,
    longitude: 8.1595,
    id: 1398,
  },
  {
    location_type: 'zip_code',
    location: '5733',
    location_name: 'Leimbach AG',
    latitude: 47.2746,
    longitude: 8.168,
    id: 1399,
  },
  {
    location_type: 'zip_code',
    location: '5734',
    location_name: 'Reinach AG',
    latitude: 47.2635,
    longitude: 8.1748,
    id: 1400,
  },
  {
    location_type: 'zip_code',
    location: '5736',
    location_name: 'Burg AG',
    latitude: 47.2342,
    longitude: 8.1787,
    id: 1401,
  },
  {
    location_type: 'zip_code',
    location: '5737',
    location_name: 'Menziken',
    latitude: 47.2359,
    longitude: 8.1937,
    id: 1402,
  },
  {
    location_type: 'zip_code',
    location: '4333',
    location_name: 'M\u00fcnchwilen AG',
    latitude: 47.5354,
    longitude: 7.9623,
    id: 1403,
  },
  {
    location_type: 'zip_code',
    location: '4334',
    location_name: 'Sisseln AG',
    latitude: 47.5512,
    longitude: 7.9828,
    id: 1404,
  },
  {
    location_type: 'zip_code',
    location: '5027',
    location_name: 'Herznach',
    latitude: 47.4725,
    longitude: 8.0479,
    id: 1405,
  },
  {
    location_type: 'zip_code',
    location: '5028',
    location_name: 'Ueken',
    latitude: 47.4866,
    longitude: 8.0443,
    id: 1406,
  },
  {
    location_type: 'zip_code',
    location: '5062',
    location_name: 'Oberhof',
    latitude: 47.4421,
    longitude: 8.0083,
    id: 1407,
  },
  {
    location_type: 'zip_code',
    location: '5063',
    location_name: 'W\u00f6lflinswil',
    latitude: 47.4643,
    longitude: 8.0021,
    id: 1408,
  },
  {
    location_type: 'zip_code',
    location: '5064',
    location_name: 'Wittnau',
    latitude: 47.4772,
    longitude: 7.9682,
    id: 1409,
  },
  {
    location_type: 'zip_code',
    location: '5070',
    location_name: 'Frick',
    latitude: 47.5079,
    longitude: 8.012,
    id: 1410,
  },
  {
    location_type: 'zip_code',
    location: '5072',
    location_name: 'Oeschgen',
    latitude: 47.5249,
    longitude: 8.0179,
    id: 1411,
  },
  {
    location_type: 'zip_code',
    location: '5073',
    location_name: 'Gipf-Oberfrick',
    latitude: 47.4897,
    longitude: 8.0066,
    id: 1412,
  },
  {
    location_type: 'zip_code',
    location: '5074',
    location_name: 'Eiken',
    latitude: 47.5384,
    longitude: 7.99,
    id: 1413,
  },
  {
    location_type: 'zip_code',
    location: '5075',
    location_name: 'Hornussen',
    latitude: 47.4997,
    longitude: 8.0666,
    id: 1414,
  },
  {
    location_type: 'zip_code',
    location: '5079',
    location_name: 'Zeihen',
    latitude: 47.4691,
    longitude: 8.0932,
    id: 1415,
  },
  {
    location_type: 'zip_code',
    location: '5080',
    location_name: 'Laufenburg',
    latitude: 47.5565,
    longitude: 8.0598,
    id: 1416,
  },
  {
    location_type: 'zip_code',
    location: '5082',
    location_name: 'Kaisten',
    latitude: 47.5365,
    longitude: 8.0428,
    id: 1417,
  },
  {
    location_type: 'zip_code',
    location: '5083',
    location_name: 'Ittenthal',
    latitude: 47.518,
    longitude: 8.0609,
    id: 1418,
  },
  {
    location_type: 'zip_code',
    location: '5084',
    location_name: 'Rheinsulz',
    latitude: 47.5533,
    longitude: 8.0859,
    id: 1419,
  },
  {
    location_type: 'zip_code',
    location: '5085',
    location_name: 'Sulz AG',
    latitude: 47.5349,
    longitude: 8.099,
    id: 1420,
  },
  {
    location_type: 'zip_code',
    location: '5272',
    location_name: 'Gansingen',
    latitude: 47.5389,
    longitude: 8.1318,
    id: 1421,
  },
  {
    location_type: 'zip_code',
    location: '5326',
    location_name: 'Schwaderloch',
    latitude: 47.5843,
    longitude: 8.1427,
    id: 1422,
  },
  {
    location_type: 'zip_code',
    location: '5102',
    location_name: 'Rupperswil',
    latitude: 47.4031,
    longitude: 8.1337,
    id: 1423,
  },
  {
    location_type: 'zip_code',
    location: '5103',
    location_name: 'M\u00f6riken AG',
    latitude: 47.4155,
    longitude: 8.1925,
    id: 1424,
  },
  {
    location_type: 'zip_code',
    location: '5113',
    location_name: 'Holderbank AG',
    latitude: 47.4305,
    longitude: 8.1735,
    id: 1425,
  },
  {
    location_type: 'zip_code',
    location: '5502',
    location_name: 'Hunzenschwil',
    latitude: 47.3827,
    longitude: 8.1222,
    id: 1426,
  },
  {
    location_type: 'zip_code',
    location: '5503',
    location_name: 'Schafisheim',
    latitude: 47.3753,
    longitude: 8.1383,
    id: 1427,
  },
  {
    location_type: 'zip_code',
    location: '5504',
    location_name: 'Othmarsingen',
    latitude: 47.4029,
    longitude: 8.2159,
    id: 1428,
  },
  {
    location_type: 'zip_code',
    location: '5505',
    location_name: 'Brunegg',
    latitude: 47.4211,
    longitude: 8.2186,
    id: 1429,
  },
  {
    location_type: 'zip_code',
    location: '5510',
    location_name: 'Hunzenschwil Dist Ba',
    latitude: 47.4,
    longitude: 8.2056,
    id: 1430,
  },
  {
    location_type: 'zip_code',
    location: '5600',
    location_name: 'Ammerswil AG',
    latitude: 47.3681,
    longitude: 8.2062,
    id: 1431,
  },
  {
    location_type: 'zip_code',
    location: '5601',
    location_name: 'Lenzburg Sonderdienste',
    latitude: 47.3833,
    longitude: 8.21,
    id: 1432,
  },
  {
    location_type: 'zip_code',
    location: '5603',
    location_name: 'Staufen',
    latitude: 47.3763,
    longitude: 8.159,
    id: 1433,
  },
  {
    location_type: 'zip_code',
    location: '5604',
    location_name: 'Hendschiken',
    latitude: 47.3846,
    longitude: 8.2165,
    id: 1434,
  },
  {
    location_type: 'zip_code',
    location: '5606',
    location_name: 'Dintikon',
    latitude: 47.3611,
    longitude: 8.2215,
    id: 1435,
  },
  {
    location_type: 'zip_code',
    location: '5615',
    location_name: 'Fahrwangen',
    latitude: 47.2945,
    longitude: 8.2379,
    id: 1436,
  },
  {
    location_type: 'zip_code',
    location: '5616',
    location_name: 'Meisterschwanden',
    latitude: 47.2903,
    longitude: 8.2246,
    id: 1437,
  },
  {
    location_type: 'zip_code',
    location: '5617',
    location_name: 'Tennwil',
    latitude: 47.3046,
    longitude: 8.2188,
    id: 1438,
  },
  {
    location_type: 'zip_code',
    location: '5702',
    location_name: 'Niederlenz',
    latitude: 47.4046,
    longitude: 8.1678,
    id: 1439,
  },
  {
    location_type: 'zip_code',
    location: '5703',
    location_name: 'Seon',
    latitude: 47.3487,
    longitude: 8.1589,
    id: 1440,
  },
  {
    location_type: 'zip_code',
    location: '5704',
    location_name: 'Egliswil',
    latitude: 47.3517,
    longitude: 8.1921,
    id: 1441,
  },
  {
    location_type: 'zip_code',
    location: '5705',
    location_name: 'Hallwil',
    latitude: 47.328,
    longitude: 8.1766,
    id: 1442,
  },
  {
    location_type: 'zip_code',
    location: '5706',
    location_name: 'Boniswil',
    latitude: 47.3101,
    longitude: 8.1946,
    id: 1443,
  },
  {
    location_type: 'zip_code',
    location: '5707',
    location_name: 'Seengen',
    latitude: 47.3273,
    longitude: 8.2058,
    id: 1444,
  },
  {
    location_type: 'zip_code',
    location: '5618',
    location_name: 'Bettwil',
    latitude: 47.2928,
    longitude: 8.2708,
    id: 1445,
  },
  {
    location_type: 'zip_code',
    location: '5622',
    location_name: 'Waltenschwil',
    latitude: 47.3341,
    longitude: 8.2969,
    id: 1446,
  },
  {
    location_type: 'zip_code',
    location: '5623',
    location_name: 'Boswil',
    latitude: 47.2986,
    longitude: 8.3123,
    id: 1447,
  },
  {
    location_type: 'zip_code',
    location: '5624',
    location_name: 'Waldh\u00e4usern AG',
    latitude: 47.3273,
    longitude: 8.3181,
    id: 1448,
  },
  {
    location_type: 'zip_code',
    location: '5625',
    location_name: 'Kallern',
    latitude: 47.312,
    longitude: 8.2897,
    id: 1449,
  },
  {
    location_type: 'zip_code',
    location: '5627',
    location_name: 'Besenb\u00fcren',
    latitude: 47.312,
    longitude: 8.3446,
    id: 1450,
  },
  {
    location_type: 'zip_code',
    location: '5628',
    location_name: 'Aristau',
    latitude: 47.2902,
    longitude: 8.3632,
    id: 1451,
  },
  {
    location_type: 'zip_code',
    location: '5630',
    location_name: 'Muri AG',
    latitude: 47.2733,
    longitude: 8.3386,
    id: 1452,
  },
  {
    location_type: 'zip_code',
    location: '5632',
    location_name: 'Buttwil',
    latitude: 47.2696,
    longitude: 8.3054,
    id: 1453,
  },
  {
    location_type: 'zip_code',
    location: '5634',
    location_name: 'Merenschwand',
    latitude: 47.264,
    longitude: 8.3844,
    id: 1454,
  },
  {
    location_type: 'zip_code',
    location: '5636',
    location_name: 'Benzenschwil',
    latitude: 47.2466,
    longitude: 8.3646,
    id: 1455,
  },
  {
    location_type: 'zip_code',
    location: '5637',
    location_name: 'Beinwil (Freiamt)',
    latitude: 47.2292,
    longitude: 8.3438,
    id: 1456,
  },
  {
    location_type: 'zip_code',
    location: '5642',
    location_name: 'M\u00fchlau',
    latitude: 47.2305,
    longitude: 8.3945,
    id: 1457,
  },
  {
    location_type: 'zip_code',
    location: '5643',
    location_name: 'Alikon',
    latitude: 47.1949,
    longitude: 8.3503,
    id: 1458,
  },
  {
    location_type: 'zip_code',
    location: '5644',
    location_name: 'Auw',
    latitude: 47.2174,
    longitude: 8.3679,
    id: 1459,
  },
  {
    location_type: 'zip_code',
    location: '5645',
    location_name: 'Fenkrieden',
    latitude: 47.1592,
    longitude: 8.3708,
    id: 1460,
  },
  {
    location_type: 'zip_code',
    location: '5646',
    location_name: 'Abtwil AG',
    latitude: 47.1732,
    longitude: 8.3527,
    id: 1461,
  },
  {
    location_type: 'zip_code',
    location: '5647',
    location_name: 'Oberr\u00fcti',
    latitude: 47.165,
    longitude: 8.3974,
    id: 1462,
  },
  {
    location_type: 'zip_code',
    location: '6042',
    location_name: 'Dietwil',
    latitude: 47.1496,
    longitude: 8.3967,
    id: 1463,
  },
  {
    location_type: 'zip_code',
    location: '8919',
    location_name: 'Rottenschwil',
    latitude: 47.3147,
    longitude: 8.3658,
    id: 1464,
  },
  {
    location_type: 'zip_code',
    location: '4303',
    location_name: 'Kaiseraugst',
    latitude: 47.5357,
    longitude: 7.7417,
    id: 1465,
  },
  {
    location_type: 'zip_code',
    location: '4305',
    location_name: 'Olsberg',
    latitude: 47.5229,
    longitude: 7.7842,
    id: 1466,
  },
  {
    location_type: 'zip_code',
    location: '4310',
    location_name: 'Rheinfelden',
    latitude: 47.5603,
    longitude: 7.8155,
    id: 1467,
  },
  {
    location_type: 'zip_code',
    location: '4312',
    location_name: 'Magden',
    latitude: 47.5187,
    longitude: 7.8091,
    id: 1468,
  },
  {
    location_type: 'zip_code',
    location: '4313',
    location_name: 'M\u00f6hlin',
    latitude: 47.5682,
    longitude: 7.8581,
    id: 1469,
  },
  {
    location_type: 'zip_code',
    location: '4314',
    location_name: 'Zeiningen',
    latitude: 47.5446,
    longitude: 7.8762,
    id: 1470,
  },
  {
    location_type: 'zip_code',
    location: '4315',
    location_name: 'Zuzgen',
    latitude: 47.5235,
    longitude: 7.9041,
    id: 1471,
  },
  {
    location_type: 'zip_code',
    location: '4316',
    location_name: 'Hellikon',
    latitude: 47.5088,
    longitude: 7.9175,
    id: 1472,
  },
  {
    location_type: 'zip_code',
    location: '4317',
    location_name: 'Wegenstetten',
    latitude: 47.4964,
    longitude: 7.934,
    id: 1473,
  },
  {
    location_type: 'zip_code',
    location: '4322',
    location_name: 'Mumpf',
    latitude: 47.5448,
    longitude: 7.918,
    id: 1474,
  },
  {
    location_type: 'zip_code',
    location: '4323',
    location_name: 'Wallbach',
    latitude: 47.5696,
    longitude: 7.8948,
    id: 1475,
  },
  {
    location_type: 'zip_code',
    location: '4324',
    location_name: 'Obermumpf',
    latitude: 47.5282,
    longitude: 7.9387,
    id: 1476,
  },
  {
    location_type: 'zip_code',
    location: '4325',
    location_name: 'Schupfart',
    latitude: 47.5139,
    longitude: 7.9631,
    id: 1477,
  },
  {
    location_type: 'zip_code',
    location: '4332',
    location_name: 'Stein AG',
    latitude: 47.5405,
    longitude: 7.9498,
    id: 1478,
  },
  {
    location_type: 'zip_code',
    location: '4663',
    location_name: 'Aarburg',
    latitude: 47.3205,
    longitude: 7.8993,
    id: 1479,
  },
  {
    location_type: 'zip_code',
    location: '4665',
    location_name: 'Oftringen',
    latitude: 47.3131,
    longitude: 7.9424,
    id: 1480,
  },
  {
    location_type: 'zip_code',
    location: '4800',
    location_name: 'Zofingen',
    latitude: 47.2874,
    longitude: 7.9577,
    id: 1481,
  },
  {
    location_type: 'zip_code',
    location: '4801',
    location_name: 'Zofingen Ringier AG',
    latitude: 47.2667,
    longitude: 7.93,
    id: 1482,
  },
  {
    location_type: 'zip_code',
    location: '4802',
    location_name: 'Strengelbach',
    latitude: 47.2831,
    longitude: 7.9236,
    id: 1483,
  },
  {
    location_type: 'zip_code',
    location: '4803',
    location_name: 'Vordemwald',
    latitude: 47.2713,
    longitude: 7.8931,
    id: 1484,
  },
  {
    location_type: 'zip_code',
    location: '4805',
    location_name: 'Brittnau',
    latitude: 47.2561,
    longitude: 7.9286,
    id: 1485,
  },
  {
    location_type: 'zip_code',
    location: '4807',
    location_name: 'Zofingen PostFinance',
    latitude: 47.2888,
    longitude: 7.9633,
    id: 1486,
  },
  {
    location_type: 'zip_code',
    location: '4808',
    location_name: 'Zofingen PostFinance',
    latitude: 47.2888,
    longitude: 7.9633,
    id: 1487,
  },
  {
    location_type: 'zip_code',
    location: '4809',
    location_name: 'Zofingen Postfinance UBS',
    latitude: 47.2888,
    longitude: 7.9633,
    id: 1488,
  },
  {
    location_type: 'zip_code',
    location: '4812',
    location_name: 'M\u00fchlethal',
    latitude: 47.2996,
    longitude: 7.977,
    id: 1489,
  },
  {
    location_type: 'zip_code',
    location: '4813',
    location_name: 'Uerkheim',
    latitude: 47.3034,
    longitude: 8.0109,
    id: 1490,
  },
  {
    location_type: 'zip_code',
    location: '4814',
    location_name: 'Bottenwil',
    latitude: 47.2833,
    longitude: 7.9991,
    id: 1491,
  },
  {
    location_type: 'zip_code',
    location: '4852',
    location_name: 'Rothrist',
    latitude: 47.298,
    longitude: 7.8857,
    id: 1492,
  },
  {
    location_type: 'zip_code',
    location: '4853',
    location_name: 'Murgenthal',
    latitude: 47.2677,
    longitude: 7.8362,
    id: 1493,
  },
  {
    location_type: 'zip_code',
    location: '4856',
    location_name: 'Glash\u00fctten',
    latitude: 47.2501,
    longitude: 7.8561,
    id: 1494,
  },
  {
    location_type: 'zip_code',
    location: '5053',
    location_name: 'Staffelbach',
    latitude: 47.2818,
    longitude: 8.0369,
    id: 1495,
  },
  {
    location_type: 'zip_code',
    location: '5054',
    location_name: 'Kirchleerau',
    latitude: 47.2768,
    longitude: 8.0731,
    id: 1496,
  },
  {
    location_type: 'zip_code',
    location: '5056',
    location_name: 'Attelwil',
    latitude: 47.2629,
    longitude: 8.0381,
    id: 1497,
  },
  {
    location_type: 'zip_code',
    location: '5057',
    location_name: 'Reitnau',
    latitude: 47.252,
    longitude: 8.037,
    id: 1498,
  },
  {
    location_type: 'zip_code',
    location: '5058',
    location_name: 'Wiliberg',
    latitude: 47.2663,
    longitude: 8.0164,
    id: 1499,
  },
  {
    location_type: 'zip_code',
    location: '5742',
    location_name: 'K\u00f6lliken',
    latitude: 47.334,
    longitude: 8.023,
    id: 1500,
  },
  {
    location_type: 'zip_code',
    location: '5745',
    location_name: 'Safenwil',
    latitude: 47.3213,
    longitude: 7.9863,
    id: 1501,
  },
  {
    location_type: 'zip_code',
    location: '5304',
    location_name: 'Endingen',
    latitude: 47.5273,
    longitude: 8.2939,
    id: 1502,
  },
  {
    location_type: 'zip_code',
    location: '5305',
    location_name: 'Unterendingen',
    latitude: 47.5458,
    longitude: 8.2947,
    id: 1503,
  },
  {
    location_type: 'zip_code',
    location: '5306',
    location_name: 'Tegerfelden',
    latitude: 47.5606,
    longitude: 8.2856,
    id: 1504,
  },
  {
    location_type: 'zip_code',
    location: '5312',
    location_name: 'D\u00f6ttingen',
    latitude: 47.5547,
    longitude: 8.2512,
    id: 1505,
  },
  {
    location_type: 'zip_code',
    location: '5313',
    location_name: 'Klingnau',
    latitude: 47.5886,
    longitude: 8.2492,
    id: 1506,
  },
  {
    location_type: 'zip_code',
    location: '5314',
    location_name: 'Kleind\u00f6ttingen',
    latitude: 47.5698,
    longitude: 8.2377,
    id: 1507,
  },
  {
    location_type: 'zip_code',
    location: '5315',
    location_name: 'B\u00f6ttstein',
    latitude: 47.553,
    longitude: 8.218,
    id: 1508,
  },
  {
    location_type: 'zip_code',
    location: '5316',
    location_name: 'Felsenau AG',
    latitude: 47.6056,
    longitude: 8.2184,
    id: 1509,
  },
  {
    location_type: 'zip_code',
    location: '5317',
    location_name: 'Hettenschwil',
    latitude: 47.5724,
    longitude: 8.1926,
    id: 1510,
  },
  {
    location_type: 'zip_code',
    location: '5322',
    location_name: 'Koblenz',
    latitude: 47.6047,
    longitude: 8.2425,
    id: 1511,
  },
  {
    location_type: 'zip_code',
    location: '5323',
    location_name: 'Rietheim',
    latitude: 47.5987,
    longitude: 8.2718,
    id: 1512,
  },
  {
    location_type: 'zip_code',
    location: '5324',
    location_name: 'Full-Reuenthal',
    latitude: 47.6076,
    longitude: 8.203,
    id: 1513,
  },
  {
    location_type: 'zip_code',
    location: '5325',
    location_name: 'Leibstadt',
    latitude: 47.5871,
    longitude: 8.1772,
    id: 1514,
  },
  {
    location_type: 'zip_code',
    location: '5330',
    location_name: 'Bad Zurzach',
    latitude: 47.592,
    longitude: 8.2948,
    id: 1515,
  },
  {
    location_type: 'zip_code',
    location: '5332',
    location_name: 'Rekingen AG',
    latitude: 47.5679,
    longitude: 8.3214,
    id: 1516,
  },
  {
    location_type: 'zip_code',
    location: '5333',
    location_name: 'Baldingen',
    latitude: 47.5549,
    longitude: 8.3134,
    id: 1517,
  },
  {
    location_type: 'zip_code',
    location: '5334',
    location_name: 'B\u00f6bikon',
    latitude: 47.5518,
    longitude: 8.3363,
    id: 1518,
  },
  {
    location_type: 'zip_code',
    location: '5425',
    location_name: 'Schneisingen',
    latitude: 47.528,
    longitude: 8.3666,
    id: 1519,
  },
  {
    location_type: 'zip_code',
    location: '5426',
    location_name: 'Lengnau AG',
    latitude: 47.5265,
    longitude: 8.3219,
    id: 1520,
  },
  {
    location_type: 'zip_code',
    location: '5462',
    location_name: 'Siglistorf',
    latitude: 47.534,
    longitude: 8.3904,
    id: 1521,
  },
  {
    location_type: 'zip_code',
    location: '5463',
    location_name: 'Wislikofen',
    latitude: 47.5531,
    longitude: 8.3672,
    id: 1522,
  },
  {
    location_type: 'zip_code',
    location: '5464',
    location_name: 'R\u00fcmikon AG',
    latitude: 47.5657,
    longitude: 8.3768,
    id: 1523,
  },
  {
    location_type: 'zip_code',
    location: '5465',
    location_name: 'Mellikon',
    latitude: 47.5622,
    longitude: 8.3481,
    id: 1524,
  },
  {
    location_type: 'zip_code',
    location: '5466',
    location_name: 'Kaiserstuhl AG',
    latitude: 47.5679,
    longitude: 8.4198,
    id: 1525,
  },
  {
    location_type: 'zip_code',
    location: '5467',
    location_name: 'Fisibach',
    latitude: 47.5573,
    longitude: 8.4051,
    id: 1526,
  },
  {
    location_type: 'zip_code',
    location: '9050',
    location_name: 'Appenzell Enggenh\u00fctten',
    latitude: 47.3496,
    longitude: 9.3596,
    id: 1527,
  },
  {
    location_type: 'zip_code',
    location: '9054',
    location_name: 'Haslen',
    latitude: 47.3695,
    longitude: 9.3822,
    id: 1528,
  },
  {
    location_type: 'zip_code',
    location: '9057',
    location_name: 'Schwende',
    latitude: 47.2965,
    longitude: 9.4341,
    id: 1529,
  },
  {
    location_type: 'zip_code',
    location: '9058',
    location_name: 'Br\u00fclisau',
    latitude: 47.2724,
    longitude: 9.456,
    id: 1530,
  },
  {
    location_type: 'zip_code',
    location: '9108',
    location_name: 'Gontenbad',
    latitude: 47.3255,
    longitude: 9.3695,
    id: 1531,
  },
  {
    location_type: 'zip_code',
    location: '9413',
    location_name: 'Oberegg',
    latitude: 47.4129,
    longitude: 9.5482,
    id: 1532,
  },
  {
    location_type: 'zip_code',
    location: '9442',
    location_name: 'B\u00fcriswilen',
    latitude: 47.4324,
    longitude: 9.6018,
    id: 1533,
  },
  {
    location_type: 'zip_code',
    location: '9063',
    location_name: 'Stein AR',
    latitude: 47.3646,
    longitude: 9.3185,
    id: 1534,
  },
  {
    location_type: 'zip_code',
    location: '9064',
    location_name: 'Hundwil',
    latitude: 47.3646,
    longitude: 9.3185,
    id: 1535,
  },
  {
    location_type: 'zip_code',
    location: '9100',
    location_name: 'Herisau',
    latitude: 47.3786,
    longitude: 9.2887,
    id: 1536,
  },
  {
    location_type: 'zip_code',
    location: '9101',
    location_name: 'Herisau',
    latitude: 47.3861,
    longitude: 9.2792,
    id: 1537,
  },
  {
    location_type: 'zip_code',
    location: '9102',
    location_name: 'Herisau',
    latitude: 47.3861,
    longitude: 9.2792,
    id: 1538,
  },
  {
    location_type: 'zip_code',
    location: '9103',
    location_name: 'Schwellbrunn',
    latitude: 47.3465,
    longitude: 9.1973,
    id: 1539,
  },
  {
    location_type: 'zip_code',
    location: '9104',
    location_name: 'Waldstatt',
    latitude: 47.3563,
    longitude: 9.2834,
    id: 1540,
  },
  {
    location_type: 'zip_code',
    location: '9105',
    location_name: 'Sch\u00f6nengrund',
    latitude: 47.3258,
    longitude: 9.2241,
    id: 1541,
  },
  {
    location_type: 'zip_code',
    location: '9107',
    location_name: 'Urn\u00e4sch',
    latitude: 47.2773,
    longitude: 9.3103,
    id: 1542,
  },
  {
    location_type: 'zip_code',
    location: '9112',
    location_name: 'Schachen b. Herisau',
    latitude: 47.3743,
    longitude: 9.2002,
    id: 1543,
  },
  {
    location_type: 'zip_code',
    location: '9037',
    location_name: 'Speicherschwendi',
    latitude: 47.4279,
    longitude: 9.4384,
    id: 1544,
  },
  {
    location_type: 'zip_code',
    location: '9042',
    location_name: 'Speicher',
    latitude: 47.4065,
    longitude: 9.4412,
    id: 1545,
  },
  {
    location_type: 'zip_code',
    location: '9043',
    location_name: 'Trogen',
    latitude: 47.4,
    longitude: 9.4667,
    id: 1546,
  },
  {
    location_type: 'zip_code',
    location: '9052',
    location_name: 'Niederteufen',
    latitude: 47.3457,
    longitude: 9.4305,
    id: 1547,
  },
  {
    location_type: 'zip_code',
    location: '9053',
    location_name: 'Teufen AR Zustellung',
    latitude: 47.3457,
    longitude: 9.4305,
    id: 1548,
  },
  {
    location_type: 'zip_code',
    location: '9055',
    location_name: 'B\u00fchler',
    latitude: 47.3715,
    longitude: 9.4002,
    id: 1549,
  },
  {
    location_type: 'zip_code',
    location: '9056',
    location_name: 'Gais',
    latitude: 47.3462,
    longitude: 9.4732,
    id: 1550,
  },
  {
    location_type: 'zip_code',
    location: '9062',
    location_name: 'Lustm\u00fchle',
    latitude: 47.3646,
    longitude: 9.3185,
    id: 1551,
  },
  {
    location_type: 'zip_code',
    location: '9035',
    location_name: 'Grub AR',
    latitude: 47.442,
    longitude: 9.5063,
    id: 1552,
  },
  {
    location_type: 'zip_code',
    location: '9038',
    location_name: 'Rehetobel',
    latitude: 47.4167,
    longitude: 9.4833,
    id: 1553,
  },
  {
    location_type: 'zip_code',
    location: '9044',
    location_name: 'Wald AR',
    latitude: 47.4,
    longitude: 9.4583,
    id: 1554,
  },
  {
    location_type: 'zip_code',
    location: '9405',
    location_name: 'Wienacht-Tobel',
    latitude: 47.4645,
    longitude: 9.5378,
    id: 1555,
  },
  {
    location_type: 'zip_code',
    location: '9410',
    location_name: 'Heiden Zustellung',
    latitude: 47.4333,
    longitude: 9.5333,
    id: 1556,
  },
  {
    location_type: 'zip_code',
    location: '9411',
    location_name: 'Reute AR',
    latitude: 47.4201,
    longitude: 9.5942,
    id: 1557,
  },
  {
    location_type: 'zip_code',
    location: '9414',
    location_name: 'Schachen b. Reute',
    latitude: 47.425,
    longitude: 9.5417,
    id: 1558,
  },
  {
    location_type: 'zip_code',
    location: '9426',
    location_name: 'Lutzenberg',
    latitude: 47.4667,
    longitude: 9.5778,
    id: 1559,
  },
  {
    location_type: 'zip_code',
    location: '9427',
    location_name: 'Wolfhalden',
    latitude: 47.4441,
    longitude: 9.561,
    id: 1560,
  },
  {
    location_type: 'zip_code',
    location: '9428',
    location_name: 'Walzenhausen',
    latitude: 47.4398,
    longitude: 9.5951,
    id: 1561,
  },
  {
    location_type: 'zip_code',
    location: '3035',
    location_name: 'Frieswil',
    latitude: 46.992,
    longitude: 7.293,
    id: 1562,
  },
  {
    location_type: 'zip_code',
    location: '3036',
    location_name: 'Detligen',
    latitude: 46.9903,
    longitude: 7.2684,
    id: 1563,
  },
  {
    location_type: 'zip_code',
    location: '3042',
    location_name: 'Ortschwaben',
    latitude: 46.9922,
    longitude: 7.3994,
    id: 1564,
  },
  {
    location_type: 'zip_code',
    location: '3045',
    location_name: 'Meikirch',
    latitude: 47.0092,
    longitude: 7.3723,
    id: 1565,
  },
  {
    location_type: 'zip_code',
    location: '3046',
    location_name: 'Wahlendorf',
    latitude: 47.0095,
    longitude: 7.3403,
    id: 1566,
  },
  {
    location_type: 'zip_code',
    location: '3053',
    location_name: 'L\u00e4tti',
    latitude: 47.037,
    longitude: 7.4344,
    id: 1567,
  },
  {
    location_type: 'zip_code',
    location: '3054',
    location_name: 'Sch\u00fcpfen',
    latitude: 47.0355,
    longitude: 7.3775,
    id: 1568,
  },
  {
    location_type: 'zip_code',
    location: '3250',
    location_name: 'Lyss',
    latitude: 47.0754,
    longitude: 7.3108,
    id: 1569,
  },
  {
    location_type: 'zip_code',
    location: '3255',
    location_name: 'Rapperswil BE',
    latitude: 47.0665,
    longitude: 7.4134,
    id: 1570,
  },
  {
    location_type: 'zip_code',
    location: '3256',
    location_name: 'Dieterswil',
    latitude: 47.0508,
    longitude: 7.4306,
    id: 1571,
  },
  {
    location_type: 'zip_code',
    location: '3257',
    location_name: 'Grossaffoltern',
    latitude: 47.0686,
    longitude: 7.3665,
    id: 1572,
  },
  {
    location_type: 'zip_code',
    location: '3262',
    location_name: 'Suberg',
    latitude: 47.0559,
    longitude: 7.3396,
    id: 1573,
  },
  {
    location_type: 'zip_code',
    location: '3266',
    location_name: 'Wiler b. Seedorf',
    latitude: 47.0508,
    longitude: 7.3199,
    id: 1574,
  },
  {
    location_type: 'zip_code',
    location: '3267',
    location_name: 'Seedorf BE',
    latitude: 47.0236,
    longitude: 7.3247,
    id: 1575,
  },
  {
    location_type: 'zip_code',
    location: '3268',
    location_name: 'Lobsigen',
    latitude: 47.0181,
    longitude: 7.2988,
    id: 1576,
  },
  {
    location_type: 'zip_code',
    location: '3270',
    location_name: 'Aarberg',
    latitude: 47.0442,
    longitude: 7.2832,
    id: 1577,
  },
  {
    location_type: 'zip_code',
    location: '3271',
    location_name: 'Radelfingen b. Aarberg',
    latitude: 47.0186,
    longitude: 7.2716,
    id: 1578,
  },
  {
    location_type: 'zip_code',
    location: '3273',
    location_name: 'Kappelen',
    latitude: 47.0756,
    longitude: 7.2776,
    id: 1579,
  },
  {
    location_type: 'zip_code',
    location: '3282',
    location_name: 'Bargen BE',
    latitude: 47.0352,
    longitude: 7.2503,
    id: 1580,
  },
  {
    location_type: 'zip_code',
    location: '3283',
    location_name: 'Kallnach',
    latitude: 47.0152,
    longitude: 7.2201,
    id: 1581,
  },
  {
    location_type: 'zip_code',
    location: '3368',
    location_name: 'Bleienbach',
    latitude: 47.1831,
    longitude: 7.7604,
    id: 1582,
  },
  {
    location_type: 'zip_code',
    location: '4900',
    location_name: 'Langenthal',
    latitude: 47.2175,
    longitude: 7.7951,
    id: 1583,
  },
  {
    location_type: 'zip_code',
    location: '4901',
    location_name: 'Langenthal',
    latitude: 47.2167,
    longitude: 7.7833,
    id: 1584,
  },
  {
    location_type: 'zip_code',
    location: '4902',
    location_name: 'Langenthal',
    latitude: 47.2167,
    longitude: 7.7833,
    id: 1585,
  },
  {
    location_type: 'zip_code',
    location: '4911',
    location_name: 'Schwarzh\u00e4usern',
    latitude: 47.2574,
    longitude: 7.7659,
    id: 1586,
  },
  {
    location_type: 'zip_code',
    location: '4912',
    location_name: 'Aarwangen',
    latitude: 47.2367,
    longitude: 7.7672,
    id: 1587,
  },
  {
    location_type: 'zip_code',
    location: '4913',
    location_name: 'Bannwil',
    latitude: 47.238,
    longitude: 7.73,
    id: 1588,
  },
  {
    location_type: 'zip_code',
    location: '4914',
    location_name: 'Roggwil BE',
    latitude: 47.2368,
    longitude: 7.8254,
    id: 1589,
  },
  {
    location_type: 'zip_code',
    location: '4916',
    location_name: 'Untersteckholz',
    latitude: 47.2058,
    longitude: 7.8439,
    id: 1590,
  },
  {
    location_type: 'zip_code',
    location: '4917',
    location_name: 'Melchnau',
    latitude: 47.1775,
    longitude: 7.8559,
    id: 1591,
  },
  {
    location_type: 'zip_code',
    location: '4919',
    location_name: 'Reisiswil',
    latitude: 47.1611,
    longitude: 7.8419,
    id: 1592,
  },
  {
    location_type: 'zip_code',
    location: '4922',
    location_name: 'Thunstetten',
    latitude: 47.1998,
    longitude: 7.7478,
    id: 1593,
  },
  {
    location_type: 'zip_code',
    location: '4923',
    location_name: 'Wynau',
    latitude: 47.2562,
    longitude: 7.8079,
    id: 1594,
  },
  {
    location_type: 'zip_code',
    location: '4924',
    location_name: 'Obersteckholz',
    latitude: 47.201,
    longitude: 7.8255,
    id: 1595,
  },
  {
    location_type: 'zip_code',
    location: '4932',
    location_name: 'Lotzwil',
    latitude: 47.1887,
    longitude: 7.7917,
    id: 1596,
  },
  {
    location_type: 'zip_code',
    location: '4933',
    location_name: 'R\u00fctschelen',
    latitude: 47.1725,
    longitude: 7.7713,
    id: 1597,
  },
  {
    location_type: 'zip_code',
    location: '4934',
    location_name: 'Madiswil',
    latitude: 47.1623,
    longitude: 7.8017,
    id: 1598,
  },
  {
    location_type: 'zip_code',
    location: '4935',
    location_name: 'Leimiswil',
    latitude: 47.154,
    longitude: 7.7705,
    id: 1599,
  },
  {
    location_type: 'zip_code',
    location: '4936',
    location_name: 'Kleindietwil',
    latitude: 47.1411,
    longitude: 7.7906,
    id: 1600,
  },
  {
    location_type: 'zip_code',
    location: '4937',
    location_name: 'Ursenbach',
    latitude: 47.1288,
    longitude: 7.7621,
    id: 1601,
  },
  {
    location_type: 'zip_code',
    location: '4938',
    location_name: 'Rohrbachgraben',
    latitude: 47.111,
    longitude: 7.8051,
    id: 1602,
  },
  {
    location_type: 'zip_code',
    location: '4943',
    location_name: 'Oeschenbach',
    latitude: 47.1103,
    longitude: 7.7448,
    id: 1603,
  },
  {
    location_type: 'zip_code',
    location: '4944',
    location_name: 'Auswil',
    latitude: 47.1358,
    longitude: 7.8385,
    id: 1604,
  },
  {
    location_type: 'zip_code',
    location: '4955',
    location_name: 'Gondiswil',
    latitude: 47.1372,
    longitude: 7.876,
    id: 1605,
  },
  {
    location_type: 'zip_code',
    location: '3000',
    location_name: 'Bern 1 Zustellung',
    latitude: 46.9167,
    longitude: 7.4667,
    id: 1606,
  },
  {
    location_type: 'zip_code',
    location: '3001',
    location_name: 'Bern',
    latitude: 46.947,
    longitude: 7.4404,
    id: 1607,
  },
  {
    location_type: 'zip_code',
    location: '3002',
    location_name: 'Bern Postfinance',
    latitude: 46.9167,
    longitude: 7.4667,
    id: 1608,
  },
  {
    location_type: 'zip_code',
    location: '3003',
    location_name: 'Bern 3 Nationalrat',
    latitude: 46.9167,
    longitude: 7.4667,
    id: 1609,
  },
  {
    location_type: 'zip_code',
    location: '3004',
    location_name: 'Bern',
    latitude: 46.9805,
    longitude: 7.452,
    id: 1610,
  },
  {
    location_type: 'zip_code',
    location: '3005',
    location_name: 'Bern',
    latitude: 46.9394,
    longitude: 7.4499,
    id: 1611,
  },
  {
    location_type: 'zip_code',
    location: '3006',
    location_name: 'Bern',
    latitude: 46.9458,
    longitude: 7.4713,
    id: 1612,
  },
  {
    location_type: 'zip_code',
    location: '3007',
    location_name: 'Bern',
    latitude: 46.9392,
    longitude: 7.4346,
    id: 1613,
  },
  {
    location_type: 'zip_code',
    location: '3008',
    location_name: 'Bern',
    latitude: 46.9546,
    longitude: 7.4207,
    id: 1614,
  },
  {
    location_type: 'zip_code',
    location: '3010',
    location_name: 'Bern',
    latitude: 46.9471,
    longitude: 7.4244,
    id: 1615,
  },
  {
    location_type: 'zip_code',
    location: '3011',
    location_name: 'Bern',
    latitude: 46.948,
    longitude: 7.4484,
    id: 1616,
  },
  {
    location_type: 'zip_code',
    location: '3012',
    location_name: 'Bern',
    latitude: 46.9616,
    longitude: 7.426,
    id: 1617,
  },
  {
    location_type: 'zip_code',
    location: '3013',
    location_name: 'Bern',
    latitude: 46.9562,
    longitude: 7.4462,
    id: 1618,
  },
  {
    location_type: 'zip_code',
    location: '3014',
    location_name: 'Bern',
    latitude: 46.9622,
    longitude: 7.4584,
    id: 1619,
  },
  {
    location_type: 'zip_code',
    location: '3015',
    location_name: 'Bern',
    latitude: 46.9402,
    longitude: 7.4855,
    id: 1620,
  },
  {
    location_type: 'zip_code',
    location: '3017',
    location_name: 'Bern Zustellung',
    latitude: 46.9476,
    longitude: 7.4065,
    id: 1621,
  },
  {
    location_type: 'zip_code',
    location: '3018',
    location_name: 'Bern',
    latitude: 46.9339,
    longitude: 7.3891,
    id: 1622,
  },
  {
    location_type: 'zip_code',
    location: '3019',
    location_name: 'Bern',
    latitude: 46.931,
    longitude: 7.3479,
    id: 1623,
  },
  {
    location_type: 'zip_code',
    location: '3020',
    location_name: 'Bern',
    latitude: 46.9371,
    longitude: 7.3209,
    id: 1624,
  },
  {
    location_type: 'zip_code',
    location: '3024',
    location_name: 'Bern',
    latitude: 46.9167,
    longitude: 7.4667,
    id: 1625,
  },
  {
    location_type: 'zip_code',
    location: '3027',
    location_name: 'Bern',
    latitude: 46.9558,
    longitude: 7.3872,
    id: 1626,
  },
  {
    location_type: 'zip_code',
    location: '3029',
    location_name: 'Bern Entris',
    latitude: 46.9476,
    longitude: 7.4065,
    id: 1627,
  },
  {
    location_type: 'zip_code',
    location: '3030',
    location_name: 'Bern',
    latitude: 46.9167,
    longitude: 7.4667,
    id: 1628,
  },
  {
    location_type: 'zip_code',
    location: '3032',
    location_name: 'Hinterkappelen',
    latitude: 46.9672,
    longitude: 7.3802,
    id: 1629,
  },
  {
    location_type: 'zip_code',
    location: '3033',
    location_name: 'Wohlen b. Bern',
    latitude: 46.9737,
    longitude: 7.351,
    id: 1630,
  },
  {
    location_type: 'zip_code',
    location: '3034',
    location_name: 'Murzelen',
    latitude: 46.9762,
    longitude: 7.3116,
    id: 1631,
  },
  {
    location_type: 'zip_code',
    location: '3037',
    location_name: 'Herrenschwanden',
    latitude: 46.9772,
    longitude: 7.4198,
    id: 1632,
  },
  {
    location_type: 'zip_code',
    location: '3038',
    location_name: 'Kirchlindach',
    latitude: 46.9998,
    longitude: 7.4151,
    id: 1633,
  },
  {
    location_type: 'zip_code',
    location: '3039',
    location_name: 'Bern PF Operations Center',
    latitude: 46.9667,
    longitude: 7.3833,
    id: 1634,
  },
  {
    location_type: 'zip_code',
    location: '3040',
    location_name: 'Bern',
    latitude: 46.9167,
    longitude: 7.4667,
    id: 1635,
  },
  {
    location_type: 'zip_code',
    location: '3041',
    location_name: 'Bern UBS',
    latitude: 46.9778,
    longitude: 7.3944,
    id: 1636,
  },
  {
    location_type: 'zip_code',
    location: '3043',
    location_name: 'Uettligen',
    latitude: 46.9886,
    longitude: 7.3782,
    id: 1637,
  },
  {
    location_type: 'zip_code',
    location: '3044',
    location_name: 'Innerberg',
    latitude: 46.9961,
    longitude: 7.313,
    id: 1638,
  },
  {
    location_type: 'zip_code',
    location: '3047',
    location_name: 'Bremgarten b. Bern',
    latitude: 46.9771,
    longitude: 7.4361,
    id: 1639,
  },
  {
    location_type: 'zip_code',
    location: '3048',
    location_name: 'Worblaufen',
    latitude: 46.9771,
    longitude: 7.4617,
    id: 1640,
  },
  {
    location_type: 'zip_code',
    location: '3049',
    location_name: 'S\u00e4riswil',
    latitude: 46.9923,
    longitude: 7.3348,
    id: 1641,
  },
  {
    location_type: 'zip_code',
    location: '3050',
    location_name: 'Bern Swisscom',
    latitude: 47.0111,
    longitude: 7.4333,
    id: 1642,
  },
  {
    location_type: 'zip_code',
    location: '3052',
    location_name: 'Zollikofen',
    latitude: 46.9967,
    longitude: 7.4533,
    id: 1643,
  },
  {
    location_type: 'zip_code',
    location: '3063',
    location_name: 'Ittigen',
    latitude: 46.9805,
    longitude: 7.4786,
    id: 1644,
  },
  {
    location_type: 'zip_code',
    location: '3065',
    location_name: 'Bolligen',
    latitude: 46.9841,
    longitude: 7.5083,
    id: 1645,
  },
  {
    location_type: 'zip_code',
    location: '3066',
    location_name: 'Stettlen',
    latitude: 46.9625,
    longitude: 7.5287,
    id: 1646,
  },
  {
    location_type: 'zip_code',
    location: '3067',
    location_name: 'Boll',
    latitude: 46.9696,
    longitude: 7.5523,
    id: 1647,
  },
  {
    location_type: 'zip_code',
    location: '3068',
    location_name: 'Utzigen',
    latitude: 46.9662,
    longitude: 7.5856,
    id: 1648,
  },
  {
    location_type: 'zip_code',
    location: '3070',
    location_name: 'Ostermundigen (LZB)',
    latitude: 46.9559,
    longitude: 7.4956,
    id: 1649,
  },
  {
    location_type: 'zip_code',
    location: '3071',
    location_name: 'Ostermundigen Zustellung',
    latitude: 46.9375,
    longitude: 7.5333,
    id: 1650,
  },
  {
    location_type: 'zip_code',
    location: '3072',
    location_name: 'Ostermundigen',
    latitude: 46.9535,
    longitude: 7.4925,
    id: 1651,
  },
  {
    location_type: 'zip_code',
    location: '3073',
    location_name: 'G\u00fcmligen',
    latitude: 46.9351,
    longitude: 7.5071,
    id: 1652,
  },
  {
    location_type: 'zip_code',
    location: '3074',
    location_name: 'Muri b. Bern',
    latitude: 46.9266,
    longitude: 7.4864,
    id: 1653,
  },
  {
    location_type: 'zip_code',
    location: '3084',
    location_name: 'Wabern',
    latitude: 46.9217,
    longitude: 7.4529,
    id: 1654,
  },
  {
    location_type: 'zip_code',
    location: '3085',
    location_name: 'Wabern 2 x Weihnachten',
    latitude: 46.9043,
    longitude: 7.3978,
    id: 1655,
  },
  {
    location_type: 'zip_code',
    location: '3095',
    location_name: 'Spiegel b. Bern',
    latitude: 46.9249,
    longitude: 7.4354,
    id: 1656,
  },
  {
    location_type: 'zip_code',
    location: '3096',
    location_name: 'Oberbalm',
    latitude: 46.8649,
    longitude: 7.4106,
    id: 1657,
  },
  {
    location_type: 'zip_code',
    location: '3097',
    location_name: 'Liebefeld',
    latitude: 46.9313,
    longitude: 7.4224,
    id: 1658,
  },
  {
    location_type: 'zip_code',
    location: '3098',
    location_name: 'K\u00f6niz',
    latitude: 46.9177,
    longitude: 7.4116,
    id: 1659,
  },
  {
    location_type: 'zip_code',
    location: '3144',
    location_name: 'Gasel',
    latitude: 46.898,
    longitude: 7.39,
    id: 1660,
  },
  {
    location_type: 'zip_code',
    location: '3145',
    location_name: 'Niederscherli',
    latitude: 46.886,
    longitude: 7.3925,
    id: 1661,
  },
  {
    location_type: 'zip_code',
    location: '3147',
    location_name: 'Mittelh\u00e4usern',
    latitude: 46.8719,
    longitude: 7.3714,
    id: 1662,
  },
  {
    location_type: 'zip_code',
    location: '3172',
    location_name: 'Niederwangen b. Bern',
    latitude: 46.9187,
    longitude: 7.3766,
    id: 1663,
  },
  {
    location_type: 'zip_code',
    location: '3173',
    location_name: 'Oberwangen b. Bern',
    latitude: 46.9126,
    longitude: 7.3548,
    id: 1664,
  },
  {
    location_type: 'zip_code',
    location: '3174',
    location_name: 'Th\u00f6rishaus',
    latitude: 46.893,
    longitude: 7.3449,
    id: 1665,
  },
  {
    location_type: 'zip_code',
    location: '2500',
    location_name: 'Biel/Bienne 6',
    latitude: 47.1324,
    longitude: 7.2441,
    id: 1666,
  },
  {
    location_type: 'zip_code',
    location: '2501',
    location_name: 'Biel/Bienne',
    latitude: 47.1504,
    longitude: 7.247,
    id: 1667,
  },
  {
    location_type: 'zip_code',
    location: '2502',
    location_name: 'Biel',
    latitude: 47.143,
    longitude: 7.2468,
    id: 1668,
  },
  {
    location_type: 'zip_code',
    location: '2503',
    location_name: 'Biel',
    latitude: 47.1311,
    longitude: 7.2519,
    id: 1669,
  },
  {
    location_type: 'zip_code',
    location: '2504',
    location_name: 'Biel',
    latitude: 47.1581,
    longitude: 7.283,
    id: 1670,
  },
  {
    location_type: 'zip_code',
    location: '2505',
    location_name: 'Biel',
    latitude: 47.1342,
    longitude: 7.2188,
    id: 1671,
  },
  {
    location_type: 'zip_code',
    location: '2510',
    location_name: 'Biel/Bienne Dist Ba',
    latitude: 47.1213,
    longitude: 7.1486,
    id: 1672,
  },
  {
    location_type: 'zip_code',
    location: '2532',
    location_name: 'Macolin',
    latitude: 47.1427,
    longitude: 7.2126,
    id: 1673,
  },
  {
    location_type: 'zip_code',
    location: '2533',
    location_name: 'Evilard',
    latitude: 47.1505,
    longitude: 7.2345,
    id: 1674,
  },
  {
    location_type: 'zip_code',
    location: '2542',
    location_name: 'Pieterlen',
    latitude: 47.1757,
    longitude: 7.3277,
    id: 1675,
  },
  {
    location_type: 'zip_code',
    location: '2543',
    location_name: 'Lengnau BE',
    latitude: 47.1826,
    longitude: 7.365,
    id: 1676,
  },
  {
    location_type: 'zip_code',
    location: '2554',
    location_name: 'Meinisberg',
    latitude: 47.1616,
    longitude: 7.3539,
    id: 1677,
  },
  {
    location_type: 'zip_code',
    location: '3251',
    location_name: 'Wengi b. B\u00fcren',
    latitude: 47.0853,
    longitude: 7.3921,
    id: 1678,
  },
  {
    location_type: 'zip_code',
    location: '3263',
    location_name: 'B\u00fcetigen',
    latitude: 47.1061,
    longitude: 7.3341,
    id: 1679,
  },
  {
    location_type: 'zip_code',
    location: '3264',
    location_name: 'Diessbach b. B\u00fcren',
    latitude: 47.1071,
    longitude: 7.3649,
    id: 1680,
  },
  {
    location_type: 'zip_code',
    location: '3292',
    location_name: 'Busswil b. B\u00fcren',
    latitude: 47.1006,
    longitude: 7.3219,
    id: 1681,
  },
  {
    location_type: 'zip_code',
    location: '3293',
    location_name: 'Dotzigen',
    latitude: 47.1239,
    longitude: 7.3472,
    id: 1682,
  },
  {
    location_type: 'zip_code',
    location: '3294',
    location_name: 'B\u00fcren an der Aare',
    latitude: 47.1419,
    longitude: 7.3657,
    id: 1683,
  },
  {
    location_type: 'zip_code',
    location: '3295',
    location_name: 'R\u00fcti b. B\u00fcren',
    latitude: 47.152,
    longitude: 7.4129,
    id: 1684,
  },
  {
    location_type: 'zip_code',
    location: '3296',
    location_name: 'Arch',
    latitude: 47.1695,
    longitude: 7.4326,
    id: 1685,
  },
  {
    location_type: 'zip_code',
    location: '3297',
    location_name: 'Leuzigen',
    latitude: 47.1711,
    longitude: 7.4691,
    id: 1686,
  },
  {
    location_type: 'zip_code',
    location: '3298',
    location_name: 'Oberwil b. B\u00fcren',
    latitude: 47.1322,
    longitude: 7.4135,
    id: 1687,
  },
  {
    location_type: 'zip_code',
    location: '3309',
    location_name: 'Kernenried',
    latitude: 47.0689,
    longitude: 7.5512,
    id: 1688,
  },
  {
    location_type: 'zip_code',
    location: '3323',
    location_name: 'B\u00e4riswil BE',
    latitude: 47.016,
    longitude: 7.5309,
    id: 1689,
  },
  {
    location_type: 'zip_code',
    location: '3324',
    location_name: 'M\u00f6tschwil',
    latitude: 47.0477,
    longitude: 7.5681,
    id: 1690,
  },
  {
    location_type: 'zip_code',
    location: '3325',
    location_name: 'Hettiswil b. Hindelbank',
    latitude: 47.0256,
    longitude: 7.5564,
    id: 1691,
  },
  {
    location_type: 'zip_code',
    location: '3326',
    location_name: 'Krauchthal',
    latitude: 47.0041,
    longitude: 7.5663,
    id: 1692,
  },
  {
    location_type: 'zip_code',
    location: '3400',
    location_name: 'Burgdorf',
    latitude: 47.0576,
    longitude: 7.6181,
    id: 1693,
  },
  {
    location_type: 'zip_code',
    location: '3401',
    location_name: 'Burgdorf',
    latitude: 47.05,
    longitude: 7.6167,
    id: 1694,
  },
  {
    location_type: 'zip_code',
    location: '3402',
    location_name: 'Burgdorf',
    latitude: 47.05,
    longitude: 7.6167,
    id: 1695,
  },
  {
    location_type: 'zip_code',
    location: '3412',
    location_name: 'Heimiswil',
    latitude: 47.0538,
    longitude: 7.6629,
    id: 1696,
  },
  {
    location_type: 'zip_code',
    location: '3413',
    location_name: 'Kaltacker',
    latitude: 47.0728,
    longitude: 7.6871,
    id: 1697,
  },
  {
    location_type: 'zip_code',
    location: '3414',
    location_name: 'Oberburg',
    latitude: 47.0211,
    longitude: 7.6097,
    id: 1698,
  },
  {
    location_type: 'zip_code',
    location: '3415',
    location_name: 'Hasle b. Burgdorf',
    latitude: 47.0137,
    longitude: 7.6406,
    id: 1699,
  },
  {
    location_type: 'zip_code',
    location: '3419',
    location_name: 'Biembach im Emmental',
    latitude: 46.9933,
    longitude: 7.6124,
    id: 1700,
  },
  {
    location_type: 'zip_code',
    location: '3421',
    location_name: 'R\u00fcti b. Lyssach',
    latitude: 47.0555,
    longitude: 7.5819,
    id: 1701,
  },
  {
    location_type: 'zip_code',
    location: '3422',
    location_name: 'Alchenfl\u00fch',
    latitude: 47.0833,
    longitude: 7.5779,
    id: 1702,
  },
  {
    location_type: 'zip_code',
    location: '3423',
    location_name: 'Ersigen',
    latitude: 47.1009,
    longitude: 7.5943,
    id: 1703,
  },
  {
    location_type: 'zip_code',
    location: '3424',
    location_name: 'Ober\u00f6sch',
    latitude: 47.1054,
    longitude: 7.6128,
    id: 1704,
  },
  {
    location_type: 'zip_code',
    location: '3425',
    location_name: 'Koppigen',
    latitude: 47.1337,
    longitude: 7.605,
    id: 1705,
  },
  {
    location_type: 'zip_code',
    location: '3426',
    location_name: 'Aefligen',
    latitude: 47.0953,
    longitude: 7.5487,
    id: 1706,
  },
  {
    location_type: 'zip_code',
    location: '3429',
    location_name: 'Hellsau',
    latitude: 47.143,
    longitude: 7.6493,
    id: 1707,
  },
  {
    location_type: 'zip_code',
    location: '3472',
    location_name: 'Rumendingen',
    latitude: 47.1038,
    longitude: 7.6421,
    id: 1708,
  },
  {
    location_type: 'zip_code',
    location: '3473',
    location_name: 'Alchenstorf',
    latitude: 47.1229,
    longitude: 7.6433,
    id: 1709,
  },
  {
    location_type: 'zip_code',
    location: '3474',
    location_name: 'R\u00fcedisbach',
    latitude: 47.1171,
    longitude: 7.7056,
    id: 1710,
  },
  {
    location_type: 'zip_code',
    location: '2333',
    location_name: 'La Ferri\u00e8re',
    latitude: 47.1481,
    longitude: 6.9011,
    id: 1711,
  },
  {
    location_type: 'zip_code',
    location: '2534',
    location_name: 'Orvin',
    latitude: 47.1565,
    longitude: 7.2115,
    id: 1712,
  },
  {
    location_type: 'zip_code',
    location: '2535',
    location_name: 'Frinvillier',
    latitude: 47.1704,
    longitude: 7.2628,
    id: 1713,
  },
  {
    location_type: 'zip_code',
    location: '2536',
    location_name: 'Plagne',
    latitude: 47.1892,
    longitude: 7.2776,
    id: 1714,
  },
  {
    location_type: 'zip_code',
    location: '2537',
    location_name: 'Vauffelin',
    latitude: 47.1858,
    longitude: 7.3073,
    id: 1715,
  },
  {
    location_type: 'zip_code',
    location: '2538',
    location_name: 'Romont BE',
    latitude: 47.1917,
    longitude: 7.3274,
    id: 1716,
  },
  {
    location_type: 'zip_code',
    location: '2603',
    location_name: 'P\u00e8ry',
    latitude: 47.1936,
    longitude: 7.2553,
    id: 1717,
  },
  {
    location_type: 'zip_code',
    location: '2604',
    location_name: 'La Heutte',
    latitude: 47.1911,
    longitude: 7.2181,
    id: 1718,
  },
  {
    location_type: 'zip_code',
    location: '2605',
    location_name: 'Sonceboz-Sombeval',
    latitude: 47.1891,
    longitude: 7.1815,
    id: 1719,
  },
  {
    location_type: 'zip_code',
    location: '2606',
    location_name: 'Corg\u00e8mont',
    latitude: 47.1918,
    longitude: 7.1412,
    id: 1720,
  },
  {
    location_type: 'zip_code',
    location: '2607',
    location_name: 'Cort\u00e8bert',
    latitude: 47.1905,
    longitude: 7.1077,
    id: 1721,
  },
  {
    location_type: 'zip_code',
    location: '2608',
    location_name: 'Courtelary',
    latitude: 47.1881,
    longitude: 7.0706,
    id: 1722,
  },
  {
    location_type: 'zip_code',
    location: '2610',
    location_name: 'St-Imier',
    latitude: 47.148,
    longitude: 6.9873,
    id: 1723,
  },
  {
    location_type: 'zip_code',
    location: '2612',
    location_name: 'Cormoret',
    latitude: 47.1571,
    longitude: 7.0521,
    id: 1724,
  },
  {
    location_type: 'zip_code',
    location: '2613',
    location_name: 'Villeret',
    latitude: 47.1493,
    longitude: 7.0318,
    id: 1725,
  },
  {
    location_type: 'zip_code',
    location: '2615',
    location_name: 'Sonvilier',
    latitude: 47.1308,
    longitude: 6.9532,
    id: 1726,
  },
  {
    location_type: 'zip_code',
    location: '2616',
    location_name: 'Renan (BE)',
    latitude: 47.1105,
    longitude: 6.9071,
    id: 1727,
  },
  {
    location_type: 'zip_code',
    location: '2720',
    location_name: 'Tramelan',
    latitude: 47.2309,
    longitude: 7.1171,
    id: 1728,
  },
  {
    location_type: 'zip_code',
    location: '2722',
    location_name: 'Les Reussilles',
    latitude: 47.2326,
    longitude: 7.0803,
    id: 1729,
  },
  {
    location_type: 'zip_code',
    location: '2723',
    location_name: 'Mont-Tramelan',
    latitude: 47.2105,
    longitude: 7.0519,
    id: 1730,
  },
  {
    location_type: 'zip_code',
    location: '2576',
    location_name: 'L\u00fcscherz',
    latitude: 47.0451,
    longitude: 7.1623,
    id: 1731,
  },
  {
    location_type: 'zip_code',
    location: '2577',
    location_name: 'Siselen BE',
    latitude: 47.0305,
    longitude: 7.1974,
    id: 1732,
  },
  {
    location_type: 'zip_code',
    location: '3225',
    location_name: 'M\u00fcntschemier',
    latitude: 46.9956,
    longitude: 7.1447,
    id: 1733,
  },
  {
    location_type: 'zip_code',
    location: '3226',
    location_name: 'Treiten',
    latitude: 47.008,
    longitude: 7.1648,
    id: 1734,
  },
  {
    location_type: 'zip_code',
    location: '3232',
    location_name: 'Ins',
    latitude: 47.0015,
    longitude: 7.1091,
    id: 1735,
  },
  {
    location_type: 'zip_code',
    location: '3233',
    location_name: 'Tschugg',
    latitude: 47.0235,
    longitude: 7.0775,
    id: 1736,
  },
  {
    location_type: 'zip_code',
    location: '3234',
    location_name: 'Vinelz',
    latitude: 47.0324,
    longitude: 7.1197,
    id: 1737,
  },
  {
    location_type: 'zip_code',
    location: '3235',
    location_name: 'Erlach',
    latitude: 47.05,
    longitude: 7.1092,
    id: 1738,
  },
  {
    location_type: 'zip_code',
    location: '3236',
    location_name: 'Gampelen',
    latitude: 46.9963,
    longitude: 7.0595,
    id: 1739,
  },
  {
    location_type: 'zip_code',
    location: '3237',
    location_name: 'Br\u00fcttelen',
    latitude: 47.0214,
    longitude: 7.1493,
    id: 1740,
  },
  {
    location_type: 'zip_code',
    location: '3238',
    location_name: 'Gals',
    latitude: 47.0355,
    longitude: 7.0581,
    id: 1741,
  },
  {
    location_type: 'zip_code',
    location: '3302',
    location_name: 'Moosseedorf',
    latitude: 47.0097,
    longitude: 7.4861,
    id: 1742,
  },
  {
    location_type: 'zip_code',
    location: '3303',
    location_name: 'Jegenstorf',
    latitude: 47.052,
    longitude: 7.507,
    id: 1743,
  },
  {
    location_type: 'zip_code',
    location: '3305',
    location_name: 'Iffwil',
    latitude: 47.0664,
    longitude: 7.4792,
    id: 1744,
  },
  {
    location_type: 'zip_code',
    location: '3306',
    location_name: 'Etzelkofen',
    latitude: 47.0829,
    longitude: 7.4799,
    id: 1745,
  },
  {
    location_type: 'zip_code',
    location: '3308',
    location_name: 'Grafenried',
    latitude: 47.0784,
    longitude: 7.5076,
    id: 1746,
  },
  {
    location_type: 'zip_code',
    location: '3312',
    location_name: 'Fraubrunnen',
    latitude: 47.0881,
    longitude: 7.5304,
    id: 1747,
  },
  {
    location_type: 'zip_code',
    location: '3313',
    location_name: 'B\u00fcren zum Hof',
    latitude: 47.0982,
    longitude: 7.5073,
    id: 1748,
  },
  {
    location_type: 'zip_code',
    location: '3314',
    location_name: 'Schalunen',
    latitude: 47.1135,
    longitude: 7.5266,
    id: 1749,
  },
  {
    location_type: 'zip_code',
    location: '3315',
    location_name: 'B\u00e4tterkinden',
    latitude: 47.1282,
    longitude: 7.5277,
    id: 1750,
  },
  {
    location_type: 'zip_code',
    location: '3317',
    location_name: 'Limpach',
    latitude: 47.1095,
    longitude: 7.4989,
    id: 1751,
  },
  {
    location_type: 'zip_code',
    location: '3321',
    location_name: 'Sch\u00f6nb\u00fchl Einkaufszentrum',
    latitude: 47.025,
    longitude: 7.55,
    id: 1752,
  },
  {
    location_type: 'zip_code',
    location: '3322',
    location_name: 'Urtenen-Sch\u00f6nb\u00fchl',
    latitude: 47.0252,
    longitude: 7.4977,
    id: 1753,
  },
  {
    location_type: 'zip_code',
    location: '3427',
    location_name: 'Utzenstorf',
    latitude: 47.1201,
    longitude: 7.5639,
    id: 1754,
  },
  {
    location_type: 'zip_code',
    location: '3428',
    location_name: 'Wiler b. Utzenstorf',
    latitude: 47.1522,
    longitude: 7.558,
    id: 1755,
  },
  {
    location_type: 'zip_code',
    location: '4564',
    location_name: 'Zielebach',
    latitude: 47.1579,
    longitude: 7.5747,
    id: 1756,
  },
  {
    location_type: 'zip_code',
    location: '3703',
    location_name: 'Aeschiried',
    latitude: 46.6132,
    longitude: 7.7613,
    id: 1757,
  },
  {
    location_type: 'zip_code',
    location: '3704',
    location_name: 'Krattigen',
    latitude: 46.6539,
    longitude: 7.7379,
    id: 1758,
  },
  {
    location_type: 'zip_code',
    location: '3711',
    location_name: 'Emdthal',
    latitude: 46.6455,
    longitude: 7.6815,
    id: 1759,
  },
  {
    location_type: 'zip_code',
    location: '3713',
    location_name: 'Reichenbach im Kandertal',
    latitude: 46.6121,
    longitude: 7.6824,
    id: 1760,
  },
  {
    location_type: 'zip_code',
    location: '3714',
    location_name: 'Frutigen',
    latitude: 46.5845,
    longitude: 7.6364,
    id: 1761,
  },
  {
    location_type: 'zip_code',
    location: '3715',
    location_name: 'Adelboden',
    latitude: 46.47,
    longitude: 7.5582,
    id: 1762,
  },
  {
    location_type: 'zip_code',
    location: '3716',
    location_name: 'Kandergrund',
    latitude: 46.5529,
    longitude: 7.668,
    id: 1763,
  },
  {
    location_type: 'zip_code',
    location: '3717',
    location_name: 'Blausee-Mitholz',
    latitude: 46.5275,
    longitude: 7.6843,
    id: 1764,
  },
  {
    location_type: 'zip_code',
    location: '3718',
    location_name: 'Kandersteg',
    latitude: 46.4655,
    longitude: 7.7137,
    id: 1765,
  },
  {
    location_type: 'zip_code',
    location: '3722',
    location_name: 'Scharnachtal',
    latitude: 46.6117,
    longitude: 7.7136,
    id: 1766,
  },
  {
    location_type: 'zip_code',
    location: '3723',
    location_name: 'Kiental',
    latitude: 46.5558,
    longitude: 7.7579,
    id: 1767,
  },
  {
    location_type: 'zip_code',
    location: '3724',
    location_name: 'Ried (Frutigen)',
    latitude: 46.5615,
    longitude: 7.5921,
    id: 1768,
  },
  {
    location_type: 'zip_code',
    location: '3725',
    location_name: 'Achseten',
    latitude: 46.5279,
    longitude: 7.5976,
    id: 1769,
  },
  {
    location_type: 'zip_code',
    location: '3706',
    location_name: 'Leissigen',
    latitude: 46.6428,
    longitude: 7.777,
    id: 1770,
  },
  {
    location_type: 'zip_code',
    location: '3707',
    location_name: 'D\u00e4rligen',
    latitude: 46.6518,
    longitude: 7.8103,
    id: 1771,
  },
  {
    location_type: 'zip_code',
    location: '3800',
    location_name: 'Interlaken',
    latitude: 46.6843,
    longitude: 7.8786,
    id: 1772,
  },
  {
    location_type: 'zip_code',
    location: '3803',
    location_name: 'Beatenberg',
    latitude: 46.7093,
    longitude: 7.8007,
    id: 1773,
  },
  {
    location_type: 'zip_code',
    location: '3804',
    location_name: 'Habkern',
    latitude: 46.753,
    longitude: 7.8897,
    id: 1774,
  },
  {
    location_type: 'zip_code',
    location: '3805',
    location_name: 'Goldswil b. Interlaken',
    latitude: 46.7014,
    longitude: 7.8747,
    id: 1775,
  },
  {
    location_type: 'zip_code',
    location: '3806',
    location_name: 'B\u00f6nigen b. Interlaken',
    latitude: 46.6775,
    longitude: 7.9197,
    id: 1776,
  },
  {
    location_type: 'zip_code',
    location: '3807',
    location_name: 'Iseltwald',
    latitude: 46.7014,
    longitude: 7.9821,
    id: 1777,
  },
  {
    location_type: 'zip_code',
    location: '3812',
    location_name: 'Wilderswil',
    latitude: 46.6384,
    longitude: 7.8715,
    id: 1778,
  },
  {
    location_type: 'zip_code',
    location: '3813',
    location_name: 'Saxeten',
    latitude: 46.6238,
    longitude: 7.8257,
    id: 1779,
  },
  {
    location_type: 'zip_code',
    location: '3814',
    location_name: 'Gsteigwiler',
    latitude: 46.6503,
    longitude: 7.8898,
    id: 1780,
  },
  {
    location_type: 'zip_code',
    location: '3815',
    location_name: 'Zweil\u00fctschinen',
    latitude: 46.6231,
    longitude: 7.9048,
    id: 1781,
  },
  {
    location_type: 'zip_code',
    location: '3816',
    location_name: 'L\u00fctschental',
    latitude: 46.6421,
    longitude: 7.9525,
    id: 1782,
  },
  {
    location_type: 'zip_code',
    location: '3818',
    location_name: 'Grindelwald',
    latitude: 46.6156,
    longitude: 8.0406,
    id: 1783,
  },
  {
    location_type: 'zip_code',
    location: '3822',
    location_name: 'Lauterbrunnen',
    latitude: 46.591,
    longitude: 7.9038,
    id: 1784,
  },
  {
    location_type: 'zip_code',
    location: '3823',
    location_name: 'Kleine Scheidegg',
    latitude: 46.5846,
    longitude: 7.9602,
    id: 1785,
  },
  {
    location_type: 'zip_code',
    location: '3824',
    location_name: 'Stechelberg',
    latitude: 46.5287,
    longitude: 7.9233,
    id: 1786,
  },
  {
    location_type: 'zip_code',
    location: '3825',
    location_name: 'M\u00fcrren',
    latitude: 46.5719,
    longitude: 7.8616,
    id: 1787,
  },
  {
    location_type: 'zip_code',
    location: '3826',
    location_name: 'Gimmelwald',
    latitude: 46.5325,
    longitude: 7.8507,
    id: 1788,
  },
  {
    location_type: 'zip_code',
    location: '3852',
    location_name: 'Ringgenberg BE',
    latitude: 46.7095,
    longitude: 7.897,
    id: 1789,
  },
  {
    location_type: 'zip_code',
    location: '3853',
    location_name: 'Niederried b. Interlaken',
    latitude: 46.725,
    longitude: 7.9249,
    id: 1790,
  },
  {
    location_type: 'zip_code',
    location: '3854',
    location_name: 'Oberried am Brienzersee',
    latitude: 46.7608,
    longitude: 7.9583,
    id: 1791,
  },
  {
    location_type: 'zip_code',
    location: '3855',
    location_name: 'Schwanden b. Brienz',
    latitude: 46.7713,
    longitude: 8.0546,
    id: 1792,
  },
  {
    location_type: 'zip_code',
    location: '3856',
    location_name: 'Brienzwiler',
    latitude: 46.7603,
    longitude: 8.1042,
    id: 1793,
  },
  {
    location_type: 'zip_code',
    location: '3858',
    location_name: 'Hofstetten b. Brienz',
    latitude: 46.7666,
    longitude: 8.084,
    id: 1794,
  },
  {
    location_type: 'zip_code',
    location: '3075',
    location_name: 'R\u00fcfenacht BE',
    latitude: 46.9215,
    longitude: 7.5411,
    id: 1795,
  },
  {
    location_type: 'zip_code',
    location: '3076',
    location_name: 'Worb',
    latitude: 46.9308,
    longitude: 7.5645,
    id: 1796,
  },
  {
    location_type: 'zip_code',
    location: '3077',
    location_name: 'Enggistein',
    latitude: 46.9291,
    longitude: 7.6014,
    id: 1797,
  },
  {
    location_type: 'zip_code',
    location: '3078',
    location_name: 'Richigen',
    latitude: 46.9172,
    longitude: 7.5839,
    id: 1798,
  },
  {
    location_type: 'zip_code',
    location: '3082',
    location_name: 'Schlosswil',
    latitude: 46.9113,
    longitude: 7.6118,
    id: 1799,
  },
  {
    location_type: 'zip_code',
    location: '3083',
    location_name: 'Trimstein',
    latitude: 46.9021,
    longitude: 7.5798,
    id: 1800,
  },
  {
    location_type: 'zip_code',
    location: '3110',
    location_name: 'M\u00fcnsingen',
    latitude: 46.8764,
    longitude: 7.56,
    id: 1801,
  },
  {
    location_type: 'zip_code',
    location: '3111',
    location_name: 'T\u00e4gertschi',
    latitude: 46.8737,
    longitude: 7.587,
    id: 1802,
  },
  {
    location_type: 'zip_code',
    location: '3112',
    location_name: 'Allmendingen b. Bern',
    latitude: 46.9177,
    longitude: 7.5197,
    id: 1803,
  },
  {
    location_type: 'zip_code',
    location: '3113',
    location_name: 'Rubigen',
    latitude: 46.8947,
    longitude: 7.5407,
    id: 1804,
  },
  {
    location_type: 'zip_code',
    location: '3114',
    location_name: 'Wichtrach',
    latitude: 46.8457,
    longitude: 7.5818,
    id: 1805,
  },
  {
    location_type: 'zip_code',
    location: '3434',
    location_name: 'Landiswil',
    latitude: 46.9577,
    longitude: 7.6855,
    id: 1806,
  },
  {
    location_type: 'zip_code',
    location: '3503',
    location_name: 'Gysenstein',
    latitude: 46.896,
    longitude: 7.5959,
    id: 1807,
  },
  {
    location_type: 'zip_code',
    location: '3504',
    location_name: 'Niederh\u00fcnigen',
    latitude: 46.8648,
    longitude: 7.6484,
    id: 1808,
  },
  {
    location_type: 'zip_code',
    location: '3506',
    location_name: 'Grossh\u00f6chstetten',
    latitude: 46.9075,
    longitude: 7.6355,
    id: 1809,
  },
  {
    location_type: 'zip_code',
    location: '3507',
    location_name: 'Biglen',
    latitude: 46.9304,
    longitude: 7.6283,
    id: 1810,
  },
  {
    location_type: 'zip_code',
    location: '3508',
    location_name: 'Arni BE',
    latitude: 46.9342,
    longitude: 7.6635,
    id: 1811,
  },
  {
    location_type: 'zip_code',
    location: '3510',
    location_name: 'Freimettigen',
    latitude: 46.865,
    longitude: 7.6303,
    id: 1812,
  },
  {
    location_type: 'zip_code',
    location: '3512',
    location_name: 'Walkringen',
    latitude: 46.9484,
    longitude: 7.618,
    id: 1813,
  },
  {
    location_type: 'zip_code',
    location: '3513',
    location_name: 'Bigenthal',
    latitude: 46.972,
    longitude: 7.6341,
    id: 1814,
  },
  {
    location_type: 'zip_code',
    location: '3531',
    location_name: 'Oberthal',
    latitude: 46.9222,
    longitude: 7.6819,
    id: 1815,
  },
  {
    location_type: 'zip_code',
    location: '3532',
    location_name: 'Z\u00e4ziwil',
    latitude: 46.8956,
    longitude: 7.6672,
    id: 1816,
  },
  {
    location_type: 'zip_code',
    location: '3533',
    location_name: 'Bowil',
    latitude: 46.8862,
    longitude: 7.7022,
    id: 1817,
  },
  {
    location_type: 'zip_code',
    location: '3629',
    location_name: 'Oppligen',
    latitude: 46.8151,
    longitude: 7.5954,
    id: 1818,
  },
  {
    location_type: 'zip_code',
    location: '3671',
    location_name: 'Herbligen',
    latitude: 46.8284,
    longitude: 7.6053,
    id: 1819,
  },
  {
    location_type: 'zip_code',
    location: '3672',
    location_name: 'Aeschlen b. Oberdiessbach',
    latitude: 46.8326,
    longitude: 7.6532,
    id: 1820,
  },
  {
    location_type: 'zip_code',
    location: '3673',
    location_name: 'Linden',
    latitude: 46.8488,
    longitude: 7.6837,
    id: 1821,
  },
  {
    location_type: 'zip_code',
    location: '3674',
    location_name: 'Bleiken b. Oberdiessbach',
    latitude: 46.8168,
    longitude: 7.6402,
    id: 1822,
  },
  {
    location_type: 'zip_code',
    location: '1595',
    location_name: 'Clavaleyres',
    latitude: 46.8982,
    longitude: 7.0932,
    id: 1823,
  },
  {
    location_type: 'zip_code',
    location: '1797',
    location_name: 'M\u00fcnchenwiler',
    latitude: 46.9098,
    longitude: 7.1255,
    id: 1824,
  },
  {
    location_type: 'zip_code',
    location: '3176',
    location_name: 'Neuenegg',
    latitude: 46.8999,
    longitude: 7.2983,
    id: 1825,
  },
  {
    location_type: 'zip_code',
    location: '3177',
    location_name: 'Laupen BE',
    latitude: 46.9063,
    longitude: 7.2483,
    id: 1826,
  },
  {
    location_type: 'zip_code',
    location: '3179',
    location_name: 'Kriechenwil',
    latitude: 46.9137,
    longitude: 7.2111,
    id: 1827,
  },
  {
    location_type: 'zip_code',
    location: '3202',
    location_name: 'Frauenkappelen',
    latitude: 46.9548,
    longitude: 7.3326,
    id: 1828,
  },
  {
    location_type: 'zip_code',
    location: '3203',
    location_name: 'M\u00fchleberg',
    latitude: 46.9629,
    longitude: 7.2759,
    id: 1829,
  },
  {
    location_type: 'zip_code',
    location: '3204',
    location_name: 'Rossh\u00e4usern',
    latitude: 46.9294,
    longitude: 7.2728,
    id: 1830,
  },
  {
    location_type: 'zip_code',
    location: '3205',
    location_name: 'G\u00fcmmenen',
    latitude: 46.938,
    longitude: 7.2422,
    id: 1831,
  },
  {
    location_type: 'zip_code',
    location: '3206',
    location_name: 'Gammen',
    latitude: 46.9205,
    longitude: 7.2257,
    id: 1832,
  },
  {
    location_type: 'zip_code',
    location: '3207',
    location_name: 'Golaten',
    latitude: 46.9858,
    longitude: 7.2358,
    id: 1833,
  },
  {
    location_type: 'zip_code',
    location: '3208',
    location_name: 'Gurbr\u00fc',
    latitude: 46.9669,
    longitude: 7.2168,
    id: 1834,
  },
  {
    location_type: 'zip_code',
    location: '2710',
    location_name: 'Tavannes',
    latitude: 47.2202,
    longitude: 7.1937,
    id: 1835,
  },
  {
    location_type: 'zip_code',
    location: '2712',
    location_name: 'Le Fuet',
    latitude: 47.2486,
    longitude: 7.1765,
    id: 1836,
  },
  {
    location_type: 'zip_code',
    location: '2713',
    location_name: 'Bellelay',
    latitude: 47.2641,
    longitude: 7.1632,
    id: 1837,
  },
  {
    location_type: 'zip_code',
    location: '2715',
    location_name: 'Ch\u00c2telat',
    latitude: 47.2697,
    longitude: 7.1895,
    id: 1838,
  },
  {
    location_type: 'zip_code',
    location: '2716',
    location_name: 'Sornetan',
    latitude: 47.2757,
    longitude: 7.2156,
    id: 1839,
  },
  {
    location_type: 'zip_code',
    location: '2717',
    location_name: 'Reb\u00e8velier',
    latitude: 47.2894,
    longitude: 7.1874,
    id: 1840,
  },
  {
    location_type: 'zip_code',
    location: '2732',
    location_name: 'Loveresse',
    latitude: 47.24,
    longitude: 7.2438,
    id: 1841,
  },
  {
    location_type: 'zip_code',
    location: '2733',
    location_name: 'Pontenet',
    latitude: 47.2464,
    longitude: 7.2584,
    id: 1842,
  },
  {
    location_type: 'zip_code',
    location: '2735',
    location_name: 'Malleray',
    latitude: 47.2337,
    longitude: 7.2665,
    id: 1843,
  },
  {
    location_type: 'zip_code',
    location: '2736',
    location_name: 'Sorvilier',
    latitude: 47.2329,
    longitude: 7.3091,
    id: 1844,
  },
  {
    location_type: 'zip_code',
    location: '2738',
    location_name: 'Court',
    latitude: 47.243,
    longitude: 7.3945,
    id: 1845,
  },
  {
    location_type: 'zip_code',
    location: '2740',
    location_name: 'Moutier',
    latitude: 47.2786,
    longitude: 7.3702,
    id: 1846,
  },
  {
    location_type: 'zip_code',
    location: '2742',
    location_name: 'Perrefitte',
    latitude: 47.2782,
    longitude: 7.3219,
    id: 1847,
  },
  {
    location_type: 'zip_code',
    location: '2743',
    location_name: 'Eschert',
    latitude: 47.2662,
    longitude: 7.4122,
    id: 1848,
  },
  {
    location_type: 'zip_code',
    location: '2744',
    location_name: 'Belprahon',
    latitude: 47.2902,
    longitude: 7.4054,
    id: 1849,
  },
  {
    location_type: 'zip_code',
    location: '2745',
    location_name: 'Grandval',
    latitude: 47.283,
    longitude: 7.4284,
    id: 1850,
  },
  {
    location_type: 'zip_code',
    location: '2746',
    location_name: 'Cr\u00e8mines',
    latitude: 47.2732,
    longitude: 7.4548,
    id: 1851,
  },
  {
    location_type: 'zip_code',
    location: '2747',
    location_name: 'Seehof',
    latitude: 47.3052,
    longitude: 7.5239,
    id: 1852,
  },
  {
    location_type: 'zip_code',
    location: '2748',
    location_name: 'Souboz',
    latitude: 47.2723,
    longitude: 7.2626,
    id: 1853,
  },
  {
    location_type: 'zip_code',
    location: '2762',
    location_name: 'Roches BE',
    latitude: 47.3037,
    longitude: 7.3889,
    id: 1854,
  },
  {
    location_type: 'zip_code',
    location: '2827',
    location_name: 'Schelten',
    latitude: 47.3386,
    longitude: 7.5617,
    id: 1855,
  },
  {
    location_type: 'zip_code',
    location: '2515',
    location_name: 'Pr\u00eales',
    latitude: 47.097,
    longitude: 7.1196,
    id: 1856,
  },
  {
    location_type: 'zip_code',
    location: '2516',
    location_name: 'Lamboing',
    latitude: 47.1265,
    longitude: 7.1593,
    id: 1857,
  },
  {
    location_type: 'zip_code',
    location: '2517',
    location_name: 'Diesse',
    latitude: 47.1193,
    longitude: 7.121,
    id: 1858,
  },
  {
    location_type: 'zip_code',
    location: '2518',
    location_name: 'Nods',
    latitude: 47.1217,
    longitude: 7.0592,
    id: 1859,
  },
  {
    location_type: 'zip_code',
    location: '2520',
    location_name: 'La Neuveville',
    latitude: 47.0696,
    longitude: 7.0892,
    id: 1860,
  },
  {
    location_type: 'zip_code',
    location: '2514',
    location_name: 'Ligerz',
    latitude: 47.0891,
    longitude: 7.1382,
    id: 1861,
  },
  {
    location_type: 'zip_code',
    location: '2552',
    location_name: 'Orpund',
    latitude: 47.1418,
    longitude: 7.3013,
    id: 1862,
  },
  {
    location_type: 'zip_code',
    location: '2553',
    location_name: 'Safnern',
    latitude: 47.1534,
    longitude: 7.3199,
    id: 1863,
  },
  {
    location_type: 'zip_code',
    location: '2555',
    location_name: 'Br\u00fcgg BE',
    latitude: 47.1278,
    longitude: 7.2825,
    id: 1864,
  },
  {
    location_type: 'zip_code',
    location: '2556',
    location_name: 'Schwadernau',
    latitude: 47.1243,
    longitude: 7.3097,
    id: 1865,
  },
  {
    location_type: 'zip_code',
    location: '2557',
    location_name: 'Studen BE',
    latitude: 47.1136,
    longitude: 7.306,
    id: 1866,
  },
  {
    location_type: 'zip_code',
    location: '2558',
    location_name: 'Aegerten',
    latitude: 47.1176,
    longitude: 7.2832,
    id: 1867,
  },
  {
    location_type: 'zip_code',
    location: '2560',
    location_name: 'Nidau',
    latitude: 47.1242,
    longitude: 7.2403,
    id: 1868,
  },
  {
    location_type: 'zip_code',
    location: '2562',
    location_name: 'Port',
    latitude: 47.1137,
    longitude: 7.2582,
    id: 1869,
  },
  {
    location_type: 'zip_code',
    location: '2563',
    location_name: 'Ipsach',
    latitude: 47.1151,
    longitude: 7.2316,
    id: 1870,
  },
  {
    location_type: 'zip_code',
    location: '2564',
    location_name: 'Bellmund',
    latitude: 47.1025,
    longitude: 7.2476,
    id: 1871,
  },
  {
    location_type: 'zip_code',
    location: '2565',
    location_name: 'Jens',
    latitude: 47.0966,
    longitude: 7.2688,
    id: 1872,
  },
  {
    location_type: 'zip_code',
    location: '2572',
    location_name: 'M\u00f6rigen',
    latitude: 47.0846,
    longitude: 7.2125,
    id: 1873,
  },
  {
    location_type: 'zip_code',
    location: '2575',
    location_name: 'Gerolfingen',
    latitude: 47.0765,
    longitude: 7.2005,
    id: 1874,
  },
  {
    location_type: 'zip_code',
    location: '3252',
    location_name: 'Worben',
    latitude: 47.0987,
    longitude: 7.2932,
    id: 1875,
  },
  {
    location_type: 'zip_code',
    location: '3272',
    location_name: 'Walperswil',
    latitude: 47.053,
    longitude: 7.226,
    id: 1876,
  },
  {
    location_type: 'zip_code',
    location: '3274',
    location_name: 'B\u00fchl b. Aarberg',
    latitude: 47.0665,
    longitude: 7.2463,
    id: 1877,
  },
  {
    location_type: 'zip_code',
    location: '3632',
    location_name: 'Oberstocken',
    latitude: 46.7072,
    longitude: 7.5499,
    id: 1878,
  },
  {
    location_type: 'zip_code',
    location: '3646',
    location_name: 'Einigen',
    latitude: 46.7034,
    longitude: 7.6483,
    id: 1879,
  },
  {
    location_type: 'zip_code',
    location: '3647',
    location_name: 'Reutigen',
    latitude: 46.6937,
    longitude: 7.605,
    id: 1880,
  },
  {
    location_type: 'zip_code',
    location: '3700',
    location_name: 'Spiez',
    latitude: 46.6859,
    longitude: 7.6754,
    id: 1881,
  },
  {
    location_type: 'zip_code',
    location: '3702',
    location_name: 'Hondrich',
    latitude: 46.6725,
    longitude: 7.6815,
    id: 1882,
  },
  {
    location_type: 'zip_code',
    location: '3705',
    location_name: 'Faulensee',
    latitude: 46.672,
    longitude: 7.7042,
    id: 1883,
  },
  {
    location_type: 'zip_code',
    location: '3752',
    location_name: 'Wimmis',
    latitude: 46.6649,
    longitude: 7.645,
    id: 1884,
  },
  {
    location_type: 'zip_code',
    location: '3753',
    location_name: 'Oey',
    latitude: 46.6505,
    longitude: 7.5991,
    id: 1885,
  },
  {
    location_type: 'zip_code',
    location: '3754',
    location_name: 'Diemtigen',
    latitude: 46.6478,
    longitude: 7.5559,
    id: 1886,
  },
  {
    location_type: 'zip_code',
    location: '3755',
    location_name: 'Horboden',
    latitude: 46.6121,
    longitude: 7.5717,
    id: 1887,
  },
  {
    location_type: 'zip_code',
    location: '3756',
    location_name: 'Zwischenfl\u00fch',
    latitude: 46.5992,
    longitude: 7.5229,
    id: 1888,
  },
  {
    location_type: 'zip_code',
    location: '3757',
    location_name: 'Schwenden im Diemtigtal',
    latitude: 46.5573,
    longitude: 7.5084,
    id: 1889,
  },
  {
    location_type: 'zip_code',
    location: '3758',
    location_name: 'Latterbach',
    latitude: 46.6727,
    longitude: 7.5938,
    id: 1890,
  },
  {
    location_type: 'zip_code',
    location: '3762',
    location_name: 'Erlenbach im Simmental',
    latitude: 46.6559,
    longitude: 7.5216,
    id: 1891,
  },
  {
    location_type: 'zip_code',
    location: '3763',
    location_name: 'D\u00e4rstetten',
    latitude: 46.666,
    longitude: 7.5089,
    id: 1892,
  },
  {
    location_type: 'zip_code',
    location: '3764',
    location_name: 'Weissenburg',
    latitude: 46.6792,
    longitude: 7.4757,
    id: 1893,
  },
  {
    location_type: 'zip_code',
    location: '3765',
    location_name: 'Oberwil im Simmental',
    latitude: 46.6655,
    longitude: 7.4228,
    id: 1894,
  },
  {
    location_type: 'zip_code',
    location: '3857',
    location_name: 'Unterbach BE',
    latitude: 46.7378,
    longitude: 8.1223,
    id: 1895,
  },
  {
    location_type: 'zip_code',
    location: '3860',
    location_name: 'Rosenlaui',
    latitude: 46.6797,
    longitude: 8.1536,
    id: 1896,
  },
  {
    location_type: 'zip_code',
    location: '3862',
    location_name: 'Innertkirchen',
    latitude: 46.6871,
    longitude: 8.2385,
    id: 1897,
  },
  {
    location_type: 'zip_code',
    location: '3863',
    location_name: 'Gadmen',
    latitude: 46.7006,
    longitude: 8.3639,
    id: 1898,
  },
  {
    location_type: 'zip_code',
    location: '3864',
    location_name: 'Guttannen',
    latitude: 46.6077,
    longitude: 8.3041,
    id: 1899,
  },
  {
    location_type: 'zip_code',
    location: '6083',
    location_name: 'Hasliberg Hohfluh',
    latitude: 46.7546,
    longitude: 8.1647,
    id: 1900,
  },
  {
    location_type: 'zip_code',
    location: '6084',
    location_name: 'Hasliberg Wasserwendi',
    latitude: 46.7546,
    longitude: 8.2105,
    id: 1901,
  },
  {
    location_type: 'zip_code',
    location: '6085',
    location_name: 'Hasliberg Goldern',
    latitude: 46.7383,
    longitude: 8.1967,
    id: 1902,
  },
  {
    location_type: 'zip_code',
    location: '6086',
    location_name: 'Hasliberg Reuti',
    latitude: 46.7422,
    longitude: 8.2517,
    id: 1903,
  },
  {
    location_type: 'zip_code',
    location: '3766',
    location_name: 'Boltigen',
    latitude: 46.6047,
    longitude: 7.3573,
    id: 1904,
  },
  {
    location_type: 'zip_code',
    location: '3770',
    location_name: 'Zweisimmen',
    latitude: 46.5704,
    longitude: 7.3913,
    id: 1905,
  },
  {
    location_type: 'zip_code',
    location: '3771',
    location_name: 'Blankenburg',
    latitude: 46.537,
    longitude: 7.4141,
    id: 1906,
  },
  {
    location_type: 'zip_code',
    location: '3772',
    location_name: 'St. Stephan',
    latitude: 46.4909,
    longitude: 7.3899,
    id: 1907,
  },
  {
    location_type: 'zip_code',
    location: '3773',
    location_name: 'Matten (St. Stephan)',
    latitude: 46.5098,
    longitude: 7.4718,
    id: 1908,
  },
  {
    location_type: 'zip_code',
    location: '3775',
    location_name: 'Lenk im Simmental',
    latitude: 46.4339,
    longitude: 7.4645,
    id: 1909,
  },
  {
    location_type: 'zip_code',
    location: '3776',
    location_name: 'Oeschseite',
    latitude: 46.5263,
    longitude: 7.3345,
    id: 1910,
  },
  {
    location_type: 'zip_code',
    location: '1657',
    location_name: 'Abl\u00e4ndschen',
    latitude: 46.5726,
    longitude: 7.2814,
    id: 1911,
  },
  {
    location_type: 'zip_code',
    location: '3777',
    location_name: 'Saanenm\u00f6ser',
    latitude: 46.5129,
    longitude: 7.3137,
    id: 1912,
  },
  {
    location_type: 'zip_code',
    location: '3778',
    location_name: 'Sch\u00f6nried',
    latitude: 46.5072,
    longitude: 7.2785,
    id: 1913,
  },
  {
    location_type: 'zip_code',
    location: '3780',
    location_name: 'Gstaad',
    latitude: 46.4756,
    longitude: 7.2943,
    id: 1914,
  },
  {
    location_type: 'zip_code',
    location: '3781',
    location_name: 'Turbach',
    latitude: 46.4646,
    longitude: 7.3461,
    id: 1915,
  },
  {
    location_type: 'zip_code',
    location: '3782',
    location_name: 'Lauenen b. Gstaad',
    latitude: 46.3935,
    longitude: 7.3422,
    id: 1916,
  },
  {
    location_type: 'zip_code',
    location: '3783',
    location_name: 'Grund b. Gstaad',
    latitude: 46.4383,
    longitude: 7.2433,
    id: 1917,
  },
  {
    location_type: 'zip_code',
    location: '3784',
    location_name: 'Feutersoey',
    latitude: 46.4156,
    longitude: 7.2473,
    id: 1918,
  },
  {
    location_type: 'zip_code',
    location: '3785',
    location_name: 'Gsteig b. Gstaad',
    latitude: 46.3668,
    longitude: 7.2521,
    id: 1919,
  },
  {
    location_type: 'zip_code',
    location: '3792',
    location_name: 'Saanen',
    latitude: 46.478,
    longitude: 7.2522,
    id: 1920,
  },
  {
    location_type: 'zip_code',
    location: '1738',
    location_name: 'Sangernboden',
    latitude: 46.6962,
    longitude: 7.3577,
    id: 1921,
  },
  {
    location_type: 'zip_code',
    location: '3148',
    location_name: 'Lanzenh\u00e4usern',
    latitude: 46.8447,
    longitude: 7.3611,
    id: 1922,
  },
  {
    location_type: 'zip_code',
    location: '3150',
    location_name: 'Schwarzenburg',
    latitude: 46.8137,
    longitude: 7.3315,
    id: 1923,
  },
  {
    location_type: 'zip_code',
    location: '3152',
    location_name: 'Mamishaus',
    latitude: 46.8162,
    longitude: 7.3775,
    id: 1924,
  },
  {
    location_type: 'zip_code',
    location: '3153',
    location_name: 'R\u00fcschegg Gambach',
    latitude: 46.7713,
    longitude: 7.3819,
    id: 1925,
  },
  {
    location_type: 'zip_code',
    location: '3154',
    location_name: 'R\u00fcschegg Heubach',
    latitude: 46.7701,
    longitude: 7.4199,
    id: 1926,
  },
  {
    location_type: 'zip_code',
    location: '3156',
    location_name: 'Riffenmatt',
    latitude: 46.7638,
    longitude: 7.3541,
    id: 1927,
  },
  {
    location_type: 'zip_code',
    location: '3157',
    location_name: 'Milken',
    latitude: 46.7944,
    longitude: 7.3651,
    id: 1928,
  },
  {
    location_type: 'zip_code',
    location: '3158',
    location_name: 'Guggisberg',
    latitude: 46.7594,
    longitude: 7.3197,
    id: 1929,
  },
  {
    location_type: 'zip_code',
    location: '3159',
    location_name: 'Riedst\u00e4tt',
    latitude: 46.7839,
    longitude: 7.3189,
    id: 1930,
  },
  {
    location_type: 'zip_code',
    location: '3183',
    location_name: 'Albligen',
    latitude: 46.8488,
    longitude: 7.3226,
    id: 1931,
  },
  {
    location_type: 'zip_code',
    location: '3086',
    location_name: 'Englisberg',
    latitude: 46.8957,
    longitude: 7.4636,
    id: 1932,
  },
  {
    location_type: 'zip_code',
    location: '3087',
    location_name: 'Niedermuhlern',
    latitude: 46.86,
    longitude: 7.4573,
    id: 1933,
  },
  {
    location_type: 'zip_code',
    location: '3088',
    location_name: 'R\u00fceggisberg',
    latitude: 46.8242,
    longitude: 7.4381,
    id: 1934,
  },
  {
    location_type: 'zip_code',
    location: '3089',
    location_name: 'Hinterfultigen',
    latitude: 46.8378,
    longitude: 7.4047,
    id: 1935,
  },
  {
    location_type: 'zip_code',
    location: '3099',
    location_name: 'R\u00fcti b. Riggisberg',
    latitude: 46.7603,
    longitude: 7.4592,
    id: 1936,
  },
  {
    location_type: 'zip_code',
    location: '3115',
    location_name: 'Gerzensee',
    latitude: 46.849,
    longitude: 7.5414,
    id: 1937,
  },
  {
    location_type: 'zip_code',
    location: '3116',
    location_name: 'Noflen BE',
    latitude: 46.8017,
    longitude: 7.5416,
    id: 1938,
  },
  {
    location_type: 'zip_code',
    location: '3122',
    location_name: 'Kehrsatz',
    latitude: 46.9132,
    longitude: 7.4677,
    id: 1939,
  },
  {
    location_type: 'zip_code',
    location: '3123',
    location_name: 'Belp',
    latitude: 46.8933,
    longitude: 7.5074,
    id: 1940,
  },
  {
    location_type: 'zip_code',
    location: '3124',
    location_name: 'Belpberg',
    latitude: 46.8673,
    longitude: 7.5243,
    id: 1941,
  },
  {
    location_type: 'zip_code',
    location: '3125',
    location_name: 'Toffen',
    latitude: 46.8608,
    longitude: 7.4946,
    id: 1942,
  },
  {
    location_type: 'zip_code',
    location: '3126',
    location_name: 'Kaufdorf',
    latitude: 46.8403,
    longitude: 7.4946,
    id: 1943,
  },
  {
    location_type: 'zip_code',
    location: '3127',
    location_name: 'M\u00fchlethurnen',
    latitude: 46.8141,
    longitude: 7.5102,
    id: 1944,
  },
  {
    location_type: 'zip_code',
    location: '3128',
    location_name: 'Kirchenthurnen',
    latitude: 46.8243,
    longitude: 7.5041,
    id: 1945,
  },
  {
    location_type: 'zip_code',
    location: '3132',
    location_name: 'Riggisberg',
    latitude: 46.8067,
    longitude: 7.4664,
    id: 1946,
  },
  {
    location_type: 'zip_code',
    location: '3155',
    location_name: 'Helgisried-Rohrbach',
    latitude: 46.8111,
    longitude: 7.4172,
    id: 1947,
  },
  {
    location_type: 'zip_code',
    location: '3628',
    location_name: 'Kienersr\u00fcti',
    latitude: 46.7998,
    longitude: 7.56,
    id: 1948,
  },
  {
    location_type: 'zip_code',
    location: '3662',
    location_name: 'Seftigen',
    latitude: 46.7909,
    longitude: 7.5412,
    id: 1949,
  },
  {
    location_type: 'zip_code',
    location: '3663',
    location_name: 'Gurzelen',
    latitude: 46.7718,
    longitude: 7.5353,
    id: 1950,
  },
  {
    location_type: 'zip_code',
    location: '3664',
    location_name: 'Burgistein',
    latitude: 46.7907,
    longitude: 7.5036,
    id: 1951,
  },
  {
    location_type: 'zip_code',
    location: '3665',
    location_name: 'Wattenwil',
    latitude: 46.7589,
    longitude: 7.4886,
    id: 1952,
  },
  {
    location_type: 'zip_code',
    location: '3433',
    location_name: 'Schwanden im Emmental',
    latitude: 46.9755,
    longitude: 7.6927,
    id: 1953,
  },
  {
    location_type: 'zip_code',
    location: '3436',
    location_name: 'Zollbr\u00fcck',
    latitude: 46.9727,
    longitude: 7.7607,
    id: 1954,
  },
  {
    location_type: 'zip_code',
    location: '3437',
    location_name: 'R\u00fcderswil',
    latitude: 46.9864,
    longitude: 7.7136,
    id: 1955,
  },
  {
    location_type: 'zip_code',
    location: '3438',
    location_name: 'Lauperswil',
    latitude: 46.9614,
    longitude: 7.7278,
    id: 1956,
  },
  {
    location_type: 'zip_code',
    location: '3439',
    location_name: 'Ranfl\u00fch',
    latitude: 46.9893,
    longitude: 7.7461,
    id: 1957,
  },
  {
    location_type: 'zip_code',
    location: '3534',
    location_name: 'Signau',
    latitude: 46.9096,
    longitude: 7.7318,
    id: 1958,
  },
  {
    location_type: 'zip_code',
    location: '3535',
    location_name: 'Sch\u00fcpbach',
    latitude: 46.9305,
    longitude: 7.7447,
    id: 1959,
  },
  {
    location_type: 'zip_code',
    location: '3536',
    location_name: 'Aeschau',
    latitude: 46.9062,
    longitude: 7.7839,
    id: 1960,
  },
  {
    location_type: 'zip_code',
    location: '3537',
    location_name: 'Eggiwil',
    latitude: 46.8557,
    longitude: 7.8063,
    id: 1961,
  },
  {
    location_type: 'zip_code',
    location: '3538',
    location_name: 'R\u00f6thenbach im Emmental',
    latitude: 46.8538,
    longitude: 7.7442,
    id: 1962,
  },
  {
    location_type: 'zip_code',
    location: '3543',
    location_name: 'Emmenmatt',
    latitude: 46.9447,
    longitude: 7.7251,
    id: 1963,
  },
  {
    location_type: 'zip_code',
    location: '3550',
    location_name: 'Langnau im Emmental',
    latitude: 46.9349,
    longitude: 7.7862,
    id: 1964,
  },
  {
    location_type: 'zip_code',
    location: '3551',
    location_name: 'Oberfrittenbach',
    latitude: 46.975,
    longitude: 7.7964,
    id: 1965,
  },
  {
    location_type: 'zip_code',
    location: '3552',
    location_name: 'B\u00e4rau',
    latitude: 46.936,
    longitude: 7.8248,
    id: 1966,
  },
  {
    location_type: 'zip_code',
    location: '3553',
    location_name: 'Gohl',
    latitude: 46.982,
    longitude: 7.8318,
    id: 1967,
  },
  {
    location_type: 'zip_code',
    location: '3555',
    location_name: 'Trubschachen',
    latitude: 46.9043,
    longitude: 7.8692,
    id: 1968,
  },
  {
    location_type: 'zip_code',
    location: '3556',
    location_name: 'Trub',
    latitude: 46.9642,
    longitude: 7.8847,
    id: 1969,
  },
  {
    location_type: 'zip_code',
    location: '3557',
    location_name: 'Fankhaus (Trub)',
    latitude: 46.9823,
    longitude: 7.9247,
    id: 1970,
  },
  {
    location_type: 'zip_code',
    location: '6197',
    location_name: 'Schangnau',
    latitude: 46.8085,
    longitude: 7.9233,
    id: 1971,
  },
  {
    location_type: 'zip_code',
    location: '3600',
    location_name: 'Thun',
    latitude: 46.7554,
    longitude: 7.6236,
    id: 1972,
  },
  {
    location_type: 'zip_code',
    location: '3601',
    location_name: 'Thun',
    latitude: 46.75,
    longitude: 7.6167,
    id: 1973,
  },
  {
    location_type: 'zip_code',
    location: '3602',
    location_name: 'Thun 2',
    latitude: 46.75,
    longitude: 7.6167,
    id: 1974,
  },
  {
    location_type: 'zip_code',
    location: '3603',
    location_name: 'Thun',
    latitude: 46.762,
    longitude: 7.6001,
    id: 1975,
  },
  {
    location_type: 'zip_code',
    location: '3604',
    location_name: 'Thun',
    latitude: 46.7405,
    longitude: 7.6207,
    id: 1976,
  },
  {
    location_type: 'zip_code',
    location: '3605',
    location_name: 'Thun',
    latitude: 46.75,
    longitude: 7.6167,
    id: 1977,
  },
  {
    location_type: 'zip_code',
    location: '3607',
    location_name: 'Thun',
    latitude: 46.75,
    longitude: 7.6167,
    id: 1978,
  },
  {
    location_type: 'zip_code',
    location: '3608',
    location_name: 'Thun',
    latitude: 46.7399,
    longitude: 7.5988,
    id: 1979,
  },
  {
    location_type: 'zip_code',
    location: '3609',
    location_name: 'Thun',
    latitude: 46.75,
    longitude: 7.6167,
    id: 1980,
  },
  {
    location_type: 'zip_code',
    location: '3612',
    location_name: 'Steffisburg',
    latitude: 46.7827,
    longitude: 7.6382,
    id: 1981,
  },
  {
    location_type: 'zip_code',
    location: '3613',
    location_name: 'Steffisburg',
    latitude: 46.771,
    longitude: 7.6145,
    id: 1982,
  },
  {
    location_type: 'zip_code',
    location: '3614',
    location_name: 'Unterlangenegg',
    latitude: 46.8,
    longitude: 7.6919,
    id: 1983,
  },
  {
    location_type: 'zip_code',
    location: '3615',
    location_name: 'Heimenschwand',
    latitude: 46.8243,
    longitude: 7.6897,
    id: 1984,
  },
  {
    location_type: 'zip_code',
    location: '3616',
    location_name: 'Schwarzenegg',
    latitude: 46.7992,
    longitude: 7.7379,
    id: 1985,
  },
  {
    location_type: 'zip_code',
    location: '3617',
    location_name: 'Fahrni b. Thun',
    latitude: 46.796,
    longitude: 7.658,
    id: 1986,
  },
  {
    location_type: 'zip_code',
    location: '3618',
    location_name: 'S\u00fcderen',
    latitude: 46.8217,
    longitude: 7.7608,
    id: 1987,
  },
  {
    location_type: 'zip_code',
    location: '3619',
    location_name: 'Innereriz',
    latitude: 46.7768,
    longitude: 7.8405,
    id: 1988,
  },
  {
    location_type: 'zip_code',
    location: '3622',
    location_name: 'Homberg b. Thun',
    latitude: 46.7752,
    longitude: 7.6829,
    id: 1989,
  },
  {
    location_type: 'zip_code',
    location: '3623',
    location_name: 'Horrenbach',
    latitude: 46.7777,
    longitude: 7.7446,
    id: 1990,
  },
  {
    location_type: 'zip_code',
    location: '3624',
    location_name: 'Schwendibach',
    latitude: 46.7708,
    longitude: 7.6595,
    id: 1991,
  },
  {
    location_type: 'zip_code',
    location: '3625',
    location_name: 'Heiligenschwendi',
    latitude: 46.7521,
    longitude: 7.6788,
    id: 1992,
  },
  {
    location_type: 'zip_code',
    location: '3626',
    location_name: 'H\u00fcnibach',
    latitude: 46.7484,
    longitude: 7.6487,
    id: 1993,
  },
  {
    location_type: 'zip_code',
    location: '3627',
    location_name: 'Heimberg',
    latitude: 46.7908,
    longitude: 7.6039,
    id: 1994,
  },
  {
    location_type: 'zip_code',
    location: '3631',
    location_name: 'H\u00f6fen b. Thun',
    latitude: 46.7215,
    longitude: 7.5641,
    id: 1995,
  },
  {
    location_type: 'zip_code',
    location: '3633',
    location_name: 'Amsoldingen',
    latitude: 46.729,
    longitude: 7.5813,
    id: 1996,
  },
  {
    location_type: 'zip_code',
    location: '3634',
    location_name: 'Thierachern',
    latitude: 46.7499,
    longitude: 7.5711,
    id: 1997,
  },
  {
    location_type: 'zip_code',
    location: '3635',
    location_name: 'Uebeschi',
    latitude: 46.7399,
    longitude: 7.5489,
    id: 1998,
  },
  {
    location_type: 'zip_code',
    location: '3636',
    location_name: 'L\u00e4ngenb\u00fchl',
    latitude: 46.7582,
    longitude: 7.5383,
    id: 1999,
  },
  {
    location_type: 'zip_code',
    location: '3638',
    location_name: 'Blumenstein',
    latitude: 46.729,
    longitude: 7.4936,
    id: 2000,
  },
  {
    location_type: 'zip_code',
    location: '3645',
    location_name: 'Gwatt (Thun)',
    latitude: 46.7241,
    longitude: 7.6217,
    id: 2001,
  },
  {
    location_type: 'zip_code',
    location: '3652',
    location_name: 'Hilterfingen',
    latitude: 46.7409,
    longitude: 7.6617,
    id: 2002,
  },
  {
    location_type: 'zip_code',
    location: '3653',
    location_name: 'Oberhofen am Thunersee',
    latitude: 46.7314,
    longitude: 7.6732,
    id: 2003,
  },
  {
    location_type: 'zip_code',
    location: '3654',
    location_name: 'Gunten',
    latitude: 46.7148,
    longitude: 7.7045,
    id: 2004,
  },
  {
    location_type: 'zip_code',
    location: '3655',
    location_name: 'Sigriswil',
    latitude: 46.7198,
    longitude: 7.7348,
    id: 2005,
  },
  {
    location_type: 'zip_code',
    location: '3656',
    location_name: 'Aeschlen ob Gunten',
    latitude: 46.7218,
    longitude: 7.6991,
    id: 2006,
  },
  {
    location_type: 'zip_code',
    location: '3657',
    location_name: 'Schwanden (Sigriswil)',
    latitude: 46.7443,
    longitude: 7.757,
    id: 2007,
  },
  {
    location_type: 'zip_code',
    location: '3658',
    location_name: 'Merligen',
    latitude: 46.7007,
    longitude: 7.7457,
    id: 2008,
  },
  {
    location_type: 'zip_code',
    location: '3661',
    location_name: 'Uetendorf',
    latitude: 46.7758,
    longitude: 7.5772,
    id: 2009,
  },
  {
    location_type: 'zip_code',
    location: '3416',
    location_name: 'Affoltern im Emmental',
    latitude: 47.068,
    longitude: 7.7246,
    id: 2010,
  },
  {
    location_type: 'zip_code',
    location: '3417',
    location_name: 'R\u00fcegsau',
    latitude: 47.0274,
    longitude: 7.6824,
    id: 2011,
  },
  {
    location_type: 'zip_code',
    location: '3418',
    location_name: 'R\u00fcegsbach',
    latitude: 47.0475,
    longitude: 7.6996,
    id: 2012,
  },
  {
    location_type: 'zip_code',
    location: '3432',
    location_name: 'L\u00fctzelfl\u00fch-Goldbach',
    latitude: 47.0064,
    longitude: 7.6847,
    id: 2013,
  },
  {
    location_type: 'zip_code',
    location: '3435',
    location_name: 'Ramsei',
    latitude: 47,
    longitude: 7.7094,
    id: 2014,
  },
  {
    location_type: 'zip_code',
    location: '3452',
    location_name: 'Gr\u00fcnenmatt',
    latitude: 47.0097,
    longitude: 7.7288,
    id: 2015,
  },
  {
    location_type: 'zip_code',
    location: '3453',
    location_name: 'Heimisbach',
    latitude: 47.0094,
    longitude: 7.7813,
    id: 2016,
  },
  {
    location_type: 'zip_code',
    location: '3454',
    location_name: 'Sumiswald',
    latitude: 47.0365,
    longitude: 7.7434,
    id: 2017,
  },
  {
    location_type: 'zip_code',
    location: '3455',
    location_name: 'Gr\u00fcnen',
    latitude: 47.0217,
    longitude: 7.7381,
    id: 2018,
  },
  {
    location_type: 'zip_code',
    location: '3456',
    location_name: 'Trachselwald',
    latitude: 47.014,
    longitude: 7.7484,
    id: 2019,
  },
  {
    location_type: 'zip_code',
    location: '3457',
    location_name: 'Wasen im Emmental',
    latitude: 47.0267,
    longitude: 7.8463,
    id: 2020,
  },
  {
    location_type: 'zip_code',
    location: '3462',
    location_name: 'Weier im Emmental',
    latitude: 47.0611,
    longitude: 7.7686,
    id: 2021,
  },
  {
    location_type: 'zip_code',
    location: '3463',
    location_name: 'H\u00e4usernmoos im Emmental',
    latitude: 47.0821,
    longitude: 7.75,
    id: 2022,
  },
  {
    location_type: 'zip_code',
    location: '3464',
    location_name: 'Schmidigen-M\u00fchleweg',
    latitude: 47.0935,
    longitude: 7.74,
    id: 2023,
  },
  {
    location_type: 'zip_code',
    location: '3465',
    location_name: 'D\u00fcrrenroth',
    latitude: 47.0802,
    longitude: 7.7848,
    id: 2024,
  },
  {
    location_type: 'zip_code',
    location: '4942',
    location_name: 'Walterswil BE',
    latitude: 47.1054,
    longitude: 7.7737,
    id: 2025,
  },
  {
    location_type: 'zip_code',
    location: '4950',
    location_name: 'Huttwil',
    latitude: 47.1101,
    longitude: 7.8526,
    id: 2026,
  },
  {
    location_type: 'zip_code',
    location: '4952',
    location_name: 'Eriswil',
    latitude: 47.0719,
    longitude: 7.8732,
    id: 2027,
  },
  {
    location_type: 'zip_code',
    location: '4953',
    location_name: 'Schwarzenbach (Huttwil)',
    latitude: 47.105,
    longitude: 7.8269,
    id: 2028,
  },
  {
    location_type: 'zip_code',
    location: '4954',
    location_name: 'Wyssachen',
    latitude: 47.0743,
    longitude: 7.8207,
    id: 2029,
  },
  {
    location_type: 'zip_code',
    location: '3360',
    location_name: 'Herzogenbuchsee',
    latitude: 47.1951,
    longitude: 7.7132,
    id: 2030,
  },
  {
    location_type: 'zip_code',
    location: '3362',
    location_name: 'Nieder\u00f6nz',
    latitude: 47.1883,
    longitude: 7.6843,
    id: 2031,
  },
  {
    location_type: 'zip_code',
    location: '3363',
    location_name: 'Ober\u00f6nz',
    latitude: 47.1745,
    longitude: 7.6888,
    id: 2032,
  },
  {
    location_type: 'zip_code',
    location: '3365',
    location_name: 'Seeberg',
    latitude: 47.1599,
    longitude: 7.6651,
    id: 2033,
  },
  {
    location_type: 'zip_code',
    location: '3366',
    location_name: 'Bettenhausen',
    latitude: 47.174,
    longitude: 7.7096,
    id: 2034,
  },
  {
    location_type: 'zip_code',
    location: '3367',
    location_name: 'Ochlenberg',
    latitude: 47.1522,
    longitude: 7.7403,
    id: 2035,
  },
  {
    location_type: 'zip_code',
    location: '3372',
    location_name: 'Wanzwil',
    latitude: 47.1987,
    longitude: 7.6908,
    id: 2036,
  },
  {
    location_type: 'zip_code',
    location: '3373',
    location_name: 'R\u00f6thenbach Herzogenbuchsee',
    latitude: 47.2056,
    longitude: 7.6827,
    id: 2037,
  },
  {
    location_type: 'zip_code',
    location: '3374',
    location_name: 'Wangenried',
    latitude: 47.2181,
    longitude: 7.6615,
    id: 2038,
  },
  {
    location_type: 'zip_code',
    location: '3375',
    location_name: 'Inkwil',
    latitude: 47.2019,
    longitude: 7.6628,
    id: 2039,
  },
  {
    location_type: 'zip_code',
    location: '3376',
    location_name: 'Graben',
    latitude: 47.2178,
    longitude: 7.7204,
    id: 2040,
  },
  {
    location_type: 'zip_code',
    location: '3377',
    location_name: 'Walliswil b. Wangen',
    latitude: 47.2277,
    longitude: 7.6861,
    id: 2041,
  },
  {
    location_type: 'zip_code',
    location: '3380',
    location_name: 'Wangen an der Aare',
    latitude: 47.2316,
    longitude: 7.6475,
    id: 2042,
  },
  {
    location_type: 'zip_code',
    location: '3475',
    location_name: 'Hermiswil',
    latitude: 47.1555,
    longitude: 7.699,
    id: 2043,
  },
  {
    location_type: 'zip_code',
    location: '3476',
    location_name: 'Oschwand',
    latitude: 47.1369,
    longitude: 7.722,
    id: 2044,
  },
  {
    location_type: 'zip_code',
    location: '4536',
    location_name: 'Attiswil',
    latitude: 47.2504,
    longitude: 7.608,
    id: 2045,
  },
  {
    location_type: 'zip_code',
    location: '4537',
    location_name: 'Wiedlisbach',
    latitude: 47.2473,
    longitude: 7.6475,
    id: 2046,
  },
  {
    location_type: 'zip_code',
    location: '4538',
    location_name: 'Oberbipp',
    latitude: 47.2527,
    longitude: 7.6739,
    id: 2047,
  },
  {
    location_type: 'zip_code',
    location: '4539',
    location_name: 'Farnern',
    latitude: 47.2697,
    longitude: 7.6073,
    id: 2048,
  },
  {
    location_type: 'zip_code',
    location: '4704',
    location_name: 'Wolfisberg',
    latitude: 47.2771,
    longitude: 7.6573,
    id: 2049,
  },
  {
    location_type: 'zip_code',
    location: '4023',
    location_name: 'Basel',
    latitude: 47.5186,
    longitude: 7.6174,
    id: 2050,
  },
  {
    location_type: 'zip_code',
    location: '4040',
    location_name: 'Basel',
    latitude: 47.5186,
    longitude: 7.6174,
    id: 2051,
  },
  {
    location_type: 'zip_code',
    location: '4042',
    location_name: 'Basel PF Operations Center',
    latitude: 47.5186,
    longitude: 7.6174,
    id: 2052,
  },
  {
    location_type: 'zip_code',
    location: '4101',
    location_name: 'Bruderholz',
    latitude: 47.528,
    longitude: 7.5805,
    id: 2053,
  },
  {
    location_type: 'zip_code',
    location: '4102',
    location_name: 'Binningen',
    latitude: 47.5367,
    longitude: 7.5677,
    id: 2054,
  },
  {
    location_type: 'zip_code',
    location: '4103',
    location_name: 'Bottmingen',
    latitude: 47.5211,
    longitude: 7.5775,
    id: 2055,
  },
  {
    location_type: 'zip_code',
    location: '4104',
    location_name: 'Oberwil',
    latitude: 47.5191,
    longitude: 7.5453,
    id: 2056,
  },
  {
    location_type: 'zip_code',
    location: '4105',
    location_name: 'Biel-Benken BL',
    latitude: 47.4985,
    longitude: 7.5159,
    id: 2057,
  },
  {
    location_type: 'zip_code',
    location: '4106',
    location_name: 'Therwil',
    latitude: 47.4965,
    longitude: 7.5565,
    id: 2058,
  },
  {
    location_type: 'zip_code',
    location: '4107',
    location_name: 'Ettingen',
    latitude: 47.4761,
    longitude: 7.5465,
    id: 2059,
  },
  {
    location_type: 'zip_code',
    location: '4123',
    location_name: 'Allschwil',
    latitude: 47.5417,
    longitude: 7.5343,
    id: 2060,
  },
  {
    location_type: 'zip_code',
    location: '4124',
    location_name: 'Sch\u00f6nenbuch',
    latitude: 47.5355,
    longitude: 7.5069,
    id: 2061,
  },
  {
    location_type: 'zip_code',
    location: '4127',
    location_name: 'Birsfelden',
    latitude: 47.5481,
    longitude: 7.6269,
    id: 2062,
  },
  {
    location_type: 'zip_code',
    location: '4132',
    location_name: 'Muttenz',
    latitude: 47.5228,
    longitude: 7.6478,
    id: 2063,
  },
  {
    location_type: 'zip_code',
    location: '4142',
    location_name: 'M\u00fcnchenstein',
    latitude: 47.5174,
    longitude: 7.6115,
    id: 2064,
  },
  {
    location_type: 'zip_code',
    location: '4144',
    location_name: 'Arlesheim',
    latitude: 47.4962,
    longitude: 7.6281,
    id: 2065,
  },
  {
    location_type: 'zip_code',
    location: '4147',
    location_name: 'Aesch',
    latitude: 47.4694,
    longitude: 7.5828,
    id: 2066,
  },
  {
    location_type: 'zip_code',
    location: '4148',
    location_name: 'Pfeffingen',
    latitude: 47.456,
    longitude: 7.581,
    id: 2067,
  },
  {
    location_type: 'zip_code',
    location: '4153',
    location_name: 'Reinach',
    latitude: 47.4915,
    longitude: 7.5869,
    id: 2068,
  },
  {
    location_type: 'zip_code',
    location: '2814',
    location_name: 'Roggenburg',
    latitude: 47.4267,
    longitude: 7.3559,
    id: 2069,
  },
  {
    location_type: 'zip_code',
    location: '4117',
    location_name: 'Burg',
    latitude: 47.4526,
    longitude: 7.4394,
    id: 2070,
  },
  {
    location_type: 'zip_code',
    location: '4202',
    location_name: 'Duggingen',
    latitude: 47.4514,
    longitude: 7.61,
    id: 2071,
  },
  {
    location_type: 'zip_code',
    location: '4203',
    location_name: 'Grellingen',
    latitude: 47.4392,
    longitude: 7.5844,
    id: 2072,
  },
  {
    location_type: 'zip_code',
    location: '4222',
    location_name: 'Zwingen',
    latitude: 47.4358,
    longitude: 7.5302,
    id: 2073,
  },
  {
    location_type: 'zip_code',
    location: '4223',
    location_name: 'Blauen',
    latitude: 47.4524,
    longitude: 7.5222,
    id: 2074,
  },
  {
    location_type: 'zip_code',
    location: '4224',
    location_name: 'Nenzlingen',
    latitude: 47.4491,
    longitude: 7.5618,
    id: 2075,
  },
  {
    location_type: 'zip_code',
    location: '4225',
    location_name: 'Brislach',
    latitude: 47.4282,
    longitude: 7.5493,
    id: 2076,
  },
  {
    location_type: 'zip_code',
    location: '4242',
    location_name: 'Laufen',
    latitude: 47.4073,
    longitude: 7.4845,
    id: 2077,
  },
  {
    location_type: 'zip_code',
    location: '4243',
    location_name: 'Dittingen',
    latitude: 47.4428,
    longitude: 7.4881,
    id: 2078,
  },
  {
    location_type: 'zip_code',
    location: '4244',
    location_name: 'R\u00f6schenz',
    latitude: 47.432,
    longitude: 7.4615,
    id: 2079,
  },
  {
    location_type: 'zip_code',
    location: '4246',
    location_name: 'Wahlen',
    latitude: 47.3987,
    longitude: 7.5118,
    id: 2080,
  },
  {
    location_type: 'zip_code',
    location: '4253',
    location_name: 'Liesberg',
    latitude: 47.399,
    longitude: 7.4322,
    id: 2081,
  },
  {
    location_type: 'zip_code',
    location: '4254',
    location_name: 'Liesberg Dorf',
    latitude: 47.4064,
    longitude: 7.4145,
    id: 2082,
  },
  {
    location_type: 'zip_code',
    location: '4133',
    location_name: 'Pratteln',
    latitude: 47.5174,
    longitude: 7.6941,
    id: 2083,
  },
  {
    location_type: 'zip_code',
    location: '4302',
    location_name: 'Augst',
    latitude: 47.5311,
    longitude: 7.7246,
    id: 2084,
  },
  {
    location_type: 'zip_code',
    location: '4304',
    location_name: 'Giebenach',
    latitude: 47.5251,
    longitude: 7.7456,
    id: 2085,
  },
  {
    location_type: 'zip_code',
    location: '4402',
    location_name: 'Frenkendorf',
    latitude: 47.5022,
    longitude: 7.7063,
    id: 2086,
  },
  {
    location_type: 'zip_code',
    location: '4410',
    location_name: 'Liestal',
    latitude: 47.483,
    longitude: 7.7254,
    id: 2087,
  },
  {
    location_type: 'zip_code',
    location: '4411',
    location_name: 'Seltisberg',
    latitude: 47.4608,
    longitude: 7.7133,
    id: 2088,
  },
  {
    location_type: 'zip_code',
    location: '4414',
    location_name: 'F\u00fcllinsdorf',
    latitude: 47.5094,
    longitude: 7.7343,
    id: 2089,
  },
  {
    location_type: 'zip_code',
    location: '4415',
    location_name: 'Lausen',
    latitude: 47.4693,
    longitude: 7.7663,
    id: 2090,
  },
  {
    location_type: 'zip_code',
    location: '4416',
    location_name: 'Bubendorf',
    latitude: 47.4471,
    longitude: 7.7348,
    id: 2091,
  },
  {
    location_type: 'zip_code',
    location: '4417',
    location_name: 'Ziefen',
    latitude: 47.4272,
    longitude: 7.6988,
    id: 2092,
  },
  {
    location_type: 'zip_code',
    location: '4419',
    location_name: 'Lupsingen',
    latitude: 47.4487,
    longitude: 7.6994,
    id: 2093,
  },
  {
    location_type: 'zip_code',
    location: '4422',
    location_name: 'Arisdorf',
    latitude: 47.5077,
    longitude: 7.7665,
    id: 2094,
  },
  {
    location_type: 'zip_code',
    location: '4423',
    location_name: 'Hersberg',
    latitude: 47.4919,
    longitude: 7.7818,
    id: 2095,
  },
  {
    location_type: 'zip_code',
    location: '4433',
    location_name: 'Ramlinsburg',
    latitude: 47.4495,
    longitude: 7.7675,
    id: 2096,
  },
  {
    location_type: 'zip_code',
    location: '4441',
    location_name: 'Th\u00fcrnen',
    latitude: 47.4513,
    longitude: 7.8288,
    id: 2097,
  },
  {
    location_type: 'zip_code',
    location: '4442',
    location_name: 'Diepflingen',
    latitude: 47.445,
    longitude: 7.8422,
    id: 2098,
  },
  {
    location_type: 'zip_code',
    location: '4443',
    location_name: 'Wittinsburg',
    latitude: 47.4292,
    longitude: 7.8387,
    id: 2099,
  },
  {
    location_type: 'zip_code',
    location: '4444',
    location_name: 'R\u00fcmlingen',
    latitude: 47.4277,
    longitude: 7.8578,
    id: 2100,
  },
  {
    location_type: 'zip_code',
    location: '4445',
    location_name: 'H\u00e4felfingen',
    latitude: 47.4139,
    longitude: 7.8688,
    id: 2101,
  },
  {
    location_type: 'zip_code',
    location: '4446',
    location_name: 'Buckten',
    latitude: 47.4126,
    longitude: 7.8464,
    id: 2102,
  },
  {
    location_type: 'zip_code',
    location: '4447',
    location_name: 'K\u00e4nerkinden',
    latitude: 47.411,
    longitude: 7.8331,
    id: 2103,
  },
  {
    location_type: 'zip_code',
    location: '4448',
    location_name: 'L\u00e4ufelfingen',
    latitude: 47.392,
    longitude: 7.853,
    id: 2104,
  },
  {
    location_type: 'zip_code',
    location: '4450',
    location_name: 'Sissach',
    latitude: 47.473,
    longitude: 7.8057,
    id: 2105,
  },
  {
    location_type: 'zip_code',
    location: '4451',
    location_name: 'Wintersingen',
    latitude: 47.4969,
    longitude: 7.8263,
    id: 2106,
  },
  {
    location_type: 'zip_code',
    location: '4452',
    location_name: 'Itingen',
    latitude: 47.4635,
    longitude: 7.7867,
    id: 2107,
  },
  {
    location_type: 'zip_code',
    location: '4453',
    location_name: 'Nusshof',
    latitude: 47.4917,
    longitude: 7.8008,
    id: 2108,
  },
  {
    location_type: 'zip_code',
    location: '4455',
    location_name: 'Zunzgen',
    latitude: 47.4435,
    longitude: 7.7938,
    id: 2109,
  },
  {
    location_type: 'zip_code',
    location: '4456',
    location_name: 'Tenniken',
    latitude: 47.4329,
    longitude: 7.8152,
    id: 2110,
  },
  {
    location_type: 'zip_code',
    location: '4460',
    location_name: 'Gelterkinden',
    latitude: 47.4551,
    longitude: 7.86,
    id: 2111,
  },
  {
    location_type: 'zip_code',
    location: '4461',
    location_name: 'B\u00f6ckten',
    latitude: 47.4687,
    longitude: 7.8344,
    id: 2112,
  },
  {
    location_type: 'zip_code',
    location: '4462',
    location_name: 'Rickenbach',
    latitude: 47.4863,
    longitude: 7.8515,
    id: 2113,
  },
  {
    location_type: 'zip_code',
    location: '4463',
    location_name: 'Buus',
    latitude: 47.5062,
    longitude: 7.8681,
    id: 2114,
  },
  {
    location_type: 'zip_code',
    location: '4464',
    location_name: 'Maisprach',
    latitude: 47.5233,
    longitude: 7.8476,
    id: 2115,
  },
  {
    location_type: 'zip_code',
    location: '4465',
    location_name: 'Hemmiken',
    latitude: 47.4923,
    longitude: 7.8981,
    id: 2116,
  },
  {
    location_type: 'zip_code',
    location: '4466',
    location_name: 'Ormalingen',
    latitude: 47.4768,
    longitude: 7.8777,
    id: 2117,
  },
  {
    location_type: 'zip_code',
    location: '4467',
    location_name: 'Rothenfluh',
    latitude: 47.4681,
    longitude: 7.9163,
    id: 2118,
  },
  {
    location_type: 'zip_code',
    location: '4469',
    location_name: 'Anwil',
    latitude: 47.4525,
    longitude: 7.9413,
    id: 2119,
  },
  {
    location_type: 'zip_code',
    location: '4492',
    location_name: 'Tecknau',
    latitude: 47.4431,
    longitude: 7.8945,
    id: 2120,
  },
  {
    location_type: 'zip_code',
    location: '4493',
    location_name: 'Wenslingen',
    latitude: 47.4451,
    longitude: 7.9084,
    id: 2121,
  },
  {
    location_type: 'zip_code',
    location: '4494',
    location_name: 'Oltingen',
    latitude: 47.4313,
    longitude: 7.9384,
    id: 2122,
  },
  {
    location_type: 'zip_code',
    location: '4495',
    location_name: 'Zeglingen',
    latitude: 47.4118,
    longitude: 7.9095,
    id: 2123,
  },
  {
    location_type: 'zip_code',
    location: '4496',
    location_name: 'Kilchberg',
    latitude: 47.4271,
    longitude: 7.8977,
    id: 2124,
  },
  {
    location_type: 'zip_code',
    location: '4497',
    location_name: 'R\u00fcnenberg',
    latitude: 47.4314,
    longitude: 7.8803,
    id: 2125,
  },
  {
    location_type: 'zip_code',
    location: '4207',
    location_name: 'Bretzwil',
    latitude: 47.3953,
    longitude: 7.6517,
    id: 2126,
  },
  {
    location_type: 'zip_code',
    location: '4418',
    location_name: 'Reigoldswil',
    latitude: 47.3855,
    longitude: 7.6934,
    id: 2127,
  },
  {
    location_type: 'zip_code',
    location: '4424',
    location_name: 'Arboldswil',
    latitude: 47.4164,
    longitude: 7.7192,
    id: 2128,
  },
  {
    location_type: 'zip_code',
    location: '4425',
    location_name: 'Titterten',
    latitude: 47.4017,
    longitude: 7.7173,
    id: 2129,
  },
  {
    location_type: 'zip_code',
    location: '4426',
    location_name: 'Lauwil',
    latitude: 47.3807,
    longitude: 7.6645,
    id: 2130,
  },
  {
    location_type: 'zip_code',
    location: '4431',
    location_name: 'Bennwil',
    latitude: 47.3945,
    longitude: 7.7822,
    id: 2131,
  },
  {
    location_type: 'zip_code',
    location: '4432',
    location_name: 'Lampenberg',
    latitude: 47.4275,
    longitude: 7.7531,
    id: 2132,
  },
  {
    location_type: 'zip_code',
    location: '4434',
    location_name: 'H\u00f6lstein',
    latitude: 47.4272,
    longitude: 7.7745,
    id: 2133,
  },
  {
    location_type: 'zip_code',
    location: '4435',
    location_name: 'Niederdorf',
    latitude: 47.4124,
    longitude: 7.7509,
    id: 2134,
  },
  {
    location_type: 'zip_code',
    location: '4436',
    location_name: 'Liedertswil',
    latitude: 47.3885,
    longitude: 7.7203,
    id: 2135,
  },
  {
    location_type: 'zip_code',
    location: '4437',
    location_name: 'Waldenburg',
    latitude: 47.3776,
    longitude: 7.7382,
    id: 2136,
  },
  {
    location_type: 'zip_code',
    location: '4438',
    location_name: 'Langenbruck',
    latitude: 47.3501,
    longitude: 7.775,
    id: 2137,
  },
  {
    location_type: 'zip_code',
    location: '4457',
    location_name: 'Diegten',
    latitude: 47.4093,
    longitude: 7.8133,
    id: 2138,
  },
  {
    location_type: 'zip_code',
    location: '4458',
    location_name: 'Eptingen',
    latitude: 47.3795,
    longitude: 7.8169,
    id: 2139,
  },
  {
    location_type: 'zip_code',
    location: '4000',
    location_name: 'Basel 2 Zustellung',
    latitude: 47.5667,
    longitude: 7.6,
    id: 2140,
  },
  {
    location_type: 'zip_code',
    location: '4001',
    location_name: 'Basel',
    latitude: 47.5564,
    longitude: 7.5889,
    id: 2141,
  },
  {
    location_type: 'zip_code',
    location: '4002',
    location_name: 'Basel',
    latitude: 47.5488,
    longitude: 7.5904,
    id: 2142,
  },
  {
    location_type: 'zip_code',
    location: '4003',
    location_name: 'Basel',
    latitude: 47.5592,
    longitude: 7.5788,
    id: 2143,
  },
  {
    location_type: 'zip_code',
    location: '4004',
    location_name: 'Basel',
    latitude: 47.5667,
    longitude: 7.6,
    id: 2144,
  },
  {
    location_type: 'zip_code',
    location: '4005',
    location_name: 'Basel',
    latitude: 47.5623,
    longitude: 7.5946,
    id: 2145,
  },
  {
    location_type: 'zip_code',
    location: '4007',
    location_name: 'Basel',
    latitude: 47.5667,
    longitude: 7.6,
    id: 2146,
  },
  {
    location_type: 'zip_code',
    location: '4008',
    location_name: 'Basel',
    latitude: 47.5667,
    longitude: 7.6,
    id: 2147,
  },
  {
    location_type: 'zip_code',
    location: '4009',
    location_name: 'Basel',
    latitude: 47.5667,
    longitude: 7.6,
    id: 2148,
  },
  {
    location_type: 'zip_code',
    location: '4010',
    location_name: 'Basel',
    latitude: 47.5528,
    longitude: 7.5929,
    id: 2149,
  },
  {
    location_type: 'zip_code',
    location: '4011',
    location_name: 'Basel',
    latitude: 47.5667,
    longitude: 7.6,
    id: 2150,
  },
  {
    location_type: 'zip_code',
    location: '4012',
    location_name: 'Basel',
    latitude: 47.5667,
    longitude: 7.6,
    id: 2151,
  },
  {
    location_type: 'zip_code',
    location: '4013',
    location_name: 'Basel',
    latitude: 47.5667,
    longitude: 7.6,
    id: 2152,
  },
  {
    location_type: 'zip_code',
    location: '4015',
    location_name: 'Basel',
    latitude: 47.5667,
    longitude: 7.6,
    id: 2153,
  },
  {
    location_type: 'zip_code',
    location: '4016',
    location_name: 'Basel',
    latitude: 47.5667,
    longitude: 7.6,
    id: 2154,
  },
  {
    location_type: 'zip_code',
    location: '4017',
    location_name: 'Basel',
    latitude: 47.5667,
    longitude: 7.6,
    id: 2155,
  },
  {
    location_type: 'zip_code',
    location: '4018',
    location_name: 'Basel',
    latitude: 47.5667,
    longitude: 7.6,
    id: 2156,
  },
  {
    location_type: 'zip_code',
    location: '4019',
    location_name: 'Basel',
    latitude: 47.5667,
    longitude: 7.6,
    id: 2157,
  },
  {
    location_type: 'zip_code',
    location: '4020',
    location_name: 'Basel',
    latitude: 47.5667,
    longitude: 7.6,
    id: 2158,
  },
  {
    location_type: 'zip_code',
    location: '4024',
    location_name: 'Basel',
    latitude: 47.5667,
    longitude: 7.6,
    id: 2159,
  },
  {
    location_type: 'zip_code',
    location: '4025',
    location_name: 'Basel',
    latitude: 47.5667,
    longitude: 7.6,
    id: 2160,
  },
  {
    location_type: 'zip_code',
    location: '4030',
    location_name: 'Basel',
    latitude: 47.5667,
    longitude: 7.6,
    id: 2161,
  },
  {
    location_type: 'zip_code',
    location: '4031',
    location_name: 'Basel Universit\u00e4tsspital',
    latitude: 47.5667,
    longitude: 7.6,
    id: 2162,
  },
  {
    location_type: 'zip_code',
    location: '4032',
    location_name: 'Basel',
    latitude: 47.5667,
    longitude: 7.6,
    id: 2163,
  },
  {
    location_type: 'zip_code',
    location: '4033',
    location_name: 'Basel Novartis Campus',
    latitude: 47.5667,
    longitude: 7.6,
    id: 2164,
  },
  {
    location_type: 'zip_code',
    location: '4034',
    location_name: 'Basel 34 Breite',
    latitude: 47.5577,
    longitude: 7.5936,
    id: 2165,
  },
  {
    location_type: 'zip_code',
    location: '4035',
    location_name: 'Basel 35 B\u00e4umlihof',
    latitude: 47.5577,
    longitude: 7.5936,
    id: 2166,
  },
  {
    location_type: 'zip_code',
    location: '4039',
    location_name: 'Basel',
    latitude: 47.5667,
    longitude: 7.6,
    id: 2167,
  },
  {
    location_type: 'zip_code',
    location: '4041',
    location_name: 'Basel UBS',
    latitude: 47.5577,
    longitude: 7.5936,
    id: 2168,
  },
  {
    location_type: 'zip_code',
    location: '4051',
    location_name: 'Basel',
    latitude: 47.554,
    longitude: 7.5944,
    id: 2169,
  },
  {
    location_type: 'zip_code',
    location: '4052',
    location_name: 'Basel',
    latitude: 47.5225,
    longitude: 7.5962,
    id: 2170,
  },
  {
    location_type: 'zip_code',
    location: '4053',
    location_name: 'Basel',
    latitude: 47.5383,
    longitude: 7.601,
    id: 2171,
  },
  {
    location_type: 'zip_code',
    location: '4054',
    location_name: 'Basel',
    latitude: 47.5505,
    longitude: 7.5689,
    id: 2172,
  },
  {
    location_type: 'zip_code',
    location: '4055',
    location_name: 'Basel',
    latitude: 47.5637,
    longitude: 7.564,
    id: 2173,
  },
  {
    location_type: 'zip_code',
    location: '4056',
    location_name: 'Basel',
    latitude: 47.5696,
    longitude: 7.5734,
    id: 2174,
  },
  {
    location_type: 'zip_code',
    location: '4057',
    location_name: 'Basel',
    latitude: 47.5761,
    longitude: 7.5999,
    id: 2175,
  },
  {
    location_type: 'zip_code',
    location: '4058',
    location_name: 'Basel',
    latitude: 47.5677,
    longitude: 7.6135,
    id: 2176,
  },
  {
    location_type: 'zip_code',
    location: '4059',
    location_name: 'Basel',
    latitude: 47.5316,
    longitude: 7.5934,
    id: 2177,
  },
  {
    location_type: 'zip_code',
    location: '4065',
    location_name: 'Basel SBB Cargo AG',
    latitude: 47.5617,
    longitude: 7.6067,
    id: 2178,
  },
  {
    location_type: 'zip_code',
    location: '4070',
    location_name: 'Basel',
    latitude: 47.5667,
    longitude: 7.6,
    id: 2179,
  },
  {
    location_type: 'zip_code',
    location: '4075',
    location_name: 'Basel',
    latitude: 47.5667,
    longitude: 7.6,
    id: 2180,
  },
  {
    location_type: 'zip_code',
    location: '4078',
    location_name: 'Basel Reader\'s Digest',
    latitude: 47.5617,
    longitude: 7.6067,
    id: 2181,
  },
  {
    location_type: 'zip_code',
    location: '4080',
    location_name: 'Basel',
    latitude: 47.5667,
    longitude: 7.6,
    id: 2182,
  },
  {
    location_type: 'zip_code',
    location: '4081',
    location_name: 'Basel SPI AS1',
    latitude: 47.5667,
    longitude: 7.6,
    id: 2183,
  },
  {
    location_type: 'zip_code',
    location: '4082',
    location_name: 'Basel SPI AS2',
    latitude: 47.5667,
    longitude: 7.6,
    id: 2184,
  },
  {
    location_type: 'zip_code',
    location: '4083',
    location_name: 'Basel',
    latitude: 47.5667,
    longitude: 7.6,
    id: 2185,
  },
  {
    location_type: 'zip_code',
    location: '4084',
    location_name: 'Basel',
    latitude: 47.5667,
    longitude: 7.6,
    id: 2186,
  },
  {
    location_type: 'zip_code',
    location: '4085',
    location_name: 'Basel',
    latitude: 47.5667,
    longitude: 7.6,
    id: 2187,
  },
  {
    location_type: 'zip_code',
    location: '4086',
    location_name: 'Basel',
    latitude: 47.5667,
    longitude: 7.6,
    id: 2188,
  },
  {
    location_type: 'zip_code',
    location: '4087',
    location_name: 'Basel',
    latitude: 47.5667,
    longitude: 7.6,
    id: 2189,
  },
  {
    location_type: 'zip_code',
    location: '4088',
    location_name: 'Basel',
    latitude: 47.5667,
    longitude: 7.6,
    id: 2190,
  },
  {
    location_type: 'zip_code',
    location: '4089',
    location_name: 'Basel SPI GLS Retour',
    latitude: 47.5667,
    longitude: 7.6,
    id: 2191,
  },
  {
    location_type: 'zip_code',
    location: '4091',
    location_name: 'Basel',
    latitude: 47.5667,
    longitude: 7.6,
    id: 2192,
  },
  {
    location_type: 'zip_code',
    location: '4092',
    location_name: 'Basel SPI AS 22',
    latitude: 47.5667,
    longitude: 7.6,
    id: 2193,
  },
  {
    location_type: 'zip_code',
    location: '4093',
    location_name: 'Basel SPI AS 23',
    latitude: 47.5667,
    longitude: 7.6,
    id: 2194,
  },
  {
    location_type: 'zip_code',
    location: '4094',
    location_name: 'Basel SPI AS 24',
    latitude: 47.5667,
    longitude: 7.6,
    id: 2195,
  },
  {
    location_type: 'zip_code',
    location: '4095',
    location_name: 'Basel SPI AS 25',
    latitude: 47.5667,
    longitude: 7.6,
    id: 2196,
  },
  {
    location_type: 'zip_code',
    location: '4096',
    location_name: 'Basel SPI AS 26',
    latitude: 47.5667,
    longitude: 7.6,
    id: 2197,
  },
  {
    location_type: 'zip_code',
    location: '4125',
    location_name: 'Riehen',
    latitude: 47.5805,
    longitude: 7.653,
    id: 2198,
  },
  {
    location_type: 'zip_code',
    location: '4126',
    location_name: 'Bettingen',
    latitude: 47.5714,
    longitude: 7.6753,
    id: 2199,
  },
  {
    location_type: 'zip_code',
    location: '1410',
    location_name: 'Pr\u00e8vondavaux',
    latitude: 46.7316,
    longitude: 6.8033,
    id: 2200,
  },
  {
    location_type: 'zip_code',
    location: '1468',
    location_name: 'Cheyres',
    latitude: 46.8082,
    longitude: 6.7871,
    id: 2201,
  },
  {
    location_type: 'zip_code',
    location: '1470',
    location_name: 'Lully (FR)',
    latitude: 46.8338,
    longitude: 6.8503,
    id: 2202,
  },
  {
    location_type: 'zip_code',
    location: '1473',
    location_name: 'Font',
    latitude: 46.8375,
    longitude: 6.8221,
    id: 2203,
  },
  {
    location_type: 'zip_code',
    location: '1474',
    location_name: 'Ch\u00c2bles',
    latitude: 46.82,
    longitude: 6.8107,
    id: 2204,
  },
  {
    location_type: 'zip_code',
    location: '1475',
    location_name: 'Forel (FR)',
    latitude: 46.8753,
    longitude: 6.8967,
    id: 2205,
  },
  {
    location_type: 'zip_code',
    location: '1482',
    location_name: 'Cugy (FR)',
    latitude: 46.8099,
    longitude: 6.8945,
    id: 2206,
  },
  {
    location_type: 'zip_code',
    location: '1483',
    location_name: 'Vesin',
    latitude: 46.8042,
    longitude: 6.8708,
    id: 2207,
  },
  {
    location_type: 'zip_code',
    location: '1484',
    location_name: 'Granges-de-Vesin',
    latitude: 46.8123,
    longitude: 6.8426,
    id: 2208,
  },
  {
    location_type: 'zip_code',
    location: '1485',
    location_name: 'Nuvilly',
    latitude: 46.7818,
    longitude: 6.8388,
    id: 2209,
  },
  {
    location_type: 'zip_code',
    location: '1486',
    location_name: 'Vuissens',
    latitude: 46.7317,
    longitude: 6.7676,
    id: 2210,
  },
  {
    location_type: 'zip_code',
    location: '1489',
    location_name: 'Murist',
    latitude: 46.795,
    longitude: 6.81,
    id: 2211,
  },
  {
    location_type: 'zip_code',
    location: '1527',
    location_name: 'Villeneuve (FR)',
    latitude: 46.7442,
    longitude: 6.8668,
    id: 2212,
  },
  {
    location_type: 'zip_code',
    location: '1528',
    location_name: 'Surpierre',
    latitude: 46.7486,
    longitude: 6.8555,
    id: 2213,
  },
  {
    location_type: 'zip_code',
    location: '1529',
    location_name: 'Cheiry',
    latitude: 46.7479,
    longitude: 6.8335,
    id: 2214,
  },
  {
    location_type: 'zip_code',
    location: '1532',
    location_name: 'F\u00e8tigny',
    latitude: 46.7938,
    longitude: 6.9137,
    id: 2215,
  },
  {
    location_type: 'zip_code',
    location: '1533',
    location_name: 'M\u00e9ni\u00e8res',
    latitude: 46.7855,
    longitude: 6.89,
    id: 2216,
  },
  {
    location_type: 'zip_code',
    location: '1534',
    location_name: 'Chapelle (Broye)',
    latitude: 46.7672,
    longitude: 6.8469,
    id: 2217,
  },
  {
    location_type: 'zip_code',
    location: '1541',
    location_name: 'S\u00e9vaz',
    latitude: 46.8393,
    longitude: 6.8712,
    id: 2218,
  },
  {
    location_type: 'zip_code',
    location: '1542',
    location_name: 'Rueyres-les-Pr\u00e9s',
    latitude: 46.8608,
    longitude: 6.9135,
    id: 2219,
  },
  {
    location_type: 'zip_code',
    location: '1544',
    location_name: 'Gletterens',
    latitude: 46.9002,
    longitude: 6.9366,
    id: 2220,
  },
  {
    location_type: 'zip_code',
    location: '1563',
    location_name: 'Dompierre (FR)',
    latitude: 46.8571,
    longitude: 6.9923,
    id: 2221,
  },
  {
    location_type: 'zip_code',
    location: '1564',
    location_name: 'Domdidier',
    latitude: 46.8683,
    longitude: 7.0111,
    id: 2222,
  },
  {
    location_type: 'zip_code',
    location: '1565',
    location_name: 'Vallon',
    latitude: 46.8865,
    longitude: 6.954,
    id: 2223,
  },
  {
    location_type: 'zip_code',
    location: '1566',
    location_name: 'Saint-Aubin (FR)',
    latitude: 46.8916,
    longitude: 6.9781,
    id: 2224,
  },
  {
    location_type: 'zip_code',
    location: '1567',
    location_name: 'Delley',
    latitude: 46.9128,
    longitude: 6.9701,
    id: 2225,
  },
  {
    location_type: 'zip_code',
    location: '1568',
    location_name: 'Portalban',
    latitude: 46.9102,
    longitude: 6.9534,
    id: 2226,
  },
  {
    location_type: 'zip_code',
    location: '1773',
    location_name: 'Chandon',
    latitude: 46.8417,
    longitude: 7.0398,
    id: 2227,
  },
  {
    location_type: 'zip_code',
    location: '1774',
    location_name: 'Montagny-les-Monts',
    latitude: 46.8024,
    longitude: 6.9931,
    id: 2228,
  },
  {
    location_type: 'zip_code',
    location: '1775',
    location_name: 'Grandsivaz',
    latitude: 46.7861,
    longitude: 6.9804,
    id: 2229,
  },
  {
    location_type: 'zip_code',
    location: '1776',
    location_name: 'Montagny-la-Ville',
    latitude: 46.8193,
    longitude: 7.0027,
    id: 2230,
  },
  {
    location_type: 'zip_code',
    location: '1553',
    location_name: 'Ch\u00c2tonnaye',
    latitude: 46.75,
    longitude: 6.9487,
    id: 2231,
  },
  {
    location_type: 'zip_code',
    location: '1608',
    location_name: 'Chapelle (Gl\u00c2ne)',
    latitude: 46.5867,
    longitude: 6.8379,
    id: 2232,
  },
  {
    location_type: 'zip_code',
    location: '1670',
    location_name: 'Esmonts',
    latitude: 46.6453,
    longitude: 6.847,
    id: 2233,
  },
  {
    location_type: 'zip_code',
    location: '1673',
    location_name: 'Ecublens (FR)',
    latitude: 46.6081,
    longitude: 6.808,
    id: 2234,
  },
  {
    location_type: 'zip_code',
    location: '1674',
    location_name: 'Morlens',
    latitude: 46.6553,
    longitude: 6.8389,
    id: 2235,
  },
  {
    location_type: 'zip_code',
    location: '1675',
    location_name: 'Vauderens',
    latitude: 46.6231,
    longitude: 6.851,
    id: 2236,
  },
  {
    location_type: 'zip_code',
    location: '1676',
    location_name: 'Chavannes-les-Forts',
    latitude: 46.6508,
    longitude: 6.8957,
    id: 2237,
  },
  {
    location_type: 'zip_code',
    location: '1677',
    location_name: 'Prez-vers-Siviriez',
    latitude: 46.6351,
    longitude: 6.8741,
    id: 2238,
  },
  {
    location_type: 'zip_code',
    location: '1678',
    location_name: 'Siviriez',
    latitude: 46.6622,
    longitude: 6.8758,
    id: 2239,
  },
  {
    location_type: 'zip_code',
    location: '1679',
    location_name: 'Villaraboud',
    latitude: 46.6564,
    longitude: 6.9135,
    id: 2240,
  },
  {
    location_type: 'zip_code',
    location: '1680',
    location_name: 'Romont (FR)',
    latitude: 46.7085,
    longitude: 6.9257,
    id: 2241,
  },
  {
    location_type: 'zip_code',
    location: '1681',
    location_name: 'Billens',
    latitude: 46.6908,
    longitude: 6.8969,
    id: 2242,
  },
  {
    location_type: 'zip_code',
    location: '1684',
    location_name: 'M\u00e9zi\u00e8res (FR)',
    latitude: 46.6804,
    longitude: 6.9276,
    id: 2243,
  },
  {
    location_type: 'zip_code',
    location: '1685',
    location_name: 'Villariaz',
    latitude: 46.665,
    longitude: 6.9405,
    id: 2244,
  },
  {
    location_type: 'zip_code',
    location: '1686',
    location_name: 'Grangettes',
    latitude: 46.6709,
    longitude: 6.9777,
    id: 2245,
  },
  {
    location_type: 'zip_code',
    location: '1687',
    location_name: 'Vuisternens-devant-Romont',
    latitude: 46.6509,
    longitude: 6.9326,
    id: 2246,
  },
  {
    location_type: 'zip_code',
    location: '1688',
    location_name: 'Sommentier',
    latitude: 46.6358,
    longitude: 6.9156,
    id: 2247,
  },
  {
    location_type: 'zip_code',
    location: '1689',
    location_name: 'Le Ch\u00c2telard',
    latitude: 46.6742,
    longitude: 6.9987,
    id: 2248,
  },
  {
    location_type: 'zip_code',
    location: '1690',
    location_name: 'Villaz-Saint-Pierre',
    latitude: 46.7207,
    longitude: 6.958,
    id: 2249,
  },
  {
    location_type: 'zip_code',
    location: '1691',
    location_name: 'Villarimboud',
    latitude: 46.7427,
    longitude: 6.9671,
    id: 2250,
  },
  {
    location_type: 'zip_code',
    location: '1692',
    location_name: 'Massonnens',
    latitude: 46.6988,
    longitude: 6.9777,
    id: 2251,
  },
  {
    location_type: 'zip_code',
    location: '1694',
    location_name: 'Villargiroud',
    latitude: 46.6966,
    longitude: 7.0001,
    id: 2252,
  },
  {
    location_type: 'zip_code',
    location: '1697',
    location_name: 'Les Ecasseys',
    latitude: 46.6208,
    longitude: 6.9097,
    id: 2253,
  },
  {
    location_type: 'zip_code',
    location: '1748',
    location_name: 'Torny-le-Grand',
    latitude: 46.7695,
    longitude: 6.9679,
    id: 2254,
  },
  {
    location_type: 'zip_code',
    location: '1749',
    location_name: 'Middes',
    latitude: 46.7718,
    longitude: 6.9488,
    id: 2255,
  },
  {
    location_type: 'zip_code',
    location: '1625',
    location_name: 'S\u00c2les (Gruy\u00e8re)',
    latitude: 46.63,
    longitude: 6.965,
    id: 2256,
  },
  {
    location_type: 'zip_code',
    location: '1626',
    location_name: 'Rueyres-Treyfayes',
    latitude: 46.6438,
    longitude: 6.9543,
    id: 2257,
  },
  {
    location_type: 'zip_code',
    location: '1627',
    location_name: 'Vaulruz',
    latitude: 46.6175,
    longitude: 6.982,
    id: 2258,
  },
  {
    location_type: 'zip_code',
    location: '1628',
    location_name: 'Vuadens',
    latitude: 46.6099,
    longitude: 7.0029,
    id: 2259,
  },
  {
    location_type: 'zip_code',
    location: '1630',
    location_name: 'Bulle',
    latitude: 46.5871,
    longitude: 6.9981,
    id: 2260,
  },
  {
    location_type: 'zip_code',
    location: '1631',
    location_name: 'Bulle',
    latitude: 46.6167,
    longitude: 7.0667,
    id: 2261,
  },
  {
    location_type: 'zip_code',
    location: '1632',
    location_name: 'Riaz',
    latitude: 46.642,
    longitude: 7.0419,
    id: 2262,
  },
  {
    location_type: 'zip_code',
    location: '1633',
    location_name: 'Marsens',
    latitude: 46.6568,
    longitude: 7.0383,
    id: 2263,
  },
  {
    location_type: 'zip_code',
    location: '1634',
    location_name: 'La Roche',
    latitude: 46.6926,
    longitude: 7.155,
    id: 2264,
  },
  {
    location_type: 'zip_code',
    location: '1635',
    location_name: 'La Tour-de-Tr\u00eame',
    latitude: 46.5983,
    longitude: 7.025,
    id: 2265,
  },
  {
    location_type: 'zip_code',
    location: '1636',
    location_name: 'Broc',
    latitude: 46.6,
    longitude: 7.1221,
    id: 2266,
  },
  {
    location_type: 'zip_code',
    location: '1637',
    location_name: 'Charmey',
    latitude: 46.5965,
    longitude: 7.1932,
    id: 2267,
  },
  {
    location_type: 'zip_code',
    location: '1638',
    location_name: 'Morlon',
    latitude: 46.6249,
    longitude: 7.0899,
    id: 2268,
  },
  {
    location_type: 'zip_code',
    location: '1642',
    location_name: 'Sorens',
    latitude: 46.6729,
    longitude: 7.0436,
    id: 2269,
  },
  {
    location_type: 'zip_code',
    location: '1643',
    location_name: 'Gumefens',
    latitude: 46.6771,
    longitude: 7.0754,
    id: 2270,
  },
  {
    location_type: 'zip_code',
    location: '1644',
    location_name: 'Avry-devant-Pont',
    latitude: 46.684,
    longitude: 7.0876,
    id: 2271,
  },
  {
    location_type: 'zip_code',
    location: '1645',
    location_name: 'Le Bry',
    latitude: 46.7004,
    longitude: 7.0852,
    id: 2272,
  },
  {
    location_type: 'zip_code',
    location: '1646',
    location_name: 'Echarlens',
    latitude: 46.647,
    longitude: 7.0814,
    id: 2273,
  },
  {
    location_type: 'zip_code',
    location: '1647',
    location_name: 'Corbi\u00e8res',
    latitude: 46.6581,
    longitude: 7.1129,
    id: 2274,
  },
  {
    location_type: 'zip_code',
    location: '1648',
    location_name: 'Hauteville',
    latitude: 46.6699,
    longitude: 7.1268,
    id: 2275,
  },
  {
    location_type: 'zip_code',
    location: '1649',
    location_name: 'Pont-la-Ville',
    latitude: 46.7014,
    longitude: 7.1111,
    id: 2276,
  },
  {
    location_type: 'zip_code',
    location: '1651',
    location_name: 'Villarvolard',
    latitude: 46.6445,
    longitude: 7.1211,
    id: 2277,
  },
  {
    location_type: 'zip_code',
    location: '1652',
    location_name: 'Botterens',
    latitude: 46.6175,
    longitude: 7.1147,
    id: 2278,
  },
  {
    location_type: 'zip_code',
    location: '1653',
    location_name: 'Cr\u00e9suz',
    latitude: 46.6218,
    longitude: 7.1421,
    id: 2279,
  },
  {
    location_type: 'zip_code',
    location: '1654',
    location_name: 'Cerniat (FR)',
    latitude: 46.6508,
    longitude: 7.1842,
    id: 2280,
  },
  {
    location_type: 'zip_code',
    location: '1656',
    location_name: 'Im Fang',
    latitude: 46.5907,
    longitude: 7.2301,
    id: 2281,
  },
  {
    location_type: 'zip_code',
    location: '1661',
    location_name: 'Le P\u00c2quier (FR) - Montbarry',
    latitude: 46.5895,
    longitude: 7.0484,
    id: 2282,
  },
  {
    location_type: 'zip_code',
    location: '1663',
    location_name: 'Mol\u00e9son-sur-Gruy\u00e8res',
    latitude: 46.5616,
    longitude: 7.0334,
    id: 2283,
  },
  {
    location_type: 'zip_code',
    location: '1665',
    location_name: 'Estavannens',
    latitude: 46.565,
    longitude: 7.1135,
    id: 2284,
  },
  {
    location_type: 'zip_code',
    location: '1666',
    location_name: 'Grandvillard',
    latitude: 46.5233,
    longitude: 7.1077,
    id: 2285,
  },
  {
    location_type: 'zip_code',
    location: '1667',
    location_name: 'Enney',
    latitude: 46.5622,
    longitude: 7.0698,
    id: 2286,
  },
  {
    location_type: 'zip_code',
    location: '1669',
    location_name: 'Lessoc',
    latitude: 46.508,
    longitude: 7.0795,
    id: 2287,
  },
  {
    location_type: 'zip_code',
    location: '1695',
    location_name: 'Villarsel-le-Gibloux',
    latitude: 46.7129,
    longitude: 7.0156,
    id: 2288,
  },
  {
    location_type: 'zip_code',
    location: '1696',
    location_name: 'Vuisternens-en-Ogoz',
    latitude: 46.7041,
    longitude: 7.0579,
    id: 2289,
  },
  {
    location_type: 'zip_code',
    location: '1700',
    location_name: 'Fribourg',
    latitude: 46.8043,
    longitude: 7.1667,
    id: 2290,
  },
  {
    location_type: 'zip_code',
    location: '1701',
    location_name: 'Fribourg',
    latitude: 46.8,
    longitude: 7.15,
    id: 2291,
  },
  {
    location_type: 'zip_code',
    location: '1702',
    location_name: 'Fribourg',
    latitude: 46.8067,
    longitude: 7.1614,
    id: 2292,
  },
  {
    location_type: 'zip_code',
    location: '1704',
    location_name: 'Fribourg',
    latitude: 46.8,
    longitude: 7.15,
    id: 2293,
  },
  {
    location_type: 'zip_code',
    location: '1705',
    location_name: 'Fribourg',
    latitude: 46.8,
    longitude: 7.15,
    id: 2294,
  },
  {
    location_type: 'zip_code',
    location: '1707',
    location_name: 'Fribourg',
    latitude: 46.8,
    longitude: 7.15,
    id: 2295,
  },
  {
    location_type: 'zip_code',
    location: '1708',
    location_name: 'Fribourg',
    latitude: 46.8,
    longitude: 7.15,
    id: 2296,
  },
  {
    location_type: 'zip_code',
    location: '1709',
    location_name: 'Fribourg',
    latitude: 46.8,
    longitude: 7.15,
    id: 2297,
  },
  {
    location_type: 'zip_code',
    location: '1720',
    location_name: 'Ch\u00e9sopelloz',
    latitude: 46.806,
    longitude: 7.0769,
    id: 2298,
  },
  {
    location_type: 'zip_code',
    location: '1722',
    location_name: 'Bourguillon',
    latitude: 46.7982,
    longitude: 7.1742,
    id: 2299,
  },
  {
    location_type: 'zip_code',
    location: '1723',
    location_name: 'Marly',
    latitude: 46.7768,
    longitude: 7.1474,
    id: 2300,
  },
  {
    location_type: 'zip_code',
    location: '1724',
    location_name: 'Le Mouret',
    latitude: 46.7498,
    longitude: 7.181,
    id: 2301,
  },
  {
    location_type: 'zip_code',
    location: '1725',
    location_name: 'Posieux',
    latitude: 46.7717,
    longitude: 7.1102,
    id: 2302,
  },
  {
    location_type: 'zip_code',
    location: '1726',
    location_name: 'Posat',
    latitude: 46.7422,
    longitude: 7.0602,
    id: 2303,
  },
  {
    location_type: 'zip_code',
    location: '1727',
    location_name: 'Corpataux-Magnedens',
    latitude: 46.7447,
    longitude: 7.1003,
    id: 2304,
  },
  {
    location_type: 'zip_code',
    location: '1728',
    location_name: 'Rossens (FR)',
    latitude: 46.7233,
    longitude: 7.0996,
    id: 2305,
  },
  {
    location_type: 'zip_code',
    location: '1730',
    location_name: 'Ecuvillens',
    latitude: 46.7563,
    longitude: 7.0775,
    id: 2306,
  },
  {
    location_type: 'zip_code',
    location: '1731',
    location_name: 'Ependes (FR)',
    latitude: 46.7562,
    longitude: 7.1492,
    id: 2307,
  },
  {
    location_type: 'zip_code',
    location: '1732',
    location_name: 'Arconciel',
    latitude: 46.753,
    longitude: 7.1153,
    id: 2308,
  },
  {
    location_type: 'zip_code',
    location: '1733',
    location_name: 'Treyvaux',
    latitude: 46.7206,
    longitude: 7.1441,
    id: 2309,
  },
  {
    location_type: 'zip_code',
    location: '1740',
    location_name: 'Neyruz (FR)',
    latitude: 46.7662,
    longitude: 7.0623,
    id: 2310,
  },
  {
    location_type: 'zip_code',
    location: '1741',
    location_name: 'Cottens (FR)',
    latitude: 46.755,
    longitude: 7.035,
    id: 2311,
  },
  {
    location_type: 'zip_code',
    location: '1742',
    location_name: 'Autigny',
    latitude: 46.7408,
    longitude: 7.0333,
    id: 2312,
  },
  {
    location_type: 'zip_code',
    location: '1744',
    location_name: 'Ch\u00e9nens',
    latitude: 46.7426,
    longitude: 6.9964,
    id: 2313,
  },
  {
    location_type: 'zip_code',
    location: '1745',
    location_name: 'Lentigny',
    latitude: 46.761,
    longitude: 7.0027,
    id: 2314,
  },
  {
    location_type: 'zip_code',
    location: '1746',
    location_name: 'Prez-vers-Nor\u00e9az',
    latitude: 46.7843,
    longitude: 7.0105,
    id: 2315,
  },
  {
    location_type: 'zip_code',
    location: '1747',
    location_name: 'Corserey',
    latitude: 46.7731,
    longitude: 6.9887,
    id: 2316,
  },
  {
    location_type: 'zip_code',
    location: '1752',
    location_name: 'Villars-sur-Gl\u00c2ne',
    latitude: 46.7961,
    longitude: 7.123,
    id: 2317,
  },
  {
    location_type: 'zip_code',
    location: '1753',
    location_name: 'Matran',
    latitude: 46.7859,
    longitude: 7.0887,
    id: 2318,
  },
  {
    location_type: 'zip_code',
    location: '1754',
    location_name: 'Avry-Centre FR',
    latitude: 46.7881,
    longitude: 7.0603,
    id: 2319,
  },
  {
    location_type: 'zip_code',
    location: '1756',
    location_name: 'Onnens (FR)',
    latitude: 46.7716,
    longitude: 7.0382,
    id: 2320,
  },
  {
    location_type: 'zip_code',
    location: '1757',
    location_name: 'Nor\u00e9az',
    latitude: 46.8017,
    longitude: 7.0385,
    id: 2321,
  },
  {
    location_type: 'zip_code',
    location: '1762',
    location_name: 'Givisiez',
    latitude: 46.8134,
    longitude: 7.1255,
    id: 2322,
  },
  {
    location_type: 'zip_code',
    location: '1763',
    location_name: 'Granges-Paccot',
    latitude: 46.8224,
    longitude: 7.1537,
    id: 2323,
  },
  {
    location_type: 'zip_code',
    location: '1772',
    location_name: 'Ponthaux',
    latitude: 46.8166,
    longitude: 7.0399,
    id: 2324,
  },
  {
    location_type: 'zip_code',
    location: '1782',
    location_name: 'La Corbaz',
    latitude: 46.8407,
    longitude: 7.1146,
    id: 2325,
  },
  {
    location_type: 'zip_code',
    location: '1583',
    location_name: 'Villarepos',
    latitude: 46.8823,
    longitude: 7.0804,
    id: 2326,
  },
  {
    location_type: 'zip_code',
    location: '1721',
    location_name: 'Courtion',
    latitude: 46.8604,
    longitude: 7.0689,
    id: 2327,
  },
  {
    location_type: 'zip_code',
    location: '1783',
    location_name: 'Barber\u00eache',
    latitude: 46.8604,
    longitude: 7.1515,
    id: 2328,
  },
  {
    location_type: 'zip_code',
    location: '1784',
    location_name: 'Wallenried',
    latitude: 46.8769,
    longitude: 7.1097,
    id: 2329,
  },
  {
    location_type: 'zip_code',
    location: '1785',
    location_name: 'Cressier (FR)',
    latitude: 46.8964,
    longitude: 7.1407,
    id: 2330,
  },
  {
    location_type: 'zip_code',
    location: '1786',
    location_name: 'Sugiez',
    latitude: 46.9697,
    longitude: 7.1074,
    id: 2331,
  },
  {
    location_type: 'zip_code',
    location: '1787',
    location_name: 'Mur (Vully) (FR)',
    latitude: 46.9461,
    longitude: 7.0644,
    id: 2332,
  },
  {
    location_type: 'zip_code',
    location: '1788',
    location_name: 'Praz (Vully)',
    latitude: 46.9564,
    longitude: 7.0963,
    id: 2333,
  },
  {
    location_type: 'zip_code',
    location: '1789',
    location_name: 'Lugnorre',
    latitude: 46.9583,
    longitude: 7.0744,
    id: 2334,
  },
  {
    location_type: 'zip_code',
    location: '1791',
    location_name: 'Courtaman',
    latitude: 46.8712,
    longitude: 7.1322,
    id: 2335,
  },
  {
    location_type: 'zip_code',
    location: '1792',
    location_name: 'Guschelmuth',
    latitude: 46.8834,
    longitude: 7.1406,
    id: 2336,
  },
  {
    location_type: 'zip_code',
    location: '1793',
    location_name: 'Jeuss',
    latitude: 46.9096,
    longitude: 7.1736,
    id: 2337,
  },
  {
    location_type: 'zip_code',
    location: '1794',
    location_name: 'Salvenach',
    latitude: 46.9139,
    longitude: 7.1498,
    id: 2338,
  },
  {
    location_type: 'zip_code',
    location: '1795',
    location_name: 'Courlevon',
    latitude: 46.8916,
    longitude: 7.1101,
    id: 2339,
  },
  {
    location_type: 'zip_code',
    location: '1796',
    location_name: 'Courgevaux',
    latitude: 46.9054,
    longitude: 7.1046,
    id: 2340,
  },
  {
    location_type: 'zip_code',
    location: '3210',
    location_name: 'Kerzers',
    latitude: 46.9791,
    longitude: 7.1994,
    id: 2341,
  },
  {
    location_type: 'zip_code',
    location: '3212',
    location_name: 'Kleingurmels',
    latitude: 46.8845,
    longitude: 7.1871,
    id: 2342,
  },
  {
    location_type: 'zip_code',
    location: '3213',
    location_name: 'Kleinb\u00f6singen',
    latitude: 46.8938,
    longitude: 7.2038,
    id: 2343,
  },
  {
    location_type: 'zip_code',
    location: '3214',
    location_name: 'Ulmiz',
    latitude: 46.9299,
    longitude: 7.1994,
    id: 2344,
  },
  {
    location_type: 'zip_code',
    location: '3215',
    location_name: 'B\u00fcchslen',
    latitude: 46.9438,
    longitude: 7.1826,
    id: 2345,
  },
  {
    location_type: 'zip_code',
    location: '3216',
    location_name: 'Ried bei Kerzers',
    latitude: 46.9629,
    longitude: 7.1748,
    id: 2346,
  },
  {
    location_type: 'zip_code',
    location: '3280',
    location_name: 'Greng',
    latitude: 46.9149,
    longitude: 7.0918,
    id: 2347,
  },
  {
    location_type: 'zip_code',
    location: '3284',
    location_name: 'Fr\u00e4schels',
    latitude: 46.998,
    longitude: 7.2079,
    id: 2348,
  },
  {
    location_type: 'zip_code',
    location: '3285',
    location_name: 'Galmiz',
    latitude: 46.9619,
    longitude: 7.1462,
    id: 2349,
  },
  {
    location_type: 'zip_code',
    location: '3286',
    location_name: 'Muntelier',
    latitude: 46.942,
    longitude: 7.136,
    id: 2350,
  },
  {
    location_type: 'zip_code',
    location: '1712',
    location_name: 'Tafers',
    latitude: 46.8109,
    longitude: 7.2152,
    id: 2351,
  },
  {
    location_type: 'zip_code',
    location: '1713',
    location_name: 'St. Antoni',
    latitude: 46.8221,
    longitude: 7.2648,
    id: 2352,
  },
  {
    location_type: 'zip_code',
    location: '1714',
    location_name: 'Heitenried',
    latitude: 46.823,
    longitude: 7.3007,
    id: 2353,
  },
  {
    location_type: 'zip_code',
    location: '1715',
    location_name: 'Alterswil',
    latitude: 46.7861,
    longitude: 7.2835,
    id: 2354,
  },
  {
    location_type: 'zip_code',
    location: '1716',
    location_name: 'Schwarzsee',
    latitude: 46.6701,
    longitude: 7.2858,
    id: 2355,
  },
  {
    location_type: 'zip_code',
    location: '1717',
    location_name: 'St. Ursen',
    latitude: 46.7894,
    longitude: 7.2286,
    id: 2356,
  },
  {
    location_type: 'zip_code',
    location: '1718',
    location_name: 'Rechthalten',
    latitude: 46.7654,
    longitude: 7.2447,
    id: 2357,
  },
  {
    location_type: 'zip_code',
    location: '1719',
    location_name: 'Br\u00fcnisried',
    latitude: 46.7557,
    longitude: 7.2781,
    id: 2358,
  },
  {
    location_type: 'zip_code',
    location: '1734',
    location_name: 'Tentlingen',
    latitude: 46.7662,
    longitude: 7.1945,
    id: 2359,
  },
  {
    location_type: 'zip_code',
    location: '1735',
    location_name: 'Giffers',
    latitude: 46.7536,
    longitude: 7.2277,
    id: 2360,
  },
  {
    location_type: 'zip_code',
    location: '1736',
    location_name: 'St. Silvester',
    latitude: 46.7262,
    longitude: 7.2179,
    id: 2361,
  },
  {
    location_type: 'zip_code',
    location: '1737',
    location_name: 'Plasselb',
    latitude: 46.6934,
    longitude: 7.2262,
    id: 2362,
  },
  {
    location_type: 'zip_code',
    location: '3175',
    location_name: 'Flamatt',
    latitude: 46.8874,
    longitude: 7.3064,
    id: 2363,
  },
  {
    location_type: 'zip_code',
    location: '3178',
    location_name: 'B\u00f6singen',
    latitude: 46.8853,
    longitude: 7.2322,
    id: 2364,
  },
  {
    location_type: 'zip_code',
    location: '3182',
    location_name: 'Ueberstorf',
    latitude: 46.8688,
    longitude: 7.3256,
    id: 2365,
  },
  {
    location_type: 'zip_code',
    location: '3184',
    location_name: 'W\u00fcnnewil',
    latitude: 46.8735,
    longitude: 7.2774,
    id: 2366,
  },
  {
    location_type: 'zip_code',
    location: '3185',
    location_name: 'Schmitten (FR)',
    latitude: 46.8493,
    longitude: 7.2515,
    id: 2367,
  },
  {
    location_type: 'zip_code',
    location: '3186',
    location_name: 'D\u00fcdingen',
    latitude: 46.8506,
    longitude: 7.1882,
    id: 2368,
  },
  {
    location_type: 'zip_code',
    location: '1609',
    location_name: 'Saint-Martin (FR)',
    latitude: 46.5738,
    longitude: 6.8856,
    id: 2369,
  },
  {
    location_type: 'zip_code',
    location: '1611',
    location_name: 'Le Cr\u00eat-pr\u00e8s-Semsales',
    latitude: 46.6067,
    longitude: 6.9208,
    id: 2370,
  },
  {
    location_type: 'zip_code',
    location: '1614',
    location_name: 'Granges (Veveyse)',
    latitude: 46.517,
    longitude: 6.8271,
    id: 2371,
  },
  {
    location_type: 'zip_code',
    location: '1615',
    location_name: 'Bossonnens',
    latitude: 46.5292,
    longitude: 6.8483,
    id: 2372,
  },
  {
    location_type: 'zip_code',
    location: '1616',
    location_name: 'Attalens',
    latitude: 46.5075,
    longitude: 6.8526,
    id: 2373,
  },
  {
    location_type: 'zip_code',
    location: '1617',
    location_name: 'Remaufens',
    latitude: 46.5323,
    longitude: 6.8815,
    id: 2374,
  },
  {
    location_type: 'zip_code',
    location: '1618',
    location_name: 'Ch\u00c2tel-St-Denis',
    latitude: 46.5338,
    longitude: 6.9435,
    id: 2375,
  },
  {
    location_type: 'zip_code',
    location: '1619',
    location_name: 'Les Paccots',
    latitude: 46.502,
    longitude: 6.973,
    id: 2376,
  },
  {
    location_type: 'zip_code',
    location: '1623',
    location_name: 'Semsales',
    latitude: 46.5698,
    longitude: 6.9357,
    id: 2377,
  },
  {
    location_type: 'zip_code',
    location: '1624',
    location_name: 'Grattavache',
    latitude: 46.5975,
    longitude: 6.9328,
    id: 2378,
  },
  {
    location_type: 'zip_code',
    location: '1699',
    location_name: 'Porsel',
    latitude: 46.6025,
    longitude: 6.8733,
    id: 2379,
  },
  {
    location_type: 'zip_code',
    location: '1200',
    location_name: 'Gen\u00e8ve 18',
    latitude: 46.2,
    longitude: 6.1667,
    id: 2380,
  },
  {
    location_type: 'zip_code',
    location: '1201',
    location_name: 'Gen\u00e8ve',
    latitude: 46.2103,
    longitude: 6.1464,
    id: 2381,
  },
  {
    location_type: 'zip_code',
    location: '1202',
    location_name: 'Gen\u00e8ve',
    latitude: 46.2214,
    longitude: 6.1431,
    id: 2382,
  },
  {
    location_type: 'zip_code',
    location: '1203',
    location_name: 'Gen\u00e8ve',
    latitude: 46.2086,
    longitude: 6.1236,
    id: 2383,
  },
  {
    location_type: 'zip_code',
    location: '1204',
    location_name: 'Gen\u00e8ve',
    latitude: 46.2024,
    longitude: 6.145,
    id: 2384,
  },
  {
    location_type: 'zip_code',
    location: '1205',
    location_name: 'Gen\u00e8ve',
    latitude: 46.1952,
    longitude: 6.1436,
    id: 2385,
  },
  {
    location_type: 'zip_code',
    location: '1206',
    location_name: 'Gen\u00e8ve',
    latitude: 46.1872,
    longitude: 6.1588,
    id: 2386,
  },
  {
    location_type: 'zip_code',
    location: '1207',
    location_name: 'Gen\u00e8ve',
    latitude: 46.2058,
    longitude: 6.1619,
    id: 2387,
  },
  {
    location_type: 'zip_code',
    location: '1208',
    location_name: 'Gen\u00e8ve',
    latitude: 46.1994,
    longitude: 6.1659,
    id: 2388,
  },
  {
    location_type: 'zip_code',
    location: '1209',
    location_name: 'Gen\u00e8ve',
    latitude: 46.2219,
    longitude: 6.1244,
    id: 2389,
  },
  {
    location_type: 'zip_code',
    location: '1211',
    location_name: 'Gen\u00e8ve 73',
    latitude: 46.2111,
    longitude: 6.1028,
    id: 2390,
  },
  {
    location_type: 'zip_code',
    location: '1212',
    location_name: 'Grand-Lancy',
    latitude: 46.1793,
    longitude: 6.1187,
    id: 2391,
  },
  {
    location_type: 'zip_code',
    location: '1213',
    location_name: 'Petit-Lancy',
    latitude: 46.193,
    longitude: 6.1158,
    id: 2392,
  },
  {
    location_type: 'zip_code',
    location: '1214',
    location_name: 'Vernier',
    latitude: 46.2134,
    longitude: 6.0809,
    id: 2393,
  },
  {
    location_type: 'zip_code',
    location: '1215',
    location_name: 'Gen\u00e8ve',
    latitude: 46.2352,
    longitude: 6.1069,
    id: 2394,
  },
  {
    location_type: 'zip_code',
    location: '1216',
    location_name: 'Cointrin',
    latitude: 46.2247,
    longitude: 6.1068,
    id: 2395,
  },
  {
    location_type: 'zip_code',
    location: '1217',
    location_name: 'Meyrin',
    latitude: 46.2319,
    longitude: 6.0712,
    id: 2396,
  },
  {
    location_type: 'zip_code',
    location: '1218',
    location_name: 'Le Grand-Saconnex',
    latitude: 46.2341,
    longitude: 6.1238,
    id: 2397,
  },
  {
    location_type: 'zip_code',
    location: '1219',
    location_name: 'A\u00cfre',
    latitude: 46.1999,
    longitude: 6.0984,
    id: 2398,
  },
  {
    location_type: 'zip_code',
    location: '1220',
    location_name: 'Les Avanchets',
    latitude: 46.2208,
    longitude: 6.109,
    id: 2399,
  },
  {
    location_type: 'zip_code',
    location: '1222',
    location_name: 'V\u00e9senaz',
    latitude: 46.2383,
    longitude: 6.2088,
    id: 2400,
  },
  {
    location_type: 'zip_code',
    location: '1223',
    location_name: 'Cologny',
    latitude: 46.2183,
    longitude: 6.1871,
    id: 2401,
  },
  {
    location_type: 'zip_code',
    location: '1224',
    location_name: 'Ch\u00eane-Bougeries',
    latitude: 46.2032,
    longitude: 6.1951,
    id: 2402,
  },
  {
    location_type: 'zip_code',
    location: '1225',
    location_name: 'Ch\u00eane-Bourg',
    latitude: 46.1964,
    longitude: 6.1976,
    id: 2403,
  },
  {
    location_type: 'zip_code',
    location: '1226',
    location_name: 'Th\u00f4nex',
    latitude: 46.1976,
    longitude: 6.2099,
    id: 2404,
  },
  {
    location_type: 'zip_code',
    location: '1227',
    location_name: 'Carouge GE',
    latitude: 46.1795,
    longitude: 6.1391,
    id: 2405,
  },
  {
    location_type: 'zip_code',
    location: '1228',
    location_name: 'Plan-les-Ouates',
    latitude: 46.1642,
    longitude: 6.1182,
    id: 2406,
  },
  {
    location_type: 'zip_code',
    location: '1231',
    location_name: 'Conches',
    latitude: 46.1848,
    longitude: 6.1798,
    id: 2407,
  },
  {
    location_type: 'zip_code',
    location: '1232',
    location_name: 'Confignon',
    latitude: 46.1709,
    longitude: 6.0883,
    id: 2408,
  },
  {
    location_type: 'zip_code',
    location: '1233',
    location_name: 'Bernex',
    latitude: 46.1754,
    longitude: 6.0664,
    id: 2409,
  },
  {
    location_type: 'zip_code',
    location: '1234',
    location_name: 'Vessy',
    latitude: 46.175,
    longitude: 6.1572,
    id: 2410,
  },
  {
    location_type: 'zip_code',
    location: '1236',
    location_name: 'Cartigny',
    latitude: 46.1737,
    longitude: 6.0287,
    id: 2411,
  },
  {
    location_type: 'zip_code',
    location: '1237',
    location_name: 'Avully',
    latitude: 46.1671,
    longitude: 5.9997,
    id: 2412,
  },
  {
    location_type: 'zip_code',
    location: '1239',
    location_name: 'Collex',
    latitude: 46.2796,
    longitude: 6.1214,
    id: 2413,
  },
  {
    location_type: 'zip_code',
    location: '1240',
    location_name: 'Gen\u00e8ve',
    latitude: 46.2,
    longitude: 6.1667,
    id: 2414,
  },
  {
    location_type: 'zip_code',
    location: '1241',
    location_name: 'Puplinge',
    latitude: 46.2101,
    longitude: 6.2295,
    id: 2415,
  },
  {
    location_type: 'zip_code',
    location: '1242',
    location_name: 'Satigny',
    latitude: 46.2154,
    longitude: 6.0271,
    id: 2416,
  },
  {
    location_type: 'zip_code',
    location: '1243',
    location_name: 'Presinge',
    latitude: 46.2162,
    longitude: 6.2627,
    id: 2417,
  },
  {
    location_type: 'zip_code',
    location: '1244',
    location_name: 'Choulex',
    latitude: 46.2254,
    longitude: 6.2303,
    id: 2418,
  },
  {
    location_type: 'zip_code',
    location: '1245',
    location_name: 'Collonge-Bellerive',
    latitude: 46.2569,
    longitude: 6.2063,
    id: 2419,
  },
  {
    location_type: 'zip_code',
    location: '1246',
    location_name: 'Corsier GE',
    latitude: 46.2629,
    longitude: 6.2259,
    id: 2420,
  },
  {
    location_type: 'zip_code',
    location: '1247',
    location_name: 'Ani\u00e8res',
    latitude: 46.277,
    longitude: 6.2326,
    id: 2421,
  },
  {
    location_type: 'zip_code',
    location: '1248',
    location_name: 'Hermance',
    latitude: 46.2946,
    longitude: 6.2443,
    id: 2422,
  },
  {
    location_type: 'zip_code',
    location: '1251',
    location_name: 'Gy',
    latitude: 46.2536,
    longitude: 6.2858,
    id: 2423,
  },
  {
    location_type: 'zip_code',
    location: '1252',
    location_name: 'Meinier',
    latitude: 46.2416,
    longitude: 6.2358,
    id: 2424,
  },
  {
    location_type: 'zip_code',
    location: '1253',
    location_name: 'Vandoeuvres',
    latitude: 46.2212,
    longitude: 6.2057,
    id: 2425,
  },
  {
    location_type: 'zip_code',
    location: '1254',
    location_name: 'Jussy',
    latitude: 46.2389,
    longitude: 6.28,
    id: 2426,
  },
  {
    location_type: 'zip_code',
    location: '1255',
    location_name: 'Veyrier',
    latitude: 46.1677,
    longitude: 6.1744,
    id: 2427,
  },
  {
    location_type: 'zip_code',
    location: '1256',
    location_name: 'Troinex',
    latitude: 46.1594,
    longitude: 6.1559,
    id: 2428,
  },
  {
    location_type: 'zip_code',
    location: '1257',
    location_name: 'La Croix-de-Rozon',
    latitude: 46.1497,
    longitude: 6.118,
    id: 2429,
  },
  {
    location_type: 'zip_code',
    location: '1258',
    location_name: 'Perly',
    latitude: 46.1582,
    longitude: 6.088,
    id: 2430,
  },
  {
    location_type: 'zip_code',
    location: '1281',
    location_name: 'Russin',
    latitude: 46.1905,
    longitude: 6.0122,
    id: 2431,
  },
  {
    location_type: 'zip_code',
    location: '1283',
    location_name: 'Dardagny',
    latitude: 46.2028,
    longitude: 5.9852,
    id: 2432,
  },
  {
    location_type: 'zip_code',
    location: '1284',
    location_name: 'Chancy',
    latitude: 46.1487,
    longitude: 5.9807,
    id: 2433,
  },
  {
    location_type: 'zip_code',
    location: '1285',
    location_name: 'Athenaz',
    latitude: 46.1518,
    longitude: 6.0058,
    id: 2434,
  },
  {
    location_type: 'zip_code',
    location: '1286',
    location_name: 'Soral',
    latitude: 46.1469,
    longitude: 6.0411,
    id: 2435,
  },
  {
    location_type: 'zip_code',
    location: '1287',
    location_name: 'Laconnex',
    latitude: 46.1589,
    longitude: 6.0333,
    id: 2436,
  },
  {
    location_type: 'zip_code',
    location: '1288',
    location_name: 'Aire-la-Ville',
    latitude: 46.1908,
    longitude: 6.0454,
    id: 2437,
  },
  {
    location_type: 'zip_code',
    location: '1289',
    location_name: 'Gen\u00e8ve Services Sp\u00e9ciaux',
    latitude: 46.2058,
    longitude: 6.1416,
    id: 2438,
  },
  {
    location_type: 'zip_code',
    location: '1290',
    location_name: 'Versoix',
    latitude: 46.2937,
    longitude: 6.1499,
    id: 2439,
  },
  {
    location_type: 'zip_code',
    location: '1292',
    location_name: 'Chamb\u00e9sy',
    latitude: 46.2402,
    longitude: 6.14,
    id: 2440,
  },
  {
    location_type: 'zip_code',
    location: '1293',
    location_name: 'Bellevue',
    latitude: 46.2587,
    longitude: 6.1351,
    id: 2441,
  },
  {
    location_type: 'zip_code',
    location: '1294',
    location_name: 'Genthod',
    latitude: 46.2661,
    longitude: 6.1538,
    id: 2442,
  },
  {
    location_type: 'zip_code',
    location: '1298',
    location_name: 'C\u00e9ligny',
    latitude: 46.3547,
    longitude: 6.1875,
    id: 2443,
  },
  {
    location_type: 'zip_code',
    location: '8762',
    location_name: 'Schwanden',
    latitude: 46.9672,
    longitude: 9.0996,
    id: 2444,
  },
  {
    location_type: 'zip_code',
    location: '8773',
    location_name: 'Haslen',
    latitude: 46.9648,
    longitude: 9.0713,
    id: 2445,
  },
  {
    location_type: 'zip_code',
    location: '8777',
    location_name: 'Diesbach',
    latitude: 46.9483,
    longitude: 9.0491,
    id: 2446,
  },
  {
    location_type: 'zip_code',
    location: '8782',
    location_name: 'R\u00fcti',
    latitude: 46.9327,
    longitude: 9.0203,
    id: 2447,
  },
  {
    location_type: 'zip_code',
    location: '7077',
    location_name: 'Valbella',
    latitude: 46.7534,
    longitude: 9.5507,
    id: 2448,
  },
  {
    location_type: 'zip_code',
    location: '7078',
    location_name: 'Lenzerheide/Lai',
    latitude: 46.7346,
    longitude: 9.5454,
    id: 2449,
  },
  {
    location_type: 'zip_code',
    location: '7082',
    location_name: 'Vaz/Obervaz',
    latitude: 46.6989,
    longitude: 9.5344,
    id: 2450,
  },
  {
    location_type: 'zip_code',
    location: '7083',
    location_name: 'Lantsch/Lenz',
    latitude: 46.6998,
    longitude: 9.5771,
    id: 2451,
  },
  {
    location_type: 'zip_code',
    location: '7084',
    location_name: 'Brienz/Brinzauls',
    latitude: 46.6869,
    longitude: 9.5991,
    id: 2452,
  },
  {
    location_type: 'zip_code',
    location: '7431',
    location_name: 'Mutten',
    latitude: 46.6789,
    longitude: 9.4996,
    id: 2453,
  },
  {
    location_type: 'zip_code',
    location: '7450',
    location_name: 'Tiefencastel',
    latitude: 46.6438,
    longitude: 9.5965,
    id: 2454,
  },
  {
    location_type: 'zip_code',
    location: '7451',
    location_name: 'Alvaschein',
    latitude: 46.6786,
    longitude: 9.5334,
    id: 2455,
  },
  {
    location_type: 'zip_code',
    location: '7452',
    location_name: 'Cunter',
    latitude: 46.6235,
    longitude: 9.6058,
    id: 2456,
  },
  {
    location_type: 'zip_code',
    location: '7453',
    location_name: 'Tinizong',
    latitude: 46.5896,
    longitude: 9.6571,
    id: 2457,
  },
  {
    location_type: 'zip_code',
    location: '7454',
    location_name: 'Rona',
    latitude: 46.5543,
    longitude: 9.6404,
    id: 2458,
  },
  {
    location_type: 'zip_code',
    location: '7455',
    location_name: 'Mulegns',
    latitude: 46.5003,
    longitude: 9.5765,
    id: 2459,
  },
  {
    location_type: 'zip_code',
    location: '7456',
    location_name: 'Sur',
    latitude: 46.5296,
    longitude: 9.6619,
    id: 2460,
  },
  {
    location_type: 'zip_code',
    location: '7457',
    location_name: 'Bivio',
    latitude: 46.4581,
    longitude: 9.6644,
    id: 2461,
  },
  {
    location_type: 'zip_code',
    location: '7458',
    location_name: 'Mon',
    latitude: 46.6481,
    longitude: 9.5485,
    id: 2462,
  },
  {
    location_type: 'zip_code',
    location: '7459',
    location_name: 'Stierva',
    latitude: 46.6622,
    longitude: 9.5222,
    id: 2463,
  },
  {
    location_type: 'zip_code',
    location: '7460',
    location_name: 'Savognin',
    latitude: 46.5784,
    longitude: 9.5889,
    id: 2464,
  },
  {
    location_type: 'zip_code',
    location: '7462',
    location_name: 'Salouf',
    latitude: 46.6254,
    longitude: 9.5429,
    id: 2465,
  },
  {
    location_type: 'zip_code',
    location: '7463',
    location_name: 'Riom',
    latitude: 46.6221,
    longitude: 9.5871,
    id: 2466,
  },
  {
    location_type: 'zip_code',
    location: '7464',
    location_name: 'Parsonz',
    latitude: 46.556,
    longitude: 9.5376,
    id: 2467,
  },
  {
    location_type: 'zip_code',
    location: '7472',
    location_name: 'Surava',
    latitude: 46.6583,
    longitude: 9.6172,
    id: 2468,
  },
  {
    location_type: 'zip_code',
    location: '7473',
    location_name: 'Alvaneu Bad',
    latitude: 46.6682,
    longitude: 9.6392,
    id: 2469,
  },
  {
    location_type: 'zip_code',
    location: '7477',
    location_name: 'Filisur',
    latitude: 46.6447,
    longitude: 9.6978,
    id: 2470,
  },
  {
    location_type: 'zip_code',
    location: '7482',
    location_name: 'Preda',
    latitude: 46.5875,
    longitude: 9.7666,
    id: 2471,
  },
  {
    location_type: 'zip_code',
    location: '7484',
    location_name: 'Latsch',
    latitude: 46.6383,
    longitude: 9.7545,
    id: 2472,
  },
  {
    location_type: 'zip_code',
    location: '7492',
    location_name: 'Alvaneu Dorf',
    latitude: 46.7124,
    longitude: 9.649,
    id: 2473,
  },
  {
    location_type: 'zip_code',
    location: '7493',
    location_name: 'Schmitten (Albula)',
    latitude: 46.7016,
    longitude: 9.6781,
    id: 2474,
  },
  {
    location_type: 'zip_code',
    location: '7710',
    location_name: 'Ospizio Bernina',
    latitude: 46.398,
    longitude: 10.0244,
    id: 2475,
  },
  {
    location_type: 'zip_code',
    location: '7741',
    location_name: 'S. Carlo GR',
    latitude: 46.3598,
    longitude: 10.09,
    id: 2476,
  },
  {
    location_type: 'zip_code',
    location: '7742',
    location_name: 'Sfaz\u00f9',
    latitude: 46.3985,
    longitude: 10.1168,
    id: 2477,
  },
  {
    location_type: 'zip_code',
    location: '7743',
    location_name: 'Miralago',
    latitude: 46.2731,
    longitude: 10.1007,
    id: 2478,
  },
  {
    location_type: 'zip_code',
    location: '7744',
    location_name: 'Campocologno',
    latitude: 46.2356,
    longitude: 10.1424,
    id: 2479,
  },
  {
    location_type: 'zip_code',
    location: '7745',
    location_name: 'Li Curt',
    latitude: 46.2955,
    longitude: 10.0263,
    id: 2480,
  },
  {
    location_type: 'zip_code',
    location: '7746',
    location_name: 'Le Prese',
    latitude: 46.2851,
    longitude: 10.084,
    id: 2481,
  },
  {
    location_type: 'zip_code',
    location: '7747',
    location_name: 'Viano',
    latitude: 46.2628,
    longitude: 10.1538,
    id: 2482,
  },
  {
    location_type: 'zip_code',
    location: '7748',
    location_name: 'Campascio',
    latitude: 46.2374,
    longitude: 10.0947,
    id: 2483,
  },
  {
    location_type: 'zip_code',
    location: '7404',
    location_name: 'Feldis/Veulden',
    latitude: 46.7973,
    longitude: 9.4356,
    id: 2484,
  },
  {
    location_type: 'zip_code',
    location: '7405',
    location_name: 'Rothenbrunnen',
    latitude: 46.7756,
    longitude: 9.4207,
    id: 2485,
  },
  {
    location_type: 'zip_code',
    location: '7407',
    location_name: 'Trans',
    latitude: 46.762,
    longitude: 9.4824,
    id: 2486,
  },
  {
    location_type: 'zip_code',
    location: '7408',
    location_name: 'Cazis',
    latitude: 46.7246,
    longitude: 9.431,
    id: 2487,
  },
  {
    location_type: 'zip_code',
    location: '7411',
    location_name: 'Sils im Domleschg',
    latitude: 46.6905,
    longitude: 9.4717,
    id: 2488,
  },
  {
    location_type: 'zip_code',
    location: '7412',
    location_name: 'Scharans',
    latitude: 46.7149,
    longitude: 9.4828,
    id: 2489,
  },
  {
    location_type: 'zip_code',
    location: '7413',
    location_name: 'F\u00fcrstenaubruck',
    latitude: 46.7145,
    longitude: 9.4507,
    id: 2490,
  },
  {
    location_type: 'zip_code',
    location: '7414',
    location_name: 'F\u00fcrstenau',
    latitude: 46.7227,
    longitude: 9.449,
    id: 2491,
  },
  {
    location_type: 'zip_code',
    location: '7415',
    location_name: 'Rodels',
    latitude: 46.7389,
    longitude: 9.4406,
    id: 2492,
  },
  {
    location_type: 'zip_code',
    location: '7416',
    location_name: 'Almens',
    latitude: 46.7426,
    longitude: 9.4823,
    id: 2493,
  },
  {
    location_type: 'zip_code',
    location: '7417',
    location_name: 'Paspels',
    latitude: 46.751,
    longitude: 9.457,
    id: 2494,
  },
  {
    location_type: 'zip_code',
    location: '7418',
    location_name: 'Tumegl/Tomils',
    latitude: 46.7641,
    longitude: 9.4445,
    id: 2495,
  },
  {
    location_type: 'zip_code',
    location: '7419',
    location_name: 'Scheid',
    latitude: 46.7839,
    longitude: 9.4706,
    id: 2496,
  },
  {
    location_type: 'zip_code',
    location: '7421',
    location_name: 'Summaprada',
    latitude: 46.7117,
    longitude: 9.4295,
    id: 2497,
  },
  {
    location_type: 'zip_code',
    location: '7422',
    location_name: 'Tartar',
    latitude: 46.721,
    longitude: 9.4195,
    id: 2498,
  },
  {
    location_type: 'zip_code',
    location: '7423',
    location_name: 'Sarn',
    latitude: 46.7252,
    longitude: 9.3883,
    id: 2499,
  },
  {
    location_type: 'zip_code',
    location: '7424',
    location_name: 'Pr\u00e4z',
    latitude: 46.7478,
    longitude: 9.3962,
    id: 2500,
  },
  {
    location_type: 'zip_code',
    location: '7425',
    location_name: 'Masein',
    latitude: 46.6956,
    longitude: 9.4224,
    id: 2501,
  },
  {
    location_type: 'zip_code',
    location: '7426',
    location_name: 'Flerden',
    latitude: 46.6864,
    longitude: 9.3986,
    id: 2502,
  },
  {
    location_type: 'zip_code',
    location: '7427',
    location_name: 'Urmein',
    latitude: 46.6909,
    longitude: 9.3978,
    id: 2503,
  },
  {
    location_type: 'zip_code',
    location: '7428',
    location_name: 'Tschappina',
    latitude: 46.6879,
    longitude: 9.3648,
    id: 2504,
  },
  {
    location_type: 'zip_code',
    location: '7430',
    location_name: 'Thusis',
    latitude: 46.6853,
    longitude: 9.4316,
    id: 2505,
  },
  {
    location_type: 'zip_code',
    location: '7432',
    location_name: 'Zillis',
    latitude: 46.6445,
    longitude: 9.4692,
    id: 2506,
  },
  {
    location_type: 'zip_code',
    location: '7433',
    location_name: 'Mathon',
    latitude: 46.6409,
    longitude: 9.3824,
    id: 2507,
  },
  {
    location_type: 'zip_code',
    location: '7434',
    location_name: 'Sufers',
    latitude: 46.5509,
    longitude: 9.3875,
    id: 2508,
  },
  {
    location_type: 'zip_code',
    location: '7435',
    location_name: 'Spl\u00fcgen',
    latitude: 46.5396,
    longitude: 9.3262,
    id: 2509,
  },
  {
    location_type: 'zip_code',
    location: '7436',
    location_name: 'Medels',
    latitude: 46.5466,
    longitude: 9.2785,
    id: 2510,
  },
  {
    location_type: 'zip_code',
    location: '7437',
    location_name: 'Nufenen',
    latitude: 46.5466,
    longitude: 9.2393,
    id: 2511,
  },
  {
    location_type: 'zip_code',
    location: '7438',
    location_name: 'Hinterrhein',
    latitude: 46.515,
    longitude: 9.1554,
    id: 2512,
  },
  {
    location_type: 'zip_code',
    location: '7440',
    location_name: 'Andeer',
    latitude: 46.5784,
    longitude: 9.414,
    id: 2513,
  },
  {
    location_type: 'zip_code',
    location: '7442',
    location_name: 'Clugin',
    latitude: 46.614,
    longitude: 9.4159,
    id: 2514,
  },
  {
    location_type: 'zip_code',
    location: '7443',
    location_name: 'Pignia',
    latitude: 46.6032,
    longitude: 9.4688,
    id: 2515,
  },
  {
    location_type: 'zip_code',
    location: '7444',
    location_name: 'Ausserferrera',
    latitude: 46.5547,
    longitude: 9.4638,
    id: 2516,
  },
  {
    location_type: 'zip_code',
    location: '7445',
    location_name: 'Innerferrera',
    latitude: 46.4914,
    longitude: 9.431,
    id: 2517,
  },
  {
    location_type: 'zip_code',
    location: '7446',
    location_name: 'Campsut-Cr\u00f6t',
    latitude: 46.464,
    longitude: 9.4854,
    id: 2518,
  },
  {
    location_type: 'zip_code',
    location: '7447',
    location_name: 'Am Bach (Avers)',
    latitude: 46.4252,
    longitude: 9.5407,
    id: 2519,
  },
  {
    location_type: 'zip_code',
    location: '7448',
    location_name: 'Juf',
    latitude: 46.4337,
    longitude: 9.5897,
    id: 2520,
  },
  {
    location_type: 'zip_code',
    location: '7012',
    location_name: 'Felsberg',
    latitude: 46.8602,
    longitude: 9.4622,
    id: 2521,
  },
  {
    location_type: 'zip_code',
    location: '7013',
    location_name: 'Domat/Ems',
    latitude: 46.8193,
    longitude: 9.4528,
    id: 2522,
  },
  {
    location_type: 'zip_code',
    location: '7014',
    location_name: 'Trin',
    latitude: 46.8777,
    longitude: 9.3234,
    id: 2523,
  },
  {
    location_type: 'zip_code',
    location: '7015',
    location_name: 'Tamins',
    latitude: 46.8418,
    longitude: 9.4121,
    id: 2524,
  },
  {
    location_type: 'zip_code',
    location: '7016',
    location_name: 'Trin Mulin',
    latitude: 46.8219,
    longitude: 9.3341,
    id: 2525,
  },
  {
    location_type: 'zip_code',
    location: '7017',
    location_name: 'Flims Dorf',
    latitude: 46.8551,
    longitude: 9.2685,
    id: 2526,
  },
  {
    location_type: 'zip_code',
    location: '7018',
    location_name: 'Flims Waldhaus',
    latitude: 46.8244,
    longitude: 9.2854,
    id: 2527,
  },
  {
    location_type: 'zip_code',
    location: '7019',
    location_name: 'Fidaz',
    latitude: 46.8449,
    longitude: 9.3178,
    id: 2528,
  },
  {
    location_type: 'zip_code',
    location: '7402',
    location_name: 'Bonaduz',
    latitude: 46.7593,
    longitude: 9.3717,
    id: 2529,
  },
  {
    location_type: 'zip_code',
    location: '7403',
    location_name: 'Rh\u00e4z\u00fcns',
    latitude: 46.7859,
    longitude: 9.395,
    id: 2530,
  },
  {
    location_type: 'zip_code',
    location: '7527',
    location_name: 'Brail',
    latitude: 46.6714,
    longitude: 10.0166,
    id: 2531,
  },
  {
    location_type: 'zip_code',
    location: '7530',
    location_name: 'Zernez',
    latitude: 46.6671,
    longitude: 10.1646,
    id: 2532,
  },
  {
    location_type: 'zip_code',
    location: '7532',
    location_name: 'Tschierv',
    latitude: 46.642,
    longitude: 10.299,
    id: 2533,
  },
  {
    location_type: 'zip_code',
    location: '7533',
    location_name: 'Fuldera',
    latitude: 46.6024,
    longitude: 10.3448,
    id: 2534,
  },
  {
    location_type: 'zip_code',
    location: '7534',
    location_name: 'L\u00fc',
    latitude: 46.6335,
    longitude: 10.369,
    id: 2535,
  },
  {
    location_type: 'zip_code',
    location: '7535',
    location_name: 'Valchava',
    latitude: 46.5966,
    longitude: 10.3811,
    id: 2536,
  },
  {
    location_type: 'zip_code',
    location: '7536',
    location_name: 'Sta. Maria V. M.',
    latitude: 46.5882,
    longitude: 10.2874,
    id: 2537,
  },
  {
    location_type: 'zip_code',
    location: '7537',
    location_name: 'M\u00fcstair',
    latitude: 46.6273,
    longitude: 10.4477,
    id: 2538,
  },
  {
    location_type: 'zip_code',
    location: '7542',
    location_name: 'Susch',
    latitude: 46.7497,
    longitude: 10.0311,
    id: 2539,
  },
  {
    location_type: 'zip_code',
    location: '7543',
    location_name: 'Lavin',
    latitude: 46.789,
    longitude: 10.1063,
    id: 2540,
  },
  {
    location_type: 'zip_code',
    location: '7545',
    location_name: 'Guarda',
    latitude: 46.8201,
    longitude: 10.1408,
    id: 2541,
  },
  {
    location_type: 'zip_code',
    location: '7546',
    location_name: 'Ardez',
    latitude: 46.7749,
    longitude: 10.1856,
    id: 2542,
  },
  {
    location_type: 'zip_code',
    location: '7550',
    location_name: 'Scuol',
    latitude: 46.7324,
    longitude: 10.3277,
    id: 2543,
  },
  {
    location_type: 'zip_code',
    location: '7551',
    location_name: 'Ftan',
    latitude: 46.8238,
    longitude: 10.2267,
    id: 2544,
  },
  {
    location_type: 'zip_code',
    location: '7552',
    location_name: 'Vulpera',
    latitude: 46.7872,
    longitude: 10.2862,
    id: 2545,
  },
  {
    location_type: 'zip_code',
    location: '7553',
    location_name: 'Tarasp',
    latitude: 46.7349,
    longitude: 10.2464,
    id: 2546,
  },
  {
    location_type: 'zip_code',
    location: '7554',
    location_name: 'Crusch',
    latitude: 46.8203,
    longitude: 10.3603,
    id: 2547,
  },
  {
    location_type: 'zip_code',
    location: '7556',
    location_name: 'Ramosch',
    latitude: 46.8641,
    longitude: 10.3232,
    id: 2548,
  },
  {
    location_type: 'zip_code',
    location: '7557',
    location_name: 'Vn\u00e0',
    latitude: 46.8731,
    longitude: 10.346,
    id: 2549,
  },
  {
    location_type: 'zip_code',
    location: '7558',
    location_name: 'Strada',
    latitude: 46.8363,
    longitude: 10.4331,
    id: 2550,
  },
  {
    location_type: 'zip_code',
    location: '7559',
    location_name: 'Tschlin',
    latitude: 46.9056,
    longitude: 10.4125,
    id: 2551,
  },
  {
    location_type: 'zip_code',
    location: '7560',
    location_name: 'Martina',
    latitude: 46.9118,
    longitude: 10.4575,
    id: 2552,
  },
  {
    location_type: 'zip_code',
    location: '7562',
    location_name: 'Samnaun-Compatsch',
    latitude: 46.9621,
    longitude: 10.3981,
    id: 2553,
  },
  {
    location_type: 'zip_code',
    location: '7563',
    location_name: 'Samnaun Dorf',
    latitude: 46.9472,
    longitude: 10.3594,
    id: 2554,
  },
  {
    location_type: 'zip_code',
    location: '7023',
    location_name: 'Haldenstein',
    latitude: 46.8791,
    longitude: 9.4989,
    id: 2555,
  },
  {
    location_type: 'zip_code',
    location: '7201',
    location_name: 'Untervaz Bahnhof',
    latitude: 46.9109,
    longitude: 9.5527,
    id: 2556,
  },
  {
    location_type: 'zip_code',
    location: '7202',
    location_name: 'Says',
    latitude: 46.8994,
    longitude: 9.6109,
    id: 2557,
  },
  {
    location_type: 'zip_code',
    location: '7203',
    location_name: 'Trimmis',
    latitude: 46.8899,
    longitude: 9.567,
    id: 2558,
  },
  {
    location_type: 'zip_code',
    location: '7204',
    location_name: 'Untervaz',
    latitude: 46.9214,
    longitude: 9.5177,
    id: 2559,
  },
  {
    location_type: 'zip_code',
    location: '7205',
    location_name: 'Zizers',
    latitude: 46.936,
    longitude: 9.5782,
    id: 2560,
  },
  {
    location_type: 'zip_code',
    location: '7206',
    location_name: 'Igis',
    latitude: 46.9441,
    longitude: 9.5821,
    id: 2561,
  },
  {
    location_type: 'zip_code',
    location: '7208',
    location_name: 'Malans GR',
    latitude: 46.9896,
    longitude: 9.5818,
    id: 2562,
  },
  {
    location_type: 'zip_code',
    location: '7302',
    location_name: 'Landquart',
    latitude: 46.9614,
    longitude: 9.5749,
    id: 2563,
  },
  {
    location_type: 'zip_code',
    location: '7303',
    location_name: 'Mastrils',
    latitude: 46.9602,
    longitude: 9.5365,
    id: 2564,
  },
  {
    location_type: 'zip_code',
    location: '7304',
    location_name: 'Maienfeld',
    latitude: 47.0192,
    longitude: 9.5398,
    id: 2565,
  },
  {
    location_type: 'zip_code',
    location: '7306',
    location_name: 'Fl\u00e4sch',
    latitude: 47.0396,
    longitude: 9.5025,
    id: 2566,
  },
  {
    location_type: 'zip_code',
    location: '7307',
    location_name: 'Jenins',
    latitude: 47.0078,
    longitude: 9.5656,
    id: 2567,
  },
  {
    location_type: 'zip_code',
    location: '7500',
    location_name: 'St. Moritz',
    latitude: 46.4855,
    longitude: 9.8335,
    id: 2568,
  },
  {
    location_type: 'zip_code',
    location: '7502',
    location_name: 'Bever',
    latitude: 46.5424,
    longitude: 9.7994,
    id: 2569,
  },
  {
    location_type: 'zip_code',
    location: '7503',
    location_name: 'Samedan',
    latitude: 46.5351,
    longitude: 9.8461,
    id: 2570,
  },
  {
    location_type: 'zip_code',
    location: '7504',
    location_name: 'Pontresina',
    latitude: 46.4337,
    longitude: 9.9407,
    id: 2571,
  },
  {
    location_type: 'zip_code',
    location: '7505',
    location_name: 'Celerina',
    latitude: 46.5023,
    longitude: 9.8691,
    id: 2572,
  },
  {
    location_type: 'zip_code',
    location: '7512',
    location_name: 'Champf\u00e8r',
    latitude: 46.4795,
    longitude: 9.7935,
    id: 2573,
  },
  {
    location_type: 'zip_code',
    location: '7513',
    location_name: 'Silvaplana-Surlej',
    latitude: 46.444,
    longitude: 9.8216,
    id: 2574,
  },
  {
    location_type: 'zip_code',
    location: '7514',
    location_name: 'Fex',
    latitude: 46.3755,
    longitude: 9.7897,
    id: 2575,
  },
  {
    location_type: 'zip_code',
    location: '7515',
    location_name: 'Sils/Segl Baselgia',
    latitude: 46.4363,
    longitude: 9.7435,
    id: 2576,
  },
  {
    location_type: 'zip_code',
    location: '7517',
    location_name: 'Plaun da Lej',
    latitude: 46.4304,
    longitude: 9.7101,
    id: 2577,
  },
  {
    location_type: 'zip_code',
    location: '7522',
    location_name: 'La Punt-Chamues-ch',
    latitude: 46.5387,
    longitude: 9.9598,
    id: 2578,
  },
  {
    location_type: 'zip_code',
    location: '7523',
    location_name: 'Madulain',
    latitude: 46.543,
    longitude: 9.9684,
    id: 2579,
  },
  {
    location_type: 'zip_code',
    location: '7524',
    location_name: 'Zuoz',
    latitude: 46.5984,
    longitude: 9.9344,
    id: 2580,
  },
  {
    location_type: 'zip_code',
    location: '7525',
    location_name: 'S-chanf',
    latitude: 46.5905,
    longitude: 10.0337,
    id: 2581,
  },
  {
    location_type: 'zip_code',
    location: '7526',
    location_name: 'Cinuos-chel',
    latitude: 46.6579,
    longitude: 9.9375,
    id: 2582,
  },
  {
    location_type: 'zip_code',
    location: '6534',
    location_name: 'S. Vittore',
    latitude: 46.2158,
    longitude: 9.1268,
    id: 2583,
  },
  {
    location_type: 'zip_code',
    location: '6535',
    location_name: 'Roveredo GR',
    latitude: 46.2013,
    longitude: 9.1507,
    id: 2584,
  },
  {
    location_type: 'zip_code',
    location: '6537',
    location_name: 'Grono',
    latitude: 46.2326,
    longitude: 9.1833,
    id: 2585,
  },
  {
    location_type: 'zip_code',
    location: '6538',
    location_name: 'Verdabbio',
    latitude: 46.2827,
    longitude: 9.1992,
    id: 2586,
  },
  {
    location_type: 'zip_code',
    location: '6540',
    location_name: 'Castaneda',
    latitude: 46.2563,
    longitude: 9.1316,
    id: 2587,
  },
  {
    location_type: 'zip_code',
    location: '6541',
    location_name: 'Sta. Maria',
    latitude: 46.2765,
    longitude: 9.1437,
    id: 2588,
  },
  {
    location_type: 'zip_code',
    location: '6542',
    location_name: 'Buseno',
    latitude: 46.2752,
    longitude: 9.1003,
    id: 2589,
  },
  {
    location_type: 'zip_code',
    location: '6543',
    location_name: 'Arvigo',
    latitude: 46.3115,
    longitude: 9.0933,
    id: 2590,
  },
  {
    location_type: 'zip_code',
    location: '6544',
    location_name: 'Braggio',
    latitude: 46.3009,
    longitude: 9.1336,
    id: 2591,
  },
  {
    location_type: 'zip_code',
    location: '6545',
    location_name: 'Selma',
    latitude: 46.3179,
    longitude: 9.1351,
    id: 2592,
  },
  {
    location_type: 'zip_code',
    location: '6546',
    location_name: 'Cauco',
    latitude: 46.3398,
    longitude: 9.1142,
    id: 2593,
  },
  {
    location_type: 'zip_code',
    location: '6547',
    location_name: 'Augio',
    latitude: 46.3604,
    longitude: 9.1341,
    id: 2594,
  },
  {
    location_type: 'zip_code',
    location: '6548',
    location_name: 'Rossa',
    latitude: 46.4089,
    longitude: 9.1315,
    id: 2595,
  },
  {
    location_type: 'zip_code',
    location: '6549',
    location_name: 'Laura',
    latitude: 46.21,
    longitude: 9.1042,
    id: 2596,
  },
  {
    location_type: 'zip_code',
    location: '6556',
    location_name: 'Leggia',
    latitude: 46.2498,
    longitude: 9.1878,
    id: 2597,
  },
  {
    location_type: 'zip_code',
    location: '6557',
    location_name: 'Cama',
    latitude: 46.2607,
    longitude: 9.2029,
    id: 2598,
  },
  {
    location_type: 'zip_code',
    location: '6558',
    location_name: 'Lostallo',
    latitude: 46.3034,
    longitude: 9.2256,
    id: 2599,
  },
  {
    location_type: 'zip_code',
    location: '6562',
    location_name: 'Soazza',
    latitude: 46.3501,
    longitude: 9.2294,
    id: 2600,
  },
  {
    location_type: 'zip_code',
    location: '6563',
    location_name: 'Mesocco',
    latitude: 46.4023,
    longitude: 9.2213,
    id: 2601,
  },
  {
    location_type: 'zip_code',
    location: '6565',
    location_name: 'S. Bernardino',
    latitude: 46.4676,
    longitude: 9.1804,
    id: 2602,
  },
  {
    location_type: 'zip_code',
    location: '7000',
    location_name: 'Chur',
    latitude: 46.8535,
    longitude: 9.5309,
    id: 2603,
  },
  {
    location_type: 'zip_code',
    location: '7001',
    location_name: 'Chur',
    latitude: 46.85,
    longitude: 9.5,
    id: 2604,
  },
  {
    location_type: 'zip_code',
    location: '7002',
    location_name: 'Chur',
    latitude: 46.8449,
    longitude: 9.5317,
    id: 2605,
  },
  {
    location_type: 'zip_code',
    location: '7003',
    location_name: 'Chur Postauto GR Reg. Chur',
    latitude: 46.85,
    longitude: 9.5,
    id: 2606,
  },
  {
    location_type: 'zip_code',
    location: '7004',
    location_name: 'Chur',
    latitude: 46.85,
    longitude: 9.5,
    id: 2607,
  },
  {
    location_type: 'zip_code',
    location: '7006',
    location_name: 'Chur',
    latitude: 46.85,
    longitude: 9.5,
    id: 2608,
  },
  {
    location_type: 'zip_code',
    location: '7007',
    location_name: 'Chur',
    latitude: 46.85,
    longitude: 9.5,
    id: 2609,
  },
  {
    location_type: 'zip_code',
    location: '7026',
    location_name: 'Maladers',
    latitude: 46.8438,
    longitude: 9.5699,
    id: 2610,
  },
  {
    location_type: 'zip_code',
    location: '7027',
    location_name: 'Castiel',
    latitude: 46.8496,
    longitude: 9.6158,
    id: 2611,
  },
  {
    location_type: 'zip_code',
    location: '7028',
    location_name: 'St. Peter',
    latitude: 46.8473,
    longitude: 9.6553,
    id: 2612,
  },
  {
    location_type: 'zip_code',
    location: '7029',
    location_name: 'Peist',
    latitude: 46.8402,
    longitude: 9.6865,
    id: 2613,
  },
  {
    location_type: 'zip_code',
    location: '7050',
    location_name: 'Arosa',
    latitude: 46.7717,
    longitude: 9.6753,
    id: 2614,
  },
  {
    location_type: 'zip_code',
    location: '7056',
    location_name: 'Molinis',
    latitude: 46.8181,
    longitude: 9.6477,
    id: 2615,
  },
  {
    location_type: 'zip_code',
    location: '7057',
    location_name: 'Langwies',
    latitude: 46.8207,
    longitude: 9.7475,
    id: 2616,
  },
  {
    location_type: 'zip_code',
    location: '7058',
    location_name: 'Litzir\u00fcti',
    latitude: 46.7988,
    longitude: 9.7014,
    id: 2617,
  },
  {
    location_type: 'zip_code',
    location: '7062',
    location_name: 'Passugg-Araschgen',
    latitude: 46.8227,
    longitude: 9.5496,
    id: 2618,
  },
  {
    location_type: 'zip_code',
    location: '7063',
    location_name: 'Praden',
    latitude: 46.8208,
    longitude: 9.584,
    id: 2619,
  },
  {
    location_type: 'zip_code',
    location: '7064',
    location_name: 'Tschiertschen',
    latitude: 46.7941,
    longitude: 9.607,
    id: 2620,
  },
  {
    location_type: 'zip_code',
    location: '7074',
    location_name: 'Malix',
    latitude: 46.8125,
    longitude: 9.5179,
    id: 2621,
  },
  {
    location_type: 'zip_code',
    location: '7075',
    location_name: 'Churwalden',
    latitude: 46.7778,
    longitude: 9.5303,
    id: 2622,
  },
  {
    location_type: 'zip_code',
    location: '7076',
    location_name: 'Parpan',
    latitude: 46.7795,
    longitude: 9.5688,
    id: 2623,
  },
  {
    location_type: 'zip_code',
    location: '7212',
    location_name: 'Seewis Dorf',
    latitude: 47.018,
    longitude: 9.6368,
    id: 2624,
  },
  {
    location_type: 'zip_code',
    location: '7213',
    location_name: 'Valzeina',
    latitude: 46.9673,
    longitude: 9.6161,
    id: 2625,
  },
  {
    location_type: 'zip_code',
    location: '7214',
    location_name: 'Gr\u00fcsch',
    latitude: 46.9603,
    longitude: 9.643,
    id: 2626,
  },
  {
    location_type: 'zip_code',
    location: '7215',
    location_name: 'Fanas',
    latitude: 47.0038,
    longitude: 9.6804,
    id: 2627,
  },
  {
    location_type: 'zip_code',
    location: '7220',
    location_name: 'Schiers',
    latitude: 46.983,
    longitude: 9.7272,
    id: 2628,
  },
  {
    location_type: 'zip_code',
    location: '7222',
    location_name: 'Mittellunden',
    latitude: 46.9501,
    longitude: 9.7128,
    id: 2629,
  },
  {
    location_type: 'zip_code',
    location: '7223',
    location_name: 'Buchen',
    latitude: 46.9385,
    longitude: 9.7236,
    id: 2630,
  },
  {
    location_type: 'zip_code',
    location: '7224',
    location_name: 'Putz',
    latitude: 46.9271,
    longitude: 9.743,
    id: 2631,
  },
  {
    location_type: 'zip_code',
    location: '7226',
    location_name: 'Fajauna',
    latitude: 46.9699,
    longitude: 9.7011,
    id: 2632,
  },
  {
    location_type: 'zip_code',
    location: '7228',
    location_name: 'Pusserein',
    latitude: 46.9909,
    longitude: 9.7128,
    id: 2633,
  },
  {
    location_type: 'zip_code',
    location: '7231',
    location_name: 'Pragg-Jenaz',
    latitude: 46.9398,
    longitude: 9.698,
    id: 2634,
  },
  {
    location_type: 'zip_code',
    location: '7232',
    location_name: 'Furna',
    latitude: 46.9115,
    longitude: 9.6604,
    id: 2635,
  },
  {
    location_type: 'zip_code',
    location: '7233',
    location_name: 'Jenaz',
    latitude: 46.898,
    longitude: 9.7054,
    id: 2636,
  },
  {
    location_type: 'zip_code',
    location: '7235',
    location_name: 'Fideris',
    latitude: 46.8879,
    longitude: 9.7369,
    id: 2637,
  },
  {
    location_type: 'zip_code',
    location: '7240',
    location_name: 'K\u00fcblis',
    latitude: 46.9246,
    longitude: 9.7931,
    id: 2638,
  },
  {
    location_type: 'zip_code',
    location: '7241',
    location_name: 'Conters',
    latitude: 46.8846,
    longitude: 9.7873,
    id: 2639,
  },
  {
    location_type: 'zip_code',
    location: '7242',
    location_name: 'Luzein',
    latitude: 46.9208,
    longitude: 9.7625,
    id: 2640,
  },
  {
    location_type: 'zip_code',
    location: '7243',
    location_name: 'Pany',
    latitude: 46.9491,
    longitude: 9.7706,
    id: 2641,
  },
  {
    location_type: 'zip_code',
    location: '7244',
    location_name: 'Gadenst\u00e4tt',
    latitude: 46.9443,
    longitude: 9.79,
    id: 2642,
  },
  {
    location_type: 'zip_code',
    location: '7245',
    location_name: 'Ascharina',
    latitude: 46.9487,
    longitude: 9.8208,
    id: 2643,
  },
  {
    location_type: 'zip_code',
    location: '7246',
    location_name: 'St. Ant\u00f6nien',
    latitude: 46.9779,
    longitude: 9.8295,
    id: 2644,
  },
  {
    location_type: 'zip_code',
    location: '7247',
    location_name: 'Saas im Pr\u00e4ttigau',
    latitude: 46.91,
    longitude: 9.8448,
    id: 2645,
  },
  {
    location_type: 'zip_code',
    location: '7249',
    location_name: 'Serneus',
    latitude: 46.8883,
    longitude: 9.8303,
    id: 2646,
  },
  {
    location_type: 'zip_code',
    location: '7250',
    location_name: 'Klosters',
    latitude: 46.837,
    longitude: 9.9764,
    id: 2647,
  },
  {
    location_type: 'zip_code',
    location: '7252',
    location_name: 'Klosters Dorf',
    latitude: 46.8989,
    longitude: 9.9219,
    id: 2648,
  },
  {
    location_type: 'zip_code',
    location: '7260',
    location_name: 'Davos Dorf',
    latitude: 46.7783,
    longitude: 9.879,
    id: 2649,
  },
  {
    location_type: 'zip_code',
    location: '7265',
    location_name: 'Davos Wolfgang',
    latitude: 46.8374,
    longitude: 9.8538,
    id: 2650,
  },
  {
    location_type: 'zip_code',
    location: '7270',
    location_name: 'Davos Platz',
    latitude: 46.7858,
    longitude: 9.8154,
    id: 2651,
  },
  {
    location_type: 'zip_code',
    location: '7272',
    location_name: 'Davos Clavadel',
    latitude: 46.7203,
    longitude: 9.8487,
    id: 2652,
  },
  {
    location_type: 'zip_code',
    location: '7276',
    location_name: 'Davos Frauenkirch',
    latitude: 46.7802,
    longitude: 9.7688,
    id: 2653,
  },
  {
    location_type: 'zip_code',
    location: '7277',
    location_name: 'Davos Glaris',
    latitude: 46.7392,
    longitude: 9.7672,
    id: 2654,
  },
  {
    location_type: 'zip_code',
    location: '7278',
    location_name: 'Davos Monstein',
    latitude: 46.6988,
    longitude: 9.7814,
    id: 2655,
  },
  {
    location_type: 'zip_code',
    location: '7494',
    location_name: 'Davos Wiesen',
    latitude: 46.7144,
    longitude: 9.7186,
    id: 2656,
  },
  {
    location_type: 'zip_code',
    location: '7031',
    location_name: 'Laax GR',
    latitude: 46.8113,
    longitude: 9.2661,
    id: 2657,
  },
  {
    location_type: 'zip_code',
    location: '7032',
    location_name: 'Laax GR 2',
    latitude: 46.8542,
    longitude: 9.2106,
    id: 2658,
  },
  {
    location_type: 'zip_code',
    location: '7104',
    location_name: 'Versam',
    latitude: 46.7677,
    longitude: 9.338,
    id: 2659,
  },
  {
    location_type: 'zip_code',
    location: '7106',
    location_name: 'Tenna',
    latitude: 46.7473,
    longitude: 9.333,
    id: 2660,
  },
  {
    location_type: 'zip_code',
    location: '7107',
    location_name: 'Safien Platz',
    latitude: 46.7105,
    longitude: 9.3265,
    id: 2661,
  },
  {
    location_type: 'zip_code',
    location: '7109',
    location_name: 'Thalkirch',
    latitude: 46.6348,
    longitude: 9.2857,
    id: 2662,
  },
  {
    location_type: 'zip_code',
    location: '7110',
    location_name: 'Peiden',
    latitude: 46.7166,
    longitude: 9.194,
    id: 2663,
  },
  {
    location_type: 'zip_code',
    location: '7111',
    location_name: 'Pitasch',
    latitude: 46.7228,
    longitude: 9.242,
    id: 2664,
  },
  {
    location_type: 'zip_code',
    location: '7112',
    location_name: 'Duvin',
    latitude: 46.6923,
    longitude: 9.2562,
    id: 2665,
  },
  {
    location_type: 'zip_code',
    location: '7113',
    location_name: 'Camuns',
    latitude: 46.6973,
    longitude: 9.2172,
    id: 2666,
  },
  {
    location_type: 'zip_code',
    location: '7114',
    location_name: 'Uors (Lumnezia)',
    latitude: 46.7035,
    longitude: 9.189,
    id: 2667,
  },
  {
    location_type: 'zip_code',
    location: '7115',
    location_name: 'Surcasti',
    latitude: 46.6803,
    longitude: 9.1682,
    id: 2668,
  },
  {
    location_type: 'zip_code',
    location: '7116',
    location_name: 'St. Martin',
    latitude: 46.6597,
    longitude: 9.1982,
    id: 2669,
  },
  {
    location_type: 'zip_code',
    location: '7122',
    location_name: 'Valendas',
    latitude: 46.7668,
    longitude: 9.295,
    id: 2670,
  },
  {
    location_type: 'zip_code',
    location: '7126',
    location_name: 'Castrisch',
    latitude: 46.7723,
    longitude: 9.2416,
    id: 2671,
  },
  {
    location_type: 'zip_code',
    location: '7127',
    location_name: 'Sevgein',
    latitude: 46.7575,
    longitude: 9.2262,
    id: 2672,
  },
  {
    location_type: 'zip_code',
    location: '7128',
    location_name: 'Riein',
    latitude: 46.7423,
    longitude: 9.2575,
    id: 2673,
  },
  {
    location_type: 'zip_code',
    location: '7130',
    location_name: 'Schnaus',
    latitude: 46.7999,
    longitude: 9.1803,
    id: 2674,
  },
  {
    location_type: 'zip_code',
    location: '7132',
    location_name: 'Vals',
    latitude: 46.5734,
    longitude: 9.1336,
    id: 2675,
  },
  {
    location_type: 'zip_code',
    location: '7133',
    location_name: 'Obersaxen Affeier',
    latitude: 46.7542,
    longitude: 9.1214,
    id: 2676,
  },
  {
    location_type: 'zip_code',
    location: '7134',
    location_name: 'Obersaxen Meierhof',
    latitude: 46.7152,
    longitude: 9.0891,
    id: 2677,
  },
  {
    location_type: 'zip_code',
    location: '7135',
    location_name: 'Obersaxen Giraniga',
    latitude: 46.7398,
    longitude: 9.0796,
    id: 2678,
  },
  {
    location_type: 'zip_code',
    location: '7136',
    location_name: 'Obers Friggah\u00fcs',
    latitude: 46.7214,
    longitude: 9.0451,
    id: 2679,
  },
  {
    location_type: 'zip_code',
    location: '7137',
    location_name: 'Flond',
    latitude: 46.7666,
    longitude: 9.1599,
    id: 2680,
  },
  {
    location_type: 'zip_code',
    location: '7138',
    location_name: 'Surcuolm',
    latitude: 46.7505,
    longitude: 9.1512,
    id: 2681,
  },
  {
    location_type: 'zip_code',
    location: '7141',
    location_name: 'Luven',
    latitude: 46.7552,
    longitude: 9.1891,
    id: 2682,
  },
  {
    location_type: 'zip_code',
    location: '7142',
    location_name: 'Cumbel',
    latitude: 46.7353,
    longitude: 9.2002,
    id: 2683,
  },
  {
    location_type: 'zip_code',
    location: '7143',
    location_name: 'Morissen',
    latitude: 46.7342,
    longitude: 9.1717,
    id: 2684,
  },
  {
    location_type: 'zip_code',
    location: '7144',
    location_name: 'Vella',
    latitude: 46.7211,
    longitude: 9.1559,
    id: 2685,
  },
  {
    location_type: 'zip_code',
    location: '7145',
    location_name: 'Degen',
    latitude: 46.7055,
    longitude: 9.1717,
    id: 2686,
  },
  {
    location_type: 'zip_code',
    location: '7146',
    location_name: 'Vattiz',
    latitude: 46.7138,
    longitude: 9.1388,
    id: 2687,
  },
  {
    location_type: 'zip_code',
    location: '7147',
    location_name: 'Vignogn',
    latitude: 46.7005,
    longitude: 9.135,
    id: 2688,
  },
  {
    location_type: 'zip_code',
    location: '7148',
    location_name: 'Lumbrein',
    latitude: 46.6634,
    longitude: 9.0366,
    id: 2689,
  },
  {
    location_type: 'zip_code',
    location: '7149',
    location_name: 'Vrin',
    latitude: 46.6234,
    longitude: 9.0409,
    id: 2690,
  },
  {
    location_type: 'zip_code',
    location: '7151',
    location_name: 'Schluein',
    latitude: 46.7855,
    longitude: 9.2246,
    id: 2691,
  },
  {
    location_type: 'zip_code',
    location: '7152',
    location_name: 'Sagogn',
    latitude: 46.7987,
    longitude: 9.281,
    id: 2692,
  },
  {
    location_type: 'zip_code',
    location: '7153',
    location_name: 'Falera',
    latitude: 46.8062,
    longitude: 9.228,
    id: 2693,
  },
  {
    location_type: 'zip_code',
    location: '7154',
    location_name: 'Ruschein',
    latitude: 46.8027,
    longitude: 9.1753,
    id: 2694,
  },
  {
    location_type: 'zip_code',
    location: '7155',
    location_name: 'Ladir',
    latitude: 46.8197,
    longitude: 9.1863,
    id: 2695,
  },
  {
    location_type: 'zip_code',
    location: '7156',
    location_name: 'Pigniu',
    latitude: 46.8351,
    longitude: 9.1172,
    id: 2696,
  },
  {
    location_type: 'zip_code',
    location: '7157',
    location_name: 'Siat',
    latitude: 46.7975,
    longitude: 9.1535,
    id: 2697,
  },
  {
    location_type: 'zip_code',
    location: '7158',
    location_name: 'Waltensburg/Vuorz',
    latitude: 46.8031,
    longitude: 9.0868,
    id: 2698,
  },
  {
    location_type: 'zip_code',
    location: '7159',
    location_name: 'Andiast',
    latitude: 46.8247,
    longitude: 9.0932,
    id: 2699,
  },
  {
    location_type: 'zip_code',
    location: '7162',
    location_name: 'Tavanasa',
    latitude: 46.7568,
    longitude: 9.0777,
    id: 2700,
  },
  {
    location_type: 'zip_code',
    location: '7163',
    location_name: 'Danis',
    latitude: 46.7558,
    longitude: 9.0546,
    id: 2701,
  },
  {
    location_type: 'zip_code',
    location: '7164',
    location_name: 'Dardin',
    latitude: 46.758,
    longitude: 9.0372,
    id: 2702,
  },
  {
    location_type: 'zip_code',
    location: '7165',
    location_name: 'Breil/Brigels',
    latitude: 46.7984,
    longitude: 9.0158,
    id: 2703,
  },
  {
    location_type: 'zip_code',
    location: '7166',
    location_name: 'Trun',
    latitude: 46.7523,
    longitude: 8.9807,
    id: 2704,
  },
  {
    location_type: 'zip_code',
    location: '7167',
    location_name: 'Zignau',
    latitude: 46.7135,
    longitude: 8.9921,
    id: 2705,
  },
  {
    location_type: 'zip_code',
    location: '7168',
    location_name: 'Schlans',
    latitude: 46.7667,
    longitude: 9.0018,
    id: 2706,
  },
  {
    location_type: 'zip_code',
    location: '7172',
    location_name: 'Rabius',
    latitude: 46.7622,
    longitude: 8.941,
    id: 2707,
  },
  {
    location_type: 'zip_code',
    location: '7173',
    location_name: 'Surrein',
    latitude: 46.6771,
    longitude: 8.9655,
    id: 2708,
  },
  {
    location_type: 'zip_code',
    location: '7174',
    location_name: 'S. Benedetg',
    latitude: 46.7584,
    longitude: 8.9301,
    id: 2709,
  },
  {
    location_type: 'zip_code',
    location: '7175',
    location_name: 'Sumvitg',
    latitude: 46.7555,
    longitude: 8.9166,
    id: 2710,
  },
  {
    location_type: 'zip_code',
    location: '7176',
    location_name: 'Cumpadials',
    latitude: 46.7045,
    longitude: 8.9185,
    id: 2711,
  },
  {
    location_type: 'zip_code',
    location: '7180',
    location_name: 'Disentis/Must\u00e9r',
    latitude: 46.7457,
    longitude: 8.8427,
    id: 2712,
  },
  {
    location_type: 'zip_code',
    location: '7182',
    location_name: 'Cavardiras',
    latitude: 46.7034,
    longitude: 8.889,
    id: 2713,
  },
  {
    location_type: 'zip_code',
    location: '7183',
    location_name: 'Mump\u00e9 Medel',
    latitude: 46.6873,
    longitude: 8.8472,
    id: 2714,
  },
  {
    location_type: 'zip_code',
    location: '7184',
    location_name: 'Curaglia',
    latitude: 46.6776,
    longitude: 8.8738,
    id: 2715,
  },
  {
    location_type: 'zip_code',
    location: '7185',
    location_name: 'Platta',
    latitude: 46.617,
    longitude: 8.86,
    id: 2716,
  },
  {
    location_type: 'zip_code',
    location: '7186',
    location_name: 'Segnas',
    latitude: 46.6868,
    longitude: 8.8147,
    id: 2717,
  },
  {
    location_type: 'zip_code',
    location: '7187',
    location_name: 'Camischolas',
    latitude: 46.6815,
    longitude: 8.7614,
    id: 2718,
  },
  {
    location_type: 'zip_code',
    location: '7188',
    location_name: 'Sedrun',
    latitude: 46.6229,
    longitude: 8.7504,
    id: 2719,
  },
  {
    location_type: 'zip_code',
    location: '7189',
    location_name: 'Rueras',
    latitude: 46.677,
    longitude: 8.7131,
    id: 2720,
  },
  {
    location_type: 'zip_code',
    location: '2800',
    location_name: 'Del\u00e9mont',
    latitude: 47.3685,
    longitude: 7.3325,
    id: 2721,
  },
  {
    location_type: 'zip_code',
    location: '2802',
    location_name: 'Develier',
    latitude: 47.3633,
    longitude: 7.2859,
    id: 2722,
  },
  {
    location_type: 'zip_code',
    location: '2803',
    location_name: 'Bourrignon',
    latitude: 47.3941,
    longitude: 7.2518,
    id: 2723,
  },
  {
    location_type: 'zip_code',
    location: '2805',
    location_name: 'Soyhi\u00e8res',
    latitude: 47.397,
    longitude: 7.3822,
    id: 2724,
  },
  {
    location_type: 'zip_code',
    location: '2806',
    location_name: 'Mettembert',
    latitude: 47.392,
    longitude: 7.3333,
    id: 2725,
  },
  {
    location_type: 'zip_code',
    location: '2807',
    location_name: 'Pleigne',
    latitude: 47.4154,
    longitude: 7.2698,
    id: 2726,
  },
  {
    location_type: 'zip_code',
    location: '2812',
    location_name: 'Movelier',
    latitude: 47.4105,
    longitude: 7.331,
    id: 2727,
  },
  {
    location_type: 'zip_code',
    location: '2813',
    location_name: 'Ederswiler',
    latitude: 47.4268,
    longitude: 7.3258,
    id: 2728,
  },
  {
    location_type: 'zip_code',
    location: '2822',
    location_name: 'Courroux',
    latitude: 47.3669,
    longitude: 7.3813,
    id: 2729,
  },
  {
    location_type: 'zip_code',
    location: '2823',
    location_name: 'Courcelon',
    latitude: 47.3657,
    longitude: 7.4049,
    id: 2730,
  },
  {
    location_type: 'zip_code',
    location: '2824',
    location_name: 'Vicques',
    latitude: 47.349,
    longitude: 7.4247,
    id: 2731,
  },
  {
    location_type: 'zip_code',
    location: '2825',
    location_name: 'Courchapoix',
    latitude: 47.3511,
    longitude: 7.4516,
    id: 2732,
  },
  {
    location_type: 'zip_code',
    location: '2826',
    location_name: 'Corban',
    latitude: 47.3525,
    longitude: 7.4737,
    id: 2733,
  },
  {
    location_type: 'zip_code',
    location: '2828',
    location_name: 'Montsevelier',
    latitude: 47.3599,
    longitude: 7.5107,
    id: 2734,
  },
  {
    location_type: 'zip_code',
    location: '2829',
    location_name: 'Vermes',
    latitude: 47.3192,
    longitude: 7.4938,
    id: 2735,
  },
  {
    location_type: 'zip_code',
    location: '2830',
    location_name: 'Vellerat',
    latitude: 47.3149,
    longitude: 7.3635,
    id: 2736,
  },
  {
    location_type: 'zip_code',
    location: '2832',
    location_name: 'Rebeuvelier',
    latitude: 47.3209,
    longitude: 7.4169,
    id: 2737,
  },
  {
    location_type: 'zip_code',
    location: '2842',
    location_name: 'Rossemaison',
    latitude: 47.3453,
    longitude: 7.344,
    id: 2738,
  },
  {
    location_type: 'zip_code',
    location: '2843',
    location_name: 'Ch\u00c2tillon JU',
    latitude: 47.322,
    longitude: 7.3445,
    id: 2739,
  },
  {
    location_type: 'zip_code',
    location: '2852',
    location_name: 'Court\u00e9telle',
    latitude: 47.3318,
    longitude: 7.3173,
    id: 2740,
  },
  {
    location_type: 'zip_code',
    location: '2853',
    location_name: 'Courfaivre',
    latitude: 47.3305,
    longitude: 7.2814,
    id: 2741,
  },
  {
    location_type: 'zip_code',
    location: '2854',
    location_name: 'Bassecourt',
    latitude: 47.3343,
    longitude: 7.2375,
    id: 2742,
  },
  {
    location_type: 'zip_code',
    location: '2855',
    location_name: 'Glovelier',
    latitude: 47.324,
    longitude: 7.1858,
    id: 2743,
  },
  {
    location_type: 'zip_code',
    location: '2856',
    location_name: 'Bo\u00e9court',
    latitude: 47.3582,
    longitude: 7.2163,
    id: 2744,
  },
  {
    location_type: 'zip_code',
    location: '2857',
    location_name: 'Montavon',
    latitude: 47.3716,
    longitude: 7.2366,
    id: 2745,
  },
  {
    location_type: 'zip_code',
    location: '2863',
    location_name: 'Undervelier',
    latitude: 47.3041,
    longitude: 7.2147,
    id: 2746,
  },
  {
    location_type: 'zip_code',
    location: '2864',
    location_name: 'Soulce',
    latitude: 47.3,
    longitude: 7.2911,
    id: 2747,
  },
  {
    location_type: 'zip_code',
    location: '2873',
    location_name: 'Saulcy',
    latitude: 47.304,
    longitude: 7.1656,
    id: 2748,
  },
  {
    location_type: 'zip_code',
    location: '2336',
    location_name: 'Les Bois',
    latitude: 47.178,
    longitude: 6.8981,
    id: 2749,
  },
  {
    location_type: 'zip_code',
    location: '2338',
    location_name: 'Muriaux',
    latitude: 47.2445,
    longitude: 6.9849,
    id: 2750,
  },
  {
    location_type: 'zip_code',
    location: '2340',
    location_name: 'Le Noirmont',
    latitude: 47.2155,
    longitude: 6.9443,
    id: 2751,
  },
  {
    location_type: 'zip_code',
    location: '2345',
    location_name: 'Le Cerneux-Veusil',
    latitude: 47.1805,
    longitude: 6.9682,
    id: 2752,
  },
  {
    location_type: 'zip_code',
    location: '2350',
    location_name: 'Saignel\u00e9gier',
    latitude: 47.2459,
    longitude: 7.028,
    id: 2753,
  },
  {
    location_type: 'zip_code',
    location: '2353',
    location_name: 'Les Pommerats',
    latitude: 47.2881,
    longitude: 6.9882,
    id: 2754,
  },
  {
    location_type: 'zip_code',
    location: '2354',
    location_name: 'Goumois',
    latitude: 47.2523,
    longitude: 6.9647,
    id: 2755,
  },
  {
    location_type: 'zip_code',
    location: '2360',
    location_name: 'Le B\u00e9mont JU',
    latitude: 47.2617,
    longitude: 7.0334,
    id: 2756,
  },
  {
    location_type: 'zip_code',
    location: '2362',
    location_name: 'Montfavergier',
    latitude: 47.3057,
    longitude: 7.0882,
    id: 2757,
  },
  {
    location_type: 'zip_code',
    location: '2363',
    location_name: 'Les Enfers',
    latitude: 47.2883,
    longitude: 7.0408,
    id: 2758,
  },
  {
    location_type: 'zip_code',
    location: '2364',
    location_name: 'St-Brais',
    latitude: 47.3066,
    longitude: 7.1222,
    id: 2759,
  },
  {
    location_type: 'zip_code',
    location: '2714',
    location_name: 'Le Pr\u00e9dame',
    latitude: 47.2543,
    longitude: 7.1004,
    id: 2760,
  },
  {
    location_type: 'zip_code',
    location: '2718',
    location_name: 'Lajoux JU',
    latitude: 47.2783,
    longitude: 7.131,
    id: 2761,
  },
  {
    location_type: 'zip_code',
    location: '2887',
    location_name: 'Soubey',
    latitude: 47.3109,
    longitude: 7.0473,
    id: 2762,
  },
  {
    location_type: 'zip_code',
    location: '2882',
    location_name: 'St-Ursanne',
    latitude: 47.3418,
    longitude: 7.13,
    id: 2763,
  },
  {
    location_type: 'zip_code',
    location: '2883',
    location_name: 'Montmelon',
    latitude: 47.3459,
    longitude: 7.172,
    id: 2764,
  },
  {
    location_type: 'zip_code',
    location: '2884',
    location_name: 'Montenol',
    latitude: 47.3502,
    longitude: 7.1427,
    id: 2765,
  },
  {
    location_type: 'zip_code',
    location: '2885',
    location_name: 'Epauvillers',
    latitude: 47.333,
    longitude: 7.1137,
    id: 2766,
  },
  {
    location_type: 'zip_code',
    location: '2886',
    location_name: 'Epiquerez',
    latitude: 47.3294,
    longitude: 7.0705,
    id: 2767,
  },
  {
    location_type: 'zip_code',
    location: '2888',
    location_name: 'Seleute',
    latitude: 47.3685,
    longitude: 7.1109,
    id: 2768,
  },
  {
    location_type: 'zip_code',
    location: '2889',
    location_name: 'Ocourt',
    latitude: 47.3528,
    longitude: 7.0683,
    id: 2769,
  },
  {
    location_type: 'zip_code',
    location: '2900',
    location_name: 'Porrentruy',
    latitude: 47.4207,
    longitude: 7.0685,
    id: 2770,
  },
  {
    location_type: 'zip_code',
    location: '2902',
    location_name: 'Fontenais',
    latitude: 47.3991,
    longitude: 7.0786,
    id: 2771,
  },
  {
    location_type: 'zip_code',
    location: '2903',
    location_name: 'Villars-Fontenais',
    latitude: 47.383,
    longitude: 7.0755,
    id: 2772,
  },
  {
    location_type: 'zip_code',
    location: '2904',
    location_name: 'Bressaucourt',
    latitude: 47.3833,
    longitude: 7.0378,
    id: 2773,
  },
  {
    location_type: 'zip_code',
    location: '2905',
    location_name: 'Courtedoux',
    latitude: 47.4117,
    longitude: 7.0289,
    id: 2774,
  },
  {
    location_type: 'zip_code',
    location: '2906',
    location_name: 'Chevenez',
    latitude: 47.392,
    longitude: 6.9967,
    id: 2775,
  },
  {
    location_type: 'zip_code',
    location: '2907',
    location_name: 'Rocourt',
    latitude: 47.3887,
    longitude: 6.9605,
    id: 2776,
  },
  {
    location_type: 'zip_code',
    location: '2908',
    location_name: 'Grandfontaine',
    latitude: 47.3929,
    longitude: 6.9316,
    id: 2777,
  },
  {
    location_type: 'zip_code',
    location: '2912',
    location_name: 'Roche-d\'Or',
    latitude: 47.3657,
    longitude: 6.9558,
    id: 2778,
  },
  {
    location_type: 'zip_code',
    location: '2914',
    location_name: 'Damvant',
    latitude: 47.3689,
    longitude: 6.8953,
    id: 2779,
  },
  {
    location_type: 'zip_code',
    location: '2915',
    location_name: 'Bure',
    latitude: 47.44,
    longitude: 6.9956,
    id: 2780,
  },
  {
    location_type: 'zip_code',
    location: '2916',
    location_name: 'Fahy',
    latitude: 47.4196,
    longitude: 6.9585,
    id: 2781,
  },
  {
    location_type: 'zip_code',
    location: '2922',
    location_name: 'Courchavon',
    latitude: 47.4381,
    longitude: 7.0447,
    id: 2782,
  },
  {
    location_type: 'zip_code',
    location: '2923',
    location_name: 'Courtema\u00eeche',
    latitude: 47.4586,
    longitude: 7.045,
    id: 2783,
  },
  {
    location_type: 'zip_code',
    location: '2924',
    location_name: 'Montignez',
    latitude: 47.4835,
    longitude: 7.0578,
    id: 2784,
  },
  {
    location_type: 'zip_code',
    location: '2925',
    location_name: 'Buix',
    latitude: 47.475,
    longitude: 7.0249,
    id: 2785,
  },
  {
    location_type: 'zip_code',
    location: '2926',
    location_name: 'Boncourt',
    latitude: 47.4884,
    longitude: 7.01,
    id: 2786,
  },
  {
    location_type: 'zip_code',
    location: '2932',
    location_name: 'Coeuve',
    latitude: 47.4508,
    longitude: 7.0952,
    id: 2787,
  },
  {
    location_type: 'zip_code',
    location: '2933',
    location_name: 'Damphreux',
    latitude: 47.4747,
    longitude: 7.1015,
    id: 2788,
  },
  {
    location_type: 'zip_code',
    location: '2935',
    location_name: 'Beurnev\u00e9sin',
    latitude: 47.4915,
    longitude: 7.136,
    id: 2789,
  },
  {
    location_type: 'zip_code',
    location: '2942',
    location_name: 'Alle',
    latitude: 47.4276,
    longitude: 7.1263,
    id: 2790,
  },
  {
    location_type: 'zip_code',
    location: '2943',
    location_name: 'Vendlincourt',
    latitude: 47.4526,
    longitude: 7.1491,
    id: 2791,
  },
  {
    location_type: 'zip_code',
    location: '2944',
    location_name: 'Bonfol',
    latitude: 47.4771,
    longitude: 7.1574,
    id: 2792,
  },
  {
    location_type: 'zip_code',
    location: '2946',
    location_name: 'Mi\u00e9court',
    latitude: 47.4311,
    longitude: 7.1713,
    id: 2793,
  },
  {
    location_type: 'zip_code',
    location: '2947',
    location_name: 'Charmoille',
    latitude: 47.4274,
    longitude: 7.2153,
    id: 2794,
  },
  {
    location_type: 'zip_code',
    location: '2950',
    location_name: 'Courtemautruy',
    latitude: 47.3989,
    longitude: 7.1403,
    id: 2795,
  },
  {
    location_type: 'zip_code',
    location: '2952',
    location_name: 'Cornol',
    latitude: 47.4048,
    longitude: 7.1612,
    id: 2796,
  },
  {
    location_type: 'zip_code',
    location: '2953',
    location_name: 'Fregi\u00e9court',
    latitude: 47.4109,
    longitude: 7.1919,
    id: 2797,
  },
  {
    location_type: 'zip_code',
    location: '2954',
    location_name: 'Asuel',
    latitude: 47.3992,
    longitude: 7.2033,
    id: 2798,
  },
  {
    location_type: 'zip_code',
    location: '6105',
    location_name: 'Schachen LU',
    latitude: 47.0381,
    longitude: 8.1425,
    id: 2799,
  },
  {
    location_type: 'zip_code',
    location: '6106',
    location_name: 'Werthenstein',
    latitude: 47.0459,
    longitude: 8.1082,
    id: 2800,
  },
  {
    location_type: 'zip_code',
    location: '6112',
    location_name: 'Doppleschwand',
    latitude: 47.0099,
    longitude: 8.0494,
    id: 2801,
  },
  {
    location_type: 'zip_code',
    location: '6113',
    location_name: 'Romoos',
    latitude: 47.0077,
    longitude: 7.9988,
    id: 2802,
  },
  {
    location_type: 'zip_code',
    location: '6160',
    location_name: 'Entlebuch Businesszentrum',
    latitude: 46.9833,
    longitude: 8.0667,
    id: 2803,
  },
  {
    location_type: 'zip_code',
    location: '6161',
    location_name: 'Entlebuch Versandzentrum',
    latitude: 46.9833,
    longitude: 8.0667,
    id: 2804,
  },
  {
    location_type: 'zip_code',
    location: '6162',
    location_name: 'Entlebuch',
    latitude: 46.9902,
    longitude: 8.0721,
    id: 2805,
  },
  {
    location_type: 'zip_code',
    location: '6163',
    location_name: 'Ebnet',
    latitude: 47.02,
    longitude: 8.0856,
    id: 2806,
  },
  {
    location_type: 'zip_code',
    location: '6166',
    location_name: 'Hasle LU',
    latitude: 46.9608,
    longitude: 8.0831,
    id: 2807,
  },
  {
    location_type: 'zip_code',
    location: '6167',
    location_name: 'Bramboden',
    latitude: 46.9833,
    longitude: 7.9757,
    id: 2808,
  },
  {
    location_type: 'zip_code',
    location: '6170',
    location_name: 'Sch\u00fcpfheim',
    latitude: 46.9441,
    longitude: 8.0059,
    id: 2809,
  },
  {
    location_type: 'zip_code',
    location: '6173',
    location_name: 'Fl\u00fchli LU',
    latitude: 46.8802,
    longitude: 8.0197,
    id: 2810,
  },
  {
    location_type: 'zip_code',
    location: '6174',
    location_name: 'S\u00f6renberg',
    latitude: 46.8067,
    longitude: 8.0337,
    id: 2811,
  },
  {
    location_type: 'zip_code',
    location: '6182',
    location_name: 'Escholzmatt',
    latitude: 46.928,
    longitude: 7.9359,
    id: 2812,
  },
  {
    location_type: 'zip_code',
    location: '6192',
    location_name: 'Wiggen',
    latitude: 46.8782,
    longitude: 7.9465,
    id: 2813,
  },
  {
    location_type: 'zip_code',
    location: '6196',
    location_name: 'Marbach LU',
    latitude: 46.8618,
    longitude: 7.9088,
    id: 2814,
  },
  {
    location_type: 'zip_code',
    location: '6020',
    location_name: 'Emmenbr\u00fccke',
    latitude: 47.0761,
    longitude: 8.2615,
    id: 2815,
  },
  {
    location_type: 'zip_code',
    location: '6021',
    location_name: 'Emmenbr\u00fccke 1',
    latitude: 47.1292,
    longitude: 8.1875,
    id: 2816,
  },
  {
    location_type: 'zip_code',
    location: '6023',
    location_name: 'Rothenburg',
    latitude: 47.1043,
    longitude: 8.2674,
    id: 2817,
  },
  {
    location_type: 'zip_code',
    location: '6026',
    location_name: 'Rain',
    latitude: 47.1326,
    longitude: 8.2634,
    id: 2818,
  },
  {
    location_type: 'zip_code',
    location: '6027',
    location_name: 'R\u00f6merswil LU',
    latitude: 47.173,
    longitude: 8.2491,
    id: 2819,
  },
  {
    location_type: 'zip_code',
    location: '6028',
    location_name: 'Herlisberg',
    latitude: 47.1959,
    longitude: 8.2297,
    id: 2820,
  },
  {
    location_type: 'zip_code',
    location: '6032',
    location_name: 'Emmen',
    latitude: 47.0876,
    longitude: 8.3096,
    id: 2821,
  },
  {
    location_type: 'zip_code',
    location: '6034',
    location_name: 'Inwil',
    latitude: 47.1352,
    longitude: 8.3719,
    id: 2822,
  },
  {
    location_type: 'zip_code',
    location: '6274',
    location_name: 'Eschenbach LU',
    latitude: 47.1252,
    longitude: 8.314,
    id: 2823,
  },
  {
    location_type: 'zip_code',
    location: '6275',
    location_name: 'Ballwil',
    latitude: 47.1524,
    longitude: 8.3476,
    id: 2824,
  },
  {
    location_type: 'zip_code',
    location: '6276',
    location_name: 'Hohenrain',
    latitude: 47.179,
    longitude: 8.318,
    id: 2825,
  },
  {
    location_type: 'zip_code',
    location: '6277',
    location_name: 'Lieli LU',
    latitude: 47.2127,
    longitude: 8.3061,
    id: 2826,
  },
  {
    location_type: 'zip_code',
    location: '6280',
    location_name: 'Urswil',
    latitude: 47.1472,
    longitude: 8.288,
    id: 2827,
  },
  {
    location_type: 'zip_code',
    location: '6281',
    location_name: 'Hochdorf',
    latitude: 47.1667,
    longitude: 8.2833,
    id: 2828,
  },
  {
    location_type: 'zip_code',
    location: '6283',
    location_name: 'Baldegg',
    latitude: 47.1836,
    longitude: 8.2824,
    id: 2829,
  },
  {
    location_type: 'zip_code',
    location: '6284',
    location_name: 'Sulz LU',
    latitude: 47.222,
    longitude: 8.2883,
    id: 2830,
  },
  {
    location_type: 'zip_code',
    location: '6285',
    location_name: 'Retschwil',
    latitude: 47.1967,
    longitude: 8.2468,
    id: 2831,
  },
  {
    location_type: 'zip_code',
    location: '6286',
    location_name: 'Altwis',
    latitude: 47.24,
    longitude: 8.2513,
    id: 2832,
  },
  {
    location_type: 'zip_code',
    location: '6287',
    location_name: 'Aesch LU',
    latitude: 47.2591,
    longitude: 8.2454,
    id: 2833,
  },
  {
    location_type: 'zip_code',
    location: '6288',
    location_name: 'Schongau',
    latitude: 47.2671,
    longitude: 8.2715,
    id: 2834,
  },
  {
    location_type: 'zip_code',
    location: '6289',
    location_name: 'M\u00fcswangen',
    latitude: 47.2401,
    longitude: 8.2985,
    id: 2835,
  },
  {
    location_type: 'zip_code',
    location: '6294',
    location_name: 'Ermensee',
    latitude: 47.2248,
    longitude: 8.2367,
    id: 2836,
  },
  {
    location_type: 'zip_code',
    location: '6295',
    location_name: 'Mosen',
    latitude: 47.2416,
    longitude: 8.228,
    id: 2837,
  },
  {
    location_type: 'zip_code',
    location: '6000',
    location_name: 'Luzern 1 Annahme',
    latitude: 47.0833,
    longitude: 8.2667,
    id: 2838,
  },
  {
    location_type: 'zip_code',
    location: '6002',
    location_name: 'Luzern',
    latitude: 47.0596,
    longitude: 8.306,
    id: 2839,
  },
  {
    location_type: 'zip_code',
    location: '6003',
    location_name: 'Luzern',
    latitude: 47.0501,
    longitude: 8.297,
    id: 2840,
  },
  {
    location_type: 'zip_code',
    location: '6004',
    location_name: 'Luzern',
    latitude: 47.0603,
    longitude: 8.2986,
    id: 2841,
  },
  {
    location_type: 'zip_code',
    location: '6005',
    location_name: 'Luzern',
    latitude: 47.0342,
    longitude: 8.3178,
    id: 2842,
  },
  {
    location_type: 'zip_code',
    location: '6006',
    location_name: 'Luzern',
    latitude: 47.0585,
    longitude: 8.3337,
    id: 2843,
  },
  {
    location_type: 'zip_code',
    location: '6007',
    location_name: 'Luzern',
    latitude: 47.0833,
    longitude: 8.2667,
    id: 2844,
  },
  {
    location_type: 'zip_code',
    location: '6008',
    location_name: 'Luzern UBS',
    latitude: 47.0833,
    longitude: 8.2667,
    id: 2845,
  },
  {
    location_type: 'zip_code',
    location: '6009',
    location_name: 'Luzern',
    latitude: 47.0833,
    longitude: 8.2667,
    id: 2846,
  },
  {
    location_type: 'zip_code',
    location: '6010',
    location_name: 'Kriens',
    latitude: 47.0028,
    longitude: 8.2616,
    id: 2847,
  },
  {
    location_type: 'zip_code',
    location: '6011',
    location_name: 'Kriens',
    latitude: 47.0333,
    longitude: 8.2833,
    id: 2848,
  },
  {
    location_type: 'zip_code',
    location: '6012',
    location_name: 'Obernau',
    latitude: 47.0288,
    longitude: 8.2374,
    id: 2849,
  },
  {
    location_type: 'zip_code',
    location: '6013',
    location_name: 'Eigenthal',
    latitude: 46.9906,
    longitude: 8.2192,
    id: 2850,
  },
  {
    location_type: 'zip_code',
    location: '6014',
    location_name: 'Littau',
    latitude: 47.0567,
    longitude: 8.2446,
    id: 2851,
  },
  {
    location_type: 'zip_code',
    location: '6015',
    location_name: 'Reussb\u00fchl',
    latitude: 47.0638,
    longitude: 8.2775,
    id: 2852,
  },
  {
    location_type: 'zip_code',
    location: '6030',
    location_name: 'Ebikon',
    latitude: 47.0809,
    longitude: 8.3425,
    id: 2853,
  },
  {
    location_type: 'zip_code',
    location: '6031',
    location_name: 'Ebikon',
    latitude: 47.0833,
    longitude: 8.3333,
    id: 2854,
  },
  {
    location_type: 'zip_code',
    location: '6033',
    location_name: 'Buchrain',
    latitude: 47.102,
    longitude: 8.3468,
    id: 2855,
  },
  {
    location_type: 'zip_code',
    location: '6035',
    location_name: 'Perlen',
    latitude: 47.1107,
    longitude: 8.3646,
    id: 2856,
  },
  {
    location_type: 'zip_code',
    location: '6036',
    location_name: 'Dierikon',
    latitude: 47.0937,
    longitude: 8.3737,
    id: 2857,
  },
  {
    location_type: 'zip_code',
    location: '6037',
    location_name: 'Root',
    latitude: 47.1111,
    longitude: 8.3961,
    id: 2858,
  },
  {
    location_type: 'zip_code',
    location: '6038',
    location_name: 'Honau',
    latitude: 47.1326,
    longitude: 8.4081,
    id: 2859,
  },
  {
    location_type: 'zip_code',
    location: '6039',
    location_name: 'Root L\u00e4ngenbold',
    latitude: 47.1035,
    longitude: 8.3729,
    id: 2860,
  },
  {
    location_type: 'zip_code',
    location: '6043',
    location_name: 'Adligenswil',
    latitude: 47.0717,
    longitude: 8.3683,
    id: 2861,
  },
  {
    location_type: 'zip_code',
    location: '6044',
    location_name: 'Udligenswil',
    latitude: 47.0918,
    longitude: 8.3991,
    id: 2862,
  },
  {
    location_type: 'zip_code',
    location: '6045',
    location_name: 'Meggen',
    latitude: 47.0504,
    longitude: 8.3709,
    id: 2863,
  },
  {
    location_type: 'zip_code',
    location: '6047',
    location_name: 'Kastanienbaum',
    latitude: 47.0088,
    longitude: 8.3315,
    id: 2864,
  },
  {
    location_type: 'zip_code',
    location: '6048',
    location_name: 'Horw',
    latitude: 47.0135,
    longitude: 8.313,
    id: 2865,
  },
  {
    location_type: 'zip_code',
    location: '6102',
    location_name: 'Malters',
    latitude: 47.0342,
    longitude: 8.1939,
    id: 2866,
  },
  {
    location_type: 'zip_code',
    location: '6103',
    location_name: 'Schwarzenberg LU',
    latitude: 46.9953,
    longitude: 8.1721,
    id: 2867,
  },
  {
    location_type: 'zip_code',
    location: '6344',
    location_name: 'Meierskappel',
    latitude: 47.1164,
    longitude: 8.4357,
    id: 2868,
  },
  {
    location_type: 'zip_code',
    location: '6353',
    location_name: 'Weggis',
    latitude: 47.038,
    longitude: 8.4318,
    id: 2869,
  },
  {
    location_type: 'zip_code',
    location: '6354',
    location_name: 'Vitznau',
    latitude: 47.0162,
    longitude: 8.4864,
    id: 2870,
  },
  {
    location_type: 'zip_code',
    location: '6356',
    location_name: 'Rigi Kaltbad',
    latitude: 47.0373,
    longitude: 8.4825,
    id: 2871,
  },
  {
    location_type: 'zip_code',
    location: '6404',
    location_name: 'Greppen',
    latitude: 47.0581,
    longitude: 8.42,
    id: 2872,
  },
  {
    location_type: 'zip_code',
    location: '5735',
    location_name: 'Pfeffikon LU',
    latitude: 47.2465,
    longitude: 8.1623,
    id: 2873,
  },
  {
    location_type: 'zip_code',
    location: '6016',
    location_name: 'Hellb\u00fchl',
    latitude: 47.0674,
    longitude: 8.1984,
    id: 2874,
  },
  {
    location_type: 'zip_code',
    location: '6017',
    location_name: 'Ruswil',
    latitude: 47.0877,
    longitude: 8.1261,
    id: 2875,
  },
  {
    location_type: 'zip_code',
    location: '6018',
    location_name: 'Buttisholz',
    latitude: 47.1146,
    longitude: 8.0966,
    id: 2876,
  },
  {
    location_type: 'zip_code',
    location: '6019',
    location_name: 'Sigigen',
    latitude: 47.0613,
    longitude: 8.1362,
    id: 2877,
  },
  {
    location_type: 'zip_code',
    location: '6022',
    location_name: 'Grosswangen',
    latitude: 47.133,
    longitude: 8.0548,
    id: 2878,
  },
  {
    location_type: 'zip_code',
    location: '6024',
    location_name: 'Hildisrieden',
    latitude: 47.1463,
    longitude: 8.235,
    id: 2879,
  },
  {
    location_type: 'zip_code',
    location: '6025',
    location_name: 'Neudorf',
    latitude: 47.1781,
    longitude: 8.207,
    id: 2880,
  },
  {
    location_type: 'zip_code',
    location: '6110',
    location_name: 'Wolhusen',
    latitude: 47.056,
    longitude: 8.0756,
    id: 2881,
  },
  {
    location_type: 'zip_code',
    location: '6114',
    location_name: 'Steinhuserberg',
    latitude: 47.0458,
    longitude: 8.0413,
    id: 2882,
  },
  {
    location_type: 'zip_code',
    location: '6203',
    location_name: 'Sempach Station',
    latitude: 47.1187,
    longitude: 8.2087,
    id: 2883,
  },
  {
    location_type: 'zip_code',
    location: '6204',
    location_name: 'Sempach',
    latitude: 47.1363,
    longitude: 8.2061,
    id: 2884,
  },
  {
    location_type: 'zip_code',
    location: '6205',
    location_name: 'Eich',
    latitude: 47.1582,
    longitude: 8.1724,
    id: 2885,
  },
  {
    location_type: 'zip_code',
    location: '6206',
    location_name: 'Neuenkirch',
    latitude: 47.0961,
    longitude: 8.2018,
    id: 2886,
  },
  {
    location_type: 'zip_code',
    location: '6207',
    location_name: 'Nottwil',
    latitude: 47.1279,
    longitude: 8.1377,
    id: 2887,
  },
  {
    location_type: 'zip_code',
    location: '6208',
    location_name: 'Oberkirch LU',
    latitude: 47.1527,
    longitude: 8.103,
    id: 2888,
  },
  {
    location_type: 'zip_code',
    location: '6210',
    location_name: 'Sursee',
    latitude: 47.176,
    longitude: 8.105,
    id: 2889,
  },
  {
    location_type: 'zip_code',
    location: '6212',
    location_name: 'St. Erhard',
    latitude: 47.1821,
    longitude: 8.071,
    id: 2890,
  },
  {
    location_type: 'zip_code',
    location: '6213',
    location_name: 'Knutwil',
    latitude: 47.2023,
    longitude: 8.0707,
    id: 2891,
  },
  {
    location_type: 'zip_code',
    location: '6214',
    location_name: 'Schenkon',
    latitude: 47.1834,
    longitude: 8.1323,
    id: 2892,
  },
  {
    location_type: 'zip_code',
    location: '6215',
    location_name: 'Schwarzenbach Berom\u00fcnster',
    latitude: 47.2364,
    longitude: 8.213,
    id: 2893,
  },
  {
    location_type: 'zip_code',
    location: '6216',
    location_name: 'Mauensee',
    latitude: 47.1684,
    longitude: 8.0747,
    id: 2894,
  },
  {
    location_type: 'zip_code',
    location: '6221',
    location_name: 'Rickenbach LU',
    latitude: 47.2154,
    longitude: 8.1501,
    id: 2895,
  },
  {
    location_type: 'zip_code',
    location: '6222',
    location_name: 'Gunzwil',
    latitude: 47.1928,
    longitude: 8.1709,
    id: 2896,
  },
  {
    location_type: 'zip_code',
    location: '6231',
    location_name: 'Schlierbach',
    latitude: 47.2293,
    longitude: 8.1186,
    id: 2897,
  },
  {
    location_type: 'zip_code',
    location: '6232',
    location_name: 'Geuensee',
    latitude: 47.2031,
    longitude: 8.1203,
    id: 2898,
  },
  {
    location_type: 'zip_code',
    location: '6233',
    location_name: 'B\u00fcron',
    latitude: 47.2138,
    longitude: 8.095,
    id: 2899,
  },
  {
    location_type: 'zip_code',
    location: '6234',
    location_name: 'Kulmerau',
    latitude: 47.2535,
    longitude: 8.0895,
    id: 2900,
  },
  {
    location_type: 'zip_code',
    location: '6235',
    location_name: 'Winikon',
    latitude: 47.2308,
    longitude: 8.0426,
    id: 2901,
  },
  {
    location_type: 'zip_code',
    location: '6236',
    location_name: 'Wilihof',
    latitude: 47.2207,
    longitude: 8.0611,
    id: 2902,
  },
  {
    location_type: 'zip_code',
    location: '4806',
    location_name: 'Wikon',
    latitude: 47.2655,
    longitude: 7.9705,
    id: 2903,
  },
  {
    location_type: 'zip_code',
    location: '4915',
    location_name: 'St. Urban',
    latitude: 47.2259,
    longitude: 7.8569,
    id: 2904,
  },
  {
    location_type: 'zip_code',
    location: '6122',
    location_name: 'Menznau',
    latitude: 47.0818,
    longitude: 8.0386,
    id: 2905,
  },
  {
    location_type: 'zip_code',
    location: '6123',
    location_name: 'Geiss',
    latitude: 47.0915,
    longitude: 8.0603,
    id: 2906,
  },
  {
    location_type: 'zip_code',
    location: '6125',
    location_name: 'Menzberg',
    latitude: 47.0361,
    longitude: 7.9992,
    id: 2907,
  },
  {
    location_type: 'zip_code',
    location: '6126',
    location_name: 'Daiwil',
    latitude: 47.0978,
    longitude: 8.0113,
    id: 2908,
  },
  {
    location_type: 'zip_code',
    location: '6130',
    location_name: 'Willisau',
    latitude: 47.1131,
    longitude: 7.9796,
    id: 2909,
  },
  {
    location_type: 'zip_code',
    location: '6132',
    location_name: 'Rohrmatt',
    latitude: 47.0843,
    longitude: 7.9849,
    id: 2910,
  },
  {
    location_type: 'zip_code',
    location: '6133',
    location_name: 'Hergiswil b. Willisau',
    latitude: 47.069,
    longitude: 7.9562,
    id: 2911,
  },
  {
    location_type: 'zip_code',
    location: '6142',
    location_name: 'Gettnau',
    latitude: 47.1383,
    longitude: 7.9704,
    id: 2912,
  },
  {
    location_type: 'zip_code',
    location: '6143',
    location_name: 'Ohmstal',
    latitude: 47.1598,
    longitude: 7.9541,
    id: 2913,
  },
  {
    location_type: 'zip_code',
    location: '6144',
    location_name: 'Zell LU',
    latitude: 47.1378,
    longitude: 7.927,
    id: 2914,
  },
  {
    location_type: 'zip_code',
    location: '6145',
    location_name: 'Fischbach LU',
    latitude: 47.1533,
    longitude: 7.905,
    id: 2915,
  },
  {
    location_type: 'zip_code',
    location: '6146',
    location_name: 'Grossdietwil',
    latitude: 47.169,
    longitude: 7.8965,
    id: 2916,
  },
  {
    location_type: 'zip_code',
    location: '6147',
    location_name: 'Altb\u00fcron',
    latitude: 47.1909,
    longitude: 7.8925,
    id: 2917,
  },
  {
    location_type: 'zip_code',
    location: '6152',
    location_name: 'H\u00fcswil',
    latitude: 47.1293,
    longitude: 7.905,
    id: 2918,
  },
  {
    location_type: 'zip_code',
    location: '6153',
    location_name: 'Ufhusen',
    latitude: 47.1075,
    longitude: 7.8979,
    id: 2919,
  },
  {
    location_type: 'zip_code',
    location: '6154',
    location_name: 'Hofstatt',
    latitude: 47.0759,
    longitude: 7.8981,
    id: 2920,
  },
  {
    location_type: 'zip_code',
    location: '6156',
    location_name: 'Luthern',
    latitude: 47.0521,
    longitude: 7.9036,
    id: 2921,
  },
  {
    location_type: 'zip_code',
    location: '6211',
    location_name: 'Buchs LU',
    latitude: 47.202,
    longitude: 8.0367,
    id: 2922,
  },
  {
    location_type: 'zip_code',
    location: '6217',
    location_name: 'Kottwil',
    latitude: 47.163,
    longitude: 8.0417,
    id: 2923,
  },
  {
    location_type: 'zip_code',
    location: '6218',
    location_name: 'Ettiswil',
    latitude: 47.1463,
    longitude: 8.0172,
    id: 2924,
  },
  {
    location_type: 'zip_code',
    location: '6242',
    location_name: 'Wauwil',
    latitude: 47.1843,
    longitude: 8.0307,
    id: 2925,
  },
  {
    location_type: 'zip_code',
    location: '6243',
    location_name: 'Egolzwil',
    latitude: 47.1844,
    longitude: 7.9973,
    id: 2926,
  },
  {
    location_type: 'zip_code',
    location: '6244',
    location_name: 'Nebikon',
    latitude: 47.1918,
    longitude: 7.9873,
    id: 2927,
  },
  {
    location_type: 'zip_code',
    location: '6245',
    location_name: 'Ebersecken',
    latitude: 47.1806,
    longitude: 7.9395,
    id: 2928,
  },
  {
    location_type: 'zip_code',
    location: '6246',
    location_name: 'Altishofen',
    latitude: 47.2035,
    longitude: 7.9593,
    id: 2929,
  },
  {
    location_type: 'zip_code',
    location: '6247',
    location_name: 'Sch\u00f6tz',
    latitude: 47.1661,
    longitude: 7.9876,
    id: 2930,
  },
  {
    location_type: 'zip_code',
    location: '6248',
    location_name: 'Alberswil',
    latitude: 47.1478,
    longitude: 7.9934,
    id: 2931,
  },
  {
    location_type: 'zip_code',
    location: '6252',
    location_name: 'Dagmersellen',
    latitude: 47.2146,
    longitude: 7.998,
    id: 2932,
  },
  {
    location_type: 'zip_code',
    location: '6253',
    location_name: 'Uffikon',
    latitude: 47.216,
    longitude: 8.0269,
    id: 2933,
  },
  {
    location_type: 'zip_code',
    location: '6260',
    location_name: 'Mehlsecken',
    latitude: 47.2445,
    longitude: 7.9572,
    id: 2934,
  },
  {
    location_type: 'zip_code',
    location: '6262',
    location_name: 'Langnau b. Reiden',
    latitude: 47.2302,
    longitude: 7.9479,
    id: 2935,
  },
  {
    location_type: 'zip_code',
    location: '6263',
    location_name: 'Richenthal',
    latitude: 47.2112,
    longitude: 7.9321,
    id: 2936,
  },
  {
    location_type: 'zip_code',
    location: '6264',
    location_name: 'Pfaffnau',
    latitude: 47.2365,
    longitude: 7.9054,
    id: 2937,
  },
  {
    location_type: 'zip_code',
    location: '6265',
    location_name: 'Roggliswil',
    latitude: 47.2135,
    longitude: 7.8837,
    id: 2938,
  },
  {
    location_type: 'zip_code',
    location: '2012',
    location_name: 'Auvernier',
    latitude: 46.9761,
    longitude: 6.8744,
    id: 2939,
  },
  {
    location_type: 'zip_code',
    location: '2013',
    location_name: 'Colombier (NE)',
    latitude: 46.9673,
    longitude: 6.8607,
    id: 2940,
  },
  {
    location_type: 'zip_code',
    location: '2014',
    location_name: 'B\u00f4le',
    latitude: 46.9688,
    longitude: 6.8358,
    id: 2941,
  },
  {
    location_type: 'zip_code',
    location: '2015',
    location_name: 'Areuse',
    latitude: 46.9528,
    longitude: 6.861,
    id: 2942,
  },
  {
    location_type: 'zip_code',
    location: '2016',
    location_name: 'Cortaillod',
    latitude: 46.9426,
    longitude: 6.8463,
    id: 2943,
  },
  {
    location_type: 'zip_code',
    location: '2017',
    location_name: 'Boudry',
    latitude: 46.9542,
    longitude: 6.818,
    id: 2944,
  },
  {
    location_type: 'zip_code',
    location: '2019',
    location_name: 'Rochefort',
    latitude: 46.9946,
    longitude: 6.8036,
    id: 2945,
  },
  {
    location_type: 'zip_code',
    location: '2022',
    location_name: 'Bevaix',
    latitude: 46.9328,
    longitude: 6.8106,
    id: 2946,
  },
  {
    location_type: 'zip_code',
    location: '2023',
    location_name: 'Gorgier',
    latitude: 46.9211,
    longitude: 6.7559,
    id: 2947,
  },
  {
    location_type: 'zip_code',
    location: '2024',
    location_name: 'Saint-Aubin-Sauges',
    latitude: 46.8966,
    longitude: 6.7666,
    id: 2948,
  },
  {
    location_type: 'zip_code',
    location: '2025',
    location_name: 'Chez-le-Bart',
    latitude: 46.9109,
    longitude: 6.7926,
    id: 2949,
  },
  {
    location_type: 'zip_code',
    location: '2027',
    location_name: 'Fresens',
    latitude: 46.8874,
    longitude: 6.7484,
    id: 2950,
  },
  {
    location_type: 'zip_code',
    location: '2028',
    location_name: 'Vaumarcus',
    latitude: 46.8748,
    longitude: 6.7471,
    id: 2951,
  },
  {
    location_type: 'zip_code',
    location: '2034',
    location_name: 'Peseux',
    latitude: 46.9943,
    longitude: 6.887,
    id: 2952,
  },
  {
    location_type: 'zip_code',
    location: '2035',
    location_name: 'Corcelles',
    latitude: 46.9869,
    longitude: 6.8647,
    id: 2953,
  },
  {
    location_type: 'zip_code',
    location: '2036',
    location_name: 'Cormondr\u00e8che',
    latitude: 46.9798,
    longitude: 6.8643,
    id: 2954,
  },
  {
    location_type: 'zip_code',
    location: '2037',
    location_name: 'Montezillon',
    latitude: 46.9869,
    longitude: 6.8363,
    id: 2955,
  },
  {
    location_type: 'zip_code',
    location: '2149',
    location_name: 'Fretereules',
    latitude: 46.9608,
    longitude: 6.7683,
    id: 2956,
  },
  {
    location_type: 'zip_code',
    location: '2300',
    location_name: 'La Chaux-de-Fonds',
    latitude: 47.1109,
    longitude: 6.8293,
    id: 2957,
  },
  {
    location_type: 'zip_code',
    location: '2301',
    location_name: 'La Chaux-de-Fonds',
    latitude: 47.1333,
    longitude: 6.85,
    id: 2958,
  },
  {
    location_type: 'zip_code',
    location: '2302',
    location_name: 'La Chaux-de-Fonds',
    latitude: 47.1333,
    longitude: 6.85,
    id: 2959,
  },
  {
    location_type: 'zip_code',
    location: '2303',
    location_name: 'La Chaux-de-Fonds',
    latitude: 47.1333,
    longitude: 6.85,
    id: 2960,
  },
  {
    location_type: 'zip_code',
    location: '2304',
    location_name: 'La Chaux-de-Fonds',
    latitude: 47.1333,
    longitude: 6.85,
    id: 2961,
  },
  {
    location_type: 'zip_code',
    location: '2306',
    location_name: 'La Chaux-de-Fonds',
    latitude: 47.1333,
    longitude: 6.85,
    id: 2962,
  },
  {
    location_type: 'zip_code',
    location: '2314',
    location_name: 'La Sagne',
    latitude: 47.0343,
    longitude: 6.8001,
    id: 2963,
  },
  {
    location_type: 'zip_code',
    location: '2322',
    location_name: 'Le Cr\u00eat-du-Locle',
    latitude: 47.0794,
    longitude: 6.7802,
    id: 2964,
  },
  {
    location_type: 'zip_code',
    location: '2325',
    location_name: 'Les Planchettes',
    latitude: 47.1074,
    longitude: 6.7686,
    id: 2965,
  },
  {
    location_type: 'zip_code',
    location: '2316',
    location_name: 'Les Ponts-de-Martel',
    latitude: 46.9892,
    longitude: 6.7187,
    id: 2966,
  },
  {
    location_type: 'zip_code',
    location: '2318',
    location_name: 'Brot-Plamboz',
    latitude: 46.9792,
    longitude: 6.746,
    id: 2967,
  },
  {
    location_type: 'zip_code',
    location: '2400',
    location_name: 'Le Pr\u00e9voux',
    latitude: 47.035,
    longitude: 6.7114,
    id: 2968,
  },
  {
    location_type: 'zip_code',
    location: '2405',
    location_name: 'La Chaux-du-Milieu',
    latitude: 47.0056,
    longitude: 6.6959,
    id: 2969,
  },
  {
    location_type: 'zip_code',
    location: '2406',
    location_name: 'La Ch\u00c2tagne',
    latitude: 46.979,
    longitude: 6.6454,
    id: 2970,
  },
  {
    location_type: 'zip_code',
    location: '2414',
    location_name: 'Le Cerneux-P\u00e9quignot',
    latitude: 47.013,
    longitude: 6.6702,
    id: 2971,
  },
  {
    location_type: 'zip_code',
    location: '2416',
    location_name: 'Les Brenets',
    latitude: 47.0752,
    longitude: 6.7244,
    id: 2972,
  },
  {
    location_type: 'zip_code',
    location: '2000',
    location_name: 'Neuch\u00c2tel',
    latitude: 47.0009,
    longitude: 6.9274,
    id: 2973,
  },
  {
    location_type: 'zip_code',
    location: '2001',
    location_name: 'Neuch\u00c2tel 1',
    latitude: 47,
    longitude: 6.9667,
    id: 2974,
  },
  {
    location_type: 'zip_code',
    location: '2002',
    location_name: 'Neuch\u00c2tel 2',
    latitude: 47,
    longitude: 6.9667,
    id: 2975,
  },
  {
    location_type: 'zip_code',
    location: '2003',
    location_name: 'Neuch\u00c2tel 3',
    latitude: 47,
    longitude: 6.9667,
    id: 2976,
  },
  {
    location_type: 'zip_code',
    location: '2004',
    location_name: 'Neuch\u00c2tel 4',
    latitude: 47,
    longitude: 6.9667,
    id: 2977,
  },
  {
    location_type: 'zip_code',
    location: '2006',
    location_name: 'Neuch\u00c2tel 6',
    latitude: 47,
    longitude: 6.9667,
    id: 2978,
  },
  {
    location_type: 'zip_code',
    location: '2007',
    location_name: 'Neuch\u00c2tel 7',
    latitude: 47,
    longitude: 6.9667,
    id: 2979,
  },
  {
    location_type: 'zip_code',
    location: '2008',
    location_name: 'Neuch\u00c2tel',
    latitude: 47,
    longitude: 6.9667,
    id: 2980,
  },
  {
    location_type: 'zip_code',
    location: '2009',
    location_name: 'Neuch\u00c2tel 9',
    latitude: 47,
    longitude: 6.9667,
    id: 2981,
  },
  {
    location_type: 'zip_code',
    location: '2010',
    location_name: 'Neuch\u00c2tel OFS',
    latitude: 46.95,
    longitude: 6.85,
    id: 2982,
  },
  {
    location_type: 'zip_code',
    location: '2067',
    location_name: 'Chaumont',
    latitude: 47.0355,
    longitude: 6.9638,
    id: 2983,
  },
  {
    location_type: 'zip_code',
    location: '2068',
    location_name: 'Hauterive (NE)',
    latitude: 47.0166,
    longitude: 6.9687,
    id: 2984,
  },
  {
    location_type: 'zip_code',
    location: '2072',
    location_name: 'Saint-Blaise',
    latitude: 47.0374,
    longitude: 7.0021,
    id: 2985,
  },
  {
    location_type: 'zip_code',
    location: '2073',
    location_name: 'Enges',
    latitude: 47.0725,
    longitude: 7.0128,
    id: 2986,
  },
  {
    location_type: 'zip_code',
    location: '2074',
    location_name: 'Marin-Epagnier',
    latitude: 47.0101,
    longitude: 7.0122,
    id: 2987,
  },
  {
    location_type: 'zip_code',
    location: '2075',
    location_name: 'Thielle',
    latitude: 47.0186,
    longitude: 7.0269,
    id: 2988,
  },
  {
    location_type: 'zip_code',
    location: '2087',
    location_name: 'Cornaux',
    latitude: 47.0336,
    longitude: 7.021,
    id: 2989,
  },
  {
    location_type: 'zip_code',
    location: '2088',
    location_name: 'Cressier (NE)',
    latitude: 47.049,
    longitude: 7.0342,
    id: 2990,
  },
  {
    location_type: 'zip_code',
    location: '2523',
    location_name: 'Ligni\u00e8res',
    latitude: 47.0911,
    longitude: 7.0581,
    id: 2991,
  },
  {
    location_type: 'zip_code',
    location: '2525',
    location_name: 'Le Landeron',
    latitude: 47.06,
    longitude: 7.0635,
    id: 2992,
  },
  {
    location_type: 'zip_code',
    location: '2042',
    location_name: 'Valangin',
    latitude: 47.0177,
    longitude: 6.903,
    id: 2993,
  },
  {
    location_type: 'zip_code',
    location: '2043',
    location_name: 'Boudevilliers',
    latitude: 47.0302,
    longitude: 6.8846,
    id: 2994,
  },
  {
    location_type: 'zip_code',
    location: '2046',
    location_name: 'Fontaines (NE)',
    latitude: 47.0412,
    longitude: 6.9008,
    id: 2995,
  },
  {
    location_type: 'zip_code',
    location: '2052',
    location_name: 'Fontainemelon',
    latitude: 47.0553,
    longitude: 6.8869,
    id: 2996,
  },
  {
    location_type: 'zip_code',
    location: '2053',
    location_name: 'Cernier',
    latitude: 47.06,
    longitude: 6.904,
    id: 2997,
  },
  {
    location_type: 'zip_code',
    location: '2054',
    location_name: 'Ch\u00e9zard-Saint-Martin',
    latitude: 47.0639,
    longitude: 6.9264,
    id: 2998,
  },
  {
    location_type: 'zip_code',
    location: '2056',
    location_name: 'Dombresson',
    latitude: 47.071,
    longitude: 6.9594,
    id: 2999,
  },
  {
    location_type: 'zip_code',
    location: '2057',
    location_name: 'Villiers',
    latitude: 47.0829,
    longitude: 6.9814,
    id: 3000,
  },
  {
    location_type: 'zip_code',
    location: '2058',
    location_name: 'Le P\u00c2quier (NE)',
    latitude: 47.1037,
    longitude: 6.9862,
    id: 3001,
  },
  {
    location_type: 'zip_code',
    location: '2063',
    location_name: 'Fenin',
    latitude: 47.0229,
    longitude: 6.9202,
    id: 3002,
  },
  {
    location_type: 'zip_code',
    location: '2065',
    location_name: 'Savagnier',
    latitude: 47.0498,
    longitude: 6.9549,
    id: 3003,
  },
  {
    location_type: 'zip_code',
    location: '2206',
    location_name: 'Les Geneveys-sur-Coffrane',
    latitude: 47.0183,
    longitude: 6.8331,
    id: 3004,
  },
  {
    location_type: 'zip_code',
    location: '2207',
    location_name: 'Coffrane',
    latitude: 47.0086,
    longitude: 6.8645,
    id: 3005,
  },
  {
    location_type: 'zip_code',
    location: '2208',
    location_name: 'Les Hauts-Geneveys',
    latitude: 47.0384,
    longitude: 6.8521,
    id: 3006,
  },
  {
    location_type: 'zip_code',
    location: '2103',
    location_name: 'Noiraigue',
    latitude: 46.946,
    longitude: 6.7212,
    id: 3007,
  },
  {
    location_type: 'zip_code',
    location: '2105',
    location_name: 'Travers',
    latitude: 46.9468,
    longitude: 6.679,
    id: 3008,
  },
  {
    location_type: 'zip_code',
    location: '2108',
    location_name: 'Couvet',
    latitude: 46.9212,
    longitude: 6.6945,
    id: 3009,
  },
  {
    location_type: 'zip_code',
    location: '2112',
    location_name: 'M\u00f4tiers',
    latitude: 46.9024,
    longitude: 6.6189,
    id: 3010,
  },
  {
    location_type: 'zip_code',
    location: '2113',
    location_name: 'Boveresse',
    latitude: 46.9163,
    longitude: 6.5986,
    id: 3011,
  },
  {
    location_type: 'zip_code',
    location: '2114',
    location_name: 'Fleurier',
    latitude: 46.8959,
    longitude: 6.5925,
    id: 3012,
  },
  {
    location_type: 'zip_code',
    location: '2115',
    location_name: 'Buttes',
    latitude: 46.8777,
    longitude: 6.5459,
    id: 3013,
  },
  {
    location_type: 'zip_code',
    location: '2116',
    location_name: 'Mont-de-Buttes',
    latitude: 46.887,
    longitude: 6.5192,
    id: 3014,
  },
  {
    location_type: 'zip_code',
    location: '2117',
    location_name: 'La C\u00f4te-aux-F\u00e9es',
    latitude: 46.8643,
    longitude: 6.4875,
    id: 3015,
  },
  {
    location_type: 'zip_code',
    location: '2123',
    location_name: 'Saint-Sulpice',
    latitude: 46.9078,
    longitude: 6.5579,
    id: 3016,
  },
  {
    location_type: 'zip_code',
    location: '2124',
    location_name: 'Les Sagnettes',
    latitude: 46.9391,
    longitude: 6.5987,
    id: 3017,
  },
  {
    location_type: 'zip_code',
    location: '2126',
    location_name: 'Les Verri\u00e8res',
    latitude: 46.9164,
    longitude: 6.4731,
    id: 3018,
  },
  {
    location_type: 'zip_code',
    location: '2127',
    location_name: 'Les Bayards',
    latitude: 46.9245,
    longitude: 6.5405,
    id: 3019,
  },
  {
    location_type: 'zip_code',
    location: '6052',
    location_name: 'Hergiswil',
    latitude: 46.9862,
    longitude: 8.2967,
    id: 3020,
  },
  {
    location_type: 'zip_code',
    location: '6362',
    location_name: 'Stansstad',
    latitude: 46.9707,
    longitude: 8.3379,
    id: 3021,
  },
  {
    location_type: 'zip_code',
    location: '6363',
    location_name: 'B\u00fcrgenstock',
    latitude: 46.9964,
    longitude: 8.3834,
    id: 3022,
  },
  {
    location_type: 'zip_code',
    location: '6365',
    location_name: 'Kehrsiten',
    latitude: 47.0007,
    longitude: 8.3824,
    id: 3023,
  },
  {
    location_type: 'zip_code',
    location: '6370',
    location_name: 'Oberdorf NW',
    latitude: 46.9515,
    longitude: 8.3893,
    id: 3024,
  },
  {
    location_type: 'zip_code',
    location: '6371',
    location_name: 'Stans',
    latitude: 46.9667,
    longitude: 8.35,
    id: 3025,
  },
  {
    location_type: 'zip_code',
    location: '6372',
    location_name: 'Ennetmoos',
    latitude: 46.9514,
    longitude: 8.3336,
    id: 3026,
  },
  {
    location_type: 'zip_code',
    location: '6373',
    location_name: 'Ennetb\u00fcrgen',
    latitude: 46.9856,
    longitude: 8.4105,
    id: 3027,
  },
  {
    location_type: 'zip_code',
    location: '6374',
    location_name: 'Buochs',
    latitude: 46.9663,
    longitude: 8.4132,
    id: 3028,
  },
  {
    location_type: 'zip_code',
    location: '6375',
    location_name: 'Beckenried',
    latitude: 46.9295,
    longitude: 8.4772,
    id: 3029,
  },
  {
    location_type: 'zip_code',
    location: '6376',
    location_name: 'Emmetten',
    latitude: 46.9508,
    longitude: 8.5029,
    id: 3030,
  },
  {
    location_type: 'zip_code',
    location: '6382',
    location_name: 'B\u00fcren NW',
    latitude: 46.9411,
    longitude: 8.4115,
    id: 3031,
  },
  {
    location_type: 'zip_code',
    location: '6383',
    location_name: 'Wiesenberg',
    latitude: 46.9233,
    longitude: 8.3588,
    id: 3032,
  },
  {
    location_type: 'zip_code',
    location: '6386',
    location_name: 'Wolfenschiessen',
    latitude: 46.9128,
    longitude: 8.3854,
    id: 3033,
  },
  {
    location_type: 'zip_code',
    location: '6387',
    location_name: 'Oberrickenbach',
    latitude: 46.878,
    longitude: 8.4398,
    id: 3034,
  },
  {
    location_type: 'zip_code',
    location: '6053',
    location_name: 'Alpnachstad',
    latitude: 46.9598,
    longitude: 8.2878,
    id: 3035,
  },
  {
    location_type: 'zip_code',
    location: '6055',
    location_name: 'Alpnach Dorf',
    latitude: 46.9504,
    longitude: 8.211,
    id: 3036,
  },
  {
    location_type: 'zip_code',
    location: '6056',
    location_name: 'K\u00e4giswil',
    latitude: 46.9152,
    longitude: 8.2529,
    id: 3037,
  },
  {
    location_type: 'zip_code',
    location: '6060',
    location_name: 'Sarnen',
    latitude: 46.8956,
    longitude: 8.2455,
    id: 3038,
  },
  {
    location_type: 'zip_code',
    location: '6061',
    location_name: 'Sarnen 1',
    latitude: 46.8833,
    longitude: 8.2667,
    id: 3039,
  },
  {
    location_type: 'zip_code',
    location: '6062',
    location_name: 'Wilen',
    latitude: 46.8729,
    longitude: 8.2152,
    id: 3040,
  },
  {
    location_type: 'zip_code',
    location: '6063',
    location_name: 'Stalden',
    latitude: 46.8995,
    longitude: 8.163,
    id: 3041,
  },
  {
    location_type: 'zip_code',
    location: '6064',
    location_name: 'Kerns',
    latitude: 46.9165,
    longitude: 8.2915,
    id: 3042,
  },
  {
    location_type: 'zip_code',
    location: '6066',
    location_name: 'St. Niklausen OW',
    latitude: 46.8732,
    longitude: 8.3013,
    id: 3043,
  },
  {
    location_type: 'zip_code',
    location: '6067',
    location_name: 'Melchtal',
    latitude: 46.8317,
    longitude: 8.2922,
    id: 3044,
  },
  {
    location_type: 'zip_code',
    location: '6068',
    location_name: 'Melchsee-Frutt',
    latitude: 46.7824,
    longitude: 8.2965,
    id: 3045,
  },
  {
    location_type: 'zip_code',
    location: '6072',
    location_name: 'Sachseln',
    latitude: 46.8231,
    longitude: 8.2434,
    id: 3046,
  },
  {
    location_type: 'zip_code',
    location: '6073',
    location_name: 'Fl\u00fceli-Ranft',
    latitude: 46.8753,
    longitude: 8.259,
    id: 3047,
  },
  {
    location_type: 'zip_code',
    location: '6074',
    location_name: 'Giswil',
    latitude: 46.8412,
    longitude: 8.1573,
    id: 3048,
  },
  {
    location_type: 'zip_code',
    location: '6078',
    location_name: 'B\u00fcrglen OW',
    latitude: 46.8114,
    longitude: 8.1661,
    id: 3049,
  },
  {
    location_type: 'zip_code',
    location: '6388',
    location_name: 'Grafenort',
    latitude: 46.8618,
    longitude: 8.3632,
    id: 3050,
  },
  {
    location_type: 'zip_code',
    location: '6390',
    location_name: 'Engelberg',
    latitude: 46.8108,
    longitude: 8.4361,
    id: 3051,
  },
  {
    location_type: 'zip_code',
    location: '6391',
    location_name: 'Engelberg',
    latitude: 46.8197,
    longitude: 8.4341,
    id: 3052,
  },
  {
    location_type: 'zip_code',
    location: '9000',
    location_name: 'St. Gallen',
    latitude: 47.4236,
    longitude: 9.3622,
    id: 3053,
  },
  {
    location_type: 'zip_code',
    location: '9001',
    location_name: 'St. Gallen Postauto SG-Azel',
    latitude: 47.4667,
    longitude: 9.4,
    id: 3054,
  },
  {
    location_type: 'zip_code',
    location: '9004',
    location_name: 'St. Gallen',
    latitude: 47.4667,
    longitude: 9.4,
    id: 3055,
  },
  {
    location_type: 'zip_code',
    location: '9006',
    location_name: 'St. Gallen',
    latitude: 47.4667,
    longitude: 9.4,
    id: 3056,
  },
  {
    location_type: 'zip_code',
    location: '9007',
    location_name: 'St. Gallen',
    latitude: 47.4667,
    longitude: 9.4,
    id: 3057,
  },
  {
    location_type: 'zip_code',
    location: '9008',
    location_name: 'St. Gallen',
    latitude: 47.4418,
    longitude: 9.3918,
    id: 3058,
  },
  {
    location_type: 'zip_code',
    location: '9009',
    location_name: 'St. Gallen',
    latitude: 47.4667,
    longitude: 9.4,
    id: 3059,
  },
  {
    location_type: 'zip_code',
    location: '9010',
    location_name: 'St. Gallen',
    latitude: 47.4422,
    longitude: 9.3717,
    id: 3060,
  },
  {
    location_type: 'zip_code',
    location: '9011',
    location_name: 'St. Gallen',
    latitude: 47.4148,
    longitude: 9.3999,
    id: 3061,
  },
  {
    location_type: 'zip_code',
    location: '9012',
    location_name: 'St. Gallen',
    latitude: 47.4103,
    longitude: 9.3617,
    id: 3062,
  },
  {
    location_type: 'zip_code',
    location: '9013',
    location_name: 'St. Gallen',
    latitude: 47.4667,
    longitude: 9.4,
    id: 3063,
  },
  {
    location_type: 'zip_code',
    location: '9014',
    location_name: 'St. Gallen',
    latitude: 47.4091,
    longitude: 9.3355,
    id: 3064,
  },
  {
    location_type: 'zip_code',
    location: '9015',
    location_name: 'St. Gallen',
    latitude: 47.4056,
    longitude: 9.3093,
    id: 3065,
  },
  {
    location_type: 'zip_code',
    location: '9016',
    location_name: 'St. Gallen',
    latitude: 47.4415,
    longitude: 9.4141,
    id: 3066,
  },
  {
    location_type: 'zip_code',
    location: '9020',
    location_name: 'St. Gallen',
    latitude: 47.4667,
    longitude: 9.4,
    id: 3067,
  },
  {
    location_type: 'zip_code',
    location: '9022',
    location_name: 'St. Gallen',
    latitude: 47.4667,
    longitude: 9.4,
    id: 3068,
  },
  {
    location_type: 'zip_code',
    location: '9024',
    location_name: 'St. Gallen Presse-Serv.G\u00fcll',
    latitude: 47.4667,
    longitude: 9.4,
    id: 3069,
  },
  {
    location_type: 'zip_code',
    location: '9025',
    location_name: 'St. Gallen Quelle',
    latitude: 47.4667,
    longitude: 9.4,
    id: 3070,
  },
  {
    location_type: 'zip_code',
    location: '9026',
    location_name: 'St. Gallen K\u00fcnzler AG',
    latitude: 47.4667,
    longitude: 9.4,
    id: 3071,
  },
  {
    location_type: 'zip_code',
    location: '9027',
    location_name: 'St. Gallen Mona Versand',
    latitude: 47.4667,
    longitude: 9.4,
    id: 3072,
  },
  {
    location_type: 'zip_code',
    location: '9028',
    location_name: 'St. Gallen Cornelia Versand',
    latitude: 47.4667,
    longitude: 9.4,
    id: 3073,
  },
  {
    location_type: 'zip_code',
    location: '9029',
    location_name: 'St. Gallen Sonderdienste',
    latitude: 47.4667,
    longitude: 9.4,
    id: 3074,
  },
  {
    location_type: 'zip_code',
    location: '9030',
    location_name: 'Abtwil SG',
    latitude: 47.4274,
    longitude: 9.3146,
    id: 3075,
  },
  {
    location_type: 'zip_code',
    location: '9032',
    location_name: 'Engelburg',
    latitude: 47.4411,
    longitude: 9.3348,
    id: 3076,
  },
  {
    location_type: 'zip_code',
    location: '9034',
    location_name: 'Eggersriet',
    latitude: 47.4414,
    longitude: 9.4623,
    id: 3077,
  },
  {
    location_type: 'zip_code',
    location: '9036',
    location_name: 'Grub SG',
    latitude: 47.4542,
    longitude: 9.5132,
    id: 3078,
  },
  {
    location_type: 'zip_code',
    location: '9200',
    location_name: 'Gossau SG',
    latitude: 47.4208,
    longitude: 9.2575,
    id: 3079,
  },
  {
    location_type: 'zip_code',
    location: '9201',
    location_name: 'Gossau SG',
    latitude: 47.4667,
    longitude: 9.2833,
    id: 3080,
  },
  {
    location_type: 'zip_code',
    location: '9204',
    location_name: 'Andwil SG',
    latitude: 47.4386,
    longitude: 9.2797,
    id: 3081,
  },
  {
    location_type: 'zip_code',
    location: '9205',
    location_name: 'Waldkirch',
    latitude: 47.4609,
    longitude: 9.282,
    id: 3082,
  },
  {
    location_type: 'zip_code',
    location: '9212',
    location_name: 'Arnegg',
    latitude: 47.4408,
    longitude: 9.2522,
    id: 3083,
  },
  {
    location_type: 'zip_code',
    location: '9300',
    location_name: 'Wittenbach',
    latitude: 47.4657,
    longitude: 9.3844,
    id: 3084,
  },
  {
    location_type: 'zip_code',
    location: '9301',
    location_name: 'Wittenbach',
    latitude: 47.4667,
    longitude: 9.3795,
    id: 3085,
  },
  {
    location_type: 'zip_code',
    location: '9304',
    location_name: 'Bernhardzell',
    latitude: 47.4683,
    longitude: 9.3437,
    id: 3086,
  },
  {
    location_type: 'zip_code',
    location: '9308',
    location_name: 'L\u00f6mmenschwil',
    latitude: 47.4908,
    longitude: 9.365,
    id: 3087,
  },
  {
    location_type: 'zip_code',
    location: '9312',
    location_name: 'H\u00e4ggenschwil',
    latitude: 47.49,
    longitude: 9.3356,
    id: 3088,
  },
  {
    location_type: 'zip_code',
    location: '9313',
    location_name: 'Muolen',
    latitude: 47.5168,
    longitude: 9.3193,
    id: 3089,
  },
  {
    location_type: 'zip_code',
    location: '9033',
    location_name: 'Untereggen',
    latitude: 47.4528,
    longitude: 9.4569,
    id: 3090,
  },
  {
    location_type: 'zip_code',
    location: '9305',
    location_name: 'Berg SG',
    latitude: 47.4871,
    longitude: 9.4124,
    id: 3091,
  },
  {
    location_type: 'zip_code',
    location: '9323',
    location_name: 'Steinach',
    latitude: 47.4948,
    longitude: 9.4332,
    id: 3092,
  },
  {
    location_type: 'zip_code',
    location: '9327',
    location_name: 'T\u00fcbach',
    latitude: 47.4811,
    longitude: 9.454,
    id: 3093,
  },
  {
    location_type: 'zip_code',
    location: '9400',
    location_name: 'Rorschach',
    latitude: 47.4757,
    longitude: 9.494,
    id: 3094,
  },
  {
    location_type: 'zip_code',
    location: '9401',
    location_name: 'Rorschach',
    latitude: 47.4667,
    longitude: 9.4417,
    id: 3095,
  },
  {
    location_type: 'zip_code',
    location: '9402',
    location_name: 'M\u00f6rschwil',
    latitude: 47.4672,
    longitude: 9.428,
    id: 3096,
  },
  {
    location_type: 'zip_code',
    location: '9403',
    location_name: 'Goldach',
    latitude: 47.4746,
    longitude: 9.4635,
    id: 3097,
  },
  {
    location_type: 'zip_code',
    location: '9404',
    location_name: 'Rorschacherberg',
    latitude: 47.4671,
    longitude: 9.5057,
    id: 3098,
  },
  {
    location_type: 'zip_code',
    location: '9422',
    location_name: 'Staad SG',
    latitude: 47.4773,
    longitude: 9.5484,
    id: 3099,
  },
  {
    location_type: 'zip_code',
    location: '9423',
    location_name: 'Altenrhein',
    latitude: 47.4947,
    longitude: 9.5586,
    id: 3100,
  },
  {
    location_type: 'zip_code',
    location: '9425',
    location_name: 'Thal',
    latitude: 47.4789,
    longitude: 9.5647,
    id: 3101,
  },
  {
    location_type: 'zip_code',
    location: '9424',
    location_name: 'Rheineck',
    latitude: 47.4668,
    longitude: 9.5878,
    id: 3102,
  },
  {
    location_type: 'zip_code',
    location: '9430',
    location_name: 'St. Margrethen SG',
    latitude: 47.4551,
    longitude: 9.6257,
    id: 3103,
  },
  {
    location_type: 'zip_code',
    location: '9434',
    location_name: 'Au SG',
    latitude: 47.4311,
    longitude: 9.6387,
    id: 3104,
  },
  {
    location_type: 'zip_code',
    location: '9435',
    location_name: 'Heerbrugg',
    latitude: 47.4129,
    longitude: 9.6273,
    id: 3105,
  },
  {
    location_type: 'zip_code',
    location: '9436',
    location_name: 'Balgach',
    latitude: 47.3945,
    longitude: 9.613,
    id: 3106,
  },
  {
    location_type: 'zip_code',
    location: '9437',
    location_name: 'Marbach SG',
    latitude: 47.3844,
    longitude: 9.5807,
    id: 3107,
  },
  {
    location_type: 'zip_code',
    location: '9443',
    location_name: 'Widnau',
    latitude: 47.4016,
    longitude: 9.6342,
    id: 3108,
  },
  {
    location_type: 'zip_code',
    location: '9444',
    location_name: 'Diepoldsau',
    latitude: 47.3831,
    longitude: 9.6473,
    id: 3109,
  },
  {
    location_type: 'zip_code',
    location: '9445',
    location_name: 'Rebstein',
    latitude: 47.401,
    longitude: 9.5884,
    id: 3110,
  },
  {
    location_type: 'zip_code',
    location: '9450',
    location_name: 'L\u00fcchingen',
    latitude: 47.397,
    longitude: 9.5495,
    id: 3111,
  },
  {
    location_type: 'zip_code',
    location: '9451',
    location_name: 'Kriessern',
    latitude: 47.3649,
    longitude: 9.6086,
    id: 3112,
  },
  {
    location_type: 'zip_code',
    location: '9452',
    location_name: 'Hinterforst',
    latitude: 47.3587,
    longitude: 9.5279,
    id: 3113,
  },
  {
    location_type: 'zip_code',
    location: '9453',
    location_name: 'Eichberg',
    latitude: 47.345,
    longitude: 9.5227,
    id: 3114,
  },
  {
    location_type: 'zip_code',
    location: '9462',
    location_name: 'Montlingen',
    latitude: 47.3328,
    longitude: 9.5858,
    id: 3115,
  },
  {
    location_type: 'zip_code',
    location: '9463',
    location_name: 'Oberriet SG',
    latitude: 47.3256,
    longitude: 9.5386,
    id: 3116,
  },
  {
    location_type: 'zip_code',
    location: '9464',
    location_name: 'Lienz',
    latitude: 47.2841,
    longitude: 9.5138,
    id: 3117,
  },
  {
    location_type: 'zip_code',
    location: '9465',
    location_name: 'Salez',
    latitude: 47.2351,
    longitude: 9.4973,
    id: 3118,
  },
  {
    location_type: 'zip_code',
    location: '9466',
    location_name: 'Sennwald',
    latitude: 47.2626,
    longitude: 9.5036,
    id: 3119,
  },
  {
    location_type: 'zip_code',
    location: '9467',
    location_name: 'Fr\u00fcmsen',
    latitude: 47.2569,
    longitude: 9.4618,
    id: 3120,
  },
  {
    location_type: 'zip_code',
    location: '9468',
    location_name: 'Sax',
    latitude: 47.2367,
    longitude: 9.4316,
    id: 3121,
  },
  {
    location_type: 'zip_code',
    location: '9469',
    location_name: 'Haag (Rheintal)',
    latitude: 47.2083,
    longitude: 9.4873,
    id: 3122,
  },
  {
    location_type: 'zip_code',
    location: '9470',
    location_name: 'Werdenberg',
    latitude: 47.1687,
    longitude: 9.457,
    id: 3123,
  },
  {
    location_type: 'zip_code',
    location: '9471',
    location_name: 'Buchs Bahnhof SBB',
    latitude: 47.15,
    longitude: 9.4567,
    id: 3124,
  },
  {
    location_type: 'zip_code',
    location: '9472',
    location_name: 'Grabserberg',
    latitude: 47.1646,
    longitude: 9.3778,
    id: 3125,
  },
  {
    location_type: 'zip_code',
    location: '9473',
    location_name: 'Gams',
    latitude: 47.2145,
    longitude: 9.4375,
    id: 3126,
  },
  {
    location_type: 'zip_code',
    location: '9475',
    location_name: 'Sevelen',
    latitude: 47.1329,
    longitude: 9.4543,
    id: 3127,
  },
  {
    location_type: 'zip_code',
    location: '9476',
    location_name: 'Weite',
    latitude: 47.0967,
    longitude: 9.4998,
    id: 3128,
  },
  {
    location_type: 'zip_code',
    location: '9477',
    location_name: 'Tr\u00fcbbach',
    latitude: 47.0781,
    longitude: 9.4882,
    id: 3129,
  },
  {
    location_type: 'zip_code',
    location: '9478',
    location_name: 'Azmoos',
    latitude: 47.0898,
    longitude: 9.4313,
    id: 3130,
  },
  {
    location_type: 'zip_code',
    location: '9479',
    location_name: 'Gretschins',
    latitude: 47.0967,
    longitude: 9.4863,
    id: 3131,
  },
  {
    location_type: 'zip_code',
    location: '7310',
    location_name: 'Bad Ragaz',
    latitude: 47.0077,
    longitude: 9.473,
    id: 3132,
  },
  {
    location_type: 'zip_code',
    location: '7312',
    location_name: 'Pf\u00e4fers',
    latitude: 46.9904,
    longitude: 9.5099,
    id: 3133,
  },
  {
    location_type: 'zip_code',
    location: '7313',
    location_name: 'St. Margrethenberg',
    latitude: 46.9649,
    longitude: 9.5171,
    id: 3134,
  },
  {
    location_type: 'zip_code',
    location: '7314',
    location_name: 'Vadura',
    latitude: 46.9569,
    longitude: 9.4924,
    id: 3135,
  },
  {
    location_type: 'zip_code',
    location: '7315',
    location_name: 'V\u00e4ttis',
    latitude: 46.8988,
    longitude: 9.3835,
    id: 3136,
  },
  {
    location_type: 'zip_code',
    location: '7317',
    location_name: 'Vas\u00f6n',
    latitude: 46.9513,
    longitude: 9.4633,
    id: 3137,
  },
  {
    location_type: 'zip_code',
    location: '7320',
    location_name: 'Sargans',
    latitude: 47.0543,
    longitude: 9.4488,
    id: 3138,
  },
  {
    location_type: 'zip_code',
    location: '7323',
    location_name: 'Wangs',
    latitude: 47.0027,
    longitude: 9.4122,
    id: 3139,
  },
  {
    location_type: 'zip_code',
    location: '7324',
    location_name: 'Vilters',
    latitude: 47.018,
    longitude: 9.4538,
    id: 3140,
  },
  {
    location_type: 'zip_code',
    location: '7325',
    location_name: 'Schwendi im Weisstannental',
    latitude: 47.0055,
    longitude: 9.3653,
    id: 3141,
  },
  {
    location_type: 'zip_code',
    location: '7326',
    location_name: 'Weisstannen',
    latitude: 46.971,
    longitude: 9.316,
    id: 3142,
  },
  {
    location_type: 'zip_code',
    location: '8877',
    location_name: 'Murg',
    latitude: 47.1008,
    longitude: 9.2072,
    id: 3143,
  },
  {
    location_type: 'zip_code',
    location: '8878',
    location_name: 'Quinten',
    latitude: 47.1347,
    longitude: 9.2205,
    id: 3144,
  },
  {
    location_type: 'zip_code',
    location: '8879',
    location_name: 'Pizolpark (Mels)',
    latitude: 47.0008,
    longitude: 9.3375,
    id: 3145,
  },
  {
    location_type: 'zip_code',
    location: '8880',
    location_name: 'Walenstadt',
    latitude: 47.1251,
    longitude: 9.3064,
    id: 3146,
  },
  {
    location_type: 'zip_code',
    location: '8881',
    location_name: 'Knoblisb\u00fchl',
    latitude: 47.1404,
    longitude: 9.2847,
    id: 3147,
  },
  {
    location_type: 'zip_code',
    location: '8882',
    location_name: 'Unterterzen',
    latitude: 47.1154,
    longitude: 9.2515,
    id: 3148,
  },
  {
    location_type: 'zip_code',
    location: '8883',
    location_name: 'Quarten',
    latitude: 47.1048,
    longitude: 9.2372,
    id: 3149,
  },
  {
    location_type: 'zip_code',
    location: '8884',
    location_name: 'Oberterzen',
    latitude: 47.0584,
    longitude: 9.2108,
    id: 3150,
  },
  {
    location_type: 'zip_code',
    location: '8885',
    location_name: 'Mols',
    latitude: 47.1102,
    longitude: 9.2834,
    id: 3151,
  },
  {
    location_type: 'zip_code',
    location: '8886',
    location_name: 'M\u00e4dris-Vermol',
    latitude: 47.0352,
    longitude: 9.3439,
    id: 3152,
  },
  {
    location_type: 'zip_code',
    location: '8887',
    location_name: 'Mels',
    latitude: 47.0444,
    longitude: 9.4264,
    id: 3153,
  },
  {
    location_type: 'zip_code',
    location: '8888',
    location_name: 'Heiligkreuz (Mels)',
    latitude: 47.066,
    longitude: 9.4099,
    id: 3154,
  },
  {
    location_type: 'zip_code',
    location: '8889',
    location_name: 'Plons',
    latitude: 47.0614,
    longitude: 9.3838,
    id: 3155,
  },
  {
    location_type: 'zip_code',
    location: '8890',
    location_name: 'Flums',
    latitude: 47.0934,
    longitude: 9.3412,
    id: 3156,
  },
  {
    location_type: 'zip_code',
    location: '8892',
    location_name: 'Berschis',
    latitude: 47.1118,
    longitude: 9.3696,
    id: 3157,
  },
  {
    location_type: 'zip_code',
    location: '8893',
    location_name: 'Flums Hochwiese',
    latitude: 47.0846,
    longitude: 9.3783,
    id: 3158,
  },
  {
    location_type: 'zip_code',
    location: '8894',
    location_name: 'Flumserberg Saxli',
    latitude: 47.0586,
    longitude: 9.3259,
    id: 3159,
  },
  {
    location_type: 'zip_code',
    location: '8895',
    location_name: 'Flumserberg Portels',
    latitude: 47.0698,
    longitude: 9.3211,
    id: 3160,
  },
  {
    location_type: 'zip_code',
    location: '8896',
    location_name: 'Flumserberg Bergheim',
    latitude: 47.0981,
    longitude: 9.3076,
    id: 3161,
  },
  {
    location_type: 'zip_code',
    location: '8897',
    location_name: 'Flumserberg Tannenheim',
    latitude: 47.0385,
    longitude: 9.2641,
    id: 3162,
  },
  {
    location_type: 'zip_code',
    location: '8898',
    location_name: 'Flumserberg Tannenbodenalp',
    latitude: 47.0866,
    longitude: 9.2749,
    id: 3163,
  },
  {
    location_type: 'zip_code',
    location: '8638',
    location_name: 'Goldingen',
    latitude: 47.293,
    longitude: 8.9784,
    id: 3164,
  },
  {
    location_type: 'zip_code',
    location: '8640',
    location_name: 'Rapperswil SG',
    latitude: 47.2245,
    longitude: 8.8192,
    id: 3165,
  },
  {
    location_type: 'zip_code',
    location: '8645',
    location_name: 'Jona',
    latitude: 47.2296,
    longitude: 8.8516,
    id: 3166,
  },
  {
    location_type: 'zip_code',
    location: '8646',
    location_name: 'Wagen',
    latitude: 47.2328,
    longitude: 8.8899,
    id: 3167,
  },
  {
    location_type: 'zip_code',
    location: '8715',
    location_name: 'Bollingen',
    latitude: 47.221,
    longitude: 8.8891,
    id: 3168,
  },
  {
    location_type: 'zip_code',
    location: '8716',
    location_name: 'Schmerikon',
    latitude: 47.2255,
    longitude: 8.9389,
    id: 3169,
  },
  {
    location_type: 'zip_code',
    location: '8717',
    location_name: 'Benken SG',
    latitude: 47.1924,
    longitude: 8.996,
    id: 3170,
  },
  {
    location_type: 'zip_code',
    location: '8718',
    location_name: 'Sch\u00e4nis',
    latitude: 47.1586,
    longitude: 9.0631,
    id: 3171,
  },
  {
    location_type: 'zip_code',
    location: '8722',
    location_name: 'Kaltbrunn',
    latitude: 47.2087,
    longitude: 9.0237,
    id: 3172,
  },
  {
    location_type: 'zip_code',
    location: '8723',
    location_name: 'Rufi',
    latitude: 47.1859,
    longitude: 9.0693,
    id: 3173,
  },
  {
    location_type: 'zip_code',
    location: '8725',
    location_name: 'Gebertingen',
    latitude: 47.2549,
    longitude: 9.0013,
    id: 3174,
  },
  {
    location_type: 'zip_code',
    location: '8726',
    location_name: 'Ricken SG',
    latitude: 47.2628,
    longitude: 9.039,
    id: 3175,
  },
  {
    location_type: 'zip_code',
    location: '8727',
    location_name: 'Walde SG',
    latitude: 47.2744,
    longitude: 9.0234,
    id: 3176,
  },
  {
    location_type: 'zip_code',
    location: '8730',
    location_name: 'Uznach',
    latitude: 47.2265,
    longitude: 8.9823,
    id: 3177,
  },
  {
    location_type: 'zip_code',
    location: '8732',
    location_name: 'Neuhaus SG',
    latitude: 47.2439,
    longitude: 8.9467,
    id: 3178,
  },
  {
    location_type: 'zip_code',
    location: '8733',
    location_name: 'Eschenbach SG',
    latitude: 47.2434,
    longitude: 8.9206,
    id: 3179,
  },
  {
    location_type: 'zip_code',
    location: '8734',
    location_name: 'Ermenswil',
    latitude: 47.2475,
    longitude: 8.8891,
    id: 3180,
  },
  {
    location_type: 'zip_code',
    location: '8735',
    location_name: 'R\u00fceterswil',
    latitude: 47.2752,
    longitude: 8.9925,
    id: 3181,
  },
  {
    location_type: 'zip_code',
    location: '8737',
    location_name: 'Gommiswald',
    latitude: 47.2316,
    longitude: 9.0207,
    id: 3182,
  },
  {
    location_type: 'zip_code',
    location: '8738',
    location_name: 'Uetliburg SG',
    latitude: 47.2444,
    longitude: 9.0483,
    id: 3183,
  },
  {
    location_type: 'zip_code',
    location: '8739',
    location_name: 'Rieden SG',
    latitude: 47.2268,
    longitude: 9.0726,
    id: 3184,
  },
  {
    location_type: 'zip_code',
    location: '8740',
    location_name: 'Uznach V\u00f6gele Versandhaus',
    latitude: 47.2265,
    longitude: 8.9813,
    id: 3185,
  },
  {
    location_type: 'zip_code',
    location: '8872',
    location_name: 'Weesen',
    latitude: 47.142,
    longitude: 9.0948,
    id: 3186,
  },
  {
    location_type: 'zip_code',
    location: '8873',
    location_name: 'Amden',
    latitude: 47.1554,
    longitude: 9.1653,
    id: 3187,
  },
  {
    location_type: 'zip_code',
    location: '9114',
    location_name: 'Hoffeld',
    latitude: 47.355,
    longitude: 9.1724,
    id: 3188,
  },
  {
    location_type: 'zip_code',
    location: '9115',
    location_name: 'Dicken',
    latitude: 47.3371,
    longitude: 9.1973,
    id: 3189,
  },
  {
    location_type: 'zip_code',
    location: '9122',
    location_name: 'Mogelsberg',
    latitude: 47.3605,
    longitude: 9.1364,
    id: 3190,
  },
  {
    location_type: 'zip_code',
    location: '9123',
    location_name: 'Nassen',
    latitude: 47.379,
    longitude: 9.1552,
    id: 3191,
  },
  {
    location_type: 'zip_code',
    location: '9125',
    location_name: 'Brunnadern',
    latitude: 47.3254,
    longitude: 9.1454,
    id: 3192,
  },
  {
    location_type: 'zip_code',
    location: '9126',
    location_name: 'Necker',
    latitude: 47.3459,
    longitude: 9.1299,
    id: 3193,
  },
  {
    location_type: 'zip_code',
    location: '9127',
    location_name: 'St. Peterzell',
    latitude: 47.3204,
    longitude: 9.1832,
    id: 3194,
  },
  {
    location_type: 'zip_code',
    location: '9533',
    location_name: 'Kirchberg SG',
    latitude: 47.42,
    longitude: 9.0244,
    id: 3195,
  },
  {
    location_type: 'zip_code',
    location: '9534',
    location_name: 'G\u00e4hwil',
    latitude: 47.3964,
    longitude: 9.0003,
    id: 3196,
  },
  {
    location_type: 'zip_code',
    location: '9601',
    location_name: 'L\u00fctisburg Station',
    latitude: 47.3813,
    longitude: 9.067,
    id: 3197,
  },
  {
    location_type: 'zip_code',
    location: '9602',
    location_name: 'M\u00fcselbach',
    latitude: 47.3871,
    longitude: 9.0357,
    id: 3198,
  },
  {
    location_type: 'zip_code',
    location: '9604',
    location_name: 'Unterrindal',
    latitude: 47.4086,
    longitude: 9.0933,
    id: 3199,
  },
  {
    location_type: 'zip_code',
    location: '9606',
    location_name: 'B\u00fctschwil',
    latitude: 47.3567,
    longitude: 9.0668,
    id: 3200,
  },
  {
    location_type: 'zip_code',
    location: '9607',
    location_name: 'Mosnang',
    latitude: 47.3608,
    longitude: 9.0364,
    id: 3201,
  },
  {
    location_type: 'zip_code',
    location: '9608',
    location_name: 'Ganterschwil',
    latitude: 47.3716,
    longitude: 9.1,
    id: 3202,
  },
  {
    location_type: 'zip_code',
    location: '9612',
    location_name: 'Dreien',
    latitude: 47.3454,
    longitude: 9.0038,
    id: 3203,
  },
  {
    location_type: 'zip_code',
    location: '9613',
    location_name: 'M\u00fchlr\u00fcti',
    latitude: 47.3694,
    longitude: 8.9796,
    id: 3204,
  },
  {
    location_type: 'zip_code',
    location: '9614',
    location_name: 'Libingen',
    latitude: 47.3192,
    longitude: 9.0197,
    id: 3205,
  },
  {
    location_type: 'zip_code',
    location: '9615',
    location_name: 'Dietfurt',
    latitude: 47.3381,
    longitude: 9.0721,
    id: 3206,
  },
  {
    location_type: 'zip_code',
    location: '9620',
    location_name: 'Lichtensteig',
    latitude: 47.3191,
    longitude: 9.0969,
    id: 3207,
  },
  {
    location_type: 'zip_code',
    location: '9621',
    location_name: 'Oberhelfenschwil',
    latitude: 47.3448,
    longitude: 9.1055,
    id: 3208,
  },
  {
    location_type: 'zip_code',
    location: '9622',
    location_name: 'Krinau',
    latitude: 47.3151,
    longitude: 9.0503,
    id: 3209,
  },
  {
    location_type: 'zip_code',
    location: '9630',
    location_name: 'Wattwil',
    latitude: 47.2858,
    longitude: 9.0867,
    id: 3210,
  },
  {
    location_type: 'zip_code',
    location: '9631',
    location_name: 'Ulisbach',
    latitude: 47.2859,
    longitude: 9.117,
    id: 3211,
  },
  {
    location_type: 'zip_code',
    location: '9633',
    location_name: 'Hemberg',
    latitude: 47.2959,
    longitude: 9.1774,
    id: 3212,
  },
  {
    location_type: 'zip_code',
    location: '9642',
    location_name: 'Ebnat-Kappel',
    latitude: 47.2357,
    longitude: 9.1318,
    id: 3213,
  },
  {
    location_type: 'zip_code',
    location: '9643',
    location_name: 'Krummenau',
    latitude: 47.2487,
    longitude: 9.1722,
    id: 3214,
  },
  {
    location_type: 'zip_code',
    location: '9650',
    location_name: 'Nesslau',
    latitude: 47.2114,
    longitude: 9.1856,
    id: 3215,
  },
  {
    location_type: 'zip_code',
    location: '9651',
    location_name: 'Ennetb\u00fchl',
    latitude: 47.2444,
    longitude: 9.2569,
    id: 3216,
  },
  {
    location_type: 'zip_code',
    location: '9652',
    location_name: 'Neu St. Johann',
    latitude: 47.2371,
    longitude: 9.1935,
    id: 3217,
  },
  {
    location_type: 'zip_code',
    location: '9655',
    location_name: 'Stein SG',
    latitude: 47.1994,
    longitude: 9.2286,
    id: 3218,
  },
  {
    location_type: 'zip_code',
    location: '9113',
    location_name: 'Degersheim',
    latitude: 47.3707,
    longitude: 9.1925,
    id: 3219,
  },
  {
    location_type: 'zip_code',
    location: '9116',
    location_name: 'Wolfertswil',
    latitude: 47.3951,
    longitude: 9.1687,
    id: 3220,
  },
  {
    location_type: 'zip_code',
    location: '9203',
    location_name: 'Niederwil SG',
    latitude: 47.4304,
    longitude: 9.1999,
    id: 3221,
  },
  {
    location_type: 'zip_code',
    location: '9230',
    location_name: 'Flawil',
    latitude: 47.409,
    longitude: 9.1896,
    id: 3222,
  },
  {
    location_type: 'zip_code',
    location: '9231',
    location_name: 'Egg (Flawil)',
    latitude: 47.3933,
    longitude: 9.2219,
    id: 3223,
  },
  {
    location_type: 'zip_code',
    location: '9240',
    location_name: 'Niederglatt SG',
    latitude: 47.4287,
    longitude: 9.169,
    id: 3224,
  },
  {
    location_type: 'zip_code',
    location: '9242',
    location_name: 'Oberuzwil',
    latitude: 47.429,
    longitude: 9.1205,
    id: 3225,
  },
  {
    location_type: 'zip_code',
    location: '9243',
    location_name: 'Jonschwil',
    latitude: 47.4255,
    longitude: 9.0966,
    id: 3226,
  },
  {
    location_type: 'zip_code',
    location: '9244',
    location_name: 'Niederuzwil',
    latitude: 47.4443,
    longitude: 9.1439,
    id: 3227,
  },
  {
    location_type: 'zip_code',
    location: '9245',
    location_name: 'Oberb\u00fcren',
    latitude: 47.4484,
    longitude: 9.168,
    id: 3228,
  },
  {
    location_type: 'zip_code',
    location: '9246',
    location_name: 'Niederb\u00fcren',
    latitude: 47.4631,
    longitude: 9.2099,
    id: 3229,
  },
  {
    location_type: 'zip_code',
    location: '9247',
    location_name: 'Henau',
    latitude: 47.4565,
    longitude: 9.1125,
    id: 3230,
  },
  {
    location_type: 'zip_code',
    location: '9248',
    location_name: 'Bichwil',
    latitude: 47.4155,
    longitude: 9.1372,
    id: 3231,
  },
  {
    location_type: 'zip_code',
    location: '9249',
    location_name: 'Oberstetten',
    latitude: 47.4458,
    longitude: 9.0916,
    id: 3232,
  },
  {
    location_type: 'zip_code',
    location: '9500',
    location_name: 'Wil SG',
    latitude: 47.4589,
    longitude: 9.0534,
    id: 3233,
  },
  {
    location_type: 'zip_code',
    location: '9501',
    location_name: 'Wil SG 1',
    latitude: 47.525,
    longitude: 8.9667,
    id: 3234,
  },
  {
    location_type: 'zip_code',
    location: '9510',
    location_name: 'Wil SG Distributionsbasis',
    latitude: 47.5135,
    longitude: 9.1162,
    id: 3235,
  },
  {
    location_type: 'zip_code',
    location: '9512',
    location_name: 'Rossr\u00fcti',
    latitude: 47.4818,
    longitude: 9.0722,
    id: 3236,
  },
  {
    location_type: 'zip_code',
    location: '9523',
    location_name: 'Z\u00fcberwangen',
    latitude: 47.4657,
    longitude: 9.0926,
    id: 3237,
  },
  {
    location_type: 'zip_code',
    location: '9524',
    location_name: 'Zuzwil SG',
    latitude: 47.4734,
    longitude: 9.107,
    id: 3238,
  },
  {
    location_type: 'zip_code',
    location: '9525',
    location_name: 'Lenggenwil',
    latitude: 47.4717,
    longitude: 9.1433,
    id: 3239,
  },
  {
    location_type: 'zip_code',
    location: '9526',
    location_name: 'Zuckenriet',
    latitude: 47.4862,
    longitude: 9.1624,
    id: 3240,
  },
  {
    location_type: 'zip_code',
    location: '9527',
    location_name: 'Niederhelfenschwil',
    latitude: 47.4775,
    longitude: 9.1861,
    id: 3241,
  },
  {
    location_type: 'zip_code',
    location: '9536',
    location_name: 'Schwarzenbach SG',
    latitude: 47.4434,
    longitude: 9.0671,
    id: 3242,
  },
  {
    location_type: 'zip_code',
    location: '9552',
    location_name: 'Bronschhofen',
    latitude: 47.4734,
    longitude: 9.028,
    id: 3243,
  },
  {
    location_type: 'zip_code',
    location: '8213',
    location_name: 'Neunkirch',
    latitude: 47.6811,
    longitude: 8.5065,
    id: 3244,
  },
  {
    location_type: 'zip_code',
    location: '8214',
    location_name: 'G\u00e4chlingen',
    latitude: 47.7107,
    longitude: 8.498,
    id: 3245,
  },
  {
    location_type: 'zip_code',
    location: '8223',
    location_name: 'Guntmadingen',
    latitude: 47.6805,
    longitude: 8.5571,
    id: 3246,
  },
  {
    location_type: 'zip_code',
    location: '8224',
    location_name: 'L\u00f6hningen',
    latitude: 47.7058,
    longitude: 8.5459,
    id: 3247,
  },
  {
    location_type: 'zip_code',
    location: '8234',
    location_name: 'Stetten',
    latitude: 47.7372,
    longitude: 8.6562,
    id: 3248,
  },
  {
    location_type: 'zip_code',
    location: '8235',
    location_name: 'Lohn',
    latitude: 47.7573,
    longitude: 8.671,
    id: 3249,
  },
  {
    location_type: 'zip_code',
    location: '8236',
    location_name: 'Opfertshofen',
    latitude: 47.7727,
    longitude: 8.6616,
    id: 3250,
  },
  {
    location_type: 'zip_code',
    location: '8239',
    location_name: 'D\u00f6rflingen',
    latitude: 47.7088,
    longitude: 8.7142,
    id: 3251,
  },
  {
    location_type: 'zip_code',
    location: '8240',
    location_name: 'Thayngen',
    latitude: 47.7395,
    longitude: 8.7106,
    id: 3252,
  },
  {
    location_type: 'zip_code',
    location: '8241',
    location_name: 'Barzheim',
    latitude: 47.7602,
    longitude: 8.7162,
    id: 3253,
  },
  {
    location_type: 'zip_code',
    location: '8242',
    location_name: 'Bibern',
    latitude: 47.7698,
    longitude: 8.6774,
    id: 3254,
  },
  {
    location_type: 'zip_code',
    location: '8243',
    location_name: 'Altdorf',
    latitude: 47.7872,
    longitude: 8.6578,
    id: 3255,
  },
  {
    location_type: 'zip_code',
    location: '8200',
    location_name: 'Schaffhausen',
    latitude: 47.7151,
    longitude: 8.622,
    id: 3256,
  },
  {
    location_type: 'zip_code',
    location: '8201',
    location_name: 'Schaffhausen',
    latitude: 47.7,
    longitude: 8.6333,
    id: 3257,
  },
  {
    location_type: 'zip_code',
    location: '8202',
    location_name: 'Schaffhausen',
    latitude: 47.7,
    longitude: 8.6333,
    id: 3258,
  },
  {
    location_type: 'zip_code',
    location: '8203',
    location_name: 'Buchthalen',
    latitude: 47.6998,
    longitude: 8.662,
    id: 3259,
  },
  {
    location_type: 'zip_code',
    location: '8204',
    location_name: 'Schaffhausen',
    latitude: 47.7,
    longitude: 8.6333,
    id: 3260,
  },
  {
    location_type: 'zip_code',
    location: '8205',
    location_name: 'Schaffhausen',
    latitude: 47.7,
    longitude: 8.6333,
    id: 3261,
  },
  {
    location_type: 'zip_code',
    location: '8207',
    location_name: 'Herblingen',
    latitude: 47.7235,
    longitude: 8.6728,
    id: 3262,
  },
  {
    location_type: 'zip_code',
    location: '8208',
    location_name: 'Schaffhausen',
    latitude: 47.7118,
    longitude: 8.6356,
    id: 3263,
  },
  {
    location_type: 'zip_code',
    location: '8210',
    location_name: 'Schaffhausen Dist Ba',
    latitude: 47.7157,
    longitude: 8.6411,
    id: 3264,
  },
  {
    location_type: 'zip_code',
    location: '8212',
    location_name: 'Neuhausen am Rheinfall',
    latitude: 47.6786,
    longitude: 8.5984,
    id: 3265,
  },
  {
    location_type: 'zip_code',
    location: '8222',
    location_name: 'Beringen',
    latitude: 47.6982,
    longitude: 8.5758,
    id: 3266,
  },
  {
    location_type: 'zip_code',
    location: '8231',
    location_name: 'Hemmental',
    latitude: 47.7391,
    longitude: 8.5739,
    id: 3267,
  },
  {
    location_type: 'zip_code',
    location: '8232',
    location_name: 'Merishausen',
    latitude: 47.763,
    longitude: 8.6024,
    id: 3268,
  },
  {
    location_type: 'zip_code',
    location: '8233',
    location_name: 'Bargen',
    latitude: 47.7942,
    longitude: 8.592,
    id: 3269,
  },
  {
    location_type: 'zip_code',
    location: '8454',
    location_name: 'Buchberg',
    latitude: 47.5685,
    longitude: 8.5509,
    id: 3270,
  },
  {
    location_type: 'zip_code',
    location: '8455',
    location_name: 'Buchberg',
    latitude: 47.5919,
    longitude: 8.553,
    id: 3271,
  },
  {
    location_type: 'zip_code',
    location: '8225',
    location_name: 'Siblingen',
    latitude: 47.7199,
    longitude: 8.5205,
    id: 3272,
  },
  {
    location_type: 'zip_code',
    location: '8226',
    location_name: 'Schleitheim',
    latitude: 47.7509,
    longitude: 8.5023,
    id: 3273,
  },
  {
    location_type: 'zip_code',
    location: '8228',
    location_name: 'Beggingen',
    latitude: 47.7659,
    longitude: 8.5397,
    id: 3274,
  },
  {
    location_type: 'zip_code',
    location: '8260',
    location_name: 'Stein am Rhein',
    latitude: 47.6654,
    longitude: 8.8565,
    id: 3275,
  },
  {
    location_type: 'zip_code',
    location: '8261',
    location_name: 'Hemishofen',
    latitude: 47.6844,
    longitude: 8.8405,
    id: 3276,
  },
  {
    location_type: 'zip_code',
    location: '8262',
    location_name: 'Ramsen',
    latitude: 47.7066,
    longitude: 8.8193,
    id: 3277,
  },
  {
    location_type: 'zip_code',
    location: '8263',
    location_name: 'Buch',
    latitude: 47.7162,
    longitude: 8.7845,
    id: 3278,
  },
  {
    location_type: 'zip_code',
    location: '8215',
    location_name: 'Hallau',
    latitude: 47.7015,
    longitude: 8.4398,
    id: 3279,
  },
  {
    location_type: 'zip_code',
    location: '8216',
    location_name: 'Oberhallau',
    latitude: 47.7143,
    longitude: 8.4723,
    id: 3280,
  },
  {
    location_type: 'zip_code',
    location: '8217',
    location_name: 'Wilchingen',
    latitude: 47.6663,
    longitude: 8.4576,
    id: 3281,
  },
  {
    location_type: 'zip_code',
    location: '8218',
    location_name: 'Osterfingen',
    latitude: 47.6573,
    longitude: 8.5017,
    id: 3282,
  },
  {
    location_type: 'zip_code',
    location: '8219',
    location_name: 'Trasadingen',
    latitude: 47.6689,
    longitude: 8.4252,
    id: 3283,
  },
  {
    location_type: 'zip_code',
    location: '4611',
    location_name: 'H\u00e4rkingen Scanning-Center',
    latitude: 47.3046,
    longitude: 7.8214,
    id: 3284,
  },
  {
    location_type: 'zip_code',
    location: '4620',
    location_name: 'H\u00e4rkingen Paketzentrum',
    latitude: 47.2867,
    longitude: 7.7767,
    id: 3285,
  },
  {
    location_type: 'zip_code',
    location: '4621',
    location_name: 'H\u00e4rkingen DocumentService',
    latitude: 47.3046,
    longitude: 7.8214,
    id: 3286,
  },
  {
    location_type: 'zip_code',
    location: '4622',
    location_name: 'Egerkingen',
    latitude: 47.3256,
    longitude: 7.7972,
    id: 3287,
  },
  {
    location_type: 'zip_code',
    location: '4623',
    location_name: 'Neuendorf',
    latitude: 47.2966,
    longitude: 7.8005,
    id: 3288,
  },
  {
    location_type: 'zip_code',
    location: '4624',
    location_name: 'H\u00e4rkingen',
    latitude: 47.3052,
    longitude: 7.8208,
    id: 3289,
  },
  {
    location_type: 'zip_code',
    location: '4625',
    location_name: 'Oberbuchsiten',
    latitude: 47.3126,
    longitude: 7.7599,
    id: 3290,
  },
  {
    location_type: 'zip_code',
    location: '4626',
    location_name: 'Niederbuchsiten',
    latitude: 47.2881,
    longitude: 7.7782,
    id: 3291,
  },
  {
    location_type: 'zip_code',
    location: '4628',
    location_name: 'Wolfwil',
    latitude: 47.2693,
    longitude: 7.798,
    id: 3292,
  },
  {
    location_type: 'zip_code',
    location: '4630',
    location_name: 'H\u00e4rkingen CALL',
    latitude: 47.3667,
    longitude: 7.8833,
    id: 3293,
  },
  {
    location_type: 'zip_code',
    location: '4640',
    location_name: 'H\u00e4rkingen ST PP 1',
    latitude: 47.3271,
    longitude: 7.8604,
    id: 3294,
  },
  {
    location_type: 'zip_code',
    location: '4702',
    location_name: 'Oensingen',
    latitude: 47.2903,
    longitude: 7.7185,
    id: 3295,
  },
  {
    location_type: 'zip_code',
    location: '4703',
    location_name: 'Kestenholz',
    latitude: 47.2775,
    longitude: 7.7553,
    id: 3296,
  },
  {
    location_type: 'zip_code',
    location: '4710',
    location_name: 'Balsthal',
    latitude: 47.3129,
    longitude: 7.708,
    id: 3297,
  },
  {
    location_type: 'zip_code',
    location: '4712',
    location_name: 'Laupersdorf',
    latitude: 47.3087,
    longitude: 7.6529,
    id: 3298,
  },
  {
    location_type: 'zip_code',
    location: '4713',
    location_name: 'Matzendorf',
    latitude: 47.3036,
    longitude: 7.6288,
    id: 3299,
  },
  {
    location_type: 'zip_code',
    location: '4714',
    location_name: 'Aedermannsdorf',
    latitude: 47.3133,
    longitude: 7.5933,
    id: 3300,
  },
  {
    location_type: 'zip_code',
    location: '4715',
    location_name: 'Herbetswil',
    latitude: 47.2939,
    longitude: 7.5742,
    id: 3301,
  },
  {
    location_type: 'zip_code',
    location: '4716',
    location_name: 'G\u00e4nsbrunnen',
    latitude: 47.2687,
    longitude: 7.4823,
    id: 3302,
  },
  {
    location_type: 'zip_code',
    location: '4717',
    location_name: 'M\u00fcmliswil',
    latitude: 47.3437,
    longitude: 7.7143,
    id: 3303,
  },
  {
    location_type: 'zip_code',
    location: '4718',
    location_name: 'Holderbank SO',
    latitude: 47.3312,
    longitude: 7.7523,
    id: 3304,
  },
  {
    location_type: 'zip_code',
    location: '4719',
    location_name: 'Ramiswil',
    latitude: 47.3449,
    longitude: 7.6436,
    id: 3305,
  },
  {
    location_type: 'zip_code',
    location: '3253',
    location_name: 'Schnottwil',
    latitude: 47.1063,
    longitude: 7.391,
    id: 3306,
  },
  {
    location_type: 'zip_code',
    location: '3254',
    location_name: 'Balm b. Messen',
    latitude: 47.1043,
    longitude: 7.435,
    id: 3307,
  },
  {
    location_type: 'zip_code',
    location: '3307',
    location_name: 'Brunnenthal',
    latitude: 47.0818,
    longitude: 7.4645,
    id: 3308,
  },
  {
    location_type: 'zip_code',
    location: '4571',
    location_name: 'L\u00fcterkofen',
    latitude: 47.1657,
    longitude: 7.5098,
    id: 3309,
  },
  {
    location_type: 'zip_code',
    location: '4574',
    location_name: 'Nennigkofen',
    latitude: 47.1842,
    longitude: 7.4897,
    id: 3310,
  },
  {
    location_type: 'zip_code',
    location: '4576',
    location_name: 'Tscheppach',
    latitude: 47.1482,
    longitude: 7.4821,
    id: 3311,
  },
  {
    location_type: 'zip_code',
    location: '4577',
    location_name: 'Hessigkofen',
    latitude: 47.1402,
    longitude: 7.4656,
    id: 3312,
  },
  {
    location_type: 'zip_code',
    location: '4578',
    location_name: 'Bibern SO',
    latitude: 47.1474,
    longitude: 7.4549,
    id: 3313,
  },
  {
    location_type: 'zip_code',
    location: '4579',
    location_name: 'Gossliwil',
    latitude: 47.137,
    longitude: 7.4336,
    id: 3314,
  },
  {
    location_type: 'zip_code',
    location: '4581',
    location_name: 'K\u00fcttigkofen',
    latitude: 47.1512,
    longitude: 7.5138,
    id: 3315,
  },
  {
    location_type: 'zip_code',
    location: '4582',
    location_name: 'Br\u00fcgglen',
    latitude: 47.1451,
    longitude: 7.4939,
    id: 3316,
  },
  {
    location_type: 'zip_code',
    location: '4583',
    location_name: 'Aetigkofen',
    latitude: 47.1225,
    longitude: 7.4632,
    id: 3317,
  },
  {
    location_type: 'zip_code',
    location: '4584',
    location_name: 'L\u00fcterswil',
    latitude: 47.1167,
    longitude: 7.4404,
    id: 3318,
  },
  {
    location_type: 'zip_code',
    location: '4585',
    location_name: 'Biezwil',
    latitude: 47.1105,
    longitude: 7.4212,
    id: 3319,
  },
  {
    location_type: 'zip_code',
    location: '4586',
    location_name: 'Kyburg-Buchegg',
    latitude: 47.1429,
    longitude: 7.513,
    id: 3320,
  },
  {
    location_type: 'zip_code',
    location: '4587',
    location_name: 'Aetingen',
    latitude: 47.1294,
    longitude: 7.501,
    id: 3321,
  },
  {
    location_type: 'zip_code',
    location: '4588',
    location_name: 'Brittern',
    latitude: 47.1212,
    longitude: 7.4941,
    id: 3322,
  },
  {
    location_type: 'zip_code',
    location: '4108',
    location_name: 'Witterswil',
    latitude: 47.4879,
    longitude: 7.5256,
    id: 3323,
  },
  {
    location_type: 'zip_code',
    location: '4112',
    location_name: 'B\u00e4ttwil',
    latitude: 47.4908,
    longitude: 7.5086,
    id: 3324,
  },
  {
    location_type: 'zip_code',
    location: '4114',
    location_name: 'Hofstetten SO',
    latitude: 47.4718,
    longitude: 7.5088,
    id: 3325,
  },
  {
    location_type: 'zip_code',
    location: '4115',
    location_name: 'Mariastein',
    latitude: 47.4735,
    longitude: 7.4823,
    id: 3326,
  },
  {
    location_type: 'zip_code',
    location: '4116',
    location_name: 'Metzerlen',
    latitude: 47.4674,
    longitude: 7.4701,
    id: 3327,
  },
  {
    location_type: 'zip_code',
    location: '4118',
    location_name: 'Rodersdorf',
    latitude: 47.4844,
    longitude: 7.4447,
    id: 3328,
  },
  {
    location_type: 'zip_code',
    location: '4143',
    location_name: 'Dornach',
    latitude: 47.4761,
    longitude: 7.6252,
    id: 3329,
  },
  {
    location_type: 'zip_code',
    location: '4145',
    location_name: 'Gempen',
    latitude: 47.4807,
    longitude: 7.6616,
    id: 3330,
  },
  {
    location_type: 'zip_code',
    location: '4146',
    location_name: 'Hochwald',
    latitude: 47.4563,
    longitude: 7.6431,
    id: 3331,
  },
  {
    location_type: 'zip_code',
    location: '4206',
    location_name: 'Seewen SO',
    latitude: 47.4281,
    longitude: 7.6489,
    id: 3332,
  },
  {
    location_type: 'zip_code',
    location: '4412',
    location_name: 'Nuglar',
    latitude: 47.4695,
    longitude: 7.6933,
    id: 3333,
  },
  {
    location_type: 'zip_code',
    location: '4413',
    location_name: 'B\u00fcren SO',
    latitude: 47.4493,
    longitude: 7.6714,
    id: 3334,
  },
  {
    location_type: 'zip_code',
    location: '4421',
    location_name: 'St. Pantaleon',
    latitude: 47.4593,
    longitude: 7.6883,
    id: 3335,
  },
  {
    location_type: 'zip_code',
    location: '4468',
    location_name: 'Kienberg',
    latitude: 47.4429,
    longitude: 7.9671,
    id: 3336,
  },
  {
    location_type: 'zip_code',
    location: '4632',
    location_name: 'Trimbach',
    latitude: 47.3698,
    longitude: 7.8934,
    id: 3337,
  },
  {
    location_type: 'zip_code',
    location: '4633',
    location_name: 'Hauenstein',
    latitude: 47.3718,
    longitude: 7.8467,
    id: 3338,
  },
  {
    location_type: 'zip_code',
    location: '4634',
    location_name: 'Wisen SO',
    latitude: 47.3921,
    longitude: 7.8916,
    id: 3339,
  },
  {
    location_type: 'zip_code',
    location: '4652',
    location_name: 'Winznau',
    latitude: 47.3664,
    longitude: 7.9302,
    id: 3340,
  },
  {
    location_type: 'zip_code',
    location: '4653',
    location_name: 'Oberg\u00f6sgen',
    latitude: 47.3636,
    longitude: 7.9527,
    id: 3341,
  },
  {
    location_type: 'zip_code',
    location: '4654',
    location_name: 'Lostorf',
    latitude: 47.3895,
    longitude: 7.935,
    id: 3342,
  },
  {
    location_type: 'zip_code',
    location: '4655',
    location_name: 'Rohr b. Olten',
    latitude: 47.4081,
    longitude: 7.948,
    id: 3343,
  },
  {
    location_type: 'zip_code',
    location: '5013',
    location_name: 'Niederg\u00f6sgen',
    latitude: 47.3748,
    longitude: 7.983,
    id: 3344,
  },
  {
    location_type: 'zip_code',
    location: '5015',
    location_name: 'Erlinsbach SO',
    latitude: 47.4006,
    longitude: 8.0018,
    id: 3345,
  },
  {
    location_type: 'zip_code',
    location: '4528',
    location_name: 'Zuchwil',
    latitude: 47.2055,
    longitude: 7.5607,
    id: 3346,
  },
  {
    location_type: 'zip_code',
    location: '4542',
    location_name: 'Luterbach',
    latitude: 47.2163,
    longitude: 7.589,
    id: 3347,
  },
  {
    location_type: 'zip_code',
    location: '4543',
    location_name: 'Deitingen',
    latitude: 47.2177,
    longitude: 7.6251,
    id: 3348,
  },
  {
    location_type: 'zip_code',
    location: '4551',
    location_name: 'Derendingen Swisscom',
    latitude: 47.2,
    longitude: 7.6,
    id: 3349,
  },
  {
    location_type: 'zip_code',
    location: '4552',
    location_name: 'Derendingen',
    latitude: 47.19,
    longitude: 7.5899,
    id: 3350,
  },
  {
    location_type: 'zip_code',
    location: '4553',
    location_name: 'Subingen',
    latitude: 47.1991,
    longitude: 7.621,
    id: 3351,
  },
  {
    location_type: 'zip_code',
    location: '4554',
    location_name: 'H\u00fcniken',
    latitude: 47.1837,
    longitude: 7.6348,
    id: 3352,
  },
  {
    location_type: 'zip_code',
    location: '4556',
    location_name: 'Burg\u00e4schi',
    latitude: 47.1717,
    longitude: 7.67,
    id: 3353,
  },
  {
    location_type: 'zip_code',
    location: '4557',
    location_name: 'Horriwil',
    latitude: 47.1806,
    longitude: 7.6221,
    id: 3354,
  },
  {
    location_type: 'zip_code',
    location: '4558',
    location_name: 'Hersiwil',
    latitude: 47.1655,
    longitude: 7.6345,
    id: 3355,
  },
  {
    location_type: 'zip_code',
    location: '4562',
    location_name: 'Biberist',
    latitude: 47.1815,
    longitude: 7.5447,
    id: 3356,
  },
  {
    location_type: 'zip_code',
    location: '4563',
    location_name: 'Gerlafingen',
    latitude: 47.1701,
    longitude: 7.5696,
    id: 3357,
  },
  {
    location_type: 'zip_code',
    location: '4565',
    location_name: 'Recherswil',
    latitude: 47.1609,
    longitude: 7.6035,
    id: 3358,
  },
  {
    location_type: 'zip_code',
    location: '4566',
    location_name: 'Kriegstetten',
    latitude: 47.1754,
    longitude: 7.5947,
    id: 3359,
  },
  {
    location_type: 'zip_code',
    location: '4573',
    location_name: 'Lohn-Ammannsegg',
    latitude: 47.1723,
    longitude: 7.527,
    id: 3360,
  },
  {
    location_type: 'zip_code',
    location: '2540',
    location_name: 'Grenchen',
    latitude: 47.2031,
    longitude: 7.3861,
    id: 3361,
  },
  {
    location_type: 'zip_code',
    location: '2544',
    location_name: 'Bettlach',
    latitude: 47.2091,
    longitude: 7.4196,
    id: 3362,
  },
  {
    location_type: 'zip_code',
    location: '2545',
    location_name: 'Selzach',
    latitude: 47.2178,
    longitude: 7.4471,
    id: 3363,
  },
  {
    location_type: 'zip_code',
    location: '4512',
    location_name: 'Bellach',
    latitude: 47.2102,
    longitude: 7.4958,
    id: 3364,
  },
  {
    location_type: 'zip_code',
    location: '4513',
    location_name: 'Langendorf',
    latitude: 47.2198,
    longitude: 7.5149,
    id: 3365,
  },
  {
    location_type: 'zip_code',
    location: '4514',
    location_name: 'Lommiswil',
    latitude: 47.229,
    longitude: 7.4698,
    id: 3366,
  },
  {
    location_type: 'zip_code',
    location: '4515',
    location_name: 'Oberdorf SO',
    latitude: 47.2326,
    longitude: 7.497,
    id: 3367,
  },
  {
    location_type: 'zip_code',
    location: '4522',
    location_name: 'R\u00fcttenen',
    latitude: 47.239,
    longitude: 7.5321,
    id: 3368,
  },
  {
    location_type: 'zip_code',
    location: '4523',
    location_name: 'Niederwil',
    latitude: 47.2484,
    longitude: 7.5693,
    id: 3369,
  },
  {
    location_type: 'zip_code',
    location: '4524',
    location_name: 'Balm b. G\u00fcnsberg',
    latitude: 47.2628,
    longitude: 7.5718,
    id: 3370,
  },
  {
    location_type: 'zip_code',
    location: '4525',
    location_name: 'Balm b. G\u00fcnsberg',
    latitude: 47.2582,
    longitude: 7.5426,
    id: 3371,
  },
  {
    location_type: 'zip_code',
    location: '4532',
    location_name: 'Feldbrunnen',
    latitude: 47.2204,
    longitude: 7.5524,
    id: 3372,
  },
  {
    location_type: 'zip_code',
    location: '4533',
    location_name: 'Riedholz',
    latitude: 47.2302,
    longitude: 7.5702,
    id: 3373,
  },
  {
    location_type: 'zip_code',
    location: '4534',
    location_name: 'Flumenthal',
    latitude: 47.2362,
    longitude: 7.6003,
    id: 3374,
  },
  {
    location_type: 'zip_code',
    location: '4535',
    location_name: 'Hubersdorf',
    latitude: 47.2449,
    longitude: 7.5887,
    id: 3375,
  },
  {
    location_type: 'zip_code',
    location: '4600',
    location_name: 'Olten',
    latitude: 47.3433,
    longitude: 7.9052,
    id: 3376,
  },
  {
    location_type: 'zip_code',
    location: '4601',
    location_name: 'Olten',
    latitude: 47.35,
    longitude: 7.9167,
    id: 3377,
  },
  {
    location_type: 'zip_code',
    location: '4603',
    location_name: 'Olten',
    latitude: 47.35,
    longitude: 7.9167,
    id: 3378,
  },
  {
    location_type: 'zip_code',
    location: '4609',
    location_name: 'Olten Sonderdienste',
    latitude: 47.35,
    longitude: 7.9167,
    id: 3379,
  },
  {
    location_type: 'zip_code',
    location: '4612',
    location_name: 'Wangen b. Olten',
    latitude: 47.3468,
    longitude: 7.8691,
    id: 3380,
  },
  {
    location_type: 'zip_code',
    location: '4613',
    location_name: 'Rickenbach',
    latitude: 47.3405,
    longitude: 7.8567,
    id: 3381,
  },
  {
    location_type: 'zip_code',
    location: '4614',
    location_name: 'H\u00e4gendorf',
    latitude: 47.3333,
    longitude: 7.8333,
    id: 3382,
  },
  {
    location_type: 'zip_code',
    location: '4615',
    location_name: 'Allerheiligenberg',
    latitude: 47.352,
    longitude: 7.8175,
    id: 3383,
  },
  {
    location_type: 'zip_code',
    location: '4616',
    location_name: 'Kappel SO',
    latitude: 47.321,
    longitude: 7.8567,
    id: 3384,
  },
  {
    location_type: 'zip_code',
    location: '4617',
    location_name: 'Gunzgen',
    latitude: 47.3156,
    longitude: 7.8335,
    id: 3385,
  },
  {
    location_type: 'zip_code',
    location: '4618',
    location_name: 'Boningen',
    latitude: 47.3011,
    longitude: 7.8519,
    id: 3386,
  },
  {
    location_type: 'zip_code',
    location: '4629',
    location_name: 'Fulenbach',
    latitude: 47.2789,
    longitude: 7.8317,
    id: 3387,
  },
  {
    location_type: 'zip_code',
    location: '4656',
    location_name: 'Starrkirch-Wil',
    latitude: 47.345,
    longitude: 7.9279,
    id: 3388,
  },
  {
    location_type: 'zip_code',
    location: '4657',
    location_name: 'Dulliken',
    latitude: 47.3477,
    longitude: 7.9483,
    id: 3389,
  },
  {
    location_type: 'zip_code',
    location: '4658',
    location_name: 'D\u00e4niken',
    latitude: 47.3489,
    longitude: 7.9811,
    id: 3390,
  },
  {
    location_type: 'zip_code',
    location: '5010',
    location_name: 'D\u00e4niken Postzentrum',
    latitude: 47.3778,
    longitude: 7.9889,
    id: 3391,
  },
  {
    location_type: 'zip_code',
    location: '5012',
    location_name: 'Eppenberg',
    latitude: 47.3734,
    longitude: 8.0236,
    id: 3392,
  },
  {
    location_type: 'zip_code',
    location: '5014',
    location_name: 'Gretzenbach',
    latitude: 47.3511,
    longitude: 7.9987,
    id: 3393,
  },
  {
    location_type: 'zip_code',
    location: '5746',
    location_name: 'Walterswil SO',
    latitude: 47.329,
    longitude: 7.9681,
    id: 3394,
  },
  {
    location_type: 'zip_code',
    location: '4500',
    location_name: 'Solothurn',
    latitude: 47.2081,
    longitude: 7.5283,
    id: 3395,
  },
  {
    location_type: 'zip_code',
    location: '4501',
    location_name: 'Solothurn',
    latitude: 47.2333,
    longitude: 7.5167,
    id: 3396,
  },
  {
    location_type: 'zip_code',
    location: '4502',
    location_name: 'Solothurn',
    latitude: 47.2333,
    longitude: 7.5167,
    id: 3397,
  },
  {
    location_type: 'zip_code',
    location: '4503',
    location_name: 'Solothurn',
    latitude: 47.2333,
    longitude: 7.5167,
    id: 3398,
  },
  {
    location_type: 'zip_code',
    location: '4509',
    location_name: 'Solothurn',
    latitude: 47.2333,
    longitude: 7.5167,
    id: 3399,
  },
  {
    location_type: 'zip_code',
    location: '4204',
    location_name: 'Himmelried',
    latitude: 47.4241,
    longitude: 7.5934,
    id: 3400,
  },
  {
    location_type: 'zip_code',
    location: '4208',
    location_name: 'Nunningen',
    latitude: 47.4007,
    longitude: 7.6132,
    id: 3401,
  },
  {
    location_type: 'zip_code',
    location: '4226',
    location_name: 'Breitenbach',
    latitude: 47.4045,
    longitude: 7.5505,
    id: 3402,
  },
  {
    location_type: 'zip_code',
    location: '4227',
    location_name: 'B\u00fcsserach',
    latitude: 47.3899,
    longitude: 7.5487,
    id: 3403,
  },
  {
    location_type: 'zip_code',
    location: '4228',
    location_name: 'Erschwil',
    latitude: 47.3704,
    longitude: 7.544,
    id: 3404,
  },
  {
    location_type: 'zip_code',
    location: '4229',
    location_name: 'Beinwil SO',
    latitude: 47.3589,
    longitude: 7.5982,
    id: 3405,
  },
  {
    location_type: 'zip_code',
    location: '4232',
    location_name: 'Fehren',
    latitude: 47.4029,
    longitude: 7.5788,
    id: 3406,
  },
  {
    location_type: 'zip_code',
    location: '4233',
    location_name: 'Meltingen',
    latitude: 47.3825,
    longitude: 7.582,
    id: 3407,
  },
  {
    location_type: 'zip_code',
    location: '4234',
    location_name: 'Zullwil',
    latitude: 47.3877,
    longitude: 7.6028,
    id: 3408,
  },
  {
    location_type: 'zip_code',
    location: '4245',
    location_name: 'Kleinl\u00fctzel',
    latitude: 47.4286,
    longitude: 7.4186,
    id: 3409,
  },
  {
    location_type: 'zip_code',
    location: '4247',
    location_name: 'Grindel',
    latitude: 47.3802,
    longitude: 7.5087,
    id: 3410,
  },
  {
    location_type: 'zip_code',
    location: '4252',
    location_name: 'B\u00e4rschwil',
    latitude: 47.3861,
    longitude: 7.4667,
    id: 3411,
  },
  {
    location_type: 'zip_code',
    location: '8836',
    location_name: 'Bennau',
    latitude: 47.152,
    longitude: 8.7073,
    id: 3412,
  },
  {
    location_type: 'zip_code',
    location: '8840',
    location_name: 'Trachslau',
    latitude: 47.1013,
    longitude: 8.7299,
    id: 3413,
  },
  {
    location_type: 'zip_code',
    location: '8841',
    location_name: 'Gross',
    latitude: 47.0998,
    longitude: 8.7656,
    id: 3414,
  },
  {
    location_type: 'zip_code',
    location: '8844',
    location_name: 'Euthal',
    latitude: 47.0932,
    longitude: 8.8242,
    id: 3415,
  },
  {
    location_type: 'zip_code',
    location: '8846',
    location_name: 'Willerzell',
    latitude: 47.1314,
    longitude: 8.812,
    id: 3416,
  },
  {
    location_type: 'zip_code',
    location: '8847',
    location_name: 'Egg',
    latitude: 47.1606,
    longitude: 8.7795,
    id: 3417,
  },
  {
    location_type: 'zip_code',
    location: '6410',
    location_name: 'Rigi-Scheidegg',
    latitude: 47.0253,
    longitude: 8.5227,
    id: 3418,
  },
  {
    location_type: 'zip_code',
    location: '6442',
    location_name: 'Gersau',
    latitude: 46.9977,
    longitude: 8.4985,
    id: 3419,
  },
  {
    location_type: 'zip_code',
    location: '8806',
    location_name: 'B\u00e4ch',
    latitude: 47.2053,
    longitude: 8.7333,
    id: 3420,
  },
  {
    location_type: 'zip_code',
    location: '8807',
    location_name: 'Freienbach',
    latitude: 47.2055,
    longitude: 8.756,
    id: 3421,
  },
  {
    location_type: 'zip_code',
    location: '8808',
    location_name: 'Pf\u00e4ffikon',
    latitude: 47.2026,
    longitude: 8.774,
    id: 3422,
  },
  {
    location_type: 'zip_code',
    location: '8832',
    location_name: 'Wilen',
    latitude: 47.195,
    longitude: 8.7347,
    id: 3423,
  },
  {
    location_type: 'zip_code',
    location: '8834',
    location_name: 'Schindellegi',
    latitude: 47.1747,
    longitude: 8.7152,
    id: 3424,
  },
  {
    location_type: 'zip_code',
    location: '8835',
    location_name: 'Feusisberg',
    latitude: 47.1794,
    longitude: 8.7583,
    id: 3425,
  },
  {
    location_type: 'zip_code',
    location: '6402',
    location_name: 'Merlischachen',
    latitude: 47.0707,
    longitude: 8.4063,
    id: 3426,
  },
  {
    location_type: 'zip_code',
    location: '6403',
    location_name: 'K\u00fcssnacht',
    latitude: 47.0867,
    longitude: 8.4437,
    id: 3427,
  },
  {
    location_type: 'zip_code',
    location: '6405',
    location_name: 'Immensee',
    latitude: 47.0913,
    longitude: 8.476,
    id: 3428,
  },
  {
    location_type: 'zip_code',
    location: '8852',
    location_name: 'Altendorf',
    latitude: 47.1783,
    longitude: 8.8256,
    id: 3429,
  },
  {
    location_type: 'zip_code',
    location: '8853',
    location_name: 'Lachen',
    latitude: 47.1972,
    longitude: 8.8561,
    id: 3430,
  },
  {
    location_type: 'zip_code',
    location: '8854',
    location_name: 'Galgenen',
    latitude: 47.17,
    longitude: 8.8687,
    id: 3431,
  },
  {
    location_type: 'zip_code',
    location: '8855',
    location_name: 'Wangen',
    latitude: 47.199,
    longitude: 8.8868,
    id: 3432,
  },
  {
    location_type: 'zip_code',
    location: '8856',
    location_name: 'Tuggen',
    latitude: 47.2005,
    longitude: 8.9394,
    id: 3433,
  },
  {
    location_type: 'zip_code',
    location: '8857',
    location_name: 'Vorderthal',
    latitude: 47.1256,
    longitude: 8.8947,
    id: 3434,
  },
  {
    location_type: 'zip_code',
    location: '8858',
    location_name: 'Innerthal',
    latitude: 47.0746,
    longitude: 8.9212,
    id: 3435,
  },
  {
    location_type: 'zip_code',
    location: '8862',
    location_name: 'Sch\u00fcbelbach',
    latitude: 47.1734,
    longitude: 8.9288,
    id: 3436,
  },
  {
    location_type: 'zip_code',
    location: '8863',
    location_name: 'Buttikon SZ',
    latitude: 47.1738,
    longitude: 8.9544,
    id: 3437,
  },
  {
    location_type: 'zip_code',
    location: '8864',
    location_name: 'Reichenburg',
    latitude: 47.1627,
    longitude: 8.9757,
    id: 3438,
  },
  {
    location_type: 'zip_code',
    location: '6318',
    location_name: 'Arth',
    latitude: 47.0899,
    longitude: 8.5231,
    id: 3439,
  },
  {
    location_type: 'zip_code',
    location: '6414',
    location_name: 'Oberarth',
    latitude: 47.0551,
    longitude: 8.5375,
    id: 3440,
  },
  {
    location_type: 'zip_code',
    location: '6415',
    location_name: 'Arth',
    latitude: 47.0738,
    longitude: 8.5243,
    id: 3441,
  },
  {
    location_type: 'zip_code',
    location: '6416',
    location_name: 'Steinerberg',
    latitude: 47.065,
    longitude: 8.5801,
    id: 3442,
  },
  {
    location_type: 'zip_code',
    location: '6417',
    location_name: 'Sattel',
    latitude: 47.0896,
    longitude: 8.621,
    id: 3443,
  },
  {
    location_type: 'zip_code',
    location: '6418',
    location_name: 'Rothenthurm',
    latitude: 47.1056,
    longitude: 8.6727,
    id: 3444,
  },
  {
    location_type: 'zip_code',
    location: '6422',
    location_name: 'Steinen',
    latitude: 47.0491,
    longitude: 8.61,
    id: 3445,
  },
  {
    location_type: 'zip_code',
    location: '6423',
    location_name: 'Ingenbohl',
    latitude: 47.0259,
    longitude: 8.6228,
    id: 3446,
  },
  {
    location_type: 'zip_code',
    location: '6424',
    location_name: 'Lauerz',
    latitude: 47.0265,
    longitude: 8.5789,
    id: 3447,
  },
  {
    location_type: 'zip_code',
    location: '6430',
    location_name: 'Schwyz',
    latitude: 47.0257,
    longitude: 8.6672,
    id: 3448,
  },
  {
    location_type: 'zip_code',
    location: '6431',
    location_name: 'Schwyz',
    latitude: 47.0167,
    longitude: 8.65,
    id: 3449,
  },
  {
    location_type: 'zip_code',
    location: '6432',
    location_name: 'Rickenbach',
    latitude: 47.0096,
    longitude: 8.6975,
    id: 3450,
  },
  {
    location_type: 'zip_code',
    location: '6433',
    location_name: 'Stoos',
    latitude: 46.9697,
    longitude: 8.662,
    id: 3451,
  },
  {
    location_type: 'zip_code',
    location: '6434',
    location_name: 'Illgau',
    latitude: 46.9974,
    longitude: 8.7357,
    id: 3452,
  },
  {
    location_type: 'zip_code',
    location: '6436',
    location_name: 'Ried',
    latitude: 46.9775,
    longitude: 8.7129,
    id: 3453,
  },
  {
    location_type: 'zip_code',
    location: '6438',
    location_name: 'Ibach',
    latitude: 47.0017,
    longitude: 8.648,
    id: 3454,
  },
  {
    location_type: 'zip_code',
    location: '6440',
    location_name: 'Ingenbohl',
    latitude: 47.0002,
    longitude: 8.5943,
    id: 3455,
  },
  {
    location_type: 'zip_code',
    location: '6443',
    location_name: 'Morschach',
    latitude: 46.9521,
    longitude: 8.6296,
    id: 3456,
  },
  {
    location_type: 'zip_code',
    location: '6452',
    location_name: 'Riemenstalden',
    latitude: 46.9352,
    longitude: 8.692,
    id: 3457,
  },
  {
    location_type: 'zip_code',
    location: '8842',
    location_name: 'Unteriberg',
    latitude: 47.0568,
    longitude: 8.7999,
    id: 3458,
  },
  {
    location_type: 'zip_code',
    location: '8843',
    location_name: 'Oberiberg',
    latitude: 47.0338,
    longitude: 8.7642,
    id: 3459,
  },
  {
    location_type: 'zip_code',
    location: '8845',
    location_name: 'Studen',
    latitude: 47.0366,
    longitude: 8.8562,
    id: 3460,
  },
  {
    location_type: 'zip_code',
    location: '8849',
    location_name: 'Alpthal',
    latitude: 47.0564,
    longitude: 8.7154,
    id: 3461,
  },
  {
    location_type: 'zip_code',
    location: '8580',
    location_name: 'Hefenhofen',
    latitude: 47.5596,
    longitude: 9.314,
    id: 3462,
  },
  {
    location_type: 'zip_code',
    location: '8582',
    location_name: 'Dozwil',
    latitude: 47.5756,
    longitude: 9.3215,
    id: 3463,
  },
  {
    location_type: 'zip_code',
    location: '8590',
    location_name: 'Romanshorn',
    latitude: 47.565,
    longitude: 9.3597,
    id: 3464,
  },
  {
    location_type: 'zip_code',
    location: '8592',
    location_name: 'Uttwil',
    latitude: 47.5778,
    longitude: 9.3405,
    id: 3465,
  },
  {
    location_type: 'zip_code',
    location: '8593',
    location_name: 'Kesswil',
    latitude: 47.5895,
    longitude: 9.3147,
    id: 3466,
  },
  {
    location_type: 'zip_code',
    location: '8599',
    location_name: 'Salmsach',
    latitude: 47.5511,
    longitude: 9.364,
    id: 3467,
  },
  {
    location_type: 'zip_code',
    location: '9306',
    location_name: 'Freidorf TG',
    latitude: 47.4849,
    longitude: 9.3876,
    id: 3468,
  },
  {
    location_type: 'zip_code',
    location: '9314',
    location_name: 'Steinebrunn',
    latitude: 47.533,
    longitude: 9.3409,
    id: 3469,
  },
  {
    location_type: 'zip_code',
    location: '9315',
    location_name: 'Winden',
    latitude: 47.51,
    longitude: 9.3628,
    id: 3470,
  },
  {
    location_type: 'zip_code',
    location: '9320',
    location_name: 'Arbon',
    latitude: 47.5117,
    longitude: 9.4258,
    id: 3471,
  },
  {
    location_type: 'zip_code',
    location: '9322',
    location_name: 'Egnach',
    latitude: 47.5401,
    longitude: 9.3824,
    id: 3472,
  },
  {
    location_type: 'zip_code',
    location: '9325',
    location_name: 'Roggwil',
    latitude: 47.51,
    longitude: 9.3922,
    id: 3473,
  },
  {
    location_type: 'zip_code',
    location: '9326',
    location_name: 'Horn',
    latitude: 47.4928,
    longitude: 9.4602,
    id: 3474,
  },
  {
    location_type: 'zip_code',
    location: '8581',
    location_name: 'Schocherswil',
    latitude: 47.5366,
    longitude: 9.2646,
    id: 3475,
  },
  {
    location_type: 'zip_code',
    location: '8583',
    location_name: 'Donzhausen',
    latitude: 47.555,
    longitude: 9.1977,
    id: 3476,
  },
  {
    location_type: 'zip_code',
    location: '8586',
    location_name: 'Ennetaach',
    latitude: 47.5527,
    longitude: 9.2137,
    id: 3477,
  },
  {
    location_type: 'zip_code',
    location: '8587',
    location_name: 'Oberaach',
    latitude: 47.5579,
    longitude: 9.2743,
    id: 3478,
  },
  {
    location_type: 'zip_code',
    location: '8588',
    location_name: 'Zihlschlacht',
    latitude: 47.5204,
    longitude: 9.2585,
    id: 3479,
  },
  {
    location_type: 'zip_code',
    location: '8589',
    location_name: 'Sitterdorf',
    latitude: 47.5058,
    longitude: 9.2468,
    id: 3480,
  },
  {
    location_type: 'zip_code',
    location: '9213',
    location_name: 'Hauptwil',
    latitude: 47.4837,
    longitude: 9.2564,
    id: 3481,
  },
  {
    location_type: 'zip_code',
    location: '9214',
    location_name: 'Kradolf',
    latitude: 47.526,
    longitude: 9.1956,
    id: 3482,
  },
  {
    location_type: 'zip_code',
    location: '9215',
    location_name: 'Sch\u00f6nenberg an der Thur',
    latitude: 47.5161,
    longitude: 9.199,
    id: 3483,
  },
  {
    location_type: 'zip_code',
    location: '9216',
    location_name: 'Heldswil',
    latitude: 47.5259,
    longitude: 9.2268,
    id: 3484,
  },
  {
    location_type: 'zip_code',
    location: '9217',
    location_name: 'Neukirch an der Thur',
    latitude: 47.5082,
    longitude: 9.1696,
    id: 3485,
  },
  {
    location_type: 'zip_code',
    location: '9220',
    location_name: 'Bischofszell',
    latitude: 47.4988,
    longitude: 9.2625,
    id: 3486,
  },
  {
    location_type: 'zip_code',
    location: '9223',
    location_name: 'Halden',
    latitude: 47.5045,
    longitude: 9.2069,
    id: 3487,
  },
  {
    location_type: 'zip_code',
    location: '9225',
    location_name: 'St. Pelagiberg',
    latitude: 47.4896,
    longitude: 9.3054,
    id: 3488,
  },
  {
    location_type: 'zip_code',
    location: '8252',
    location_name: 'Schlatt',
    latitude: 47.6694,
    longitude: 8.7003,
    id: 3489,
  },
  {
    location_type: 'zip_code',
    location: '8253',
    location_name: 'Willisdorf',
    latitude: 47.6783,
    longitude: 8.7407,
    id: 3490,
  },
  {
    location_type: 'zip_code',
    location: '8254',
    location_name: 'Basadingen',
    latitude: 47.6588,
    longitude: 8.7395,
    id: 3491,
  },
  {
    location_type: 'zip_code',
    location: '8255',
    location_name: 'Schlattingen',
    latitude: 47.6611,
    longitude: 8.7709,
    id: 3492,
  },
  {
    location_type: 'zip_code',
    location: '8355',
    location_name: 'Aadorf',
    latitude: 47.4917,
    longitude: 8.9019,
    id: 3493,
  },
  {
    location_type: 'zip_code',
    location: '8356',
    location_name: 'Ettenhausen',
    latitude: 47.4739,
    longitude: 8.8942,
    id: 3494,
  },
  {
    location_type: 'zip_code',
    location: '8357',
    location_name: 'Guntershausen b. Aadorf',
    latitude: 47.4713,
    longitude: 8.9188,
    id: 3495,
  },
  {
    location_type: 'zip_code',
    location: '8500',
    location_name: 'Frauenfeld',
    latitude: 47.5603,
    longitude: 8.9036,
    id: 3496,
  },
  {
    location_type: 'zip_code',
    location: '8501',
    location_name: 'Frauenfeld',
    latitude: 47.55,
    longitude: 8.9,
    id: 3497,
  },
  {
    location_type: 'zip_code',
    location: '8502',
    location_name: 'Frauenfeld',
    latitude: 47.55,
    longitude: 8.9,
    id: 3498,
  },
  {
    location_type: 'zip_code',
    location: '8503',
    location_name: 'Frauenfeld',
    latitude: 47.55,
    longitude: 8.9,
    id: 3499,
  },
  {
    location_type: 'zip_code',
    location: '8510',
    location_name: 'Frauenfeld Kant. Verwaltung',
    latitude: 47.5513,
    longitude: 9.0124,
    id: 3500,
  },
  {
    location_type: 'zip_code',
    location: '8512',
    location_name: 'Lustdorf',
    latitude: 47.5516,
    longitude: 8.9853,
    id: 3501,
  },
  {
    location_type: 'zip_code',
    location: '8520',
    location_name: 'Frauenfeld Dist Ba',
    latitude: 47.5833,
    longitude: 8.8167,
    id: 3502,
  },
  {
    location_type: 'zip_code',
    location: '8522',
    location_name: 'Aawangen',
    latitude: 47.4931,
    longitude: 8.9101,
    id: 3503,
  },
  {
    location_type: 'zip_code',
    location: '8524',
    location_name: 'Buch b. Frauenfeld',
    latitude: 47.5987,
    longitude: 8.838,
    id: 3504,
  },
  {
    location_type: 'zip_code',
    location: '8525',
    location_name: 'Wilen b. Neunforn',
    latitude: 47.603,
    longitude: 8.7937,
    id: 3505,
  },
  {
    location_type: 'zip_code',
    location: '8526',
    location_name: 'Oberneunforn',
    latitude: 47.6105,
    longitude: 8.7625,
    id: 3506,
  },
  {
    location_type: 'zip_code',
    location: '8530',
    location_name: 'Frauenfeld CALL',
    latitude: 47.6,
    longitude: 8.8833,
    id: 3507,
  },
  {
    location_type: 'zip_code',
    location: '8532',
    location_name: 'Weiningen',
    latitude: 47.59,
    longitude: 8.8989,
    id: 3508,
  },
  {
    location_type: 'zip_code',
    location: '8540',
    location_name: 'Frauenfeld ST PP 1',
    latitude: 47.5433,
    longitude: 8.8233,
    id: 3509,
  },
  {
    location_type: 'zip_code',
    location: '8546',
    location_name: 'Kefikon TG',
    latitude: 47.5519,
    longitude: 8.8339,
    id: 3510,
  },
  {
    location_type: 'zip_code',
    location: '8547',
    location_name: 'Gachnang',
    latitude: 47.5391,
    longitude: 8.8546,
    id: 3511,
  },
  {
    location_type: 'zip_code',
    location: '8552',
    location_name: 'Felben-Wellhausen',
    latitude: 47.5764,
    longitude: 8.9377,
    id: 3512,
  },
  {
    location_type: 'zip_code',
    location: '8553',
    location_name: 'Mettendorf',
    latitude: 47.5785,
    longitude: 8.9646,
    id: 3513,
  },
  {
    location_type: 'zip_code',
    location: '9507',
    location_name: 'Stettfurt',
    latitude: 47.525,
    longitude: 8.9606,
    id: 3514,
  },
  {
    location_type: 'zip_code',
    location: '9547',
    location_name: 'Wittenwil',
    latitude: 47.5019,
    longitude: 8.9261,
    id: 3515,
  },
  {
    location_type: 'zip_code',
    location: '9548',
    location_name: 'Matzingen',
    latitude: 47.5246,
    longitude: 8.9286,
    id: 3516,
  },
  {
    location_type: 'zip_code',
    location: '8272',
    location_name: 'Ermatingen',
    latitude: 47.6572,
    longitude: 9.0848,
    id: 3517,
  },
  {
    location_type: 'zip_code',
    location: '8273',
    location_name: 'Triboltingen',
    latitude: 47.6552,
    longitude: 9.1092,
    id: 3518,
  },
  {
    location_type: 'zip_code',
    location: '8274',
    location_name: 'Gottlieben',
    latitude: 47.6645,
    longitude: 9.1262,
    id: 3519,
  },
  {
    location_type: 'zip_code',
    location: '8280',
    location_name: 'Kreuzlingen',
    latitude: 47.6405,
    longitude: 9.1755,
    id: 3520,
  },
  {
    location_type: 'zip_code',
    location: '8285',
    location_name: 'Kreuzlingen Ifolor AG',
    latitude: 47.65,
    longitude: 9.1833,
    id: 3521,
  },
  {
    location_type: 'zip_code',
    location: '8564',
    location_name: 'W\u00e4ldi',
    latitude: 47.6325,
    longitude: 9.0963,
    id: 3522,
  },
  {
    location_type: 'zip_code',
    location: '8565',
    location_name: 'Hugelshofen',
    latitude: 47.5948,
    longitude: 9.1158,
    id: 3523,
  },
  {
    location_type: 'zip_code',
    location: '8566',
    location_name: 'Dotnacht',
    latitude: 47.598,
    longitude: 9.1418,
    id: 3524,
  },
  {
    location_type: 'zip_code',
    location: '8573',
    location_name: 'Siegershausen',
    latitude: 47.6189,
    longitude: 9.1704,
    id: 3525,
  },
  {
    location_type: 'zip_code',
    location: '8574',
    location_name: 'Lengwil-Oberhofen',
    latitude: 47.617,
    longitude: 9.1956,
    id: 3526,
  },
  {
    location_type: 'zip_code',
    location: '8585',
    location_name: 'Sch\u00f6nenbaumgarten',
    latitude: 47.6123,
    longitude: 9.2262,
    id: 3527,
  },
  {
    location_type: 'zip_code',
    location: '8594',
    location_name: 'G\u00fcttingen',
    latitude: 47.5983,
    longitude: 9.2869,
    id: 3528,
  },
  {
    location_type: 'zip_code',
    location: '8595',
    location_name: 'Altnau',
    latitude: 47.6029,
    longitude: 9.2595,
    id: 3529,
  },
  {
    location_type: 'zip_code',
    location: '8596',
    location_name: 'M\u00fcnsterlingen',
    latitude: 47.6333,
    longitude: 9.2333,
    id: 3530,
  },
  {
    location_type: 'zip_code',
    location: '8597',
    location_name: 'Landschlacht',
    latitude: 47.624,
    longitude: 9.2514,
    id: 3531,
  },
  {
    location_type: 'zip_code',
    location: '8598',
    location_name: 'Bottighofen',
    latitude: 47.6345,
    longitude: 9.2093,
    id: 3532,
  },
  {
    location_type: 'zip_code',
    location: '8360',
    location_name: 'Wallenwil',
    latitude: 47.453,
    longitude: 8.9537,
    id: 3533,
  },
  {
    location_type: 'zip_code',
    location: '8362',
    location_name: 'Balterswil',
    latitude: 47.4521,
    longitude: 8.9382,
    id: 3534,
  },
  {
    location_type: 'zip_code',
    location: '8363',
    location_name: 'Bichelsee',
    latitude: 47.4341,
    longitude: 8.9233,
    id: 3535,
  },
  {
    location_type: 'zip_code',
    location: '8370',
    location_name: 'Busswil',
    latitude: 47.4528,
    longitude: 9.0158,
    id: 3536,
  },
  {
    location_type: 'zip_code',
    location: '8372',
    location_name: 'Wiezikon b. Sirnach',
    latitude: 47.4459,
    longitude: 8.9883,
    id: 3537,
  },
  {
    location_type: 'zip_code',
    location: '8374',
    location_name: 'Dussnang',
    latitude: 47.4234,
    longitude: 8.9446,
    id: 3538,
  },
  {
    location_type: 'zip_code',
    location: '8376',
    location_name: 'Fischingen',
    latitude: 47.4099,
    longitude: 8.9713,
    id: 3539,
  },
  {
    location_type: 'zip_code',
    location: '8577',
    location_name: 'Toos',
    latitude: 47.5167,
    longitude: 9.1333,
    id: 3540,
  },
  {
    location_type: 'zip_code',
    location: '9502',
    location_name: 'Braunau',
    latitude: 47.504,
    longitude: 9.0695,
    id: 3541,
  },
  {
    location_type: 'zip_code',
    location: '9506',
    location_name: 'Lommis',
    latitude: 47.5149,
    longitude: 8.9984,
    id: 3542,
  },
  {
    location_type: 'zip_code',
    location: '9508',
    location_name: 'Weingarten-Kalth\u00e4usern',
    latitude: 47.5236,
    longitude: 8.9828,
    id: 3543,
  },
  {
    location_type: 'zip_code',
    location: '9514',
    location_name: 'Wuppenau',
    latitude: 47.4994,
    longitude: 9.109,
    id: 3544,
  },
  {
    location_type: 'zip_code',
    location: '9515',
    location_name: 'Hosenruck',
    latitude: 47.4923,
    longitude: 9.1378,
    id: 3545,
  },
  {
    location_type: 'zip_code',
    location: '9532',
    location_name: 'Rickenbach b. Wil',
    latitude: 47.4432,
    longitude: 9.0531,
    id: 3546,
  },
  {
    location_type: 'zip_code',
    location: '9535',
    location_name: 'Wilen b. Wil',
    latitude: 47.4471,
    longitude: 9.032,
    id: 3547,
  },
  {
    location_type: 'zip_code',
    location: '9542',
    location_name: 'M\u00fcnchwilen',
    latitude: 47.4805,
    longitude: 8.9941,
    id: 3548,
  },
  {
    location_type: 'zip_code',
    location: '9543',
    location_name: 'St. Margarethen TG',
    latitude: 47.4904,
    longitude: 9.0018,
    id: 3549,
  },
  {
    location_type: 'zip_code',
    location: '9545',
    location_name: 'W\u00e4ngi',
    latitude: 47.4977,
    longitude: 8.9674,
    id: 3550,
  },
  {
    location_type: 'zip_code',
    location: '9546',
    location_name: 'Tuttwil',
    latitude: 47.4795,
    longitude: 8.9488,
    id: 3551,
  },
  {
    location_type: 'zip_code',
    location: '9553',
    location_name: 'Bettwiesen',
    latitude: 47.4979,
    longitude: 9.0244,
    id: 3552,
  },
  {
    location_type: 'zip_code',
    location: '9554',
    location_name: 'T\u00e4gerschen',
    latitude: 47.5045,
    longitude: 9.0258,
    id: 3553,
  },
  {
    location_type: 'zip_code',
    location: '9555',
    location_name: 'Tobel',
    latitude: 47.5168,
    longitude: 9.0447,
    id: 3554,
  },
  {
    location_type: 'zip_code',
    location: '9556',
    location_name: 'Zezikon',
    latitude: 47.5344,
    longitude: 9.0171,
    id: 3555,
  },
  {
    location_type: 'zip_code',
    location: '9562',
    location_name: 'M\u00e4rwil',
    latitude: 47.529,
    longitude: 9.0672,
    id: 3556,
  },
  {
    location_type: 'zip_code',
    location: '9573',
    location_name: 'Littenheid',
    latitude: 47.4416,
    longitude: 9.0079,
    id: 3557,
  },
  {
    location_type: 'zip_code',
    location: '8259',
    location_name: 'Rheinklingen',
    latitude: 47.6702,
    longitude: 8.8138,
    id: 3558,
  },
  {
    location_type: 'zip_code',
    location: '8264',
    location_name: 'Eschenz',
    latitude: 47.6456,
    longitude: 8.8748,
    id: 3559,
  },
  {
    location_type: 'zip_code',
    location: '8265',
    location_name: 'Mammern',
    latitude: 47.6365,
    longitude: 8.9103,
    id: 3560,
  },
  {
    location_type: 'zip_code',
    location: '8266',
    location_name: 'Steckborn',
    latitude: 47.6587,
    longitude: 8.9993,
    id: 3561,
  },
  {
    location_type: 'zip_code',
    location: '8267',
    location_name: 'Berlingen',
    latitude: 47.665,
    longitude: 9.0295,
    id: 3562,
  },
  {
    location_type: 'zip_code',
    location: '8268',
    location_name: 'Salenstein',
    latitude: 47.6595,
    longitude: 9.0497,
    id: 3563,
  },
  {
    location_type: 'zip_code',
    location: '8269',
    location_name: 'Fruthwilen',
    latitude: 47.6565,
    longitude: 9.0643,
    id: 3564,
  },
  {
    location_type: 'zip_code',
    location: '8505',
    location_name: 'Dettighofen',
    latitude: 47.6191,
    longitude: 8.9543,
    id: 3565,
  },
  {
    location_type: 'zip_code',
    location: '8506',
    location_name: 'Lanzenneunforn',
    latitude: 47.6221,
    longitude: 8.9317,
    id: 3566,
  },
  {
    location_type: 'zip_code',
    location: '8507',
    location_name: 'H\u00f6rhausen',
    latitude: 47.6386,
    longitude: 8.9514,
    id: 3567,
  },
  {
    location_type: 'zip_code',
    location: '8508',
    location_name: 'Homburg',
    latitude: 47.6292,
    longitude: 9.0016,
    id: 3568,
  },
  {
    location_type: 'zip_code',
    location: '8535',
    location_name: 'Herdern',
    latitude: 47.612,
    longitude: 8.9071,
    id: 3569,
  },
  {
    location_type: 'zip_code',
    location: '8536',
    location_name: 'H\u00fcttwilen',
    latitude: 47.619,
    longitude: 8.8819,
    id: 3570,
  },
  {
    location_type: 'zip_code',
    location: '8537',
    location_name: 'Nussbaumen',
    latitude: 47.6245,
    longitude: 8.8301,
    id: 3571,
  },
  {
    location_type: 'zip_code',
    location: '8555',
    location_name: 'M\u00fcllheim Dorf',
    latitude: 47.6012,
    longitude: 9.0054,
    id: 3572,
  },
  {
    location_type: 'zip_code',
    location: '8558',
    location_name: 'Raperswilen',
    latitude: 47.6383,
    longitude: 9.0469,
    id: 3573,
  },
  {
    location_type: 'zip_code',
    location: '8514',
    location_name: 'Amlikon-Bissegg',
    latitude: 47.5567,
    longitude: 9.0336,
    id: 3574,
  },
  {
    location_type: 'zip_code',
    location: '8554',
    location_name: 'Bonau',
    latitude: 47.583,
    longitude: 9.0339,
    id: 3575,
  },
  {
    location_type: 'zip_code',
    location: '8556',
    location_name: 'Illhart',
    latitude: 47.618,
    longitude: 9.0363,
    id: 3576,
  },
  {
    location_type: 'zip_code',
    location: '8560',
    location_name: 'M\u00e4rstetten',
    latitude: 47.5932,
    longitude: 9.0746,
    id: 3577,
  },
  {
    location_type: 'zip_code',
    location: '8561',
    location_name: 'Ottoberg',
    latitude: 47.5933,
    longitude: 9.0902,
    id: 3578,
  },
  {
    location_type: 'zip_code',
    location: '8570',
    location_name: 'Weinfelden',
    latitude: 47.5686,
    longitude: 9.1074,
    id: 3579,
  },
  {
    location_type: 'zip_code',
    location: '8572',
    location_name: 'Graltshausen',
    latitude: 47.5971,
    longitude: 9.1835,
    id: 3580,
  },
  {
    location_type: 'zip_code',
    location: '8575',
    location_name: 'Istighofen',
    latitude: 47.539,
    longitude: 9.1455,
    id: 3581,
  },
  {
    location_type: 'zip_code',
    location: '8576',
    location_name: 'Mauren',
    latitude: 47.5655,
    longitude: 9.1531,
    id: 3582,
  },
  {
    location_type: 'zip_code',
    location: '8584',
    location_name: 'Opfershofen',
    latitude: 47.5615,
    longitude: 9.1733,
    id: 3583,
  },
  {
    location_type: 'zip_code',
    location: '9503',
    location_name: 'Stehrenberg',
    latitude: 47.5229,
    longitude: 9.0911,
    id: 3584,
  },
  {
    location_type: 'zip_code',
    location: '9504',
    location_name: 'Friltschen',
    latitude: 47.5347,
    longitude: 9.0888,
    id: 3585,
  },
  {
    location_type: 'zip_code',
    location: '9517',
    location_name: 'Mettlen',
    latitude: 47.5362,
    longitude: 9.1207,
    id: 3586,
  },
  {
    location_type: 'zip_code',
    location: '9565',
    location_name: 'Oberbussnang',
    latitude: 47.5445,
    longitude: 9.0924,
    id: 3587,
  },
  {
    location_type: 'zip_code',
    location: '6500',
    location_name: 'Bellinzona',
    latitude: 46.1889,
    longitude: 9.0247,
    id: 3588,
  },
  {
    location_type: 'zip_code',
    location: '6501',
    location_name: 'Bellinzona',
    latitude: 46.2132,
    longitude: 9.0333,
    id: 3589,
  },
  {
    location_type: 'zip_code',
    location: '6503',
    location_name: 'Carasso',
    latitude: 46.2086,
    longitude: 9.0071,
    id: 3590,
  },
  {
    location_type: 'zip_code',
    location: '6506',
    location_name: 'Bellinzona 6 Autopostale TI',
    latitude: 46.2,
    longitude: 9.0167,
    id: 3591,
  },
  {
    location_type: 'zip_code',
    location: '6511',
    location_name: 'Cadenazzo Scanning-Center',
    latitude: 46.1466,
    longitude: 8.95,
    id: 3592,
  },
  {
    location_type: 'zip_code',
    location: '6512',
    location_name: 'Giubiasco',
    latitude: 46.17,
    longitude: 8.9974,
    id: 3593,
  },
  {
    location_type: 'zip_code',
    location: '6513',
    location_name: 'Monte Carasso',
    latitude: 46.2045,
    longitude: 8.9775,
    id: 3594,
  },
  {
    location_type: 'zip_code',
    location: '6514',
    location_name: 'Sementina',
    latitude: 46.1857,
    longitude: 8.9695,
    id: 3595,
  },
  {
    location_type: 'zip_code',
    location: '6515',
    location_name: 'Gudo',
    latitude: 46.1744,
    longitude: 8.9479,
    id: 3596,
  },
  {
    location_type: 'zip_code',
    location: '6517',
    location_name: 'Arbedo',
    latitude: 46.2002,
    longitude: 9.0889,
    id: 3597,
  },
  {
    location_type: 'zip_code',
    location: '6518',
    location_name: 'Gorduno',
    latitude: 46.2278,
    longitude: 8.9855,
    id: 3598,
  },
  {
    location_type: 'zip_code',
    location: '6523',
    location_name: 'Preonzo',
    latitude: 46.2497,
    longitude: 8.9763,
    id: 3599,
  },
  {
    location_type: 'zip_code',
    location: '6524',
    location_name: 'Moleno',
    latitude: 46.2698,
    longitude: 8.9819,
    id: 3600,
  },
  {
    location_type: 'zip_code',
    location: '6525',
    location_name: 'Gnosca',
    latitude: 46.2385,
    longitude: 8.9994,
    id: 3601,
  },
  {
    location_type: 'zip_code',
    location: '6528',
    location_name: 'Camorino',
    latitude: 46.1566,
    longitude: 9.0025,
    id: 3602,
  },
  {
    location_type: 'zip_code',
    location: '6532',
    location_name: 'Castione',
    latitude: 46.2303,
    longitude: 9.0436,
    id: 3603,
  },
  {
    location_type: 'zip_code',
    location: '6533',
    location_name: 'Lumino',
    latitude: 46.239,
    longitude: 9.0727,
    id: 3604,
  },
  {
    location_type: 'zip_code',
    location: '6582',
    location_name: 'Pianezzo',
    latitude: 46.1695,
    longitude: 9.048,
    id: 3605,
  },
  {
    location_type: 'zip_code',
    location: '6583',
    location_name: 'Sant\'Antonio',
    latitude: 46.1548,
    longitude: 9.101,
    id: 3606,
  },
  {
    location_type: 'zip_code',
    location: '6584',
    location_name: 'Carena',
    latitude: 46.1674,
    longitude: 9.0868,
    id: 3607,
  },
  {
    location_type: 'zip_code',
    location: '6590',
    location_name: 'Cadenazzo Centro logistico',
    latitude: 46.1466,
    longitude: 8.95,
    id: 3608,
  },
  {
    location_type: 'zip_code',
    location: '6592',
    location_name: 'Sant\'Antonino',
    latitude: 46.1605,
    longitude: 8.9682,
    id: 3609,
  },
  {
    location_type: 'zip_code',
    location: '6593',
    location_name: 'Cadenazzo',
    latitude: 46.1552,
    longitude: 8.9536,
    id: 3610,
  },
  {
    location_type: 'zip_code',
    location: '6599',
    location_name: 'Robasacco',
    latitude: 46.1423,
    longitude: 8.9479,
    id: 3611,
  },
  {
    location_type: 'zip_code',
    location: '6810',
    location_name: 'Isone',
    latitude: 46.1312,
    longitude: 9.0158,
    id: 3612,
  },
  {
    location_type: 'zip_code',
    location: '6713',
    location_name: 'Malvaglia',
    latitude: 46.4298,
    longitude: 9.0085,
    id: 3613,
  },
  {
    location_type: 'zip_code',
    location: '6714',
    location_name: 'Semione',
    latitude: 46.3971,
    longitude: 8.9497,
    id: 3614,
  },
  {
    location_type: 'zip_code',
    location: '6715',
    location_name: 'Dongio',
    latitude: 46.4478,
    longitude: 8.9729,
    id: 3615,
  },
  {
    location_type: 'zip_code',
    location: '6716',
    location_name: 'Comprovasco',
    latitude: 46.4571,
    longitude: 8.9373,
    id: 3616,
  },
  {
    location_type: 'zip_code',
    location: '6717',
    location_name: 'Torre',
    latitude: 46.4833,
    longitude: 8.9769,
    id: 3617,
  },
  {
    location_type: 'zip_code',
    location: '6718',
    location_name: 'Camperio',
    latitude: 46.5165,
    longitude: 8.9104,
    id: 3618,
  },
  {
    location_type: 'zip_code',
    location: '6719',
    location_name: 'Aquila',
    latitude: 46.5682,
    longitude: 8.9726,
    id: 3619,
  },
  {
    location_type: 'zip_code',
    location: '6720',
    location_name: 'Campo Blenio',
    latitude: 46.5627,
    longitude: 8.8868,
    id: 3620,
  },
  {
    location_type: 'zip_code',
    location: '6721',
    location_name: 'Ludiano',
    latitude: 46.421,
    longitude: 8.952,
    id: 3621,
  },
  {
    location_type: 'zip_code',
    location: '6722',
    location_name: 'Corzoneso',
    latitude: 46.4352,
    longitude: 8.9247,
    id: 3622,
  },
  {
    location_type: 'zip_code',
    location: '6723',
    location_name: 'Castro',
    latitude: 46.4768,
    longitude: 8.8799,
    id: 3623,
  },
  {
    location_type: 'zip_code',
    location: '6724',
    location_name: 'Ponto Valentino',
    latitude: 46.4853,
    longitude: 8.8967,
    id: 3624,
  },
  {
    location_type: 'zip_code',
    location: '6742',
    location_name: 'Pollegio',
    latitude: 46.3665,
    longitude: 8.9524,
    id: 3625,
  },
  {
    location_type: 'zip_code',
    location: '6743',
    location_name: 'Bodio',
    latitude: 46.3835,
    longitude: 8.907,
    id: 3626,
  },
  {
    location_type: 'zip_code',
    location: '6744',
    location_name: 'Personico',
    latitude: 46.3424,
    longitude: 8.898,
    id: 3627,
  },
  {
    location_type: 'zip_code',
    location: '6745',
    location_name: 'Giornico',
    latitude: 46.394,
    longitude: 8.8656,
    id: 3628,
  },
  {
    location_type: 'zip_code',
    location: '6746',
    location_name: 'Lavorgo',
    latitude: 46.4409,
    longitude: 8.8429,
    id: 3629,
  },
  {
    location_type: 'zip_code',
    location: '6747',
    location_name: 'Chironico',
    latitude: 46.4222,
    longitude: 8.7974,
    id: 3630,
  },
  {
    location_type: 'zip_code',
    location: '6748',
    location_name: 'Anzonico',
    latitude: 46.4421,
    longitude: 8.87,
    id: 3631,
  },
  {
    location_type: 'zip_code',
    location: '6749',
    location_name: 'Cavagnago',
    latitude: 46.4194,
    longitude: 8.8892,
    id: 3632,
  },
  {
    location_type: 'zip_code',
    location: '6760',
    location_name: 'Campello',
    latitude: 46.4858,
    longitude: 8.8173,
    id: 3633,
  },
  {
    location_type: 'zip_code',
    location: '6763',
    location_name: 'Osco',
    latitude: 46.505,
    longitude: 8.7692,
    id: 3634,
  },
  {
    location_type: 'zip_code',
    location: '6764',
    location_name: 'Chiggiogna',
    latitude: 46.454,
    longitude: 8.8284,
    id: 3635,
  },
  {
    location_type: 'zip_code',
    location: '6772',
    location_name: 'Rodi-Fiesso',
    latitude: 46.4791,
    longitude: 8.7197,
    id: 3636,
  },
  {
    location_type: 'zip_code',
    location: '6773',
    location_name: 'Prato Leventina',
    latitude: 46.4734,
    longitude: 8.7477,
    id: 3637,
  },
  {
    location_type: 'zip_code',
    location: '6774',
    location_name: 'Dalpe',
    latitude: 46.4588,
    longitude: 8.7607,
    id: 3638,
  },
  {
    location_type: 'zip_code',
    location: '6775',
    location_name: 'Ambr\u00ec',
    latitude: 46.4958,
    longitude: 8.6916,
    id: 3639,
  },
  {
    location_type: 'zip_code',
    location: '6776',
    location_name: 'Piotta',
    latitude: 46.5494,
    longitude: 8.7098,
    id: 3640,
  },
  {
    location_type: 'zip_code',
    location: '6777',
    location_name: 'Varenzo',
    latitude: 46.5002,
    longitude: 8.7204,
    id: 3641,
  },
  {
    location_type: 'zip_code',
    location: '6780',
    location_name: 'Madrano',
    latitude: 46.5275,
    longitude: 8.6313,
    id: 3642,
  },
  {
    location_type: 'zip_code',
    location: '6781',
    location_name: 'Bedretto',
    latitude: 46.4944,
    longitude: 8.4925,
    id: 3643,
  },
  {
    location_type: 'zip_code',
    location: '6516',
    location_name: 'Cugnasco',
    latitude: 46.1979,
    longitude: 8.9062,
    id: 3644,
  },
  {
    location_type: 'zip_code',
    location: '6574',
    location_name: 'Vira Gambarogno',
    latitude: 46.1252,
    longitude: 8.8624,
    id: 3645,
  },
  {
    location_type: 'zip_code',
    location: '6575',
    location_name: 'San Nazzaro',
    latitude: 46.1326,
    longitude: 8.8048,
    id: 3646,
  },
  {
    location_type: 'zip_code',
    location: '6576',
    location_name: 'Gerra Gambarogno',
    latitude: 46.1193,
    longitude: 8.7963,
    id: 3647,
  },
  {
    location_type: 'zip_code',
    location: '6577',
    location_name: 'Sant\'Abbondio',
    latitude: 46.1073,
    longitude: 8.7921,
    id: 3648,
  },
  {
    location_type: 'zip_code',
    location: '6595',
    location_name: 'Riazzino',
    latitude: 46.177,
    longitude: 8.8914,
    id: 3649,
  },
  {
    location_type: 'zip_code',
    location: '6596',
    location_name: 'Gordola',
    latitude: 46.1779,
    longitude: 8.874,
    id: 3650,
  },
  {
    location_type: 'zip_code',
    location: '6597',
    location_name: 'Medoscio',
    latitude: 46.1819,
    longitude: 8.9056,
    id: 3651,
  },
  {
    location_type: 'zip_code',
    location: '6598',
    location_name: 'Tenero',
    latitude: 46.1761,
    longitude: 8.8472,
    id: 3652,
  },
  {
    location_type: 'zip_code',
    location: '6600',
    location_name: 'Solduno',
    latitude: 46.1747,
    longitude: 8.7716,
    id: 3653,
  },
  {
    location_type: 'zip_code',
    location: '6601',
    location_name: 'Locarno',
    latitude: 46.1691,
    longitude: 8.7955,
    id: 3654,
  },
  {
    location_type: 'zip_code',
    location: '6602',
    location_name: 'Muralto',
    latitude: 46.1667,
    longitude: 8.7967,
    id: 3655,
  },
  {
    location_type: 'zip_code',
    location: '6604',
    location_name: 'Locarno',
    latitude: 46.1667,
    longitude: 8.8,
    id: 3656,
  },
  {
    location_type: 'zip_code',
    location: '6605',
    location_name: 'Monte Br\u00e8 sopra Locarno',
    latitude: 46.1667,
    longitude: 8.8,
    id: 3657,
  },
  {
    location_type: 'zip_code',
    location: '6611',
    location_name: 'Mosogno',
    latitude: 46.1983,
    longitude: 8.6347,
    id: 3658,
  },
  {
    location_type: 'zip_code',
    location: '6612',
    location_name: 'Ascona',
    latitude: 46.1565,
    longitude: 8.7763,
    id: 3659,
  },
  {
    location_type: 'zip_code',
    location: '6613',
    location_name: 'Porto Ronco',
    latitude: 46.1417,
    longitude: 8.727,
    id: 3660,
  },
  {
    location_type: 'zip_code',
    location: '6614',
    location_name: 'Isole di Brissago',
    latitude: 46.1335,
    longitude: 8.7356,
    id: 3661,
  },
  {
    location_type: 'zip_code',
    location: '6616',
    location_name: 'Losone',
    latitude: 46.169,
    longitude: 8.7455,
    id: 3662,
  },
  {
    location_type: 'zip_code',
    location: '6618',
    location_name: 'Arcegno',
    latitude: 46.1584,
    longitude: 8.7427,
    id: 3663,
  },
  {
    location_type: 'zip_code',
    location: '6622',
    location_name: 'Ronco sopra Ascona',
    latitude: 46.1459,
    longitude: 8.7136,
    id: 3664,
  },
  {
    location_type: 'zip_code',
    location: '6631',
    location_name: 'Corippo',
    latitude: 46.2352,
    longitude: 8.8201,
    id: 3665,
  },
  {
    location_type: 'zip_code',
    location: '6632',
    location_name: 'Vogorno',
    latitude: 46.2221,
    longitude: 8.8903,
    id: 3666,
  },
  {
    location_type: 'zip_code',
    location: '6633',
    location_name: 'Lavertezzo',
    latitude: 46.2839,
    longitude: 8.8591,
    id: 3667,
  },
  {
    location_type: 'zip_code',
    location: '6634',
    location_name: 'Brione (Verzasca)',
    latitude: 46.2991,
    longitude: 8.7712,
    id: 3668,
  },
  {
    location_type: 'zip_code',
    location: '6635',
    location_name: 'Gerra (Verzasca)',
    latitude: 46.3135,
    longitude: 8.8065,
    id: 3669,
  },
  {
    location_type: 'zip_code',
    location: '6636',
    location_name: 'Frasco',
    latitude: 46.3567,
    longitude: 8.82,
    id: 3670,
  },
  {
    location_type: 'zip_code',
    location: '6637',
    location_name: 'Sonogno',
    latitude: 46.3853,
    longitude: 8.7491,
    id: 3671,
  },
  {
    location_type: 'zip_code',
    location: '6644',
    location_name: 'Orselina',
    latitude: 46.1799,
    longitude: 8.7928,
    id: 3672,
  },
  {
    location_type: 'zip_code',
    location: '6645',
    location_name: 'Brione sopra Minusio',
    latitude: 46.2018,
    longitude: 8.8142,
    id: 3673,
  },
  {
    location_type: 'zip_code',
    location: '6646',
    location_name: 'Contra',
    latitude: 46.1879,
    longitude: 8.8376,
    id: 3674,
  },
  {
    location_type: 'zip_code',
    location: '6647',
    location_name: 'Mergoscia',
    latitude: 46.2135,
    longitude: 8.825,
    id: 3675,
  },
  {
    location_type: 'zip_code',
    location: '6648',
    location_name: 'Minusio',
    latitude: 46.1866,
    longitude: 8.8106,
    id: 3676,
  },
  {
    location_type: 'zip_code',
    location: '6652',
    location_name: 'Ponte Brolla',
    latitude: 46.1853,
    longitude: 8.7551,
    id: 3677,
  },
  {
    location_type: 'zip_code',
    location: '6653',
    location_name: 'Verscio',
    latitude: 46.1963,
    longitude: 8.7247,
    id: 3678,
  },
  {
    location_type: 'zip_code',
    location: '6654',
    location_name: 'Cavigliano',
    latitude: 46.1927,
    longitude: 8.7092,
    id: 3679,
  },
  {
    location_type: 'zip_code',
    location: '6659',
    location_name: 'Monedo',
    latitude: 46.1383,
    longitude: 8.6206,
    id: 3680,
  },
  {
    location_type: 'zip_code',
    location: '6661',
    location_name: 'Berzona',
    latitude: 46.2063,
    longitude: 8.6571,
    id: 3681,
  },
  {
    location_type: 'zip_code',
    location: '6662',
    location_name: 'Russo',
    latitude: 46.217,
    longitude: 8.6257,
    id: 3682,
  },
  {
    location_type: 'zip_code',
    location: '6663',
    location_name: 'Spruga',
    latitude: 46.2058,
    longitude: 8.5549,
    id: 3683,
  },
  {
    location_type: 'zip_code',
    location: '6664',
    location_name: 'Vergeletto',
    latitude: 46.2353,
    longitude: 8.5508,
    id: 3684,
  },
  {
    location_type: 'zip_code',
    location: '6805',
    location_name: 'Mezzovico-Vira',
    latitude: 46.0916,
    longitude: 8.8935,
    id: 3685,
  },
  {
    location_type: 'zip_code',
    location: '6807',
    location_name: 'Taverne',
    latitude: 46.0655,
    longitude: 8.9323,
    id: 3686,
  },
  {
    location_type: 'zip_code',
    location: '6808',
    location_name: 'Torricella',
    latitude: 46.0698,
    longitude: 8.9137,
    id: 3687,
  },
  {
    location_type: 'zip_code',
    location: '6814',
    location_name: 'Lamone',
    latitude: 46.0475,
    longitude: 8.9326,
    id: 3688,
  },
  {
    location_type: 'zip_code',
    location: '6815',
    location_name: 'Melide',
    latitude: 45.9571,
    longitude: 8.9521,
    id: 3689,
  },
  {
    location_type: 'zip_code',
    location: '6816',
    location_name: 'Bissone',
    latitude: 45.9524,
    longitude: 8.9661,
    id: 3690,
  },
  {
    location_type: 'zip_code',
    location: '6817',
    location_name: 'Maroggia',
    latitude: 45.9342,
    longitude: 8.9732,
    id: 3691,
  },
  {
    location_type: 'zip_code',
    location: '6818',
    location_name: 'Melano',
    latitude: 45.9215,
    longitude: 8.9967,
    id: 3692,
  },
  {
    location_type: 'zip_code',
    location: '6821',
    location_name: 'Rovio',
    latitude: 45.9354,
    longitude: 8.9998,
    id: 3693,
  },
  {
    location_type: 'zip_code',
    location: '6822',
    location_name: 'Arogno',
    latitude: 45.9606,
    longitude: 8.9956,
    id: 3694,
  },
  {
    location_type: 'zip_code',
    location: '6823',
    location_name: 'Pugerna',
    latitude: 45.9788,
    longitude: 8.9792,
    id: 3695,
  },
  {
    location_type: 'zip_code',
    location: '6827',
    location_name: 'Brusino Arsizio',
    latitude: 45.9226,
    longitude: 8.9374,
    id: 3696,
  },
  {
    location_type: 'zip_code',
    location: '6867',
    location_name: 'Serpiano',
    latitude: 45.9168,
    longitude: 8.9425,
    id: 3697,
  },
  {
    location_type: 'zip_code',
    location: '6876',
    location_name: 'Caprino',
    latitude: 45.9821,
    longitude: 8.9936,
    id: 3698,
  },
  {
    location_type: 'zip_code',
    location: '6900',
    location_name: 'Paradiso',
    latitude: 45.9881,
    longitude: 8.9448,
    id: 3699,
  },
  {
    location_type: 'zip_code',
    location: '6901',
    location_name: 'Lugano',
    latitude: 46.0003,
    longitude: 8.9512,
    id: 3700,
  },
  {
    location_type: 'zip_code',
    location: '6902',
    location_name: 'Lugano 2 Paradiso Caselle',
    latitude: 46.0117,
    longitude: 8.9575,
    id: 3701,
  },
  {
    location_type: 'zip_code',
    location: '6903',
    location_name: 'Lugano',
    latitude: 46.0058,
    longitude: 8.9475,
    id: 3702,
  },
  {
    location_type: 'zip_code',
    location: '6904',
    location_name: 'Lugano 4 Molino Nuovo Casel',
    latitude: 46.0117,
    longitude: 8.9575,
    id: 3703,
  },
  {
    location_type: 'zip_code',
    location: '6905',
    location_name: 'Lugano 5 Serv Autopostali',
    latitude: 46.0117,
    longitude: 8.9575,
    id: 3704,
  },
  {
    location_type: 'zip_code',
    location: '6906',
    location_name: 'Lugano 6 Cassarate Caselle',
    latitude: 46.0117,
    longitude: 8.9575,
    id: 3705,
  },
  {
    location_type: 'zip_code',
    location: '6907',
    location_name: 'Lugano 7 Loreto Caselle',
    latitude: 46.0117,
    longitude: 8.9575,
    id: 3706,
  },
  {
    location_type: 'zip_code',
    location: '6908',
    location_name: 'Massagno Caselle',
    latitude: 46.0117,
    longitude: 8.9575,
    id: 3707,
  },
  {
    location_type: 'zip_code',
    location: '6910',
    location_name: 'Lugano Servizi Speciali',
    latitude: 46.0238,
    longitude: 8.9393,
    id: 3708,
  },
  {
    location_type: 'zip_code',
    location: '6912',
    location_name: 'Pazzallo',
    latitude: 45.9798,
    longitude: 8.9447,
    id: 3709,
  },
  {
    location_type: 'zip_code',
    location: '6913',
    location_name: 'Carabbia',
    latitude: 45.9701,
    longitude: 8.9334,
    id: 3710,
  },
  {
    location_type: 'zip_code',
    location: '6914',
    location_name: 'Carona',
    latitude: 45.9525,
    longitude: 8.93,
    id: 3711,
  },
  {
    location_type: 'zip_code',
    location: '6915',
    location_name: 'Pambio-Noranco',
    latitude: 45.9845,
    longitude: 8.9276,
    id: 3712,
  },
  {
    location_type: 'zip_code',
    location: '6916',
    location_name: 'Grancia',
    latitude: 45.9684,
    longitude: 8.9265,
    id: 3713,
  },
  {
    location_type: 'zip_code',
    location: '6917',
    location_name: 'Barbengo',
    latitude: 45.9611,
    longitude: 8.9165,
    id: 3714,
  },
  {
    location_type: 'zip_code',
    location: '6918',
    location_name: 'Figino',
    latitude: 45.9559,
    longitude: 8.9215,
    id: 3715,
  },
  {
    location_type: 'zip_code',
    location: '6919',
    location_name: 'Carabietta',
    latitude: 45.9744,
    longitude: 8.9049,
    id: 3716,
  },
  {
    location_type: 'zip_code',
    location: '6921',
    location_name: 'Vico Morcote',
    latitude: 45.9348,
    longitude: 8.9243,
    id: 3717,
  },
  {
    location_type: 'zip_code',
    location: '6922',
    location_name: 'Morcote',
    latitude: 45.934,
    longitude: 8.9124,
    id: 3718,
  },
  {
    location_type: 'zip_code',
    location: '6924',
    location_name: 'Sorengo',
    latitude: 46.0008,
    longitude: 8.9349,
    id: 3719,
  },
  {
    location_type: 'zip_code',
    location: '6925',
    location_name: 'Cappella-Agnuzzo',
    latitude: 45.9927,
    longitude: 8.9391,
    id: 3720,
  },
  {
    location_type: 'zip_code',
    location: '6926',
    location_name: 'Montagnola',
    latitude: 45.9864,
    longitude: 8.9171,
    id: 3721,
  },
  {
    location_type: 'zip_code',
    location: '6927',
    location_name: 'Agra',
    latitude: 45.9675,
    longitude: 8.9112,
    id: 3722,
  },
  {
    location_type: 'zip_code',
    location: '6928',
    location_name: 'Manno',
    latitude: 46.0306,
    longitude: 8.9179,
    id: 3723,
  },
  {
    location_type: 'zip_code',
    location: '6929',
    location_name: 'Gravesano',
    latitude: 46.0416,
    longitude: 8.919,
    id: 3724,
  },
  {
    location_type: 'zip_code',
    location: '6930',
    location_name: 'Bedano',
    latitude: 46.0491,
    longitude: 8.923,
    id: 3725,
  },
  {
    location_type: 'zip_code',
    location: '6932',
    location_name: 'Breganzona',
    latitude: 46.0067,
    longitude: 8.9296,
    id: 3726,
  },
  {
    location_type: 'zip_code',
    location: '6933',
    location_name: 'Muzzano',
    latitude: 45.9973,
    longitude: 8.9199,
    id: 3727,
  },
  {
    location_type: 'zip_code',
    location: '6934',
    location_name: 'Bioggio',
    latitude: 46.0112,
    longitude: 8.9034,
    id: 3728,
  },
  {
    location_type: 'zip_code',
    location: '6935',
    location_name: 'Bosco Luganese',
    latitude: 46.0267,
    longitude: 8.9083,
    id: 3729,
  },
  {
    location_type: 'zip_code',
    location: '6936',
    location_name: 'Cademario',
    latitude: 46.0235,
    longitude: 8.893,
    id: 3730,
  },
  {
    location_type: 'zip_code',
    location: '6937',
    location_name: 'Breno',
    latitude: 46.0361,
    longitude: 8.8694,
    id: 3731,
  },
  {
    location_type: 'zip_code',
    location: '6938',
    location_name: 'Vezio',
    latitude: 46.0645,
    longitude: 8.8695,
    id: 3732,
  },
  {
    location_type: 'zip_code',
    location: '6939',
    location_name: 'Arosio',
    latitude: 46.0514,
    longitude: 8.8966,
    id: 3733,
  },
  {
    location_type: 'zip_code',
    location: '6942',
    location_name: 'Savosa',
    latitude: 46.0208,
    longitude: 8.9453,
    id: 3734,
  },
  {
    location_type: 'zip_code',
    location: '6943',
    location_name: 'Vezia',
    latitude: 46.021,
    longitude: 8.9337,
    id: 3735,
  },
  {
    location_type: 'zip_code',
    location: '6944',
    location_name: 'Cureglia',
    latitude: 46.0379,
    longitude: 8.9438,
    id: 3736,
  },
  {
    location_type: 'zip_code',
    location: '6945',
    location_name: 'Origlio',
    latitude: 46.0495,
    longitude: 8.9418,
    id: 3737,
  },
  {
    location_type: 'zip_code',
    location: '6946',
    location_name: 'Ponte Capriasca',
    latitude: 46.0934,
    longitude: 9.0046,
    id: 3738,
  },
  {
    location_type: 'zip_code',
    location: '6947',
    location_name: 'Vaglio',
    latitude: 46.0749,
    longitude: 8.9723,
    id: 3739,
  },
  {
    location_type: 'zip_code',
    location: '6948',
    location_name: 'Porza',
    latitude: 46.0243,
    longitude: 8.9525,
    id: 3740,
  },
  {
    location_type: 'zip_code',
    location: '6949',
    location_name: 'Comano',
    latitude: 46.0362,
    longitude: 8.9577,
    id: 3741,
  },
  {
    location_type: 'zip_code',
    location: '6950',
    location_name: 'Tesserete',
    latitude: 46.0695,
    longitude: 8.9648,
    id: 3742,
  },
  {
    location_type: 'zip_code',
    location: '6951',
    location_name: 'Lelgio',
    latitude: 46.091,
    longitude: 8.9665,
    id: 3743,
  },
  {
    location_type: 'zip_code',
    location: '6952',
    location_name: 'Canobbio',
    latitude: 46.0345,
    longitude: 8.9663,
    id: 3744,
  },
  {
    location_type: 'zip_code',
    location: '6953',
    location_name: 'Lugaggia',
    latitude: 46.0914,
    longitude: 9.0008,
    id: 3745,
  },
  {
    location_type: 'zip_code',
    location: '6954',
    location_name: 'Sala Capriasca',
    latitude: 46.0984,
    longitude: 8.9906,
    id: 3746,
  },
  {
    location_type: 'zip_code',
    location: '6955',
    location_name: 'Cagiallo',
    latitude: 46.0875,
    longitude: 8.9911,
    id: 3747,
  },
  {
    location_type: 'zip_code',
    location: '6956',
    location_name: 'Lopagno',
    latitude: 46.089,
    longitude: 8.9856,
    id: 3748,
  },
  {
    location_type: 'zip_code',
    location: '6957',
    location_name: 'Roveredo',
    latitude: 46.0754,
    longitude: 8.984,
    id: 3749,
  },
  {
    location_type: 'zip_code',
    location: '6958',
    location_name: 'Bidogno',
    latitude: 46.0847,
    longitude: 9.0062,
    id: 3750,
  },
  {
    location_type: 'zip_code',
    location: '6959',
    location_name: 'Piandera',
    latitude: 46.0814,
    longitude: 9.0379,
    id: 3751,
  },
  {
    location_type: 'zip_code',
    location: '6962',
    location_name: 'Viganello',
    latitude: 46.0121,
    longitude: 8.9692,
    id: 3752,
  },
  {
    location_type: 'zip_code',
    location: '6963',
    location_name: 'Pregassona',
    latitude: 46.0196,
    longitude: 8.9763,
    id: 3753,
  },
  {
    location_type: 'zip_code',
    location: '6964',
    location_name: 'Davesco-Soragno',
    latitude: 46.0326,
    longitude: 8.9869,
    id: 3754,
  },
  {
    location_type: 'zip_code',
    location: '6965',
    location_name: 'Cadro',
    latitude: 46.041,
    longitude: 8.9933,
    id: 3755,
  },
  {
    location_type: 'zip_code',
    location: '6966',
    location_name: 'Villa Luganese',
    latitude: 46.0549,
    longitude: 9.0037,
    id: 3756,
  },
  {
    location_type: 'zip_code',
    location: '6967',
    location_name: 'Dino',
    latitude: 46.0532,
    longitude: 8.9819,
    id: 3757,
  },
  {
    location_type: 'zip_code',
    location: '6968',
    location_name: 'Sonvico',
    latitude: 46.0659,
    longitude: 9.0138,
    id: 3758,
  },
  {
    location_type: 'zip_code',
    location: '6974',
    location_name: 'Aldesago',
    latitude: 46.0097,
    longitude: 8.981,
    id: 3759,
  },
  {
    location_type: 'zip_code',
    location: '6976',
    location_name: 'Castagnola',
    latitude: 46.0035,
    longitude: 8.9875,
    id: 3760,
  },
  {
    location_type: 'zip_code',
    location: '6977',
    location_name: 'Ruvigliana',
    latitude: 46.007,
    longitude: 8.9792,
    id: 3761,
  },
  {
    location_type: 'zip_code',
    location: '6978',
    location_name: 'Gandria',
    latitude: 46.0009,
    longitude: 9.0097,
    id: 3762,
  },
  {
    location_type: 'zip_code',
    location: '6979',
    location_name: 'Br\u00e8 sopra Lugano',
    latitude: 46.0185,
    longitude: 8.999,
    id: 3763,
  },
  {
    location_type: 'zip_code',
    location: '6980',
    location_name: 'Castelrotto',
    latitude: 45.9936,
    longitude: 8.8367,
    id: 3764,
  },
  {
    location_type: 'zip_code',
    location: '6981',
    location_name: 'Bombinasco',
    latitude: 46.0164,
    longitude: 8.8328,
    id: 3765,
  },
  {
    location_type: 'zip_code',
    location: '6982',
    location_name: 'Agno',
    latitude: 46.0063,
    longitude: 8.9026,
    id: 3766,
  },
  {
    location_type: 'zip_code',
    location: '6983',
    location_name: 'Magliaso',
    latitude: 45.9825,
    longitude: 8.881,
    id: 3767,
  },
  {
    location_type: 'zip_code',
    location: '6984',
    location_name: 'Pura',
    latitude: 45.9821,
    longitude: 8.8643,
    id: 3768,
  },
  {
    location_type: 'zip_code',
    location: '6986',
    location_name: 'Miglieglia',
    latitude: 46.0289,
    longitude: 8.8514,
    id: 3769,
  },
  {
    location_type: 'zip_code',
    location: '6987',
    location_name: 'Caslano',
    latitude: 45.9706,
    longitude: 8.8784,
    id: 3770,
  },
  {
    location_type: 'zip_code',
    location: '6988',
    location_name: 'Ponte Tresa',
    latitude: 45.9714,
    longitude: 8.8588,
    id: 3771,
  },
  {
    location_type: 'zip_code',
    location: '6989',
    location_name: 'Purasca',
    latitude: 45.9822,
    longitude: 8.8487,
    id: 3772,
  },
  {
    location_type: 'zip_code',
    location: '6990',
    location_name: 'Cassina d\'Agno',
    latitude: 45.993,
    longitude: 8.8915,
    id: 3773,
  },
  {
    location_type: 'zip_code',
    location: '6991',
    location_name: 'Neggio',
    latitude: 45.9888,
    longitude: 8.8821,
    id: 3774,
  },
  {
    location_type: 'zip_code',
    location: '6992',
    location_name: 'Cimo',
    latitude: 46.0056,
    longitude: 8.8931,
    id: 3775,
  },
  {
    location_type: 'zip_code',
    location: '6993',
    location_name: 'Iseo',
    latitude: 46.0056,
    longitude: 8.8772,
    id: 3776,
  },
  {
    location_type: 'zip_code',
    location: '6994',
    location_name: 'Aranno',
    latitude: 46.0177,
    longitude: 8.873,
    id: 3777,
  },
  {
    location_type: 'zip_code',
    location: '6995',
    location_name: 'Madonna del Piano',
    latitude: 45.99,
    longitude: 8.8321,
    id: 3778,
  },
  {
    location_type: 'zip_code',
    location: '6996',
    location_name: 'Ponte Cremenaga',
    latitude: 45.9927,
    longitude: 8.8007,
    id: 3779,
  },
  {
    location_type: 'zip_code',
    location: '6997',
    location_name: 'Sessa',
    latitude: 46.0011,
    longitude: 8.8099,
    id: 3780,
  },
  {
    location_type: 'zip_code',
    location: '6998',
    location_name: 'Monteggio',
    latitude: 45.9972,
    longitude: 8.8024,
    id: 3781,
  },
  {
    location_type: 'zip_code',
    location: '6999',
    location_name: 'Astano',
    latitude: 46.0155,
    longitude: 8.8158,
    id: 3782,
  },
  {
    location_type: 'zip_code',
    location: '6825',
    location_name: 'Monte Generoso Vetta',
    latitude: 45.9278,
    longitude: 9.0185,
    id: 3783,
  },
  {
    location_type: 'zip_code',
    location: '6826',
    location_name: 'Riva San Virtale',
    latitude: 45.9115,
    longitude: 8.9625,
    id: 3784,
  },
  {
    location_type: 'zip_code',
    location: '6828',
    location_name: 'Mezzana',
    latitude: 45.8527,
    longitude: 8.9989,
    id: 3785,
  },
  {
    location_type: 'zip_code',
    location: '6830',
    location_name: 'Chiasso',
    latitude: 45.8343,
    longitude: 9.0198,
    id: 3786,
  },
  {
    location_type: 'zip_code',
    location: '6832',
    location_name: 'Seseglio',
    latitude: 45.8287,
    longitude: 8.9997,
    id: 3787,
  },
  {
    location_type: 'zip_code',
    location: '6833',
    location_name: 'Vacallo',
    latitude: 45.8495,
    longitude: 9.0331,
    id: 3788,
  },
  {
    location_type: 'zip_code',
    location: '6834',
    location_name: 'Morbio Inferiore',
    latitude: 45.8532,
    longitude: 9.0208,
    id: 3789,
  },
  {
    location_type: 'zip_code',
    location: '6836',
    location_name: 'Serfontana',
    latitude: 45.8556,
    longitude: 9.0333,
    id: 3790,
  },
  {
    location_type: 'zip_code',
    location: '6850',
    location_name: 'Mendrisio',
    latitude: 45.8705,
    longitude: 8.9874,
    id: 3791,
  },
  {
    location_type: 'zip_code',
    location: '6852',
    location_name: 'Genestrerio',
    latitude: 45.85,
    longitude: 8.9568,
    id: 3792,
  },
  {
    location_type: 'zip_code',
    location: '6853',
    location_name: 'Ligornetto',
    latitude: 45.8629,
    longitude: 8.9459,
    id: 3793,
  },
  {
    location_type: 'zip_code',
    location: '6854',
    location_name: 'San Pietro',
    latitude: 45.8577,
    longitude: 8.9343,
    id: 3794,
  },
  {
    location_type: 'zip_code',
    location: '6855',
    location_name: 'Stabio',
    latitude: 45.8445,
    longitude: 8.9381,
    id: 3795,
  },
  {
    location_type: 'zip_code',
    location: '6862',
    location_name: 'Rancate',
    latitude: 45.8703,
    longitude: 8.9688,
    id: 3796,
  },
  {
    location_type: 'zip_code',
    location: '6863',
    location_name: 'Besazio',
    latitude: 45.8721,
    longitude: 8.951,
    id: 3797,
  },
  {
    location_type: 'zip_code',
    location: '6864',
    location_name: 'Arzo',
    latitude: 45.8754,
    longitude: 8.9433,
    id: 3798,
  },
  {
    location_type: 'zip_code',
    location: '6865',
    location_name: 'Tremona',
    latitude: 45.8809,
    longitude: 8.9586,
    id: 3799,
  },
  {
    location_type: 'zip_code',
    location: '6866',
    location_name: 'Meride',
    latitude: 45.8999,
    longitude: 8.9421,
    id: 3800,
  },
  {
    location_type: 'zip_code',
    location: '6872',
    location_name: 'Salorino',
    latitude: 45.8881,
    longitude: 9.0012,
    id: 3801,
  },
  {
    location_type: 'zip_code',
    location: '6873',
    location_name: 'Corteglia',
    latitude: 45.8628,
    longitude: 8.9928,
    id: 3802,
  },
  {
    location_type: 'zip_code',
    location: '6874',
    location_name: 'Castel San Pietro',
    latitude: 45.8676,
    longitude: 9.0096,
    id: 3803,
  },
  {
    location_type: 'zip_code',
    location: '6875',
    location_name: 'Campora',
    latitude: 45.873,
    longitude: 9.0246,
    id: 3804,
  },
  {
    location_type: 'zip_code',
    location: '6877',
    location_name: 'Coldrerio',
    latitude: 45.8569,
    longitude: 8.9876,
    id: 3805,
  },
  {
    location_type: 'zip_code',
    location: '6883',
    location_name: 'Novazzano',
    latitude: 45.842,
    longitude: 8.98,
    id: 3806,
  },
  {
    location_type: 'zip_code',
    location: '6526',
    location_name: 'Prosito',
    latitude: 46.2816,
    longitude: 8.9866,
    id: 3807,
  },
  {
    location_type: 'zip_code',
    location: '6527',
    location_name: 'Lodrino',
    latitude: 46.2895,
    longitude: 8.9489,
    id: 3808,
  },
  {
    location_type: 'zip_code',
    location: '6702',
    location_name: 'Claro',
    latitude: 46.2652,
    longitude: 9.0347,
    id: 3809,
  },
  {
    location_type: 'zip_code',
    location: '6703',
    location_name: 'Osogna',
    latitude: 46.3237,
    longitude: 9.0113,
    id: 3810,
  },
  {
    location_type: 'zip_code',
    location: '6705',
    location_name: 'Cresciano',
    latitude: 46.2991,
    longitude: 9.0269,
    id: 3811,
  },
  {
    location_type: 'zip_code',
    location: '6707',
    location_name: 'Iragna',
    latitude: 46.3246,
    longitude: 8.9392,
    id: 3812,
  },
  {
    location_type: 'zip_code',
    location: '6710',
    location_name: 'Biasca',
    latitude: 46.3524,
    longitude: 8.9871,
    id: 3813,
  },
  {
    location_type: 'zip_code',
    location: '6670',
    location_name: 'Avegno',
    latitude: 46.2092,
    longitude: 8.7547,
    id: 3814,
  },
  {
    location_type: 'zip_code',
    location: '6672',
    location_name: 'Gordevio',
    latitude: 46.2353,
    longitude: 8.7649,
    id: 3815,
  },
  {
    location_type: 'zip_code',
    location: '6673',
    location_name: 'Maggia',
    latitude: 46.2699,
    longitude: 8.7331,
    id: 3816,
  },
  {
    location_type: 'zip_code',
    location: '6674',
    location_name: 'Someo',
    latitude: 46.2882,
    longitude: 8.6404,
    id: 3817,
  },
  {
    location_type: 'zip_code',
    location: '6675',
    location_name: 'Cevio',
    latitude: 46.306,
    longitude: 8.5942,
    id: 3818,
  },
  {
    location_type: 'zip_code',
    location: '6676',
    location_name: 'Bignasco',
    latitude: 46.3436,
    longitude: 8.5876,
    id: 3819,
  },
  {
    location_type: 'zip_code',
    location: '6677',
    location_name: 'Moghegno',
    latitude: 46.2472,
    longitude: 8.687,
    id: 3820,
  },
  {
    location_type: 'zip_code',
    location: '6678',
    location_name: 'Coglio',
    latitude: 46.2965,
    longitude: 8.7062,
    id: 3821,
  },
  {
    location_type: 'zip_code',
    location: '6682',
    location_name: 'Linescio',
    latitude: 46.3085,
    longitude: 8.5726,
    id: 3822,
  },
  {
    location_type: 'zip_code',
    location: '6683',
    location_name: 'Cerentino',
    latitude: 46.317,
    longitude: 8.5385,
    id: 3823,
  },
  {
    location_type: 'zip_code',
    location: '6684',
    location_name: 'Cimalmotto',
    latitude: 46.2745,
    longitude: 8.4684,
    id: 3824,
  },
  {
    location_type: 'zip_code',
    location: '6685',
    location_name: 'Bosco Gurin',
    latitude: 46.3213,
    longitude: 8.489,
    id: 3825,
  },
  {
    location_type: 'zip_code',
    location: '6690',
    location_name: 'San Carlo',
    latitude: 46.4283,
    longitude: 8.5042,
    id: 3826,
  },
  {
    location_type: 'zip_code',
    location: '6692',
    location_name: 'Menzonio',
    latitude: 46.3543,
    longitude: 8.6535,
    id: 3827,
  },
  {
    location_type: 'zip_code',
    location: '6693',
    location_name: 'Broglio',
    latitude: 46.3707,
    longitude: 8.6735,
    id: 3828,
  },
  {
    location_type: 'zip_code',
    location: '6694',
    location_name: 'Prato-Sornico',
    latitude: 46.399,
    longitude: 8.6878,
    id: 3829,
  },
  {
    location_type: 'zip_code',
    location: '6695',
    location_name: 'Peccia',
    latitude: 46.4067,
    longitude: 8.6419,
    id: 3830,
  },
  {
    location_type: 'zip_code',
    location: '6696',
    location_name: 'Fusio',
    latitude: 46.4405,
    longitude: 8.6537,
    id: 3831,
  },
  {
    location_type: 'zip_code',
    location: '6377',
    location_name: 'Seelisberg',
    latitude: 46.9698,
    longitude: 8.5665,
    id: 3832,
  },
  {
    location_type: 'zip_code',
    location: '6441',
    location_name: 'R\u00fctli',
    latitude: 46.9667,
    longitude: 8.6,
    id: 3833,
  },
  {
    location_type: 'zip_code',
    location: '6454',
    location_name: 'Fl\u00fcelen',
    latitude: 46.9126,
    longitude: 8.628,
    id: 3834,
  },
  {
    location_type: 'zip_code',
    location: '6460',
    location_name: 'Altdorf UR',
    latitude: 46.8876,
    longitude: 8.6412,
    id: 3835,
  },
  {
    location_type: 'zip_code',
    location: '6461',
    location_name: 'Isenthal',
    latitude: 46.8826,
    longitude: 8.5325,
    id: 3836,
  },
  {
    location_type: 'zip_code',
    location: '6462',
    location_name: 'Seedorf',
    latitude: 46.8876,
    longitude: 8.5884,
    id: 3837,
  },
  {
    location_type: 'zip_code',
    location: '6463',
    location_name: 'B\u00fcrglen UR',
    latitude: 46.8903,
    longitude: 8.6854,
    id: 3838,
  },
  {
    location_type: 'zip_code',
    location: '6464',
    location_name: 'Spiringen',
    latitude: 46.874,
    longitude: 8.7395,
    id: 3839,
  },
  {
    location_type: 'zip_code',
    location: '6465',
    location_name: 'Untersch\u00e4chen',
    latitude: 46.8527,
    longitude: 8.7822,
    id: 3840,
  },
  {
    location_type: 'zip_code',
    location: '6466',
    location_name: 'Seedorf UR',
    latitude: 46.9306,
    longitude: 8.5799,
    id: 3841,
  },
  {
    location_type: 'zip_code',
    location: '6467',
    location_name: 'Schattdorf',
    latitude: 46.857,
    longitude: 8.6538,
    id: 3842,
  },
  {
    location_type: 'zip_code',
    location: '6468',
    location_name: 'Attinghausen',
    latitude: 46.8568,
    longitude: 8.606,
    id: 3843,
  },
  {
    location_type: 'zip_code',
    location: '6469',
    location_name: 'Haldi b.Schattdorf',
    latitude: 46.8477,
    longitude: 8.6885,
    id: 3844,
  },
  {
    location_type: 'zip_code',
    location: '6472',
    location_name: 'Erstfeld',
    latitude: 46.8127,
    longitude: 8.601,
    id: 3845,
  },
  {
    location_type: 'zip_code',
    location: '6473',
    location_name: 'Silenen',
    latitude: 46.8019,
    longitude: 8.692,
    id: 3846,
  },
  {
    location_type: 'zip_code',
    location: '6474',
    location_name: 'Amsteg',
    latitude: 46.7745,
    longitude: 8.6576,
    id: 3847,
  },
  {
    location_type: 'zip_code',
    location: '6475',
    location_name: 'Bristen',
    latitude: 46.7714,
    longitude: 8.7521,
    id: 3848,
  },
  {
    location_type: 'zip_code',
    location: '6476',
    location_name: 'Intschi',
    latitude: 46.7595,
    longitude: 8.6452,
    id: 3849,
  },
  {
    location_type: 'zip_code',
    location: '6482',
    location_name: 'Gurtnellen',
    latitude: 46.753,
    longitude: 8.6154,
    id: 3850,
  },
  {
    location_type: 'zip_code',
    location: '6484',
    location_name: 'Wassen UR',
    latitude: 46.7012,
    longitude: 8.5992,
    id: 3851,
  },
  {
    location_type: 'zip_code',
    location: '6485',
    location_name: 'Meien',
    latitude: 46.7315,
    longitude: 8.5164,
    id: 3852,
  },
  {
    location_type: 'zip_code',
    location: '6487',
    location_name: 'G\u00f6schenen',
    latitude: 46.6676,
    longitude: 8.5182,
    id: 3853,
  },
  {
    location_type: 'zip_code',
    location: '6490',
    location_name: 'Andermatt',
    latitude: 46.6175,
    longitude: 8.6143,
    id: 3854,
  },
  {
    location_type: 'zip_code',
    location: '6491',
    location_name: 'Realp',
    latitude: 46.5769,
    longitude: 8.4725,
    id: 3855,
  },
  {
    location_type: 'zip_code',
    location: '6493',
    location_name: 'Hospental',
    latitude: 46.6092,
    longitude: 8.5586,
    id: 3856,
  },
  {
    location_type: 'zip_code',
    location: '8751',
    location_name: 'Urnerboden',
    latitude: 46.88,
    longitude: 8.908,
    id: 3857,
  },
  {
    location_type: 'zip_code',
    location: '1844',
    location_name: 'Villeneuve VD',
    latitude: 46.4039,
    longitude: 6.9667,
    id: 3858,
  },
  {
    location_type: 'zip_code',
    location: '1845',
    location_name: 'Noville',
    latitude: 46.3792,
    longitude: 6.8935,
    id: 3859,
  },
  {
    location_type: 'zip_code',
    location: '1846',
    location_name: 'Chessel',
    latitude: 46.3503,
    longitude: 6.8966,
    id: 3860,
  },
  {
    location_type: 'zip_code',
    location: '1847',
    location_name: 'Rennaz',
    latitude: 46.3759,
    longitude: 6.9177,
    id: 3861,
  },
  {
    location_type: 'zip_code',
    location: '1852',
    location_name: 'Roche VD',
    latitude: 46.3546,
    longitude: 6.929,
    id: 3862,
  },
  {
    location_type: 'zip_code',
    location: '1853',
    location_name: 'Yvorne',
    latitude: 46.337,
    longitude: 6.9722,
    id: 3863,
  },
  {
    location_type: 'zip_code',
    location: '1854',
    location_name: 'Leysin',
    latitude: 46.3513,
    longitude: 7.0087,
    id: 3864,
  },
  {
    location_type: 'zip_code',
    location: '1856',
    location_name: 'Corbeyrier',
    latitude: 46.3706,
    longitude: 6.9869,
    id: 3865,
  },
  {
    location_type: 'zip_code',
    location: '1860',
    location_name: 'Aigle',
    latitude: 46.311,
    longitude: 6.9658,
    id: 3866,
  },
  {
    location_type: 'zip_code',
    location: '1862',
    location_name: 'La Comballaz',
    latitude: 46.3782,
    longitude: 7.0767,
    id: 3867,
  },
  {
    location_type: 'zip_code',
    location: '1863',
    location_name: 'Le S\u00e9pey',
    latitude: 46.376,
    longitude: 7.0557,
    id: 3868,
  },
  {
    location_type: 'zip_code',
    location: '1864',
    location_name: 'Vers-l\'Eglise',
    latitude: 46.3522,
    longitude: 7.1144,
    id: 3869,
  },
  {
    location_type: 'zip_code',
    location: '1865',
    location_name: 'Les Diablerets',
    latitude: 46.3432,
    longitude: 7.1632,
    id: 3870,
  },
  {
    location_type: 'zip_code',
    location: '1866',
    location_name: 'La Forclaz VD',
    latitude: 46.3415,
    longitude: 7.0726,
    id: 3871,
  },
  {
    location_type: 'zip_code',
    location: '1867',
    location_name: 'Ollon VD',
    latitude: 46.2818,
    longitude: 7.0092,
    id: 3872,
  },
  {
    location_type: 'zip_code',
    location: '1880',
    location_name: 'Bex',
    latitude: 46.2491,
    longitude: 7.0137,
    id: 3873,
  },
  {
    location_type: 'zip_code',
    location: '1882',
    location_name: 'Les Posses-sur-Bex',
    latitude: 46.2752,
    longitude: 7.0456,
    id: 3874,
  },
  {
    location_type: 'zip_code',
    location: '1884',
    location_name: 'Hu\u00e9moz',
    latitude: 46.2968,
    longitude: 7.0148,
    id: 3875,
  },
  {
    location_type: 'zip_code',
    location: '1885',
    location_name: 'Chesi\u00e8res',
    latitude: 46.3024,
    longitude: 7.0372,
    id: 3876,
  },
  {
    location_type: 'zip_code',
    location: '1892',
    location_name: 'Morcles',
    latitude: 46.2047,
    longitude: 7.0448,
    id: 3877,
  },
  {
    location_type: 'zip_code',
    location: '1082',
    location_name: 'Corcelles-le-Jorat',
    latitude: 46.5989,
    longitude: 6.7304,
    id: 3878,
  },
  {
    location_type: 'zip_code',
    location: '1084',
    location_name: 'Carrouge VD',
    latitude: 46.6025,
    longitude: 6.7745,
    id: 3879,
  },
  {
    location_type: 'zip_code',
    location: '1085',
    location_name: 'Vulliens',
    latitude: 46.635,
    longitude: 6.798,
    id: 3880,
  },
  {
    location_type: 'zip_code',
    location: '1088',
    location_name: 'Ropraz',
    latitude: 46.6124,
    longitude: 6.755,
    id: 3881,
  },
  {
    location_type: 'zip_code',
    location: '1509',
    location_name: 'Vucherens',
    latitude: 46.6277,
    longitude: 6.7746,
    id: 3882,
  },
  {
    location_type: 'zip_code',
    location: '1510',
    location_name: 'Syens',
    latitude: 46.6423,
    longitude: 6.7774,
    id: 3883,
  },
  {
    location_type: 'zip_code',
    location: '1512',
    location_name: 'Chavannes-sur-Moudon',
    latitude: 46.6585,
    longitude: 6.8135,
    id: 3884,
  },
  {
    location_type: 'zip_code',
    location: '1513',
    location_name: 'Rossenges',
    latitude: 46.6547,
    longitude: 6.7747,
    id: 3885,
  },
  {
    location_type: 'zip_code',
    location: '1514',
    location_name: 'Bussy-sur-Moudon',
    latitude: 46.6913,
    longitude: 6.8109,
    id: 3886,
  },
  {
    location_type: 'zip_code',
    location: '1515',
    location_name: 'Villars-le-Comte',
    latitude: 46.7131,
    longitude: 6.8013,
    id: 3887,
  },
  {
    location_type: 'zip_code',
    location: '1521',
    location_name: 'Curtilles',
    latitude: 46.6982,
    longitude: 6.8494,
    id: 3888,
  },
  {
    location_type: 'zip_code',
    location: '1522',
    location_name: 'Oulens-sur-Lucens',
    latitude: 46.707,
    longitude: 6.8145,
    id: 3889,
  },
  {
    location_type: 'zip_code',
    location: '1523',
    location_name: 'Granges-pr\u00e8s-Marnand',
    latitude: 46.7668,
    longitude: 6.8839,
    id: 3890,
  },
  {
    location_type: 'zip_code',
    location: '1524',
    location_name: 'Marnand',
    latitude: 46.7587,
    longitude: 6.9024,
    id: 3891,
  },
  {
    location_type: 'zip_code',
    location: '1525',
    location_name: 'Seigneux',
    latitude: 46.7288,
    longitude: 6.8706,
    id: 3892,
  },
  {
    location_type: 'zip_code',
    location: '1526',
    location_name: 'Forel-sur-Lucens',
    latitude: 46.7258,
    longitude: 6.8239,
    id: 3893,
  },
  {
    location_type: 'zip_code',
    location: '1530',
    location_name: 'Payerne',
    latitude: 46.819,
    longitude: 6.9252,
    id: 3894,
  },
  {
    location_type: 'zip_code',
    location: '1535',
    location_name: 'Combremont-le-Grand',
    latitude: 46.765,
    longitude: 6.8233,
    id: 3895,
  },
  {
    location_type: 'zip_code',
    location: '1536',
    location_name: 'Combremont-le-Petit',
    latitude: 46.7462,
    longitude: 6.8033,
    id: 3896,
  },
  {
    location_type: 'zip_code',
    location: '1537',
    location_name: 'Champtauroz',
    latitude: 46.7588,
    longitude: 6.7855,
    id: 3897,
  },
  {
    location_type: 'zip_code',
    location: '1538',
    location_name: 'Treytorrens (Payerne)',
    latitude: 46.7752,
    longitude: 6.8037,
    id: 3898,
  },
  {
    location_type: 'zip_code',
    location: '1543',
    location_name: 'Grandcour',
    latitude: 46.8738,
    longitude: 6.9283,
    id: 3899,
  },
  {
    location_type: 'zip_code',
    location: '1545',
    location_name: 'Chevroux',
    latitude: 46.8906,
    longitude: 6.911,
    id: 3900,
  },
  {
    location_type: 'zip_code',
    location: '1551',
    location_name: 'Vers-chez-Perrin',
    latitude: 46.7971,
    longitude: 6.9465,
    id: 3901,
  },
  {
    location_type: 'zip_code',
    location: '1552',
    location_name: 'Trey',
    latitude: 46.7802,
    longitude: 6.9234,
    id: 3902,
  },
  {
    location_type: 'zip_code',
    location: '1554',
    location_name: 'S\u00e9deilles',
    latitude: 46.7423,
    longitude: 6.9308,
    id: 3903,
  },
  {
    location_type: 'zip_code',
    location: '1555',
    location_name: 'Villarzel',
    latitude: 46.7422,
    longitude: 6.908,
    id: 3904,
  },
  {
    location_type: 'zip_code',
    location: '1562',
    location_name: 'Corcelles-pr\u00e8s-Payerne',
    latitude: 46.8371,
    longitude: 6.9647,
    id: 3905,
  },
  {
    location_type: 'zip_code',
    location: '1580',
    location_name: 'Oleyres',
    latitude: 46.8568,
    longitude: 7.0372,
    id: 3906,
  },
  {
    location_type: 'zip_code',
    location: '1582',
    location_name: 'Donatyre',
    latitude: 46.8759,
    longitude: 7.0616,
    id: 3907,
  },
  {
    location_type: 'zip_code',
    location: '1584',
    location_name: 'Villars-le-Grand',
    latitude: 46.9044,
    longitude: 6.9997,
    id: 3908,
  },
  {
    location_type: 'zip_code',
    location: '1585',
    location_name: 'Cotterd',
    latitude: 46.9224,
    longitude: 7.0261,
    id: 3909,
  },
  {
    location_type: 'zip_code',
    location: '1586',
    location_name: 'Vallamand',
    latitude: 46.933,
    longitude: 7.0364,
    id: 3910,
  },
  {
    location_type: 'zip_code',
    location: '1587',
    location_name: 'Constantine',
    latitude: 46.9118,
    longitude: 7.0123,
    id: 3911,
  },
  {
    location_type: 'zip_code',
    location: '1588',
    location_name: 'Cudrefin',
    latitude: 46.9545,
    longitude: 7.0328,
    id: 3912,
  },
  {
    location_type: 'zip_code',
    location: '1589',
    location_name: 'Chabrey',
    latitude: 46.9303,
    longitude: 6.983,
    id: 3913,
  },
  {
    location_type: 'zip_code',
    location: '1682',
    location_name: 'Villars-Bramard',
    latitude: 46.7205,
    longitude: 6.9033,
    id: 3914,
  },
  {
    location_type: 'zip_code',
    location: '1683',
    location_name: 'Chesalles-sur-Moudon',
    latitude: 46.6752,
    longitude: 6.8352,
    id: 3915,
  },
  {
    location_type: 'zip_code',
    location: '1031',
    location_name: 'Mex VD',
    latitude: 46.5748,
    longitude: 6.5616,
    id: 3916,
  },
  {
    location_type: 'zip_code',
    location: '1034',
    location_name: 'Boussens',
    latitude: 46.6031,
    longitude: 6.5846,
    id: 3917,
  },
  {
    location_type: 'zip_code',
    location: '1035',
    location_name: 'Bournens',
    latitude: 46.609,
    longitude: 6.5644,
    id: 3918,
  },
  {
    location_type: 'zip_code',
    location: '1036',
    location_name: 'Sullens',
    latitude: 46.5917,
    longitude: 6.5693,
    id: 3919,
  },
  {
    location_type: 'zip_code',
    location: '1037',
    location_name: 'Etagni\u00e8res',
    latitude: 46.6009,
    longitude: 6.6108,
    id: 3920,
  },
  {
    location_type: 'zip_code',
    location: '1038',
    location_name: 'Bercher',
    latitude: 46.6899,
    longitude: 6.7058,
    id: 3921,
  },
  {
    location_type: 'zip_code',
    location: '1040',
    location_name: 'Echallens',
    latitude: 46.6379,
    longitude: 6.6352,
    id: 3922,
  },
  {
    location_type: 'zip_code',
    location: '1041',
    location_name: 'Dommartin',
    latitude: 46.6433,
    longitude: 6.6962,
    id: 3923,
  },
  {
    location_type: 'zip_code',
    location: '1042',
    location_name: 'Bioley-Orjulaz',
    latitude: 46.6194,
    longitude: 6.5993,
    id: 3924,
  },
  {
    location_type: 'zip_code',
    location: '1043',
    location_name: 'Sugnens',
    latitude: 46.6547,
    longitude: 6.6743,
    id: 3925,
  },
  {
    location_type: 'zip_code',
    location: '1044',
    location_name: 'Fey',
    latitude: 46.6738,
    longitude: 6.6803,
    id: 3926,
  },
  {
    location_type: 'zip_code',
    location: '1045',
    location_name: 'Ogens',
    latitude: 46.7084,
    longitude: 6.7245,
    id: 3927,
  },
  {
    location_type: 'zip_code',
    location: '1046',
    location_name: 'Rueyres',
    latitude: 46.6974,
    longitude: 6.6939,
    id: 3928,
  },
  {
    location_type: 'zip_code',
    location: '1047',
    location_name: 'Oppens',
    latitude: 46.7153,
    longitude: 6.695,
    id: 3929,
  },
  {
    location_type: 'zip_code',
    location: '1053',
    location_name: 'Cugy VD',
    latitude: 46.5863,
    longitude: 6.6579,
    id: 3930,
  },
  {
    location_type: 'zip_code',
    location: '1054',
    location_name: 'Morrens VD',
    latitude: 46.592,
    longitude: 6.6276,
    id: 3931,
  },
  {
    location_type: 'zip_code',
    location: '1055',
    location_name: 'Froideville',
    latitude: 46.5967,
    longitude: 6.6916,
    id: 3932,
  },
  {
    location_type: 'zip_code',
    location: '1058',
    location_name: 'Villars-Tiercelin',
    latitude: 46.6171,
    longitude: 6.7134,
    id: 3933,
  },
  {
    location_type: 'zip_code',
    location: '1059',
    location_name: 'Peney-le-Jorat',
    latitude: 46.629,
    longitude: 6.729,
    id: 3934,
  },
  {
    location_type: 'zip_code',
    location: '1061',
    location_name: 'Villars-Mendraz',
    latitude: 46.6478,
    longitude: 6.7273,
    id: 3935,
  },
  {
    location_type: 'zip_code',
    location: '1062',
    location_name: 'Sottens',
    latitude: 46.6513,
    longitude: 6.7466,
    id: 3936,
  },
  {
    location_type: 'zip_code',
    location: '1063',
    location_name: 'Chapelle-sur-Moudon',
    latitude: 46.6711,
    longitude: 6.7403,
    id: 3937,
  },
  {
    location_type: 'zip_code',
    location: '1302',
    location_name: 'Vufflens-la-Ville',
    latitude: 46.5752,
    longitude: 6.5372,
    id: 3938,
  },
  {
    location_type: 'zip_code',
    location: '1303',
    location_name: 'Penthaz',
    latitude: 46.5994,
    longitude: 6.5405,
    id: 3939,
  },
  {
    location_type: 'zip_code',
    location: '1305',
    location_name: 'Penthalaz',
    latitude: 46.6139,
    longitude: 6.5316,
    id: 3940,
  },
  {
    location_type: 'zip_code',
    location: '1306',
    location_name: 'Daillens',
    latitude: 46.6267,
    longitude: 6.5486,
    id: 3941,
  },
  {
    location_type: 'zip_code',
    location: '1307',
    location_name: 'Lussery-Villars',
    latitude: 46.6324,
    longitude: 6.5254,
    id: 3942,
  },
  {
    location_type: 'zip_code',
    location: '1310',
    location_name: 'Daillens Centre Colis',
    latitude: 46.6667,
    longitude: 6.5167,
    id: 3943,
  },
  {
    location_type: 'zip_code',
    location: '1320',
    location_name: 'Daillens ST PP 1',
    latitude: 46.6286,
    longitude: 6.5495,
    id: 3944,
  },
  {
    location_type: 'zip_code',
    location: '1330',
    location_name: 'Daillens CALL',
    latitude: 46.6286,
    longitude: 6.5495,
    id: 3945,
  },
  {
    location_type: 'zip_code',
    location: '1375',
    location_name: 'Penth\u00e9r\u00e9az',
    latitude: 46.6829,
    longitude: 6.6053,
    id: 3946,
  },
  {
    location_type: 'zip_code',
    location: '1376',
    location_name: 'Goumoens-le-Jux',
    latitude: 46.6667,
    longitude: 6.5869,
    id: 3947,
  },
  {
    location_type: 'zip_code',
    location: '1377',
    location_name: 'Oulens-sur-Echallens',
    latitude: 46.6481,
    longitude: 6.5719,
    id: 3948,
  },
  {
    location_type: 'zip_code',
    location: '1413',
    location_name: 'Oppens',
    latitude: 46.7162,
    longitude: 6.6946,
    id: 3949,
  },
  {
    location_type: 'zip_code',
    location: '1414',
    location_name: 'Rueyres',
    latitude: 46.6968,
    longitude: 6.693,
    id: 3950,
  },
  {
    location_type: 'zip_code',
    location: '1416',
    location_name: 'Pailly',
    latitude: 46.6991,
    longitude: 6.6746,
    id: 3951,
  },
  {
    location_type: 'zip_code',
    location: '1417',
    location_name: 'Epautheyres',
    latitude: 46.7357,
    longitude: 6.6462,
    id: 3952,
  },
  {
    location_type: 'zip_code',
    location: '1418',
    location_name: 'Vuarrens',
    latitude: 46.6854,
    longitude: 6.6468,
    id: 3953,
  },
  {
    location_type: 'zip_code',
    location: '1148',
    location_name: 'La Praz',
    latitude: 46.6688,
    longitude: 6.4246,
    id: 3954,
  },
  {
    location_type: 'zip_code',
    location: '1321',
    location_name: 'Arnex-sur-Orbe',
    latitude: 46.6946,
    longitude: 6.5183,
    id: 3955,
  },
  {
    location_type: 'zip_code',
    location: '1322',
    location_name: 'Croy',
    latitude: 46.6857,
    longitude: 6.4846,
    id: 3956,
  },
  {
    location_type: 'zip_code',
    location: '1323',
    location_name: 'Romainm\u00f4tier',
    latitude: 46.6691,
    longitude: 6.4515,
    id: 3957,
  },
  {
    location_type: 'zip_code',
    location: '1324',
    location_name: 'Premier',
    latitude: 46.7121,
    longitude: 6.4447,
    id: 3958,
  },
  {
    location_type: 'zip_code',
    location: '1325',
    location_name: 'Vaulion',
    latitude: 46.6761,
    longitude: 6.3898,
    id: 3959,
  },
  {
    location_type: 'zip_code',
    location: '1326',
    location_name: 'Juriens',
    latitude: 46.6791,
    longitude: 6.4423,
    id: 3960,
  },
  {
    location_type: 'zip_code',
    location: '1329',
    location_name: 'Bretonni\u00e8res',
    latitude: 46.7139,
    longitude: 6.4695,
    id: 3961,
  },
  {
    location_type: 'zip_code',
    location: '1337',
    location_name: 'Vallorbe',
    latitude: 46.708,
    longitude: 6.3746,
    id: 3962,
  },
  {
    location_type: 'zip_code',
    location: '1338',
    location_name: 'Ballaigues',
    latitude: 46.7351,
    longitude: 6.4105,
    id: 3963,
  },
  {
    location_type: 'zip_code',
    location: '1341',
    location_name: 'Orient',
    latitude: 46.5928,
    longitude: 6.2621,
    id: 3964,
  },
  {
    location_type: 'zip_code',
    location: '1342',
    location_name: 'Le Pont',
    latitude: 46.6742,
    longitude: 6.3353,
    id: 3965,
  },
  {
    location_type: 'zip_code',
    location: '1343',
    location_name: 'Les Charbonni\u00e8res',
    latitude: 46.6775,
    longitude: 6.2983,
    id: 3966,
  },
  {
    location_type: 'zip_code',
    location: '1344',
    location_name: 'L\'Abbaye',
    latitude: 46.6396,
    longitude: 6.322,
    id: 3967,
  },
  {
    location_type: 'zip_code',
    location: '1345',
    location_name: 'Le Lieu',
    latitude: 46.6509,
    longitude: 6.2697,
    id: 3968,
  },
  {
    location_type: 'zip_code',
    location: '1346',
    location_name: 'Les Bioux',
    latitude: 46.6147,
    longitude: 6.2925,
    id: 3969,
  },
  {
    location_type: 'zip_code',
    location: '1347',
    location_name: 'Le Sentier',
    latitude: 46.6115,
    longitude: 6.2006,
    id: 3970,
  },
  {
    location_type: 'zip_code',
    location: '1348',
    location_name: 'Le Brassus',
    latitude: 46.5689,
    longitude: 6.1983,
    id: 3971,
  },
  {
    location_type: 'zip_code',
    location: '1350',
    location_name: 'Orbe',
    latitude: 46.7227,
    longitude: 6.5432,
    id: 3972,
  },
  {
    location_type: 'zip_code',
    location: '1352',
    location_name: 'Agiez',
    latitude: 46.7161,
    longitude: 6.5087,
    id: 3973,
  },
  {
    location_type: 'zip_code',
    location: '1353',
    location_name: 'Bofflens',
    latitude: 46.7012,
    longitude: 6.4964,
    id: 3974,
  },
  {
    location_type: 'zip_code',
    location: '1354',
    location_name: 'Montcherand',
    latitude: 46.7346,
    longitude: 6.5111,
    id: 3975,
  },
  {
    location_type: 'zip_code',
    location: '1355',
    location_name: 'L\'Abergement',
    latitude: 46.7543,
    longitude: 6.4818,
    id: 3976,
  },
  {
    location_type: 'zip_code',
    location: '1356',
    location_name: 'La Russille',
    latitude: 46.7396,
    longitude: 6.49,
    id: 3977,
  },
  {
    location_type: 'zip_code',
    location: '1357',
    location_name: 'Lignerolle',
    latitude: 46.7515,
    longitude: 6.4436,
    id: 3978,
  },
  {
    location_type: 'zip_code',
    location: '1358',
    location_name: 'Valeyres-sous-Rances',
    latitude: 46.7483,
    longitude: 6.5382,
    id: 3979,
  },
  {
    location_type: 'zip_code',
    location: '1372',
    location_name: 'Bavois',
    latitude: 46.6815,
    longitude: 6.5615,
    id: 3980,
  },
  {
    location_type: 'zip_code',
    location: '1373',
    location_name: 'Chavornay',
    latitude: 46.7048,
    longitude: 6.5639,
    id: 3981,
  },
  {
    location_type: 'zip_code',
    location: '1374',
    location_name: 'Corcelles-sur-Chavornay',
    latitude: 46.6987,
    longitude: 6.6072,
    id: 3982,
  },
  {
    location_type: 'zip_code',
    location: '1400',
    location_name: 'Yverdon-les-Bains',
    latitude: 46.7787,
    longitude: 6.6339,
    id: 3983,
  },
  {
    location_type: 'zip_code',
    location: '1401',
    location_name: 'Yverdon-les-Bains',
    latitude: 46.7833,
    longitude: 6.65,
    id: 3984,
  },
  {
    location_type: 'zip_code',
    location: '1404',
    location_name: 'Cuarny',
    latitude: 46.7702,
    longitude: 6.6905,
    id: 3985,
  },
  {
    location_type: 'zip_code',
    location: '1405',
    location_name: 'Pomy',
    latitude: 46.7586,
    longitude: 6.6691,
    id: 3986,
  },
  {
    location_type: 'zip_code',
    location: '1406',
    location_name: 'Cronay',
    latitude: 46.755,
    longitude: 6.7008,
    id: 3987,
  },
  {
    location_type: 'zip_code',
    location: '1407',
    location_name: 'Donneloye',
    latitude: 46.7473,
    longitude: 6.7198,
    id: 3988,
  },
  {
    location_type: 'zip_code',
    location: '1408',
    location_name: 'Prahins',
    latitude: 46.7356,
    longitude: 6.7332,
    id: 3989,
  },
  {
    location_type: 'zip_code',
    location: '1409',
    location_name: 'Chan\u00e9az',
    latitude: 46.7289,
    longitude: 6.7426,
    id: 3990,
  },
  {
    location_type: 'zip_code',
    location: '1412',
    location_name: 'Valeyres-sous-Ursins',
    latitude: 46.745,
    longitude: 6.6539,
    id: 3991,
  },
  {
    location_type: 'zip_code',
    location: '1415',
    location_name: 'Molondin',
    latitude: 46.7649,
    longitude: 6.7487,
    id: 3992,
  },
  {
    location_type: 'zip_code',
    location: '1420',
    location_name: 'Fiez',
    latitude: 46.8277,
    longitude: 6.6273,
    id: 3993,
  },
  {
    location_type: 'zip_code',
    location: '1421',
    location_name: 'Fontaines-sur-Grandson',
    latitude: 46.8332,
    longitude: 6.6169,
    id: 3994,
  },
  {
    location_type: 'zip_code',
    location: '1422',
    location_name: 'Grandson',
    latitude: 46.819,
    longitude: 6.6509,
    id: 3995,
  },
  {
    location_type: 'zip_code',
    location: '1423',
    location_name: 'Vaugondry',
    latitude: 46.8461,
    longitude: 6.6364,
    id: 3996,
  },
  {
    location_type: 'zip_code',
    location: '1424',
    location_name: 'Champagne',
    latitude: 46.8357,
    longitude: 6.6535,
    id: 3997,
  },
  {
    location_type: 'zip_code',
    location: '1425',
    location_name: 'Onnens VD',
    latitude: 46.8441,
    longitude: 6.6876,
    id: 3998,
  },
  {
    location_type: 'zip_code',
    location: '1426',
    location_name: 'Concise',
    latitude: 46.8637,
    longitude: 6.7256,
    id: 3999,
  },
  {
    location_type: 'zip_code',
    location: '1427',
    location_name: 'Bonvillars',
    latitude: 46.8419,
    longitude: 6.6693,
    id: 4000,
  },
  {
    location_type: 'zip_code',
    location: '1428',
    location_name: 'Provence',
    latitude: 46.8921,
    longitude: 6.702,
    id: 4001,
  },
  {
    location_type: 'zip_code',
    location: '1429',
    location_name: 'Giez',
    latitude: 46.8138,
    longitude: 6.6145,
    id: 4002,
  },
  {
    location_type: 'zip_code',
    location: '1430',
    location_name: 'Orges',
    latitude: 46.8125,
    longitude: 6.5807,
    id: 4003,
  },
  {
    location_type: 'zip_code',
    location: '1431',
    location_name: 'Vugelles-La Mothe',
    latitude: 46.8255,
    longitude: 6.576,
    id: 4004,
  },
  {
    location_type: 'zip_code',
    location: '1432',
    location_name: 'Belmont-sur-Yverdon',
    latitude: 46.7379,
    longitude: 6.6246,
    id: 4005,
  },
  {
    location_type: 'zip_code',
    location: '1433',
    location_name: 'Suchy',
    latitude: 46.7209,
    longitude: 6.604,
    id: 4006,
  },
  {
    location_type: 'zip_code',
    location: '1434',
    location_name: 'Ependes VD',
    latitude: 46.7461,
    longitude: 6.5974,
    id: 4007,
  },
  {
    location_type: 'zip_code',
    location: '1435',
    location_name: 'Essert-Pittet',
    latitude: 46.7274,
    longitude: 6.5796,
    id: 4008,
  },
  {
    location_type: 'zip_code',
    location: '1436',
    location_name: 'Treycovagnes',
    latitude: 46.7726,
    longitude: 6.6112,
    id: 4009,
  },
  {
    location_type: 'zip_code',
    location: '1437',
    location_name: 'Susc\u00e9vaz',
    latitude: 46.7656,
    longitude: 6.5878,
    id: 4010,
  },
  {
    location_type: 'zip_code',
    location: '1438',
    location_name: 'Mathod',
    latitude: 46.7632,
    longitude: 6.5627,
    id: 4011,
  },
  {
    location_type: 'zip_code',
    location: '1439',
    location_name: 'Rances',
    latitude: 46.7651,
    longitude: 6.5149,
    id: 4012,
  },
  {
    location_type: 'zip_code',
    location: '1440',
    location_name: 'Montagny-Chamard',
    latitude: 46.7889,
    longitude: 6.55,
    id: 4013,
  },
  {
    location_type: 'zip_code',
    location: '1441',
    location_name: 'Valeyres-sous-Montagny',
    latitude: 46.8008,
    longitude: 6.6016,
    id: 4014,
  },
  {
    location_type: 'zip_code',
    location: '1442',
    location_name: 'Montagny-pr\u00e8s-Yverdon',
    latitude: 46.7894,
    longitude: 6.61,
    id: 4015,
  },
  {
    location_type: 'zip_code',
    location: '1443',
    location_name: 'Essert-sous-Champvent',
    latitude: 46.7941,
    longitude: 6.5841,
    id: 4016,
  },
  {
    location_type: 'zip_code',
    location: '1445',
    location_name: 'Vuiteboeuf',
    latitude: 46.8059,
    longitude: 6.5506,
    id: 4017,
  },
  {
    location_type: 'zip_code',
    location: '1446',
    location_name: 'Baulmes',
    latitude: 46.7873,
    longitude: 6.5034,
    id: 4018,
  },
  {
    location_type: 'zip_code',
    location: '1450',
    location_name: 'La Sagne (Ste-Croix)',
    latitude: 46.8038,
    longitude: 6.4936,
    id: 4019,
  },
  {
    location_type: 'zip_code',
    location: '1452',
    location_name: 'Les Rasses',
    latitude: 46.8393,
    longitude: 6.5356,
    id: 4020,
  },
  {
    location_type: 'zip_code',
    location: '1453',
    location_name: 'Mauborget',
    latitude: 46.8684,
    longitude: 6.6187,
    id: 4021,
  },
  {
    location_type: 'zip_code',
    location: '1454',
    location_name: 'La Vraconnaz',
    latitude: 46.8439,
    longitude: 6.4773,
    id: 4022,
  },
  {
    location_type: 'zip_code',
    location: '1462',
    location_name: 'Yvonand',
    latitude: 46.7893,
    longitude: 6.7311,
    id: 4023,
  },
  {
    location_type: 'zip_code',
    location: '1463',
    location_name: 'Rovray',
    latitude: 46.7875,
    longitude: 6.7672,
    id: 4024,
  },
  {
    location_type: 'zip_code',
    location: '1464',
    location_name: 'Ch\u00eane-P\u00c2quier',
    latitude: 46.7655,
    longitude: 6.771,
    id: 4025,
  },
  {
    location_type: 'zip_code',
    location: '1000',
    location_name: 'Lausanne 26',
    latitude: 46.5534,
    longitude: 6.6971,
    id: 4026,
  },
  {
    location_type: 'zip_code',
    location: '1001',
    location_name: 'Lausanne',
    latitude: 46.5224,
    longitude: 6.6303,
    id: 4027,
  },
  {
    location_type: 'zip_code',
    location: '1002',
    location_name: 'Lausanne',
    latitude: 46.5195,
    longitude: 6.6322,
    id: 4028,
  },
  {
    location_type: 'zip_code',
    location: '1003',
    location_name: 'Lausanne',
    latitude: 46.52,
    longitude: 6.63,
    id: 4029,
  },
  {
    location_type: 'zip_code',
    location: '1004',
    location_name: 'Lausanne',
    latitude: 46.5285,
    longitude: 6.6187,
    id: 4030,
  },
  {
    location_type: 'zip_code',
    location: '1005',
    location_name: 'Lausanne',
    latitude: 46.5199,
    longitude: 6.6425,
    id: 4031,
  },
  {
    location_type: 'zip_code',
    location: '1006',
    location_name: 'Lausanne',
    latitude: 46.5112,
    longitude: 6.6371,
    id: 4032,
  },
  {
    location_type: 'zip_code',
    location: '1007',
    location_name: 'Lausanne',
    latitude: 46.5177,
    longitude: 6.609,
    id: 4033,
  },
  {
    location_type: 'zip_code',
    location: '1008',
    location_name: 'Jouxtens-M\u00e9zery',
    latitude: 46.553,
    longitude: 6.5956,
    id: 4034,
  },
  {
    location_type: 'zip_code',
    location: '1010',
    location_name: 'Lausanne',
    latitude: 46.5362,
    longitude: 6.6589,
    id: 4035,
  },
  {
    location_type: 'zip_code',
    location: '1011',
    location_name: 'Lausanne',
    latitude: 46.5249,
    longitude: 6.6431,
    id: 4036,
  },
  {
    location_type: 'zip_code',
    location: '1012',
    location_name: 'Lausanne',
    latitude: 46.5263,
    longitude: 6.657,
    id: 4037,
  },
  {
    location_type: 'zip_code',
    location: '1014',
    location_name: 'Lausanne Adm cant VD',
    latitude: 46.5333,
    longitude: 6.6667,
    id: 4038,
  },
  {
    location_type: 'zip_code',
    location: '1017',
    location_name: 'Lausanne Charles Veillon SA',
    latitude: 46.5333,
    longitude: 6.6667,
    id: 4039,
  },
  {
    location_type: 'zip_code',
    location: '1018',
    location_name: 'Lausanne',
    latitude: 46.5379,
    longitude: 6.6293,
    id: 4040,
  },
  {
    location_type: 'zip_code',
    location: '1019',
    location_name: 'Lausanne Services sp\u00e9ciaux',
    latitude: 46.5218,
    longitude: 6.633,
    id: 4041,
  },
  {
    location_type: 'zip_code',
    location: '1032',
    location_name: 'Romanel-sur-Lausanne',
    latitude: 46.5639,
    longitude: 6.608,
    id: 4042,
  },
  {
    location_type: 'zip_code',
    location: '1033',
    location_name: 'Cheseaux-sur-Lausanne',
    latitude: 46.5825,
    longitude: 6.596,
    id: 4043,
  },
  {
    location_type: 'zip_code',
    location: '1039',
    location_name: 'Cheseaux Polyval',
    latitude: 46.5845,
    longitude: 6.5955,
    id: 4044,
  },
  {
    location_type: 'zip_code',
    location: '1052',
    location_name: 'Le Mont-sur-Lausanne',
    latitude: 46.5599,
    longitude: 6.64,
    id: 4045,
  },
  {
    location_type: 'zip_code',
    location: '1066',
    location_name: 'Epalinges',
    latitude: 46.5527,
    longitude: 6.6667,
    id: 4046,
  },
  {
    location_type: 'zip_code',
    location: '1009',
    location_name: 'Pully',
    latitude: 46.5124,
    longitude: 6.6567,
    id: 4047,
  },
  {
    location_type: 'zip_code',
    location: '1068',
    location_name: 'Les Monts-de-Pully',
    latitude: 46.5342,
    longitude: 6.6834,
    id: 4048,
  },
  {
    location_type: 'zip_code',
    location: '1070',
    location_name: 'Puidoux',
    latitude: 46.5123,
    longitude: 6.7879,
    id: 4049,
  },
  {
    location_type: 'zip_code',
    location: '1071',
    location_name: 'St-Saphorin (Lavaux)',
    latitude: 46.4748,
    longitude: 6.7968,
    id: 4050,
  },
  {
    location_type: 'zip_code',
    location: '1072',
    location_name: 'Forel (Lavaux)',
    latitude: 46.5404,
    longitude: 6.7634,
    id: 4051,
  },
  {
    location_type: 'zip_code',
    location: '1073',
    location_name: 'Savigny',
    latitude: 46.5382,
    longitude: 6.726,
    id: 4052,
  },
  {
    location_type: 'zip_code',
    location: '1076',
    location_name: 'Ferlens VD',
    latitude: 46.5901,
    longitude: 6.7875,
    id: 4053,
  },
  {
    location_type: 'zip_code',
    location: '1077',
    location_name: 'Servion',
    latitude: 46.5744,
    longitude: 6.7796,
    id: 4054,
  },
  {
    location_type: 'zip_code',
    location: '1078',
    location_name: 'Essertes',
    latitude: 46.5619,
    longitude: 6.7877,
    id: 4055,
  },
  {
    location_type: 'zip_code',
    location: '1080',
    location_name: 'Les Cullayes',
    latitude: 46.5719,
    longitude: 6.7559,
    id: 4056,
  },
  {
    location_type: 'zip_code',
    location: '1081',
    location_name: 'Montpreveyres',
    latitude: 46.5821,
    longitude: 6.7383,
    id: 4057,
  },
  {
    location_type: 'zip_code',
    location: '1083',
    location_name: 'M\u00e9zi\u00e8res VD',
    latitude: 46.588,
    longitude: 6.7639,
    id: 4058,
  },
  {
    location_type: 'zip_code',
    location: '1090',
    location_name: 'La Croix (Lutry)',
    latitude: 46.5236,
    longitude: 6.7017,
    id: 4059,
  },
  {
    location_type: 'zip_code',
    location: '1091',
    location_name: 'Grandvaux',
    latitude: 46.5077,
    longitude: 6.7285,
    id: 4060,
  },
  {
    location_type: 'zip_code',
    location: '1092',
    location_name: 'Belmont-sur-Lausanne',
    latitude: 46.5288,
    longitude: 6.6907,
    id: 4061,
  },
  {
    location_type: 'zip_code',
    location: '1093',
    location_name: 'La Conversion',
    latitude: 46.5139,
    longitude: 6.6854,
    id: 4062,
  },
  {
    location_type: 'zip_code',
    location: '1094',
    location_name: 'Paudex',
    latitude: 46.5084,
    longitude: 6.6733,
    id: 4063,
  },
  {
    location_type: 'zip_code',
    location: '1095',
    location_name: 'Lutry',
    latitude: 46.5065,
    longitude: 6.6923,
    id: 4064,
  },
  {
    location_type: 'zip_code',
    location: '1096',
    location_name: 'Cully',
    latitude: 46.4881,
    longitude: 6.7271,
    id: 4065,
  },
  {
    location_type: 'zip_code',
    location: '1097',
    location_name: 'Riex',
    latitude: 46.5006,
    longitude: 6.7418,
    id: 4066,
  },
  {
    location_type: 'zip_code',
    location: '1098',
    location_name: 'Epesses',
    latitude: 46.4981,
    longitude: 6.7514,
    id: 4067,
  },
  {
    location_type: 'zip_code',
    location: '1099',
    location_name: 'Montpreveyres Fotolabo Club',
    latitude: 46.5083,
    longitude: 6.7,
    id: 4068,
  },
  {
    location_type: 'zip_code',
    location: '1607',
    location_name: 'Les Thioleyres',
    latitude: 46.5356,
    longitude: 6.8118,
    id: 4069,
  },
  {
    location_type: 'zip_code',
    location: '1610',
    location_name: 'Oron-la-Ville',
    latitude: 46.5695,
    longitude: 6.825,
    id: 4070,
  },
  {
    location_type: 'zip_code',
    location: '1612',
    location_name: 'Ecoteaux',
    latitude: 46.5409,
    longitude: 6.8596,
    id: 4071,
  },
  {
    location_type: 'zip_code',
    location: '1613',
    location_name: 'Maracon',
    latitude: 46.556,
    longitude: 6.8752,
    id: 4072,
  },
  {
    location_type: 'zip_code',
    location: '1026',
    location_name: 'Echandens',
    latitude: 46.5412,
    longitude: 6.5371,
    id: 4073,
  },
  {
    location_type: 'zip_code',
    location: '1027',
    location_name: 'Lonay',
    latitude: 46.5302,
    longitude: 6.519,
    id: 4074,
  },
  {
    location_type: 'zip_code',
    location: '1028',
    location_name: 'Pr\u00e9verenges',
    latitude: 46.5177,
    longitude: 6.5288,
    id: 4075,
  },
  {
    location_type: 'zip_code',
    location: '1110',
    location_name: 'Morges',
    latitude: 46.5135,
    longitude: 6.4949,
    id: 4076,
  },
  {
    location_type: 'zip_code',
    location: '1112',
    location_name: 'Echichens',
    latitude: 46.5301,
    longitude: 6.4942,
    id: 4077,
  },
  {
    location_type: 'zip_code',
    location: '1113',
    location_name: 'St-Saphorin-sur-Morges',
    latitude: 46.5445,
    longitude: 6.4904,
    id: 4078,
  },
  {
    location_type: 'zip_code',
    location: '1114',
    location_name: 'Colombier VD',
    latitude: 46.5557,
    longitude: 6.4702,
    id: 4079,
  },
  {
    location_type: 'zip_code',
    location: '1115',
    location_name: 'Vullierens',
    latitude: 46.5759,
    longitude: 6.4786,
    id: 4080,
  },
  {
    location_type: 'zip_code',
    location: '1116',
    location_name: 'Cottens VD',
    latitude: 46.572,
    longitude: 6.4533,
    id: 4081,
  },
  {
    location_type: 'zip_code',
    location: '1117',
    location_name: 'Grancy',
    latitude: 46.5939,
    longitude: 6.4604,
    id: 4082,
  },
  {
    location_type: 'zip_code',
    location: '1121',
    location_name: 'Bremblens',
    latitude: 46.5501,
    longitude: 6.5206,
    id: 4083,
  },
  {
    location_type: 'zip_code',
    location: '1122',
    location_name: 'Romanel-sur-Morges',
    latitude: 46.5555,
    longitude: 6.5091,
    id: 4084,
  },
  {
    location_type: 'zip_code',
    location: '1123',
    location_name: 'Aclens',
    latitude: 46.5687,
    longitude: 6.5091,
    id: 4085,
  },
  {
    location_type: 'zip_code',
    location: '1124',
    location_name: 'Gollion',
    latitude: 46.5867,
    longitude: 6.51,
    id: 4086,
  },
  {
    location_type: 'zip_code',
    location: '1125',
    location_name: 'Monnaz',
    latitude: 46.5313,
    longitude: 6.4798,
    id: 4087,
  },
  {
    location_type: 'zip_code',
    location: '1126',
    location_name: 'Vaux-sur-Morges',
    latitude: 46.5377,
    longitude: 6.4638,
    id: 4088,
  },
  {
    location_type: 'zip_code',
    location: '1127',
    location_name: 'Clarmont',
    latitude: 46.5487,
    longitude: 6.4496,
    id: 4089,
  },
  {
    location_type: 'zip_code',
    location: '1128',
    location_name: 'Reverolle',
    latitude: 46.5428,
    longitude: 6.4405,
    id: 4090,
  },
  {
    location_type: 'zip_code',
    location: '1131',
    location_name: 'Tolochenaz',
    latitude: 46.5026,
    longitude: 6.4773,
    id: 4091,
  },
  {
    location_type: 'zip_code',
    location: '1132',
    location_name: 'Lully VD',
    latitude: 46.5055,
    longitude: 6.4643,
    id: 4092,
  },
  {
    location_type: 'zip_code',
    location: '1134',
    location_name: 'Chigny',
    latitude: 46.5156,
    longitude: 6.4776,
    id: 4093,
  },
  {
    location_type: 'zip_code',
    location: '1135',
    location_name: 'Denens',
    latitude: 46.5177,
    longitude: 6.4529,
    id: 4094,
  },
  {
    location_type: 'zip_code',
    location: '1136',
    location_name: 'Bussy-Chardonney',
    latitude: 46.531,
    longitude: 6.4377,
    id: 4095,
  },
  {
    location_type: 'zip_code',
    location: '1141',
    location_name: 'S\u00e9very',
    latitude: 46.5726,
    longitude: 6.4424,
    id: 4096,
  },
  {
    location_type: 'zip_code',
    location: '1142',
    location_name: 'Pampigny',
    latitude: 46.5846,
    longitude: 6.4165,
    id: 4097,
  },
  {
    location_type: 'zip_code',
    location: '1143',
    location_name: 'Apples',
    latitude: 46.5555,
    longitude: 6.4187,
    id: 4098,
  },
  {
    location_type: 'zip_code',
    location: '1144',
    location_name: 'Ballens',
    latitude: 46.5537,
    longitude: 6.3781,
    id: 4099,
  },
  {
    location_type: 'zip_code',
    location: '1145',
    location_name: 'Bi\u00e8re',
    latitude: 46.5453,
    longitude: 6.319,
    id: 4100,
  },
  {
    location_type: 'zip_code',
    location: '1146',
    location_name: 'Mollens VD',
    latitude: 46.5759,
    longitude: 6.3631,
    id: 4101,
  },
  {
    location_type: 'zip_code',
    location: '1147',
    location_name: 'Montricher',
    latitude: 46.603,
    longitude: 6.3575,
    id: 4102,
  },
  {
    location_type: 'zip_code',
    location: '1149',
    location_name: 'Berolle',
    latitude: 46.5664,
    longitude: 6.3321,
    id: 4103,
  },
  {
    location_type: 'zip_code',
    location: '1162',
    location_name: 'St-Prex',
    latitude: 46.4852,
    longitude: 6.4474,
    id: 4104,
  },
  {
    location_type: 'zip_code',
    location: '1163',
    location_name: 'Etoy',
    latitude: 46.4832,
    longitude: 6.4216,
    id: 4105,
  },
  {
    location_type: 'zip_code',
    location: '1164',
    location_name: 'Buchillon',
    latitude: 46.4667,
    longitude: 6.4093,
    id: 4106,
  },
  {
    location_type: 'zip_code',
    location: '1165',
    location_name: 'Allaman',
    latitude: 46.4703,
    longitude: 6.3932,
    id: 4107,
  },
  {
    location_type: 'zip_code',
    location: '1167',
    location_name: 'Lussy-sur-Morges',
    latitude: 46.5014,
    longitude: 6.4483,
    id: 4108,
  },
  {
    location_type: 'zip_code',
    location: '1168',
    location_name: 'Villars-sous-Yens',
    latitude: 46.5059,
    longitude: 6.4298,
    id: 4109,
  },
  {
    location_type: 'zip_code',
    location: '1169',
    location_name: 'Yens',
    latitude: 46.5252,
    longitude: 6.4055,
    id: 4110,
  },
  {
    location_type: 'zip_code',
    location: '1170',
    location_name: 'Aubonne',
    latitude: 46.4948,
    longitude: 6.3817,
    id: 4111,
  },
  {
    location_type: 'zip_code',
    location: '1172',
    location_name: 'Bougy-Villars',
    latitude: 46.4805,
    longitude: 6.3524,
    id: 4112,
  },
  {
    location_type: 'zip_code',
    location: '1173',
    location_name: 'F\u00e9chy',
    latitude: 46.4763,
    longitude: 6.374,
    id: 4113,
  },
  {
    location_type: 'zip_code',
    location: '1174',
    location_name: 'Montherod',
    latitude: 46.5061,
    longitude: 6.3536,
    id: 4114,
  },
  {
    location_type: 'zip_code',
    location: '1175',
    location_name: 'Lavigny',
    latitude: 46.4984,
    longitude: 6.4089,
    id: 4115,
  },
  {
    location_type: 'zip_code',
    location: '1176',
    location_name: 'St-Livres',
    latitude: 46.5163,
    longitude: 6.3815,
    id: 4116,
  },
  {
    location_type: 'zip_code',
    location: '1187',
    location_name: 'St-Oyens',
    latitude: 46.4942,
    longitude: 6.2974,
    id: 4117,
  },
  {
    location_type: 'zip_code',
    location: '1188',
    location_name: 'Gimel',
    latitude: 46.5212,
    longitude: 6.2886,
    id: 4118,
  },
  {
    location_type: 'zip_code',
    location: '1189',
    location_name: 'Saubraz',
    latitude: 46.519,
    longitude: 6.3256,
    id: 4119,
  },
  {
    location_type: 'zip_code',
    location: '1300',
    location_name:
      'Ecl\u00e9pens CC D\u00e9p\u00f4t',
    latitude: 46.6515,
    longitude: 6.5429,
    id: 4120,
  },
  {
    location_type: 'zip_code',
    location: '1304',
    location_name: 'Allens',
    latitude: 46.5981,
    longitude: 6.5094,
    id: 4121,
  },
  {
    location_type: 'zip_code',
    location: '1308',
    location_name: 'La Chaux-sur-Cossonay',
    latitude: 46.6202,
    longitude: 6.4671,
    id: 4122,
  },
  {
    location_type: 'zip_code',
    location: '1311',
    location_name: 'Ecl\u00e9pens Scanning-Center',
    latitude: 46.6515,
    longitude: 6.5429,
    id: 4123,
  },
  {
    location_type: 'zip_code',
    location: '1312',
    location_name: 'Ecl\u00e9pens',
    latitude: 46.6521,
    longitude: 6.5397,
    id: 4124,
  },
  {
    location_type: 'zip_code',
    location: '1313',
    location_name: 'Ferreyres',
    latitude: 46.66,
    longitude: 6.4809,
    id: 4125,
  },
  {
    location_type: 'zip_code',
    location: '1315',
    location_name: 'La Sarraz',
    latitude: 46.6569,
    longitude: 6.5195,
    id: 4126,
  },
  {
    location_type: 'zip_code',
    location: '1316',
    location_name: 'Chevilly',
    latitude: 46.6432,
    longitude: 6.4722,
    id: 4127,
  },
  {
    location_type: 'zip_code',
    location: '1317',
    location_name: 'Orny',
    latitude: 46.6713,
    longitude: 6.5329,
    id: 4128,
  },
  {
    location_type: 'zip_code',
    location: '1318',
    location_name: 'Pompaples',
    latitude: 46.6783,
    longitude: 6.5221,
    id: 4129,
  },
  {
    location_type: 'zip_code',
    location: '1166',
    location_name: 'Perroy',
    latitude: 46.4693,
    longitude: 6.3602,
    id: 4130,
  },
  {
    location_type: 'zip_code',
    location: '1180',
    location_name: 'Rolle',
    latitude: 46.4606,
    longitude: 6.3323,
    id: 4131,
  },
  {
    location_type: 'zip_code',
    location: '1182',
    location_name: 'Gilly',
    latitude: 46.4621,
    longitude: 6.2998,
    id: 4132,
  },
  {
    location_type: 'zip_code',
    location: '1183',
    location_name: 'Bursins',
    latitude: 46.4487,
    longitude: 6.2888,
    id: 4133,
  },
  {
    location_type: 'zip_code',
    location: '1184',
    location_name: 'Vinzel',
    latitude: 46.4482,
    longitude: 6.2789,
    id: 4134,
  },
  {
    location_type: 'zip_code',
    location: '1185',
    location_name: 'Mont-sur-Rolle',
    latitude: 46.4775,
    longitude: 6.3378,
    id: 4135,
  },
  {
    location_type: 'zip_code',
    location: '1186',
    location_name: 'Essertines-sur-Rolle',
    latitude: 46.487,
    longitude: 6.3081,
    id: 4136,
  },
  {
    location_type: 'zip_code',
    location: '1195',
    location_name: 'Dully',
    latitude: 46.4314,
    longitude: 6.2946,
    id: 4137,
  },
  {
    location_type: 'zip_code',
    location: '1196',
    location_name: 'Gland',
    latitude: 46.4184,
    longitude: 6.2696,
    id: 4138,
  },
  {
    location_type: 'zip_code',
    location: '1197',
    location_name: 'Prangins',
    latitude: 46.4017,
    longitude: 6.2561,
    id: 4139,
  },
  {
    location_type: 'zip_code',
    location: '1260',
    location_name: 'Nyon',
    latitude: 46.3854,
    longitude: 6.2269,
    id: 4140,
  },
  {
    location_type: 'zip_code',
    location: '1261',
    location_name: 'Marchissy',
    latitude: 46.4934,
    longitude: 6.2322,
    id: 4141,
  },
  {
    location_type: 'zip_code',
    location: '1262',
    location_name: 'Eysins',
    latitude: 46.3795,
    longitude: 6.2054,
    id: 4142,
  },
  {
    location_type: 'zip_code',
    location: '1263',
    location_name: 'Crassier',
    latitude: 46.3759,
    longitude: 6.1687,
    id: 4143,
  },
  {
    location_type: 'zip_code',
    location: '1264',
    location_name: 'St-Cergue',
    latitude: 46.4509,
    longitude: 6.1503,
    id: 4144,
  },
  {
    location_type: 'zip_code',
    location: '1265',
    location_name: 'La Cure',
    latitude: 46.4545,
    longitude: 6.0951,
    id: 4145,
  },
  {
    location_type: 'zip_code',
    location: '1266',
    location_name: 'Duillier',
    latitude: 46.4106,
    longitude: 6.2314,
    id: 4146,
  },
  {
    location_type: 'zip_code',
    location: '1267',
    location_name: 'Coinsins',
    latitude: 46.4251,
    longitude: 6.2366,
    id: 4147,
  },
  {
    location_type: 'zip_code',
    location: '1268',
    location_name: 'Begnins',
    latitude: 46.4449,
    longitude: 6.2473,
    id: 4148,
  },
  {
    location_type: 'zip_code',
    location: '1269',
    location_name: 'Bassins',
    latitude: 46.4878,
    longitude: 6.2,
    id: 4149,
  },
  {
    location_type: 'zip_code',
    location: '1270',
    location_name: 'Tr\u00e9lex',
    latitude: 46.4185,
    longitude: 6.1951,
    id: 4150,
  },
  {
    location_type: 'zip_code',
    location: '1271',
    location_name: 'Givrins',
    latitude: 46.4331,
    longitude: 6.1943,
    id: 4151,
  },
  {
    location_type: 'zip_code',
    location: '1272',
    location_name: 'Genolier',
    latitude: 46.4343,
    longitude: 6.2218,
    id: 4152,
  },
  {
    location_type: 'zip_code',
    location: '1273',
    location_name: 'Arzier',
    latitude: 46.5232,
    longitude: 6.1611,
    id: 4153,
  },
  {
    location_type: 'zip_code',
    location: '1274',
    location_name: 'Grens',
    latitude: 46.3961,
    longitude: 6.1912,
    id: 4154,
  },
  {
    location_type: 'zip_code',
    location: '1275',
    location_name: 'Ch\u00e9serex',
    latitude: 46.4107,
    longitude: 6.1482,
    id: 4155,
  },
  {
    location_type: 'zip_code',
    location: '1276',
    location_name: 'Gingins',
    latitude: 46.4212,
    longitude: 6.1659,
    id: 4156,
  },
  {
    location_type: 'zip_code',
    location: '1277',
    location_name: 'Borex',
    latitude: 46.3805,
    longitude: 6.1825,
    id: 4157,
  },
  {
    location_type: 'zip_code',
    location: '1278',
    location_name: 'La Rippe',
    latitude: 46.4048,
    longitude: 6.1199,
    id: 4158,
  },
  {
    location_type: 'zip_code',
    location: '1279',
    location_name: 'Chavannes-de-Bogis',
    latitude: 46.3395,
    longitude: 6.1534,
    id: 4159,
  },
  {
    location_type: 'zip_code',
    location: '1291',
    location_name: 'Commugny',
    latitude: 46.3236,
    longitude: 6.1644,
    id: 4160,
  },
  {
    location_type: 'zip_code',
    location: '1295',
    location_name: 'Mies',
    latitude: 46.3061,
    longitude: 6.1584,
    id: 4161,
  },
  {
    location_type: 'zip_code',
    location: '1296',
    location_name: 'Coppet',
    latitude: 46.3207,
    longitude: 6.1892,
    id: 4162,
  },
  {
    location_type: 'zip_code',
    location: '1297',
    location_name: 'Founex',
    latitude: 46.3362,
    longitude: 6.1837,
    id: 4163,
  },
  {
    location_type: 'zip_code',
    location: '1299',
    location_name: 'Crans-pr\u00e8s-C\u00e9ligny',
    latitude: 46.3635,
    longitude: 6.2032,
    id: 4164,
  },
  {
    location_type: 'zip_code',
    location: '1015',
    location_name: 'Lausanne',
    latitude: 46.5211,
    longitude: 6.5759,
    id: 4165,
  },
  {
    location_type: 'zip_code',
    location: '1020',
    location_name: 'Renens VD',
    latitude: 46.5364,
    longitude: 6.5895,
    id: 4166,
  },
  {
    location_type: 'zip_code',
    location: '1022',
    location_name: 'Chavannes-pr\u00e9s-Renens',
    latitude: 46.5304,
    longitude: 6.5766,
    id: 4167,
  },
  {
    location_type: 'zip_code',
    location: '1023',
    location_name: 'Crissier',
    latitude: 46.5553,
    longitude: 6.5775,
    id: 4168,
  },
  {
    location_type: 'zip_code',
    location: '1024',
    location_name: 'Ecublens VD',
    latitude: 46.5287,
    longitude: 6.56,
    id: 4169,
  },
  {
    location_type: 'zip_code',
    location: '1025',
    location_name: 'St-Sulpice VD',
    latitude: 46.5132,
    longitude: 6.5562,
    id: 4170,
  },
  {
    location_type: 'zip_code',
    location: '1029',
    location_name: 'Villars-Ste-Croix',
    latitude: 46.5656,
    longitude: 6.5633,
    id: 4171,
  },
  {
    location_type: 'zip_code',
    location: '1030',
    location_name: 'Bussigny-pr\u00e9s-Lausanne',
    latitude: 46.5542,
    longitude: 6.5474,
    id: 4172,
  },
  {
    location_type: 'zip_code',
    location: '1658',
    location_name: 'La Tine',
    latitude: 46.472,
    longitude: 7.0478,
    id: 4173,
  },
  {
    location_type: 'zip_code',
    location: '1659',
    location_name: 'Flendruz',
    latitude: 46.5146,
    longitude: 7.172,
    id: 4174,
  },
  {
    location_type: 'zip_code',
    location: '1660',
    location_name: 'Ch\u00c2teau-d\'Oex',
    latitude: 46.4749,
    longitude: 7.1503,
    id: 4175,
  },
  {
    location_type: 'zip_code',
    location: '1800',
    location_name: 'Vevey',
    latitude: 46.4643,
    longitude: 6.8479,
    id: 4176,
  },
  {
    location_type: 'zip_code',
    location: '1801',
    location_name: 'Le Mont-P\u00e8lerin',
    latitude: 46.4954,
    longitude: 6.8236,
    id: 4177,
  },
  {
    location_type: 'zip_code',
    location: '1802',
    location_name: 'Corseaux',
    latitude: 46.471,
    longitude: 6.8235,
    id: 4178,
  },
  {
    location_type: 'zip_code',
    location: '1803',
    location_name: 'Chardonne',
    latitude: 46.477,
    longitude: 6.8198,
    id: 4179,
  },
  {
    location_type: 'zip_code',
    location: '1804',
    location_name: 'Corsier-sur-Vevey',
    latitude: 46.4769,
    longitude: 6.8508,
    id: 4180,
  },
  {
    location_type: 'zip_code',
    location: '1805',
    location_name: 'Jongny',
    latitude: 46.4906,
    longitude: 6.8469,
    id: 4181,
  },
  {
    location_type: 'zip_code',
    location: '1806',
    location_name: 'St-L\u00e9gier-La Chi\u00e9saz',
    latitude: 46.5008,
    longitude: 6.9075,
    id: 4182,
  },
  {
    location_type: 'zip_code',
    location: '1807',
    location_name: 'Blonay',
    latitude: 46.4759,
    longitude: 6.9083,
    id: 4183,
  },
  {
    location_type: 'zip_code',
    location: '1808',
    location_name: 'Les Monts-de-Corsier',
    latitude: 46.5085,
    longitude: 6.874,
    id: 4184,
  },
  {
    location_type: 'zip_code',
    location: '1809',
    location_name: 'Fenil-sur-Corsier',
    latitude: 46.4858,
    longitude: 6.8666,
    id: 4185,
  },
  {
    location_type: 'zip_code',
    location: '1811',
    location_name: 'Vevey Services sp\u00e9ciaux',
    latitude: 46.45,
    longitude: 6.8667,
    id: 4186,
  },
  {
    location_type: 'zip_code',
    location: '1814',
    location_name: 'La Tour-de-Peilz',
    latitude: 46.455,
    longitude: 6.8677,
    id: 4187,
  },
  {
    location_type: 'zip_code',
    location: '1815',
    location_name: 'Clarens',
    latitude: 46.4461,
    longitude: 6.8898,
    id: 4188,
  },
  {
    location_type: 'zip_code',
    location: '1816',
    location_name: 'Chailly-Montreux',
    latitude: 46.4529,
    longitude: 6.8918,
    id: 4189,
  },
  {
    location_type: 'zip_code',
    location: '1817',
    location_name: 'Brent',
    latitude: 46.4528,
    longitude: 6.9039,
    id: 4190,
  },
  {
    location_type: 'zip_code',
    location: '1818',
    location_name: 'Montreux La Redoute',
    latitude: 46.45,
    longitude: 6.8667,
    id: 4191,
  },
  {
    location_type: 'zip_code',
    location: '1820',
    location_name: 'Territet',
    latitude: 46.4253,
    longitude: 6.9257,
    id: 4192,
  },
  {
    location_type: 'zip_code',
    location: '1822',
    location_name: 'Chernex',
    latitude: 46.4382,
    longitude: 6.92,
    id: 4193,
  },
  {
    location_type: 'zip_code',
    location: '1823',
    location_name: 'Glion',
    latitude: 46.4393,
    longitude: 6.9339,
    id: 4194,
  },
  {
    location_type: 'zip_code',
    location: '1824',
    location_name: 'Caux',
    latitude: 46.4428,
    longitude: 6.9686,
    id: 4195,
  },
  {
    location_type: 'zip_code',
    location: '1832',
    location_name: 'Villard-sur-Chamby',
    latitude: 46.4675,
    longitude: 6.9313,
    id: 4196,
  },
  {
    location_type: 'zip_code',
    location: '1833',
    location_name: 'Les Avants',
    latitude: 46.4667,
    longitude: 6.9539,
    id: 4197,
  },
  {
    location_type: 'zip_code',
    location: '3900',
    location_name: 'Brig',
    latitude: 46.2896,
    longitude: 7.9745,
    id: 4198,
  },
  {
    location_type: 'zip_code',
    location: '3901',
    location_name: 'Rothwald',
    latitude: 46.2715,
    longitude: 8.0786,
    id: 4199,
  },
  {
    location_type: 'zip_code',
    location: '3902',
    location_name: 'Glis',
    latitude: 46.3103,
    longitude: 7.9763,
    id: 4200,
  },
  {
    location_type: 'zip_code',
    location: '3903',
    location_name: 'Mund',
    latitude: 46.3585,
    longitude: 7.9217,
    id: 4201,
  },
  {
    location_type: 'zip_code',
    location: '3904',
    location_name: 'Naters',
    latitude: 46.3371,
    longitude: 7.9823,
    id: 4202,
  },
  {
    location_type: 'zip_code',
    location: '3907',
    location_name: 'Simplon Hospiz',
    latitude: 46.1917,
    longitude: 8.0917,
    id: 4203,
  },
  {
    location_type: 'zip_code',
    location: '3911',
    location_name: 'Ried-Brig',
    latitude: 46.3087,
    longitude: 8.0239,
    id: 4204,
  },
  {
    location_type: 'zip_code',
    location: '3912',
    location_name: 'Termen',
    latitude: 46.3304,
    longitude: 8.046,
    id: 4205,
  },
  {
    location_type: 'zip_code',
    location: '3913',
    location_name: 'Rosswald',
    latitude: 46.3143,
    longitude: 8.0787,
    id: 4206,
  },
  {
    location_type: 'zip_code',
    location: '3914',
    location_name: 'Blatten b. Naters',
    latitude: 46.3634,
    longitude: 7.972,
    id: 4207,
  },
  {
    location_type: 'zip_code',
    location: '3939',
    location_name: 'Eggerberg',
    latitude: 46.3187,
    longitude: 7.8873,
    id: 4208,
  },
  {
    location_type: 'zip_code',
    location: '1911',
    location_name: 'Mayens-de-Chamoson',
    latitude: 46.2211,
    longitude: 7.1823,
    id: 4209,
  },
  {
    location_type: 'zip_code',
    location: '1955',
    location_name: 'Grugnay (Chamoson)',
    latitude: 46.2078,
    longitude: 7.2138,
    id: 4210,
  },
  {
    location_type: 'zip_code',
    location: '1957',
    location_name: 'Ardon',
    latitude: 46.2353,
    longitude: 7.2388,
    id: 4211,
  },
  {
    location_type: 'zip_code',
    location: '1963',
    location_name: 'V\u00e9troz',
    latitude: 46.2184,
    longitude: 7.2607,
    id: 4212,
  },
  {
    location_type: 'zip_code',
    location: '1964',
    location_name: 'Conthey',
    latitude: 46.2179,
    longitude: 7.3022,
    id: 4213,
  },
  {
    location_type: 'zip_code',
    location: '1975',
    location_name: 'St-S\u00e9verin',
    latitude: 46.2387,
    longitude: 7.3029,
    id: 4214,
  },
  {
    location_type: 'zip_code',
    location: '1976',
    location_name: 'Erde',
    latitude: 46.287,
    longitude: 7.2964,
    id: 4215,
  },
  {
    location_type: 'zip_code',
    location: '1993',
    location_name: 'Cl\u00e8bes (Nendaz)',
    latitude: 46.1871,
    longitude: 7.3373,
    id: 4216,
  },
  {
    location_type: 'zip_code',
    location: '1994',
    location_name: 'Aproz (Nendaz)',
    latitude: 46.2052,
    longitude: 7.3107,
    id: 4217,
  },
  {
    location_type: 'zip_code',
    location: '1996',
    location_name: 'Bieudron (Nendaz)',
    latitude: 46.194,
    longitude: 7.2798,
    id: 4218,
  },
  {
    location_type: 'zip_code',
    location: '1997',
    location_name: 'Siviez (Nendaz)',
    latitude: 46.1113,
    longitude: 7.321,
    id: 4219,
  },
  {
    location_type: 'zip_code',
    location: '1927',
    location_name: 'Chemin',
    latitude: 46.0878,
    longitude: 7.0778,
    id: 4220,
  },
  {
    location_type: 'zip_code',
    location: '1933',
    location_name: 'Chamoille (Sembrancher)',
    latitude: 46.0613,
    longitude: 7.1558,
    id: 4221,
  },
  {
    location_type: 'zip_code',
    location: '1934',
    location_name: 'Cotterg (Le Ch\u00c2ble VS)',
    latitude: 46.0883,
    longitude: 7.2025,
    id: 4222,
  },
  {
    location_type: 'zip_code',
    location: '1936',
    location_name: 'Verbier',
    latitude: 46.0984,
    longitude: 7.2252,
    id: 4223,
  },
  {
    location_type: 'zip_code',
    location: '1937',
    location_name: 'Orsi\u00e8res',
    latitude: 46.0285,
    longitude: 7.165,
    id: 4224,
  },
  {
    location_type: 'zip_code',
    location: '1938',
    location_name: 'Champex-Lac',
    latitude: 46.0383,
    longitude: 7.0961,
    id: 4225,
  },
  {
    location_type: 'zip_code',
    location: '1941',
    location_name: 'Cries (Voll\u00e8ges)',
    latitude: 46.0932,
    longitude: 7.1749,
    id: 4226,
  },
  {
    location_type: 'zip_code',
    location: '1942',
    location_name: 'Levron',
    latitude: 46.1034,
    longitude: 7.1603,
    id: 4227,
  },
  {
    location_type: 'zip_code',
    location: '1943',
    location_name: 'Praz-de-Fort',
    latitude: 45.9827,
    longitude: 7.0898,
    id: 4228,
  },
  {
    location_type: 'zip_code',
    location: '1944',
    location_name: 'La Fouly VS',
    latitude: 45.9294,
    longitude: 7.0811,
    id: 4229,
  },
  {
    location_type: 'zip_code',
    location: '1945',
    location_name: 'Chandonne (Liddes)',
    latitude: 46.0125,
    longitude: 7.1874,
    id: 4230,
  },
  {
    location_type: 'zip_code',
    location: '1946',
    location_name: 'Bourg-St-Pierre',
    latitude: 45.9165,
    longitude: 7.2139,
    id: 4231,
  },
  {
    location_type: 'zip_code',
    location: '1947',
    location_name: 'Bagnes',
    latitude: 46.0748,
    longitude: 7.2283,
    id: 4232,
  },
  {
    location_type: 'zip_code',
    location: '1948',
    location_name: 'Fionnay',
    latitude: 45.9715,
    longitude: 7.3649,
    id: 4233,
  },
  {
    location_type: 'zip_code',
    location: '3801',
    location_name: 'Jungfraujoch',
    latitude: 46.5474,
    longitude: 7.9833,
    id: 4234,
  },
  {
    location_type: 'zip_code',
    location: '3984',
    location_name: 'Fiesch',
    latitude: 46.415,
    longitude: 8.1423,
    id: 4235,
  },
  {
    location_type: 'zip_code',
    location: '3985',
    location_name: 'M\u00fcnster VS',
    latitude: 46.4833,
    longitude: 8.2712,
    id: 4236,
  },
  {
    location_type: 'zip_code',
    location: '3988',
    location_name: 'Ulrichen',
    latitude: 46.449,
    longitude: 8.3254,
    id: 4237,
  },
  {
    location_type: 'zip_code',
    location: '3989',
    location_name: 'Blitzingen',
    latitude: 46.4428,
    longitude: 8.1979,
    id: 4238,
  },
  {
    location_type: 'zip_code',
    location: '3994',
    location_name: 'Lax',
    latitude: 46.3979,
    longitude: 8.1056,
    id: 4239,
  },
  {
    location_type: 'zip_code',
    location: '3995',
    location_name: 'Ernen',
    latitude: 46.399,
    longitude: 8.1561,
    id: 4240,
  },
  {
    location_type: 'zip_code',
    location: '3996',
    location_name: 'Binn',
    latitude: 46.3586,
    longitude: 8.2196,
    id: 4241,
  },
  {
    location_type: 'zip_code',
    location: '3997',
    location_name: 'Bellwald',
    latitude: 46.4433,
    longitude: 8.1568,
    id: 4242,
  },
  {
    location_type: 'zip_code',
    location: '3998',
    location_name: 'Gluringen',
    latitude: 46.4737,
    longitude: 8.2191,
    id: 4243,
  },
  {
    location_type: 'zip_code',
    location: '3999',
    location_name: 'Oberwald',
    latitude: 46.5727,
    longitude: 8.3962,
    id: 4244,
  },
  {
    location_type: 'zip_code',
    location: '1966',
    location_name: 'Blignoud (Ayent)',
    latitude: 46.2714,
    longitude: 7.3972,
    id: 4245,
  },
  {
    location_type: 'zip_code',
    location: '1969',
    location_name: 'Trogne (St-Martin)',
    latitude: 46.1741,
    longitude: 7.4721,
    id: 4246,
  },
  {
    location_type: 'zip_code',
    location: '1972',
    location_name: 'Anz\u00e8re',
    latitude: 46.3356,
    longitude: 7.4099,
    id: 4247,
  },
  {
    location_type: 'zip_code',
    location: '1981',
    location_name: 'Vex',
    latitude: 46.2089,
    longitude: 7.3972,
    id: 4248,
  },
  {
    location_type: 'zip_code',
    location: '1982',
    location_name: 'Euseigne',
    latitude: 46.144,
    longitude: 7.4294,
    id: 4249,
  },
  {
    location_type: 'zip_code',
    location: '1983',
    location_name: 'Lana (Evol\u00e8ne)',
    latitude: 46.1099,
    longitude: 7.4531,
    id: 4250,
  },
  {
    location_type: 'zip_code',
    location: '1984',
    location_name: 'Les Haud\u00e8res',
    latitude: 46.079,
    longitude: 7.4757,
    id: 4251,
  },
  {
    location_type: 'zip_code',
    location: '1985',
    location_name: 'La Forclaz VS',
    latitude: 46.1306,
    longitude: 7.4528,
    id: 4252,
  },
  {
    location_type: 'zip_code',
    location: '1986',
    location_name: 'Arolla',
    latitude: 46.0163,
    longitude: 7.5182,
    id: 4253,
  },
  {
    location_type: 'zip_code',
    location: '1987',
    location_name: 'H\u00e9r\u00e9mence',
    latitude: 46.0746,
    longitude: 7.3936,
    id: 4254,
  },
  {
    location_type: 'zip_code',
    location: '1988',
    location_name: 'Thyon',
    latitude: 46.176,
    longitude: 7.3751,
    id: 4255,
  },
  {
    location_type: 'zip_code',
    location: '1992',
    location_name: 'Les Agettes',
    latitude: 46.2067,
    longitude: 7.3716,
    id: 4256,
  },
  {
    location_type: 'zip_code',
    location: '3945',
    location_name: 'Niedergampel',
    latitude: 46.318,
    longitude: 7.7123,
    id: 4257,
  },
  {
    location_type: 'zip_code',
    location: '3946',
    location_name: 'Gruben',
    latitude: 46.2061,
    longitude: 7.7038,
    id: 4258,
  },
  {
    location_type: 'zip_code',
    location: '3947',
    location_name: 'Ergisch',
    latitude: 46.2534,
    longitude: 7.7305,
    id: 4259,
  },
  {
    location_type: 'zip_code',
    location: '3948',
    location_name: 'Oberems',
    latitude: 46.1585,
    longitude: 7.7037,
    id: 4260,
  },
  {
    location_type: 'zip_code',
    location: '3951',
    location_name: 'Agarn',
    latitude: 46.2756,
    longitude: 7.6642,
    id: 4261,
  },
  {
    location_type: 'zip_code',
    location: '3952',
    location_name: 'Susten',
    latitude: 46.2826,
    longitude: 7.6269,
    id: 4262,
  },
  {
    location_type: 'zip_code',
    location: '3953',
    location_name: 'Varen',
    latitude: 46.3204,
    longitude: 7.5928,
    id: 4263,
  },
  {
    location_type: 'zip_code',
    location: '3954',
    location_name: 'Leukerbad',
    latitude: 46.3983,
    longitude: 7.6153,
    id: 4264,
  },
  {
    location_type: 'zip_code',
    location: '3955',
    location_name: 'Albinen',
    latitude: 46.3536,
    longitude: 7.6431,
    id: 4265,
  },
  {
    location_type: 'zip_code',
    location: '3956',
    location_name: 'Guttet-Feschel',
    latitude: 46.346,
    longitude: 7.6761,
    id: 4266,
  },
  {
    location_type: 'zip_code',
    location: '3957',
    location_name: 'Bratsch',
    latitude: 46.3216,
    longitude: 7.7054,
    id: 4267,
  },
  {
    location_type: 'zip_code',
    location: '3970',
    location_name: 'Salgesch',
    latitude: 46.3142,
    longitude: 7.5722,
    id: 4268,
  },
  {
    location_type: 'zip_code',
    location: '1906',
    location_name: 'Charrat',
    latitude: 46.1205,
    longitude: 7.1336,
    id: 4269,
  },
  {
    location_type: 'zip_code',
    location: '1907',
    location_name: 'Saxon',
    latitude: 46.1376,
    longitude: 7.1821,
    id: 4270,
  },
  {
    location_type: 'zip_code',
    location: '1908',
    location_name: 'Riddes',
    latitude: 46.1671,
    longitude: 7.2153,
    id: 4271,
  },
  {
    location_type: 'zip_code',
    location: '1912',
    location_name: 'Montagnon (Leytron)',
    latitude: 46.1917,
    longitude: 7.1923,
    id: 4272,
  },
  {
    location_type: 'zip_code',
    location: '1913',
    location_name: 'Saillon',
    latitude: 46.1799,
    longitude: 7.1491,
    id: 4273,
  },
  {
    location_type: 'zip_code',
    location: '1914',
    location_name: 'Auddes-sur-Riddes',
    latitude: 46.1568,
    longitude: 7.2384,
    id: 4274,
  },
  {
    location_type: 'zip_code',
    location: '1918',
    location_name: 'La Tzoumaz',
    latitude: 46.1338,
    longitude: 7.2467,
    id: 4275,
  },
  {
    location_type: 'zip_code',
    location: '1919',
    location_name: 'Martigny Groupe Mutuel',
    latitude: 46.1072,
    longitude: 7.081,
    id: 4276,
  },
  {
    location_type: 'zip_code',
    location: '1920',
    location_name: 'Martigny',
    latitude: 46.1063,
    longitude: 7.0993,
    id: 4277,
  },
  {
    location_type: 'zip_code',
    location: '1921',
    location_name: 'Martigny-Croix',
    latitude: 46.0615,
    longitude: 7.0306,
    id: 4278,
  },
  {
    location_type: 'zip_code',
    location: '1926',
    location_name: 'Fully',
    latitude: 46.1551,
    longitude: 7.1172,
    id: 4279,
  },
  {
    location_type: 'zip_code',
    location: '1928',
    location_name: 'Ravoire',
    latitude: 46.099,
    longitude: 7.0406,
    id: 4280,
  },
  {
    location_type: 'zip_code',
    location: '1929',
    location_name: 'Trient',
    latitude: 46.0346,
    longitude: 6.9967,
    id: 4281,
  },
  {
    location_type: 'zip_code',
    location: '1932',
    location_name: 'Les Valettes (Bovernier)',
    latitude: 46.0772,
    longitude: 7.0732,
    id: 4282,
  },
  {
    location_type: 'zip_code',
    location: '1868',
    location_name: 'Collombey',
    latitude: 46.2606,
    longitude: 6.9332,
    id: 4283,
  },
  {
    location_type: 'zip_code',
    location: '1870',
    location_name: 'Monthey',
    latitude: 46.2399,
    longitude: 6.8893,
    id: 4284,
  },
  {
    location_type: 'zip_code',
    location: '1871',
    location_name: 'Cho\u00ebx',
    latitude: 46.2395,
    longitude: 6.9545,
    id: 4285,
  },
  {
    location_type: 'zip_code',
    location: '1872',
    location_name: 'Troistorrents',
    latitude: 46.2282,
    longitude: 6.9044,
    id: 4286,
  },
  {
    location_type: 'zip_code',
    location: '1873',
    location_name: 'Champoussin',
    latitude: 46.2065,
    longitude: 6.8592,
    id: 4287,
  },
  {
    location_type: 'zip_code',
    location: '1874',
    location_name: 'Champ\u00e9ry',
    latitude: 46.1597,
    longitude: 6.8491,
    id: 4288,
  },
  {
    location_type: 'zip_code',
    location: '1875',
    location_name: 'Morgins',
    latitude: 46.2411,
    longitude: 6.8686,
    id: 4289,
  },
  {
    location_type: 'zip_code',
    location: '1893',
    location_name: 'Collombey',
    latitude: 46.288,
    longitude: 6.899,
    id: 4290,
  },
  {
    location_type: 'zip_code',
    location: '1895',
    location_name: 'Vionnaz',
    latitude: 46.3069,
    longitude: 6.8995,
    id: 4291,
  },
  {
    location_type: 'zip_code',
    location: '1896',
    location_name: 'Vouvry',
    latitude: 46.335,
    longitude: 6.9017,
    id: 4292,
  },
  {
    location_type: 'zip_code',
    location: '1897',
    location_name: 'Bouveret',
    latitude: 46.3741,
    longitude: 6.853,
    id: 4293,
  },
  {
    location_type: 'zip_code',
    location: '1898',
    location_name: 'St-Gingolph',
    latitude: 46.3837,
    longitude: 6.8126,
    id: 4294,
  },
  {
    location_type: 'zip_code',
    location: '1899',
    location_name: 'Torgon',
    latitude: 46.3069,
    longitude: 6.8542,
    id: 4295,
  },
  {
    location_type: 'zip_code',
    location: '3916',
    location_name: 'Ferden',
    latitude: 46.3911,
    longitude: 7.7412,
    id: 4296,
  },
  {
    location_type: 'zip_code',
    location: '3917',
    location_name: 'Goppenstein',
    latitude: 46.3677,
    longitude: 7.756,
    id: 4297,
  },
  {
    location_type: 'zip_code',
    location: '3918',
    location_name: 'Wiler (L\u00f6tschen)',
    latitude: 46.4074,
    longitude: 7.787,
    id: 4298,
  },
  {
    location_type: 'zip_code',
    location: '3919',
    location_name: 'Blatten (L\u00f6tschen)',
    latitude: 46.4438,
    longitude: 7.8568,
    id: 4299,
  },
  {
    location_type: 'zip_code',
    location: '3935',
    location_name: 'B\u00fcrchen',
    latitude: 46.2713,
    longitude: 7.8213,
    id: 4300,
  },
  {
    location_type: 'zip_code',
    location: '3938',
    location_name: 'Ausserberg',
    latitude: 46.3283,
    longitude: 7.8503,
    id: 4301,
  },
  {
    location_type: 'zip_code',
    location: '3940',
    location_name: 'Steg VS',
    latitude: 46.3544,
    longitude: 7.7657,
    id: 4302,
  },
  {
    location_type: 'zip_code',
    location: '3942',
    location_name: 'Raron',
    latitude: 46.3562,
    longitude: 7.8242,
    id: 4303,
  },
  {
    location_type: 'zip_code',
    location: '3943',
    location_name: 'Eischoll',
    latitude: 46.2825,
    longitude: 7.7649,
    id: 4304,
  },
  {
    location_type: 'zip_code',
    location: '3944',
    location_name: 'Unterbach',
    latitude: 46.2465,
    longitude: 7.7745,
    id: 4305,
  },
  {
    location_type: 'zip_code',
    location: '3949',
    location_name: 'Hohtenn',
    latitude: 46.3306,
    longitude: 7.7659,
    id: 4306,
  },
  {
    location_type: 'zip_code',
    location: '3982',
    location_name: 'Bitsch',
    latitude: 46.3397,
    longitude: 8.013,
    id: 4307,
  },
  {
    location_type: 'zip_code',
    location: '3983',
    location_name: 'Goppisberg',
    latitude: 46.3714,
    longitude: 8.049,
    id: 4308,
  },
  {
    location_type: 'zip_code',
    location: '3986',
    location_name: 'Ried-M\u00f6rel',
    latitude: 46.3535,
    longitude: 8.0283,
    id: 4309,
  },
  {
    location_type: 'zip_code',
    location: '3987',
    location_name: 'Riederalp',
    latitude: 46.3812,
    longitude: 8.0303,
    id: 4310,
  },
  {
    location_type: 'zip_code',
    location: '3991',
    location_name: 'Betten',
    latitude: 46.3767,
    longitude: 8.066,
    id: 4311,
  },
  {
    location_type: 'zip_code',
    location: '3992',
    location_name: 'Bettmeralp',
    latitude: 46.4272,
    longitude: 8.0779,
    id: 4312,
  },
  {
    location_type: 'zip_code',
    location: '3993',
    location_name: 'Grengiols',
    latitude: 46.3436,
    longitude: 8.1363,
    id: 4313,
  },
  {
    location_type: 'zip_code',
    location: '1869',
    location_name: 'Massongex',
    latitude: 46.2401,
    longitude: 6.9851,
    id: 4314,
  },
  {
    location_type: 'zip_code',
    location: '1890',
    location_name: 'Mex VS',
    latitude: 46.1869,
    longitude: 6.9893,
    id: 4315,
  },
  {
    location_type: 'zip_code',
    location: '1891',
    location_name: 'V\u00e9rossaz',
    latitude: 46.2047,
    longitude: 6.9717,
    id: 4316,
  },
  {
    location_type: 'zip_code',
    location: '1902',
    location_name: 'Evionnaz',
    latitude: 46.1529,
    longitude: 6.9544,
    id: 4317,
  },
  {
    location_type: 'zip_code',
    location: '1903',
    location_name: 'Collonges',
    latitude: 46.1798,
    longitude: 7.057,
    id: 4318,
  },
  {
    location_type: 'zip_code',
    location: '1904',
    location_name: 'Vernayaz',
    latitude: 46.1434,
    longitude: 7.0307,
    id: 4319,
  },
  {
    location_type: 'zip_code',
    location: '1905',
    location_name: 'Dor\u00e9naz',
    latitude: 46.1483,
    longitude: 7.0605,
    id: 4320,
  },
  {
    location_type: 'zip_code',
    location: '1922',
    location_name: 'Les Granges (Salvan)',
    latitude: 46.1338,
    longitude: 6.9774,
    id: 4321,
  },
  {
    location_type: 'zip_code',
    location: '1923',
    location_name: 'Le Tr\u00e9tien',
    latitude: 46.0951,
    longitude: 6.9937,
    id: 4322,
  },
  {
    location_type: 'zip_code',
    location: '1925',
    location_name: 'Finhaut',
    latitude: 46.0717,
    longitude: 6.9349,
    id: 4323,
  },
  {
    location_type: 'zip_code',
    location: '1958',
    location_name: 'St-L\u00e9onard',
    latitude: 46.2562,
    longitude: 7.4287,
    id: 4324,
  },
  {
    location_type: 'zip_code',
    location: '1977',
    location_name: 'Icogne',
    latitude: 46.3426,
    longitude: 7.4565,
    id: 4325,
  },
  {
    location_type: 'zip_code',
    location: '1978',
    location_name: 'Icogne',
    latitude: 46.28,
    longitude: 7.4503,
    id: 4326,
  },
  {
    location_type: 'zip_code',
    location: '3960',
    location_name: 'Niouc',
    latitude: 46.27,
    longitude: 7.5616,
    id: 4327,
  },
  {
    location_type: 'zip_code',
    location: '3961',
    location_name: 'Grimentz',
    latitude: 46.143,
    longitude: 7.5636,
    id: 4328,
  },
  {
    location_type: 'zip_code',
    location: '3963',
    location_name: 'Crans-Montana',
    latitude: 46.327,
    longitude: 7.4864,
    id: 4329,
  },
  {
    location_type: 'zip_code',
    location: '3965',
    location_name: 'Chippis',
    latitude: 46.2743,
    longitude: 7.5394,
    id: 4330,
  },
  {
    location_type: 'zip_code',
    location: '3966',
    location_name: 'R\u00e9chy',
    latitude: 46.2657,
    longitude: 7.4948,
    id: 4331,
  },
  {
    location_type: 'zip_code',
    location: '3967',
    location_name: 'Vercorin',
    latitude: 46.242,
    longitude: 7.5346,
    id: 4332,
  },
  {
    location_type: 'zip_code',
    location: '3968',
    location_name: 'Veyras',
    latitude: 46.3053,
    longitude: 7.5397,
    id: 4333,
  },
  {
    location_type: 'zip_code',
    location: '3971',
    location_name: 'Chermignon-d\'en-Bas',
    latitude: 46.2831,
    longitude: 7.482,
    id: 4334,
  },
  {
    location_type: 'zip_code',
    location: '3972',
    location_name: 'Mi\u00e8ge',
    latitude: 46.3403,
    longitude: 7.554,
    id: 4335,
  },
  {
    location_type: 'zip_code',
    location: '3973',
    location_name: 'Venth\u00f4ne',
    latitude: 46.3034,
    longitude: 7.5234,
    id: 4336,
  },
  {
    location_type: 'zip_code',
    location: '3974',
    location_name: 'Mollens VS',
    latitude: 46.321,
    longitude: 7.5238,
    id: 4337,
  },
  {
    location_type: 'zip_code',
    location: '3975',
    location_name: 'Randogne',
    latitude: 46.3071,
    longitude: 7.5006,
    id: 4338,
  },
  {
    location_type: 'zip_code',
    location: '3976',
    location_name: 'Champzab\u00e9',
    latitude: 46.2917,
    longitude: 7.5,
    id: 4339,
  },
  {
    location_type: 'zip_code',
    location: '3977',
    location_name: 'Granges VS',
    latitude: 46.26,
    longitude: 7.4636,
    id: 4340,
  },
  {
    location_type: 'zip_code',
    location: '3978',
    location_name: 'Flanthey',
    latitude: 46.2705,
    longitude: 7.453,
    id: 4341,
  },
  {
    location_type: 'zip_code',
    location: '3979',
    location_name: 'Gr\u00f4ne',
    latitude: 46.231,
    longitude: 7.4863,
    id: 4342,
  },
  {
    location_type: 'zip_code',
    location: '1950',
    location_name: 'Sion',
    latitude: 46.2295,
    longitude: 7.3568,
    id: 4343,
  },
  {
    location_type: 'zip_code',
    location: '1951',
    location_name: 'Sion car postal VS-L\u00e9man',
    latitude: 46.2333,
    longitude: 7.35,
    id: 4344,
  },
  {
    location_type: 'zip_code',
    location: '1962',
    location_name: 'Pont-de-la-Morge (Sion)',
    latitude: 46.232,
    longitude: 7.3153,
    id: 4345,
  },
  {
    location_type: 'zip_code',
    location: '1965',
    location_name: 'Granois (Savi\u00e8se)',
    latitude: 46.2562,
    longitude: 7.3305,
    id: 4346,
  },
  {
    location_type: 'zip_code',
    location: '1967',
    location_name: 'Bramois',
    latitude: 46.2332,
    longitude: 7.4048,
    id: 4347,
  },
  {
    location_type: 'zip_code',
    location: '1971',
    location_name: 'Champlan (Grimisuat)',
    latitude: 46.2511,
    longitude: 7.3731,
    id: 4348,
  },
  {
    location_type: 'zip_code',
    location: '1974',
    location_name: 'Saxonne (Ayent)',
    latitude: 46.2999,
    longitude: 7.3744,
    id: 4349,
  },
  {
    location_type: 'zip_code',
    location: '1991',
    location_name: 'Turin (Salins)',
    latitude: 46.2152,
    longitude: 7.358,
    id: 4350,
  },
  {
    location_type: 'zip_code',
    location: '3905',
    location_name: 'Saas-Almagell',
    latitude: 46.0536,
    longitude: 7.9593,
    id: 4351,
  },
  {
    location_type: 'zip_code',
    location: '3906',
    location_name: 'Saas-Fee',
    latitude: 46.0883,
    longitude: 7.9013,
    id: 4352,
  },
  {
    location_type: 'zip_code',
    location: '3908',
    location_name: 'Saas-Balen',
    latitude: 46.1504,
    longitude: 7.9276,
    id: 4353,
  },
  {
    location_type: 'zip_code',
    location: '3910',
    location_name: 'Saas Grund',
    latitude: 46.1385,
    longitude: 7.9702,
    id: 4354,
  },
  {
    location_type: 'zip_code',
    location: '3920',
    location_name: 'Zermatt',
    latitude: 45.9906,
    longitude: 7.7422,
    id: 4355,
  },
  {
    location_type: 'zip_code',
    location: '3922',
    location_name: 'Eisten',
    latitude: 46.1755,
    longitude: 7.8951,
    id: 4356,
  },
  {
    location_type: 'zip_code',
    location: '3923',
    location_name: 'T\u00f6rbel',
    latitude: 46.2469,
    longitude: 7.8414,
    id: 4357,
  },
  {
    location_type: 'zip_code',
    location: '3924',
    location_name: 'Gasenried',
    latitude: 46.1804,
    longitude: 7.8282,
    id: 4358,
  },
  {
    location_type: 'zip_code',
    location: '3925',
    location_name: 'Gr\u00e4chen',
    latitude: 46.198,
    longitude: 7.8458,
    id: 4359,
  },
  {
    location_type: 'zip_code',
    location: '3926',
    location_name: 'Embd',
    latitude: 46.2183,
    longitude: 7.8015,
    id: 4360,
  },
  {
    location_type: 'zip_code',
    location: '3927',
    location_name: 'Herbriggen',
    latitude: 46.1385,
    longitude: 7.7828,
    id: 4361,
  },
  {
    location_type: 'zip_code',
    location: '3928',
    location_name: 'Randa',
    latitude: 46.0952,
    longitude: 7.7856,
    id: 4362,
  },
  {
    location_type: 'zip_code',
    location: '3929',
    location_name: 'T\u00e4sch',
    latitude: 46.0504,
    longitude: 7.8164,
    id: 4363,
  },
  {
    location_type: 'zip_code',
    location: '3930',
    location_name: 'Eyholz',
    latitude: 46.2833,
    longitude: 7.9167,
    id: 4364,
  },
  {
    location_type: 'zip_code',
    location: '3931',
    location_name: 'Lalden',
    latitude: 46.3005,
    longitude: 7.8984,
    id: 4365,
  },
  {
    location_type: 'zip_code',
    location: '3932',
    location_name: 'Visperterminen',
    latitude: 46.2554,
    longitude: 7.9362,
    id: 4366,
  },
  {
    location_type: 'zip_code',
    location: '3933',
    location_name: 'Staldenried',
    latitude: 46.2184,
    longitude: 7.9097,
    id: 4367,
  },
  {
    location_type: 'zip_code',
    location: '3934',
    location_name: 'Zeneggen',
    latitude: 46.2718,
    longitude: 7.8639,
    id: 4368,
  },
  {
    location_type: 'zip_code',
    location: '3937',
    location_name: 'Baltschieder',
    latitude: 46.3429,
    longitude: 7.8713,
    id: 4369,
  },
  {
    location_type: 'zip_code',
    location: '6300',
    location_name: 'Zug',
    latitude: 47.1686,
    longitude: 8.5145,
    id: 4370,
  },
  {
    location_type: 'zip_code',
    location: '6301',
    location_name: 'Zug',
    latitude: 47.1667,
    longitude: 8.5167,
    id: 4371,
  },
  {
    location_type: 'zip_code',
    location: '6302',
    location_name: 'Zug',
    latitude: 47.1667,
    longitude: 8.5167,
    id: 4372,
  },
  {
    location_type: 'zip_code',
    location: '6303',
    location_name: 'Zug',
    latitude: 47.1667,
    longitude: 8.5167,
    id: 4373,
  },
  {
    location_type: 'zip_code',
    location: '6304',
    location_name: 'Zug',
    latitude: 47.1983,
    longitude: 8.5289,
    id: 4374,
  },
  {
    location_type: 'zip_code',
    location: '6310',
    location_name: 'Zug Sonderdienste',
    latitude: 47.1433,
    longitude: 8.5533,
    id: 4375,
  },
  {
    location_type: 'zip_code',
    location: '6312',
    location_name: 'Steinhausen',
    latitude: 47.1988,
    longitude: 8.4864,
    id: 4376,
  },
  {
    location_type: 'zip_code',
    location: '6313',
    location_name: 'Finstersee',
    latitude: 47.1643,
    longitude: 8.6368,
    id: 4377,
  },
  {
    location_type: 'zip_code',
    location: '6314',
    location_name: 'Unter\u00e4geri',
    latitude: 47.1189,
    longitude: 8.5847,
    id: 4378,
  },
  {
    location_type: 'zip_code',
    location: '6315',
    location_name: 'Alosen',
    latitude: 47.1452,
    longitude: 8.6523,
    id: 4379,
  },
  {
    location_type: 'zip_code',
    location: '6317',
    location_name: 'Oberwil',
    latitude: 47.1346,
    longitude: 8.4997,
    id: 4380,
  },
  {
    location_type: 'zip_code',
    location: '6319',
    location_name: 'Allenwinden',
    latitude: 47.1642,
    longitude: 8.5483,
    id: 4381,
  },
  {
    location_type: 'zip_code',
    location: '6330',
    location_name: 'Cham',
    latitude: 47.1962,
    longitude: 8.4531,
    id: 4382,
  },
  {
    location_type: 'zip_code',
    location: '6331',
    location_name: 'H\u00fcnenberg',
    latitude: 47.1779,
    longitude: 8.4208,
    id: 4383,
  },
  {
    location_type: 'zip_code',
    location: '6332',
    location_name: 'Hagendorn',
    latitude: 47.2092,
    longitude: 8.4292,
    id: 4384,
  },
  {
    location_type: 'zip_code',
    location: '6333',
    location_name: 'H\u00fcnenberg See',
    latitude: 47.1717,
    longitude: 8.4509,
    id: 4385,
  },
  {
    location_type: 'zip_code',
    location: '6340',
    location_name: 'Sihlbrugg',
    latitude: 47.225,
    longitude: 8.5761,
    id: 4386,
  },
  {
    location_type: 'zip_code',
    location: '6341',
    location_name: 'Baar',
    latitude: 47.2,
    longitude: 8.5167,
    id: 4387,
  },
  {
    location_type: 'zip_code',
    location: '6342',
    location_name: 'Baar',
    latitude: 47.2,
    longitude: 8.5167,
    id: 4388,
  },
  {
    location_type: 'zip_code',
    location: '6343',
    location_name: 'Holzh\u00e4usern',
    latitude: 47.1521,
    longitude: 8.4411,
    id: 4389,
  },
  {
    location_type: 'zip_code',
    location: '6345',
    location_name: 'Neuheim',
    latitude: 47.2011,
    longitude: 8.5841,
    id: 4390,
  },
  {
    location_type: 'zip_code',
    location: '6346',
    location_name: 'Baar 3',
    latitude: 47.1954,
    longitude: 8.537,
    id: 4391,
  },
  {
    location_type: 'zip_code',
    location: '6349',
    location_name: 'Baar Sonderdienste',
    latitude: 47.1955,
    longitude: 8.537,
    id: 4392,
  },
  {
    location_type: 'zip_code',
    location: '8143',
    location_name: 'Uetliberg',
    latitude: 47.3485,
    longitude: 8.4918,
    id: 4393,
  },
  {
    location_type: 'zip_code',
    location: '8906',
    location_name: 'Bonstetten',
    latitude: 47.316,
    longitude: 8.4625,
    id: 4394,
  },
  {
    location_type: 'zip_code',
    location: '8907',
    location_name: 'Wettswil',
    latitude: 47.3374,
    longitude: 8.4706,
    id: 4395,
  },
  {
    location_type: 'zip_code',
    location: '8908',
    location_name: 'Hedingen',
    latitude: 47.3023,
    longitude: 8.4504,
    id: 4396,
  },
  {
    location_type: 'zip_code',
    location: '8909',
    location_name: 'Zwillikon',
    latitude: 47.2886,
    longitude: 8.4308,
    id: 4397,
  },
  {
    location_type: 'zip_code',
    location: '8910',
    location_name: 'Affoltern am Albis',
    latitude: 47.2788,
    longitude: 8.4544,
    id: 4398,
  },
  {
    location_type: 'zip_code',
    location: '8911',
    location_name: 'Rifferswil',
    latitude: 47.2455,
    longitude: 8.4949,
    id: 4399,
  },
  {
    location_type: 'zip_code',
    location: '8912',
    location_name: 'Obfelden',
    latitude: 47.2603,
    longitude: 8.4227,
    id: 4400,
  },
  {
    location_type: 'zip_code',
    location: '8913',
    location_name: 'Ottenbach',
    latitude: 47.2811,
    longitude: 8.4086,
    id: 4401,
  },
  {
    location_type: 'zip_code',
    location: '8914',
    location_name: 'Aeugst am Albis',
    latitude: 47.268,
    longitude: 8.4891,
    id: 4402,
  },
  {
    location_type: 'zip_code',
    location: '8915',
    location_name: 'Hausen am Albis',
    latitude: 47.2496,
    longitude: 8.533,
    id: 4403,
  },
  {
    location_type: 'zip_code',
    location: '8925',
    location_name: 'Ebertswil',
    latitude: 47.2276,
    longitude: 8.5537,
    id: 4404,
  },
  {
    location_type: 'zip_code',
    location: '8926',
    location_name: 'Hauptikon',
    latitude: 47.2325,
    longitude: 8.4942,
    id: 4405,
  },
  {
    location_type: 'zip_code',
    location: '8932',
    location_name: 'Mettmenstetten',
    latitude: 47.2487,
    longitude: 8.4662,
    id: 4406,
  },
  {
    location_type: 'zip_code',
    location: '8933',
    location_name: 'Maschwanden',
    latitude: 47.2357,
    longitude: 8.4308,
    id: 4407,
  },
  {
    location_type: 'zip_code',
    location: '8934',
    location_name: 'Knonau',
    latitude: 47.2243,
    longitude: 8.4631,
    id: 4408,
  },
  {
    location_type: 'zip_code',
    location: '8245',
    location_name: 'Feuerthalen',
    latitude: 47.6893,
    longitude: 8.6431,
    id: 4409,
  },
  {
    location_type: 'zip_code',
    location: '8246',
    location_name: 'Langwiesen',
    latitude: 47.6834,
    longitude: 8.6615,
    id: 4410,
  },
  {
    location_type: 'zip_code',
    location: '8247',
    location_name: 'Flurlingen',
    latitude: 47.6836,
    longitude: 8.6355,
    id: 4411,
  },
  {
    location_type: 'zip_code',
    location: '8248',
    location_name: 'Uhwiesen',
    latitude: 47.6693,
    longitude: 8.6502,
    id: 4412,
  },
  {
    location_type: 'zip_code',
    location: '8414',
    location_name: 'Buch am Irchel',
    latitude: 47.5505,
    longitude: 8.6251,
    id: 4413,
  },
  {
    location_type: 'zip_code',
    location: '8415',
    location_name: 'Berg am Irchel',
    latitude: 47.5631,
    longitude: 8.586,
    id: 4414,
  },
  {
    location_type: 'zip_code',
    location: '8416',
    location_name: 'Flaach',
    latitude: 47.5834,
    longitude: 8.6019,
    id: 4415,
  },
  {
    location_type: 'zip_code',
    location: '8444',
    location_name: 'Henggart',
    latitude: 47.5604,
    longitude: 8.6808,
    id: 4416,
  },
  {
    location_type: 'zip_code',
    location: '8447',
    location_name: 'Dachsen',
    latitude: 47.6617,
    longitude: 8.6244,
    id: 4417,
  },
  {
    location_type: 'zip_code',
    location: '8450',
    location_name: 'Andelfingen',
    latitude: 47.5947,
    longitude: 8.6814,
    id: 4418,
  },
  {
    location_type: 'zip_code',
    location: '8451',
    location_name: 'Kleinandelfingen',
    latitude: 47.6066,
    longitude: 8.6848,
    id: 4419,
  },
  {
    location_type: 'zip_code',
    location: '8452',
    location_name: 'Adlikon b. Andelfingen',
    latitude: 47.5769,
    longitude: 8.7049,
    id: 4420,
  },
  {
    location_type: 'zip_code',
    location: '8453',
    location_name: 'Alten',
    latitude: 47.597,
    longitude: 8.6416,
    id: 4421,
  },
  {
    location_type: 'zip_code',
    location: '8457',
    location_name: 'Humlikon',
    latitude: 47.5748,
    longitude: 8.6759,
    id: 4422,
  },
  {
    location_type: 'zip_code',
    location: '8458',
    location_name: 'Dorf',
    latitude: 47.5713,
    longitude: 8.6493,
    id: 4423,
  },
  {
    location_type: 'zip_code',
    location: '8459',
    location_name: 'Volken',
    latitude: 47.5766,
    longitude: 8.6314,
    id: 4424,
  },
  {
    location_type: 'zip_code',
    location: '8460',
    location_name: 'Marthalen',
    latitude: 47.6205,
    longitude: 8.6408,
    id: 4425,
  },
  {
    location_type: 'zip_code',
    location: '8461',
    location_name: 'Oerlingen',
    latitude: 47.6244,
    longitude: 8.6767,
    id: 4426,
  },
  {
    location_type: 'zip_code',
    location: '8462',
    location_name: 'Rheinau',
    latitude: 47.6276,
    longitude: 8.618,
    id: 4427,
  },
  {
    location_type: 'zip_code',
    location: '8463',
    location_name: 'Benken ZH',
    latitude: 47.6538,
    longitude: 8.6547,
    id: 4428,
  },
  {
    location_type: 'zip_code',
    location: '8464',
    location_name: 'Ellikon am Rhein',
    latitude: 47.604,
    longitude: 8.6032,
    id: 4429,
  },
  {
    location_type: 'zip_code',
    location: '8465',
    location_name: 'Wildensbuch',
    latitude: 47.6513,
    longitude: 8.6794,
    id: 4430,
  },
  {
    location_type: 'zip_code',
    location: '8466',
    location_name: 'Tr\u00fcllikon',
    latitude: 47.6364,
    longitude: 8.6999,
    id: 4431,
  },
  {
    location_type: 'zip_code',
    location: '8467',
    location_name: 'Truttikon',
    latitude: 47.6318,
    longitude: 8.7291,
    id: 4432,
  },
  {
    location_type: 'zip_code',
    location: '8468',
    location_name: 'Waltalingen',
    latitude: 47.6208,
    longitude: 8.767,
    id: 4433,
  },
  {
    location_type: 'zip_code',
    location: '8475',
    location_name: 'Ossingen',
    latitude: 47.6113,
    longitude: 8.7218,
    id: 4434,
  },
  {
    location_type: 'zip_code',
    location: '8476',
    location_name: 'Unterstammheim',
    latitude: 47.6488,
    longitude: 8.7827,
    id: 4435,
  },
  {
    location_type: 'zip_code',
    location: '8477',
    location_name: 'Oberstammheim',
    latitude: 47.6285,
    longitude: 8.8048,
    id: 4436,
  },
  {
    location_type: 'zip_code',
    location: '8478',
    location_name: 'Thalheim an der Thur',
    latitude: 47.582,
    longitude: 8.7452,
    id: 4437,
  },
  {
    location_type: 'zip_code',
    location: '8058',
    location_name: 'Z\u00fcrich',
    latitude: 47.4535,
    longitude: 8.561,
    id: 4438,
  },
  {
    location_type: 'zip_code',
    location: '8059',
    location_name: 'Z\u00fcrich 59 Exchange Office',
    latitude: 47.458,
    longitude: 8.5853,
    id: 4439,
  },
  {
    location_type: 'zip_code',
    location: '8060',
    location_name: 'Z\u00fcrich',
    latitude: 47.458,
    longitude: 8.5853,
    id: 4440,
  },
  {
    location_type: 'zip_code',
    location: '8065',
    location_name: 'Z\u00fcrich',
    latitude: 47.3667,
    longitude: 8.55,
    id: 4441,
  },
  {
    location_type: 'zip_code',
    location: '8152',
    location_name: 'Glattpark (Opfikon)',
    latitude: 47.4209,
    longitude: 8.5632,
    id: 4442,
  },
  {
    location_type: 'zip_code',
    location: '8180',
    location_name: 'B\u00fclach',
    latitude: 47.5307,
    longitude: 8.5456,
    id: 4443,
  },
  {
    location_type: 'zip_code',
    location: '8181',
    location_name: 'H\u00f6ri',
    latitude: 47.508,
    longitude: 8.5009,
    id: 4444,
  },
  {
    location_type: 'zip_code',
    location: '8182',
    location_name: 'Hochfelden',
    latitude: 47.528,
    longitude: 8.5085,
    id: 4445,
  },
  {
    location_type: 'zip_code',
    location: '8183',
    location_name: 'B\u00fclach Dist Ba',
    latitude: 47.5284,
    longitude: 8.5089,
    id: 4446,
  },
  {
    location_type: 'zip_code',
    location: '8184',
    location_name: 'Bachenb\u00fclach',
    latitude: 47.5011,
    longitude: 8.5453,
    id: 4447,
  },
  {
    location_type: 'zip_code',
    location: '8185',
    location_name: 'Winkel',
    latitude: 47.4821,
    longitude: 8.5583,
    id: 4448,
  },
  {
    location_type: 'zip_code',
    location: '8186',
    location_name: 'B\u00fclach LZB',
    latitude: 47.5281,
    longitude: 8.5407,
    id: 4449,
  },
  {
    location_type: 'zip_code',
    location: '8192',
    location_name: 'Zweidlen',
    latitude: 47.5695,
    longitude: 8.4754,
    id: 4450,
  },
  {
    location_type: 'zip_code',
    location: '8193',
    location_name: 'Eglisau',
    latitude: 47.5723,
    longitude: 8.523,
    id: 4451,
  },
  {
    location_type: 'zip_code',
    location: '8194',
    location_name: 'H\u00fcntwangen',
    latitude: 47.5931,
    longitude: 8.4982,
    id: 4452,
  },
  {
    location_type: 'zip_code',
    location: '8195',
    location_name: 'Wasterkingen',
    latitude: 47.5965,
    longitude: 8.4719,
    id: 4453,
  },
  {
    location_type: 'zip_code',
    location: '8196',
    location_name: 'Wil ZH',
    latitude: 47.6128,
    longitude: 8.5007,
    id: 4454,
  },
  {
    location_type: 'zip_code',
    location: '8197',
    location_name: 'Rafz',
    latitude: 47.6083,
    longitude: 8.5448,
    id: 4455,
  },
  {
    location_type: 'zip_code',
    location: '8301',
    location_name: 'Glattzentrum b. Wallisellen',
    latitude: 47.4292,
    longitude: 8.6385,
    id: 4456,
  },
  {
    location_type: 'zip_code',
    location: '8302',
    location_name: 'Kloten',
    latitude: 47.4587,
    longitude: 8.5821,
    id: 4457,
  },
  {
    location_type: 'zip_code',
    location: '8303',
    location_name: 'Bassersdorf',
    latitude: 47.4402,
    longitude: 8.6259,
    id: 4458,
  },
  {
    location_type: 'zip_code',
    location: '8304',
    location_name: 'Wallisellen',
    latitude: 47.4212,
    longitude: 8.5963,
    id: 4459,
  },
  {
    location_type: 'zip_code',
    location: '8305',
    location_name: 'Dietlikon',
    latitude: 47.4249,
    longitude: 8.6124,
    id: 4460,
  },
  {
    location_type: 'zip_code',
    location: '8309',
    location_name: 'N\u00fcrensdorf',
    latitude: 47.455,
    longitude: 8.6512,
    id: 4461,
  },
  {
    location_type: 'zip_code',
    location: '8423',
    location_name: 'Embrach-Embraport',
    latitude: 47.5083,
    longitude: 8.6083,
    id: 4462,
  },
  {
    location_type: 'zip_code',
    location: '8424',
    location_name: 'Embrach',
    latitude: 47.5104,
    longitude: 8.6005,
    id: 4463,
  },
  {
    location_type: 'zip_code',
    location: '8425',
    location_name: 'Oberembrach',
    latitude: 47.4897,
    longitude: 8.6336,
    id: 4464,
  },
  {
    location_type: 'zip_code',
    location: '8426',
    location_name: 'Lufingen',
    latitude: 47.4826,
    longitude: 8.5937,
    id: 4465,
  },
  {
    location_type: 'zip_code',
    location: '8427',
    location_name: 'Rorbas',
    latitude: 47.5324,
    longitude: 8.569,
    id: 4466,
  },
  {
    location_type: 'zip_code',
    location: '8428',
    location_name: 'Teufen ZH',
    latitude: 47.5514,
    longitude: 8.574,
    id: 4467,
  },
  {
    location_type: 'zip_code',
    location: '8105',
    location_name: 'Watt',
    latitude: 47.4439,
    longitude: 8.4855,
    id: 4468,
  },
  {
    location_type: 'zip_code',
    location: '8106',
    location_name: 'Adlikon b. Regensdorf',
    latitude: 47.4523,
    longitude: 8.4602,
    id: 4469,
  },
  {
    location_type: 'zip_code',
    location: '8107',
    location_name: 'Buchs ZH',
    latitude: 47.4587,
    longitude: 8.4406,
    id: 4470,
  },
  {
    location_type: 'zip_code',
    location: '8108',
    location_name: 'D\u00e4llikon',
    latitude: 47.439,
    longitude: 8.4324,
    id: 4471,
  },
  {
    location_type: 'zip_code',
    location: '8112',
    location_name: 'Otelfingen',
    latitude: 47.4657,
    longitude: 8.385,
    id: 4472,
  },
  {
    location_type: 'zip_code',
    location: '8113',
    location_name: 'Boppelsen',
    latitude: 47.4725,
    longitude: 8.4052,
    id: 4473,
  },
  {
    location_type: 'zip_code',
    location: '8114',
    location_name: 'D\u00e4nikon ZH',
    latitude: 47.4444,
    longitude: 8.4047,
    id: 4474,
  },
  {
    location_type: 'zip_code',
    location: '8115',
    location_name: 'H\u00fcttikon',
    latitude: 47.4411,
    longitude: 8.3923,
    id: 4475,
  },
  {
    location_type: 'zip_code',
    location: '8153',
    location_name: 'R\u00fcmlang',
    latitude: 47.4485,
    longitude: 8.5275,
    id: 4476,
  },
  {
    location_type: 'zip_code',
    location: '8154',
    location_name: 'Oberglatt ZH',
    latitude: 47.4804,
    longitude: 8.5249,
    id: 4477,
  },
  {
    location_type: 'zip_code',
    location: '8155',
    location_name: 'Niederhasli',
    latitude: 47.4763,
    longitude: 8.4826,
    id: 4478,
  },
  {
    location_type: 'zip_code',
    location: '8156',
    location_name: 'Oberhasli',
    latitude: 47.4604,
    longitude: 8.4967,
    id: 4479,
  },
  {
    location_type: 'zip_code',
    location: '8157',
    location_name: 'Dielsdorf',
    latitude: 47.4756,
    longitude: 8.4552,
    id: 4480,
  },
  {
    location_type: 'zip_code',
    location: '8158',
    location_name: 'Regensberg',
    latitude: 47.4757,
    longitude: 8.4228,
    id: 4481,
  },
  {
    location_type: 'zip_code',
    location: '8162',
    location_name: 'Steinmaur',
    latitude: 47.4997,
    longitude: 8.4425,
    id: 4482,
  },
  {
    location_type: 'zip_code',
    location: '8164',
    location_name: 'Bachs',
    latitude: 47.5288,
    longitude: 8.4257,
    id: 4483,
  },
  {
    location_type: 'zip_code',
    location: '8165',
    location_name: 'Sch\u00f6fflisdorf',
    latitude: 47.4996,
    longitude: 8.4204,
    id: 4484,
  },
  {
    location_type: 'zip_code',
    location: '8166',
    location_name: 'Niederweningen',
    latitude: 47.4969,
    longitude: 8.3746,
    id: 4485,
  },
  {
    location_type: 'zip_code',
    location: '8172',
    location_name: 'Niederglatt ZH',
    latitude: 47.4919,
    longitude: 8.5008,
    id: 4486,
  },
  {
    location_type: 'zip_code',
    location: '8173',
    location_name: 'Neerach',
    latitude: 47.507,
    longitude: 8.4753,
    id: 4487,
  },
  {
    location_type: 'zip_code',
    location: '8174',
    location_name: 'Stadel b. Niederglatt',
    latitude: 47.527,
    longitude: 8.4698,
    id: 4488,
  },
  {
    location_type: 'zip_code',
    location: '8175',
    location_name: 'Windlach',
    latitude: 47.5475,
    longitude: 8.4736,
    id: 4489,
  },
  {
    location_type: 'zip_code',
    location: '8187',
    location_name: 'Weiach',
    latitude: 47.5535,
    longitude: 8.4415,
    id: 4490,
  },
  {
    location_type: 'zip_code',
    location: '8340',
    location_name: 'Hinwil',
    latitude: 47.3029,
    longitude: 8.8504,
    id: 4491,
  },
  {
    location_type: 'zip_code',
    location: '8342',
    location_name: 'Wernetshausen',
    latitude: 47.2906,
    longitude: 8.878,
    id: 4492,
  },
  {
    location_type: 'zip_code',
    location: '8343',
    location_name: 'Hinwil Dist Ba',
    latitude: 47.3111,
    longitude: 8.85,
    id: 4493,
  },
  {
    location_type: 'zip_code',
    location: '8344',
    location_name: 'B\u00e4retswil',
    latitude: 47.3361,
    longitude: 8.8739,
    id: 4494,
  },
  {
    location_type: 'zip_code',
    location: '8345',
    location_name: 'Adetswil',
    latitude: 47.3422,
    longitude: 8.8366,
    id: 4495,
  },
  {
    location_type: 'zip_code',
    location: '8496',
    location_name: 'Steg im T\u00f6sstal',
    latitude: 47.3438,
    longitude: 8.9472,
    id: 4496,
  },
  {
    location_type: 'zip_code',
    location: '8497',
    location_name: 'Fischenthal',
    latitude: 47.3328,
    longitude: 8.9253,
    id: 4497,
  },
  {
    location_type: 'zip_code',
    location: '8498',
    location_name: 'Gibswil-Ried',
    latitude: 47.3097,
    longitude: 8.9127,
    id: 4498,
  },
  {
    location_type: 'zip_code',
    location: '8607',
    location_name: 'Aathal-Seegr\u00e4ben',
    latitude: 47.3431,
    longitude: 8.7673,
    id: 4499,
  },
  {
    location_type: 'zip_code',
    location: '8608',
    location_name: 'Bubikon',
    latitude: 47.2757,
    longitude: 8.8111,
    id: 4500,
  },
  {
    location_type: 'zip_code',
    location: '8614',
    location_name: 'Bertschikon (Gossau ZH)',
    latitude: 47.3211,
    longitude: 8.7583,
    id: 4501,
  },
  {
    location_type: 'zip_code',
    location: '8620',
    location_name: 'Wetzikon ZH',
    latitude: 47.3224,
    longitude: 8.8064,
    id: 4502,
  },
  {
    location_type: 'zip_code',
    location: '8621',
    location_name: 'Wetzikon ZH 4',
    latitude: 47.2833,
    longitude: 8.75,
    id: 4503,
  },
  {
    location_type: 'zip_code',
    location: '8622',
    location_name: 'Wetzikon ZH',
    latitude: 47.2833,
    longitude: 8.75,
    id: 4504,
  },
  {
    location_type: 'zip_code',
    location: '8623',
    location_name: 'Wetzikon ZH',
    latitude: 47.3328,
    longitude: 8.8168,
    id: 4505,
  },
  {
    location_type: 'zip_code',
    location: '8624',
    location_name: 'Gr\u00fct (Gossau ZH)',
    latitude: 47.3102,
    longitude: 8.779,
    id: 4506,
  },
  {
    location_type: 'zip_code',
    location: '8625',
    location_name: 'Gossau ZH',
    latitude: 47.3051,
    longitude: 8.7558,
    id: 4507,
  },
  {
    location_type: 'zip_code',
    location: '8626',
    location_name: 'Ottikon (Gossau ZH)',
    latitude: 47.2893,
    longitude: 8.786,
    id: 4508,
  },
  {
    location_type: 'zip_code',
    location: '8627',
    location_name: 'Gr\u00fcningen',
    latitude: 47.2786,
    longitude: 8.762,
    id: 4509,
  },
  {
    location_type: 'zip_code',
    location: '8630',
    location_name: 'R\u00fcti ZH',
    latitude: 47.2581,
    longitude: 8.8607,
    id: 4510,
  },
  {
    location_type: 'zip_code',
    location: '8632',
    location_name: 'Tann',
    latitude: 47.2679,
    longitude: 8.8479,
    id: 4511,
  },
  {
    location_type: 'zip_code',
    location: '8633',
    location_name: 'Wolfhausen',
    latitude: 47.2585,
    longitude: 8.7978,
    id: 4512,
  },
  {
    location_type: 'zip_code',
    location: '8635',
    location_name: 'D\u00fcrnten',
    latitude: 47.2749,
    longitude: 8.8717,
    id: 4513,
  },
  {
    location_type: 'zip_code',
    location: '8636',
    location_name: 'Wald ZH',
    latitude: 47.2831,
    longitude: 8.9183,
    id: 4514,
  },
  {
    location_type: 'zip_code',
    location: '8637',
    location_name: 'Laupen ZH',
    latitude: 47.266,
    longitude: 8.9283,
    id: 4515,
  },
  {
    location_type: 'zip_code',
    location: '8639',
    location_name: 'Faltigberg',
    latitude: 47.2763,
    longitude: 8.9434,
    id: 4516,
  },
  {
    location_type: 'zip_code',
    location: '8134',
    location_name: 'Adliswil',
    latitude: 47.3079,
    longitude: 8.5218,
    id: 4517,
  },
  {
    location_type: 'zip_code',
    location: '8135',
    location_name: 'Langnau am Albis',
    latitude: 47.2759,
    longitude: 8.5388,
    id: 4518,
  },
  {
    location_type: 'zip_code',
    location: '8136',
    location_name: 'Gattikon',
    latitude: 47.2794,
    longitude: 8.5531,
    id: 4519,
  },
  {
    location_type: 'zip_code',
    location: '8800',
    location_name: 'Thalwil',
    latitude: 47.292,
    longitude: 8.569,
    id: 4520,
  },
  {
    location_type: 'zip_code',
    location: '8801',
    location_name: 'Thalwil F\u00e4cher',
    latitude: 47.25,
    longitude: 8.6417,
    id: 4521,
  },
  {
    location_type: 'zip_code',
    location: '8802',
    location_name: 'Kilchberg ZH',
    latitude: 47.327,
    longitude: 8.5503,
    id: 4522,
  },
  {
    location_type: 'zip_code',
    location: '8803',
    location_name: 'R\u00fcschlikon',
    latitude: 47.3059,
    longitude: 8.5556,
    id: 4523,
  },
  {
    location_type: 'zip_code',
    location: '8804',
    location_name: 'Au ZH',
    latitude: 47.2484,
    longitude: 8.6508,
    id: 4524,
  },
  {
    location_type: 'zip_code',
    location: '8805',
    location_name: 'Richterswil',
    latitude: 47.2139,
    longitude: 8.7098,
    id: 4525,
  },
  {
    location_type: 'zip_code',
    location: '8810',
    location_name: 'Horgen',
    latitude: 47.2573,
    longitude: 8.6043,
    id: 4526,
  },
  {
    location_type: 'zip_code',
    location: '8812',
    location_name: 'Horgen',
    latitude: 47.2667,
    longitude: 8.5833,
    id: 4527,
  },
  {
    location_type: 'zip_code',
    location: '8813',
    location_name: 'Horgen',
    latitude: 47.2667,
    longitude: 8.5833,
    id: 4528,
  },
  {
    location_type: 'zip_code',
    location: '8815',
    location_name: 'Horgenberg',
    latitude: 47.2479,
    longitude: 8.5839,
    id: 4529,
  },
  {
    location_type: 'zip_code',
    location: '8816',
    location_name: 'Hirzel',
    latitude: 47.2203,
    longitude: 8.602,
    id: 4530,
  },
  {
    location_type: 'zip_code',
    location: '8820',
    location_name: 'W\u00e4denswil',
    latitude: 47.223,
    longitude: 8.6555,
    id: 4531,
  },
  {
    location_type: 'zip_code',
    location: '8824',
    location_name: 'Sch\u00f6nenberg ZH',
    latitude: 47.1925,
    longitude: 8.6407,
    id: 4532,
  },
  {
    location_type: 'zip_code',
    location: '8825',
    location_name: 'H\u00fctten',
    latitude: 47.1735,
    longitude: 8.6588,
    id: 4533,
  },
  {
    location_type: 'zip_code',
    location: '8833',
    location_name: 'Samstagern',
    latitude: 47.1937,
    longitude: 8.6792,
    id: 4534,
  },
  {
    location_type: 'zip_code',
    location: '8942',
    location_name: 'Oberrieden',
    latitude: 47.2749,
    longitude: 8.5785,
    id: 4535,
  },
  {
    location_type: 'zip_code',
    location: '8125',
    location_name: 'Zollikerberg',
    latitude: 47.3447,
    longitude: 8.6078,
    id: 4536,
  },
  {
    location_type: 'zip_code',
    location: '8126',
    location_name: 'Zumikon',
    latitude: 47.3359,
    longitude: 8.6258,
    id: 4537,
  },
  {
    location_type: 'zip_code',
    location: '8130',
    location_name: 'Zumikon Yves Rocher SA',
    latitude: 47.3167,
    longitude: 8.5167,
    id: 4538,
  },
  {
    location_type: 'zip_code',
    location: '8618',
    location_name: 'Oetwil am See',
    latitude: 47.2746,
    longitude: 8.7182,
    id: 4539,
  },
  {
    location_type: 'zip_code',
    location: '8634',
    location_name: 'Hombrechtikon',
    latitude: 47.2551,
    longitude: 8.7659,
    id: 4540,
  },
  {
    location_type: 'zip_code',
    location: '8700',
    location_name: 'K\u00fcsnacht ZH',
    latitude: 47.3196,
    longitude: 8.5899,
    id: 4541,
  },
  {
    location_type: 'zip_code',
    location: '8702',
    location_name: 'Zollikon',
    latitude: 47.3411,
    longitude: 8.575,
    id: 4542,
  },
  {
    location_type: 'zip_code',
    location: '8703',
    location_name: 'Erlenbach ZH',
    latitude: 47.3019,
    longitude: 8.5914,
    id: 4543,
  },
  {
    location_type: 'zip_code',
    location: '8704',
    location_name: 'Herrliberg',
    latitude: 47.2943,
    longitude: 8.6262,
    id: 4544,
  },
  {
    location_type: 'zip_code',
    location: '8706',
    location_name: 'Meilen',
    latitude: 47.2778,
    longitude: 8.6376,
    id: 4545,
  },
  {
    location_type: 'zip_code',
    location: '8707',
    location_name: 'Uetikon am See',
    latitude: 47.2695,
    longitude: 8.679,
    id: 4546,
  },
  {
    location_type: 'zip_code',
    location: '8708',
    location_name: 'M\u00e4nnedorf',
    latitude: 47.2521,
    longitude: 8.6924,
    id: 4547,
  },
  {
    location_type: 'zip_code',
    location: '8712',
    location_name: 'St\u00e4fa',
    latitude: 47.2407,
    longitude: 8.7253,
    id: 4548,
  },
  {
    location_type: 'zip_code',
    location: '8713',
    location_name: 'Uerikon',
    latitude: 47.2282,
    longitude: 8.7552,
    id: 4549,
  },
  {
    location_type: 'zip_code',
    location: '8714',
    location_name: 'Feldbach',
    latitude: 47.2358,
    longitude: 8.7868,
    id: 4550,
  },
  {
    location_type: 'zip_code',
    location: '8307',
    location_name: 'Ottikon b. Kemptthal',
    latitude: 47.4308,
    longitude: 8.7246,
    id: 4551,
  },
  {
    location_type: 'zip_code',
    location: '8308',
    location_name: 'Agasul',
    latitude: 47.4214,
    longitude: 8.7424,
    id: 4552,
  },
  {
    location_type: 'zip_code',
    location: '8310',
    location_name: 'Kemptthal',
    latitude: 47.4612,
    longitude: 8.7157,
    id: 4553,
  },
  {
    location_type: 'zip_code',
    location: '8312',
    location_name: 'Winterberg ZH',
    latitude: 47.4574,
    longitude: 8.6901,
    id: 4554,
  },
  {
    location_type: 'zip_code',
    location: '8314',
    location_name: 'Kyburg',
    latitude: 47.4526,
    longitude: 8.7464,
    id: 4555,
  },
  {
    location_type: 'zip_code',
    location: '8315',
    location_name: 'Lindau',
    latitude: 47.4439,
    longitude: 8.6778,
    id: 4556,
  },
  {
    location_type: 'zip_code',
    location: '8317',
    location_name: 'Tagelswangen',
    latitude: 47.4267,
    longitude: 8.669,
    id: 4557,
  },
  {
    location_type: 'zip_code',
    location: '8320',
    location_name: 'Fehraltorf',
    latitude: 47.3875,
    longitude: 8.7505,
    id: 4558,
  },
  {
    location_type: 'zip_code',
    location: '8322',
    location_name: 'Madetswil',
    latitude: 47.4063,
    longitude: 8.7979,
    id: 4559,
  },
  {
    location_type: 'zip_code',
    location: '8325',
    location_name: 'Effretikon Dist Ba',
    latitude: 47.3833,
    longitude: 8.75,
    id: 4560,
  },
  {
    location_type: 'zip_code',
    location: '8330',
    location_name: 'Pf\u00e4ffikon ZH',
    latitude: 47.3637,
    longitude: 8.7894,
    id: 4561,
  },
  {
    location_type: 'zip_code',
    location: '8331',
    location_name: 'Auslikon',
    latitude: 47.346,
    longitude: 8.8087,
    id: 4562,
  },
  {
    location_type: 'zip_code',
    location: '8332',
    location_name: 'Russikon',
    latitude: 47.3964,
    longitude: 8.7796,
    id: 4563,
  },
  {
    location_type: 'zip_code',
    location: '8335',
    location_name: 'Hittnau',
    latitude: 47.3672,
    longitude: 8.8296,
    id: 4564,
  },
  {
    location_type: 'zip_code',
    location: '8484',
    location_name: 'Theilingen',
    latitude: 47.417,
    longitude: 8.7671,
    id: 4565,
  },
  {
    location_type: 'zip_code',
    location: '8489',
    location_name: 'Wildberg',
    latitude: 47.4246,
    longitude: 8.819,
    id: 4566,
  },
  {
    location_type: 'zip_code',
    location: '8492',
    location_name: 'Wila',
    latitude: 47.4116,
    longitude: 8.8573,
    id: 4567,
  },
  {
    location_type: 'zip_code',
    location: '8493',
    location_name: 'Saland',
    latitude: 47.3891,
    longitude: 8.8626,
    id: 4568,
  },
  {
    location_type: 'zip_code',
    location: '8494',
    location_name: 'Bauma',
    latitude: 47.3668,
    longitude: 8.8924,
    id: 4569,
  },
  {
    location_type: 'zip_code',
    location: '8499',
    location_name: 'Sternenberg',
    latitude: 47.3929,
    longitude: 8.9204,
    id: 4570,
  },
  {
    location_type: 'zip_code',
    location: '8044',
    location_name: 'Gockhausen',
    latitude: 47.3806,
    longitude: 8.5994,
    id: 4571,
  },
  {
    location_type: 'zip_code',
    location: '8117',
    location_name: 'F\u00e4llanden',
    latitude: 47.3704,
    longitude: 8.6434,
    id: 4572,
  },
  {
    location_type: 'zip_code',
    location: '8118',
    location_name: 'Pfaffhausen',
    latitude: 47.3663,
    longitude: 8.6257,
    id: 4573,
  },
  {
    location_type: 'zip_code',
    location: '8121',
    location_name: 'Benglen',
    latitude: 47.3612,
    longitude: 8.6373,
    id: 4574,
  },
  {
    location_type: 'zip_code',
    location: '8122',
    location_name: 'Binz',
    latitude: 47.3567,
    longitude: 8.6271,
    id: 4575,
  },
  {
    location_type: 'zip_code',
    location: '8123',
    location_name: 'Ebmatingen',
    latitude: 47.351,
    longitude: 8.641,
    id: 4576,
  },
  {
    location_type: 'zip_code',
    location: '8124',
    location_name: 'Maur',
    latitude: 47.3372,
    longitude: 8.6705,
    id: 4577,
  },
  {
    location_type: 'zip_code',
    location: '8127',
    location_name: 'Forch',
    latitude: 47.3222,
    longitude: 8.6492,
    id: 4578,
  },
  {
    location_type: 'zip_code',
    location: '8132',
    location_name: 'Egg b. Z\u00fcrich',
    latitude: 47.2919,
    longitude: 8.6908,
    id: 4579,
  },
  {
    location_type: 'zip_code',
    location: '8133',
    location_name: 'Esslingen',
    latitude: 47.2888,
    longitude: 8.7171,
    id: 4580,
  },
  {
    location_type: 'zip_code',
    location: '8306',
    location_name: 'Br\u00fcttisellen',
    latitude: 47.422,
    longitude: 8.6356,
    id: 4581,
  },
  {
    location_type: 'zip_code',
    location: '8600',
    location_name: 'D\u00fcbendorf',
    latitude: 47.3924,
    longitude: 8.618,
    id: 4582,
  },
  {
    location_type: 'zip_code',
    location: '8602',
    location_name: 'Wangen b. D\u00fcbendorf',
    latitude: 47.4093,
    longitude: 8.6494,
    id: 4583,
  },
  {
    location_type: 'zip_code',
    location: '8603',
    location_name: 'Schwerzenbach',
    latitude: 47.3822,
    longitude: 8.6563,
    id: 4584,
  },
  {
    location_type: 'zip_code',
    location: '8604',
    location_name: 'Volketswil',
    latitude: 47.3949,
    longitude: 8.6819,
    id: 4585,
  },
  {
    location_type: 'zip_code',
    location: '8605',
    location_name: 'Gutenswil',
    latitude: 47.3866,
    longitude: 8.7175,
    id: 4586,
  },
  {
    location_type: 'zip_code',
    location: '8606',
    location_name: 'N\u00e4nikon',
    latitude: 47.3737,
    longitude: 8.6921,
    id: 4587,
  },
  {
    location_type: 'zip_code',
    location: '8609',
    location_name: 'Schwerzenbach',
    latitude: 47.3567,
    longitude: 8.6967,
    id: 4588,
  },
  {
    location_type: 'zip_code',
    location: '8610',
    location_name: 'Uster',
    latitude: 47.353,
    longitude: 8.723,
    id: 4589,
  },
  {
    location_type: 'zip_code',
    location: '8612',
    location_name: 'Uster 2',
    latitude: 47.3056,
    longitude: 8.7111,
    id: 4590,
  },
  {
    location_type: 'zip_code',
    location: '8613',
    location_name: 'Uster 3',
    latitude: 47.3056,
    longitude: 8.7111,
    id: 4591,
  },
  {
    location_type: 'zip_code',
    location: '8615',
    location_name: 'Freudwil',
    latitude: 47.3765,
    longitude: 8.7329,
    id: 4592,
  },
  {
    location_type: 'zip_code',
    location: '8616',
    location_name: 'Riedikon',
    latitude: 47.3295,
    longitude: 8.707,
    id: 4593,
  },
  {
    location_type: 'zip_code',
    location: '8617',
    location_name: 'M\u00f6nchaltorf',
    latitude: 47.3115,
    longitude: 8.7183,
    id: 4594,
  },
  {
    location_type: 'zip_code',
    location: '8311',
    location_name: 'Br\u00fctten',
    latitude: 47.4752,
    longitude: 8.6752,
    id: 4595,
  },
  {
    location_type: 'zip_code',
    location: '8352',
    location_name: 'Ricketwil (Winterthur)',
    latitude: 47.4869,
    longitude: 8.7975,
    id: 4596,
  },
  {
    location_type: 'zip_code',
    location: '8353',
    location_name: 'Elgg',
    latitude: 47.4901,
    longitude: 8.8614,
    id: 4597,
  },
  {
    location_type: 'zip_code',
    location: '8354',
    location_name: 'Hofstetten ZH',
    latitude: 47.4747,
    longitude: 8.8506,
    id: 4598,
  },
  {
    location_type: 'zip_code',
    location: '8400',
    location_name: 'Winterthur',
    latitude: 47.4916,
    longitude: 8.7295,
    id: 4599,
  },
  {
    location_type: 'zip_code',
    location: '8401',
    location_name: 'Winterthur',
    latitude: 47.5,
    longitude: 8.75,
    id: 4600,
  },
  {
    location_type: 'zip_code',
    location: '8402',
    location_name: 'Winterthur',
    latitude: 47.5,
    longitude: 8.75,
    id: 4601,
  },
  {
    location_type: 'zip_code',
    location: '8404',
    location_name: 'Stadel (Winterthur)',
    latitude: 47.5382,
    longitude: 8.7652,
    id: 4602,
  },
  {
    location_type: 'zip_code',
    location: '8405',
    location_name: 'Winterthur',
    latitude: 47.4776,
    longitude: 8.7783,
    id: 4603,
  },
  {
    location_type: 'zip_code',
    location: '8406',
    location_name: 'Winterthur',
    latitude: 47.4832,
    longitude: 8.7006,
    id: 4604,
  },
  {
    location_type: 'zip_code',
    location: '8408',
    location_name: 'Winterthur',
    latitude: 47.5077,
    longitude: 8.6833,
    id: 4605,
  },
  {
    location_type: 'zip_code',
    location: '8409',
    location_name: 'Winterthur',
    latitude: 47.5098,
    longitude: 8.7814,
    id: 4606,
  },
  {
    location_type: 'zip_code',
    location: '8410',
    location_name: 'Winterthur',
    latitude: 47.5,
    longitude: 8.75,
    id: 4607,
  },
  {
    location_type: 'zip_code',
    location: '8411',
    location_name: 'Winterthur',
    latitude: 47.5,
    longitude: 8.75,
    id: 4608,
  },
  {
    location_type: 'zip_code',
    location: '8412',
    location_name: 'H\u00fcnikon (Neftenbach)',
    latitude: 47.5541,
    longitude: 8.6631,
    id: 4609,
  },
  {
    location_type: 'zip_code',
    location: '8413',
    location_name: 'Neftenbach',
    latitude: 47.5318,
    longitude: 8.6635,
    id: 4610,
  },
  {
    location_type: 'zip_code',
    location: '8418',
    location_name: 'Schlatt b. Winterthur',
    latitude: 47.4715,
    longitude: 8.8173,
    id: 4611,
  },
  {
    location_type: 'zip_code',
    location: '8421',
    location_name: 'D\u00e4ttlikon',
    latitude: 47.5286,
    longitude: 8.6235,
    id: 4612,
  },
  {
    location_type: 'zip_code',
    location: '8422',
    location_name: 'Pfungen',
    latitude: 47.5097,
    longitude: 8.6424,
    id: 4613,
  },
  {
    location_type: 'zip_code',
    location: '8442',
    location_name: 'Hettlingen',
    latitude: 47.5481,
    longitude: 8.7107,
    id: 4614,
  },
  {
    location_type: 'zip_code',
    location: '8471',
    location_name: 'D\u00e4gerlen',
    latitude: 47.5616,
    longitude: 8.7169,
    id: 4615,
  },
  {
    location_type: 'zip_code',
    location: '8472',
    location_name: 'Seuzach',
    latitude: 47.5348,
    longitude: 8.7243,
    id: 4616,
  },
  {
    location_type: 'zip_code',
    location: '8474',
    location_name: 'Dinhard',
    latitude: 47.5591,
    longitude: 8.7652,
    id: 4617,
  },
  {
    location_type: 'zip_code',
    location: '8479',
    location_name: 'Altikon',
    latitude: 47.5782,
    longitude: 8.7845,
    id: 4618,
  },
  {
    location_type: 'zip_code',
    location: '8482',
    location_name: 'Sennhof (Winterthur)',
    latitude: 47.4674,
    longitude: 8.758,
    id: 4619,
  },
  {
    location_type: 'zip_code',
    location: '8483',
    location_name: 'Kollbrunn',
    latitude: 47.4551,
    longitude: 8.7852,
    id: 4620,
  },
  {
    location_type: 'zip_code',
    location: '8486',
    location_name: 'Rikon im T\u00f6sstal',
    latitude: 47.4499,
    longitude: 8.8047,
    id: 4621,
  },
  {
    location_type: 'zip_code',
    location: '8487',
    location_name: 'Zell ZH',
    latitude: 47.4515,
    longitude: 8.8303,
    id: 4622,
  },
  {
    location_type: 'zip_code',
    location: '8488',
    location_name: 'Turbenthal',
    latitude: 47.4385,
    longitude: 8.8753,
    id: 4623,
  },
  {
    location_type: 'zip_code',
    location: '8495',
    location_name: 'Schmidr\u00fcti',
    latitude: 47.4204,
    longitude: 8.9122,
    id: 4624,
  },
  {
    location_type: 'zip_code',
    location: '8523',
    location_name: 'Hagenbuch ZH',
    latitude: 47.5147,
    longitude: 8.874,
    id: 4625,
  },
  {
    location_type: 'zip_code',
    location: '8542',
    location_name: 'Wiesendangen',
    latitude: 47.5227,
    longitude: 8.788,
    id: 4626,
  },
  {
    location_type: 'zip_code',
    location: '8543',
    location_name: 'Kefikon ZH',
    latitude: 47.5493,
    longitude: 8.8284,
    id: 4627,
  },
  {
    location_type: 'zip_code',
    location: '8544',
    location_name: 'Attikon',
    latitude: 47.5381,
    longitude: 8.8018,
    id: 4628,
  },
  {
    location_type: 'zip_code',
    location: '8545',
    location_name: 'Rickenbach Sulz',
    latitude: 47.5411,
    longitude: 8.7873,
    id: 4629,
  },
  {
    location_type: 'zip_code',
    location: '8548',
    location_name: 'Ellikon an der Thur',
    latitude: 47.5625,
    longitude: 8.8272,
    id: 4630,
  },
  {
    location_type: 'zip_code',
    location: '8010',
    location_name: 'Z\u00fcrich BZI DE',
    latitude: 47.3667,
    longitude: 8.55,
    id: 4631,
  },
  {
    location_type: 'zip_code',
    location: '8011',
    location_name: 'Z\u00fcrich M\u00fclligen SC',
    latitude: 47.395,
    longitude: 8.4488,
    id: 4632,
  },
  {
    location_type: 'zip_code',
    location: '8012',
    location_name: 'Z\u00fcrich',
    latitude: 47.395,
    longitude: 8.4488,
    id: 4633,
  },
  {
    location_type: 'zip_code',
    location: '8015',
    location_name: 'Z\u00fcrich 15',
    latitude: 47.3667,
    longitude: 8.55,
    id: 4634,
  },
  {
    location_type: 'zip_code',
    location: '8080',
    location_name: 'Z\u00fcrich PV',
    latitude: 47.4201,
    longitude: 8.4303,
    id: 4635,
  },
  {
    location_type: 'zip_code',
    location: '8102',
    location_name: 'Oberengstringen',
    latitude: 47.4113,
    longitude: 8.465,
    id: 4636,
  },
  {
    location_type: 'zip_code',
    location: '8103',
    location_name: 'Unterengstringen',
    latitude: 47.4112,
    longitude: 8.4444,
    id: 4637,
  },
  {
    location_type: 'zip_code',
    location: '8104',
    location_name: 'Weiningen ZH',
    latitude: 47.4223,
    longitude: 8.4376,
    id: 4638,
  },
  {
    location_type: 'zip_code',
    location: '8142',
    location_name: 'Uitikon Waldegg',
    latitude: 47.3667,
    longitude: 8.4548,
    id: 4639,
  },
  {
    location_type: 'zip_code',
    location: '8901',
    location_name: 'Urdorf RRD',
    latitude: 47.3457,
    longitude: 8.4525,
    id: 4640,
  },
  {
    location_type: 'zip_code',
    location: '8902',
    location_name: 'Urdorf',
    latitude: 47.3821,
    longitude: 8.4239,
    id: 4641,
  },
  {
    location_type: 'zip_code',
    location: '8903',
    location_name: 'Birmensdorf ZH',
    latitude: 47.352,
    longitude: 8.4396,
    id: 4642,
  },
  {
    location_type: 'zip_code',
    location: '8904',
    location_name: 'Aesch ZH',
    latitude: 47.3355,
    longitude: 8.4355,
    id: 4643,
  },
  {
    location_type: 'zip_code',
    location: '8951',
    location_name: 'Fahrweid',
    latitude: 47.4111,
    longitude: 8.4206,
    id: 4644,
  },
  {
    location_type: 'zip_code',
    location: '8952',
    location_name: 'Schlieren',
    latitude: 47.3944,
    longitude: 8.4474,
    id: 4645,
  },
  {
    location_type: 'zip_code',
    location: '8953',
    location_name: 'Dietikon',
    latitude: 47.4028,
    longitude: 8.3897,
    id: 4646,
  },
  {
    location_type: 'zip_code',
    location: '8954',
    location_name: 'Geroldswil',
    latitude: 47.4229,
    longitude: 8.4131,
    id: 4647,
  },
  {
    location_type: 'zip_code',
    location: '8955',
    location_name: 'Oetwil an der Limmat',
    latitude: 47.4295,
    longitude: 8.3929,
    id: 4648,
  },
  {
    location_type: 'zip_code',
    location: '8970',
    location_name: 'Urdorf Exchange',
    latitude: 47.3798,
    longitude: 8.4224,
    id: 4649,
  },
  {
    location_type: 'zip_code',
    location: '8000',
    location_name: 'Z\u00fcrich',
    latitude: 47.3667,
    longitude: 8.55,
    id: 4650,
  },
  {
    location_type: 'zip_code',
    location: '8001',
    location_name: 'Z\u00fcrich',
    latitude: 47.372,
    longitude: 8.5413,
    id: 4651,
  },
  {
    location_type: 'zip_code',
    location: '8002',
    location_name: 'Z\u00fcrich',
    latitude: 47.3606,
    longitude: 8.5325,
    id: 4652,
  },
  {
    location_type: 'zip_code',
    location: '8003',
    location_name: 'Z\u00fcrich',
    latitude: 47.3726,
    longitude: 8.5161,
    id: 4653,
  },
  {
    location_type: 'zip_code',
    location: '8004',
    location_name: 'Z\u00fcrich',
    latitude: 47.378,
    longitude: 8.5234,
    id: 4654,
  },
  {
    location_type: 'zip_code',
    location: '8005',
    location_name: 'Z\u00fcrich',
    latitude: 47.3868,
    longitude: 8.5207,
    id: 4655,
  },
  {
    location_type: 'zip_code',
    location: '8006',
    location_name: 'Z\u00fcrich',
    latitude: 47.3858,
    longitude: 8.5432,
    id: 4656,
  },
  {
    location_type: 'zip_code',
    location: '8008',
    location_name: 'Z\u00fcrich',
    latitude: 47.3536,
    longitude: 8.5614,
    id: 4657,
  },
  {
    location_type: 'zip_code',
    location: '8016',
    location_name: 'Z\u00fcrich 16 Zustellung',
    latitude: 47.3667,
    longitude: 8.55,
    id: 4658,
  },
  {
    location_type: 'zip_code',
    location: '8020',
    location_name: 'Z\u00fcrich 1',
    latitude: 47.3667,
    longitude: 8.55,
    id: 4659,
  },
  {
    location_type: 'zip_code',
    location: '8021',
    location_name: 'Z\u00fcrich 1',
    latitude: 47.3667,
    longitude: 8.55,
    id: 4660,
  },
  {
    location_type: 'zip_code',
    location: '8022',
    location_name: 'Z\u00fcrich',
    latitude: 47.3674,
    longitude: 8.5374,
    id: 4661,
  },
  {
    location_type: 'zip_code',
    location: '8023',
    location_name: 'Z\u00fcrich',
    latitude: 47.3748,
    longitude: 8.5381,
    id: 4662,
  },
  {
    location_type: 'zip_code',
    location: '8024',
    location_name: 'Z\u00fcrich',
    latitude: 47.3667,
    longitude: 8.55,
    id: 4663,
  },
  {
    location_type: 'zip_code',
    location: '8026',
    location_name: 'Z\u00fcrich',
    latitude: 47.3667,
    longitude: 8.55,
    id: 4664,
  },
  {
    location_type: 'zip_code',
    location: '8027',
    location_name: 'Z\u00fcrich',
    latitude: 47.3667,
    longitude: 8.55,
    id: 4665,
  },
  {
    location_type: 'zip_code',
    location: '8030',
    location_name: 'Z\u00fcrich',
    latitude: 47.3667,
    longitude: 8.55,
    id: 4666,
  },
  {
    location_type: 'zip_code',
    location: '8031',
    location_name: 'Z\u00fcrich',
    latitude: 47.3667,
    longitude: 8.55,
    id: 4667,
  },
  {
    location_type: 'zip_code',
    location: '8032',
    location_name: 'Z\u00fcrich',
    latitude: 47.3667,
    longitude: 8.5644,
    id: 4668,
  },
  {
    location_type: 'zip_code',
    location: '8033',
    location_name: 'Z\u00fcrich',
    latitude: 47.3836,
    longitude: 8.548,
    id: 4669,
  },
  {
    location_type: 'zip_code',
    location: '8034',
    location_name: 'Z\u00fcrich',
    latitude: 47.3667,
    longitude: 8.55,
    id: 4670,
  },
  {
    location_type: 'zip_code',
    location: '8036',
    location_name: 'Z\u00fcrich Corona',
    latitude: 47.3667,
    longitude: 8.55,
    id: 4671,
  },
  {
    location_type: 'zip_code',
    location: '8037',
    location_name: 'Z\u00fcrich',
    latitude: 47.3985,
    longitude: 8.5243,
    id: 4672,
  },
  {
    location_type: 'zip_code',
    location: '8038',
    location_name: 'Z\u00fcrich',
    latitude: 47.3416,
    longitude: 8.5369,
    id: 4673,
  },
  {
    location_type: 'zip_code',
    location: '8039',
    location_name: 'Z\u00fcrich',
    latitude: 47.3667,
    longitude: 8.55,
    id: 4674,
  },
  {
    location_type: 'zip_code',
    location: '8040',
    location_name: 'Z\u00fcrich',
    latitude: 47.3802,
    longitude: 8.5068,
    id: 4675,
  },
  {
    location_type: 'zip_code',
    location: '8041',
    location_name: 'Z\u00fcrich',
    latitude: 47.3361,
    longitude: 8.5156,
    id: 4676,
  },
  {
    location_type: 'zip_code',
    location: '8042',
    location_name: 'Z\u00fcrich',
    latitude: 47.3667,
    longitude: 8.55,
    id: 4677,
  },
  {
    location_type: 'zip_code',
    location: '8045',
    location_name: 'Z\u00fcrich',
    latitude: 47.3516,
    longitude: 8.5057,
    id: 4678,
  },
  {
    location_type: 'zip_code',
    location: '8046',
    location_name: 'Z\u00fcrich',
    latitude: 47.4222,
    longitude: 8.5083,
    id: 4679,
  },
  {
    location_type: 'zip_code',
    location: '8047',
    location_name: 'Z\u00fcrich',
    latitude: 47.3739,
    longitude: 8.487,
    id: 4680,
  },
  {
    location_type: 'zip_code',
    location: '8048',
    location_name: 'Z\u00fcrich',
    latitude: 47.3868,
    longitude: 8.4839,
    id: 4681,
  },
  {
    location_type: 'zip_code',
    location: '8049',
    location_name: 'Z\u00fcrich',
    latitude: 47.4087,
    longitude: 8.5004,
    id: 4682,
  },
  {
    location_type: 'zip_code',
    location: '8050',
    location_name: 'Z\u00fcrich',
    latitude: 47.4119,
    longitude: 8.5508,
    id: 4683,
  },
  {
    location_type: 'zip_code',
    location: '8051',
    location_name: 'Z\u00fcrich',
    latitude: 47.3996,
    longitude: 8.5788,
    id: 4684,
  },
  {
    location_type: 'zip_code',
    location: '8052',
    location_name: 'Z\u00fcrich',
    latitude: 47.4245,
    longitude: 8.5406,
    id: 4685,
  },
  {
    location_type: 'zip_code',
    location: '8053',
    location_name: 'Z\u00fcrich',
    latitude: 47.3613,
    longitude: 8.5974,
    id: 4686,
  },
  {
    location_type: 'zip_code',
    location: '8055',
    location_name: 'Z\u00fcrich',
    latitude: 47.3642,
    longitude: 8.4903,
    id: 4687,
  },
  {
    location_type: 'zip_code',
    location: '8057',
    location_name: 'Z\u00fcrich',
    latitude: 47.3999,
    longitude: 8.5474,
    id: 4688,
  },
  {
    location_type: 'zip_code',
    location: '8061',
    location_name: 'Z\u00fcrich',
    latitude: 47.3667,
    longitude: 8.55,
    id: 4689,
  },
  {
    location_type: 'zip_code',
    location: '8063',
    location_name: 'Z\u00fcrich',
    latitude: 47.3666,
    longitude: 8.497,
    id: 4690,
  },
  {
    location_type: 'zip_code',
    location: '8064',
    location_name: 'Z\u00fcrich',
    latitude: 47.3987,
    longitude: 8.4852,
    id: 4691,
  },
  {
    location_type: 'zip_code',
    location: '8066',
    location_name: 'Z\u00fcrich',
    latitude: 47.3667,
    longitude: 8.55,
    id: 4692,
  },
  {
    location_type: 'zip_code',
    location: '8068',
    location_name: 'Z\u00fcrich',
    latitude: 47.3667,
    longitude: 8.55,
    id: 4693,
  },
  {
    location_type: 'zip_code',
    location: '8070',
    location_name: 'Z\u00fcrich',
    latitude: 47.3667,
    longitude: 8.55,
    id: 4694,
  },
  {
    location_type: 'zip_code',
    location: '8071',
    location_name: 'Z\u00fcrich CS PZ',
    latitude: 47.3667,
    longitude: 8.55,
    id: 4695,
  },
  {
    location_type: 'zip_code',
    location: '8074',
    location_name: 'Z\u00fcrich Voice Publishing',
    latitude: 47.3828,
    longitude: 8.5307,
    id: 4696,
  },
  {
    location_type: 'zip_code',
    location: '8075',
    location_name: 'Z\u00fcrich Benissimo spezial',
    latitude: 47.3667,
    longitude: 8.55,
    id: 4697,
  },
  {
    location_type: 'zip_code',
    location: '8081',
    location_name: 'Z\u00fcrich Helsana',
    latitude: 47.3667,
    longitude: 8.55,
    id: 4698,
  },
  {
    location_type: 'zip_code',
    location: '8085',
    location_name: 'Z\u00fcrich Versicherung',
    latitude: 47.3667,
    longitude: 8.55,
    id: 4699,
  },
  {
    location_type: 'zip_code',
    location: '8086',
    location_name: 'Z\u00fcrich Reader\'s Digest',
    latitude: 47.3667,
    longitude: 8.55,
    id: 4700,
  },
  {
    location_type: 'zip_code',
    location: '8087',
    location_name: 'Z\u00fcrich',
    latitude: 47.3667,
    longitude: 8.55,
    id: 4701,
  },
  {
    location_type: 'zip_code',
    location: '8088',
    location_name: 'Z\u00fcrich',
    latitude: 47.3667,
    longitude: 8.55,
    id: 4702,
  },
  {
    location_type: 'zip_code',
    location: '8090',
    location_name: 'Z\u00fcrich',
    latitude: 47.3667,
    longitude: 8.55,
    id: 4703,
  },
  {
    location_type: 'zip_code',
    location: '8091',
    location_name: 'Z\u00fcrich',
    latitude: 47.3667,
    longitude: 8.55,
    id: 4704,
  },
  {
    location_type: 'zip_code',
    location: '8092',
    location_name: 'Z\u00fcrich ETH-Zentrum',
    latitude: 47.3667,
    longitude: 8.55,
    id: 4705,
  },
  {
    location_type: 'zip_code',
    location: '8093',
    location_name: 'Z\u00fcrich ETH-H\u00f6nggerberg',
    latitude: 47.3667,
    longitude: 8.55,
    id: 4706,
  },
  {
    location_type: 'zip_code',
    location: '8096',
    location_name: 'Z\u00fcrich IBRS local',
    latitude: 47.3828,
    longitude: 8.5307,
    id: 4707,
  },
  {
    location_type: 'zip_code',
    location: '8098',
    location_name: 'Z\u00fcrich',
    latitude: 47.3667,
    longitude: 8.55,
    id: 4708,
  },
  {
    location_type: 'zip_code',
    location: '8099',
    location_name: 'Z\u00fcrich Sonderdienste',
    latitude: 47.3667,
    longitude: 8.55,
    id: 4709,
  },
  {
    location_type: 'zip_code',
    location: '1961',
    location_name: 'Vernami\u00e8ge',
    latitude: 46.211,
    longitude: 7.4385,
    id: 4710,
  },
  {
    location_type: 'zip_code',
    location: '1968',
    location_name: 'Mase',
    latitude: 46.1933,
    longitude: 7.4518,
    id: 4711,
  },
  {
    location_type: 'zip_code',
    location: '1973',
    location_name: 'Nax',
    latitude: 46.2171,
    longitude: 7.4716,
    id: 4712,
  },
  {
    location_type: 'zip_code',
    location: '2512',
    location_name: 'T\u00fcscherz-Alferm\u00e9e',
    latitude: 47.1233,
    longitude: 7.1986,
    id: 4713,
  },
  {
    location_type: 'zip_code',
    location: '2513',
    location_name: 'Twann',
    latitude: 47.1073,
    longitude: 7.1626,
    id: 4714,
  },
  {
    location_type: 'zip_code',
    location: '5273',
    location_name: 'Oberhofen AG',
    latitude: 47.5563,
    longitude: 8.1269,
    id: 4715,
  },
  {
    location_type: 'zip_code',
    location: '5274',
    location_name: 'Mettau',
    latitude: 47.5695,
    longitude: 8.1266,
    id: 4716,
  },
  {
    location_type: 'zip_code',
    location: '5275',
    location_name: 'Etzgen',
    latitude: 47.575,
    longitude: 8.1152,
    id: 4717,
  },
  {
    location_type: 'zip_code',
    location: '5276',
    location_name: 'Wil AG',
    latitude: 47.5685,
    longitude: 8.1574,
    id: 4718,
  },
  {
    location_type: 'zip_code',
    location: '5277',
    location_name: 'Hottwil',
    latitude: 47.544,
    longitude: 8.1651,
    id: 4719,
  },
  {
    location_type: 'zip_code',
    location: '6571',
    location_name: 'Indemini',
    latitude: 46.0945,
    longitude: 8.8415,
    id: 4720,
  },
  {
    location_type: 'zip_code',
    location: '6572',
    location_name: 'Quartino',
    latitude: 46.1544,
    longitude: 8.9062,
    id: 4721,
  },
  {
    location_type: 'zip_code',
    location: '6573',
    location_name: 'Magadino',
    latitude: 46.143,
    longitude: 8.8791,
    id: 4722,
  },
  {
    location_type: 'zip_code',
    location: '6578',
    location_name: 'Caviano',
    latitude: 46.1023,
    longitude: 8.7757,
    id: 4723,
  },
  {
    location_type: 'zip_code',
    location: '6579',
    location_name: 'Piazzogna',
    latitude: 46.1188,
    longitude: 8.8402,
    id: 4724,
  },
  {
    location_type: 'zip_code',
    location: '6594',
    location_name: 'Contone',
    latitude: 46.158,
    longitude: 8.9209,
    id: 4725,
  },
  {
    location_type: 'zip_code',
    location: '6655',
    location_name: 'Rasa',
    latitude: 46.158,
    longitude: 8.656,
    id: 4726,
  },
  {
    location_type: 'zip_code',
    location: '6656',
    location_name: 'Golino',
    latitude: 46.178,
    longitude: 8.7171,
    id: 4727,
  },
  {
    location_type: 'zip_code',
    location: '6657',
    location_name: 'Palagnedra',
    latitude: 46.149,
    longitude: 8.6395,
    id: 4728,
  },
  {
    location_type: 'zip_code',
    location: '6658',
    location_name: 'Borgnone',
    latitude: 46.1709,
    longitude: 8.6009,
    id: 4729,
  },
  {
    location_type: 'zip_code',
    location: '6802',
    location_name: 'Rivera',
    latitude: 46.1272,
    longitude: 8.9042,
    id: 4730,
  },
  {
    location_type: 'zip_code',
    location: '6803',
    location_name: 'Camignolo',
    latitude: 46.1044,
    longitude: 8.9486,
    id: 4731,
  },
  {
    location_type: 'zip_code',
    location: '6804',
    location_name: 'Bironico',
    latitude: 46.1178,
    longitude: 8.9344,
    id: 4732,
  },
  {
    location_type: 'zip_code',
    location: '6806',
    location_name: 'Sigirino',
    latitude: 46.0885,
    longitude: 8.8872,
    id: 4733,
  },
  {
    location_type: 'zip_code',
    location: '6809',
    location_name: 'Medeglia',
    latitude: 46.1309,
    longitude: 9.0095,
    id: 4734,
  },
  {
    location_type: 'zip_code',
    location: '6835',
    location_name: 'Morbio Superiore',
    latitude: 45.8648,
    longitude: 9.044,
    id: 4735,
  },
  {
    location_type: 'zip_code',
    location: '6837',
    location_name: 'Caneggio',
    latitude: 45.88,
    longitude: 9.055,
    id: 4736,
  },
  {
    location_type: 'zip_code',
    location: '6838',
    location_name: 'Scudellate',
    latitude: 45.9191,
    longitude: 9.0385,
    id: 4737,
  },
  {
    location_type: 'zip_code',
    location: '6839',
    location_name: 'Sagno',
    latitude: 45.8594,
    longitude: 9.0473,
    id: 4738,
  },
  {
    location_type: 'zip_code',
    location: '6911',
    location_name: 'Campione d\'Italia',
    latitude: 45.9681,
    longitude: 8.971,
    id: 4739,
  },
  {
    location_type: 'zip_code',
    location: '7516',
    location_name: 'Maloja',
    latitude: 46.3557,
    longitude: 9.6947,
    id: 4740,
  },
  {
    location_type: 'zip_code',
    location: '7602',
    location_name: 'Casaccia',
    latitude: 46.3726,
    longitude: 9.6725,
    id: 4741,
  },
  {
    location_type: 'zip_code',
    location: '7603',
    location_name: 'Vicosoprano',
    latitude: 46.3326,
    longitude: 9.6378,
    id: 4742,
  },
  {
    location_type: 'zip_code',
    location: '7604',
    location_name: 'Borgonovo',
    latitude: 46.3395,
    longitude: 9.6093,
    id: 4743,
  },
  {
    location_type: 'zip_code',
    location: '7605',
    location_name: 'Stampa',
    latitude: 46.3434,
    longitude: 9.5856,
    id: 4744,
  },
  {
    location_type: 'zip_code',
    location: '7606',
    location_name: 'Promontogno',
    latitude: 46.3152,
    longitude: 9.5795,
    id: 4745,
  },
  {
    location_type: 'zip_code',
    location: '7608',
    location_name: 'Castasegna',
    latitude: 46.3506,
    longitude: 9.5171,
    id: 4746,
  },
  {
    location_type: 'zip_code',
    location: '7610',
    location_name: 'Soglio',
    latitude: 46.3864,
    longitude: 9.5047,
    id: 4747,
  },
  {
    location_type: 'zip_code',
    location: '8238',
    location_name: 'B\u00fcsingen',
    latitude: 47.6964,
    longitude: 8.6876,
    id: 4748,
  },
  {
    location_type: 'zip_code',
    location: '8750',
    location_name: 'Glarus',
    latitude: 47.0384,
    longitude: 9.0533,
    id: 4749,
  },
  {
    location_type: 'zip_code',
    location: '8752',
    location_name: 'N\u00e4fels',
    latitude: 47.0821,
    longitude: 9.0108,
    id: 4750,
  },
  {
    location_type: 'zip_code',
    location: '8753',
    location_name: 'Mollis',
    latitude: 47.0958,
    longitude: 9.0917,
    id: 4751,
  },
  {
    location_type: 'zip_code',
    location: '8754',
    location_name: 'Netstal',
    latitude: 47.066,
    longitude: 9.051,
    id: 4752,
  },
  {
    location_type: 'zip_code',
    location: '8755',
    location_name: 'Ennenda',
    latitude: 47.0439,
    longitude: 9.0978,
    id: 4753,
  },
  {
    location_type: 'zip_code',
    location: '8756',
    location_name: 'Mitl\u00f6di',
    latitude: 47.0146,
    longitude: 9.0906,
    id: 4754,
  },
  {
    location_type: 'zip_code',
    location: '8757',
    location_name: 'Filzbach',
    latitude: 47.1085,
    longitude: 9.1196,
    id: 4755,
  },
  {
    location_type: 'zip_code',
    location: '8758',
    location_name: 'Obstalden',
    latitude: 47.0838,
    longitude: 9.1557,
    id: 4756,
  },
  {
    location_type: 'zip_code',
    location: '8759',
    location_name: 'Netstal',
    latitude: 47.05,
    longitude: 9.05,
    id: 4757,
  },
  {
    location_type: 'zip_code',
    location: '8765',
    location_name: 'Engi',
    latitude: 46.9985,
    longitude: 9.1704,
    id: 4758,
  },
  {
    location_type: 'zip_code',
    location: '8766',
    location_name: 'Matt',
    latitude: 46.9588,
    longitude: 9.1853,
    id: 4759,
  },
  {
    location_type: 'zip_code',
    location: '8767',
    location_name: 'Elm',
    latitude: 46.9035,
    longitude: 9.1472,
    id: 4760,
  },
  {
    location_type: 'zip_code',
    location: '8772',
    location_name: 'Nidfurn',
    latitude: 46.988,
    longitude: 9.0457,
    id: 4761,
  },
  {
    location_type: 'zip_code',
    location: '8774',
    location_name: 'Leuggelbach',
    latitude: 46.9796,
    longitude: 9.0317,
    id: 4762,
  },
  {
    location_type: 'zip_code',
    location: '8775',
    location_name: 'Luchsingen',
    latitude: 46.9802,
    longitude: 8.9987,
    id: 4763,
  },
  {
    location_type: 'zip_code',
    location: '8783',
    location_name: 'Linthal',
    latitude: 46.8705,
    longitude: 8.9724,
    id: 4764,
  },
  {
    location_type: 'zip_code',
    location: '8784',
    location_name: 'Braunwald',
    latitude: 46.9422,
    longitude: 8.9869,
    id: 4765,
  },
  {
    location_type: 'zip_code',
    location: '8865',
    location_name: 'Bilten',
    latitude: 47.1523,
    longitude: 9.0093,
    id: 4766,
  },
  {
    location_type: 'zip_code',
    location: '8866',
    location_name: 'Ziegelbr\u00fccke',
    latitude: 47.1365,
    longitude: 9.0616,
    id: 4767,
  },
  {
    location_type: 'zip_code',
    location: '8867',
    location_name: 'Niederurnen',
    latitude: 47.129,
    longitude: 9.0258,
    id: 4768,
  },
  {
    location_type: 'zip_code',
    location: '8868',
    location_name: 'Oberurnen',
    latitude: 47.1152,
    longitude: 9.0339,
    id: 4769,
  },
  {
    location_type: 'zip_code',
    location: '8874',
    location_name: 'M\u00fchlehorn',
    latitude: 47.0968,
    longitude: 9.1746,
    id: 4770,
  },
  {
    location_type: 'zip_code',
    location: '9491',
    location_name: 'Ruggell',
    latitude: 47.2333,
    longitude: 9.5167,
    id: 4771,
  },
  {
    location_type: 'zip_code',
    location: '9494',
    location_name: 'Schaan Zustellung',
    latitude: 47.1667,
    longitude: 9.5,
    id: 4772,
  },
  {
    location_type: 'zip_code',
    location: '9656',
    location_name: 'Alt St. Johann',
    latitude: 47.1831,
    longitude: 9.263,
    id: 4773,
  },
  {
    location_type: 'zip_code',
    location: '9657',
    location_name: 'Unterwasser',
    latitude: 47.209,
    longitude: 9.2987,
    id: 4774,
  },
  {
    location_type: 'zip_code',
    location: '9658',
    location_name: 'Wildhaus',
    latitude: 47.215,
    longitude: 9.352,
    id: 4775,
  },
  {
    location: 'Nidwalden',
    location_type: 'canton',
    id: 4776,
  },
];

export { data };
