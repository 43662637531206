import React, { useEffect, useState } from 'react';
import { Listings } from './Listings';
import { Overview } from './Overview';
import { Settings } from './Settings';
import { Clients } from './Clients';
import { useTranslation } from 'react-i18next';
import { FavoriteProperties } from './FavoriteProperties';
import { propertyServices } from '../../services/propertyServices';
import { Agreements, Property } from '../../config';
import { useNavigate,useParams } from 'react-router-dom';
import { agreementsServices } from '../../services/agreementsServices';
import { Icons } from '../Common';
import { Identification } from './Identification';

const tabs = [
  {
    text: 'dashboard.overview',
    value: 1,
    visibility: true,
    icon: <Icons.OverviewIcon />
  },
  {
    text: 'dashboard.listings',
    value: 2,
    visibility: true,
    icon: <Icons.ListingIcon />
  },
  {
    text: 'dashboard.favoriteProperties',
    value: 3,
    visibility: true,
    icon: <Icons.FavPropertiesIcon />
  },
  {
    text: 'dashboard.clients',
    value: 4,
    visibility: true,
    icon: <Icons.ClientsIcon />
  },
  {
    text: 'dashboard.settings',
    value: 5,
    visibility: true,
    icon: <Icons.SettingsIcon />
  },
];


const Dashboard = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [agreements, setAgreements] = useState<Array<Agreements>>([]);

  const { tab, } = useParams<{ tab?: string }>();
  const [activeTab, setActiveTab,] = React.useState(parseInt(tab || '0'));
  const navigate = useNavigate();
  const [userProperties, setUserProperties] = useState<Array<Property>>();

  useEffect(() => {
    navigate(`/dashboard/${activeTab}`);
  },
  [activeTab]);

  const handleMenuOpen = () => {
    setIsOpen(!isOpen);
  };

  const [favProperty, setFavProperty] = useState<Array<Property>>([]);
  const [loadingFavorite, setLoadingFavorite] = useState(false);
  const [loadingClients,setLoadingClients] = useState(false);
  const [loadingProperty, setLoadingProperty] = useState(false);

  useEffect(() => {
    setLoadingClients(true);
    agreementsServices
      .getAgreements()
      .then((response: any) => {
        setLoadingClients(false);
        setAgreements(response.data);
        setLoadingClients(false);
        return response;
      })
      .catch(() => {
        setLoadingClients(false);
      });
  }, []);

  useEffect(() => {
    setLoadingFavorite(true);
    propertyServices
      .getFavoriteProperty()
      .then((response) => {
        setLoadingFavorite(false);
        setFavProperty(response.data.data);
        return response;
      })
      .catch(() => {
        setLoadingFavorite(false);
      });
  }, []);

  const { t } = useTranslation();


  useEffect(() => {
    setLoadingProperty(true);
    propertyServices
      .getPropertiesbyId()
      .then((response) => {
        setLoadingProperty(false);
        setUserProperties(response.data);
        window.scrollTo(0, 0);
        return response;
      })
      .catch((error:any) => {
        setLoadingProperty(false);
        // console.log('Failed', error);
      });
  }, []);

  const handleNavigation = (prop:any) => {
    if (prop.type === 'auction') {
      navigate(`/property/auction/details/${prop.id}`);
    }
    else if (prop.type === 'rent') {
      navigate(`/property/rent/details/${prop.id}`);
    }
    else if (prop.type === 'sale') {
      navigate(`/property/sale/details/${prop.id}`);
    }
  };

  return (
    <div className='bg-white pb-[68px] sm:py-[68px] sm:px-20 min-h-screen font-press-start'>
      <div className='flex justify-start lg:hidden pt-2 pr-3'>
        {isOpen ? (
          <button
            className='flex items-center cursor-pointer fixed left-2 top-3 z-20 px-1 py-0.5 rounded-md'
            onClick={handleMenuOpen}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='currentColor'
              className='w-7 h-7 self-center'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M6 18L18 6M6 6l12 12'
              />
            </svg>{' '}
            <p className='mt-1 font-semibold'>{t('dashboard.close')}</p>
          </button>
        ) : (
          <button className='absolute px-5' onClick={handleMenuOpen}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-8 w-8'
              fill='none'
              viewBox='0 0 24 24'
              stroke='black'
              strokeWidth='2'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M4 6h16M4 12h16M4 18h16'
              />
            </svg>
          </button>
        )}
      </div>
      <div className='grid grid-cols-1 lg:grid-cols-6 '>
        <ul className='hidden lg:block space-y-[20px] text-[16px] justify-self-center'>
          {
            tabs?.filter((tab:any) => tab.visibility).map((tab:any) => (
              <li key={tab.value} className='flex space-x-2 justify-end'>
                <button
                  onClick={() => setActiveTab(tab.value)}
                  className={`${
                    activeTab === tab?.value ? 'text-darkGray' : 'text-gray-400'
                  }`}
                >
                  {t(`${tab.text}`)}
                </button>
                {tab.icon}
              </li>
            ))}
        </ul>

        {/* MENU for mobile view */}
        <div
          onClick={handleMenuOpen}
          className={`fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity ${
            isOpen ? 'translate-x-0' : '-translate-x-full'
          }`}
        ></div>
        <div
          className={`top-0 left-0 w-[85vw] bg-white pt-16 overflow-auto fixed h-full transform ease-in-out duration-300 z-10 space-y-4 px-3 ${
            isOpen ? 'translate-x-0' : '-translate-x-full'
          }`}
        >
          {
            tabs?.filter((tab:any) => tab.visibility).map((tab:any) => (
              <li key={tab.value} className='flex space-x-2 justify-start'>
                <button
                  onClick={() => {
                    setActiveTab(tab.value);
                  }}
                  className={` font-bold ${
                    activeTab === tab.value ? 'text-darkGray' : 'text-gray-400'
                  }`}
                >
                  {t(`${tab.text}`)}
                </button>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='14'
                  fill='none'
                  viewBox='0 0 24 14'
                  className='self-center'
                >
                  <path
                    fill={activeTab === tab.value ? '#353535' : '#BDBDBD'}
                    d='M12.015 2c4.751 0 8.063 3.012 9.504 4.636C20.118 8.473 16.806 12 12.015 12c-4.42 0-7.93-3.536-9.478-5.407C4.03 4.946 7.354 2 12.015 2Zm0-2C4.446 0 0 6.551 0 6.551S4.835 14 12.015 14C19.748 14 24 6.551 24 6.551S19.709 0 12.015 0ZM12 7 8.64 4.829a4 4 0 1 0 1.327-1.275L12 7Z'
                  />
                </svg>
              </li>
            ))}
        </div>
        <div className='grid col-start-3 col-span-4'>
          {activeTab === 1 && <>
            <Identification />
            <Overview
              favProperty={favProperty}
              handleNavigation={handleNavigation}
              loadingFavorite={loadingFavorite}
              agreements={agreements}
              setActiveTab={setActiveTab}
              propertiesbyId={userProperties}
              loadingOverviewListing={loadingProperty}
            />
          </>}
          {activeTab === 2 && <Listings handleNavigation={handleNavigation} userProperties={userProperties} loading={loadingProperty}/>}
          {activeTab === 3 && <FavoriteProperties favProperty={favProperty} handleNavigation={handleNavigation} loadingFavorite={loadingFavorite} />}
          {activeTab === 4 && <Clients handleNavigation={handleNavigation} agreements={agreements} loadingClients={loadingClients} />}
          {activeTab === 5 && <Settings />}
        </div>
      </div>
    </div>
  );
};
export { Dashboard };
