import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import house from '../../images/houseDocuments.svg';
import Stepper from './Stepper';
import { propertyServices } from '../../services/propertyServices';
import { PropertyTypes } from '../../config';
import { numberOfRooms } from './numberOfRooms';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { cantons } from '../Register/cantons';

interface Props {
  handleImageChange: any;
  type: string;
  steps: any;
  handleClickNext: any;
  handleClickPrev: any;
  t: any;
  handleCheckedChars: any;
  handlePropertyInputChange: any;
  propertyInput: any;
  setPropertyInput: any;
  handleAddressChange: any;
  images: any;
  setImages: any;
  setPrevImages: any;
  prevImages: any;
  prevDocuments: any;
  documents: any;
  setPrevDocuments: any;
  setDocuments: any;
  error: any;
  setError: any;
  paymentTypeData: any;
  video: any;
  setVideo: any;
  prevVideo: any;
  setPrevVideo: any;
  address: any;
  propertyChar:any;
}

const PropertyForm = (props: Props) => {
  const {
    type,
    steps,
    handleClickNext,
    handleClickPrev,
    t,
    handleCheckedChars,
    handlePropertyInputChange,
    propertyInput,
    setPropertyInput,
    images,
    setImages,
    setPrevImages,
    prevImages,
    prevDocuments,
    documents,
    setPrevDocuments,
    setDocuments,
    error,
    setError,
    paymentTypeData,
    video,
    prevVideo,
    setPrevVideo,
    setVideo,
    propertyChar
  } = props;
  const floors = [
    {
      id: 5,
      floor: 'propertyDetails.floors.groundFloor',
    },
    {
      id: 7,
      floor: 'propertyDetails.floors.1-floor',
    },
    {
      id: 8,
      floor: 'propertyDetails.floors.2-floor',
    },
    {
      id: 9,
      floor: 'propertyDetails.floors.3-floor',
    },
    {
      id: 10,
      floor: 'propertyDetails.floors.4-floor',
    },
    {
      id: 11,
      floor: 'propertyDetails.floors.5-floor',
    },
    {
      id: 12,
      floor: 'propertyDetails.floors.6-floor',
    },
    {
      id: 13,
      floor: 'propertyDetails.floors.7-floor',
    },
    {
      id: 14,
      floor: 'propertyDetails.floors.8-floor',
    },
    {
      id: 15,
      floor: 'propertyDetails.floors.9-floor',
    },
    {
      id: 16,
      floor: 'propertyDetails.floors.10-floor',
    },
  ];
  const [step] = useState(2);
  const navigate = useNavigate();

  const [propertyTypes, setPropertyTypes] = useState<Array<PropertyTypes>>();
  const [available, setAvailable] = useState('');

  const validZip = new RegExp(/^(\d{4})$/);

  useEffect(() => {
    propertyServices
      .getListingProperties()
      .then((response) => {
        // window.scrollTo(0, 0);
        setPropertyTypes(response.data.data);
      })
      .catch((error) => {
        // console.log('Error getting the properties', error);
      });
  }, []);

  const toArr = (name: any) => {
    const newArr = name.split('&');
    return newArr;
  };

  const preventMinus = (e: any) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };


  const handleImageChange = (e: any) => {
    const { files } = e.target;
    const img = Array.from(files);
    const imagesArray = img.map((img: any) => {
      return URL.createObjectURL(img);
    });
    if (paymentTypeData.payment.tier === 'silver') {
      setPrevImages((previousImages: any) => previousImages.concat(imagesArray).slice(0,5));
      setImages((previousImages: any) => previousImages.concat(img).slice(0, 5));
    } else if (paymentTypeData.payment.tier === 'gold') {
      setPrevImages((previousImages: any) => previousImages.concat(imagesArray).slice(0,10));
      setImages((previousImages: any) => previousImages.concat(img).slice(0, 10));
    } else if (paymentTypeData.payment.tier === 'platinum') {
      setPrevImages((previousImages: any) => previousImages.concat(imagesArray).slice(0,17));
      setImages((previousImages: any) => previousImages.concat(img).slice(0, 17));
    }
  };
  const handleDocumentChange = (e: any) => {
    const { files } = e.target;
    const doc = Array.from(files);
    const documentsArray = doc.map((doc: any) => {
      return URL.createObjectURL(doc);
    });
    if (paymentTypeData.payment.tier === 'silver') {
      setPrevDocuments((prevDocs: any) => prevDocs.concat(documentsArray).slice(0, 1));
      // console.log(doc, 'dddo');
      setDocuments((prevDocs: any) => prevDocs.concat(doc).slice(0, 1));
      // console.log(doc, 'dddo');
    } else if (paymentTypeData.payment.tier === 'gold') {
      setPrevDocuments((prevDocs: any) => prevDocs.concat(documentsArray).slice(0, 1));
      // console.log(doc);
      setDocuments((prevDocs: any) => prevDocs.concat(doc).slice(0, 1));
    }  else if (paymentTypeData.payment.tier === 'platinum') {
      setPrevDocuments((prevDocs: any) => prevDocs.concat(documentsArray).slice(0, 1));
      // console.log(doc);
      setDocuments((prevDocs: any) => prevDocs.concat(doc).slice(0, 1));
    }
  };
  const handleVideoChange = (e: any) => {
    const { files } = e.target;
    const vid = Array.from(files);
    const videoArray = vid.map((img: any) => {
      return URL.createObjectURL(img);
    });
    setPrevVideo((previousVideo: any) => previousVideo.concat(videoArray).slice(0,1));
    // console.log(vid, 'test');
    setVideo((previousVideos: any) => previousVideos.concat(vid).slice(0, 1));
  };

  const handleImageDelete = (image: any) => {
    const previewImages = prevImages.filter(
      (item: any, index: any) => index !== image
    );
    const mainImages = images.filter(
      (item: any, index: any) => index !== image
    );
    setPrevImages(previewImages);
    setImages(mainImages);
  };


  const handleDocumentDelete = (document: any) => {
    const previewDocument = prevDocuments.filter(
      (item: any, index: any) => index !== document
    );
    const mainDocument = documents.filter(
      (item: any, index: any) => index !== document
    );
    setPrevDocuments(previewDocument);
    setDocuments(mainDocument);
  };

  const handleVideoDelete = (videos: any) => {
    // console.log(typeof (video), 'typ');
    const previewVideo = prevVideo.filter(
      (item: any, index: any) => index !== videos
    );
    const mainVideo = video.filter(
      (item: any, index: any) => index !== videos
    );
    setPrevVideo(previewVideo);
    setVideo(mainVideo);
  };

  const [characters, updateCharacters] = useState(prevImages);
  const [updateMain, setUpdateMain] = useState(images);

  useEffect(() => {
    updateCharacters(prevImages);
    setUpdateMain(images);
  },[prevImages]);


  const handleOnDragEnd = (result: any) => {
    const imgItems = Array.from(updateMain);
    const [reorderedItemImg] = imgItems.splice(result.source.index, 1);
    imgItems.splice(result.destination.index, 0, reorderedItemImg);
    setUpdateMain(imgItems);
    const items = Array.from(characters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    updateCharacters(items);
  };

  const handleNext = () => {
    const errorList = [];
    if (!propertyInput.title) {
      errorList.push('title');
    }
    if (!propertyInput.address.street) {
      errorList.push('street');
    }
    if (!propertyInput.address.zip) {
      errorList.push('zip');
    }
    if (!propertyInput.address.city) {
      errorList.push('city');
    }
    if (!propertyInput.address.canton) {
      errorList.push('canton');
    }
    if (!propertyInput.property_types) {
      errorList.push('property_types');
    } else if (errorList.length == 0) {
      handleClickNext();
    }
    if (images.length >= 0) {
      setImages(updateMain);
      setPrevImages(characters);
    }
    setError(errorList);
  };

  const [sumOfRooms, setSumOfRooms] = useState({
    sum:0
  });
  const [hasError, setHasError] = useState(false);
  useEffect(()=>{
    if (sumOfRooms.sum > parseInt(propertyInput.size_rooms)) {
      if (!error.includes('rooms')) {
        setHasError(true);
        error.push('rooms');
      }
    }
    else {
      if (error.includes('rooms')) {
        error.pop('rooms');
        setHasError(false);
      }
    }
  },[sumOfRooms, propertyInput.size_rooms, hasError]);


  return (
    <div className='bg-white sm:px-24 px-2 sm:py-12 py-2 font-press-start'>
      <div className='flex justify-between sm:pb-10 sm:pt-20 pb-5 pt-10'>
        <div className=''>
          <p className='text-[32px]'>{t('listingItem.createListing')}</p>
        </div>
        <div className='pt-2'>
          <button
            onClick={() => navigate(-1)}
            className='bg-darkGray w-[100px] h-[34px] text-center text-white rounded-md'
          >
            {t('listingItem.buttonCancel')}
          </button>
        </div>
      </div>
      <div className='border border-darkGray w-full ' />
      <div className='sm:grid sm:grid-cols-1 md:grid md:grid-cols-1 lg:grid lg:grid-cols-3 '>
        <div className='col-span-2 pt-10'>
          <p className='text-[22px]'> {t('listingItem.basicInformation')}</p>
          <div className='py-10 sm:px-0 px-5'>
            <div className='sm:grid sm:grid-cols-2 sm:gap-28'>
              <div className='relative z-0 w-full mb-6 group'>
                <label className='font-bold' title={t('reqField.requiredField')}> {t('listingItem.title')}*</label>
                <input
                  onChange={handlePropertyInputChange}
                  name='title'
                  type='text'
                  value={propertyInput.title}
                  maxLength={100}
                  className={`block my-2.5 px-0 w-full text-base text-gray-900 bg-transparent ${
                    error?.includes('title')
                      ? 'border-0 border-b-2 border-auctionicRed'
                      : 'border-0 border-b-2 border-gray-300'
                  } appearance-none focus:outline-none focus:ring-0 focus:border-black peer required `}
                  placeholder=''
                  required
                />
                {error?.includes('title') ? (
                  <p className='text-xs text-red-500 absolute'>
                    {t('listingItem.propertyForm.titleRequired')}
                  </p>
                ) : (
                  ''
                )}
              </div>
            </div>
            <p className='font-bold'>{t('listingItem.address')}</p>
            <div className='sm:flex mt-4'>
              <div className='sm:w-[100%]'>
                <div className='relative z-0 w-full mb-6 group'>
                  <label className='font-bold' title={t('reqField.requiredField')}>{t('listingItem.streetNo')}*</label>
                  <input id="street" name="street"
                    value={propertyInput.address.street}
                    onChange={(e) => {
                      setPropertyInput({
                        ...propertyInput,
                        address: {
                          ...propertyInput.address,
                          street: e.target.value,
                        }
                      });
                    }}
                    className='py-2 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-black peer required'
                    required/>
                  {error?.includes('street') ? (
                    <p className='text-xs text-red-500 absolute'>
                      {t('listingItem.propertyForm.streetRequired')}
                    </p>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
            <div className='font-bold sm:flex sm:w-[100%]'>
              <div className='relative z-0 w-full mb-6 group'>
                <label className='' title={t('reqField.requiredField')}>{t('listingItem.postalZip')}*</label>
                <input id="zip" name="zip"
                  value={propertyInput.address.zip}
                  onChange={(e) => {
                    setPropertyInput({
                      ...propertyInput,
                      address: {
                        ...propertyInput.address,
                        zip: e.target.value,
                      }
                    });
                  }}
                  className='py-2 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-black peer required'
                  required/>
                {error?.includes('zip') ? (
                  <p className='text-xs text-red-500 absolute'>
                    {t('listingItem.propertyForm.zipRequired')}
                  </p>
                ) : (
                  <>
                    { !validZip.test(propertyInput.address.zip)  && propertyInput.address.zip != '' && (
                      <p className='text-xs text-red-500 absolute'>
                        *{t('listingItem.propertyForm.invalidZip')}
                      </p>)}
                  </>
                )}

              </div>
              <div className='relative z-0 w-full mb-6 group sm:ml-4'>
                <label className='' title={t('reqField.requiredField')}>{t('listingItem.city')}*</label>
                <input id="city"
                  value={propertyInput.address.city}
                  onChange={(e) => {
                    setPropertyInput({
                      ...propertyInput,
                      address: {
                        ...propertyInput.address,
                        city: e.target.value,
                      }
                    });
                  }}
                  name="city"
                  className='py-2 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-black peer required'
                />
                {error?.includes('city') ? (
                  <p className='text-xs text-red-500 absolute'>
                    {t('listingItem.propertyForm.cityRequired')}
                  </p>
                ) : (
                  ''
                )}
              </div>
              <div className='relative z-0 w-full mb-6 group sm:ml-9'>
                <label className='' title={t('reqField.requiredField')}>{t('listingItem.propertyForm.canton')}*</label>
                <select
                  name='canton'
                  value={propertyInput.address.canton}
                  onChange={(e) => {
                    setPropertyInput({
                      ...propertyInput,
                      address: {
                        ...propertyInput.address,
                        canton: e.target.value,
                      }
                    });
                  }}
                  className={`appearance-none z-10 block mt-3 pb-[0.5rem] px-0 w-full text-base sm:text-sm text-gray-900 bg-transparent ${
                    error?.includes('business_type')
                      ? 'border-0 border-b-2 border-auctionicRed'
                      : 'border-0 border-b-2 border-gray-300'
                  } focus:outline-none focus:ring-0 focus:border-b-black peer required `}
                >
                  <option defaultChecked className=''>
                    {t('listingItem.propertyForm.chooseCanton')}
                  </option>
                  {cantons.map((canton) => {
                    return (
                      <option key={canton.id} value={canton.location}>
                        {canton.location}
                      </option>
                    );
                  })}
                </select>
                {error?.includes('canton') ? (
                  <p className='text-xs text-red-500 absolute'>
                    {t('listingItem.propertyForm.cantonRequired')}
                  </p>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className='sm:w-[50%]'>
              <div className='relative z-0 w-full mb-6 group'>
                <label className=' font-bold' title={t('reqField.requiredField')}>
                  {t('listingItem.propertyType')}*
                </label>
                <div className='flex'>
                  <select
                    name='property_types'
                    onChange={handlePropertyInputChange}
                    value={propertyInput?.property_types != undefined ? propertyInput.property_types : 100 }
                    className={`z-10 appearance-none block my-3 px-0 w-full text-base text-gray-900 bg-transparent ${
                      error?.includes('property_types')
                        ? 'border-0 border-b-2 border-auctionicRed'
                        : 'border-0 border-b-2 border-gray-300'
                    } focus:outline-none focus:ring-0 focus:border-b-black peer required `}
                  >
                    <option disabled key={100} value={100}>
                      {t('listingItem.propertyType')}
                    </option>
                    {propertyTypes?.map((prop) => {
                      return (
                        <option key={prop.id} value={prop.id}>
                          {localStorage.getItem('locale') === 'de' ? (
                            <>{toArr(prop.name)[0]}</>
                          ) : localStorage.getItem('locale') === 'en' ? (
                            <>{toArr(prop.name)[1]}</>
                          ) : localStorage.getItem('locale') === 'fr' ? (
                            <>{toArr(prop.name)[2]}</>
                          ) : localStorage.getItem('locale') === 'it' ? (
                            <>{toArr(prop.name)[3]}</>
                          ) : <>{toArr(prop.name)[0]}</> }
                        </option>
                      );
                    })}
                  </select>
                  <div className='z-0'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth='2.0'
                      stroke='currentColor'
                      className='w-6 h-6 mt-3 absolute flex justify-end -ml-6'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M19.5 8.25l-7.5 7.5-7.5-7.5'
                      />
                    </svg>
                  </div>
                </div>
                {error?.includes('property_types') ? (
                  <p className='text-xs text-red-500 absolute'>
                    {t('listingItem.propertyForm.propertyTypeRequired')}
                  </p>
                ) : (
                  ''
                )}
              </div>
              {propertyInput.property_types?.includes(9) ? '' :
                <div className='relative z-0 w-full mb-6 group'>
                  <label className=' font-bold'>{t('listingItem.size')}</label>
                  <input
                    onChange={handlePropertyInputChange}
                    type='number'
                    name='plot_area'
                    min={0}
                    value={propertyInput.plot_area}
                    className='block my-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-black peer required'
                    placeholder=''
                    required
                    onKeyDown={preventMinus}
                  />
                </div>
              }
              {propertyInput.property_types?.includes(8) || propertyInput.property_types?.includes(11) ? '' :
                <div className='relative z-0 w-full mb-6 group'>
                  {propertyInput.property_types?.includes(9) ?
                    <label className=' font-bold'>
                        Parking space (m²)
                    </label>
                    :
                    <label className=' font-bold'>
                      {t('listingItem.livingSpace')}
                    </label>
                  }
                  <input
                    onChange={handlePropertyInputChange}
                    type='number'
                    name='living_space'
                    min={0}
                    onKeyDown={preventMinus}
                    value={propertyInput.living_space}
                    className='block my-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-black peer required'
                    placeholder=''
                    required
                  />
                </div>
              }
              {propertyInput.property_types?.includes(8) || propertyInput.property_types?.includes(9) || propertyInput.property_types?.includes(11) ? '' :
                <div className='relative z-0 w-full mb-6 group'>
                  <label className=' font-bold'>
                    {t('listingItem.numberOfRooms')}
                  </label>
                  <div className='flex'>
                    <select
                      name='size_rooms'
                      value={propertyInput.size_rooms}
                      onChange={handlePropertyInputChange}
                      className='appearance-none z-10 block my-3 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-b-gray-300 focus:outline-none focus:ring-0 focus:border-b-black peer required '
                    >
                      <option defaultChecked disabled>
                          *{t('listingItem.numberOfRooms')}
                      </option>
                      {numberOfRooms.map((rooms) => {
                        return (
                          <option key={rooms.id} value={rooms.room}>
                            {rooms.room}
                          </option>
                        );
                      })}
                    </select>
                    <div className='z-0'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth='2.0'
                        stroke='currentColor'
                        className='w-6 h-6 mt-3 absolute flex justify-end -ml-6'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M19.5 8.25l-7.5 7.5-7.5-7.5'
                        />
                      </svg>
                    </div>
                  </div>
                  {error.includes('rooms') ? <p className='text-auctionicRed text-xs'>{t('listingItem.numberOfRoomsErrorCheck')}</p> : <p className='text-auctionicRed text-xs'></p>}
                </div>
              }
            </div>
            <div className='pt-10'>
              {propertyInput.property_types?.includes(8) || propertyInput.property_types?.includes(9) || propertyInput.property_types?.includes(11) || propertyInput.property_types?.includes(10) ? '' :
                <div>
                  <p className=' font-bold'>{t('listingItem.rooms')}</p>
                  <div className='sm:grid sm:grid-cols-1 md:grid md:grid-cols-1 lg:grid lg:grid-cols-1 xl:grid xl:grid-cols-3  pt-5 '>
                    <div className='flex justify-between '>
                      <label>{t('listingItem.bedrooms')}</label>
                      <div className='flex px-5 space-x-3'>
                        <button
                          disabled={propertyInput.bedroom == 0}
                          onClick={() => {
                            setPropertyInput((prevState: any) => ({
                              ...prevState,
                              bedroom: prevState.bedroom - 1,
                            })); setSumOfRooms((prevs:any)=>({
                              ...prevs,
                              sum:prevs.sum-1,
                            }));}
                          }
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='h-4 w-4'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                            strokeWidth='2'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M16 17l-4 4m0 0l-4-4m4 4V3'
                            />
                          </svg>
                        </button>
                        <span defaultValue={propertyInput.bedroom}>{propertyInput.bedroom}</span>
                        <button
                          onClick={() =>{
                            setPropertyInput((prevState: any) => ({
                              ...prevState,
                              bedroom: prevState.bedroom + 1,
                            })); setSumOfRooms((prevs:any)=>({
                              ...prevs,
                              sum:prevs.sum+1,
                            }));}
                          }
                          disabled={hasError}
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='h-4 w-4'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                            strokeWidth='2'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M8 7l4-4m0 0l4 4m-4-4v18'
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className='flex justify-between'>
                      <label>{t('listingItem.bathroom')}</label>
                      <div className='flex px-5 space-x-3'>
                        <button
                          disabled={propertyInput.bathroom == 0}
                          onClick={() => {
                            setPropertyInput((prevState: any) => ({
                              ...prevState,
                              bathroom: prevState.bathroom - 1,
                            })); }
                          }
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='h-4 w-4'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                            strokeWidth='2'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M16 17l-4 4m0 0l-4-4m4 4V3'
                            />
                          </svg>
                        </button>
                        <span defaultValue={propertyInput.bathroom}>{propertyInput.bathroom}</span>
                        <button
                          onClick={() => {
                            setPropertyInput((prevState: any) => ({
                              ...prevState,
                              bathroom: prevState.bathroom + 1,
                            }));}
                          }
                          disabled={hasError}
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='h-4 w-4'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                            strokeWidth='2'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M8 7l4-4m0 0l4 4m-4-4v18'
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className='flex justify-between'>
                      <label>{t('listingItem.shower')}</label>
                      <div className='flex px-5 space-x-3'>
                        <button
                          disabled={propertyInput.shower == 0}
                          onClick={() => {
                            setPropertyInput((prevState: any) => ({
                              ...prevState,
                              shower: prevState.shower - 1,
                            }));}
                          }
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='h-4 w-4'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                            strokeWidth='2'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M16 17l-4 4m0 0l-4-4m4 4V3'
                            />
                          </svg>
                        </button>
                        <span defaultValue={propertyInput.shower}>{propertyInput.shower}</span>
                        <button
                          onClick={() => {
                            setPropertyInput((prevState: any) => ({
                              ...prevState,
                              shower: prevState.shower + 1,
                            }));}
                          }
                          disabled={hasError}
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='h-4 w-4'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                            strokeWidth='2'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M8 7l4-4m0 0l4 4m-4-4v18'
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='sm:grid sm:grid-cols-1 md:grid md:grid-cols-1 lg:grid lg:grid-cols-1 xl:grid xl:grid-cols-3 pt-5'>
                    <div className='flex justify-between'>
                      <label>{t('listingItem.livingRoom')}</label>
                      <div className='flex px-5 space-x-3'>
                        <button
                          disabled={propertyInput.living_room == 0}
                          onClick={() => {
                            setPropertyInput((prevState: any) => ({
                              ...prevState,
                              living_room: prevState.living_room - 1,
                            })); setSumOfRooms((prevs:any)=>({
                              ...prevs,
                              sum:prevs.sum-1
                            }));}
                          }
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='h-4 w-4'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                            strokeWidth='2'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M16 17l-4 4m0 0l-4-4m4 4V3'
                            />
                          </svg>
                        </button>
                        <span defaultValue={propertyInput.living_room}>{propertyInput.living_room}</span>
                        <button
                          onClick={() => {
                            setPropertyInput((prevState: any) => ({
                              ...prevState,
                              living_room: prevState.living_room + 1,
                            })); setSumOfRooms((prevs:any)=>({
                              ...prevs,
                              sum:prevs.sum+1
                            }));}
                          }
                          disabled={hasError}
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='h-4 w-4'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                            strokeWidth='2'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M8 7l4-4m0 0l4 4m-4-4v18'
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className='flex justify-between'>
                      <label>{t('listingItem.kitchen')}</label>
                      <div className='flex px-5 space-x-3'>
                        <button
                          disabled={propertyInput.kitchen == 0}
                          onClick={() =>{
                            setPropertyInput((prevState: any) => ({
                              ...prevState,
                              kitchen: prevState.kitchen - 1,
                            }));}
                          }
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='h-4 w-4'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                            strokeWidth='2'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M16 17l-4 4m0 0l-4-4m4 4V3'
                            />
                          </svg>
                        </button>
                        <span defaultValue={propertyInput.kitchen}>{propertyInput.kitchen}</span>
                        <button
                          onClick={() =>{
                            setPropertyInput((prevState: any) => ({
                              ...prevState,
                              kitchen: prevState.kitchen + 1,
                            }));}
                          }
                          disabled={hasError}
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='h-4 w-4'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                            strokeWidth='2'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M8 7l4-4m0 0l4 4m-4-4v18'
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className='flex justify-between'>
                      <label>{t('listingItem.garage')}</label>
                      <div className='flex px-5 space-x-3'>
                        <button
                          disabled={propertyInput.garage == 0}
                          onClick={() =>
                            setPropertyInput((prevState: any) => ({
                              ...prevState,
                              garage: prevState.garage - 1,
                            }))
                          }
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='h-4 w-4'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                            strokeWidth='2'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M16 17l-4 4m0 0l-4-4m4 4V3'
                            />
                          </svg>
                        </button>
                        <span defaultValue={propertyInput.garage}>{propertyInput.garage}</span>
                        <button
                          onClick={() =>
                            setPropertyInput((prevState: any) => ({
                              ...prevState,
                              garage: prevState.garage + 1,
                            }))
                          }
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='h-4 w-4'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                            strokeWidth='2'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M8 7l4-4m0 0l4 4m-4-4v18'
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='mt-10'>
                    <p className='font-bold'>{t('listingItem.floors')}</p>
                    <div className='sm:grid sm:grid-cols-2 sm:gap-28 mt-5'>
                      <div className='relative z-0 w-full mb-6 group'>
                        <label className=''>{t('listingItem.floor')}</label>
                        <div className='flex'>
                          <select
                            name='floor'
                            onChange={handlePropertyInputChange}
                            defaultValue={propertyInput.floor}
                            className='z-10 appearance-none block my-3 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-b-gray-300 focus:outline-none focus:ring-0 focus:border-b-black peer required '
                          >
                            <option defaultChecked>{t('listingItem.not_specified_floor')}</option>
                            {floors.map((floor) => {
                              return (
                                <option key={floor.id} value={floor.floor}>
                                  {t(`${floor.floor}`)}
                                </option>
                              );
                            })}
                          </select>
                          <div className='z-0'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth='2.0'
                              stroke='currentColor'
                              className='w-6 h-6 mt-3 absolute flex justify-end -ml-6'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M19.5 8.25l-7.5 7.5-7.5-7.5'
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                      {propertyInput.property_types?.includes(1) || propertyInput.property_types?.includes(2) || propertyInput.property_types?.includes(3) ? (
                        <div className='relative z-0 w-full mb-6 group'>
                          <label className=''>{t('listingItem.yearBuilt')}</label>
                          <input
                            onChange={handlePropertyInputChange}
                            type='number'
                            name='year_built'
                            value={propertyInput?.year_built != undefined ? propertyInput?.year_built : ''}
                            className='block my-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer required'
                            placeholder=' '
                            required
                          />
                        </div>
                      ) : ''}
                    </div>
                  </div>
                </div>
              }
              <div className='mt-10'>
                <p className='font-bold'>{t('listingItem.characteristics')}</p>
                <div className='sm:grid sm:grid-cols-2 mt-5 pb-24'>
                  {propertyInput.property_types?.includes(8) ?
                    propertyChar?.filter((char: { id: number; }) => (char.id == 5) || (char.id == 8) || (char.id == 14)).map((filteredChars: any) => {
                      return (
                        <label
                          key={filteredChars.id}
                          className='labels pr-10 text-darkGray font-sm font-bold'
                        >
                          {localStorage.getItem('locale') === 'de' ? (
                            <p>{toArr(filteredChars.char)[0]}</p>
                          ) : localStorage.getItem('locale') === 'en' ? (
                            <p>{toArr(filteredChars.char)[1]}</p>
                          ) : localStorage.getItem('locale') === 'fr' ? (
                            <p>{toArr(filteredChars.char)[2]}</p>
                          ) : localStorage.getItem('locale') === 'it' ? (
                            <p>{toArr(filteredChars.char)[3]}</p>
                          ) : <>{toArr(filteredChars.char)[0]}</>}
                          <input
                            type='checkbox'
                            value={filteredChars.id}
                            className='h-6 w-6 absolute'
                            name='property_chars'
                            checked={propertyInput.property_chars.includes(filteredChars.id)}
                            onChange={handleCheckedChars}
                          />
                          <span className='checks'></span>
                        </label>
                      );
                    }) : propertyInput.property_types?.includes(9) ?
                      propertyChar?.filter((char: { id: number; }) => (char.id == 1) || (char.id == 2) || (char.id == 3) || (char.id == 14)).map((filteredChars: any) => {
                        return (
                          <label
                            key={filteredChars.id}
                            className='labels pr-10 text-darkGray font-sm font-bold'
                          >
                            {localStorage.getItem('locale') === 'de' ? (
                              <p>{toArr(filteredChars.char)[0]}</p>
                            ) : localStorage.getItem('locale') === 'en' ? (
                              <p>{toArr(filteredChars.char)[1]}</p>
                            ) : localStorage.getItem('locale') === 'fr' ? (
                              <p>{toArr(filteredChars.char)[2]}</p>
                            ) : localStorage.getItem('locale') === 'it' ? (
                              <p>{toArr(filteredChars.char)[3]}</p>
                            ) : <>{toArr(filteredChars.char[0])}</>}
                            <input
                              type='checkbox'
                              value={filteredChars.id}
                              className='h-6 w-6 absolute'
                              name='property_chars'
                              checked={propertyInput.property_chars.includes(filteredChars.id)}
                              onChange={handleCheckedChars}
                            />
                            <span className='checks'></span>
                          </label>
                        );
                      })
                      : propertyInput.property_types?.includes(10) ?
                        propertyChar?.filter((char: { id: number; }) => (char.id != 4) && (char.id != 11)).map((filteredChars: any) => {
                          return (
                            <label
                              key={filteredChars.id}
                              className='labels pr-10 text-darkGray font-sm font-bold'
                            >
                              {localStorage.getItem('locale') === 'de' ? (
                                <p>{toArr(filteredChars.char)[0]}</p>
                              ) : localStorage.getItem('locale') === 'en' ? (
                                <p>{toArr(filteredChars.char)[1]}</p>
                              ) : localStorage.getItem('locale') === 'fr' ? (
                                <p>{toArr(filteredChars.char)[2]}</p>
                              ) : localStorage.getItem('locale') === 'it' ? (
                                <p>{toArr(filteredChars.char)[3]}</p>
                              ) : <>{toArr(filteredChars.char[0])}</>}
                              <input
                                type='checkbox'
                                value={filteredChars.id}
                                className='h-6 w-6 absolute'
                                name='property_chars'
                                checked={propertyInput.property_chars.includes(filteredChars.id)}
                                onChange={handleCheckedChars}
                              />
                              <span className='checks'></span>
                            </label>
                          );
                        }) :
                        propertyInput.property_types?.includes(11) ?
                          propertyChar?.filter((char: { id: number; }) => (char.id == 8) || (char.id == 5) || (char.id == 14)).map((filteredChars: any) => {
                            return (
                              <label
                                key={filteredChars.id}
                                className='labels pr-10 text-darkGray font-sm font-bold'
                              >
                                {localStorage.getItem('locale') === 'de' ? (
                                  <p>{toArr(filteredChars.char)[0]}</p>
                                ) : localStorage.getItem('locale') === 'en' ? (
                                  <p>{toArr(filteredChars.char)[1]}</p>
                                ) : localStorage.getItem('locale') === 'fr' ? (
                                  <p>{toArr(filteredChars.char)[2]}</p>
                                ) : localStorage.getItem('locale') === 'it' ? (
                                  <p>{toArr(filteredChars.char)[3]}</p>
                                ) : <>{toArr(filteredChars.char)[0]}</>}
                                <input
                                  type='checkbox'
                                  value={filteredChars.id}
                                  className='h-6 w-6 absolute'
                                  name='property_chars'
                                  checked={propertyInput.property_chars.includes(filteredChars.id)}
                                  onChange={handleCheckedChars}
                                />
                                <span className='checks'></span>
                              </label>
                            );
                          })
                          :
                          propertyChar?.map((char: any) => {
                            return (
                              <label
                                key={char.id}
                                className='labels pr-10 text-darkGray font-sm font-bold'
                              >
                                {localStorage.getItem('locale') === 'de' ? (
                                  <p>{toArr(char.char)[0]}</p>
                                ) : localStorage.getItem('locale') === 'en' ? (
                                  <p>{toArr(char.char)[1]}</p>
                                ) : localStorage.getItem('locale') === 'fr' ? (
                                  <p>{toArr(char.char)[2]}</p>
                                ) : localStorage.getItem('locale') === 'it' ? (
                                  <p>{toArr(char.char)[3]}</p>
                                ) : <>{toArr(char.char)[0]}</>}
                                <input
                                  type='checkbox'
                                  value={char.id}
                                  className='h-6 w-6 absolute'
                                  name='property_chars'
                                  checked={propertyInput.property_chars.includes(char.id)}
                                  onChange={handleCheckedChars}
                                />
                                <span className='checks'></span>
                              </label>
                            );
                          })
                  }
                </div>
              </div>
              {type !== 'auction' && (
                <div className='text-[18px]'>
                  <label className='pr-10 font-bold'>
                    {t('listingItem.availabletoVisit')}
                  </label>
                  <input
                    type='datetime-local'
                    name='available_to_visit'
                    min={new Date().toISOString().slice(0, -8)}
                    onChange={handlePropertyInputChange}
                    value={propertyInput.available_to_visit != null ? propertyInput?.available_to_visit : ''}
                    className='form-control  block w-[85vw] sm:w-full px-3 py-1.5 mb-4 mt-3 text-base font-normal text-gray-700 bg-white bg-clip-padding border-2 border-solid border-gray-300 focus:border-gray-800 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none'
                    placeholder='Available to visit'
                  />
                </div>
              )}
              <div className='text-[18px]'>
                <label className='pr-10 font-bold'>
                  {t('listingItem.availabletoEnter')}
                </label>
              </div>
              <div className='block sm:flex sm:space-x-4 pt-4 pb-3'>
                <div className='flex space-x-2'>
                  <label className='label'>
                    {t('listingItem.propertyForm.immediately')}
                    <input
                      type='radio'
                      name='type'
                      className='h-6 w-6 '
                      value='immediately'
                      onClick={(e: any) => setAvailable(e.target.value)}
                      onChange={() => {
                        setPropertyInput((inputs: any) => ({
                          ...inputs,
                          available_to_enter: paymentTypeData?.start_date
                        }));
                      }}
                    />
                    <span className='checkmark'></span>
                  </label>
                </div>
                <div className='flex space-x-2'>
                  <label className='label'>
                    {t('listingItem.propertyForm.ask')}
                    <input
                      type='radio'
                      name='type'
                      className='h-6 w-6'
                      value='ask'
                      onClick={(e: any) => setAvailable(e.target.value)}
                      onChange={() => {
                        setPropertyInput((inputs: any) => ({
                          ...inputs,
                          available_to_enter: ''
                        }));
                      }}
                    />
                    <span className='checkmark'></span>
                  </label>{' '}
                </div>
                <div className='flex space-x-2'>
                  <label className='label'>
                    {t('listingItem.propertyForm.inputSelect')}
                    <input
                      type='radio'
                      name='type'
                      className='h-6 w-6'
                      value='field'
                      onClick={(e: any) => setAvailable(e.target.value)}
                      onChange={() => {
                        setPropertyInput((inputs: any) => ({
                          ...inputs,
                          available_to_enter: ''
                        }));
                      }}
                    />
                    <span className='checkmark'></span>
                  </label>{' '}
                </div>
              </div>
              {available == '' || available == 'field' ?
                <div className='text-[18px]'>
                  <input
                    type='datetime-local'
                    name='available_to_enter'
                    value={propertyInput.available_to_enter != null ? propertyInput.available_to_enter : ''}
                    min={new Date().toISOString().slice(0, -8)}
                    onChange={handlePropertyInputChange}
                    className='form-control  block w-[85vw] sm:w-full px-3 py-1.5 mb-4 mt-3 text-base font-normal text-gray-700 bg-white bg-clip-padding border-2 border-solid border-gray-300 focus:border-gray-800 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none'
                    placeholder='Available to enter'
                  />
                </div>
                : '' }
              <div className=''>
                <h1 className='text-[18px] pb-5 font-bold'>
                  {t('listingItem.description')}
                </h1>
                <textarea
                  name='description'
                  onChange={handlePropertyInputChange}
                  value={propertyInput.description}
                  className='text-[14px] w-full h-96 border-2 rounded-md px-1'
                  placeholder={t('listingItem.describeListing')}
                />
              </div>
              <div className='border border-darkGray w-full mt-8 ' />
              <p className='text-[20px] font-semibold pt-4 pb-3 text-center sm:text-left'>
                {t('listingItem.photos')}
              </p>
              <div className='mb-2'>
                <div className='h-[204px] w-[204px] border-2 border-black rounded-md '>
                  <input
                    className='absolute cursor-pointer opacity-0 h-[204px] w-[204px] border border-black rounded-md z-50'
                    type='file'
                    multiple
                    onChange={handleImageChange}
                    disabled={paymentTypeData.payment.tier == 'silver' && prevImages.length >= 5
                        || paymentTypeData.payment.tier == 'gold' && prevImages.length >=10 ||
                        paymentTypeData.payment.tier == 'platinum' && prevImages.length >=17
                    }
                  />
                  <button className='flex py-8'>
                    <div className='flex px-14'>
                      <div className='z-10 '>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 512 512'
                          className='h-[50px] w-[50px] -rotate-45'
                        >
                          <path d='M152 120c-26.51 0-48 21.49-48 48s21.49 48 48 48s48-21.49 48-48S178.5 120 152 120zM447.1 32h-384C28.65 32-.0091 60.65-.0091 96v320c0 35.35 28.65 64 63.1 64h384c35.35 0 64-28.65 64-64V96C511.1 60.65 483.3 32 447.1 32zM463.1 409.3l-136.8-185.9C323.8 218.8 318.1 216 312 216c-6.113 0-11.82 2.768-15.21 7.379l-106.6 144.1l-37.09-46.1c-3.441-4.279-8.934-6.809-14.77-6.809c-5.842 0-11.33 2.529-14.78 6.809l-75.52 93.81c0-.0293 0 .0293 0 0L47.99 96c0-8.822 7.178-16 16-16h384c8.822 0 16 7.178 16 16V409.3z' />
                        </svg>
                      </div>
                      <div className='z-20 absolute pl-8'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          className='h-[70px] w-[70px]'
                          viewBox='0 0 20 20'
                          fill='currentColor'
                        >
                          <path d='M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z' />
                          <path d='M9 13h2v5a1 1 0 11-2 0v-5z' />
                        </svg>
                      </div>
                    </div>
                    <div className='absolute space-y-2 self-center '>
                      <div className='px-7'>
                        <p className='text-sm w-36 pt-[170px] '>
                          {t('listingItem.uploadImages')}
                        </p>
                      </div>
                      <div className='px-7'>
                        <p className='text-xs'>
                          {t('listingItem.dragImages')}
                        </p>
                      </div>
                    </div>
                  </button>
                </div>
                {prevImages.length != 0 && <h1 className='text-[18px] pt-2 font-medium font-avenir55 italic border-b border-b-auctionicRed'>
                  {t('listingItem.preview_images')}
                </h1>}
              </div>
              <div className='pt-1'>
                {paymentTypeData.payment.tier === 'silver' ?
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="characters" direction='horizontal'>
                      {(provided) => (
                        <div className="characters grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 w-full"
                          {...provided.droppableProps} ref={provided.innerRef}>
                          {provided.placeholder}
                          {characters &&
                                  characters.slice(0, 5).map((image: any, index: number) => {
                                    return (
                                      <Draggable key={index} draggableId={index.toLocaleString()} index={index}>
                                        {(provided) => (
                                          <div key={index} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                            <div key={index}>
                                              <div className='relative flex w-[220px]'>
                                                <img
                                                  src={image}
                                                  alt='upload'
                                                  className='h-[204px] w-[450px] rounded-lg shadow-md shadow-black'
                                                />
                                                <button
                                                  className='absolute p-2 rounded-br-lg rounded-tl-lg bg-white hover:bg-auctionicRed hover:text-white hover:scale-105 shadow-md shadow-black transition-all ease-in-out duration-150'
                                                  onClick={() => handleImageDelete(index)}
                                                >
                                                  <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    fill='none'
                                                    viewBox='0 0 24 24'
                                                    strokeWidth='1.5'
                                                    stroke='currentColor'
                                                    className='w-6 h-6'
                                                  >
                                                    <path
                                                      strokeLinecap='round'
                                                      strokeLinejoin='round'
                                                      d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
                                                    />
                                                  </svg>
                                                </button>
                                                {index == 0 ?
                                                  <button
                                                    className='absolute bottom-0 right-0 h-8 w-[130px] rounded-br-lg rounded-tl-lg text-white bg-auctionicRed shadow-md shadow-black'
                                                  >{t('listingItem.header')}
                                                  </button>
                                                  : ''}
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </Draggable>
                                    );
                                  })
                          }
                          
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  : paymentTypeData.payment.tier == 'gold' ?
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                      <Droppable droppableId="characters" direction='horizontal'>
                        {(provided) => (
                          <div className="characters grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 w-full"
                            {...provided.droppableProps} ref={provided.innerRef}>
                            {characters &&
                                characters.slice(0, 10).map((image: any, index: number) => {
                                  return (
                                    <Draggable key={index} draggableId={index.toLocaleString()} index={index}>
                                      {(provided) => (
                                        <div key={index} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                          <div key={index}>
                                            <div className='flex w-[220px]'>
                                              <img
                                                src={image}
                                                alt='upload'
                                                className='h-[204px] w-[450px] rounded-lg shadow-md shadow-black'
                                              />
                                              <button
                                                className='absolute p-2 rounded-br-lg rounded-tl-lg bg-white hover:bg-auctionicRed hover:text-white hover:scale-105 shadow-md shadow-black transition-all ease-in-out duration-150'
                                                onClick={() => handleImageDelete(index)}
                                              >
                                                <svg
                                                  xmlns='http://www.w3.org/2000/svg'
                                                  fill='none'
                                                  viewBox='0 0 24 24'
                                                  strokeWidth='1.5'
                                                  stroke='currentColor'
                                                  className='w-6 h-6'
                                                >
                                                  <path
                                                    strokeLinecap='round'
                                                    strokeLinejoin='round'
                                                    d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
                                                  />
                                                </svg>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                })
                            }
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    :
                    paymentTypeData.payment.tier == 'platinum' ?
                      <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId="characters" direction='horizontal'>
                          {(provided) => (
                            <div className="characters grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 w-full"
                              {...provided.droppableProps} ref={provided.innerRef}>
                              {characters &&
                                  characters.slice(0, 17).map((image: any, index: number) => {
                                    return (
                                      <Draggable key={index} draggableId={index.toLocaleString()} index={index}>
                                        {(provided) => (
                                          <div key={index} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                            <div key={index}>
                                              <div className='flex w-[220px]'>
                                                <img
                                                  src={image}
                                                  alt='upload'
                                                  className='h-[204px] w-[450px] rounded-lg shadow-md shadow-black'
                                                />
                                                <button
                                                  className='absolute p-2 rounded-br-lg rounded-tl-lg bg-white hover:bg-auctionicRed hover:text-white hover:scale-105 shadow-md shadow-black transition-all ease-in-out duration-150'
                                                  onClick={() => handleImageDelete(index)}
                                                >
                                                  <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    fill='none'
                                                    viewBox='0 0 24 24'
                                                    strokeWidth='1.5'
                                                    stroke='currentColor'
                                                    className='w-6 h-6'
                                                  >
                                                    <path
                                                      strokeLinecap='round'
                                                      strokeLinejoin='round'
                                                      d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
                                                    />
                                                  </svg>
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </Draggable>
                                    );
                                  })
                              }
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                      : ''
                }
              </div>
              <div className='pt-5 text-gray-700 italic text-sm'>
                {paymentTypeData.payment.tier == 'silver' ?
                  <div className='py-2'>
                    <p>*{t('listingItem.max_images_silver')}</p>
                    <button onClick={handleClickPrev} className='px-2 py-1 bg-auctionicRed text-white rounded-md'>{t('listingItem.change_plan')}</button>
                  </div> :
                  paymentTypeData.payment.tier === 'gold'
                    ? <div className='py-1'>
                      <p>*{t('listingItem.max_images_gold')}</p>
                      <button onClick={handleClickPrev} className='px-2 py-1 bg-auctionicRed text-white rounded-md'>{t('listingItem.change_plan')}</button>
                    </div> :
                    paymentTypeData.payment.tier === 'platinum' ?
                      <p>*{t('listingItem.max_images_platinum')}</p> : '' }
              </div>
              <div className='border border-darkGray w-full mt-5 ' />
              <p className='text-[20px] font-semibold pt-4 pb-4 text-center sm:text-left'>
                {t('listingItem.documents')}
              </p>
              <div className=''>
                <div className='flex justify-center sm:justify-start'>
                  <div className='h-[204px] w-[204px] border-2 border-black rounded-md '>
                    <input
                      className='absolute cursor-pointer opacity-0 h-[204px] w-[204px] border border-black rounded-md z-50'
                      type='file'
                      multiple
                      onChange={handleDocumentChange}
                      accept='application/pdf'
                    />
                    <button className='flex py-8'>
                      <div className='flex ml-[75px]'>
                        <img src={house} alt='' />
                      </div>
                      <div className='absolute space-y-2 self-center '>
                        <div className='px-7'>
                          <p className='text-sm w-36 pt-[170px] '>
                            {t('listingItem.uploadDocuments')}
                          </p>
                        </div>
                        <div className='px-7'>
                          <p className='text-xs'>
                            {t('listingItem.dragDocuments')}
                          </p>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
                {paymentTypeData.payment.tier == 'silver' ?
                  prevDocuments &&
                  prevDocuments.slice(0, 1).map((docs: any, index: number) => {
                    return (
                      <ul
                        key={index}
                        className='font-semibold mt-5 flex justify-center sm:justify-start  '
                      >
                        <li className='flex justify-around bg-gray-100 w-[204px] py-5 rounded-md '>
                          <div className='flex space-x-2 justify-center hover:hover:text-blue-400 transition-all ease-in-out duration-200'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth='1.5'
                              stroke='currentColor'
                              className='w-6 h-6'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z'
                              />
                            </svg>
                            <a
                              href={docs}
                              target='_blank'
                              rel='noreferrer'
                              className='text-sm self-center'
                            >
                              Filename {index + 1}
                            </a>
                          </div>
                          <button
                            onClick={() => handleDocumentDelete(index)}
                            className=''
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth='1.5'
                              stroke='currentColor'
                              className='w-6 h-6 hover:text-auctionicRed'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M6 18L18 6M6 6l12 12'
                              />
                            </svg>
                          </button>
                        </li>
                      </ul>
                    );
                  })
                  : paymentTypeData.payment.tier == 'gold' ?
                    prevDocuments &&
                        prevDocuments.slice(0, 1).map((docs: any, index: number) => {
                          return (
                            <ul
                              key={index}
                              className='font-semibold mt-5 flex justify-center sm:justify-start  '
                            >
                              <li className='flex justify-around bg-gray-100 w-[204px] py-5 rounded-md '>
                                <div className='flex space-x-2 justify-center hover:hover:text-blue-400 transition-all ease-in-out duration-200'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 24 24'
                                    strokeWidth='1.5'
                                    stroke='currentColor'
                                    className='w-6 h-6'
                                  >
                                    <path
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                      d='M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z'
                                    />
                                  </svg>
                                  <a
                                    href={docs}
                                    target='_blank'
                                    rel='noreferrer'
                                    className='text-sm self-center'
                                  >
                                      Filename {index + 1}
                                  </a>
                                </div>
                                <button
                                  onClick={() => handleDocumentDelete(index)}
                                  className=''
                                >
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 24 24'
                                    strokeWidth='1.5'
                                    stroke='currentColor'
                                    className='w-6 h-6 hover:text-auctionicRed'
                                  >
                                    <path
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                      d='M6 18L18 6M6 6l12 12'
                                    />
                                  </svg>
                                </button>
                              </li>
                            </ul>
                          );
                        })
                    : paymentTypeData.payment.tier == 'platinum' ?
                      prevDocuments &&
                            prevDocuments.slice(0, 1).map((docs: any, index: number) => {
                              return (
                                <ul
                                  key={index}
                                  className='font-semibold mt-5 flex justify-center sm:justify-start  '
                                >
                                  <li className='flex justify-around bg-gray-100 w-[204px] py-5 rounded-md '>
                                    <div className='flex space-x-2 justify-center hover:hover:text-blue-400 transition-all ease-in-out duration-200'>
                                      <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        fill='none'
                                        viewBox='0 0 24 24'
                                        strokeWidth='1.5'
                                        stroke='currentColor'
                                        className='w-6 h-6'
                                      >
                                        <path
                                          strokeLinecap='round'
                                          strokeLinejoin='round'
                                          d='M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z'
                                        />
                                      </svg>
                                      <a
                                        href={docs}
                                        target='_blank'
                                        rel='noreferrer'
                                        className='text-sm self-center'
                                      >
                                          Filename {index + 1}
                                      </a>
                                    </div>
                                    <button
                                      onClick={() => handleDocumentDelete(index)}
                                      className=''
                                    >
                                      <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        fill='none'
                                        viewBox='0 0 24 24'
                                        strokeWidth='1.5'
                                        stroke='currentColor'
                                        className='w-6 h-6 hover:text-auctionicRed'
                                      >
                                        <path
                                          strokeLinecap='round'
                                          strokeLinejoin='round'
                                          d='M6 18L18 6M6 6l12 12'
                                        />
                                      </svg>
                                    </button>
                                  </li>
                                </ul>
                              );
                            })
                      : ''}
                <div className='pt-5 text-gray-700 italic text-sm'>
                  {paymentTypeData.payment.tier == 'silver' ?
                    <div className='py-2'>
                      <p>*{t('listingItem.max_documents_silver')}</p>
                      <button onClick={handleClickPrev} className='px-2 py-1 bg-auctionicRed text-white rounded-md'>{t('listingItem.change_plan')}</button>
                    </div> :
                    paymentTypeData.payment.tier === 'gold'
                      ? <div className='py-1'>
                        <p>*{t('listingItem.max_documents_gold')}</p>
                        <button onClick={handleClickPrev} className='px-2 py-1 bg-auctionicRed text-white rounded-md'>{t('listingItem.change_plan')}</button>
                      </div> :
                      paymentTypeData.payment.tier === 'platinum' ?
                        <p>*{t('listingItem.max_documents_platinum')}</p> : '' }
                </div>
              </div>
              <div className='border border-darkGray w-full mt-8' />
              <div className=''>
                {paymentTypeData.payment.tier !== 'silver' ?
                  <><p className='text-[20px] font-semibold pt-4 text-center sm:text-left'>
                      Video
                  </p>
                  <div className='grid grid-cols-1 sm:grid-cols-4 gap-4 pt-4'>
                    {prevVideo &&
                            prevVideo.map((video: any, index: number) => {
                              return (
                                <div key={index} className=''>
                                  <div key={index} className='flex'>
                                    <video
                                      playsInline
                                      loop
                                      autoPlay
                                      controls={true}
                                      key={index}
                                      id='top'
                                      className='h-[204px] w-auto object-cover rounded-lg shadow-md shadow-black'
                                    >
                                      <source src={video}/>
                                    </video>
                                    <button
                                      className='absolute p-2 rounded-br-lg rounded-tl-lg bg-white hover:bg-auctionicRed hover:text-white hover:scale-105 shadow-md shadow-black transition-all ease-in-out duration-150'
                                      onClick={() => handleVideoDelete(index)}
                                    >
                                      <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        fill='none'
                                        viewBox='0 0 24 24'
                                        strokeWidth='1.5'
                                        stroke='currentColor'
                                        className='w-6 h-6'
                                      >
                                        <path
                                          strokeLinecap='round'
                                          strokeLinejoin='round'
                                          d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'/>
                                      </svg>
                                    </button>
                                  </div>
                                </div>
                              );
                            })}
                    <div className=''>
                      <div className='h-[204px] w-[204px] border-2 border-black rounded-md '>
                        <input
                          className='absolute cursor-pointer opacity-0 h-[204px] w-[204px] border border-black rounded-md z-50'
                          type='file'
                          onChange={handleVideoChange}/>
                        <button className='flex py-8'>
                          <div className='flex px-16'>
                            <div className='z-10 '>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                viewBox='0 0 384 512'
                                className='w-[80px] h-[70px]'
                              >
                                <path
                                  d='M365.3 93.38l-74.63-74.64C278.6 6.742 262.3 0 245.4 0H64C28.65 0 0 28.65 0 64l.0065 384c0 35.34 28.65 64 64 64H320c35.2 0 64-28.8 64-64V138.6C384 121.7 377.3 105.4 365.3 93.38zM336 448c0 8.836-7.164 16-16 16H64.02c-8.838 0-16-7.164-16-16L48 64.13c0-8.836 7.164-16 16-16h160L224 128c0 17.67 14.33 32 32 32h79.1V448zM240 288c0-17.67-14.33-32-32-32h-96c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32v-16.52l43.84 30.2C292.3 403.5 304 397.6 304 387.4V284.6c0-10.16-11.64-16.16-20.16-10.32L240 304.5V288z'/>
                              </svg>
                            </div>
                          </div>
                          <div className='absolute space-y-2 self-center '>
                            <div className='px-7'>
                              <p className='text-sm w-36 pt-[170px] '>
                                {t('listingItem.uploadVideo')}
                              </p>
                            </div>
                            <div className='px-7'>
                              <p className='text-xs'>
                                {t('listingItem.dragImages')}
                              </p>
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                  <p className='pt-5 text-gray-700 italic text-sm'>*{t('listingItem.max_upload_video')}</p>
                  </>
                  : '' }
              </div>
              {/* <div className='border border-darkGray w-full mt-8' />*/}
              {/* {type === 'rent' && (*/}
              {/*  <>*/}
              {/*    <p className='text-[22px] pt-10 font-bold'>Contact</p>*/}
              {/*    <div className='py-20 sm:px-0 px-5'>*/}
              {/*      <div className='col-span-2'>*/}
              {/*        <div className='sm:grid sm:grid-cols-1 md:grid md:grid-cols-3 md:space-x-16 lg:grid lg:grid-cols-3 '>*/}
              {/*          <div className='relative w-full mb-6'>*/}
              {/*            <label className=''>*/}
              {/*              {t('listingItem.fullName')}*/}
              {/*            </label>*/}
              {/*            <input*/}
              {/*              type='text'*/}
              {/*              value=''*/}
              {/*              className='block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-black peer required '*/}
              {/*              placeholder=' '*/}
              {/*              required*/}
              {/*            />*/}
              {/*          </div>*/}
              {/*          <div className='relative w-full mb-6 '>*/}
              {/*            <label className=''>{t('listingItem.contact')}</label>*/}
              {/*            <input*/}
              {/*              type='text'*/}
              {/*              value=''*/}
              {/*              className='block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-black peer required'*/}
              {/*              placeholder=' '*/}
              {/*              required*/}
              {/*            />*/}
              {/*          </div>*/}
              {/*        </div>*/}
              {/*        <div className='sm:grid sm:grid-cols-1 md:grid md:grid-cols-3 lg:grid lg:grid-cols-3 '>*/}
              {/*          <div className='relative z-0 w-full mb-6 group'>*/}
              {/*            <label className=''>{t('listingItem.email')}</label>*/}
              {/*            <input*/}
              {/*              type='email'*/}
              {/*              value=''*/}
              {/*              className='block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-black peer required '*/}
              {/*              placeholder=' '*/}
              {/*              required*/}
              {/*            />*/}
              {/*          </div>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*    <div className='border border-darkGray w-full' />*/}
              {/*  </>*/}
              {/* )}*/}
            </div>
          </div>
        </div>
        <div className='col-span-0 sm:col-span-1 hidden sm:block'>
          <Stepper steps={steps} activeStep={step} />
        </div>
      </div>
      <div className='pt-12 flex justify-between'>
        <button
          className='hover:scale-125 hover:ease-in duration-300'
          onClick={handleClickPrev}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-6 w-6'
            fill='none'
            viewBox='0 0 24 24'
            stroke='black'
          >
            <path d='M10 19l-7-7m0 0l7-7m-7 7h18' />
          </svg>
        </button>
        <button
          className='bg-darkGray w-[100px] h-[34px] text-center text-white rounded-md hover:scale-105 hover:ease-in duration-300'
          onClick={handleNext}
          disabled={hasError}
        >
          {t('listingItem.buttonNext')}
        </button>
      </div>
      <div className='col-span-0 sm:col-span-1 block sm:hidden'>
        <Stepper steps={steps} activeStep={step} />
      </div>
    </div>
  );
};
export { PropertyForm };
