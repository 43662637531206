import React, { useState } from 'react';
import Logo from '../../images/logoRed.svg';
import OtpInput from 'react-otp-input';
import '../LisitingItem/ListingItem.css';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Keyboard } from 'swiper';
import { utils } from '../../helpers/utils';
import { propertyServices } from '../../services/propertyServices';
import 'swiper/css';
import 'swiper/css/navigation';
import './PropertyDetails.css';
import { userServices } from '../../services/userServices';
import { SpinnerLoading } from '../LoadingTest/SpinnerLoading';
import close from '../../images/x.svg';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { utilsProperty } from './utils';

interface BiddingProps {
  form: any;
  setForm: any;
  handleBidding: any;
  inputData: any;
  setInputData: any;
  handleOpenBid: any;
  increasingAmount: any;
  property: any;
  propertyDetails: any;
  buttonLoader1: any;
}

const BidPrice = (props: any) => {
  const { t } = useTranslation();
  const [prevPrice, setPrevPrice] = useState('');
  const {
    increasingAmount,
    setForm,
    inputData,
    setInputData,
    propertyDetails,
  } = props;
  const handleBidChange = (e: any) => {
    const { name, value } = e.target;
    if (name == 'price') {
      const formattedValue = (Number(value.replace(/\D/g, '')) || '')
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, '\'');
      const unformattedValue = Number(value.replace(/\D/g, '')) || '';
      setInputData((prevState: any) => ({
        ...prevState,
        price: unformattedValue,
      }));
      setPrevPrice(formattedValue);
    }
  };

  const sum = parseInt(inputData.price);
  return (
    <form className='w-full'>
      <p className='text-[24px]'>{t('propertyDetails.bidInput')}</p>
      <div className='pt-2 sm:pt-[49px] w-full'>
        <input
          type='text'
          value={prevPrice}
          required
          name='price'
          className='bg-transparent text-2xl rounded-md border border-gray-50 px-2 py-1 w-full'
          onChange={handleBidChange}
        />
        {sum < increasingAmount && (
          <div className='flex text-sm text-red-500 pt-2'>
            {t('propertyDetails.bidValidate') + ' CHF ' +
              utils.numberWithComma(propertyDetails?.minimum_increasing_amount)}
          </div>
        )}
      </div>
      <hr className='border border-white my-3 ' />
      <div className='flex justify-end'>
        <button
          className='bg-white rounded-lg p-4 bidNextButton disabled:bg-gray-400'
          onClick={() => setForm((form: any) => form + 1)}
          disabled={sum < increasingAmount}
        >
          <span className='bidNextIcon'>
            <svg
              width='16'
              height='14'
              viewBox='0 0 16 14'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M9.97115 0.278417L15.7347 6.32784C16.0884 6.69906 16.0884 7.30094 15.7347 7.67216L9.97115 13.7216C9.61747 14.0928 9.04404 14.0928 8.69035 13.7216C8.33667 13.3504 8.33667 12.7485 8.69035 12.3773L12.9079 7.95058H0V6.04942H12.9079L8.69035 1.62273C8.33667 1.25151 8.33667 0.64964 8.69035 0.278417C9.04404 -0.0928058 9.61747 -0.0928058 9.97115 0.278417Z'
                fill='black'
              />
            </svg>
          </span>
          <span className='bidNextText text-darkGray font-bold'>
            {t('propertyDetails.next')}
          </span>
        </button>
      </div>
    </form>
  );
};
const MortgageCheck = (props: any) => {
  const { setForm, inputData } = props;
  const { t } = useTranslation();
  return (
    <div className='rounded-md h-full'>
      <div className='text-darkGray font-press-start bg-gray-50 h-full rounded-md'>
        <div className='text-xl text-left px-6 py-6'>
          <p className=''>{t('propertyDetails.areYouSureBid')}</p>
          <p className='text-xl font-bold text-auctionicRed'>
            CHF {utils.numberWithComma(inputData.price)}
          </p>
        </div>
        <hr className='border border-darkGray mx-6' />
        <div className='text-left px-6 pt-6 space-y-6'>
          <div>
            <p>{t('propertyDetails.bidMortgagePromptText')}</p>
          </div>
          <div>
            <a
              href='/mortgage'
              target='_blank'
              className='hover:border-b border-auctionicRed text-auctionicRed transition-all duration-150 ease-in-out'
            >
              {t('propertyDetails.calculateBidMortgage')}
            </a>
          </div>
        </div>
      </div>
      <hr className='border border-gray-50 my-3 ' />
      <div className='flex justify-end'>
        <button
          className='bg-white rounded-lg p-4 bidNextButton'
          onClick={() => setForm((form: any) => form + 1)}
        >
          <span className='bidNextIcon'>
            <svg
              width='16'
              height='14'
              viewBox='0 0 16 14'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M9.97115 0.278417L15.7347 6.32784C16.0884 6.69906 16.0884 7.30094 15.7347 7.67216L9.97115 13.7216C9.61747 14.0928 9.04404 14.0928 8.69035 13.7216C8.33667 13.3504 8.33667 12.7485 8.69035 12.3773L12.9079 7.95058H0V6.04942H12.9079L8.69035 1.62273C8.33667 1.25151 8.33667 0.64964 8.69035 0.278417C9.04404 -0.0928058 9.61747 -0.0928058 9.97115 0.278417Z'
                fill='black'
              />
            </svg>
          </span>
          <span className='bidNextText text-darkGray font-bold'>
            {t('propertyDetails.next')}
          </span>
        </button>
      </div>
    </div>
  );
};

const TermsAndConditions = (props: any) => {
  const { t } = useTranslation();
  const { handleTerms,buttonLoader } = props;

  const handleClick = () => {
    handleTerms();
  };
  return (
    <div className='rounded-md h-full'>
      <div className='text-darkGray font-press-start rounded-lg bg-gray-50 h-full relative'>
        <h1 className='text-2xl text-center pt-4 mx-3'>
          {t('propertyDetails.termsAndConditions')}
        </h1>
        <hr className='py-2' />
        <div className='text-left px-6'>
          <p>{t('propertyDetails.biddingTextTermsCond')}</p>
        </div>
        <div className='space-y-3 mt-5'>
          <div className='text-left px-6 font-semibold text-lg'>
            <a href='/terms/conditions' target='_blank' className='text-auctionicRed'>
              {t('propertyDetails.termsAndConditions')}
            </a>
          </div>
          <div className='text-left px-6 text-auctionicRed font-semibold text-lg'>
            <a href='/privacy/policy' target='_blank' className='text-auctionicRed'>
              {t('propertyDetails.privacyPolicy')}
            </a>
          </div>
        </div>
        <div className='m-auto absolute bottom-3 text-xs  w-full px-4 text-left '>
          <p>{t('propertyDetails.continueTextTerms')}.</p>
        </div>
      </div>
      <hr className='border border-gray-50 my-3 ' />
      <div className='flex justify-end bg-transparent'>
        <button
          className='bg-white rounded-lg px-4 py-4 bidNextButton'
          onClick={handleClick}
        >
          <span className='bidNextIcon'>
            {buttonLoader ? <SpinnerLoading/>:
              <svg
                width='16'
                height='14'
                viewBox='0 0 16 14'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M9.97115 0.278417L15.7347 6.32784C16.0884 6.69906 16.0884 7.30094 15.7347 7.67216L9.97115 13.7216C9.61747 14.0928 9.04404 14.0928 8.69035 13.7216C8.33667 13.3504 8.33667 12.7485 8.69035 12.3773L12.9079 7.95058H0V6.04942H12.9079L8.69035 1.62273C8.33667 1.25151 8.33667 0.64964 8.69035 0.278417C9.04404 -0.0928058 9.61747 -0.0928058 9.97115 0.278417Z'
                  fill='black'
                />
              </svg>}
          </span>
          <span className='bidNextText text-darkGray font-bold'>
            {t('propertyDetails.next')}
          </span>
        </button>
      </div>
    </div>
  );
};

const ConfirmPhoneCode = (props: any) => {
  const { t } = useTranslation();
  const { setInputData, handleBidding, inputData, buttonLoader } = props;
  const [otpBid, setOtpBid] = useState('');

  const handleOtpChange = (e: any) => {
    setOtpBid(e);
    setInputData((inputData: any) => ({ ...inputData, code: e }));
  };

  const resendCode = () => {
    userServices
      .resendVerification()
      .then((response) => {
        // console.log(response);
      })
      .catch((error: any) => {
        // console.log(error);
      });
  };
  return (
    <div className='h-full font-avenir55 '>
      <p className='text-[24px] text-left'>{t('propertyDetails.bidInfo')}</p>
      <div className='pt-2 sm:pt-[49px]'>
        <p className='text-[24px] text-left'>
          CHF {utils.numberWithComma(inputData.price)}
        </p>
      </div>
      <div className='text-[14px] mt-4 text-left'>
        {t('propertyDetails.verificationSent')}
        <br />
        {t('propertyDetails.code')}
      </div>
      <div className='flex flex-row justify-start text-center text-white mt-5'>
        <OtpInput
          value={otpBid}
          onChange={handleOtpChange}
          isInputNum={true}
          numInputs={6}
          inputStyle={{
            width: '2.5rem',
            height: '2.5rem',
            margin: '0 0.7rem',
            fontSize: '2rem',
            borderRadius: 4,
            border: '1px solid rgba(0,0,0,0.3)',
            color: 'black',
          }}
          shouldAutoFocus={true}
        />
      </div>
      <div className='flex justify-start mt-2'>
        <button
          className='text-white font-light flex space-x-3'
          onClick={resendCode}
        >
          <span>{t('propertyDetails.resendVerification')}</span>
          <svg
            viewBox='0 0 512 512'
            xmlns='http://www.w3.org/2000/svg'
            className='h-4 w-4 self-center '
            fill='#FFFFFF'
          >
            <path d='M480 256c0 123.4-100.5 223.9-223.9 223.9c-48.86 0-95.19-15.58-134.2-44.86c-14.14-10.59-17-30.66-6.391-44.81c10.61-14.09 30.69-16.97 44.8-6.375c27.84 20.91 61 31.94 95.89 31.94C344.3 415.8 416 344.1 416 256s-71.67-159.8-159.8-159.8C205.9 96.22 158.6 120.3 128.6 160H192c17.67 0 32 14.31 32 32S209.7 224 192 224H48c-17.67 0-32-14.31-32-32V48c0-17.69 14.33-32 32-32s32 14.31 32 32v70.23C122.1 64.58 186.1 32.11 256.1 32.11C379.5 32.11 480 132.6 480 256z' />
          </svg>
        </button>
      </div>
      <hr className='border border-white my-3 ' />
      <div className='flex justify-start'>
        <button
          className='bg-white rounded-lg px-3 py-3 text-darkGray flex'
          onClick={handleBidding}
        >
          {t('propertyDetails.continueButton')}
          {buttonLoader ? <SpinnerLoading/>:
            <svg
              className='self-center ml-3'
              width='16'
              height='14'
              viewBox='0 0 16 14'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M9.97115 0.278417L15.7347 6.32784C16.0884 6.69906 16.0884 7.30094 15.7347 7.67216L9.97115 13.7216C9.61747 14.0928 9.04404 14.0928 8.69035 13.7216C8.33667 13.3504 8.33667 12.7485 8.69035 12.3773L12.9079 7.95058H0V6.04942H12.9079L8.69035 1.62273C8.33667 1.25151 8.33667 0.64964 8.69035 0.278417C9.04404 -0.0928058 9.61747 -0.0928058 9.97115 0.278417Z'
                fill='black'
              />
            </svg>}
        </button>
      </div>
    </div>
  );
};

const Success = (props: any) => {
  const { handleOpenBid } = props;
  const { t } = useTranslation();

  return (
    <div className='bg-white text-darkGray font-avenir55 rounded-lg h-full'>
      <div className='self-center'>
        <div className='flex justify-center'>
          <img src={Logo} alt='Logo' className='mt-[49px]' />
        </div>
        <div className='block space-y-[14px] mt-[98px] px-16'>
          <p className='text-[20px]'>{t('propertyDetails.bidSuccess')}</p>
          <p className='text-[14px]'>{t('propertyDetails.accessChat')}</p>
        </div>
        <div className='mt-[103px] mb-[41px] flex justify-center'>
          <button
            className='px-12 py-2  bg-darkGray text-white text-[14px] rounded-md'
            onClick={handleOpenBid}
          >
            {t('propertyDetails.closeButton')}
          </button>
        </div>
      </div>
    </div>
  );
};

const Error = (props: any) => {
  const { handleOpenBid } = props;
  const { t } = useTranslation();

  return (
    <div className='bg-white text-darkGray font-press-start rounded-lg h-full'>
      <div className='self-center'>
        <div className='flex justify-center'>
          <img src={Logo} alt='Logo' className='mt-5' />
        </div>
        <div className='block space-y-[14px] px-16 sm:mt-20'>
          <p className='text-[20px]'>{t('propertyDetails.bidUnSuccess')}</p>
          <p className='text-[14px]'>{t('propertyDetails.pleaseTryAgain')}</p>
        </div>
        <div className='mt-[103px] mb-[41px] flex justify-center'>
          <button
            className='px-12 py-2  bg-darkGray text-white text-[14px] rounded-md'
            onClick={handleOpenBid}
          >
            {t('propertyDetails.closeButton')}
          </button>
        </div>
      </div>
    </div>
  );
};

const Bidding = (props: BiddingProps) => {
  const [buttonLoader, setButtonLoader] = useState(false);
  const {
    handleOpenBid,
    increasingAmount,
    inputData,
    setInputData,
    handleBidding,
    form,
    setForm,
    property,
    propertyDetails,
    buttonLoader1,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();


  const handleTerms = () => {
    setButtonLoader(true);
    propertyServices
      .requestBid(inputData.property_on_auction_id, inputData.price)
      .then((res) => {
        setButtonLoader(false);
        // console.log(res);
        setForm((form: any) => form + 1);
      })
      .catch((err: any) => {
        if (err.response.data.msg =='auction_locked') {
          // console.log('auction_locked', 'Auction is locked, somebody making a bid');
          toast.error(t(`errors.${err.response.data.msg}`), {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          setForm(100);

        }
        if (err.response.data.msg =='bid_over_limit') {
          // console.log('bid_over_limit', 'The entered bid price is over integer limit');
          toast.error(t(`errors.${err.response.data.msg}`), {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          setForm(100);
        }
        if (err.response.data.msg =='bid_under_limit') {
          // console.log('bid_under_limit', 'The entered bid price is under limit');
          toast.error(t(`errors.${err.response.data.msg}`), {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          setForm(100);
        }
        if (err.response.data.msg =='auction_time_passed') {
          // console.log('auction_time_passed', 'The time for the auction has passed');
          toast.error(t(`errors.${err.response.data.msg}`), {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          setForm(100);
        }
        if (err.response.data.msg != 'auction_locked' && err.response.data.msg != 'bid_over_limit' && err.response.data.msg != 'bid_under_limit' && err.response.data.msg != 'auction_time_passed') {
          setForm(100);
        }
        setTimeout(() => {
          navigate(0);
        },4000);
      });
  };

  return (
    <div className='relative z-10'>
      <div className='fixed inset-0 bg-black bg-opacity-95 transition-opacity'></div>
      <ToastContainer autoClose={4000} />
      <div
        className='fixed z-10 inset-0 overflow-y-auto transition delay-300 duration-300 ease-in-out'
        onClick={handleOpenBid}
      >
        <div className='block mx-3'>
          <div className='text-left pt-5 sm:mx-[208px] text-white block sm:hidden mx-2 font-bold'>
            <p className='text-[24px]'>
              {utilsProperty.generateTitle(propertyDetails,t)}
            </p>
            <p className='text-[18px]'>{property.title}</p>
            <p className='sm:pt-4 text-[24px] mr-24'>
              <span className='border-b-2 border-red-600'>
                CHF {utils.numberWithComma(propertyDetails?.current_bid)}.
              </span>
            </p>
          </div>
          <div className='flex justify-center text-center sm:mx-[10%] sm:mt-[4%]'>
            <div
              className='grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-3  text-white my-2 mx-2 sm:mx-0 sm:my-0 sm:gap-10 rounded-md'
              onClick={(e) => e.stopPropagation()}
            >
              <div className='col-span-2'>
                <div className=''>
                  <Swiper
                    modules={[Autoplay, Navigation, Keyboard]}
                    navigation={true}
                    keyboard={true}
                    className='rounded-md'
                  >
                    {propertyDetails.images.map((images: any, index: any) => {
                      return (
                        <SwiperSlide key={index} className='rounded-md m-auto '>
                          <img className='rounded-md sm:h-[580px] m-auto' src={images.url} alt='' />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              </div>
              <div className='mt-3 sm:mt-0 w-full col-span-1'>
                <div className='flex justify-end cursor-pointer pb-2' onClick={handleOpenBid}>
                  <img src={close} alt=''/>
                </div>
                <div className='rounded-md h-full'>
                  {form === 0 ? (
                    <BidPrice
                      setForm={setForm}
                      increasingAmount={increasingAmount}
                      handleOpenBid={handleOpenBid}
                      inputData={inputData}
                      setInputData={setInputData}
                      propertyDetails={propertyDetails}
                    />
                  ) : form === 1 ? (
                    <MortgageCheck setForm={setForm} inputData={inputData} />
                  ) : form === 2 ? (
                    <TermsAndConditions
                      setForm={setForm}
                      increasingAmount={increasingAmount}
                      handleOpenBid={handleOpenBid}
                      inputData={inputData}
                      setInputData={setInputData}
                      handleTerms={handleTerms}
                      buttonLoader={buttonLoader}
                    />
                  ) : form === 100 ? (
                    <Error
                      setForm={setForm}
                      increasingAmount={increasingAmount}
                      handleOpenBid={handleOpenBid}
                      inputData={inputData}
                      setInputData={setInputData}
                      handleTerms={handleTerms}
                    />
                  ) : form === 3 ? (
                    <ConfirmPhoneCode
                      setForm={setForm}
                      increasingAmount={increasingAmount}
                      handleOpenBid={handleOpenBid}
                      inputData={inputData}
                      setInputData={setInputData}
                      handleBidding={handleBidding}
                      buttonLoader={buttonLoader1}
                    />
                  ) : (
                    <Success
                      setForm={setForm}
                      increasingAmount={increasingAmount}
                      handleOpenBid={handleOpenBid}
                      inputData={inputData}
                      setInputData={setInputData}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='text-left pt-5 sm:mx-[10%] text-white hidden sm:block'>
            <p className='text-[24px]'>
              {utilsProperty.generateTitle(propertyDetails,t)}
            </p>
            <p className='text-[18px]'>{property.title}</p>
            <p className='sm:pt-4 text-[24px] mr-24'>
              <span className='border-b-2 border-red-600'>
                CHF {utils.numberWithComma(propertyDetails?.current_bid)}.
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Bidding };
