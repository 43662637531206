import React, { useEffect, useMemo, useState } from 'react';
import './Landing.css';
import Logo from '../../images/logoRed.svg';
import { TrendingEstate } from './TrendingEstate';
import { Infos } from './Infos';
import Image1 from '../../images/ArrowTop.svg';
import Menu from '../../images/menu_1.svg';
import ComparisLogo from '../../images/Logo_Comparis.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import house from '../../images/deal.svg';
import { data } from './data';
import { WelcomeUser } from './WelcomeUser';
import DatalistInput, { Item } from 'react-datalist-input';
import useAuth from '../../context/AuthContext';
import i18n from 'i18next';
import banner from '../../images/poster-min.png';
import { Loading1 } from '../Loading';
import { ChatBot } from '../ChatBot';
import { Cookies } from 'react-cookie';
import { ChatCard } from '../Support';

const Landing = () => {
  const [userMenu, setUserMenu] = useState(false);
  const handleOpenUserMenu = () => {
    setUserMenu(!userMenu);
  };
  const handleCloseUserMenu = () => {
    if (userMenu === false) {
      return null;
    } else {
      handleOpenUserMenu();
    }
  };
  const cookies = new Cookies();
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const isLogged = localStorage.getItem('access_token');
  const [openMenu, setOpenMenu] = useState(false);
  const [openCookie, setOpenCookie] = React.useState(true);

  const handleSideMenu = () => {
    setOpenMenu(!openMenu);
  };
  const { t } = useTranslation();
  const [type, setType] = useState('rent');
  const [location, setLocation] = useState('');
  const [location_type, setLocationType] = useState('');
  const changeLanguage = (ln: string) => {
    localStorage.setItem('locale', ln);
    i18n.changeLanguage(ln);
  };

  const [openService, setOpenService] = useState(false);
  const handleOpenService = () => {
    setOpenService(!openService);
  };
  const body = [{ location: location }, { location_type: location_type }];

  // Landing search dropdown starts here
  const dataItems = useMemo(
    () =>
      data.map((location: any) => ({
        id: location.id,
        value:
          location.location +
          `${
            location?.location_name != null ? ' ' + location?.location_name : ''
          }`,
        ...location,
      })),
    []
  );
  const items: Array<Item> = dataItems;
  const CustomItem = ({ item }: { item: Item }) => {
    return (
      <div className='flex justify-between font-avenir55'>
        {item.location_type === 'zip_code' ? (
          <p className='text-[#676767]'>
            {item.location + ' ' + item?.location_name}
          </p>
        ) : (
          <p className='text-[#676767]'>{item.location}</p>
        )}
        <span className='text-[#676767] text-sm font-normal'>
          {t('landing.' + item.location_type)}
        </span>
      </div>
    );
  };

  const [index, setIndex] = useState(6);

  const customItems = items.slice(0, index).map((item) => ({
    ...item,
    node: <CustomItem item={item} />,
  }));


  const handleClick = () => {
    if (location !== '') {
      navigate('/listing', { state: { type: type, filter: body } });
    } else {
      navigate('/listing', { state: { type: type } });
    }
  };

  const handleChangeLocation = (e: any) => {
    setLocation(e.location);
    // const result = data.filter((obj) => {
    //   return obj.location === e.target.value
    // });
    setLocationType(e.location_type);
  };

  useEffect(() => {
    if (location == '') {
      setIndex(6);
    }
  }, [location]);

  const firstLanding = sessionStorage.getItem('first_landing');
  if (firstLanding == null) {
    if (loading) {
      return <Loading1 loading={loading} setLoading={setLoading} />;
    }
  }

  const backToTop = () => {
    window?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <div onClick={handleCloseUserMenu}>
      <meta
        name='viewport'
        content='width=device-width, initial-scale=1, maximum-scale=1'
      />
      <video
        playsInline
        loop
        autoPlay
        poster={banner}
        muted
        className='w-auto sm:w-full h-[500px] object-cover sm:object-center sm:h-auto md:h-auto lg:h-auto'
      >
        <source src='https://d1wk1w3htz6uk8.cloudfront.net/landing_3.m4v' />
        {/* <source src={video4} />*/}
      </video>
      <div className='topContainer'>
        <div className='logo'>
          <button onClick={() => navigate('/')}>
            <img
              src={Logo}
              className='relative top-[25px] sm:top-[37px] w-[140px] sm:w-auto '
              alt=''
            />
          </button>
          {!openMenu && (
            <button
              className='rounded-md absolute left-2 sm:left-[80px] md:left-[100px] 2xl:left-[208px] top-[20px] sm:top-[25px] text-auctionicRed bg-gray-100 bg-opacity-[55%] px-3 py-2.5 text-sm font-semibold'
              onClick={handleSideMenu}
            >
              <span className='flex space-x-2'>
                <img src={Menu} alt='' />
                <p className='text-base hidden md:flex'>Menu</p>
              </span>
            </button>
          )}
          {!isLogged ? (
            <button
              className='loginButton absolute right-1 sm:right-36 lg:right-52 bg-auctionicRed text-white  hover:bg-white hover:text-auctionicRed transition-all ease-in-out duration-300 rounded-full mt-3 sm:mt-6 px-6'
              onClick={() => navigate('/login')}
            >
              <span className='loginIcon'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='1.9'
                  stroke='currentColor'
                  className='w-7 h-7'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75'
                  />
                </svg>
              </span>
              <span className='loginText text-auctionicRed text-lg'>
                {t('login.buttonLogin')}
              </span>
            </button>
          ) : (
            <WelcomeUser
              userMenu={userMenu}
              handleOpenUserMenu={handleOpenUserMenu}
            />
          )}
          {/* ------- SIDEBAR STARTS HERE ------- */}
          <>
            <div
              onClick={handleSideMenu}
              className={`fixed inset-0 bg-black bg-opacity-75 transition-opacity z-20 ${
                openMenu ? 'translate-x-0' : 'translate-x-full'
              }`}
            ></div>
            <div
              className={`top-0 left-0 nav-bar bg-white text-white fixed h-full transform ease-in-out duration-300 z-40 ${
                openMenu ? 'translate-x-0 ' : '-translate-x-full'
              }`}
            >
              <div className='bg-blue-300 '>
                <button
                  onClick={handleSideMenu}
                  className='flex justify-center absolute left-0 right-0 top-7 '
                >
                  <img src={Menu} alt='' />
                </button>
              </div>
              {isLogged && (
                <div className='flex justify-center mt-16 mb-5'>
                  <div className='bg-gray-100 py-2 px-5 text-darkGray text-lg rounded-lg flex space-x-2'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth='1.5'
                      stroke='currentColor'
                      className='w-6 h-6'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z'
                      />
                    </svg>
                    <p>
                      {t('navBar.welcome')}
                      {user?.type === 'individual' ? `, ${user?.first_name}` : user?.type === 'company' ? `, ${user?.name}` : ''}
                    </p>
                  </div>
                </div>
              )}
              <div className='overflow-y-auto px-3 absolute top-[108px] bottom-0 right-0 left-0'>
                <div
                  className='text-left pt-4 px-10 font-press-start'
                  onChange={handleSideMenu}
                >
                  <ul className='block space-y-4'>
                    <li className='font-medium text-darkGray mt-4 text-xl'>
                      <Link
                        to={'/listingItem'}
                        state={{ type: 'auction', showRadio: false }}
                        className='hover:border-b-2 border-b-red-600 hover:relative'
                      >
                        {t('navBar.auction')}
                      </Link>
                    </li>
                    <li className='font-medium text-darkGray pt-4 text-xl'>
                      <Link
                        to={'/listingItem'}
                        state={{ type: 'sale', showRadio: false }}
                        className='hover:border-b-2 border-b-red-600 hover:relative'
                      >
                        {t('navBar.sale')}
                      </Link>
                    </li>
                    <li className='font-medium text-darkGray pt-4 text-xl'>
                      <Link
                        to={'/listingItem'}
                        state={{ type: 'rent', showRadio: false }}
                        className='hover:border-b-2 border-b-red-600 hover:relative'
                      >
                        {t('navBar.rent')}
                      </Link>
                    </li>
                    <li className='font-medium text-darkGray pt-4 text-xl'>
                      <Link
                        to={'/listingItem'}
                        state={{ type: 'auction', showRadio: true }}
                        className='hover:border-b-2 border-b-red-600 hover:relative'
                      >
                        {t('navBar.advertise')}
                      </Link>
                    </li>
                    <li className='font-medium text-darkGray pt-4 text-xl'>
                      <Link
                        to={'/mortgage'}
                        className='hover:border-b-2 border-b-red-600'
                        onClick={handleSideMenu}
                      >
                        {t('navBar.mortgages')}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className='text-left pt-6 px-10 font-press-start'>
                  <ul className='block space-y-4'>
                    {!isLogged && (
                      <li className='font-medium text-darkGray pt-9 text-xl '>
                        <button
                          onClick={() => navigate('/register')}
                          className='px-9 py-2 rounded-md text-white  bg-red-600 uppercase'
                        >
                          {t('navBar.register')}
                        </button>
                      </li>
                    )}
                  </ul>
                </div>
                <div className='text-left pt-10 px-10 font-press-start'>
                  <ul className='block space-y-4 pb-24'>
                    <li className='font-medium text-darkGray pt-4 text-xl'>
                      <Link
                        to={'/contact'}
                        className='hover:border-b-2 border-b-red-600'
                        onClick={handleSideMenu}
                      >
                        {t('navBar.contact')}
                      </Link>
                    </li>
                    <li className='font-medium text-darkGray pt-4 text-xl'>
                      <Link
                        to={'/how_it_works'}
                        className='hover:border-b-2 border-b-red-600'
                        onClick={handleSideMenu}
                      >
                        {t('navBar.howitworks')}
                      </Link>
                    </li>
                    <li className='font-medium text-darkGray py-4 text-xl flex items-center'>
                      <Link
                        to={'/services'}
                        state={{ type: '' }}
                        className='hover:border-b-2 hover:border-b-red-600 border-b-2 border-b-transparent '
                      >
                        {t('navBar.service')}
                      </Link>
                      <a
                        onClick={handleOpenService}
                        className={`ml-2 self-center ${
                          openService
                            ? 'bg-auctionicRed p-0.5 rounded-full text-white ease-in-out duration-200 self-center'
                            : ''
                        }`}
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                          strokeWidth='2'
                          stroke='currentColor'
                          className='w-6 h-6 '
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M19.5 8.25l-7.5 7.5-7.5-7.5'
                          />
                        </svg>
                      </a>
                    </li>
                    {openService ? (
                      <div className='ml-4 text-left'>
                        <li className='font-semibold text-darkGray text-md'>
                          <Link
                            to={'/services'}
                            state={{ type: 'financeAndInsure' }}
                            className='hover:border-b-2 border-b-red-600 '
                          >
                            {t('navBar.service1')}
                          </Link>
                        </li>
                        <li className='font-semibold text-darkGray pt-4 text-md'>
                          <Link
                            to={'/services'}
                            state={{ type: 'rentalDeposit' }}
                            className='hover:border-b-2 border-b-red-600 '
                          >
                            {t('navBar.service2')}
                          </Link>
                        </li>
                        <li className='font-semibold text-darkGray pt-4 text-md'>
                          <Link
                            to={'/services'}
                            state={{ type: 'realEstateEvaluation' }}
                            className='hover:border-b-2 border-b-red-600 '
                          >
                            {t('navBar.service10')}
                          </Link>
                        </li>
                        <li className='font-semibold text-darkGray pt-4 text-md'>
                          {localStorage.getItem('user_id') ?
                            <Link
                              to={'/services'}
                              state={{ type: 'debtCollection' }}
                              className='hover:border-b-2 border-b-red-600 '
                            >
                              {t('navBar.service3')}
                            </Link>
                            :
                            <Link
                              to={'/login/redirect'}
                              // state={{ type: 'debtCollection' }}
                              className='hover:border-b-2 border-b-red-600 '
                            >
                              {t('navBar.service3')}
                            </Link>
                          }
                        </li>
                        <li className='font-semibold text-darkGray pt-4 text-md'>
                          <Link
                            to={'/services'}
                            className='hover:border-b-2 border-b-red-600 '
                            state={{ type: 'legalConsultation' }}
                          >
                            {t('navBar.service4')}
                          </Link>
                        </li>
                        <li className='font-semibold text-darkGray pt-4 text-md'>
                          <Link
                            to={'/services'}
                            state={{ type: 'relocationAssistance' }}
                            className='hover:border-b-2 border-b-red-600 '
                          >
                            {t('navBar.service5')}
                          </Link>
                        </li>
                        <li className='font-semibold text-darkGray pt-4 text-md'>
                          <Link
                            to={'/services'}
                            state={{ type: 'propertyManagement' }}
                            className='hover:border-b-2 border-b-red-600 '
                          >
                            {t('navBar.service6')}
                          </Link>
                        </li>
                        <li className='font-semibold text-darkGray pt-4 text-md'>
                          <Link
                            to={'/services'}
                            state={{ type: 'realEstateManagement' }}
                            className='hover:border-b-2 border-b-red-600 '
                          >
                            {t('navBar.service7')}
                          </Link>
                        </li>
                        <li className='font-semibold text-darkGray pt-4 text-md'>
                          <Link
                            to={'/services'}
                            state={{ type: 'cleaningAndHygieneServices' }}
                            className='hover:border-b-2 border-b-red-600 '
                          >
                            {t('navBar.service8')}
                          </Link>
                        </li>
                        <li className='font-semibold text-darkGray pt-4 text-md'>
                          <Link
                            to={'/services'}
                            state={{ type: 'buildingAndRenovation' }}
                            className='hover:border-b-2 border-b-red-600 '
                          >
                            {t('navBar.service9')}
                          </Link>
                        </li>
                      </div>
                    ) : (
                      ''
                    )}
                  </ul>
                </div>
              </div>
              <div className='absolute bottom-0 text-darkGray right-0 left-0 py-1 px-5 bg-white shadow-sm shadow-black'>
                <div className='grid grid-cols-4 place-items-center'>
                  <div className=''>
                    <button
                      className='disabled:text-red-600 disabled:border-b-2 disabled:border-red-500 p-1'
                      disabled={i18n.language === 'de'}
                      onClick={() => changeLanguage('de')}
                    >
                      Deutsch
                    </button>
                  </div>
                  <div className=''>
                    <button
                      className='disabled:text-red-600 disabled:border-b-2 disabled:border-red-500 p-1'
                      disabled={i18n.language === 'en'}
                      onClick={() => changeLanguage('en')}
                    >
                      English
                    </button>
                  </div>
                  <div className=''>
                    <button
                      className='disabled:text-red-600 disabled:border-b-2 disabled:border-red-500 p-1'
                      disabled={i18n.language === 'fr'}
                      onClick={() => changeLanguage('fr')}
                    >
                      Français
                    </button>
                  </div>
                  <div className=''>
                    <button
                      className='disabled:text-red-600 disabled:border-b-2 disabled:border-red-500 p-1'
                      disabled={i18n.language === 'it'}
                      onClick={() => changeLanguage('it')}
                    >
                      Italiano
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
          {/* ------- SIDEBAR ENDS HERE ------- */}
        </div>
        <div className='px-1 absolute top-[160px] sm:top-[100px] sm:px-[76px] md:top-[120px] lg:top-[180px] xl:top-[233px] w-full  md:px-[100px] 2xl:px-[200px]'>
          <ul className='flex mb-2 justify-center sm:justify-start font-press-start'>
            <li className='mr-2'>
              <button
                onClick={() => setType('auction')}
                className={`text-darkGray inline-block p-4 hover:border-b-2 active hover:text-gray-700 hover:border-auctionicRed ${
                  type === 'auction' ? 'border-b-2 border-auctionicRed' : ''
                }`}
              >
                {t('landing.auction')}
              </button>
            </li>
            <li className='mr-2'>
              <button
                className={`text-darkGray inline-block p-4 hover:border-b-2 active hover:text-gray-700 hover:border-auctionicRed ${
                  type === 'rent' ? 'border-b-2 border-auctionicRed' : ''
                }`}
                aria-current='page'
                onClick={() => setType('rent')}
              >
                {t('landing.rent')}
              </button>
            </li>
            <li className='mr-2'>
              <button
                onClick={() => setType('sale')}
                className={`text-darkGray inline-block p-4 hover:border-b-2 active hover:text-gray-700 hover:border-auctionicRed ${
                  type === 'sale' ? 'border-b-2 border-auctionicRed' : ''
                }`}
              >
                {t('landing.sell')}
              </button>
            </li>
          </ul>
          <div className='block sm:flex items-center space-x-0 sm:space-x-8 space-y-3 sm:space-y-0'>
            <form onSubmit={handleClick} className='w-full'>
              <div className='data-list-input'>
                <DatalistInput
                  placeholder={t('landing.placeholderSearch')}
                  label=''
                  onSelect={handleChangeLocation}
                  onClick={() => setIndex(6)}
                  onChange={() => setIndex(index + 5000)}
                  items={customItems}
                  className='text-base'
                  // onMouseEnter={() => setIndex(6)}
                  // onMouseLeave={() => setIndex(6)}
                />
              </div>
            </form>
            <div className='flex justify-center sm:justify-start items-center text-white data-list-input'>
              <button
                onClick={handleClick}
                className='bg-auctionicRed rounded-md w-full sm:w-auto px-6 py-2.5 font-press-start uppercase tracking-wide'
              >
                {t('landing.buttonSearch')}
              </button>
            </div>
          </div>
        </div>
        <div className='font-press-start rounded-md shadow-lg bg-gray-100 absolute sm:ml-[9%] sm:mr-[5%]  md:ml-[9%] md:mr-[36%] -mt-32 sm:mt-0 lg:-mt-[100px] xl:-mt-[130px] sm:h-48 sm:self-center sm:flex sm:items-center'>
          <div className='grid grid-cols-1 sm:grid-cols-4 '>
            <div className='col-span-1 flex justify-center self-center mt-2 sm:mt-0 mx-4 p-2  sm:mx-0 sm:p-0'>
              <img
                src={house}
                alt=''
                className='h-16 w-16 sm:h-[75px] sm:w-[75px]'
              />
            </div>
            <div className='sm:col-span-3 space-y-1 pb-3 pr-3 pl-3 sm:pt-3 sm:pb-3 sm:pr-3 sm:pl-0'>
              <div className='grid grid-cols-1 sm:grid-cols-3 rounded-sm'>
                <div className='col-span-2 '>
                  <p className='text-darkGray flex sm:text-[17px] justify-center px-3 sm:justify-start'>
                    {' '}
                    {t('landing.createTxt')}
                  </p>
                </div>
                <div className='self-center col-span-1'>
                  <div className='flex sm:justify-end mt-2 sm:mt-0'>
                    <button
                      onClick={() =>
                        navigate('/create-ad')
                      }
                      className='w-full sm:w-auto px-4 uppercase tracking-wide py-1 sm:py-2 bg-auctionicRed border border-red-600 text-white rounded-md transition-all ease-in-out duration-100 '
                    >
                      {t('landing.advertise')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='absolute text-3xl flex justify-end right-0 -mt-32 opacity-0 lg:opacity-100 mr-3 '>
          <div className='flex justify-end'>
            <Swiper
              slidesPerView={1}
              modules={[Autoplay]}
              autoplay={{ delay: 4000 }}
              className='w-72 text-center font-braga text-4xl'
            >
              <SwiperSlide>
                {t('landing.swiperFirst')}
                <br />
              </SwiperSlide>
              <SwiperSlide>
                {t('landing.swiperSecond')}
                <br />
              </SwiperSlide>
              <SwiperSlide>
                {t('landing.swiperFifth')}
                <br />
              </SwiperSlide>
              <SwiperSlide>
                {t('landing.swiperThird')}
                <br />
              </SwiperSlide>
              <SwiperSlide>
                {t('landing.swiperFourth')}
                <br />
              </SwiperSlide>
              <SwiperSlide>
                {t('landing.swiperSixth')}
                <br />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
      
      <div className='flex justify-center md:justify-end mr-0 md:mr-28 lg:mr-36 pt-32 sm:pt-56 md:pt-12'>
        <img
          className=''
          src={ComparisLogo}
          alt=''
        />
      </div>
      <div className='bg-white mt-36'>
        <p className='text-base tracking-wider  font-press-start font-semibold leading-5 text-center mb-7 sm:text-left sm:ml-[9%] pt-20 uppercase text-darkGray'>
          {t('landing.trendingEstate')}
        </p>
        <TrendingEstate />
        <div className='border border-darkGray w-full mb-20' />
        <Infos />
        <div className='flex justify-center'>
          <ChatCard />
        </div>
        
        <div className='mt-24 pb-16 text-center'>
          <button
            className='bg-darkGray rounded px-4 py-3'
            onClick={backToTop}
          >
            <img src={Image1} alt='' />
          </button>
          <p className='pt-3 text-sm font-press-start text-darkGray'>
            {t('landing.scrollUp')}
          </p>
        </div>
      </div>
      <ChatBot />
      {cookies.get('our_site_cookies') || localStorage.getItem('user_id') ? null : (
        <>
          {openCookie && (
            <div className="fixed bottom-0 w-full px-6 py-4 z-50 bg-auctionicRed rounded-tl-md rounded-tr-md text-white text-sm  shadow-lg">
              <div className="container mx-auto lg:w-[75%] justify-center">
                <div className="flex justify-between">
                  <p className="font-normal text-sm xl:text-lg">
                    {t('cookies.message')}<a className='cursor-pointer border-b-2 border-white' onClick={() => navigate('/terms/conditions')}>{t('cookies.message2')}</a>{t('cookies.message3')}<a className='cursor-pointer border-b-2 border-white' onClick={() => navigate('/privacy/policy') }>{t('cookies.message4')}</a>{t('cookies.message5')}
                  </p>
                  <button
                    onClick={() => {
                      localStorage.setItem('cookies', 'true');
                      setOpenCookie(false);
                      cookies.set('our_site_cookies', { name: 'Auctionic' });
                    }
                    }
                    className="bg-white p-3 h-fit hover:text-white hover:bg-auctionicRed hover:border-2 hover:border-white text-auctionicRed rounded-xl flex items-center justify-center self-center">
                    {/* <svg className="h-6 w-6 text-red-500" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18 6L6 18" stroke="#FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M6 6L18 18" stroke="#FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg> */}
                    {t('cookies.accept')}
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export { Landing };
