import React from 'react';
import Logo from '../../images/logoRed.svg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Jobs = () => {
  const { t } = useTranslation();
  window.scrollTo(0, 0);
  return (
    <div className='sm:px-10 md:px-24 px-2 sm:py-12 py-2 min-h-screen font-press-start bg-gray-50'>
      <div className='flex justify-center'>
        <Link to='/'>
          <img src={Logo} alt='' />
        </Link>
      </div>
      <div className='pt-14 sm:pt-32 '>
        <h1 className='sm:text-4xl font-bold  text-xl text-center sm:text-left text-gray-500 font-avenir55 border-b border-red-500 py-5'>
          {t('jobs.jobTitle')}
        </h1>
      </div>
      <div className='pt-14 sm:pt-32 '>
        <p className='sm:text-xl text-center font-normal text-xl text-darkGray font-avenir55'>
          {t('jobs.message')}
        </p>
      </div>
    </div>
  );
};
export { Jobs };
