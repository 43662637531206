import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BuildingAndRenovation } from './BuildingAndRenovation';
import { CleaningAndHygiene } from './CleaningAndHygiene';
import { DebtCollection } from './DebtCollection';
import { FinanceAndInsure } from './FinanceAndInsure';
import { LegalConsultation } from './LegalConsultation';
import { PropertyManagement } from './PropertyManagement';
import { RealEstateManagement } from './RealEstateManagement';
import { RelocationAssistance } from './RelocationAssistance';
import { RentalDeposit } from './RentalDeposit';
import { RealEstateEvaluation } from './RealEstateEvaluation';
import { ServicesIntro } from './ServicesIntro';
import { useLocation } from 'react-router-dom';

const Services = () => {
  const { state } = useLocation() as any;
  let typeOfService = '';
  if (state?.type != null) {
    typeOfService = state.type;
  }
  else {
    typeOfService = '';
  }
  const [services, setServices] = useState(typeOfService);
  const handleChangeServices = (event: any) => {
    setServices(event);
  };

  useEffect(() => {
    setServices(services);
  },[services]);
  const { t } = useTranslation();
  const [openServicesMenu, setOpenServicesMenu] = useState(false);
  const handleOpenServicesMenu = () => {
    setOpenServicesMenu(!openServicesMenu);
  };

  return (
    <div className='bg-white h-auto font-avenir55'>
      <div className='flex justify-start sm:hidden pt-2 pr-3'>
        {openServicesMenu ? (
          <button
            className='flex items-center cursor-pointer fixed left-2 top-3 z-20 px-1 py-0.5 rounded-md'
            onClick={handleOpenServicesMenu}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='currentColor'
              className='w-7 h-7 self-center'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M6 18L18 6M6 6l12 12'
              />
            </svg>{' '}
            <p className='mt-1 font-semibold'>{t('dashboard.close')}</p>
          </button>
        ) : (
          <button className='absolute px-5' onClick={handleOpenServicesMenu}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-8 w-8'
              fill='none'
              viewBox='0 0 24 24'
              stroke='black'
              strokeWidth='2'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M4 6h16M4 12h16M4 18h16'
              />
            </svg>
          </button>
        )}
      </div>
      <div className='grid sm:grid-cols-3'>
        <div className='hidden sm:flex col-span-1 border-r border-gray-900 my-[115px] sm:ml-16'>
          <ul className='space-y-6 text-[16px]'>
            <li className=''>
              <button
                onClick={() => handleChangeServices('financeAndInsure')}
                className={`${
                  services === 'financeAndInsure'
                    ? 'text-darkGray'
                    : 'text-gray-400'
                }`}
              >
                {t('services.financeAndInsure.title')}
              </button>
            </li>
            <li className=''>
              <button
                onClick={() => handleChangeServices('rentalDeposit')}
                className={`${
                  services === 'rentalDeposit'
                    ? 'text-darkGray'
                    : 'text-gray-400'
                }`}
              >
                {t('services.rentalDeposit')}
              </button>
            </li>
            <li className=''>
              <button
                onClick={() => handleChangeServices('realEstateEvaluation')}
                className={`${
                  services === 'realEstateEvaluation'
                    ? 'text-darkGray'
                    : 'text-gray-400'
                }`}
              >
                {t('services.realEstateEvaluation.title')}
              </button>
            </li>
            <li className=''>
              <button
                onClick={() => handleChangeServices('debtCollection')}
                className={`${
                  services === 'debtCollection'
                    ? 'text-darkGray'
                    : 'text-gray-400'
                }`}
              >
                {t('services.debtCollection')}
              </button>
            </li>
            <li className=''>
              <button
                onClick={() => handleChangeServices('legalConsultation')}
                className={`${
                  services === 'legalConsultation'
                    ? 'text-darkGray'
                    : 'text-gray-400'
                }`}
              >
                {t('services.legalConsultation.title')}
              </button>
            </li>
            <li className=''>
              <button
                onClick={() => handleChangeServices('relocationAssistance')}
                className={`${
                  services === 'relocationAssistance'
                    ? 'text-darkGray'
                    : 'text-gray-400'
                }`}
              >
                {t('services.moving')}
              </button>
            </li>
            <li className=''>
              <button
                onClick={() => handleChangeServices('propertyManagement')}
                className={`${
                  services === 'propertyManagement'
                    ? 'text-darkGray'
                    : 'text-gray-400'
                }`}
              >
                {t('services.propertyManagement.title')}
              </button>
            </li>
            <li className=''>
              <button
                onClick={() => handleChangeServices('realEstateManagement')}
                className={`${
                  services === 'realEstateManagement'
                    ? 'text-darkGray'
                    : 'text-gray-400'
                }`}
              >
                {t('services.realEstateManagement.title')}
              </button>
            </li>
            <li className=''>
              <button
                onClick={() =>
                  handleChangeServices('cleaningAndHygieneServices')
                }
                className={`${
                  services === 'cleaningAndHygieneServices'
                    ? 'text-darkGray'
                    : 'text-gray-400'
                }`}
              >
                {t('services.cleaningAndHygieneServices')}
              </button>
            </li>
            <li className=''>
              <button
                onClick={() => handleChangeServices('buildingAndRenovation')}
                className={`${
                  services === 'buildingAndRenovation'
                    ? 'text-darkGray'
                    : 'text-gray-400'
                }`}
              >
                {t('services.buildingAndRenovation.title')}
              </button>
            </li>
          </ul>
        </div>
        {/* MENU for mobile view START */}
        <div
          onClick={handleOpenServicesMenu}
          className={`fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity ${
            openServicesMenu ? 'translate-x-0' : '-translate-x-full'
          }`}
        ></div>
        <div
          className={`top-0 left-0 w-[85vw] bg-white pt-16 overflow-auto fixed h-full transform ease-in-out duration-300 z-10 space-y-4 px-3 ${
            openServicesMenu ? 'translate-x-0' : '-translate-x-full'
          }`}
        >
          <li className=''>
            <button
              onClick={() => {
                handleChangeServices('financeAndInsure');
                handleOpenServicesMenu();
              }}
              className={`${
                services === 'financeAndInsure'
                  ? 'text-darkGray'
                  : 'text-gray-400'
              }`}
            >
              {t('services.financeAndInsure.title')}
            </button>
          </li>
          <li className=''>
            <button
              onClick={() => {
                handleChangeServices('rentalDeposit');
                handleOpenServicesMenu();
              }}
              className={`${
                services === 'rentalDeposit' ? 'text-darkGray' : 'text-gray-400'
              }`}
            >
              {t('services.rentalDeposit')}
            </button>
          </li>
          <li className=''>
            <button
              onClick={() => {
                handleChangeServices('realEstateEvaluation');
                handleOpenServicesMenu();
              }}
              className={`${
                services === 'realEstateEvaluation' ? 'text-darkGray' : 'text-gray-400'
              }`}
            >
              <h1>{t('services.realEstateEvaluation.title')}</h1>
            </button>
          </li>
          <li className=''>
            <button
              onClick={() => {
                handleChangeServices('debtCollection');
                handleOpenServicesMenu();
              }}
              className={`${
                services === 'debtCollection'
                  ? 'text-darkGray'
                  : 'text-gray-400'
              }`}
            >
              {t('services.debtCollection')}
            </button>
          </li>
          <li className=''>
            <button
              onClick={() => {
                handleChangeServices('legalConsultation');
                handleOpenServicesMenu();
              }}
              className={`${
                services === 'legalConsultation'
                  ? 'text-darkGray'
                  : 'text-gray-400'
              }`}
            >
              {t('services.legalConsultation.title')}
            </button>
          </li>
          <li className=''>
            <button
              onClick={() => {
                handleChangeServices('relocationAssistance');
                handleOpenServicesMenu();
              }}
              className={`${
                services === 'relocationAssistance'
                  ? 'text-darkGray'
                  : 'text-gray-400'
              }`}
            >
              {t('services.moving')}
            </button>
          </li>
          <li className=''>
            <button
              onClick={() => {
                handleChangeServices('propertyManagement');
                handleOpenServicesMenu();
              }}
              className={`${
                services === 'propertyManagement'
                  ? 'text-darkGray'
                  : 'text-gray-400'
              }`}
            >
              {t('services.propertyManagement.title')}
            </button>
          </li>
          <li className=''>
            <button
              onClick={() => {
                handleChangeServices('realEstateManagement');
                handleOpenServicesMenu();
              }}
              className={`${
                services === 'realEstateManagement'
                  ? 'text-darkGray'
                  : 'text-gray-400'
              }`}
            >
              {t('services.realEstateManagement.title')}
            </button>
          </li>
          <li className=''>
            <button
              onClick={() => {
                handleChangeServices('cleaningAndHygieneServices');
                handleOpenServicesMenu();
              }}
              className={`${
                services === 'cleaningAndHygieneServices'
                  ? 'text-darkGray'
                  : 'text-gray-400'
              }`}
            >
              {t('services.cleaningAndHygieneServices')}
            </button>
          </li>
          <li className=''>
            <button
              onClick={() => {
                handleChangeServices('buildingAndRenovation');
                handleOpenServicesMenu();
              }}
              className={`${
                services === 'buildingAndRenovation'
                  ? 'text-darkGray'
                  : 'text-gray-400'
              }`}
            >
              {t('services.buildingAndRenovation.title')}
            </button>
          </li>
        </div>
        {/* MENU for mobile view END*/}
        <div className='col-span-2 mx-1 sm:mx-10 '>
          {services === '' && <ServicesIntro handleChangeServices={handleChangeServices} />}
          {services === 'financeAndInsure' && <FinanceAndInsure />}
          {services === 'rentalDeposit' && <RentalDeposit />}
          {services === 'realEstateEvaluation' && <RealEstateEvaluation />}
          {services === 'debtCollection' && <DebtCollection />}
          {services === 'legalConsultation' && <LegalConsultation />}
          {services === 'relocationAssistance' && <RelocationAssistance />}
          {services === 'propertyManagement' && <PropertyManagement />}
          {services === 'realEstateManagement' && <RealEstateManagement />}
          {services === 'cleaningAndHygieneServices' && <CleaningAndHygiene />}
          {services === 'buildingAndRenovation' && <BuildingAndRenovation />}
        </div>
      </div>
    </div>
  );
};

export { Services };
