import React, { useState } from 'react';
import { RelocationAssistanceIntro } from './RelocationAssistanceIntro';
import { RuckstuhlTransport } from './RuckstuhlTransport';
import { KuoniTransport } from './KuoniTransport';

const RelocationAssistance = () => {
  const [selectedRegion, setSelectedRegion] = useState('');

  return (
    <div className='sm:mt-[115px] mt-10 my-10'>
      <div className='col-span-2 mx-1 sm:mx-10 '>
        {selectedRegion === '' && <RelocationAssistanceIntro setSelectedRegion={setSelectedRegion} />}
        {selectedRegion === 'ostSchweiz' && <RuckstuhlTransport setSelectedRegion={setSelectedRegion}/>}
        {selectedRegion === 'Graubünden' && <KuoniTransport setSelectedRegion={setSelectedRegion}/>}
      </div>
    </div>
  );
};

export { RelocationAssistance };
