import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Account } from './Account';
import { Other } from './Other';
import { Security } from './Security';

const Settings = () => {
  const [settings, setSettings] = useState('account');
  const handleNavigate = (event: any) => {
    setSettings(event);
  };
  const { t } = useTranslation();
  return (
    <div className=''>
      <ul className='flex justify-around w-full text-[20px] self-center mt-14 sm:mt-0 text-darkGray'>
        <li
          className={`px-1.5 py-0.5 rounded-t-md  ${settings === 'account' ? 'border-b-2 border-b-red-700 bg-lightGray transition-all ease-in-out duration-150' : ''}`}
        >
          <button onClick={() => handleNavigate('account')}>
            {t('dashboard.account')}
          </button>
        </li>
        <li
          className={`px-1.5 py-0.5 rounded-t-md  ${settings === 'security' ? 'border-b-2 border-b-red-700 bg-lightGray transition-all ease-in-out duration-150' : ''}`}
        >
          <button onClick={() => handleNavigate('security')}>
            {t('dashboard.security')}
          </button>
        </li>
        <li
          className={`px-1.5 py-0.5 rounded-t-md  ${settings === 'other' ? 'border-b-2 border-b-red-700 bg-lightGray transition-all ease-in-out duration-150' : ''}`}
        >
          <button onClick={() => handleNavigate('other')}>
            {t('dashboard.other')}
          </button>
        </li>
      </ul>
      {settings === 'account' && <Account />}
      {settings === 'security' && <Security />}
      {settings === 'other' && <Other />}
    </div>
  );
};

export { Settings };
