import React, { useEffect, useState } from 'react';
import { NavigationStepper } from './NavigationStepper';
import SignatureCanvas from 'react-signature-canvas';
import './DebtCollectionForm.css';
import { useTranslation } from 'react-i18next';
import { userServices } from '../../services';
import { propertyServices } from '../../services/propertyServices';
import { SpinnerLoading } from '../LoadingTest/SpinnerLoading';

interface Props {
  handleBack: any;
  steps: any;
  input: any;
  setInput: any;
}

function SignRequest(props: Props) {
  const { handleBack, steps, input, setInput } = props;
  const [openModal, setOpenModal] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [generateInvoice, setGenerateInvoice] = useState({
    payment_id: '',
    link: '',
  });
  const [invoice, setInvoice] = useState<number>();
  const [step] = useState(3);
  let signaturePad: any;
  const Delete = () => {
    signaturePad.clear();
  };
  const Undo = () => {
    const data = signaturePad.toData();
    if (data) {
      data.pop();
      signaturePad.fromData(data);
    }
  };
  const [convert, setConvert] = useState();
  const { t } = useTranslation();

  const handleDebt = () => {
    setButtonLoader(true);
    userServices
      .debtRequest(input)
      .then((response: any) => {
        setButtonLoader(false);
        setOpenModal(true);
        setInvoice(response.data.payment_id);
        return response;
      })
      .catch((error: any) => {
        // console.log(error, 'error');
        setOpenModal(true);
        setButtonLoader(false);
      });
  };

  useEffect(() => {
    propertyServices
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .generateInvoice(invoice!)
      .then((response) => {
        setGenerateInvoice(response.data);
      })
      .catch((error: any) => {
        // console.log('Failed to get the plans', error);
      });
  }, [invoice]);

  useEffect(() => {
    setConvert(signaturePad.toDataURL());
  }, [convert]);

  return (
    <div className='min-h-screen'>
      <NavigationStepper steps={steps} activeStep={step} />
      <div className='mt-6 mb-2'>
        <p className='font-bold text-[22px]'>
          {t('debtCollection.orderDebtCollection')}
        </p>
      </div>
      <div>
        <p className='font-bold text-lg'>
          {t('debtCollection.signYourRequest.title')}
        </p>
      </div>
      <div className='grid sm:grid-cols-3 '>
        <div className='border-[0.5px] border-gray-300 rounded-md px-10 sm:col-span-2'>
          <div className='border-b-2 border-dashed'>
            <SignatureCanvas
              canvasProps={{ className: 'sigCanvas' }}
              ref={(ref) => (signaturePad = ref)}
              onEnd={() => {
                setConvert(signaturePad.toDataURL());
                setInput({
                  ...input,
                  signature: convert,
                });
              }}
            />
          </div>
          <div className='flex justify-center pt-1 font-bold'>
            <p>{t('debtCollection.signYourRequest.nameSurname')}</p>
          </div>
          <div className='flex justify-center pt-2 font-bold'>
            <button
              onClick={() => {
                setConvert(signaturePad.toDataURL());
                setInput({
                  ...input,
                  signature: convert,
                });
              }}
              className='px-3 py-2 bg-auctionicRed text-white rounded-md'>{t('debtCollection.signYourRequest.confirmSignature')}</button>
          </div>
          <div className='grid grid-cols-2 gap-5 py-4'>
            <button
              className='px-4 inline-flex hover:text-auctionicRed transition-all ease-out duration-150 justify-end'
              onClick={Delete}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                stroke='currentColor'
                className='w-5 h-5'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
                />
              </svg>
              <p className=''>{t('debtCollection.signYourRequest.delete')}</p>
            </button>
            <button
              className='flex hover:text-blue-700 transition-all ease-out duration-150 px-4 '
              onClick={Undo}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                stroke='currentColor'
                className='w-5 h-5'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3'
                />
              </svg>
              <p className='self-center mt-0.5 text-sm'>
                {t('debtCollection.signYourRequest.undo')}
              </p>
            </button>
          </div>
        </div>
        <div></div>
      </div>
      <div className='space-y-6 py-6'>
        <p className='text-lg font-bold'>
          {t('debtCollection.signYourRequest.processAndDelivery')}
        </p>
        <p>{t('debtCollection.signYourRequest.description1')}</p>
        <p>{t('debtCollection.signYourRequest.description2')}</p>
      </div>
      <div>
        <p>{t('debtCollection.signYourRequest.priceSummary')}</p>
        <p>CHF 17.– {t('debtCollection.signYourRequest.officialFees')}</p>
        <p>CHF 11.– {t('debtCollection.signYourRequest.processingFees')}</p>
        <p>CHF 1.– {t('debtCollection.signYourRequest.mailDeliveryCost')}</p>
      </div>
      <hr className='mt-6' />
      <div className='flex justify-between mt-5'>
        <button
          className='text-darkGray shadow-md flex  hover:bg-gray-100 rounded-lg self-center px-4 py-2 font-black'
          onClick={handleBack}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth='2'
            stroke='currentColor'
            className='w-5 h-5'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M15.75 19.5L8.25 12l7.5-7.5'
            />
          </svg>
          <p className=''>{t('debtCollection.back')}</p>
        </button>
        <button onClick={handleDebt} className='flex px-4 py-2 bg-auctionicRed text-white font-bold rounded-md cursor-pointer hover:bg-darkGray transition-all ease-in-out duration-100'>
          {t('debtCollection.order')} {buttonLoader ? <SpinnerLoading/>: '' }
        </button>
        {openModal ?
          <div id="popup-modal"
            className="fixed top-0 left-0 right-0 z-50 flex justify-center p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">
            <div className="relative w-full h-full max-w-md md:h-auto">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <div className="p-6 text-center">
                  <svg aria-hidden="true" className="mx-auto mb-4 text-gray-400 w-14 h-14 dark:text-gray-200"
                    fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                      d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                  </svg>
                  <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">{t('debtCollection.signYourRequest.checkInvoice')}</h3>
                  <button
                    onClick={() => window.open(generateInvoice?.link, '_blank')}
                    data-modal-toggle="popup-modal" type="button"
                    className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                    {t('debtCollection.signYourRequest.btnCheck')}
                  </button>
                  <button onClick={() => setOpenModal(false)} data-modal-toggle="popup-modal" type="button"
                    className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">{t('debtCollection.signYourRequest.cancel')}
                  </button>
                </div>
              </div>
            </div>
          </div>
          : '' }
      </div>
    </div>
  );
}

export { SignRequest };
