import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import svg from '../../images/legal-consultation-services.svg';

const LegalConsultation = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className='sm:mt-[115px] mt-10 my-10'>
      <div className='space-y-6'>
        <div className='text-xl sm:text-[45px] text-center'>
          {t('services.legalConsultation.title')}
        </div>
        <div className='text-lg sm:text-[25px] text-center'>
          {t('services.legalConsultation.description')}
        </div>
      </div>
      <div className='flex justify-center pt-10'>
        <div className='bg-auctionicRed w-[350px] h-[400px] rounded-xl grid gap-1 content-center' onClick={()=> navigate('/contact')}>
          <div className='flex justify-center'>
            <img
              className='w-[120px] h-[120px] lg:min-w-[200px] lg:min-h-[200px]'
              src={svg}
              alt=''
            />
          </div>
          <div className='text-center font-braga text-white text-3xl'>
            <h1>{t('services.legalConsultation.title')}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export { LegalConsultation };
