import React, { useState } from 'react';
import './Register.css';
import { useTranslation } from 'react-i18next';
import { SpinnerLoading } from '../LoadingTest/SpinnerLoading';
import { ToastContainer } from 'react-toastify';
import { cantons } from './cantons';
import { UserInput } from '../../config';
import { useNavigate } from 'react-router-dom';

interface StepperProps {
  setPage: any;
  handleChangeRegister: any;
  handleTwoFactor: any;
  secondButton: any;
  handleRegister: any;
  setInputRegister: (input: UserInput) => void;
  error: any;
  setError: any;
  inputRegister: UserInput;
  buttonLoader: any;
}

const BasicInformation = (props: StepperProps) => {
  const navigate = useNavigate();
  const {
    setPage,
    handleTwoFactor,
    secondButton,
    handleRegister,
    setInputRegister,
    error,
    setError,
    inputRegister,
    buttonLoader,
  } = props;
  const { t } = useTranslation();
  const [terms, setTerms] = useState(inputRegister?.terms_and_conditions);
  const [phoneErrorMessage, setPhoneErrorMessage] = useState(false);

  const handleTerms = () => {
    setTerms(!terms);
    setInputRegister({
      ...inputRegister,
      terms_and_conditions: !terms,
    });
  };

  const validZip = new RegExp(/^(\d{4})$/);

  const handleNext = () => {
    const errorList = [];
    if (!inputRegister?.address?.street) {
      errorList.push('street');
    } else if (!inputRegister.address.zip) {
      errorList.push('zip');
    } else if (!inputRegister.address.canton) {
      errorList.push('canton');
    } else if (!inputRegister.address.city) {
      errorList.push('city');
    }
    else if (!inputRegister.phone || phoneErrorMessage) {
      errorList.push('phone');
    } else if (terms === false) {
      errorList.push('terms');
    }
    else if (!inputRegister.address.zip || !validZip) {
      errorList.push('zip');
    }
    else {
      handleRegister();
    }
    setError(errorList);
  };

  const checkPhoneFormat = (phone: any) => {
    return /^[0-9]{9}$/.test(phone);
  };
  const handlePhoneChange = (e: any) => {
    const phone = e.target.value;
    if (phone != '' && phone != undefined) {
      if (!checkPhoneFormat(phone)) {
        setPhoneErrorMessage(true);
      } else {
        setPhoneErrorMessage(false);
      }
    }
    setInputRegister({
      ...inputRegister,
      phone: `+41${phone}`,
    });
  };
  return (
    <div className='registerContainer'>
      <ToastContainer autoClose={7000} />
      <div className='flex items-center justify-center py-[64px] px-4 sm:px-6 lg:px-8 font-avenir55'>
        <div className='max-w-3xl w-full space-y-8 bg-white font-press-start'>
          <div className='pb-4 pt-4 sm:pt-[41px] pl-4 sm:pl-[30px]'>
            <p className='text-[25px]'>{t('register.createAcc')}</p>
          </div>
          <div id='form1' className='sm:flex px-10 sm:px-16'>
            <div className='space-y-8 sm:space-y-5'>
              <div className='block'>
                <label className='pr-2 ' title={t('reqField.requiredField')}>
                  {t('register.street')}*
                </label>
                <input
                  type='text'
                  name='street'
                  value={inputRegister.address.street}
                  onChange={(e) => {
                    setInputRegister({
                      ...inputRegister,
                      address: {
                        ...inputRegister.address,
                        street: e.target.value,
                      }
                    });
                  }}
                  className={`py-2 w-full text-sm text-gray-900 bg-transparent ${error?.includes('street') ? 'border-0 border-b-2 border-auctionicRed' : 'border-0 border-b-2 border-gray-300'} appearance-none  focus:outline-none focus:ring-0 focus:border-black peer required `}
                />
                {error?.includes('street') ? (
                  <p className='text-xs text-red-500 absolute'>
                    {t('register.basicInformation.streetRequired')}
                  </p>
                ) : (
                  ''
                )}
              </div>
              <div className='block'>
                <label className='pr-2 ' title={t('reqField.requiredField')}>{t('register.postal')}*</label>
                <input
                  type='text'
                  name='zip'
                  value={inputRegister.address.zip}
                  onChange={(e) => {
                    setInputRegister({
                      ...inputRegister,
                      address: {
                        ...inputRegister.address,
                        zip: e.target.value,
                      }
                    });
                  }}
                  className={`py-2 w-full text-sm text-gray-900 bg-transparent ${error?.includes('zip') ? 'border-0 border-b-2 border-auctionicRed' : 'border-0 border-b-2 border-gray-300'} appearance-none  focus:outline-none focus:ring-0 focus:border-black peer required `}
                />
                <div className='text-left'>
                  <small className='text-left text-xs text-gray-500'>
                    *{t('register.basicInformation.zipContain')}
                  </small>
                </div>
                {!validZip.test(inputRegister.address.zip) && (
                  <p className='text-xs text-red-500 absolute'>
                      *{t('register.basicInformation.invalidZip')}
                  </p>
                )}
                {error?.includes('zip') ? (
                  <p className='text-xs text-red-500 absolute'>
                    {t('register.basicInformation.zipRequired')}
                  </p>
                ) : (
                  ''
                )}
              </div>
              <div className='block'>
                <label className='pr-2 ' title={t('reqField.requiredField')}>{t('register.city')}*</label>
                <input
                  type='text'
                  name='city'
                  value={inputRegister.address.city}
                  onChange={(e) => {
                    setInputRegister({
                      ...inputRegister,
                      address: {
                        ...inputRegister.address,
                        city: e.target.value,
                      }
                    });
                  }}
                  className={` py-2 w-full text-sm text-gray-900 bg-transparent ${error?.includes('city') ? 'border-0 border-b-2 border-auctionicRed' : 'border-0 border-b-2 border-gray-300'} appearance-none focus:outline-none focus:ring-0 focus:border-black peer required`}
                />
                {error?.includes('city') ? (
                  <p className='text-xs text-red-500 absolute'>
                    {t('register.basicInformation.cityRequired')}
                  </p>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className='pt-8 sm:pt-0 pl-0 sm:pl-[100px]'>
              <div className='space-y-8 sm:space-y-10'>
                <div className='block'>
                  <label className='pr-2 ' title={t('reqField.requiredField')}>{t('register.basicInformation.canton')}*</label>
                  <select
                    name='canton'
                    value={inputRegister.address.canton}
                    onChange={(e) => {
                      setInputRegister({
                        ...inputRegister,
                        address: {
                          ...inputRegister.address,
                          canton: e.target.value,
                        }
                      });
                    }}
                    className={`appearance-none z-10 block mt-3 px-0 w-full text-base sm:text-sm text-gray-900 bg-transparent ${
                      error?.includes('canton')
                        ? 'border-0 border-b-2 border-auctionicRed'
                        : 'border-0 border-b-2 border-gray-300'
                    } focus:outline-none focus:ring-0 focus:border-b-black peer required `}
                  >
                    <option defaultChecked className=''>
                      {t('register.basicInformation.chooseCanton')}
                    </option>
                    {cantons.map((canton) => {
                      return (
                        <option key={canton.id} value={canton.location}>
                          {canton.location}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className='block'>
                  <div className='sm:flex'>
                    <label className='pr-2 sm:self-center' title={t('reqField.requiredField')}>
                      {t('register.phone')}*
                    </label>
                    <button className='button1 hidden sm:flex'>
                      <span className='icon1 '>
                        <abbr title={t('register.infoPhoneNumber')}>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='h-8 w-8 text-darkGray'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                            strokeWidth='2'
                            fill='transparent'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                            />
                          </svg>
                        </abbr>
                      </span>
                    </button>
                  </div>
                  <div className='mt-2 inline-block'>
                    <span className='country-code px-2 text-sm'>+41</span>
                    <input
                      type='text'
                      name='phone'
                      className={`text-base sm:text-sm text-gray-900 bg-transparent ${
                        error?.includes('phone')
                          ? 'border-0 border-b-2 border-auctionicRed'
                          : 'border-0 border-b-2 border-gray-300'
                      } appearance-none  focus:outline-none focus:ring-0 focus:border-black peer required `}
                      id='ec-mobile-number'
                      onChange={handlePhoneChange}
                      placeholder='761234567'
                    />
                  </div>
                  {error?.includes('phone') || phoneErrorMessage ? (
                    <p className='text-xs text-red-500 absolute'>
                      {t('register.phoneError')}
                    </p>
                  ) : (
                    ''
                  )}
                  <div className='flex sm:hidden animated fadeOut'>
                    <p className='text-darkGray sm:hidden text-xs'>
                      *{t('register.infoPhoneNumber')}
                    </p>
                  </div>
                </div>
                <div className=''>
                  <label className='labels text-left '>
                    {t('register.terms&conditions')}<a className='cursor-pointer border-b-2 border-gray-900'  href='/terms/conditions' target='_blank'>{t('cookies.message2')}</a>{t('cookies.message3')}<a className='cursor-pointer border-b-2 border-gray-900' href='/privacy/policy' target='_blank'>{t('cookies.message4')}</a>
                    <input
                      type='checkbox'
                      className='h-6 w-6 absolute'
                      name='terms_and_conditions'
                      checked={inputRegister?.terms_and_conditions}
                      onChange={handleTerms}
                    />
                    <span className='checks'></span>
                  </label>
                  {error?.includes('terms') ? (
                    <p className='text-xs text-red-500 '>
                      {t('register.termsError')}
                    </p>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='px-4 sm:px-[30px] pb-4 sm:pb-[34px] pt-4 sm:pt-[50px] flex justify-between'>
            <button
              onClick={() => {
                setPage((currPage: number) => currPage - 1);
              }}
              className='bg-white hover:bg-gray-200 px-2 py-2 mt-3 rounded-md border-2 border-black transition-all ease-in-out duration-150'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-6 w-6 '
                fill='none'
                viewBox='0 0 24 24'
                stroke='black'
              >
                <path d='M10 19l-7-7m0 0l7-7m-7 7h18' />
              </svg>
            </button>
            <div
              id='dots'
              className='w-full h-full mt-8 text-base flex justify-center space-x-1 sm:space-x-16'
            >
              <div className='bg-gray-900 border border-black text-center  w-[15%]'></div>
              <div className='bg-gray-900 border border-black text-center   w-[15%]  '></div>
              <div className='bg-white border border-gray-300 text-center  w-[15%] '></div>
            </div>
            {!secondButton && (
              <button
                onClick={handleNext}
                // disabled={inputRegister.terms_and_conditions === terms}
                className='bg-gray-900 hover:bg-gray-700 hover:scale-105 px-2 py-2 mt-3 rounded-md border-2 border-black transition-all ease-in-out duration-150'
              >
                {buttonLoader ? (
                  <SpinnerLoading />
                ) : (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-6 w-6'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='white'
                  >
                    <path d='M14 5l7 7m0 0l-7 7m7-7H3' />
                  </svg>
                )}
              </button>
            )}
            {secondButton && (
              <button
                onClick={handleTwoFactor}
                className='bg-gray-900 hover:bg-gray-700 hover:scale-105 px-2 py-2 mt-3 rounded-md border-2 border-black transition-all ease-in-out duration-150'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='white'
                >
                  <path d='M14 5l7 7m0 0l-7 7m7-7H3' />
                </svg>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export { BasicInformation };
