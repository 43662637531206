import React from 'react';
import Logo from '../../images/logoRed.svg';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
  window.scrollTo(0, 0);
  const { t } = useTranslation();
  return (
    <div className='bg-white
      xl:py-28 lg:py-20 md:py-16 py-10
      xl:px-80 lg:px-52 md:px-12 px-5
      block sm:grid sm:grid-cols-1 font-avenir55'>
      <img src={Logo} className='w-[400px] mb-12'/>
      <p className='font-semibold lg:text-4xl sm:text-3xl text-2xl my-1'>{t('privacyPolicy.title')}</p>
      <p className='font-semibold text-md'>{t('privacyPolicy.date')}</p>
      <p className='mt-3'>
        <span className='font-bold'>
          {t('privacyPolicy.auctionicAg')}
        </span>{t('privacyPolicy.auctionicAddress')}
        <a href='https://auctionic.ch'>www.auctionic.ch</a>
      </p>
      <div className='border border-darkGray w-full mb-10 mt-4' />
      <div className='space-y-2 text-justify text-sm mb-10'>
        <p className='font-semibold text-[18px]'>{t('privacyPolicy.general.title')}</p>
        <p>{t('privacyPolicy.general.p1')}</p>
        <p>{t('privacyPolicy.general.p2')}</p>
        <p>{t('privacyPolicy.general.p3')}</p>
        <p>{t('privacyPolicy.general.p4')}</p>
        <p>{t('privacyPolicy.general.p5')}</p>
      </div>
      <ol className='px-16 space-y-4 font-avenir55 text-xl list-decimal'>
        <div className='space-y-2'>
          <li className='list-decimal font-bold'>{t('privacyPolicy.heading1.title')}</li>
          <p className='text-sm'>{t('privacyPolicy.heading1.p1')}</p>
        </div>
        <div className='space-y-2'>
          <li className='list-decimal font-bold'>{t('privacyPolicy.heading2.title')}</li>
          <p className='font-bold'>{t('privacyPolicy.heading2.title1')}</p>
          <p className='text-sm'>{t('privacyPolicy.heading2.p1')}</p>
          <ul className='list-disc list-inside font-normal text-sm text-justify'>
            <li>{t('privacyPolicy.heading2.li1')}</li>
            <li>{t('privacyPolicy.heading2.li2')}</li>
            <li>{t('privacyPolicy.heading2.li3')}</li>
            <li>{t('privacyPolicy.heading2.li4')}</li>
            <li>{t('privacyPolicy.heading2.li5')}</li>
          </ul>
          <p className='text-sm pt-2'>{t('privacyPolicy.heading2.p2')}</p>
          <p className='text-sm pt-2'>{t('privacyPolicy.heading2.p3')}</p>
          <p className='text-sm pt-2'>{t('privacyPolicy.heading2.p4')}</p>
        </div>
        <div className='space-y-2'>
          <p className='font-bold'>{t('privacyPolicy.heading2.title2')}</p>
          <p className='text-sm'>{t('privacyPolicy.heading2.p5')} </p>
          <p className='text-sm pt-2'>{t('privacyPolicy.heading2.p6')}</p>
          <ul className='list-disc list-inside font-normal text-sm text-justify'>
            <li>{t('privacyPolicy.heading2.li6')}</li>
            <li>{t('privacyPolicy.heading2.li7')}</li>
            <li>{t('privacyPolicy.heading2.li8')}</li>
            <li>{t('privacyPolicy.heading2.li9')}</li>
          </ul>
          <p className='text-sm'>{t('privacyPolicy.heading2.p7')}</p>
          <p className='text-sm'>{t('privacyPolicy.heading2.p8')}</p>
        </div>
        <div className='space-y-2'>
          <p className='font-bold'>{t('privacyPolicy.heading2.title3')}</p>
          <p className='text-sm'>{t('privacyPolicy.heading2.p10')}</p>
        </div>
        <div className='space-y-2'>
          <p className='font-bold'>{t('privacyPolicy.heading2.title4')}</p>
          <p className='text-sm'>{t('privacyPolicy.heading2.p11')}<a href={'https://aws.amazon.com/de/privacy/'} target='_blank' rel="noreferrer" className='text-auctionicRed hover:underline'> {t('privacyPolicy.heading2.link1')} </a>{t('privacyPolicy.heading2.p12')}</p>
          <p className='text-sm'>{t('privacyPolicy.heading2.p13')}</p>
        </div>
        <div className='space-y-2'>
          <li className='font-bold'>{t('privacyPolicy.heading3.title')}</li>
          <p className='text-sm'>{t('privacyPolicy.heading3.p1')}</p>
          <ul className='list-disc list-inside font-normal text-sm text-justify'>
            <li>{t('privacyPolicy.heading3.li1')}</li>
            <li>{t('privacyPolicy.heading3.li2')}</li>
            <li>{t('privacyPolicy.heading3.li3')}; </li>
            <li>{t('privacyPolicy.heading3.li4')}</li>
            <li>{t('privacyPolicy.heading3.li5')}</li>
            <li>{t('privacyPolicy.heading3.li6')}</li>
            <li>{t('privacyPolicy.heading3.li7')}</li>
            <li>{t('privacyPolicy.heading3.li8')}</li>
            <li>{t('privacyPolicy.heading3.li9')}</li>
            <li>{t('privacyPolicy.heading3.li10')}</li>
            <li>{t('privacyPolicy.heading3.li11')}</li>
            <li>{t('privacyPolicy.heading3.li12')}</li>
            <li>{t('privacyPolicy.heading3.li13')}</li>
          </ul>
        </div>
        <div className='space-y-2'>
          <li className='font-bold'>{t('privacyPolicy.heading4.title')}</li>
          <p className='font-bold'>{t('privacyPolicy.heading4.title1')}</p>
          <p className='text-sm'>{t('privacyPolicy.heading4.p1')}</p>
          <p className='text-sm'>{t('privacyPolicy.heading4.p2')}</p>
          <p className='text-sm'>{t('privacyPolicy.heading4.p3')}</p>
          <p className='text-sm'>{t('privacyPolicy.heading4.p4')}</p>
        </div>
        <div className='space-y-2'>
          <p className='font-bold'>{t('privacyPolicy.heading4.title2')}</p>
          <p className='text-sm'>{t('privacyPolicy.heading4.p5')}</p>
        </div>
        <div className='space-y-2'>
          <p className='font-bold'>{t('privacyPolicy.heading4.title3')}</p>
          <p className='text-sm'>{t('privacyPolicy.heading4.p6')} <a href={'https://support.google.com/analytics/answer/6004245?hl=de'} target='_blank' rel="noreferrer" className='text-auctionicRed hover:underline'>{t('privacyPolicy.heading4.link2')}</a>{t('privacyPolicy.heading4.p7')} </p>
        </div>
        <div className='space-y-2'>
          <p className='font-bold'>{t('privacyPolicy.heading4.title4')}</p>
          <p className='text-sm'>{t('privacyPolicy.heading4.p8')}</p>
        </div>
        <div className='space-y-2'>
          <li className='font-bold'>{t('privacyPolicy.heading5.title')}</li>
          <p className='text-sm'>{t('privacyPolicy.heading5.p1')}</p>
          <p className='text-sm'>{t('privacyPolicy.heading5.p2')}</p>
          <p className='text-sm'>{t('privacyPolicy.heading5.p3')}</p>
        </div>
        <div className='space-y-2'>
          <li className='font-bold'>{t('privacyPolicy.heading6.title')}</li>
          <p className='text-sm'>{t('privacyPolicy.heading6.p1')}</p>
        </div>
        <div className='space-y-2'>
          <li className='font-bold'>{t('privacyPolicy.heading7.title')}</li>
          <p className='text-sm'>{t('privacyPolicy.heading7.p1')} </p>
        </div>
        <div className='space-y-2'>
          <p className='font-bold'>{t('privacyPolicy.heading7.title1')}</p>
          <p className='text-sm'>{t('privacyPolicy.heading7.p2')} </p>
        </div>
        <div className='space-y-2'>
          <p className='font-bold'>{t('privacyPolicy.heading7.title2')}</p>
          <p className='text-sm'> {t('privacyPolicy.heading7.p3')}</p>
          <p className='text-sm'>{t('privacyPolicy.heading7.p4')}</p>
        </div>
        <div className='space-y-2'>
          <p className='font-bold'>{t('privacyPolicy.heading7.title3')}</p>
          <p className='text-sm'>{t('privacyPolicy.heading7.p5')}</p>
          <ul className='list-disc list-inside font-normal text-sm text-justify'>
            <li>{t('privacyPolicy.heading7.li1')} </li>
            <li>{t('privacyPolicy.heading7.li2')}</li>
          </ul>
          <p className='text-sm'>{t('privacyPolicy.heading7.p6')}</p>
        </div>
        <div className='space-y-2'>
          <li className='font-bold'>{t('privacyPolicy.heading8.title')}</li>
          <p className='font-bold'>{t('privacyPolicy.heading8.title1')}</p>
          <p className='text-sm'>{t('privacyPolicy.heading8.p1')}</p>
          <p className='text-sm'>{t('privacyPolicy.heading8.p2')}</p>
          <p className='text-sm'>{t('privacyPolicy.heading8.p3')} </p>
        </div>
        <div className='space-y-2'>
          <p className='font-bold'>{t('privacyPolicy.heading8.title2')}</p>
          <p className='text-sm'>{t('privacyPolicy.heading8.p4')}</p>
        </div>
        <div className='space-y-2'>
          <li className='font-bold'>{t('privacyPolicy.heading9.title')}</li>
          <p className='font-bold'>{t('privacyPolicy.heading9.title1')}</p>
          <p className='text-sm'>{t('privacyPolicy.heading9.p1')}</p>
          <p className='text-sm'>{t('privacyPolicy.heading9.p2')}</p>
        </div>
        <div className='space-y-2'>
          <p className='font-bold'>{t('privacyPolicy.heading9.title2')}</p>
          <p className='text-sm'>{t('privacyPolicy.heading9.p3')}</p>
          <p className='text-sm'>{t('privacyPolicy.heading9.p4')}</p>
          <p className='text-sm'>{t('privacyPolicy.heading9.p5')}</p>
        </div>
        <div className='space-y-2'>
          <p className='font-bold'>{t('privacyPolicy.heading9.title3')}</p>
          <p className='text-sm'>{t('privacyPolicy.heading9.p6')}</p>
        </div>
        <div className='space-y-2'>
          <p className='font-bold'>{t('privacyPolicy.heading9.title4')}</p>
          <p>{t('privacyPolicy.heading9.p7')}</p>
        </div>
        <div className='space-y-2'>
          <p className='font-bold'>{t('privacyPolicy.heading9.title5')}</p>
          <p className='text-sm'>{t('privacyPolicy.heading9.p8')}</p>
        </div>
        <div className='space-y-2'>
          <p className='font-bold'>{t('privacyPolicy.heading9.title6')}</p>
          <p className='text-sm'>{t('privacyPolicy.heading9.p9')}</p>
        </div>
        <div className='space-y-2'>
          <p className='font-bold'>{t('privacyPolicy.heading9.title7')}</p>
          <p className='text-sm'>{t('privacyPolicy.heading9.p10')}</p>
          <p className='text-sm font-bold'>{t('privacyPolicy.heading9.boldAddress')}</p>
          <p className='text-sm'>{t('privacyPolicy.heading9.p1Email')} <a href={'mailto:info@auctionic.ch'} target='_blank' className='font-bold' rel="noreferrer" >info@auctionic.ch</a> {t('privacyPolicy.heading9.p2Email')}</p>
          <p className='text-sm'>{t('privacyPolicy.heading9.p11')}</p>
          <p className='text-sm'>{t('privacyPolicy.heading9.p12')}</p>
        </div>
        <div className='space-y-2'>
          <li className='font-bold'>{t('privacyPolicy.heading10.title')}</li>
          <p className='text-sm'>{t('privacyPolicy.heading10.p1')}</p>
        </div>
        <div className='space-y-2'>
          <li className='font-bold'>{t('privacyPolicy.heading11.title')}</li>
          <p className='text-sm'>{t('privacyPolicy.heading11.p1')}</p>
          <p className='text-auctionicRed'>COPYRIGHT BY AUCTIONIC AG</p>
        </div>
      </ol>
      <div className='pt-8'>
        <p className='text-center'>
          <a href='https://auctionic.ch/' className='text-auctionicRed'>AUCTIONIC AG</a>, Auweg 2, 8371 Busswil <a href='mailto:info@auctionic.ch'>info@auctionic.ch</a>
        </p>
      </div>
    </div>
  );
};

export { PrivacyPolicy };
