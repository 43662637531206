import React from 'react';
import { useTranslation } from 'react-i18next';
import { utils } from '../../helpers/utils';
import { PropertyNotFound } from '../PropertyListing/PropertyNotFound';
import { DashboardLoading } from '../LoadingTest/DashboardLoading';
import { utilsProperty } from '../PropertyDetails/utils';
interface Props {
  favProperty: any;
  handleNavigation: any;
  loadingFavorite: any;
}

const FavoriteProperties = (props: Props) => {
  const { favProperty, handleNavigation , loadingFavorite } = props;
  const { t } = useTranslation();
  window.scrollTo(0, 0);

  return (
    <div className='bg-[#F5F5F5] rounded-lg px-5 py-[24px] mt-10 sm:mt-0 text-darkGray'>
      <div className='text-[20px] border-b border-darkGray font-press-start text-center sm:text-justify'>
        {t('dashboard.favouriteSection.favouriteProperties')}
      </div>
      <div className='grid grid-cols-1 '>
        {loadingFavorite ? <DashboardLoading />:
          <>
            {favProperty.length !== 0 ? (
              <>
                {favProperty?.map((dat: any, index:any) => {
                  return (
                    <div className='py-2' key={index}>
                      <div
                        className='block rounded-xl transition-all ease-out duration-100 hover:bg-white hover:shadow-lg border-[0.5px] shadow-lg'
                      >
                        <div className='block sm:flex py-5 px-4'>
                          <div className='self-center '>
                            <img
                              src={dat?.header_pic}
                              alt=''
                              className='rounded-md bg-black w-full sm:h-[110px] sm:w-[120px] '
                            />
                          </div>
                          <div className='block sm:flex justify-center sm:justify-between pl-3 mt-4 sm:mt-0 sm:pr-3 sm:w-full'>
                            <div className='self-center space-y-1.5 sm:col-span-2 my-4 sm:my-0 ml-0 sm:ml-3'>
                              <div className='text-[15px] font-bold text-darkGray'>
                                {utilsProperty.generateTitleList(dat,t)}
                              </div>
                              <div className='text-[15px] text-red-500 font-press-start'>
                                {dat?.type === 'auction' ? (
                                  <p>CHF {utils.numberWithComma(dat?.current_bid)}</p>
                                ) : (
                                  <p>CHF {utils.numberWithComma(dat?.price)}</p>
                                )}
                              </div>
                              {dat?.title !== '' && (
                                <div className='text-[15px] font-press-start text-darkGray'>
                                  {dat?.title}
                                </div>
                              )}
                              <div className='flex justify-start space-x-1 mt-4 sm:mt-0 text-xs'>
                                <p className='self-center truncate text-darkGray'>
                                  {t('dashboard.favouriteSection.propertyVisitors')}
                                </p>
                                <span className='flex space-x-1 font-bold'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 24 24'
                                    strokeWidth='2'
                                    stroke='currentColor'
                                    className='w-5 h-5 self-center'
                                  >
                                    <path
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                      d='M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z'
                                    />
                                    <path
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                      d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
                                    />
                                  </svg>
                                  <p className='flex mt-1 self-center truncate text-darkGray'>
                                    {dat?.views} {t('dashboard.favouriteSection.views')}
                                  </p>
                                </span>
                              </div>
                            </div>
                            <div className='self-center mt-7 sm:mt-0'>
                              <button className='mt-2 w-full sm:w-auto sm:px-4 py-2 bg-auctionicRed text-white rounded-md hover:bg-gray-200 hover:text-darkGray transition-all ease-out duration-150' onClick={()=> handleNavigation(dat)}>
                                {t('dashboard.overviewSection.seeProperty')}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <PropertyNotFound name={t('dashboard.noFavouriteProperty')} />
            )}
          </>}
      </div>
    </div>
  );
};
export { FavoriteProperties };
