import React from 'react';
import Countdown from 'react-countdown';
import { useTranslation } from 'react-i18next';
import { utils } from '../../helpers/utils';

interface Props {
    property: any;
}

const BidTimer = (props: Props) => {
  const { property } = props;
  const { t } = useTranslation();
  const d1 = new Date(property.finishing_date);
  const d2 = utils.convertUTCDateToLocalDate(d1);
  const finish = d2.getTime();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const renderer = ({ days, hours, minutes, seconds }) => {
    return <span>{days} {t('landing.days')}, {hours}:{minutes}:{seconds}</span>;
  };

  return (
    <>
      <Countdown
        autoStart={true}
        daysInHours={false}
        date={finish}
        renderer={renderer}
      />
    </>
  );
};

export { BidTimer };
