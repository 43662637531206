import React from 'react';
import Bid from '../../images/bidding.svg';
import Secure from '../../images/secure.svg';
import Swiss from '../../images/mapOfSwiss.svg';
import { useTranslation } from 'react-i18next';

const LearnMore = () => {
  window.scrollTo(0, 0);
  const { t } = useTranslation();
  return (
    <>
      <section className="bg-white">
        <div
          className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
          <div className="font-light text-gray-500 sm:text-lg">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-darkGray">
              {t('learnMore.title')}
            </h2>
            <p className="mb-4 text-gray-500">
              {t('learnMore.paragraph1')}
            </p>
            {/* <p className='text-gray-500'>
                With a commitment to customer satisfaction and a focus on innovation,
                Auctionic is the go-to choice for anyone looking to make the most of their real estate investment.
            </p> */}
          </div>
          <div className="grid grid-cols-2 gap-4 mt-8">
            <img className="w-full rounded-lg"
              src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/office-long-2.png"
              alt="office content 1"/>
            <img className="mt-4 w-full lg:mt-10 rounded-lg"
              src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/office-long-1.png"
              alt="office content 2"/>
          </div>
        </div>
      </section>
      <section className="bg-white">
        <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
          <a href="/create-ad"
            className="inline-flex justify-between items-center py-2 px-2 pr-4 mb-10 text-sm text-white bg-darkGray rounded-full hover:bg-gray-700"
            role="alert">
            <span className="text-xs bg-auctionicRed rounded-full text-white px-4 py-1.5 mr-3"></span> <span
              className="text-sm font-medium">{t('learnMore.createAd')}</span>
            <svg className="ml-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"></path>
            </svg>
          </a>
          <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl">
            {t('learnMore.title2')}
          </h1>
          <p className="mb-3 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48">
            {t('learnMore.paragraph2')} {t('learnMore.paragraph3')}
          </p>
          {/* <p className="mb-3 text-lg font-normal  text-left text-gray-500 lg:text-xl sm:px-16 xl:px-48">
            {t('learnMore.paragraph3')}
          </p> */}
          <p className=" text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48">
            {t('learnMore.paragraph4')}
          </p>
          <p className="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48">
            <ul>
              <li>{t('learnMore.li1')}</li>
              <li>{t('learnMore.li2')}</li>
              <li>{t('learnMore.li3')}</li>
              <li>{t('learnMore.li4')}</li>
              <li>{t('learnMore.li5')}</li>
            </ul>
          </p>
          <div className="px-4 mx-auto text-center md:max-w-screen-md lg:max-w-screen-lg lg:px-16">
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-14 mt-8">
              <img className="w-full h-[340px] object-cover rounded-lg"
                src="https://www.thepinnaclelist.com/wp-content/uploads/2016/12/01-Feldbalz-House-Luxury-Residence-Zurichsee-Zurich-Switzerland.jpg"
                alt="office content 1"
              />
              <img className="mt-4 w-full lg:mt-10 object-cover h-[340px] rounded-lg"
                src="https://www.thepinnaclelist.com/wp-content/uploads/2016/12/19-Feldbalz-House-Luxury-Residence-Zurichsee-Zurich-Switzerland.jpg"
                alt="office content 2"/>
              <img className="mt-12 w-full lg:mt-24 object-cover h-[340px] rounded-lg"
                src="https://www.e-architect.com/wp-content/uploads/2019/05/aeschbachquartier-in-aarau-switzerland-k220519-b6.jpg"
                alt="office content 2"/>
            </div>
          </div>
        </div>
      </section>
      <div className='relative top-[800px] sm:top-[550px] mt-[-350px] sm:mt-[-220px]'>
        <h1 className="mb-4 text-4xl font-extrabold text-center tracking-tight leading-none text-white md:text-5xl lg:text-6xl">
          {t('learnMore.title3')}
        </h1>
        <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
            <div>
              <div
                className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12">
                <svg className="w-6 h-6 lg:w-6 lg:h-6" fill="white"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd"
                    d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
                    clipRule="evenodd"></path>
                  <path
                    d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z"></path>
                </svg>
              </div>
              <h3 className="mb-2 text-xl text-white font-bold">{t('learnMore.subtitle1')}</h3>
              <p className="text-white">
                {t('learnMore.sub1p1')}
                {t('learnMore.sub1p2')}
              </p>
            </div>
            <div>
              <div
                className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12">
                <svg className="w-6 h-6 text-primary-600 lg:w-6 lg:h-6" fill="white"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd"
                    d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11.707 4.707a1 1 0 00-1.414-1.414L10 9.586 8.707 8.293a1 1 0 00-1.414 0l-2 2a1 1 0 101.414 1.414L8 10.414l1.293 1.293a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"></path>
                </svg>
              </div>
              <h3 className="mb-2 text-xl font-bold text-white">
                {t('learnMore.subtitle2')}
              </h3>
              <p className="text-white">
                {t('learnMore.sub2p1')}
              </p>
            </div>
            <div>
              <div
                className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12">
                <svg className="w-6 h-6 text-primary-600 lg:w-6 lg:h-6"
                  fill="white" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path>
                </svg>
              </div>
              <h3 className="mb-2 text-xl font-bold text-white">
                {t('learnMore.subtitle3')}
              </h3>
              <p className="text-white">
                {t('learnMore.sub3p1')}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-auctionicRed h-[950px] sm:h-[600px]">
        <svg data-name="Layer 1" fill={'white'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120"
          preserveAspectRatio="none">
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            className="shape-fill"></path>
        </svg>
      </div>
      <section className='mt-24 pb-10'>
        <div className='px-[15px] sm:px-[200px]'>
          <div className='flex justify-center pb-20'>
            <h1 className="mb-4 text-4xl font-extrabold text-center tracking-tight leading-none text-darkGray md:text-5xl lg:text-6xl">
              {t('learnMore.title4')}
            </h1>
          </div>
          <div className='grid grid-cols-1 lg:grid-cols-3 md:grid-cols-1 justify-between sm:space-x-14 space-x-0'>
            <div className='mb-5'>
              <div className={'flex justify-center'}>
                <img src={Bid} className='mb-5'/>
              </div>
              <p className='flex justify-start font-bold mb-3 text-darkGray'>{t('listingItem.createAd.onlineAuction')}</p>
              <p className='flex text-darkGray'>{t('listingItem.createAd.advertiseRealEstate')}</p>
              <ul className='list-disc pt-2 pl-5'>
                <li>{t('listingItem.createAd.li1')}</li>
                <li>{t('listingItem.createAd.li2')}</li>
                <li>{t('listingItem.createAd.li3')}</li>
                <li>{t('listingItem.createAd.li4')}</li>
                <li>{t('listingItem.createAd.li5')}</li>
                <li>{t('listingItem.createAd.li6')}</li>
                <li>{t('listingItem.createAd.li7')}</li>
                <li>{t('listingItem.createAd.li8')}</li>
                <li>{t('listingItem.createAd.li9')}</li>
              </ul>
            </div>
            <div className='mb-5'>
              <div className={'flex justify-center'}>
                <img src={Secure} className='mb-5'/>
              </div>
              <p className='flex justify-start font-bold mb-3 text-darkGray'>{t('listingItem.createAd.security')}</p>
              <p className='flex text-darkGray'>{t('listingItem.createAd.ultimateSecurity')}</p>
            </div>
            <div>
              <div className={'flex justify-center'}>
                <img src={Swiss} className='mb-5'/>
              </div>
              <p className='flex justify-start font-bold mb-3 text-darkGray'>{t('listingItem.createAd.fromSwiss')}</p>
              <p className='flex text-darkGray'>{t('listingItem.createAd.discorverReliability')}</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export { LearnMore };
