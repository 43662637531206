import { ContactUsInput } from '../config/interfaces';
import { auctionicApi } from '../helpers/api';
import { headers } from '../helpers/headers';

const contactUs = (contactData: ContactUsInput) => {
  const body = contactData;
  const config = { headers: headers.getHeaders() };
  return auctionicApi.post('/email/contact', body, config);
};

const captchaToken = (token: string) => {
  const body = { token };
  const config = { headers: headers.getHeaders() };
  return auctionicApi.post('/verify/captcha/', body, config);
};


export const contactServices = {
  contactUs,
  captchaToken,
};
