import React from 'react';
import { useTranslation } from 'react-i18next';
import landing from '../../images/axa-landing.jpg';

const FinanceAndInsure = () => {
  const { t } = useTranslation();
  return (
    <div className='sm:my-[115px] my-10'>
      <div className='space-y-6 pb-6'>
        <div className='text-xl sm:text-[45px] text-center leading-normal'>
          {t('services.financeAndInsure.title')}
        </div>
        <div className='text-lg sm:text-[35px] text-center'>
          AXA
        </div>
      </div>
      <div className='space-y-20'>
        <div className='relative lg:h-80 md:h-56 h-80 bg-gradient-to-r from-blue-900 via-blue-900 flex self-center'>
          <img src={landing} className="opacity-50 object-cover h-full w-full" alt="" />
          <div className='absolute xl:left-20 md:left-4 left-4 grid grid-flow-row self-center text-white space-y-4'>
            <h1 className='lg:text-4xl md:text-2xl text-2xl font-bold'>{t('services.financeAndInsure.companyName')}</h1>
            <div className='xl:text-2xl lg:text-xl md:text-sm text-base'>
              <p>{t('services.financeAndInsure.txt1')}</p>
              <p>{t('services.financeAndInsure.txt2')}</p>
              <p>{t('services.financeAndInsure.txt3')}</p>
            </div>
            <div>
              <a
                href='https://www.axa.ch/en/forms/consultation-sme.html?overwriteData=oVU-JfyZylzKn3DUTvApzgXUbGDQ-GdQnKjpsWOdCi16H0RzBy2g2r6BTAJVjJSM'
                className='bg-[#00008f] hover:bg-white hover:text-[#00008f] px-3 py-2 w-auto'>
                {t('services.financeAndInsure.requestAdvice')}
              </a>
            </div>
          </div>
        </div>
        <div className='mx-10 py-5 sm:px-16 md:px-5 px-5 bg-slate-200 rounded-md'>
          <h1 className='pb-5 text-2xl font-bold'>{t('services.financeAndInsure.keyPoints')}</h1>
          <ul className='space-y-3'>
            <li>
              <h1 className='font-bold'>
                {t('services.financeAndInsure.key1')}
              </h1>
              <p>
                {t('services.financeAndInsure.key2')}
              </p>
            </li>
            <li>
              <h1 className='font-bold'>
                {t('services.financeAndInsure.key3')}
              </h1>
              <p>
                {t('services.financeAndInsure.key4')}
              </p>
            </li>
            <li>
              <h1 className='font-bold'>
                {t('services.financeAndInsure.key5')}
              </h1>
              <p>
                {t('services.financeAndInsure.key6')}
              </p>
            </li>
          </ul>
        </div>
        <div>
          <div className='text-center lg:text-5xl md:text-2xl text-2xl font-bold pb-10 px-10 md:px-5'>
            {t('services.financeAndInsure.whoNeeds')}
          </div>
          <div className='text-center lg:text-2xl md:text-base text-base px-16 md:px-5'>
            {t('services.financeAndInsure.whoNeeds2')}
          </div>
        </div>
        <div className='bg-slate-200 py-10 space-y-10'>
          <div className='text-center lg:text-5xl md:text-2xl text-2xl font-bold sm:pb-10 md:pb-0 px-10 md:px-5'>
            {t('services.financeAndInsure.whatDoes')}
          </div>
          <div className='text-center lg:text-2xl md:text-base text-base px-16 md:px-5'>
            {t('services.financeAndInsure.whatDoes2')}
          </div>
          <div className='flex justify-center'>
            <a
              href='https://www.axa.ch/en/corporate-customers/offers/inventory-real-estate/property-insurance.html'
              className='px-3 py-2 text-xl text-white hover:text-[#00008f] bg-[#00008f] hover:bg-slate-200 hover:border hover:border-[#00008f]'>
              {t('services.financeAndInsure.showDetails')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export { FinanceAndInsure };
