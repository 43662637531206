import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../context/AuthContext';
import Stepper from './Stepper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import { propertyServices } from '../../services/propertyServices';
import { utils } from '../../helpers/utils';
import visa from '../../images/visa.svg';
import mastercard from '../../images/mastercard-alt.svg';
import twint from '../../images/twint.svg';
import datatrans from '../../images/datatrans.svg';
import bexio from '../../images/bexio-ag-logo-vector.svg';

interface Props {
  handleClickNext: any;
  handleClickPrev: any;
  steps: any;
  propertyInput: any;
  paymentTypeData: any;
  t: any;
  prevImages:any;
  showRadio:any;
  handlePaymentDataChange:any;
  setPaymentTypeData:any;
}

const ListingInfos = (props: Props) => {
  const [step] = useState(3);
  const {
    handleClickNext,
    handleClickPrev,
    steps,
    paymentTypeData,
    propertyInput,
    t,
    prevImages,
    showRadio,
    setPaymentTypeData
  } = props;
  const navigate = useNavigate();
  const { user } = useAuth();
  // const [userIdentity, setUserIdentity] = useState();
  const [discountSum, setDiscountSum] = useState({
    percentage: '',
  });
  const [method, setMethod] = useState('bill');

  const changeMethod = (method_:string) =>{
    setMethod(method_);
    setPaymentTypeData((prev:any) => ({
      ...prev,
      payment:{
        ...prev.payment,
        method:method_ }
    }));
  };

  useEffect(() => {
    propertyServices
      .getDiscount()
      .then((response) => {
        window.scrollTo(0, 0);
        setDiscountSum(response.data);
      })
      .catch((error: any) => {
        console.error('User could not found: ', error);
      });
  }, []);

  const [codeTypo, setCodeTypo] = useState({
    discount: '',
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setCodeTypo((prevInput) => {
      return {
        ...prevInput,
        [name]: value,
      };
    });
  };
  const [code, setCode] = useState(false);
  const validCode = `AUCTIONIC${discountSum.percentage}`;
  const checkCode = () => {
    if (codeTypo.discount == validCode) {
      setCode(true);
      setPaymentTypeData((prev: any) => ({ ...prev, payment: { ...prev.payment, discount: discountSum.percentage } }));
    } else {
      setCode(false);
    }

  };
  const calculateDiscount = Number(discountSum.percentage) / 100;
  const discountLeft = Number(paymentTypeData.payment.price) * Number(calculateDiscount);
  const totalPrice = Number(paymentTypeData.payment.price) - Number(discountLeft);

  return (
    <div className='bg-white sm:px-24 px-2 sm:py-12 py-2 min-h-screen font-press-start'>
      {/* <div className='flex justify-center'>*/}
      {/*  <img src={Logo} alt='' className='py-6' />*/}
      {/* </div>*/}
      <div className='flex justify-between sm:pb-10 sm:pt-20 pb-5 pt-10'>
        <div className=''>
          <p className='text-[32px]'>{t('listingItem.createListing')}</p>
        </div>
        <div className='pt-2'>
          <button
            onClick={() => navigate(-1)}
            className='bg-darkGray w-[100px] h-[34px] text-center text-white rounded-md'
          >
            {t('listingItem.buttonCancel')}
          </button>
        </div>
      </div>
      <div className='border border-darkGray w-full ' />
      <div className='sm:grid sm:grid-cols-1 md:grid md:grid-cols-1 lg:grid lg:grid-cols-3 font-avenir55  '>
        <div className='col-span-2 pt-10 rounded-md mt-2 sm:mt-16 sm:px-10 '>
          <div className='sm:flex sm:space-x-5 border-b-2 border-white bg-gray-50 p-5 rounded-lg'>
            <div className='sm:w-[45%] sm:h-[250px] mb-5'>
              <Swiper
                slidesPerView={1}
                modules={[Autoplay]}
                autoplay={true}
                className='h-full'
              >
                {prevImages &&
                  prevImages.map((image: any, index: number) => {
                    return (
                      <SwiperSlide key={index}>
                        <img src={image} alt='' className='h-full m-auto rounded-lg' />
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>
            <div className=' py-5'>
              <p className='text-[24px]'>{propertyInput.title}</p>
              <p className='text-[24px]'>
                {propertyInput.size_rooms != '0' && propertyInput.size_rooms} {propertyInput.size_rooms != '0' && t('listingItem.fifthForm.rooms')}
                <>
                  {propertyInput.property_types?.includes(8) || propertyInput.property_types?.includes(11) ? (
                    <>
                      {propertyInput.size_rooms != '0' && propertyInput.plot_area != 0 && <>, </>}
                      {propertyInput.plot_area != 0 && <>{propertyInput.plot_area}</>}
                      {propertyInput.plot_area != 0 && <> m</>}
                      {propertyInput.plot_area != 0 && <><sup className='text-lg'>2</sup></>}
                    </>
                  ):(
                    <>
                      {(propertyInput.size_rooms != '0' && propertyInput.living_space != 0) ? (<>, </>):''}
                      {propertyInput.living_space != 0 && <>{propertyInput.living_space}</>}
                      {propertyInput.living_space != 0 && <> m</>}
                      {propertyInput.living_space != 0 && <><sup className='text-lg'>2</sup></>}
                    </>
                  )}

                </>
              </p>
              <p className='sm:pt-4 text-[24px] border-b border-auctionicRed flex space-x-1'>
                CHF&nbsp;
                {paymentTypeData.type === 'auction' ?
                  utils.numberWithComma(paymentTypeData.starting_price)
                  : paymentTypeData.type === 'sale' ?
                    utils?.numberWithComma(paymentTypeData.price)
                    : paymentTypeData.type === 'rent' ?
                      utils.numberWithComma(paymentTypeData.price)
                      : null}
              </p>
            </div>
          </div>
          <div className='border-b-2 border-white py-5 bg-gray-50 p-5 rounded-lg'>
            <p className='text-[25px] font-semibold'>{t('listingItem.fifthForm.advertisement')}</p>
            <div className='block space-y-4'>
              <div className='flex space-x-1'>
                <p className=''>{t('listingItem.fifthForm.paymentType')}:</p>
                <p className='font-semibold'>{t(`listingItem.${paymentTypeData.type}`)}</p>
              </div>
              <div className='flex space-x-1'>
                <p className=''>{t('listingItem.fifthForm.tierType')}:</p>
                <p className='capitalize font-semibold'>{showRadio}</p>
              </div>
              <div className='flex space-x-1'>
                <p className=''>{t('listingItem.fifthForm.duration')}:</p>
                <p className='uppercase font-semibold'>{paymentTypeData.duration} {' '}<span className=''>{t('listingItem.fifthForm.days')}</span></p>
              </div>
              <div className='flex space-x-1'>
                <p className=''>{t('listingItem.fifthForm.price')}:</p>
                <p className='uppercase font-semibold'>CHF {paymentTypeData.payment.price}.00</p>
              </div>
              {Object.keys(discountSum).length !== 0  ? (
                <div>
                  <div className='flex space-x-5'>
                    <div className='sm:w-[40%]'>
                      <label className='font-medium'>{t('listingItem.fifthForm.redeemCode')}</label>
                      <input name='discount' onChange={handleChange} value={codeTypo.discount} type='text' className='block my-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer required ' placeholder=''/>
                    </div>
                    <div className='self-center'>
                      <button onClick={checkCode} className='px-4 py-2 bg-darkGray rounded-md text-white text-sm hover:bg-gray-500 transition-all ease-in-out duration-150'>{t('listingItem.fifthForm.calculate')}</button>
                    </div>
                  </div>
                  {code ? <p>{t('listingItem.fifthForm.correctCode')}: {totalPrice?.toFixed(2)} CHF</p> : <p>{t('listingItem.fifthForm.discountCode')}</p>}
                </div>
              ):(
                ''
              )
              }
            </div>
          </div>
          <div className='space-y-4 border-b-2 border-white py-5 bg-gray-50 p-5 rounded-lg'>
            <p className='text-[25px] font-semibold'>{t('listingItem.fifthForm.billingDetails')}</p>
            {user?.type=='individual' ? (
              <div className='flex sm:space-x-5'>
                <div className='flex space-x-1 mr-1'>
                  <p className=''>{t('listingItem.fifthForm.firstName')}</p>
                  <p className='font-semibold'>{user?.first_name} </p>
                </div>
                <div className='flex space-x-1'>
                  <p className=''>{t('listingItem.fifthForm.lastName')}</p>
                  <p className='capitalize font-semibold'>{user?.last_name}</p>
                </div>
              </div>
            ):(
              user?.type=='company' && (
                <div className='flex sm:space-x-5'>
                  <div className='flex space-x-1'>
                    <p className=''>{t('listingItem.fifthForm.companyName')}</p>
                    <p className='font-semibold'>{user?.name}</p>
                  </div>
                </div>
              )
            )}
            <div className='flex space-x-1'>
              <p className=''>{t('listingItem.fifthForm.address')}</p>
              <p className='font-semibold'>{user?.address.formatted}</p>
            </div>
            <div className='flex space-x-1'>
              <p className=''>{t('listingItem.fifthForm.phoneNumber')}</p>
              <p className='font-semibold'>{user?.phone}</p>
            </div>
            <div className='flex space-x-1'>
              <p className=''>{t('listingItem.fifthForm.email')}</p>
              <p className='font-semibold'>{user?.email}</p>
            </div>
          </div>
          <div className='border-b-2 border-white py-5 bg-gray-50 p-5 rounded-lg'>
            <p className='text-[25px] font-semibold'>{t('listingItem.fifthForm.chooseMethod')}</p>
            <div className='flex space-x-2 items-center'>
              <input
                type='radio'
                name='card'
                className='h-6 w-6 accent-auctionicRed'
                checked={method=='card'}
                onClick={() => changeMethod('card')}
                onChange={() => changeMethod('card')}
              />
              <div onClick={()=>changeMethod('card')} className={`grid justify-between items-center grid-flow-row sm:grid-flow-col border-b-2 border-white w-full ${method=='card'? 'bg-auctionicRed text-white':'bg-gray-50' } p-5 rounded-lg`}>
                <div className='sm:pb-0 pb-5 text-[20px] font-semibold'>{t('listingItem.fifthForm.card')}</div>
                <div className=''>
  
                  <div className='flex items-center space-x-5 mb-5'>
                    <div>
                      <img src={visa} />
                    </div>
                    <div>
                      <img src={mastercard} />
                    </div>
                    <div>
                      <img src={twint} />
                    </div>
                  </div>
                  <div className='flex items-center justify-end'>
                    Powered by
                    <img src={datatrans} className='max-h-[35px] ml-5'/>
                  </div>
                </div>
              </div>
            </div>
            <div className='flex space-x-2 items-center'>
              <input
                type='radio'
                name='bill'
                className='h-6 w-6 accent-auctionicRed'
                checked={method=='bill'}
                onClick={() => changeMethod('bill')}
                onChange={() => changeMethod('bill')}
              />
              <div onClick={()=>{changeMethod('bill');}} className={`border-b-2 border-white w-full grid justify-between grid-flow-row sm:grid-flow-col items-center ${method=='bill'? 'bg-auctionicRed text-white':'bg-gray-50' } p-5 rounded-lg`}>
                <div className='text-[20px] font-semibold'>{t('listingItem.fifthForm.bill')}</div>
                <div className={`flex ${method=='bill'? 'bg-auctionicRed text-white':'bg-gray-50'} justify-end`}>
                  <div className='w-[200px] flex items-center justify-end'>
                    <p>Powered by</p>
                    <img src={bexio} className='ml-5 max-h-[75px]' />
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <div className='col-span-0 sm:col-span-1 hidden sm:block'>
          <Stepper steps={steps} activeStep={step} />
        </div>
      </div>
      <div className='pt-12 flex justify-between'>
        <button
          className='hover:scale-125 hover:ease-in duration-300'
          onClick={handleClickPrev}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-6 w-6'
            fill='none'
            viewBox='0 0 24 24'
            stroke='black'
          >
            <path d='M10 19l-7-7m0 0l7-7m-7 7h18' />
          </svg>
        </button>
        <button
          className='bg-darkGray w-[100px] h-[34px] text-center text-white rounded-md hover:scale-105 hover:ease-in duration-300 '
          onClick={handleClickNext}
        >
          {t('listingItem.buttonNext')}
        </button>
      </div>
      <div className='col-span-0 sm:col-span-1 block sm:hidden '>
        <Stepper steps={steps} activeStep={step} />
      </div>
    </div>
  );
};

export { ListingInfos };
