import React from 'react';
import { useTranslation } from 'react-i18next';
import iazilogo from '../../images/iazi-logo.svg';
import img1 from '../../images/realEstateEvaluation1.jpg';
import img2 from '../../images/realEstateEvaluation2.png';

const RealEstateEvaluation = () => {
  const { t } = useTranslation();
  return (
    <div className='sm:my-[115px] my-10'>
      <div className='space-y-6'>
        
        <div className='text-xl sm:text-[45px] text-center leading-normal'>
          {t('services.realEstateEvaluation.title')}
        </div>
        <div className='text-lg sm:text-[35px] text-center'>
          IAZI - CIFI
        </div>

        <div className='relative flex self-center h-72 md:h-64 lg:h-full'>
          <img src={img1} className='w-full object-cover object-left h-full'/>
          <div className='absolute flex self-center space-x-3 xl:left-36 xl:right-36 lg:left-28 lg:right-28 md:left-16 md:right-16 left-10 right-10'>
            <img className='xl:w-[200px] xl:h-[200px] lg:w-[160px] lg:h-[160px] md:w-[160px] md:h-[160px] w-[130px] h-[130px]' src={iazilogo} />
            <h1 className='xl:text-4xl lg:text-2xl md:text-2xl text-base text-white self-center drop-shadow-md'>
              {t('services.realEstateEvaluation.textImg')}
            </h1>
          </div>
        </div>

        <div className='px-10 pt-5'>
          <h1 className='text-xl font-extrabold pb-3'>{t('services.realEstateEvaluation.dscHeader')}</h1>
          <p>{t('services.realEstateEvaluation.dsc')}<br/><br/>{t('services.realEstateEvaluation.dsc2')}
            <a className='text-blue-900' href='https://online.iazi.ch/?customer=Auctionic' target='_blank' rel='noopener noreferrer'> https://online.iazi.ch</a></p>
        </div>

        <div className='flex justify-center pt-10'>
          <img src={img2} />
        </div>
        
        <div className='flex justify-center'>
          <div className='bg-[#669dc9] hover:bg-[#2a78b8] rounded-full w-[170px] text-2xl text-center text-white p-4' >
            <a href="https://online.iazi.ch/?customer=Auctionic" target='_blank' rel='noopener noreferrer'>{t('services.realEstateEvaluation.visitUs')}</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export { RealEstateEvaluation };
