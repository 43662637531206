import React from 'react';
import Image from '../../images/phoneRegister.png';
import Image2 from '../../images/learnMore.png';
import Image3 from '../../images/ArrowLeft.svg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Infos = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className='block sm:flex justify-center'>
      <div className='relative text-white border-2 border-white'>
        <img src={Image} alt='' />
        <div className='absolute bottom-2 left-4 font-press-start'>
          {t('infos.message')}
          <div className='flex pt-2 place-items-center'>
            <p>{t('infos.subscribe')}</p>
            <button
              onClick={() => navigate('/newsletter')}
              className='bg-white ml-3 hover:px-5 transition-all ease-in-out duration-150 rounded px-3 py-3'
            >
              <img src={Image3} alt='' />
            </button>
          </div>
        </div>
      </div>
      <div className='relative text-white border-2 border-white'>
        <img src={Image2} alt='' />
        <div className='absolute bottom-2 left-4 font-press-start'>
          {t('infos.trustedWebsite')}
          <div className='flex pt-2 place-items-center'>
            <p> {t('infos.learn')}</p>
            <button className='bg-white ml-3 hover:px-5 transition-all ease-in-out duration-150 rounded px-3 py-3' onClick={() => navigate('/learnMore')}>
              <img src={Image3} alt='' />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Infos };
