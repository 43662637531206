import React from 'react';
import AuctionicR from '../../images/logoRed.svg';
import { useTranslation } from 'react-i18next';
import img1 from '../../images/aboutUsBg.jpg';

const Auctionic = () => {
  const { t } = useTranslation();
  window.scrollTo(0, 0);
  return (
    <div className='relative flex bg-white font-press-start justify-center'>
      <img src={img1} className='object-cover object-bottom lg:h-[900px] md:h-[1100px] h-[1000px] w-full' alt="" />
      <div className='absolute grid grid-cols-1 gap-5 sm:px-10 md:px-24 px-4 sm:py-12 py-2'>
        <div className='flex justify-center pb-5 lg:pt-28 md:pt-16 pt-10'>
          <img src={AuctionicR} alt='' className='h-[100px]' />
        </div>
        <div className='text-darkGray'>
          <div className=' max-w-4xl'>
            <div className='flex pb-5'>
              <p className='text-lg'>{t('auctionicAg.founded')}</p>
            </div>
            <p className='text-lg pb-1'>{t('auctionicAg.bestPrice')}</p>
            <ul className='pl-7 space-y-1 font-semibold text-darkGray'>
              <li className='text-md list-disc'>{t('auctionicAg.provider')}</li>
              <li className='text-md list-disc'>{t('auctionicAg.public')}</li>
              <li className='text-md list-disc'>{t('auctionicAg.financial')}</li>
              <li className='text-md list-disc'>{t('auctionicAg.investors')}</li>
              <li className='text-md list-disc'>{t('auctionicAg.individuals')}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>  );
};
export { Auctionic };
