import { t } from 'i18next';
import React, { useState } from 'react';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-google-places-autocomplete';
import './PropertyDetails.css';

export const PrefferedLocationModal = (props: any) => {
  const { setModal, setPrefferedLocation } = props;
  const [inputValue, setInputValue] = useState('');

  const handleChange = (location: any) => {
    geocodeByAddress(location.label)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) =>
        setPrefferedLocation((prev: any) => ({ ...prev, lat: lat, lng: lng }))
      );
  };

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const handleSubmmit = () => {
    setModal(false);
    setPrefferedLocation((prev: any) => ({ ...prev, name: inputValue }));
  };

  const handleCancel = () => {
    setModal(false);
    setPrefferedLocation((prev: any) => ({
      ...prev,
      name: '',
      lat: null,
      lng: null,
    }));
  };

  return (
    <div
      className='relative z-10'
      aria-labelledby='modal-title'
      role='dialog'
      aria-modal='true'
    >
      <div className='fixed inset-0 bg-black bg-opacity-75 transition-opacity'></div>

      <div
        className='fixed inset-0 z-10 overflow-y-auto'
        onClick={handleCancel}
      >
        <div className='flex min-h-full justify-center p-4 text-center items-center sm:p-0'>
          <div className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-2xl'>
            <div
              className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 h-96'
              onClick={(e) => e.stopPropagation()}
            >
              <div className='flex justify-between'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='1.5'
                  stroke='currentColor'
                  className='w-6 h-6 bg-gray-100 m-2 rounded-full'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M15 10.5a3 3 0 11-6 0 3 3 0 016 0z'
                  />
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z'
                  />
                </svg>
                <h3 className='text-lg font-medium leading-6 text-gray-900 self-center'>
                  {t('propertyDetails.calculateDistance')}
                </h3>
                <button
                  type='button'
                  onClick={handleCancel}
                  className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center'
                  data-modal-toggle='defaultModal'
                >
                  <svg
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    className=''
                  >
                    <path
                      d='M16 13.4587L10.4567 7.986L15.9233 2.46467L13.4587 0L7.984 5.54533L2.444 0.0766667L0 2.52067L5.54733 8.014L0.0766667 13.556L2.52067 16L8.012 10.4547L13.5353 15.9233L16 13.4587Z'
                      fill='black'
                    />
                  </svg>
                </button>
              </div>
              <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left place-items-center'>
                <div className=''>
                  <div className='mt-2'>
                    <label className='block mb-2 text-sm font-medium text-gray-900 '>
                      {t('propertyDetails.yourLocation')}
                    </label>
                    <GooglePlacesAutocomplete
                      selectProps={{
                        onChange: handleChange,
                      }}
                    />
                  </div>
                  <div className='mt-16'>
                    <label className='block mb-2 text-sm font-medium text-gray-900 '>
                      {t('propertyDetails.locationName')}
                    </label>
                    <input
                      type='text'
                      className='block my-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer required'
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className='flex justify-end mt-10'>
                    <button
                      onClick={handleSubmmit}
                      type='button'
                      className='text-white bg-auctionicRed hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center'
                    >
                      {t('propertyDetails.setDistance')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
