import { Address, AddressInput } from '../config';

export const utils = {
  formatAddress,
  formatDate,
  convertUTCDateToLocalDate,
  formatTimewithoutSeconds,
  numberWithComma,
  formatAddressEdit,
  localDateToUTC,
};

function formatAddress(inputAdress: AddressInput): Address {
  const street =
    inputAdress.street != '' && inputAdress.street != undefined
      ? inputAdress.street + ','
      : ' ';
  const zip =
    inputAdress.zip != '' && inputAdress.zip != undefined
      ? inputAdress.zip
      : ' ';
  const city =
    inputAdress.city != '' && inputAdress.city != undefined
      ? inputAdress.city + ','
      : ' ,';
  const canton =
    inputAdress.canton != '' && inputAdress.canton != undefined
      ? inputAdress.canton + ','
      : ' ,';
  const language = localStorage.getItem('locale');
  let country = 'Schweiz';
  if (language != null) {
    if (language == 'de') {
      country = 'Schweiz';
    }
    else if (language == 'en') {
      country = 'Switzerland';
    }
    else if (language == 'fr') {
      country = 'Suisse';
    }
    else if (language == 'it') {
      country = 'Svizzera';
    }
  } else {
    country = 'Schweiz';
  }
  return {
    address: `${street} ${zip} ${city} ${canton} ${country}`,
  };
}

function formatAddressEdit(inputAdress: AddressInput): Address {
  const street =
    inputAdress.street != '' && inputAdress.street != undefined
      ? inputAdress.street + ','
      : ' ';
  const zip_code =
    inputAdress.zip_code != '' && inputAdress.zip_code != undefined
      ? inputAdress.zip_code
      : ' ';
  const city =
    inputAdress.city != '' && inputAdress.city != undefined
      ? inputAdress.city + ','
      : ' ,';
  const canton =
    inputAdress.canton != '' && inputAdress.canton != undefined
      ? inputAdress.canton + ','
      : ' ,';
  const language = localStorage.getItem('locale');
  let country = 'Schweiz';
  if (language != null) {
    if (language == 'de') {
      country = 'Schweiz';
    }
    else if (language == 'en') {
      country = 'Switzerland';
    }
    else if (language == 'fr') {
      country = 'Suisse';
    }
    else if (language == 'it') {
      country = 'Svizzera';
    }
  } else {
    country = 'Schweiz';
  }
  return {
    address: `${street} ${zip_code} ${city} ${canton} ${country}`,
  };
}

function formatDate(date: string | Date, t: any) {
  const new_date = convertUTCDateToLocalDate(date);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return `${new_date.getDate()} ${t(
    `months.${months[new_date.getMonth()]}`
  )} ${new_date.getFullYear()}`;
}

function convertUTCDateToLocalDate(date: string | Date) {
  const _date = new Date(date);
  const newDate = new Date(
    _date.getTime() + _date.getTimezoneOffset() * 60 * 1000
  );
  newDate.setHours(_date.getHours() - _date.getTimezoneOffset() / 60);
  return newDate;
}

function formatTimewithoutSeconds(date: string | Date) {
  const new_date = convertUTCDateToLocalDate(date);
  const minutes = new_date.getMinutes();
  return `${new_date.getHours()}:${minutes < 10 ? `0${minutes}` : minutes}`;
}

function numberWithComma(x: number) {
  if (x != null) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\'');
  }
}

function localDateToUTC(value: any) {
  if (value != null && value != '') {
    const localDate = new Date(value);
    const value1 = localDate.toISOString().slice(0, -8);
    return value1;
  }
  else {
    return null;
  }
}
