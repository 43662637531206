import { t } from 'i18next';
import React from 'react';
import Logo from '../../images/blackLogo.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import './Services.css';
import { EffectCoverflow, Pagination, Autoplay } from 'swiper';
import svg1 from '../../images/home-insurance-services.svg';
import svg2 from '../../images/rental-deposit-services.svg';
import svg3 from '../../images/debt-collection-services.svg';
import svg4 from '../../images/legal-consultation-services.svg';
import svg5 from '../../images/relocation-assistance-services.svg';
import svg6 from '../../images/property-management-services.svg';
import svg7 from '../../images/realestate-management-services.svg';
import svg8 from '../../images/cleaning-services.svg';
import svg9 from '../../images/building-renovation-services.svg';
import svg10 from '../../images/real-estate-evaluation-services.svg';

interface Props {
  handleChangeServices:any;
}

const ServicesIntro = (props:Props) => {
  const { handleChangeServices } = props;
  return (
    <div className='my-10 sm:mt-[115px]'>
      <div className='space-y-5'>
        <div className='flex justify-center'>
          <img src={Logo} alt='' className='sm:w-[305px] sm:h-[56px]' />
        </div>
        <div className='sm:text-[35px] text-center'>
          {t('services.services')}
        </div>
        <div className='sm:text-[20px] mt-[38px] sm:mx-10'>
          <div className='mx-4'>{t('services.typesOfServices')}</div>
          <div className='flex justify-center mx-auto'>
            <div className='flex w-auto max-w-[340px]'>
              <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={'auto'}
                coverflowEffect={{
                  rotate: 50,
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                  slideShadows: true,
                }}
                autoplay={{
                  delay:3000
                }}
                width={340}
                pagination={true}
                modules={[Autoplay, EffectCoverflow, Pagination]}
                className='swiper-service'
              >
                <SwiperSlide className='swiper-slide-services' onClick={() => handleChangeServices('financeAndInsure')}>
                  <div className='bg-auctionicRed w-[100%] h-[100%] rounded-xl grid gap-1 content-center'>
                    <div className='flex justify-center'>
                      <img
                        className='w-[120px] h-[120px] lg:min-w-[200px] lg:min-h-[200px]'
                        src={svg1}
                        alt=''
                      />
                    </div>
                    <div className='text-center font-braga text-white text-3xl'>
                      <h1>{t('services.financeAndInsure.title')}</h1>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide className='swiper-slide-services' onClick={() => handleChangeServices('rentalDeposit')}>
                  <div className='bg-auctionicRed w-[100%] h-[100%] rounded-xl grid gap-1 content-center'>
                    <div className='flex justify-center'>
                      <img
                        className='w-[120px] h-[120px] lg:min-w-[200px] lg:min-h-[200px]'
                        src={svg2}
                        alt=''
                      />
                    </div>
                    <div className='text-center font-braga text-white text-3xl'>
                      <h1>{t('services.rentalDeposit')}</h1>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide className='swiper-slide-services' onClick={() => handleChangeServices('realEstateEvaluation')}>
                  <div className='bg-auctionicRed w-[100%] h-[100%] rounded-xl grid gap-1 content-center'>
                    <div className='flex justify-center'>
                      <img
                        className='w-[120px] h-[120px] lg:min-w-[200px] lg:min-h-[200px]'
                        src={svg10}
                        alt=''
                      />
                    </div>
                    <div className='text-center font-braga text-white text-3xl'>
                      <h1>{t('services.realEstateEvaluation.title')}</h1>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide className='swiper-slide-services' onClick={() => handleChangeServices('debtCollection')}>
                  <div className='bg-auctionicRed w-[100%] h-[100%] rounded-xl grid gap-1 content-center'>
                    <div className='flex justify-center'>
                      <img
                        className='w-[120px] h-[120px] lg:min-w-[200px] lg:min-h-[200px]'
                        src={svg3}
                        alt=''
                      />
                    </div>
                    <div className='text-center font-braga text-white text-3xl'>
                      <h1>{t('services.debtCollection')}</h1>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide className='swiper-slide-services' onClick={() => handleChangeServices('legalConsultation')}>
                  <div className='bg-auctionicRed w-[100%] h-[100%] rounded-xl grid gap-1 content-center'>
                    <div className='flex justify-center'>
                      <img
                        className='w-[120px] h-[120px] lg:min-w-[200px] lg:min-h-[200px]'
                        src={svg4}
                        alt=''
                      />
                    </div>
                    <div className='text-center font-braga text-white text-3xl'>
                      <h1>{t('services.legalConsultation.title')}</h1>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide className='swiper-slide-services' onClick={() => handleChangeServices('relocationAssistance')}>
                  <div className='bg-auctionicRed w-[100%] h-[100%] rounded-xl grid gap-1 content-center'>
                    <div className='flex justify-center'>
                      <img
                        className='w-[120px] h-[120px] lg:min-w-[200px] lg:min-h-[200px]'
                        src={svg5}
                        alt=''
                      />
                    </div>
                    <div className='text-center font-braga text-white text-3xl'>
                      <h1>{t('services.moving')}</h1>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide className='swiper-slide-services' onClick={() => handleChangeServices('propertyManagement')}>
                  <div className='bg-auctionicRed w-[100%] h-[100%] rounded-xl grid gap-1 content-center'>
                    <div className='flex justify-center'>
                      <img
                        className='w-[120px] h-[120px] lg:min-w-[200px] lg:min-h-[200px]'
                        src={svg6}
                        alt=''
                      />
                    </div>
                    <div className='text-center font-braga text-white text-3xl'>
                      <h1>{t('services.propertyManagement.title')}</h1>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide className='swiper-slide-services' onClick={() => handleChangeServices('realEstateManagement')}>
                  <div className='bg-auctionicRed w-[100%] h-[100%] rounded-xl grid gap-1 content-center'>
                    <div className='flex justify-center'>
                      <img
                        className='w-[120px] h-[120px] lg:min-w-[200px] lg:min-h-[200px]'
                        src={svg7}
                        alt=''
                      />
                    </div>
                    <div className='text-center font-braga text-white text-3xl'>
                      <h1>{t('services.realEstateManagement.title')}</h1>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide className='swiper-slide-services' onClick={() => handleChangeServices('cleaningAndHygieneServices')}>
                  <div className='bg-auctionicRed w-[100%] h-[100%] rounded-xl grid gap-1 content-center'>
                    <div className='flex justify-center'>
                      <img
                        className='w-[120px] h-[120px] lg:min-w-[200px] lg:min-h-[200px]'
                        src={svg8}
                        alt=''
                      />
                    </div>
                    <div className='text-center font-braga text-white text-3xl'>
                      <h1 className='px-4'>
                        {t('services.cleaningAndHygieneServices')}
                      </h1>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide className='swiper-slide-services' onClick={() => handleChangeServices('buildingAndRenovation')}>
                  <div className='bg-auctionicRed w-[100%] h-[100%] rounded-xl grid gap-1 content-center'>
                    <div className='flex justify-center'>
                      <img
                        className='w-[120px] h-[120px] lg:min-w-[170px] lg:min-h-[170px]'
                        src={svg9}
                        alt=''
                      />
                    </div>
                    <div className='text-center font-braga text-white text-3xl'>
                      <h1>{t('services.buildingAndRenovation.title')}</h1>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ServicesIntro };
