import React, {
  ReactNode,
  useMemo,
  useState,
} from 'react';
import { AuthContext, } from './AuthContext';
import { userServices, } from '../services';
import { AxiosResponse, } from 'axios';
import { LoadingTest, Login, } from '../components';
import {
  auctionicApi,
} from '../helpers/api';
import { User, } from '../config';


const AuthProvider = ({ children, }: { children: ReactNode }) => {
  const [initialized, setInitialized,] = useState(false);
  const [user, setUser,] = useState<User>({} as User);
  const [loading, setLoading,] = useState(false);
  const [error, setError,] = useState<any>(null);
  const [token, setToken,] = useState<string | null>(null);

  const logout = () => {
    localStorage.clear();
    setUser({} as User);
    setToken(null);
  };

  const auth = () => {
    setLoading(true);
    setInitialized(true);
    auctionicApi.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
    userServices.userAuth()
      .then((response: AxiosResponse<User>) => {
        setToken(`Bearer ${localStorage.getItem('access_token')}`);
        setUser(response.data);
      })
      .catch(() => {
        logout();
      })
      .finally(() => setLoading(false));
  };


  const memoizedValue = useMemo(
    () => {
      if (!user.id && !initialized && !loading) {
        auth();
      }
      return {
        user,
        token,
        loading,
        error,
        auth,
        logout,
      };
    },
    [user,]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {
        loading && <LoadingTest/>
      }
      {
        user.id && !loading && children
      }
      {
        !loading && !user.id && (
          <Login/>
        )
      }
    </AuthContext.Provider>
  );
};

export { AuthProvider, };
