import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ContactUsInput } from '../../config';
import Logo from '../../images/logoRed.svg';
import { contactServices } from '../../services/contactServices';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SpinnerLoading } from '../LoadingTest/SpinnerLoading';
import HCaptcha from '@hcaptcha/react-hcaptcha';
const ContactForm = () => {
  const { t } = useTranslation();
  const subjects = [
    {
      id: 1,
      subject: t('contact.signInUp'),
    },
    {
      id: 2,
      subject: t('contact.ads'),
    },
    {
      id: 3,
      subject: t('contact.images'),
    },
    {
      id: 4,
      subject: t('contact.search'),
    },
    {
      id: 5,
      subject: t('contact.payments'),
    },
    {
      id: 6,
      subject: t('contact.userAccount'),
    },
    {
      id: 7,
      subject: t('contact.errors'),
    },
    {
      id: 8,
      subject: t('contact.fraud'),
    },
    {
      id: 9,
      subject: t('contact.requestQuote'),
    },
    {
      id: 10,
      subject: t('contact.suggestion'),
    },
    {
      id: 11,
      subject: t('contact.other'),
    },
  ];
  const [inputContact, setInputContact] = useState({} as ContactUsInput);
  const navigate = useNavigate();
  const [error, setError] = useState<string[]>([]);
  const [contactLoader,setContactLoader] = useState(false);
  const handleContactChange = (event: any) => {
    const errorList:string[] = [];
    setError(errorList);
    const { name, value } = event.target;
    setInputContact((prevInputContact) => {
      return {
        ...prevInputContact,
        [name]: value,
      };
    });
  };
  const [openModal, setOpenModal] = useState(false);
  const checkEmailFormat = (email: any) => {
    return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
  };
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [token, setToken] = useState(null);
  const captchaRef = useRef(null);

  const onLoad = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    captchaRef.current.execute();
  };

  useEffect(() => {
    if (token)
    {console.log(`hCaptcha Token: ${token}`);}

  }, [token]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClick = () => {
    const errorList:string[] = [];
    if (!inputContact.first_name)
    {
      errorList.push('first_name');
    } else if (!inputContact.last_name)
    {
      errorList.push('last_name');
    } else if (!inputContact.email)
    {
      errorList.push('email');
    } else if (!checkEmailFormat(inputContact?.email)) {
      errorList.push('email_incorrect');
    } else if (!inputContact.subject)
    {
      errorList.push('subject');
    } else if (!inputContact.message) {
      errorList.push('message');
    }
    else {
      setShowCaptcha(true);
    }
    setError(errorList);
  };

  const handleCaptchSubmit = (token: string) => {
    contactServices.captchaToken(token).then(() => {
      sendContactForm();
    }).catch(() => {
      toast.error(t('errors.contactE'), {
        position: toast.POSITION.BOTTOM_LEFT,
      });    });
  };

  const sendContactForm = () => {

    setContactLoader(true);
    contactServices
      .contactUs(inputContact)
      .then((response) => {
        setContactLoader(false);
        setInputContact(response.data);
        setOpenModal(true);
        setTimeout(() => {
          setOpenModal(false);
          navigate(0);
        }, 10000);
      })
      .catch(() => {
        setContactLoader(false);
        toast.error(t('errors.contactE'), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };

  const getCountry = () => {
    const language = localStorage.getItem('locale');
    let country = 'Schweiz';
    if (language != null) {
      if (language == 'de') {
        country = 'Schweiz';
      }
      else if (language == 'en') {
        country = 'Switzerland';
      }
      else if (language == 'fr') {
        country = 'Suisse';
      }
      else if (language == 'it') {
        country = 'Svizzera';
      }
    } else {
      country = 'Schweiz';
    }
    return country;
  };
  
  return (
    <div className='sm:px-10 md:px-24 px-2 sm:py-12 py-2 min-h-screen font-press-start bg-gray-50'>
      <ToastContainer autoClose={4000} />
      <div className='flex justify-center'>
        <button onClick={() => navigate('/')}>
          <img src={Logo} alt='' />
        </button>
      </div>
      <div className='pt-14 sm:pt-32 '>
        <h1 className='sm:text-[30px] text-xl font-avenir55 border-b border-red-500 py-5'>
          {t('contact.contactUs')}
        </h1>
      </div>
      <div className='grid grid-cols-1 sm:grid-cols-2 pt-10'>
        <div className=''>
          <div className='shadow-lg shadow-gray-500 rounded-2xl p-10 space-y-8 sm:space-y-5'>
            <div className='grid grid-cols-1 sm:grid-cols-2 gap-8 sm:gap-5'>
              <div className='block'>
                <label className='pr-2 ' title={t('reqField.requiredField')}>{t('contact.firstname')}*</label>
                <input
                  type='text'
                  name='first_name'
                  className=' py-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-black peer required '
                  onChange={handleContactChange}
                />
                {error?.includes('first_name') ? (
                  <p className='text-xs text-red-500 absolute '>
                    {t('contact.firstNameRequired')}
                  </p>
                ) : (
                  ''
                )}
              </div>
              <div className='block'>
                <label className='pr-2 ' title={t('reqField.requiredField')}>{t('contact.lastname')}*</label>
                <input
                  type='text'
                  name='last_name'
                  className=' py-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-black peer required '
                  onChange={handleContactChange}
                />
                {error?.includes('last_name') ? (
                  <p className='text-xs text-red-500 absolute '>
                    {t('contact.lastNameRequired')}
                  </p>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className='grid grid-cols-1 sm:grid-cols-2 gap-8 sm:gap-5'>
              <div className='block'>
                <label className='pr-2 ' title={t('reqField.requiredField')}>{t('contact.email')}*</label>
                <input
                  type='text'
                  name='email'
                  className=' py-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-black peer required '
                  onChange={handleContactChange}
                />
                {error?.includes('email') ? (
                  <p className='text-xs text-red-500 absolute '>
                    {t('contact.emailRequired')}
                  </p>
                ) : (
                  ''
                )}
                {error?.includes('email_incorrect') ? (
                  <p className='text-xs text-red-500 absolute '>
                    {t('contact.emailNotValid')}
                  </p>
                ) : (
                  ''
                )}
              </div>
              <div className='block'>
                <label className='pr-2 ' title={t('reqField.requiredField')}>{t('contact.subject')}*</label>
                <div className='flex'>
                  <select
                    name='subject'
                    onChange={handleContactChange}
                    className='appearance-none z-10 relative block mt-4 px-1 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-b-gray-300 focus:outline-none focus:ring-0 focus:border-b-black'
                  >

                    <option defaultChecked>{t('contact.selectSubject')}</option>
                    {subjects.map((subject) => {
                      return (
                        <option key={subject.id} value={subject.subject}>
                          {subject.subject}
                        </option>
                      );
                    })}
                  </select>
                  <div className='z-0'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth='1.5'
                      stroke='currentColor'
                      className='w-6 h-6 mt-4 absolute flex justify-end -ml-6'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M19.5 8.25l-7.5 7.5-7.5-7.5'
                      />
                    </svg>
                  </div>
                </div>
                {error?.includes('subject') ? (
                  <p className='text-xs text-red-500 absolute '>
                    {t('contact.subjectRequired')}
                  </p>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className='block space-y-3'>
              <label className='pr-2 ' title={t('reqField.requiredField')}>{t('contact.message')}*</label>
              <textarea
                className='text-[14px] w-full h-56 border-2 rounded-md px-1 bg-transparent focus:outline-none focus:border-black'
                name='message'
                onChange={handleContactChange}
              />
              {error?.includes('message') ? (
                <p className='text-xs text-red-500 absolute '>
                  {t('contact.messageRequired')}
                </p>
              ) : (
                ''
              )}
            </div>
            {showCaptcha ? (
              <form className='flex justify-center'>
                <HCaptcha
                  sitekey="7b1f48d5-fe34-44bd-ae6d-09373fd4e524"
                  onLoad={onLoad}
                  onVerify={handleCaptchSubmit}
                  ref={captchaRef}
                />
              </form>
            ) : ( '' )}
            <div className='flex justify-center'>
              <button
                className='bg-red-500 rounded-md text-white px-5 py-2'
                onClick={handleClick}
              >
                {contactLoader ? <SpinnerLoading /> : <p>{t('contact.send')}</p>}
              </button>
            </div>
          </div>
          {openModal && (
            <div id="popup-modal"
              className="fixed top-0 pt-[7%] left-0 flex justify-center right-0 z-50 p-7 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">
              <div className="relative w-full h-full max-w-md md:h-auto">
                <div className="relative bg-white rounded-lg shadow dark:bg-darkGray">
                  <div className='flex justify-center'>
                    <img src={Logo} alt='' className='p-7' />
                  </div>
                  <div className="p-6 text-center">
                    <h3 className="mb-5 text-lg font-normal text-darkGray dark:text-white">
                      {t('contact.sentYourRequest')}
                    </h3>
                    <button onClick={() => {setOpenModal(false), navigate(0);}} data-modal-toggle="popup-modal" type="button"
                      className="text-white bg-red-600 hover:bg-red-800   font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                          Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className=' text-lg py-20 sm:py-0 '>
          <div className='sm:px-20'>
            <div className='text-2xl'>
              <p>{t('contact.info')}</p>
            </div>
            <div className='mt-10'>
              <div className='text-xl font-bold'>AUCTIONIC AG</div>
              <div>Auweg 2</div>
              <div>8371 Busswil TG</div>
              <div>{getCountry()}</div>
              <div>UID: CHE-223.456.369</div>
              <div className='flex absolute'>
                <p>{t('contact.email')}:</p>
                <a
                  href='mailto:support@auctionic.ch'
                  className='hover:border-b hover:border-red-500 transtion-all ease-in-out duration-100 ml-2'
                >
                  support@auctionic.ch
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export { ContactForm };
