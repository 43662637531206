const cantons = [
  {
    location: 'Z\u00fcrich',
    id: 1,
  },
  {
    location: 'Bern',
    id: 2,
  },
  {
    location: 'Luzern',
    id: 3,
  },
  {
    location: 'Uri',
    id: 4,
  },
  {
    location: 'Schwyz',
    id: 5,
  },
  {
    location: 'Obwalden',
    id: 6,
  },
  {
    location: 'Glarus',
    id: 7,
  },
  {
    location: 'Zug',
    id: 8,
  },
  {
    location: 'Freiburg',
    id: 9,
  },
  {
    location: 'Solothurn',
    id: 10,
  },
  {
    location: 'Basel Stadt',
    id: 11,
  },
  {
    location: 'Schaffhausen',
    id: 12,
  },
  {
    location: 'Appenzell Ausserrhoden',
    id: 13,
  },
  {
    location: 'Sankt Gallen',
    id: 14,
  },
  {
    location: 'Graub\u00fcnden',
    id: 15,
  },
  {
    location: 'Aargau',
    id: 16,
  },
  {
    location: 'Thurgau',
    id: 17,
  },
  {
    location: 'Ticino',
    id: 18,
  },
  {
    location: 'Vaud',
    id: 19,
  },
  {
    location: 'Valais',
    id: 20,
  },
  {
    location: 'Neuch\u00e2tel',
    id: 21,
  },
  {
    location: 'Gen\u00e8ve',
    id: 22,
  },
  {
    location: 'Jura',
    id: 23,
  },
  {
    location: 'Nidwalden',
    id: 24,
  },
  {
    location: 'Basel Land',
    id: 25,
  },
  {
    location: 'Appenzell Innerrhoden',
    id: 26,
  },
];

export { cantons };
