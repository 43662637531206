import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Stepper from './Stepper';
import { Auction } from './Auction';
import { FixedPrice } from './FixedPrice';
import { Rent } from './Rent';
import { VerificationAlert } from '../Common';

interface Props {
  steps: any;
  handleClickNext: any;
  t: any;
  setPaymentTypeData: any;
  handlePaymentMethodChange: any;
  paymentTypeData: any;
  error: any;
  setError: any;
  setShowRadio: any;
  user: any;
}
const PaymentForms = (props: Props) => {
  const {
    steps,
    handleClickNext,
    t,
    setPaymentTypeData,
    handlePaymentMethodChange,
    paymentTypeData,
    error,
    setError,
    setShowRadio,
    user,
  } = props;
  const [step] = useState(0);
  const navigate = useNavigate();
  const { state } = useLocation() as any;
  const [customState, setCustomState] = useState(state);
  const [typeIsChanged, setTypeIsChanged] =useState('');

  const [buyNowChecked, setBuyNowChecked] = useState(false);
  const handleBuyNowChecked = () => {
    setBuyNowChecked(!buyNowChecked);
    setPaymentTypeData((prevState:any) => ({
      ...prevState,
      buy_now: !buyNowChecked,
    }));
  };
  const handleNext = () => {
    const errorList = [];
    if (!paymentTypeData.start_date) {
      errorList.push('start_date');
    }
    if (!paymentTypeData.starting_price) {
      errorList.push('starting_price');
    }
    if (paymentTypeData.starting_price > '2147483646') {
      errorList.push('starting_price_max');
    }
    if (!paymentTypeData.minimum_increasing_amount) {
      errorList.push('minimum_increasing_amount');
    }
    if (paymentTypeData.buy_now === true) {
      if (!paymentTypeData.buy_now_price) {
        errorList.push('buy_now');
      }
      if (paymentTypeData.buy_now_price > '2147483646') {
        errorList.push('buy_now_max');
      }
    }
    if (paymentTypeData.starting_price && paymentTypeData.buy_now_price) {
      if (parseInt(paymentTypeData.buy_now_price) <= parseInt(paymentTypeData.starting_price)) {
        errorList.push('buy_now_price');
      }
    }
    if (errorList.length === 0) {
      handleClickNext();
    }
    setError(errorList);
  };

  const handleTypeChange = (e: any) => {
    const { name, value } = e.target;
    if (name === 'type') {
      state.type = value;
      setCustomState(state);
      paymentTypeData.type = value;
      setPaymentTypeData({
        type: value,
      });
      setShowRadio('');
    }
    setTypeIsChanged(value);
  };
  const handleNextSale = () => {
    const errorList = [];
    if (!paymentTypeData.start_date) {
      errorList.push('start_date');
    }
    if (!paymentTypeData.price) {
      errorList.push('price');
    }
    if (paymentTypeData.price > '2147483646') {
      errorList.push('price_max');
    }
    if (errorList.length === 0) {
      handleClickNext();
    }
    setError(errorList);
  };
  const handleNextRent = () => {
    const errorList = [];
    if (!paymentTypeData.start_date) {
      errorList.push('start_date');
    }
    if (!paymentTypeData.price) {
      errorList.push('price');
    }
    if (paymentTypeData.price > '2147483646') {
      errorList.push('price_max');
    }
    {if (errorList.length === 0) {
      handleClickNext();
    }}
    setError(errorList);
  };
  
  useEffect(() => {
    if (!state) {
      console.log('sss');
      navigate('/listingItem', { state: { type: 'auction', showRadio: true } });
    }else {
      setCustomState(state);
      if (state.type === 'auction') {
        setPaymentTypeData((prev: any) => ({
          ...prev,
          type: 'auction'
        }));
      }
      else if (state.type === 'sale') {
        setPaymentTypeData((prev: any) => ({
          ...prev,
          type: 'sale'
        }));
      }
      else if (state.type === 'rent') {
        setPaymentTypeData((prev: any) => ({
          ...prev,
          type: 'rent'
        }));
      }
    }
  },[state, typeIsChanged]);
  
 
  const handleNavigationForms = () => {
    if (paymentTypeData.type === 'auction') {
      handleNext();
    }
    else if (paymentTypeData.type === 'sale') {
      handleNextSale();
    }
    else {handleNextRent();}
  };
  return (
    <div className='bg-white sm:px-24 px-2 sm:py-12 py-2 min-h-screen font-press-start'>
      {user.type== 'individual' && user.email_confirmed == false || user.phone_confirmed == false || user.id_card_confirmed == false ?
        <VerificationAlert />
        : user.type === 'company' && user.email_confirmed == false || user.phone_confirmed == false || user.address_confirmed == false ?
          <VerificationAlert />
          : '' }
      <div className='flex justify-between sm:pb-10 sm:pt-20 pb-5 pt-10'>
        <div className=''>
          <p className='text-[32px]'>{t('listingItem.createListing')}</p>
        </div>
        <div className='pt-2'>
          <button
            onClick={() => navigate(-1)}
            className='bg-darkGray w-[100px] h-[34px] text-center text-white rounded-md'
          >
            {t('listingItem.buttonCancel')}
          </button>
        </div>
      </div>
      <div className='border border-darkGray w-full ' />
      <div className='sm:grid sm:grid-cols-1 md:grid md:grid-cols-1 lg:grid lg:grid-cols-3 '>
        <div className='col-span-2 pt-10'>
          <p className='text-[22px] pl-3 sm:pl-0'>{t('listingItem.payment')}</p>
          <div className=''>
            <div className='sm:flex sm:space-x-10 pt-5 pl-3 sm:pl-0 text-lg'>
              {state && state.showRadio == false ? <p className='italic text-md items-center'>{state.type === 'auction' ? <>{t('listingItem.listingPropTypeAuction')} <span className='text-auctionicRed items-center'> {t('listingItem.auction')}</span> </> : <>{t('listingItem.listingPropType')} <span className='text-auctionicRed items-center'>{state.type === 'rent' ? t('listingItem.rent') :state.type === 'sale' ? t('listingItem.sale') : ''} </span> </>} </p> :
                <>
                  <div className='flex space-x-2'>
                    <label className='label'>
                      {t('listingItem.auction')}
                      <input
                        type='radio'
                        name='type'
                        className='h-6 w-6 '
                        value='auction'
                        onChange={handleTypeChange}
                        checked={paymentTypeData?.type === 'auction'}/>
                      <span className='checkmark'></span>
                    </label>
                  </div>
                  <div className='flex space-x-2'>
                    <label className='label'>
                      {t('listingItem.fixedPrice')}
                      <input
                        type='radio'
                        name='type'
                        className='h-6 w-6'
                        value='sale'
                        onChange={handleTypeChange}
                        checked={paymentTypeData?.type === 'sale'}/>
                      <span className='checkmark'></span>
                    </label>{' '}
                  </div>
                  <div className='flex space-x-2'>
                    <label className='label'>{t('listingItem.rent')}
                      <input
                        type='radio'
                        name='type'
                        className='h-6 w-6'
                        value='rent'
                        onChange={handleTypeChange}
                        checked={paymentTypeData?.type === 'rent'}/>
                      <span className='checkmark'></span>
                    </label>{' '}
                  </div>
                </>
              }
            </div>
            {customState?.type === 'auction' && (
              <Auction
                setPaymentTypeData={setPaymentTypeData}
                handlePaymentDataChange={handlePaymentMethodChange}
                error={error}
                setError={setError}
                paymentTypeData={paymentTypeData}
                handleBuyNowChecked={handleBuyNowChecked}
                buyNowChecked={buyNowChecked}
                setBuyNowChecked={setBuyNowChecked}
              />
            )}
            {customState?.type === 'sale' && (
              <FixedPrice handlePaymentDataChange={handlePaymentMethodChange} error={error}
                setPaymentTypeData={setPaymentTypeData} paymentTypeData={paymentTypeData}/>
            )}
            {customState?.type === 'rent' && (
              <Rent handlePaymentDataChange={handlePaymentMethodChange} error={error}
                setPaymentTypeData={setPaymentTypeData} paymentTypeData={paymentTypeData}/>
            )}
          </div>
        </div>
        <div className='col-span-0 sm:col-span-1 hidden sm:block '>
          <Stepper steps={steps} activeStep={step} />
        </div>
      </div>
      <div className='pt-12 flex justify-end'>
        <button
          className='bg-darkGray w-[100px] disabled:bg-gray-600 h-[34px] text-center text-white rounded-md hover:scale-105 hover:ease-in duration-300 '
          onClick={handleNavigationForms}
          disabled={
            (user.type== 'individual' && user.email_confirmed == false || user.phone_confirmed == false || user.id_card_confirmed == false) ||
              (user.type == 'company' && user.email_confirmed == false || user.phone_confirmed == false || user.address_confirmed == false)
          }
        >
          {t('listingItem.buttonNext')}
        </button>
      </div>
      <div className='col-span-0 sm:col-span-1 block sm:hidden '>
        <Stepper steps={steps} activeStep={step} />
      </div>
    </div>
  );
};

export { PaymentForms };
