import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CreatedAccount = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className='registerContainer'>
      <div className='flex items-center justify-center py-[64px] px-4 sm:px-6 lg:px-8 font-avenir55 '>
        <div className='max-w-3xl w-full bg-white'>
          <p className='text-center pt-[70px] text-[32px] font-normal font-braga'>
            {t('register.congrats')}
          </p>
          <p className='text-[16px] text-center pt-[133px] px-5 sm:px-0 font-semibold font-press-start'>
            {t('register.created')}
          </p>
          <p className='text-[9px] pt-[7px] text-center font-semibold font-press-start'>
            {t('register.return')}
          </p>
          <div className='text-center pt-[171px] pb-8'>
            <button
              onClick={() => navigate('/')}
              className='bg-darkGray text-white px-12 py-3 rounded-[4px] font-press-start'
            >
              {t('register.continue')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export { CreatedAccount };
