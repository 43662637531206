import React from 'react';
import { useTranslation } from 'react-i18next';
import logoRed from '../../images/logoRed.svg';

const TermsConditions = () => {
  window.scrollTo(0, 0);
  const { t } = useTranslation();
  return (
    <div className='bg-white
      xl:py-28 lg:py-20 md:py-16 py-10
      xl:px-80 lg:px-52 md:px-12 px-5
      block sm:grid sm:grid-cols-1 font-avenir55'>
      <img src={logoRed} className='w-[400px] mb-12' alt="" />
      <p className='font-semibold lg:text-4xl sm:text-3xl text-2xl my-1'>{t('termsAndCon.title')}</p>
      <p className='font-semibold text-md'>{t('termsAndCon.releaseDate')}</p>
      <p className='mt-3'>
        <span className='font-bold'>
          {t('termsAndCon.auctionicAg')}
        </span>{t('termsAndCon.address')}
        <a href='https://auctionic.ch'>www.auctionic.ch</a>
      </p>
      <div className='border border-darkGray w-full mb-10 mt-4' />
      <div className='space-y-2 text-justify text-sm mb-10'>
        <p className='font-semibold text-[18px]'>{t('termsAndCon.general.title')}</p>
        <p>{t('termsAndCon.general.p1-1')}<a href='https://auctionic.ch'>(www.auctionic.ch)</a>{t('termsAndCon.general.p1-2')}</p>
        <p>{t('termsAndCon.general.p2')}</p>
        <p>{t('termsAndCon.general.p3')}</p>
        <p>{t('termsAndCon.general.p4')}</p>
        <p>{t('termsAndCon.general.p5')}</p>
      </div>
      <ol className='px-16 space-y-4 font-avenir55 text-xl list-decimal'>
        <div className='space-y-2'>
          <li className='font-bold'>{t('termsAndCon.title1.title')}</li>
          <p className='font-bold'>{t('termsAndCon.title1.underTitle1.title')}</p>
          <p className='text-sm'>{t('termsAndCon.title1.underTitle1.p1')}</p>
          <p className='text-sm'>{t('termsAndCon.title1.underTitle1.p2')}</p>
          <p className='text-sm'>{t('termsAndCon.title1.underTitle1.p3')}</p>
        </div>
        <div className='space-y-2'>
          <p className='font-bold'>{t('termsAndCon.title1.underTitle2.title')}</p>
          <ul className='list-disc list-inside text-sm text-justify'>
            <li>{t('termsAndCon.title1.underTitle2.p1')}</li>
            <li>{t('termsAndCon.title1.underTitle2.p2')}</li>
            <li>{t('termsAndCon.title1.underTitle2.p3')}</li>
            <li>{t('termsAndCon.title1.underTitle2.p4')}</li>
            <li>{t('termsAndCon.title1.underTitle2.p5')}</li>
            <li>{t('termsAndCon.title1.underTitle2.p6')}</li>
            <li>{t('termsAndCon.title1.underTitle2.p7')}</li>
          </ul>
          <p className='text-sm text-justify'>{t('termsAndCon.title1.underTitle2.p8')}</p>
        </div>
        <div className='space-y-2'>
          <p className='font-bold'>{t('termsAndCon.title1.underTitle3.title')}</p>
          <p className='text-sm'>{t('termsAndCon.title1.underTitle3.p1')}</p>
          <p className='text-sm'>{t('termsAndCon.title1.underTitle3.p2')}</p>
          <p className='text-sm'>{t('termsAndCon.title1.underTitle3.p3')}</p>
        </div>
        <div className='space-y-2'>
          <p className='font-bold'>{t('termsAndCon.title1.underTitle4.title')}</p>
          <p className='text-sm'>{t('termsAndCon.title1.underTitle4.p1')}</p>
          <p className='text-sm'>{t('termsAndCon.title1.underTitle4.p2')}</p>
          <p className='text-sm'>{t('termsAndCon.title1.underTitle4.p3')}</p>
          <p className='text-sm'>{t('termsAndCon.title1.underTitle4.p4')}</p>
        </div>
        <div className='space-y-2'>
          <p className='font-bold'>{t('termsAndCon.title1.underTitle5.title')}</p>
          <p className='text-sm'>{t('termsAndCon.title1.underTitle5.p1')}</p>
        </div>
        <div className='space-y-2'>
          <li className='font-bold'>{t('termsAndCon.title2.title')}</li>
          <p className='font-bold'>{t('termsAndCon.title2.underTitle1.title')}</p>
          <p className='text-sm'>{t('termsAndCon.title2.underTitle1.p1')}</p>
          <p className='text-sm'>{t('termsAndCon.title2.underTitle1.p2')}</p>
        </div>
        <div className='space-y-2'>
          <p className='font-bold'>{t('termsAndCon.title2.underTitle2.title')}</p>
          <p className='text-sm'>{t('termsAndCon.title2.underTitle2.p1')}</p>
        </div>
        <div className='space-y-2'>
          <li className='font-bold'>{t('termsAndCon.title3.title')}</li>
          <p className='font-bold'>{t('termsAndCon.title3.underTitle1.title')}</p>
          <p className='text-sm'>{t('termsAndCon.title3.underTitle1.p1')}</p>
        </div>
        <div className='space-y-2'>
          <p className='font-bold'>{t('termsAndCon.title3.underTitle2.title')}</p>
          <p className='text-sm'>{t('termsAndCon.title3.underTitle2.p1')}</p>
          <p className='text-sm'>{t('termsAndCon.title3.underTitle2.p2')}</p>
          <p className='text-sm'>{t('termsAndCon.title3.underTitle2.p3')}</p>
        </div>
        <div className='space-y-2'>
          <p className='font-bold'>{t('termsAndCon.title3.underTitle3.title')}</p>
          <p className='text-sm'>{t('termsAndCon.title3.underTitle3.p1')}</p>
          <p className='text-sm'>{t('termsAndCon.title3.underTitle3.p2')}</p>
        </div>
        <div className='space-y-2'>
          <p className='font-bold'>{t('termsAndCon.title3.underTitle4.title')}</p>
          <p className='text-sm'>{t('termsAndCon.title3.underTitle4.p1')}</p>
          <p className='text-sm'>{t('termsAndCon.title3.underTitle4.p2')}</p>
        </div>
        <div className='space-y-2'>
          <li className='font-bold'>{t('termsAndCon.title4.title')}</li>
          <p className='font-bold'>{t('termsAndCon.title4.underTitle1.title')}</p>
          <p className='text-sm'>{t('termsAndCon.title4.underTitle1.p1')}</p>
          <p className='text-sm'>{t('termsAndCon.title4.underTitle1.p2')}</p>
          <p className='text-sm'>{t('termsAndCon.title4.underTitle1.p3')}</p>
        </div>
        <div className='space-y-2'>
          <p className='font-bold'>{t('termsAndCon.title4.underTitle2.title')}</p>
          <p className='text-sm'>{t('termsAndCon.title4.underTitle2.p1')}</p>
          <p className='text-sm'>{t('termsAndCon.title4.underTitle2.p2')}</p>
        </div>
        <div className='space-y-2'>
          <li className='font-bold'>{t('termsAndCon.title5.title')}</li>
          <p className='font-bold'>{t('termsAndCon.title5.underTitle1.title')}</p>
          <p className='text-sm'>{t('termsAndCon.title5.underTitle1.p1')}</p>
          <p className='text-sm'>{t('termsAndCon.title5.underTitle1.p2')}</p>
        </div>
        <div className='space-y-2'>
          <p className='font-bold'>{t('termsAndCon.title5.underTitle2.title')}</p>
          <p className='text-sm'>{t('termsAndCon.title5.underTitle2.p1')}</p>
        </div>
        <div className='space-y-2'>
          <p className='font-bold'>{t('termsAndCon.title5.underTitle3.title')}</p>
          <p className='text-sm'>{t('termsAndCon.title5.underTitle3.p1')}</p>
        </div>
        <div className='space-y-2'>
          <li className='font-bold'>{t('termsAndCon.title6.title')}</li>
          <p className='font-bold'>{t('termsAndCon.title6.underTitle1.title')}</p>
          <p className='text-sm'>{t('termsAndCon.title6.underTitle1.p1')}</p>
        </div>
        <div className='space-y-2'>
          <p className='font-bold'>{t('termsAndCon.title6.underTitle2.title')}</p>
          <p className='text-sm'>{t('termsAndCon.title6.underTitle2.p1')}</p>
        </div>
        <div className='space-y-2'>
          <p className='font-bold'>{t('termsAndCon.title6.underTitle3.title')}</p>
          <p className='text-sm'>{t('termsAndCon.title6.underTitle3.p1')}</p>
        </div>
        <div className='space-y-2'>
          <p className='font-bold'>{t('termsAndCon.title6.underTitle4.title')}</p>
          <p className='text-sm'>{t('termsAndCon.title6.underTitle4.p1')}</p>
        </div>
        <div className='space-y-2'>
          <p className='font-bold'>{t('termsAndCon.title6.underTitle5.title')}</p>
          <p className='text-sm'>{t('termsAndCon.title6.underTitle5.p1')}</p>
        </div>
        <div className='space-y-2'>
          <li className='font-bold'>{t('termsAndCon.title7.title')}</li>
          <p className='font-bold'>{t('termsAndCon.title7.underTitle1.title')}</p>
          <p className='text-sm'>{t('termsAndCon.title7.underTitle1.p1')}</p>
        </div>
        <div className='space-y-2'>
          <p className='font-bold'>{t('termsAndCon.title7.underTitle2.title')}</p>
          <p className='text-sm'>{t('termsAndCon.title7.underTitle2.p1')}</p>
        </div>
        <div className='space-y-2'>
          <p className='font-bold'>{t('termsAndCon.title7.underTitle3.title')}</p>
          <p className='text-sm'>{t('termsAndCon.title7.underTitle3.p1')}</p>
        </div>
        <div className='space-y-2'>
          <li className='font-bold'>{t('termsAndCon.title8.title')}</li>
          <p className='font-bold'>{t('termsAndCon.title8.underTitle1.title')}</p>
          <p className='text-sm'>{t('termsAndCon.title8.underTitle1.p1')}</p>
        </div>
        <div className='space-y-2'>
          <p className='font-bold'>{t('termsAndCon.title8.underTitle2.title')}</p>
          <p className='text-sm'>{t('termsAndCon.title8.underTitle2.p1')}</p>
        </div>
        <div className='space-y-2'>
          <p className='font-bold'>{t('termsAndCon.title8.underTitle3.title')}</p>
          <p className='text-sm'>{t('termsAndCon.title8.underTitle3.p1')}</p>
        </div>
        <div className='space-y-2'>
          <p className='font-bold'>{t('termsAndCon.title8.underTitle4.title')}</p>
          <p className='text-sm'>{t('termsAndCon.title8.underTitle4.p1')}</p>
        </div>
        <div className='space-y-2'>
          <p className='font-bold'>{t('termsAndCon.title8.underTitle5.title')}</p>
          <p className='text-sm'>{t('termsAndCon.title8.underTitle5.p1')}</p>
        </div>
        <div className='space-y-2'>
          <p className='font-bold'>{t('termsAndCon.title8.underTitle6.title')}</p>
          <p className='text-sm'>{t('termsAndCon.title8.underTitle6.p1')}</p>
        </div>
        <div className='space-y-2'>
          <p className='font-bold'>{t('termsAndCon.title8.underTitle7.title')}</p>
          <p className='text-sm'>{t('termsAndCon.title8.underTitle7.p1')}</p>
        </div>
        <div className='space-y-2'>
          <p className='font-bold'>{t('termsAndCon.title8.underTitle8.title')}</p>
          <p className='text-sm'>{t('termsAndCon.title8.underTitle8.p1')}</p>
        </div>
        <div className='space-y-2'>
          <li className='font-bold'>{t('termsAndCon.title9.title')}</li>
          <p className='text-sm'>{t('termsAndCon.title9.p1')}</p>
        </div>
        <div className='space-y-2'>
          <li className='font-bold'>{t('termsAndCon.title10.title')}</li>
          <p className='text-sm'>{t('termsAndCon.title10.p1')}</p>
        </div>
        <div className='space-y-2'>
          <li className='font-bold'>{t('termsAndCon.title11.title')}</li>
          <p className='text-sm'>{t('termsAndCon.title11.p1')}</p>
          <p className='text-sm'>{t('termsAndCon.title11.p2')}</p>
          <p className='text-sm'>{t('termsAndCon.title11.p3')}</p>
        </div>
        <div className='space-y-2'>
          <li className='font-bold'>{t('termsAndCon.title12.title')}</li>
          <p className='text-sm'>{t('termsAndCon.title12.p1')}</p>
          <p className='text-sm'>{t('termsAndCon.title12.p2')}</p>
          <p className='text-sm'>{t('termsAndCon.title12.p3')}</p>
        </div>
        <div className='space-y-2'>
          <li className='font-bold'>{t('termsAndCon.title13.title')}</li>
          <p className='text-sm'>{t('termsAndCon.title13.p1')}</p>
          <p className='text-sm'>{t('termsAndCon.title13.p2')}</p>
          <p className='text-sm'>{t('termsAndCon.title13.p3')}</p>
        </div>
        <div className='space-y-2'>
          <li className='font-bold'>{t('termsAndCon.title14.title')}</li>
          <p className='text-sm'>{t('termsAndCon.title14.p1')}</p>
        </div>
        <div className='space-y-2'>
          <li className='font-bold'>{t('termsAndCon.title15.title')}</li>
          <p className='text-sm'>{t('termsAndCon.title15.p1')}</p>
          <p className='text-sm'>{t('termsAndCon.title15.p2')}</p>
          <p className='text-auctionicRed'>{t('termsAndCon.copyright')}</p>
        </div>
      </ol>
      <div className='pt-8'>
        <p className='text-center'>
          <a href='https://auctionic.ch/' className='text-auctionicRed'>AUCTIONIC AG</a>, Auweg 2, 8371 Busswil <a href='mailto:info@auctionic.ch'>info@auctionic.ch</a>
        </p>
      </div>
    </div>
  );
};

export { TermsConditions };
