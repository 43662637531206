import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ErrorLogin1 from '../../images/ErrorLoginRedirect2.svg';

const LoginRedirect = () => {
  const { t } =  useTranslation();
  window.scrollTo(0, 0);
  const navigate = useNavigate();
  return (
    <div className='grid md:grid-cols-2 py-20 md:px-40  bg-white min-h-screen justify-center text-darkGray '>

      <div id='error' className='px-10 sm:px-0 self-center'>
        <div className='grid justify-center'>
          <h1 className='text-[58px] font-braga'>{t('loginError.sorry')}</h1>
          <hr className='sm:mr-15 border-red-600' />
          <p className='pt-5 font-press-start'>{t('loginError.to_continue')}</p>
        </div>
        <div className='flex justify-center'>
          <button className="bg-auctionicRed text-white rounded-md px-6 py-2 my-5 font-press-start text-center" onClick={() => navigate('/login')}>{t('loginError.login')}</button>
        </div>
      </div>

      {/* Image */}
      <div className='img flex justify-center'>
        <img src={ErrorLogin1} alt='' className='max-h-[500px] max-w-[500px] self-center'/>
      </div>

    </div>
  );
};

export { LoginRedirect };

