import React, { useState } from 'react';
import { ProofIdentity } from './ProofIdentity';
import { SignRequest } from './SignRequest';
import { UserDetails } from './UserDetails';
import { UserInformation } from './UserInformation';
import AuctionicLogo from '../../images/logoRed.svg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './DebtCollectionForm.css';
import useAuth from '../../context/AuthContext';

const steps = [1, 2, 3, 4];
const DebtCollectionForm = () => {
  const [step, setStep] = useState(0);
  const [input, setInput] = useState({
    birthday: '',
    images: [],
    images_back: [],
    id_document: 'id_card',
    signature: ''
  });
  const { user } = useAuth();

  const handleNext = () => {
    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };
  const navigate = useNavigate();
  const { t } = useTranslation();
  // console.log(user);

  return (
    <div className='font-press-start'>
      <div className='px-5 py-3 md:px-[120px] lg:px-[300px] '>
        <div className='flex justify-between pt-5 pb-4 px-3 '>
          <img src={AuctionicLogo} alt='' className='sm:h-8 ' />
          <button  onClick={() => navigate('/')} className='flex space-x-2 px-2 py-1 rounded-md transition-all duration-200 ease-in-out cancelBtn'>
            <p className='mt-0.5 self-center text-lg'>{t('debtCollection.cancel')}</p>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='black'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='currentColor'
              className='w-8 h-8 self-center'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M6 18L18 6M6 6l12 12'
              />
            </svg>
          </button>
        </div>
        {step === 0 ? (
          <UserInformation steps={steps} handleNext={handleNext} user={user} input={input} setInput={setInput} />
        ) : step === 1 ? (
          <ProofIdentity steps={steps} handleBack={handleBack} handleNext={handleNext} input={input} setInput={setInput} />
        ) : step === 2 ? (
          <UserDetails steps={steps} handleBack={handleBack} handleNext={handleNext} user={user} input={input} />
        ) : (
          <SignRequest steps={steps} handleBack={handleBack} input={input} setInput={setInput} />
        )}
      </div>
    </div>
  );
};

export { DebtCollectionForm };
