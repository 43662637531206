import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import Man from '../../images/man.svg';
import Bus from '../../images/bus.svg';
import Car from '../../images/car.svg';
import LikeChecked from '../../images/like_checked_filled.svg';
import Like from '../../images/likeuncheck.svg';
import { useTranslation } from 'react-i18next';
import { BidTimer } from './BidTimer';
import { utils } from '../../helpers/utils';
import './PropertyDetails.css';
import { utilsProperty } from './utils';

interface Props {
  property: any;
  handleFavouriteProperty: (id: number) => void;
  handleFavouriteDelete: (id: number) => void;
  state: any;
  handleNavigation: any;
}

export const PropertyCard = (props: Props) => {
  const { t } = useTranslation();
  const {
    property,
    handleFavouriteProperty,
    handleFavouriteDelete,
    handleNavigation,
    state,
  } = props;

  return (
    <div
      key={property.id}
      className='ml-4 sm:ml-[10%] mr-4 sm:mr-[10%] lg:ml-[15%] lg:mr-[26%] mt-10 lg:mt-[35px] mb-10 lg:mb-[85px] overflow-hidden rounded-xl transition-all duration-100 propertyCard'
    >
      <div className='hover:rounded-xl hover:bg-[#F8F8F8] pb-0.5 p-[18px] transition-all ease-linear duration-100'>
        <div className='grid grid-cols-1 sm:grid-cols-2 '>
          <div className='relative text-center rel'>
            <Swiper
              modules={[Navigation]}
              slidesPerView={1}
              navigation={true}
              className='rounded-xl'
            >
              {property.header_pic ? (
                <SwiperSlide>
                  <img
                    className='sm:w-[483px] sm:h-[364px]'
                    src={property.header_pic}
                    alt=''
                  />
                </SwiperSlide>
              ) : (
                <SwiperSlide className='flex justify-center bg-gray-100 '>
                  <div className='sm:w-[483px] sm:h-[364px]'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth='1.5'
                      stroke='gray'
                      className='w-fit flex justify-center'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z'
                      />
                    </svg>

                    <p className='text-center text-gray-500 font-thin'>
                      No images for this property.
                    </p>
                  </div>
                </SwiperSlide>
              )}
            </Swiper>
            {property.is_trending === true ? (
              <p className='text-red-600 flex bg-white text-sm absolute top-0 left-0 p-2 rounded-br-xl z-10'>
                {t('propertyListing.trending')}
                <span className='pl-2'>
                  <svg
                    width='17'
                    height='22'
                    viewBox='0 0 17 22'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M5.63293 0.547729C6.16392 6.8055 0.736572 8.9599 0.736572 14.4717C0.736572 18.2155 3.40803 21.411 8.57075 21.4389C13.7335 21.4667 16.4049 17.5966 16.4049 13.643C16.4049 10.0384 14.61 6.63924 11.2239 4.52575C12.0282 6.79505 10.9576 8.86763 9.91736 9.58141C9.97829 6.67667 8.93808 2.36613 5.63293 0.547729ZM9.73369 11.8638C13.0023 15.336 10.995 19.6979 8.36967 19.6979C6.77237 19.6979 5.95065 18.5968 5.95936 17.4548C5.9759 15.3369 8.34182 15.3352 9.73369 11.8638Z'
                      fill='#FF002B'
                    />
                  </svg>
                </span>
              </p>
            ) : (
              ''
            )}
          </div>
          <div className='sm:ml-8 sm:mt-5 py-4 sm:max-w-[350px] '>
            <p className='text-base font-normal leading-4'>
              {utilsProperty.generateTitleList(property, t)}
            </p>
            <p className='text-sm leading-3 mt-3'>{property.address}</p>
            <p className='text-sm sm:max-w-[350px] mt-6'>{property.title}</p>
            {state.type === 'auction' ? (
              <p className='mt-3 text-[18px]'>
                CHF {utils.numberWithComma(property?.current_bid)}.—
              </p>
            ) : state.type === 'sale' ? (
              <p className='mt-3 text-[18px]'>
                CHF {utils.numberWithComma(property?.price)}.—
              </p>
            ) : state.type === 'rent' ? (
              <p className='mt-3 text-[18px]'>
                CHF {utils.numberWithComma(property?.price)}.—
              </p>
            ) : (
              ''
            )}
            <div className='border border-red-600 w-36' />
            <div className='flex justify-between mt-20'>
              {state.type === 'auction' ? (
                <div>
                  <p className='text-xs'>{t('trendingEstate.timeLeft')}</p>
                  <div className='text-red-600 text-base'>
                    <BidTimer property={property} />
                  </div>
                </div>
              ) : (
                ''
              )}
              <div>
                <button
                  type='button'
                  onClick={() => handleNavigation(property.id)}
                  className='inline-block px-6 py-2.5 bg-darkGray text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-600 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out'
                >
                  {state.type === 'auction' ? (
                    <>{t('propertyListing.bidButton')}</>
                  ) : (
                    <>{t('propertyListing.viewDetails')}</>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='border border-darkGray mt-10 mb-5 ' />
        <div className='grid grid-cols-3 mb-6 '>
          <div className='flex space-x-4'>
            <img src={Man} alt='' />
            <img src={Bus} alt='' />
            <img src={Car} alt='' />
          </div>
          <div className='flex justify-center'>
            {property.isFavorite ? (
              <button
                onClick={() => handleFavouriteDelete(property.property_id)}
              >
                <img src={LikeChecked} alt='' />
              </button>
            ) : (
              <button
                onClick={() => handleFavouriteProperty(property.property_id)}
              >
                <img src={Like} alt='' />
              </button>
            )}
          </div>
          <div className='flex justify-end'>
            <button
              className='backButton bg-darkGray font-press-start rounded px-1 hover:text-gray-400 text-sm '
              onClick={() => handleNavigation(property.id)}
            >
              <span className='backIcon'>
                <svg
                  width='12'
                  height='10'
                  viewBox='0 0 12 10'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M7.12488 0.590106L10.9872 4.45229C11.2243 4.6893 11.2243 5.07356 10.9872 5.31056L7.12488 9.17275C6.88787 9.40975 6.50359 9.40975 6.26658 9.17275C6.02956 8.93574 6.02956 8.55148 6.26658 8.31448L9.09288 5.48831L0.2406 5.48831L0.2406 4.27454L9.09288 4.27454L6.26658 1.44837C6.02957 1.21137 6.02957 0.827109 6.26658 0.590106C6.50359 0.353103 6.88787 0.353103 7.12488 0.590106Z'
                    fill='white'
                  />
                </svg>
              </span>
              <span className='backText text-white '>
                {t('propertyListing.moreInfo')}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
