import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Keyboard } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import Logo from '../../images/logoRed.svg';
import OtpInput from 'react-otp-input';
import { utils } from '../../helpers/utils';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import { userServices } from '../../services/userServices';
import { SpinnerLoading } from '../LoadingTest/SpinnerLoading';
import close from '../../images/x.svg';
import { ToastContainer } from 'react-toastify';
import { utilsProperty } from './utils';

interface BuyNowDialogProps {
  handleOpenBuyNow: any;
  propertyDetails: any;
  form: any;
  setForm: any;
  inputData: any;
  setInputData: any;
  confirmBuyNowRequest: any;
  handleBuyNowRequest: any;
  reloadPage: any;
  buttonLoader: any;
}

const BuyNowPrice = (props: any) => {
  const { propertyDetails, setForm } = props;
  return (
    <div className='py-2 h-full relative '>
      <div className='text-darkGray font-press-start h-full rounded-md'>
        <div className='rounded-md w-full absolute sm:top-[50%] sm:left-3 sm:right-3 bg-gray-50 space-y-2.5 p-2'>
          <p className='font-bold text-[19px] font-avenir55 text-center self-center rounded-md text-darkGray'>
            {t('propertyDetails.buyNowPrice')}:
          </p>
          <hr className='bg-darkGray ' />
          <p className='font-bold text-[19px] font-avenir55 text-center self-center rounded-md text-darkGray'>
            CHF {utils.numberWithComma(propertyDetails?.buy_now_price)}
          </p>
        </div>
      </div>
      <hr className='border border-gray-50 my-3 ' />
      <div className='flex justify-end '>
        <button
          className='bg-white rounded-md p-4 mt-5'
          onClick={() => setForm((form: any) => form + 1)}
        >
          <svg
            width='16'
            height='14'
            viewBox='0 0 16 14'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M9.97115 0.278417L15.7347 6.32784C16.0884 6.69906 16.0884 7.30094 15.7347 7.67216L9.97115 13.7216C9.61747 14.0928 9.04404 14.0928 8.69035 13.7216C8.33667 13.3504 8.33667 12.7485 8.69035 12.3773L12.9079 7.95058H0V6.04942H12.9079L8.69035 1.62273C8.33667 1.25151 8.33667 0.64964 8.69035 0.278417C9.04404 -0.0928058 9.61747 -0.0928058 9.97115 0.278417Z'
              fill='black'
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

const TermsAndConditionsBuyNow = (props: any) => {
  const { handleBuyNowRequest } = props;
  const { buttonLoader } = props;
  const { t } = useTranslation();
  return (
    <div className='rounded-md h-full'>
      <div className='text-darkGray font-press-start rounded-lg bg-gray-50 h-full relative'>
        <h1 className='text-2xl text-center pt-4 mx-3'>
          {t('propertyDetails.termsAndConditions')}
        </h1>
        <hr className='py-2 mx-2' />
        <div className=''>
          <p>{t('propertyDetails.buyNowTextTermsCond')}</p>
        </div>
        <div className='space-y-3 mt-5'>
          <div className='text-left mx-3 font-semibold'>
            <a href='/terms/conditions' target='_blank' className='text-auctionicRed'>
              {t('propertyDetails.termsAndConditions')}
            </a>
          </div>
          <div className='text-left mx-3 text-auctionicRed font-semibold'>
            <a href='/privacy/policy' target='_blank' className='text-auctionicRed'>
              {t('propertyDetails.privacyPolicy')}
            </a>
          </div>
        </div>
        <div className='m-auto absolute bottom-3 text-xs  w-full px-4 text-left '>
          <p>{t('propertyDetails.continueTextTerms')}.</p>
        </div>
      </div>
      <hr className='border border-gray-50 my-3 ' />
      <div className='flex justify-end bg-transparent'>
        <button
          className='bg-white rounded-lg px-4 py-4 bidNextButton'
          onClick={()=>{handleBuyNowRequest();}}
        ><span className='bidNextIcon'>
            {buttonLoader ? <SpinnerLoading/>:
              <svg
                width='16'
                height='14'
                viewBox='0 0 16 14'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M9.97115 0.278417L15.7347 6.32784C16.0884 6.69906 16.0884 7.30094 15.7347 7.67216L9.97115 13.7216C9.61747 14.0928 9.04404 14.0928 8.69035 13.7216C8.33667 13.3504 8.33667 12.7485 8.69035 12.3773L12.9079 7.95058H0V6.04942H12.9079L8.69035 1.62273C8.33667 1.25151 8.33667 0.64964 8.69035 0.278417C9.04404 -0.0928058 9.61747 -0.0928058 9.97115 0.278417Z'
                  fill='black'
                />
              </svg>}
          </span>
          <span className='bidNextText text-darkGray font-bold'>
            {t('propertyDetails.next')}
          </span>
        </button>
      </div>
    </div>
  );
};

const ConfirmPhoneCode = (props: any) => {
  const { setInputData, confirmBuyNowRequest, buttonLoader } = props;
  const [otpCode, setOtpCode] = useState('');
  const handleCodeChange = (e: any) => {
    setOtpCode(e);
    setInputData((inputData: any) => ({ ...inputData, code: e }));
  };
  const { t } = useTranslation();
  const resendCode = () => {
    userServices
      .resendVerification()
      .then((response) => {
        // console.log(response);
      })
      .catch((error: any) => {
        // console.log(error);
      });
  };
  return (
    <div className='rounded-md space-y-3 py-2 h-full'>
      <div className='mx-2 text-darkGray font-press-start rounded-lg'>
        <div className='rounded-lg py-3 '>
          <div className='border-b border-gray-400 text-white'>
            <p className='text-2xl text-center '>{t('propertyDetails.buyNowConfirmPhoneCode')}</p>
          </div>
          <div className='text-[14px] mt-4 text-left text-white'>
            {t('propertyDetails.verificationSent')}
            <br />
            {t('propertyDetails.buyNowCode')}
          </div>
        </div>
        <div className='flex flex-row justify-start text-center text-white mt-5'>
          <OtpInput
            value={otpCode}
            onChange={handleCodeChange}
            isInputNum={true}
            numInputs={6}
            inputStyle={{
              width: '2.5rem',
              height: '2.5rem',
              margin: '0 0.7rem',
              fontSize: '2rem',
              borderRadius: 4,
              border: '1px solid rgba(0,0,0,0.3)',
              color: 'black',
            }}
            shouldAutoFocus={true}
          />
        </div>
      </div>
      <div className='flex justify-start mt-2'>
        <button
          className='text-white font-light flex space-x-3'
          onClick={resendCode}
        >
          <span>{t('propertyDetails.resendVerification')}</span>
          <svg
            viewBox='0 0 512 512'
            xmlns='http://www.w3.org/2000/svg'
            className='h-4 w-4 self-center '
            fill='#FFFFFF'
          >
            <path d='M480 256c0 123.4-100.5 223.9-223.9 223.9c-48.86 0-95.19-15.58-134.2-44.86c-14.14-10.59-17-30.66-6.391-44.81c10.61-14.09 30.69-16.97 44.8-6.375c27.84 20.91 61 31.94 95.89 31.94C344.3 415.8 416 344.1 416 256s-71.67-159.8-159.8-159.8C205.9 96.22 158.6 120.3 128.6 160H192c17.67 0 32 14.31 32 32S209.7 224 192 224H48c-17.67 0-32-14.31-32-32V48c0-17.69 14.33-32 32-32s32 14.31 32 32v70.23C122.1 64.58 186.1 32.11 256.1 32.11C379.5 32.11 480 132.6 480 256z' />
          </svg>
        </button>
      </div>
      <hr className='border border-white my-3 ' />
      <div className='flex justify-start'>
        <button
          className='bg-white rounded-lg px-3 py-3 text-darkGray flex'
          onClick={confirmBuyNowRequest}
        >{t('propertyDetails.continueButton')}
          {buttonLoader ? <SpinnerLoading/>:
            <svg
              className='self-center ml-3'
              width='16'
              height='14'
              viewBox='0 0 16 14'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M9.97115 0.278417L15.7347 6.32784C16.0884 6.69906 16.0884 7.30094 15.7347 7.67216L9.97115 13.7216C9.61747 14.0928 9.04404 14.0928 8.69035 13.7216C8.33667 13.3504 8.33667 12.7485 8.69035 12.3773L12.9079 7.95058H0V6.04942H12.9079L8.69035 1.62273C8.33667 1.25151 8.33667 0.64964 8.69035 0.278417C9.04404 -0.0928058 9.61747 -0.0928058 9.97115 0.278417Z'
                fill='black'
              />
            </svg>}
        </button>
      </div>
    </div>
  );
};

const Error = (props: any) => {
  const { reloadPage } = props;
  return (
    <div className='space-y-3 bg-white text-darkGray font-avenir55 rounded-lg py-2 h-full relative'>
      <div className='text-darkGray font-press-start rounded-lg h-full'>
        <div className='self-center'>
          <div className='flex justify-center'>
            <img src={Logo} alt='Logo' className='my-10' />
          </div>
          <div className='block space-y-7 mt-10'>
            <p className='text-[20px]'>{t('propertyDetails.buyNowError')}</p>
            <p className='text-[14px]'>{t('propertyDetails.buyNowTryAgain')}</p>
          </div>
          <div className='absolute bottom-5 left-2 right-2'>
            <button
              className='px-12 py-2  bg-darkGray text-white text-[14px] rounded-md'
              onClick={reloadPage}
            >
              {t('propertyDetails.buyNowClose')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Success = (props: any) => {
  const { reloadPage } = props;
  const { t } = useTranslation();
  return (
    <div className='bg-white text-darkGray font-avenir55 rounded-lg py-2 h-full relative'>
      <div className='self-center'>
        <div className='flex justify-center'>
          <img src={Logo} alt='Logo' className='my-10' />
        </div>
        <div className='block space-y-7'>
          <p className='text-[20px]'>{t('propertyDetails.buyNowOfferSent')}</p>
          <p className='text-[14px]'>
            {t('propertyDetails.buyNowSuccessOffer')}
          </p>
          <p className='text-[14px]'>
            {t('propertyDetails.buyNowYouSeeProperty')}
            <a href='/dashboard/4' className='text-blue-300'>
              {t('propertyDetails.buyNowAgreements')}
            </a>
          </p>
        </div>
        <div className='absolute bottom-5 left-2 right-2 '>
          <button
            className='px-12 py-2  bg-darkGray text-white text-[14px] rounded-md'
            onClick={reloadPage}
          >
            {t('propertyDetails.buyNowClose')}
          </button>
        </div>
      </div>
    </div>
  );
};

const BuyNowDialog = (props: BuyNowDialogProps) => {
  const {
    handleOpenBuyNow,
    propertyDetails,
    setForm,
    form,
    setInputData,
    handleBuyNowRequest,
    confirmBuyNowRequest,
    reloadPage,
    buttonLoader,
  } = props;

  return (
    <div className='relative z-10'>
      <div className='fixed inset-0 bg-black bg-opacity-95 transition-opacity'></div>
      <ToastContainer autoClose={4000} />
      <div
        className='fixed z-10 inset-0 overflow-y-auto transition delay-300 duration-300 ease-in-out'
        onClick={reloadPage}
      >
        <div className='block mx-3'>
          <div className='text-left pt-5 sm:mx-[208px] text-white block sm:hidden mx-2 font-bold'>
            <p className='text-[24px]'>
              {utilsProperty.generateTitle(propertyDetails,t)}
              <sup className='text-lg'>2</sup>
            </p>
            <p className='text-[18px]'>{propertyDetails?.property.title}</p>
            <p className='sm:pt-4 text-[24px] mr-24'>
              <span className='border-b-2 border-red-600'>
                CHF {utils.numberWithComma(propertyDetails?.current_bid)}.
              </span>
            </p>
          </div>
          <div className='flex justify-center text-center sm:mx-[10%] sm:mt-[4%]'>
            <div
              className='grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-3  text-white my-2 mx-2 sm:mx-0 sm:my-0 sm:gap-10 rounded-md'
              onClick={(e) => e.stopPropagation()}
            >
              <div className='col-span-2'>
                <div className=''>
                  <Swiper
                    modules={[Autoplay, Navigation, Keyboard]}
                    navigation={true}
                    keyboard={true}
                    className='rounded-md'
                  >
                    {propertyDetails.images.map((images: any, index: any) => {
                      return (
                        <SwiperSlide key={index} className='rounded-md m-auto'>
                          <img className='rounded-md' src={images.url} alt='' />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              </div>
              <div className='mt-3 sm:mt-0 w-full col-span-1'>
                <div className='flex justify-end cursor-pointer' onClick={handleOpenBuyNow}>
                  <img src={close} alt=''/>
                </div>
                <div className='rounded-md h-full'>
                  {form === 0 ? (
                    <BuyNowPrice
                      setForm={setForm}
                      handleOpenBuyNow={handleOpenBuyNow}
                      propertyDetails={propertyDetails}
                      handleBuyNowRequest={handleBuyNowRequest}
                      setInputData={setInputData}
                    />
                  ) : form === 1 ? (
                    <TermsAndConditionsBuyNow
                      setForm={setForm}
                      handleBuyNowRequest={handleBuyNowRequest}
                      buttonLoader={buttonLoader}
                    />
                  ) : form === 100 ? (
                    <Error reloadPage={reloadPage} />
                  ) : form === 2 ? (
                    <ConfirmPhoneCode
                      setForm={setForm}
                      setInputData={setInputData}
                      confirmBuyNowRequest={confirmBuyNowRequest}
                      buttonLoader={buttonLoader}
                    />
                  ) : (
                    <Success
                      setForm={setForm}
                      handleOpenBuyNow={handleOpenBuyNow}
                      reloadPage={reloadPage}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='text-left pt-5 sm:mx-[10%] text-white hidden sm:block'>
            <p className='text-[24px]'>
              {utilsProperty.generateTitle(propertyDetails,t)}
            </p>
            <p className='text-[18px]'>{propertyDetails?.property.title}</p>
            <p className='sm:pt-4 text-[24px] mr-24'>
              <span className='border-b-2 border-red-600'>
                CHF {utils.numberWithComma(propertyDetails?.current_bid)}.
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export { BuyNowDialog };
