import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const RegisterFailed = () => {
  const { t } = useTranslation();
  return (
    <div className='registerContainer'>
      <div className='flex items-center justify-center py-[64px] px-4 sm:px-6 lg:px-8 font-avenir55 '>
        <div className='max-w-3xl w-full bg-white text-red-600'>
          <p className='text-center pt-[70px] text-[32px] font-normal'>
            Registration failed!
          </p>
          <p className='text-[16px] text-center pt-[133px] px-5 sm:px-0 font-semibold text-red-600'>
            Your registration process failed, make sure your data are correct.
          </p>
          <p className='text-[14px] pt-[7px] text-center font-semibold text-red-500'>
            Try again
          </p>
          <div className='text-center pt-[171px] pb-8'>
            <Link
              to={'/'}
              className='bg-darkGray text-red-600 px-12 py-3 rounded-[4px]'
            >
              {t('register.continue')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export { RegisterFailed };
