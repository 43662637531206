import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigationStepper } from './NavigationStepper';

interface Props {
  handleNext: any;
  steps: any;
  user: any;
  input: any;
  setInput: any;
}

const UserInformation = (props: Props) => {
  const { handleNext, steps, user, input, setInput } = props;
  const [step] = useState(0);
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  useEffect(() => {
    if (
      input.birthday.match(
        '^([0-2][0-9]|(3)[0-1])(\\.)(((0)[0-9])|((1)[0-2]))(\\.)\\d{4}$'
      )
    ) {
      setError(false);
    } else {
      setError(true);
    }
  }, [input.birthday]);

  return (
    <div className='min-h-screen '>
      <div className=''>
        <NavigationStepper steps={steps} activeStep={step} />
        <div className='mt-6 mb-2'>
          <p className='font-bold text-[22px]'>
            {t('debtCollection.orderDebtCollection')}
          </p>
        </div>
        <div className='space-y-3'>
          <p>{t('debtCollection.userInformation.title')}</p>
          <p>{t('debtCollection.userInformation.checkInformation')}</p>
        </div>
        {/* Form starts here */}
        <form className='mt-6' onSubmit={handleNext}>
          <div className='space-y-4'>
            <div className='grid sm:grid-cols-2'>
              <div className=''>
                <label className='font-semibold'>
                  {t('debtCollection.userInformation.firstLastName')}
                </label>
                <input
                  name='name'
                  value={`${user?.first_name} ${user?.last_name}`}
                  type='text'
                  className='block my-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer required '
                  placeholder=''
                  //   required
                />
              </div>
              <div></div>
            </div>
            <div className='grid sm:grid-cols-2'>
              <div className=''>
                <label className='font-semibold'>
                  {t('debtCollection.userInformation.postcodeCity')}
                </label>
                <input
                  name='zip'
                  value={
                    user.address?.zip_code === null ||
                    user.address?.city === null
                      ? user.address?.formatted.split(',')[1]
                      : user.address?.zip_code + ' ' + user.address?.city
                  }
                  type='text'
                  className='block my-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer required '
                  placeholder=''
                  //   required
                />
              </div>
              <div></div>
            </div>
            <div className='grid sm:grid-cols-2'>
              <div className=''>
                <label className='font-semibold'>
                  {t('debtCollection.userInformation.streetAndNumber')}
                </label>
                <input
                  name='street'
                  type='text'
                  value={
                    user.address.street === null ||
                    user.address.street_no === null
                      ? user.address.formatted.split(',')[0]
                      : user.address.street + ' ' + user.address.street_no
                  }
                  className='block my-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer required '
                  placeholder=''
                  //   required
                />
              </div>
              <div></div>
            </div>
            <div className='grid sm:grid-cols-2'>
              <div className='grid grid-cols-2'>
                <div>
                  <label className='font-semibold'>
                    {t('debtCollection.userInformation.telephoneNumber')}
                  </label>
                  <input
                    name='phone'
                    type='text'
                    value={user?.phone}
                    className='block my-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer required '
                    placeholder=''
                    // required
                  />
                </div>
                <div></div>
              </div>
              <div></div>
            </div>
            <div className='grid sm:grid-cols-2'>
              <div className=''>
                <label className='font-semibold'>
                  {t('debtCollection.userInformation.emailAddress')}
                </label>
                <input
                  name='email'
                  value={user?.email}
                  type='text'
                  className='block my-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer required '
                  placeholder=''
                  // required
                />
              </div>
              <div></div>
            </div>
            <div className='grid sm:grid-cols-2'>
              <div className='grid grid-cols-2'>
                <div>
                  <label className='font-semibold'>
                    *{t('debtCollection.userInformation.dateOfBirth')}
                  </label>
                  <input
                    name='birthday'
                    onChange={(e) => {
                      setInput({
                        ...input,
                        birthday: e.target.value,
                      });
                    }}
                    type='text'
                    className='block my-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer required '
                    placeholder=''
                    required
                  />
                  {error && (
                    <p className='text-sm text-auctionicRed italic'>
                      {t('debtCollection.userInformation.invalidDateFormat')}
                    </p>
                  )}
                </div>
                <div></div>
              </div>
              <div></div>
            </div>
          </div>
          <hr className='mt-6' />
          <div className='flex justify-end mt-5'>
            <button
              type='submit'
              disabled={error || input.birthday.length == 0}
              className='px-4 py-2 bg-auctionicRed disabled:bg-gray-400 text-white font-bold rounded-md cursor-pointer hover:bg-darkGray transition-all ease-in-out duration-100'
            >
              {t('debtCollection.next')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export { UserInformation };
