import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    handlePaymentDataChange: any;
    error: any;
    paymentTypeData: any;
    setPaymentTypeData: any;
}

const FixedPrice = (props: Props) => {
  const { handlePaymentDataChange, error, paymentTypeData } = props;
  const { t } = useTranslation();
  const [formattedFixedPrice, setFormattedFixedPrice] = useState('');
  const [tempPaymentData, setTempPaymentData] = useState(paymentTypeData);

  const handlePaymentDataChange1 = (e: any) => {
    const { name, value } = e.target;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // if (error.includes(name)) {
    //   setError(error.filter((e) => e !== name));
    // }
    if (name == 'price') {
      const formattedValue = (Number(value.replace(/\D/g, '')) || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g,'\'');
      const unformattedValue = (Number(value.replace(/\D/g, '')) || '');
      props.setPaymentTypeData((prevState: any) => ({
        ...prevState,
        price: unformattedValue,
      }));
      setFormattedFixedPrice(formattedValue);
    }
  };
  useEffect(() => {

    if (paymentTypeData.type ==='sale' && paymentTypeData.price != null)  {
      const value = paymentTypeData.price.toString();
      const formattedValue = (Number(value.replace(/\D/g, '')) || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\'');
      setFormattedFixedPrice(formattedValue);
      setTempPaymentData(paymentTypeData);
    }
  }, []);
  useEffect(()=>{
    props.setPaymentTypeData(tempPaymentData);
  },[tempPaymentData]);
  return (
    <div className='sm:py-12 py-2 text-3xl font-press-start space-y-5'>
      <div className='sm:grid sm:grid-cols-1'>
        <label className='w-full self-center text-base font-bold' title={t('reqField.requiredField')}>
          {t('listingItem.startDate')}*
        </label>
        <input
          name='start_date'
          onChange={handlePaymentDataChange}
          type='datetime-local'
          min={new Date().toISOString().slice(0, -8)}
          onKeyDown={(e) => e.preventDefault()}
          className={`form-control  block sm:w-1/2 px-3 py-1.5 mt-3 mb-1 text-base font-normal text-gray-700 bg-white bg-clip-padding ${error?.includes('start_date') ? 'border-2 border-solid border-auctionicRed' : 'border-2 border-solid border-gray-300' } focus:border-gray-800 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none w-full`}
          placeholder={t('listingItem.startDate')}
          value={paymentTypeData?.start_date != undefined ? paymentTypeData.start_date : '' }
        />
        {error?.includes('start_date') ? (
          <p className='text-xs text-red-500 py-1'>
            {t('listingItem.paymentForm.fixedPrice.startingDateRequired')}
          </p>
        ) : (
          ''
        )}
      </div>
      <div className='sm:grid sm:grid-cols-1'>
        <label className='w-full self-center text-base font-bold' title={t('reqField.requiredField')}>
          {t('listingItem.price')}*
        </label>
        <input
          name='price'
          value={formattedFixedPrice}
          onChange={handlePaymentDataChange1}
          type='text'
          className={`form-control  block sm:w-1/2 w-full px-3 py-1.5 mb-1 mt-3 text-base font-normal text-gray-700 bg-white bg-clip-padding ${error?.includes('price') || error?.includes('price_max') ? 'border-2 border-solid border-auctionicRed' : 'border-2 border-solid border-gray-300' } focus:border-gray-800 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none`}
          placeholder={`${t('listingItem.price')} (CHF)`}
          min={1}
        />
        {error?.includes('price') ? (
          <p className='text-xs text-red-500 py-1'>
            {t('listingItem.paymentForm.fixedPrice.priceRequired')}
          </p>
        ) : (
          error?.includes('price_max') && (
            <p className='text-xs text-red-500'>
              {t('listingItem.paymentForm.auction.priceOverMax')}
            </p>
          )
        )}
      </div>
    </div>
  );
};

export { FixedPrice };
