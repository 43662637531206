import React, { useEffect, useState } from 'react';
import Image from '../../images/logo.svg';
import Menu from '../../images/menuWhite.svg';
import Menu1 from '../../images/menu_2.svg';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { userServices } from '../../services/userServices';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const isLogged = localStorage.getItem('access_token');
  const [openSideBar, setOpenSideBar] = useState(false);
  const handleSideBar = () => {
    setOpenSideBar(!openSideBar);
  };
  const changeLanguage = (ln: string) => {
    localStorage.setItem('locale', ln);
    i18n.changeLanguage(ln);
  };
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleMyAuctionic = () => {
    setOpenSideBar(!openSideBar);
    navigate('/dashboard/1');
  };

  const handleRegister = () => {
    setOpenSideBar(!openSideBar);
    navigate('/register');
  };

  const handleLogout = () => {
    setOpenSideBar(!openSideBar);
    userServices
      .userLogout()
      .then(() => {
        localStorage.clear();
        navigate('/login');
      })
      .catch((error: any) => {
        console.error('Logout error', error);
      });
  };

  const [openService, setOpenService] = useState(false);
  const handleOpenService = () => {
    setOpenService(!openService);
  };

  const [theUser, setTheUser] = useState({
    first_name: '',
    name:'',
    type:''
  });
  useEffect(() => {
    if (localStorage.getItem('access_token') != null) {
      userServices
        .userAuth()
        .then((response) => {
          setTheUser(response.data);
          return response;
        })
        .catch((error: any) => {
          console.error('User could not found: ', error);
        });
    }
  }, []);

  return (
    <nav className='flex flex-wrap items-center justify-around pt-3 pb-3 bg-darkGray w-full font-press-start relative'>
      <button onClick={() => navigate('/')}>
        <img src={Image} alt='' />
      </button>
      <div className='flex md:hidden h-9'>
        <button id='hamburger'>
          <img
            className='toggle hidden'
            src='https://img.icons8.com/fluent-systems-regular/2x/close-window.png'
            width='45'
            height='45'
          />
        </button>
      </div>
      <div className='toggle hidden w-[100%] md:w-auto md:flex  text-bold mt-5 md:mt-0 pr-14'>
        <button
          onClick={() => navigate('/listing', { state: { type: 'auction' } })}
          className='block md:inline-block text-white hover:text-auctionicRed px-4 py-3 transition-all ease-in-out duration-150'
        >
          {t('navBar.auction_listed')}
        </button>
        <button
          onClick={() => navigate('/listing', { state: { type: 'sale' } })}
          className='block md:inline-block text-white hover:text-auctionicRed px-4 py-3 transition-all ease-in-out duration-150'
        >
          {t('navBar.sale_listed')}
        </button>
        <button
          onClick={() => navigate('/listing', { state: { type: 'rent' } })}
          className='block md:inline-block text-white hover:text-auctionicRed px-4 py-3 transition-all ease-in-out duration-150'
        >
          {t('navBar.rent_listed')}
        </button>
        <button
          onClick={() =>
            navigate('/listingItem', { state: { type: 'auction' } })
          }
          className='block md:inline-block text-white hover:text-auctionicRed border rounded-md hover:border-auctionicRed px-4 py-3 transition-all ease-in-out duration-150'
        >
          {t('navBar.advertise')}
        </button>
        <button
          onClick={() =>
            navigate('/services', { state: { type: 'realEstateEvaluation' } })
          }
          className='block md:inline-block text-white hover:text-auctionicRed px-4 py-3 transition-all ease-in-out duration-150'
        >
          {t('navBar.evaluate')}
        </button>
      </div>
      <div>
        {!openSideBar && (
          <button className='mt-1' onClick={handleSideBar}>
            <span className='flex space-x-2'>
              <img src={Menu} alt='' />
              <p className='text-white text-base hidden sm:flex font-semibold'>
                Menu
              </p>
            </span>
          </button>
        )}
      </div>
      <div
        onClick={handleSideBar}
        className={`fixed inset-0 bg-black bg-opacity-75 transition-opacity z-20 ${
          openSideBar ? 'translate-x-0' : '-translate-x-full'
        }`}
      ></div>
      <div
        className={`top-0 right-0 nav-bar bg-white text-white fixed h-full transform ease-in-out duration-300 z-40 ${
          openSideBar ? 'translate-x-0 ' : 'translate-x-full'
        }`}
      >
        <div className='bg-white absolute w-full h-[20px] p-8 z-20'>
          <button
            onClick={handleSideBar}
            className='flex justify-center bg-white left-0 right-0 top-7 mb-2  w-full'
          >
            <img src={Menu1} alt='' />
          </button>
        </div>

        <div className='overflow-y-auto px-10 absolute top-5 bottom-7 right-0 left-0'>
          <div className=''>
            <div className=''>
              <div className='text-left pb-6'>
                <ul className='block space-y-4 overflow-y-hidden'>
                  <li>
                    {isLogged ? (
                      <div className='flex justify-center mt-16 mb-5'>
                        <div className='bg-gray-100 py-2 px-5 text-darkGray text-lg rounded-lg grid grid-cols-1'>
                          <div className='flex mb-2 items-center'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth='1.5'
                              stroke='currentColor'
                              className='w-6 h-6 mr-1'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z'
                              />
                            </svg>
                            <p>
                              {t('navBar.welcome')}
                              {theUser?.type === 'individual'
                                ? `, ${theUser?.first_name}`
                                : theUser?.type === 'company'
                                  ?`, ${theUser?.name}`
                                  : ''}
                            </p>
                          </div>
                          <div className=''>
                            <Link
                              to={''}
                              className='flex items-center justify-start text-darkGray hover:bg-white hover:text-auctionicRed rounded-lg py-2 pr-2 text-lg'
                              onClick={handleLogout}
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                                strokeWidth='1.5'
                                stroke='currentColor'
                                className='w-6 h-6 mr-1'
                              >
                                <path
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  d='M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9'
                                />
                              </svg>
                              {t('navBar.logoutButton')}
                            </Link>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className='flex justify-center mt-16 mb-3'>
                        <div className='bg-gray-100 py-2 px-10 m-2 text-darkGray text-lg rounded-lg grid grid-cols-1'>
                          <Link
                            to={'/login'}
                            className='flex text-darkGray py-1 pt-1 text-lg hover:text-auctionicRed'
                            onClick={handleSideBar}
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth='1.9'
                              stroke='currentColor'
                              className='w-7 h-7'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75'
                              />
                            </svg>
                            {t('navBar.buttonLogin')}
                          </Link>
                        </div>
                      </div>
                    )}
                  </li>
                  <li className='font-medium text-darkGray pt-4 text-xl'>
                    <Link
                      to={'/listingItem'}
                      state={{ type: 'auction', showRadio: false }}
                      className='hover:border-b-2 border-b-red-600 px-2'
                      onClick={handleSideBar}
                    >
                      {t('navBar.auction')}
                    </Link>
                  </li>
                  <li className='font-medium text-darkGray pt-4 text-xl'>
                    <Link
                      to={'/listingItem'}
                      state={{ type: 'sale', showRadio: false }}
                      className='hover:border-b-2 border-b-red-600 px-2'
                      onClick={handleSideBar}
                    >
                      {t('navBar.sale')}
                    </Link>
                  </li>
                  <li className='font-medium text-darkGray pt-4 text-xl'>
                    <Link
                      to={'/listingItem'}
                      state={{ type: 'rent', showRadio: false }}
                      className='hover:border-b-2 border-b-red-600 px-2'
                      onClick={handleSideBar}
                    >
                      {t('navBar.rent')}
                    </Link>
                  </li>
                  <li className='font-medium text-darkGray pt-4 text-xl'>
                    <Link
                      to={'/listingItem'}
                      state={{ type: 'auction', showRadio: true }}
                      className='hover:border-b-2 border-b-red-600 px-2'
                      onClick={handleSideBar}
                    >
                      {t('navBar.advertise')}
                    </Link>
                  </li>
                  <li className='font-medium text-darkGray pt-4 text-xl'>
                    <Link
                      to={'/mortgage'}
                      className='hover:border-b-2 border-b-red-600 px-2'
                      onClick={handleSideBar}
                    >
                      {t('navBar.mortgages')}
                    </Link>
                  </li>
                  {isLogged ? (
                    <li className='font-medium text-darkGray pt-4 text-xl '>
                      <button
                        onClick={handleMyAuctionic}
                        className='px-9 py-2 rounded-md text-white  bg-red-600 uppercase'
                      >
                        {t('navBar.myAuctionic')}
                      </button>
                    </li>
                  ) : (
                    <li className='font-medium text-darkGray pt-4 text-xl '>
                      <button
                        onClick={handleRegister}
                        className='px-9 py-2 rounded-md text-white  bg-red-600 uppercase'
                      >
                        {t('navBar.register')}
                      </button>
                    </li>
                  )}
                  <li className='font-medium text-darkGray pt-4 text-xl'>
                    <Link
                      to={'/contact'}
                      className='hover:border-b-2 border-b-red-600 px-2'
                      onClick={handleSideBar}
                    >
                      {t('navBar.contact')}
                    </Link>
                  </li>
                  <li className='font-medium text-darkGray pt-4 text-xl'>
                    <Link
                      to={'/how_it_works'}
                      className='hover:border-b-2 border-b-red-600 px-2'
                      onClick={handleSideBar}
                    >
                      {t('navBar.howitworks')}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='font-medium text-darkGray py-4 text-xl flex items-center'>
              <Link
                className='border-b-2 border-b-transparent hover:border-b-2 hover:border-b-auctionicRed px-2'
                to={'/services'}
                state={{ type: '' }}
                onClick={handleSideBar}
              >
                {t('navBar.service')}
              </Link>
              <a
                className={`${
                  openService
                    ? 'bg-auctionicRed p-0.5 rounded-full text-white transiton-all ease-in-out duration-200 self-center'
                    : ''
                }`}
                onClick={handleOpenService}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='2'
                  stroke='currentColor'
                  className='w-6 h-6'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M19.5 8.25l-7.5 7.5-7.5-7.5'
                  />
                </svg>
              </a>
            </div>
            {openService ? (
              <div className='list-none text-left px-4 pb-10 pt-3 '>
                <li className='font-semibold text-darkGray pt-4 text-md'>
                  <Link to={'/services'}
                    state={{ type: 'financeAndInsure' }}
                    onClick={handleSideBar}
                    className='hover:border-b-2 border-b-red-600'>
                    {t('navBar.service1')}
                  </Link>
                </li>
                <li className='font-semibold  text-darkGray pt-4 text-md'>
                  <Link to={'/services'}
                    onClick={handleSideBar}
                    state={{ type: 'rentalDeposit' }} className='hover:border-b-2 border-b-red-600'>
                    {t('navBar.service2')}
                  </Link>
                </li>
                <li className='font-semibold  text-darkGray pt-4 text-md'>
                  <Link to={'/services'}
                    onClick={handleSideBar}
                    state={{ type: 'realEstateEvaluation' }} className='hover:border-b-2 border-b-red-600'>
                    {t('navBar.service10')}
                  </Link>
                </li>
                <li className='font-semibold  text-darkGray pt-4 text-md'>
                  {localStorage.getItem('user_id') ?
                    <Link
                      to={'/services'}
                      state={{ type: 'debtCollection' }}
                      className='hover:border-b-2 border-b-red-600 '
                    >
                      {t('navBar.service3')}
                    </Link>
                    :
                    <Link
                      to={'/login/redirect'}
                      // state={{ type: 'debtCollection' }}
                      className='hover:border-b-2 border-b-red-600 '
                    >
                      {t('navBar.service3')}
                    </Link>
                  }
                </li>
                <li className='font-semibold text-darkGray pt-4 text-md'>
                  <Link to='/services'
                    onClick={handleSideBar}
                    state={{ type: 'legalConsultation' }}
                    className='hover:border-b-2 border-b-red-600'>
                    {t('navBar.service4')}
                  </Link>
                </li>
                <li className='font-semibold  text-darkGray pt-4 text-md'>
                  <Link
                    onClick={handleSideBar}
                    to={'/services'}
                    state={{ type: 'relocationAssistance' }}
                    className='hover:border-b-2 border-b-red-600'>
                    {t('navBar.service5')}
                  </Link>
                </li>
                <li className='font-semibold text-darkGray pt-4 text-md'>
                  <Link
                    onClick={handleSideBar}
                    to={'/services'}
                    state={{ type: 'propertyManagement' }}
                    className='hover:border-b-2 border-b-red-600'>
                    {t('navBar.service6')}
                  </Link>
                </li>
                <li className='font-semibold text-darkGray pt-4 text-md'>
                  <Link
                    onClick={handleSideBar}
                    to={'/services'}
                    state={{ type: 'realEstateManagement' }}
                    className='hover:border-b-2 border-b-red-600'>
                    {t('navBar.service7')}
                  </Link>
                </li>
                <li className='font-semibold text-darkGray pt-4 text-md'>
                  <Link
                    onClick={handleSideBar}
                    to={'/services'}
                    state={{ type: 'cleaningAndHygieneServices' }}
                    className='hover:border-b-2 border-b-red-600'>
                    {t('navBar.service8')}
                  </Link>
                </li>
                <li className='font-semibold text-darkGray pt-4 text-md'>
                  <Link
                    onClick={handleSideBar}
                    to={'/services'}
                    state={{ type: 'buildingAndRenovation' }}
                    className='hover:border-b-2 border-b-red-600'>
                    {t('navBar.service9')}
                  </Link>
                </li>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className='absolute bottom-0 text-darkGray right-0 left-0 py-1 px-5 bg-white shadow-sm shadow-black'>
          <div className='grid grid-cols-4 place-items-center'>
            <div className=''>
              <button
                className='disabled:text-red-600 disabled:border-b-2 disabled:border-red-500 p-1'
                disabled={i18n.language === 'de'}
                onClick={() => changeLanguage('de')}
              >
                Deutsch
              </button>
            </div>
            <div className=''>
              <button
                className='disabled:text-red-600 disabled:border-b-2 disabled:border-red-500 p-1'
                disabled={i18n.language === 'en'}
                onClick={() => changeLanguage('en')}
              >
                English
              </button>
            </div>
            <div className=''>
              <button
                className='disabled:text-red-600 disabled:border-b-2 disabled:border-red-500 p-1'
                disabled={i18n.language === 'fr'}
                onClick={() => changeLanguage('fr')}
              >
                Français
              </button>
            </div>
            <div className=''>
              <button
                className='disabled:text-red-600 disabled:border-b-2 disabled:border-red-500 p-1'
                disabled={i18n.language === 'it'}
                onClick={() => changeLanguage('it')}
              >
                Italiano
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export { Navbar };
