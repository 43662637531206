import React from 'react';

interface Props {
  steps: Array<string>;
  activeStep: number;
}

const NavigationStepper = (props: Props) => {
  const { steps, activeStep } = props;
  return (
    <div className='grid grid-cols-4 gap-1 my-0.5'>
      {steps.map((step, index) => {
        return (
          <div
            key={step}
            className={`py-0.5 ${activeStep === index ? 'bg-auctionicRed' : 'border-[0.5px] border-darkGray'}`}
          />
        );
      })}
    </div>
  );
};

export { NavigationStepper };
