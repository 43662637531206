import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../context/AuthContext';


interface Props  {
  userMenu:any;
  handleOpenUserMenu:any;
}

const WelcomeUser = (props:Props) => {
  const { userMenu, handleOpenUserMenu }= props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user, logout } = useAuth();

  return (
    <div className='absolute right-2 sm:right-14 md:right-5 lg:right-[12.3rem] mt-[18px] sm:mt-6'>
      <div
        className='bg-gray-100 bg-opacity-75 py-1 sm:py-2 px-5 text-darkGray text-lg rounded-lg flex space-x-2 cursor-pointer items-center'
        onClick={handleOpenUserMenu}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth='1.5'
          stroke='red'
          className='w-6 h-6'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z'
          />
        </svg>
        <p className='hidden md:flex text-auctionicRed font-semibold'>
          {t('navBar.welcome')}
          {user?.type === 'individual' ? `, ${user?.first_name}` : user?.type === 'company' ? `, ${user?.name}` : ''}
        </p>
      </div>
      {userMenu ? (
        <div className='mt-2 p-2 bg-gray-100 border-b border-gray-100 rounded-md absolute right-0 z-10 w-56'>
          <ul className='space-y-3'>
            <li className='mb-2 flex justify-center p-2 hover:bg-white rounded-lg text-left'>
              <button
                className='flex space-x-2 hover:text-auctionicRed transition-all ease-linear duration-100 text-lg text-darkGray'
                onClick={() => navigate('/dashboard/1')}
              >
                {t('navBar.myAuctionic')}
              </button>
            </li>
            <li className='flex justify-center p-2 hover:bg-white rounded-lg text-left'>
              <button
                className='flex space-x-2 hover:text-auctionicRed transition-all ease-linear duration-100 text-lg text-darkGray'
                onClick={() => logout()}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='1.5'
                  stroke='currentColor'
                  className='w-6 h-6'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9'
                  />
                </svg>
                <p className='text-lg'>{t('login.logoutButton')}</p>
              </button>
            </li>
          </ul>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export { WelcomeUser };
