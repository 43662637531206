import React, { useEffect, useState } from 'react';
import './ListingItem.css';
import 'swiper/css';
import 'swiper/css/navigation';
import { SubscriptionPlans } from './SubscriptionPlans';
import { Preview } from './Preview';
import { PropertyForm } from './PropertyForm';
import { PaymentForms } from './PaymentForms';
import { useTranslation } from 'react-i18next';
import { PropertyInput, PaymentTypeInput, AddressInput, AuctionPlans, SalePlans, RentPlans, CharTypes } from '../../config';
import { utils } from '../../helpers/utils';
import { SubscriptionSale } from './SubscriptionSale';
import { SubscriptionRent } from './SubscriptionRent';
import { ListingInfos } from './ListingInfos';
import { propertyServices } from '../../services/propertyServices';
import { PropertyConfirmationForm } from './PropertyConfirmationForm';
import useAuth from '../../context/AuthContext';

const steps = ['1', '2', '3', '4', '5'];
const ListingItem = () => {
  // window.scrollTo(0, 0);
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const [paymentTypeData, setPaymentTypeData] = useState<PaymentTypeInput>({
    // type: 'auction',
  } as PaymentTypeInput);
  const [advertisementInfo, setAdvertisementInfo] = useState({});
  
  const [propertyInput, setPropertyInput] = useState<PropertyInput>({
    bedroom: 0,
    bathroom: 0,
    kitchen: 0,
    garage: 0,
    living_room: 0,
    property_chars: [],
    size_rooms: '0',
    shower: 0,
    living_space: 0,
    plot_area: 0,
    available_to_enter:null,
    available_to_visit:null,
    title: '',
    address: {
      city: '',
      street: '',
      zip: '',
      canton: ''
    } as AddressInput,
  } as PropertyInput);
  const [images, setImages] = useState<Array<File>>([]);
  const [prevImages, setPrevImages] = useState<Array<File>>([]);
  const [checkedVals, setChecked] = useState([] as Array<number>);
  const [address, setAdress] = useState({} as AddressInput);
  const [documents, setDocuments] = useState<Array<File>>([]);
  const [prevDocuments, setPrevDocuments] = useState<Array<File>>([]);
  const [error, setError] = useState([]);
  const [video, setVideo] = useState<Array<File>>([]);
  const [prevVideo, setPrevVideo] = useState<Array<File>>([]);

  //  Hooks for loaders
  const [loader1, setLoader1] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [loader3, setLoader3] = useState(false);
  const [loader4, setLoader4] = useState(false);
  const [creatingProperty, setCreatingProperty] = useState(false);
  const [uploadingImages, setUploadingImages] = useState(false);
  const [uploadingDocuments, setUploadingDocuments] = useState(false);
  const [uploadingVideos, setUploadingVideos] = useState(false);
  const { user } = useAuth();

  const handleImageChange = (e: any) => {
    const { files } = e.target;
    const img = Array.from(files);
    const imagesArray = img.map((img: any) => {
      return URL.createObjectURL(img);
    });
    setPrevImages((previousImages: any) => previousImages.concat(imagesArray));
    setImages((previousImages: any) => previousImages.concat(img));
  };
  const handleCheckedChars = (e: any) => {
    const char_id = e.target.value;
    const newState = [...checkedVals];
    if (e.target.checked) {
      newState.push(parseInt(char_id));
    } else {
      if (newState.indexOf(parseInt(char_id)) != -1) {
        newState.splice(newState.indexOf(parseInt(char_id)),1);
      }
    }
    setChecked(newState);
    setPropertyInput((prevState: any) => ({
      ...prevState,
      property_chars: newState,
    }));
  };

  const handlePaymentTypeChange = (e: any) => {
    const { name, value } = e.target;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (error.includes(name)) {
      setError(error.filter((e) => e !== name));
    }
    setPaymentTypeData((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePropertyInputChange = (e: any) => {
    const { name, value } = e.target;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (error.includes(name)) {
      setError(error.filter((e) => e !== name));
    }
    if (name == 'property_types') {
      if (value == 8 || value == 11) {
        setPropertyInput((prevState: any) => ({
          ...prevState,
          living_space: 0,
        }));
      }
      setPropertyInput((prevState: any) => ({
        ...prevState,
        property_types: [parseInt(value)],
      }));
    } else if (name == 'number_of_floor') {
      setPropertyInput((prevState: any) => ({
        ...prevState,
        number_of_floor: [parseInt(value)],
      }));
    } else {
      setPropertyInput((prevState: any) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleAddressChange = (e: any) => {
    const { name, value } = e.target;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (error.includes(name)) {
      setError(error.filter((e) => e !== name));
    }
    setAdress((prevInputadress: any) => {
      return {
        ...prevInputadress,
        [name]: value,
      };
    });

    setPropertyInput((prevInputadress: any) => {
      return {
        ...prevInputadress,
        address: utils.formatAddress(address),
      };
    });
  };


  const handleClickNext = () => {
    window.scrollTo(0, 0);
    setStep(step + 1);
  };
  const handleClickPrev = () => {
    window.scrollTo(0, 0);
    setStep(step - 1);
  };

  const [silverPrice, setSilverPrice] = useState<any | null>(null);
  const handleShowPriceSilver = (e:any) => {
    setSilverPrice(e.target.value);
  };
  const [goldPrice, setGoldPrice] = useState<any | null>(null);
  const handleShowPriceGold = (e:any) => {
    setGoldPrice(e.target.value);
  };

  const [platinumPrice, setPlatinumPrice] = useState<any | null>(null);
  const handleShowPricePlatinum = (e:any) => {
    setPlatinumPrice(e.target.value);
  };
  const [showRadio, setShowRadio] = useState('');
  const [plans, setPlans] = useState<AuctionPlans>();
  const [salePlans, setSalePlans] = useState<SalePlans>();
  const [rentPlans, setRentPlans] = useState<RentPlans>();
  const [propertyChar, setPropertyChar] = useState<Array<CharTypes>>([]);

  useEffect(() => {
    propertyServices
      .getCharachteristics()
      .then((response) => {
        setPropertyChar(response.data.data);
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        // console.log('Error getting the properties', error);
      });
  }, []);
  const [invoice, setInvoice] = useState('');

  //  useEffect for Auction plans
  useEffect(() => {
    propertyServices
      .subscriptionAuction()
      .then((response) => {
        window.scrollTo(0, 0);
        setPlans(response.data);
      })
      .catch((error: any) => {
        // console.log('Failed to get the plans', error);
      });
  }, []);

  // useEffect for Sale plans
  useEffect(() => {
    propertyServices
      .subscriptionSale()
      .then((response) => {
        window.scrollTo(0, 0);
        setSalePlans(response.data);
      })
      .catch((error: any) => {
        // console.log('Failed to get the rent plan', error);
      });
  }, []);

  // useEffect for Rent plans
  useEffect(() => {
    propertyServices
      .subscriptionRent()
      .then((response) => {
        window.scrollTo(0, 0);
        setRentPlans(response.data);
      })
      .catch((error: any) => {
        // console.log('Failed to get the rent plan', error);
      });
  }, []);
  const handleChoice = (event: string) => {
    setShowRadio(event);
    switch (event) {
    case 'silver':
      if (paymentTypeData.type === 'auction') {
        setPaymentTypeData((prev: any) => ({ ...prev, duration: plans?.silver['60'].duration , payment: { method:'bill', plan_id: plans?.silver['60'].id, price: plans?.silver['60'].price, discount: 0, tier: 'silver' } }));
        setSilverPrice(plans?.silver['60'].price);
      } else if (paymentTypeData.type === 'sale') {
        setPaymentTypeData((prev: any) => ({ ...prev, duration: salePlans?.silver['30'].duration , payment: { method:'bill', plan_id: salePlans?.silver['30'].id, price: salePlans?.silver['30'].price, discount: 0, tier: 'silver' } }));
        setSilverPrice(salePlans?.silver['30'].price);
      } else if (paymentTypeData.type ==='rent') {
        setPaymentTypeData((prev: any) => ({ ...prev, duration: rentPlans?.silver['30'].duration , payment: { method:'bill', plan_id: rentPlans?.silver['30'].id, price: rentPlans?.silver['30'].price, discount: 0, tier: 'silver' } }));
        setSilverPrice(rentPlans?.silver['30'].price);
      }
      break;
    case 'gold':
      if (paymentTypeData.type === 'auction') {
        setPaymentTypeData((prev: any) => ({ ...prev, duration: plans?.gold['60'].duration , payment: { method:'bill', plan_id: plans?.gold['60'].id, price: plans?.gold['60'].price, discount: 0, tier: 'gold' } }));
        setGoldPrice(plans?.gold['60'].price);
      } else if (paymentTypeData.type === 'sale') {
        setPaymentTypeData((prev: any) => ({ ...prev, duration: salePlans?.gold['30'].duration , payment: { method:'bill', plan_id: salePlans?.gold['30'].id, price: salePlans?.gold['30'].price, discount: 0, tier: 'gold' } }));
        setGoldPrice(salePlans?.gold['30'].price);
      } else if (paymentTypeData.type ==='rent') {
        setPaymentTypeData((prev: any) => ({ ...prev, duration: rentPlans?.gold['30'].duration , payment: { method:'bill', plan_id: rentPlans?.gold['30'].id, price: rentPlans?.gold['30'].price, discount: 0, tier: 'gold' } }));
        setGoldPrice(rentPlans?.gold['30'].price);
      }
      break;
    case 'platinum':
      if (paymentTypeData.type === 'auction') {
        setPaymentTypeData((prev: any) => ({ ...prev, duration: plans?.platinum['60'].duration , payment: { method:'bill', plan_id: plans?.platinum['60'].id, price: plans?.platinum['60'].price, discount: 0, tier: 'platinum' } }));
        setPlatinumPrice(plans?.platinum['60'].price);
      } else if (paymentTypeData.type === 'sale') {
        setPaymentTypeData((prev: any) => ({ ...prev, duration: salePlans?.platinum['30'].duration , payment: { method:'bill', plan_id: salePlans?.platinum['30'].id, price: salePlans?.platinum['30'].price, discount: 0, tier: 'platinum' } }));
        setPlatinumPrice(salePlans?.gold['30'].price);
      } else if (paymentTypeData.type ==='rent') {
        setPaymentTypeData((prev: any) => ({ ...prev, duration: rentPlans?.platinum['30'].duration , payment: { method:'bill', plan_id: rentPlans?.platinum['30'].id, price: rentPlans?.platinum['30'].price, discount: 0, tier: 'platinum' } }));
        setPlatinumPrice(rentPlans?.platinum['30'].price);
      }
      break;
    }
  };
  return (
    <div className=''>
      {step === 0 ? (
        <PaymentForms
          steps={steps}
          handleClickNext={handleClickNext}
          t={t}
          handlePaymentMethodChange={handlePaymentTypeChange}
          setPaymentTypeData={setPaymentTypeData}
          paymentTypeData={paymentTypeData}
          error={error}
          setError={setError}
          setShowRadio={setShowRadio}
          user={user}
        />
      ) : step === 1 && paymentTypeData.type === 'auction' ? (
        <SubscriptionPlans
          setPaymentTypeData={setPaymentTypeData}
          paymentTypeData={paymentTypeData}
          handleClickNext={handleClickNext}
          handleClickPrev={handleClickPrev}
          steps={steps}
          t={t}
          silverPrice={silverPrice}
          goldPrice={goldPrice}
          platinumPrice={platinumPrice}
          handleShowPriceSilver={handleShowPriceSilver}
          handleShowPriceGold={handleShowPriceGold}
          handleShowPricePlatinum={handleShowPricePlatinum}
          showRadio={showRadio}
          plans={plans}
          handleChoice={handleChoice}
        />
      ) : step === 1 && paymentTypeData.type === 'sale' ? (
        <SubscriptionSale
          setPaymentTypeData={setPaymentTypeData}
          paymentTypeData={paymentTypeData}
          handleClickNext={handleClickNext}
          handleClickPrev={handleClickPrev}
          steps={steps}
          t={t}
          silverPrice={silverPrice}
          goldPrice={goldPrice}
          platinumPrice={platinumPrice}
          handleShowPriceSilver={handleShowPriceSilver}
          handleShowPriceGold={handleShowPriceGold}
          handleShowPricePlatinum={handleShowPricePlatinum}
          showRadio={showRadio}
          salePlans={salePlans}
          handleChoice={handleChoice}
        />
      ) : step === 1 && paymentTypeData.type === 'rent' ? (
        <SubscriptionRent
          setPaymentTypeData={setPaymentTypeData}
          paymentTypeData={paymentTypeData}
          handleClickNext={handleClickNext}
          handleClickPrev={handleClickPrev}
          steps={steps}
          t={t}
          silverPrice={silverPrice}
          goldPrice={goldPrice}
          platinumPrice={platinumPrice}
          handleShowPriceSilver={handleShowPriceSilver}
          handleShowPriceGold={handleShowPriceGold}
          handleShowPricePlatinum={handleShowPricePlatinum}
          showRadio={showRadio}
          rentPlans={rentPlans}
          handleChoice={handleChoice}
        />
      ) : step === 2 ? (
        <PropertyForm
          handleCheckedChars={handleCheckedChars}
          handleImageChange={handleImageChange}
          type={paymentTypeData.type}
          setPropertyInput={setPropertyInput}
          handlePropertyInputChange={handlePropertyInputChange}
          steps={steps}
          handleClickNext={handleClickNext}
          handleClickPrev={handleClickPrev}
          t={t}
          propertyInput={propertyInput}
          handleAddressChange={handleAddressChange}
          images={images}
          setImages={setImages}
          setPrevImages={setPrevImages}
          prevImages={prevImages}
          prevDocuments={prevDocuments}
          documents={documents}
          setPrevDocuments={setPrevDocuments}
          setDocuments={setDocuments}
          error={error}
          setError={setError}
          paymentTypeData={paymentTypeData}
          video={video}
          setVideo={setVideo}
          prevVideo={prevVideo}
          setPrevVideo={setPrevVideo}
          address={address}
          propertyChar={propertyChar}
        />
      ) : step === 3 ? (
        <ListingInfos
          propertyInput={propertyInput}
          paymentTypeData={paymentTypeData}
          handleClickNext={handleClickNext}
          handleClickPrev={handleClickPrev}
          steps={steps}
          t={t}
          prevImages={prevImages}
          showRadio={showRadio}
          handlePaymentDataChange={handlePaymentTypeChange}
          setPaymentTypeData={setPaymentTypeData}
        />
      ) : step === 4 ? (
        <Preview
          handleClickPrev={handleClickPrev}
          steps={steps}
          t={t}
          propertyInput={propertyInput}
          images={images}
          paymentTypeData={paymentTypeData}
          documents={documents}
          prevImages={prevImages}
          prevDocuments={prevDocuments}
          handleClickNext={handleClickNext}
          video={video}
          propertyChar={propertyChar}
          prevVideo={prevVideo}
          invoice={invoice}
          setInvoice={setInvoice}
          setLoader1={setLoader1}
          setLoader2={setLoader2}
          setLoader3={setLoader3}
          setLoader4={setLoader4}
          setCreatingProperty={setCreatingProperty}
          setUploadingImages={setUploadingImages}
          setUploadingDocuments={setUploadingDocuments}
          setUploadingVideos={setUploadingVideos}
          setError={setError}
          setAdvertisementInfo={setAdvertisementInfo}
        />
      ) : (
        <PropertyConfirmationForm
          invoice={invoice}
          loader1={loader1}
          loader2={loader2}
          loader3={loader3}
          loader4={loader4}
          creatingProperty={creatingProperty}
          uploadingImages={uploadingImages}
          uploadingDocuments={uploadingDocuments}
          uploadingVideos={uploadingVideos}
          error={error}
          advertisementInfo={advertisementInfo}
        />
      )}
    </div>
  );
};

export { ListingItem };
