import { t } from 'i18next';
import React from 'react';
import AuctionicPosteri from '../../images/AuctionicPOSTER2.png';


const HowItWorks = () => {
  window.scrollTo(0, 0);
  return (
    <div className='bg-white sm:px-10 md:px-24 px-2 sm:py-12 py-2 min-h-screen font-press-start'>
      <div className='grid grid-cols-1 gap-5'>
        <div className='flex justify-center  '>
          <div className='flex pb-5 justify-start'>
            <p className='text-4xl'>{t('navBar.howitworks')}</p>
          </div>
        </div>
        <div className='flex justify-center sm:mx-24 sm:h-[600px]'>
          <video controls={true} className='rounded-md ' poster={AuctionicPosteri} >
            {localStorage.getItem('locale') === 'de' ? (
              <source src='https://d1wk1w3htz6uk8.cloudfront.net/AUCTIONIC_German.mp4'/>
            ) : localStorage.getItem('locale') === 'en' ? (
              <source src='https://d1wk1w3htz6uk8.cloudfront.net/AUCTIONIC_English.mp4'/>
            ) : localStorage.getItem('locale') === 'fr' ? (
              <source src='https://d1wk1w3htz6uk8.cloudfront.net/AUCTIONIC_French.mp4'/>
            ) : localStorage.getItem('locale') === 'it' ? (
              <source src='https://d1wk1w3htz6uk8.cloudfront.net/AUCTIONIC_Italian.mp4'/>
            ) : (
              <source src='https://d1wk1w3htz6uk8.cloudfront.net/AUCTIONIC_German.mp4'/>
            )}
          </video>
        </div>
      </div>
    </div>
  );
};

export { HowItWorks };
