import React from 'react';

interface Props {
  steps: Array<string>;
  activeStep: number;
}

const Stepper = (props: Props) => {
  return (
    <div className='px-3 p-5 self-center sm:justify-between pt-16'>
      <div className='flex justify-center lg:justify-end lg:-space-x-8'>
        <div className='border border-darkGray hidden lg:flex ' />
        <div className='flex sm:flex md:flex lg:block xl:block lg:space-y-[130px] space-x-3 sm:space-x-8 lg:space-x-0 '>
          {props.steps.map((step, index) => (
            <div
              key={step}
              className={`rounded-md transition duration-500 ease-in-out flex items-center justify-center py-4 px-6  
              ${
            props.activeStep === index
              ? 'bg-darkGray text-white '
              : 'border border-darkGray text-darkGray bg-white '
            }`}
            >
              {`${step}`}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Stepper;
