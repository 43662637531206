import React from 'react';


const OverviewIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='14'
      fill='none'
      viewBox='0 0 24 14'
      className='self-center'
    >
      <path
        fill={'#353535'}
        d='M12.015 2c4.751 0 8.063 3.012 9.504 4.636C20.118 8.473 16.806 12 12.015 12c-4.42 0-7.93-3.536-9.478-5.407C4.03 4.946 7.354 2 12.015 2Zm0-2C4.446 0 0 6.551 0 6.551S4.835 14 12.015 14C19.748 14 24 6.551 24 6.551S19.709 0 12.015 0ZM12 7 8.64 4.829a4 4 0 1 0 1.327-1.275L12 7Z'
      />
    </svg>
  );
};
const ListingIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='21'
      height='21'
      fill='none'
      viewBox='0 0 21 21'
      className='self-center'
    >
      <path
        fill={ '#353535' }
        d='M11.007 5.714H18v.857h-6.993v-.857Zm0 4.286H18v.857h-6.993V10Zm0 4.286H18v.857h-6.993v-.857ZM8.187 4l.62.597-2.84 2.832L4 5.599l.61-.609 1.345 1.245L8.187 4Zm0 4.286.62.596-2.84 2.832L4 9.884l.61-.608 1.345 1.244 2.231-2.234Zm0 4.285.62.597L5.968 16 4 14.17l.61-.609 1.345 1.245 2.231-2.235Z'
      />
      <path
        fill={ '#353535'}
        fillRule='evenodd'
        d='M0 4a4 4 0 0 1 4-4h13a4 4 0 0 1 4 4v13a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4Zm4-3h13a3 3 0 0 1 3 3v13a3 3 0 0 1-3 3H4a3 3 0 0 1-3-3V4a3 3 0 0 1 3-3Z'
        clipRule='evenodd'
      />
    </svg>
  );
};
const FavPropertiesIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='21'
      height='21'
      fill='none'
      viewBox='0 0 30 30'
    >
      <circle cx='15' cy='15' r='14.5' stroke='#353535' />
      <path
        fill='#353535'
        d='M12.14 10c1.618 0 2.486 1.745 2.86 2.515.375-.773 1.235-2.51 2.863-2.51 1.029 0 2.137.654 2.137 2.091 0 1.721-2.372 3.925-5 6.5-2.629-2.576-5-4.78-5-6.5 0-1.338.982-2.097 2.14-2.096Zm0-1C10.55 9 9 10.094 9 12.096c0 2.33 2.785 4.713 6 7.904 3.215-3.19 6-5.573 6-7.904 0-2.005-1.549-3.091-3.137-3.091-1.102 0-2.223.521-2.863 1.619C14.357 9.521 13.239 9 12.14 9Z'
      />
    </svg>
  );
};
const ClientsIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='30'
      height='15'
      fill='none'
      viewBox='0 0 30 15'
      className='self-center'
    >
      <path
        fill={'#353535'}
        d='M22.496 15H7.503l-.003-.779c0-1.573.125-2.482 1.985-2.912 2.105-.486 4.18-.92 3.181-2.761C9.71 3.093 11.824 0 15 0c3.114 0 5.282 2.979 2.332 8.549-.968 1.83 1.033 2.265 3.181 2.761 1.863.43 1.987 1.34 1.987 2.916l-.003.774Zm6.014-2.768c-1.613-.372-3.113-.698-2.386-2.07 2.211-4.178.586-6.412-1.75-6.412-1.581 0-2.81 1.021-2.81 2.905 0 4.879 2.835 2.212 2.807 8.345h5.627l.002-.579c0-1.182-.093-1.866-1.49-2.188ZM.002 15H5.63c-.026-6.133 2.807-3.465 2.807-8.345 0-1.884-1.229-2.905-2.81-2.905-2.336 0-3.961 2.234-1.749 6.411.727 1.374-.773 1.699-2.386 2.072C.092 12.554 0 13.239 0 14.42l.003.58Z'
      />
    </svg>
  );
};
const SettingsIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='21'
      height='21'
      fill='none'
      viewBox='0 0 21 21'
      className='self-center'
    >
      <path
        fill={'#353535'}
        d='M21 11.914V9.086c-1.445-.514-2.357-.658-2.817-1.767-.46-1.112.088-1.868.741-3.244l-1.999-2c-1.366.65-2.129 1.204-3.244.742-1.11-.46-1.256-1.38-1.767-2.817H9.086c-.51 1.43-.655 2.356-1.767 2.817-1.112.462-1.866-.086-3.244-.742l-2 2c.652 1.372 1.204 2.13.742 3.244C2.355 8.43 1.43 8.576 0 9.085v2.828c1.428.508 2.356.655 2.817 1.767.463 1.121-.1 1.895-.742 3.243l2 2c1.367-.65 2.13-1.203 3.244-.74 1.11.46 1.257 1.381 1.767 2.816h2.828c.51-1.431.656-2.354 1.774-2.82 1.105-.458 1.855.09 3.236.745l2-2c-.651-1.368-1.203-2.129-.742-3.243.46-1.112 1.39-1.26 2.818-1.768ZM10.5 14a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7Z'
      />
    </svg>
  );
};
export const Icons = {
  OverviewIcon,
  ListingIcon,
  FavPropertiesIcon,
  ClientsIcon,
  SettingsIcon,
};
