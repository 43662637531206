import React, { useState } from 'react';
import './Login.css';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import OtpInput from 'react-otp-input';
import { userServices } from '../../services';
import { User } from '../../config';
import { SpinnerLoading } from '../LoadingTest/SpinnerLoading';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Loading } from '../Loading';
// import socketIO from 'socket.io-client';
//
// const socket = socketIO('http://192.168.1.109:5000/');
// socket.connect();

const Login = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showTwoFactor, setShowTwoFactor] = useState(false);
  const [otpBid, setOtpBid] = useState('');
  const [secondLoginButton, setSecondLoginButton] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [input, setInput] = useState({
    email: '',
    password: '',
  });
  const [passwordShow, setPasswordShow] = useState(false);
  const handlePasswordShow = () => {
    setPasswordShow(!passwordShow);
  };
  const [error, setError] = useState(false);
  const handleChange = (e: any) => {
    setError(false);
    const { name, value } = e.target;
    setInput((prevInput) => {
      return {
        ...prevInput,
        [name]: value,
      };
    });
  };
  const confirmationCodeRef = React.useRef<OtpInput>(null);
  React.useEffect(() => {
    confirmationCodeRef.current?.focusInput(0);
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const [disabledLoginInput, setDisabledLoginInput] = useState(false);

  const handleLoginButton = () => {
    setButtonLoader(true);
    userServices
      .userLogin(input.email, input.password)
      .then((response: any) => {
        localStorage.setItem('access_token', response.data.access_token);
        setButtonLoader(false);
        setShowTwoFactor(true);
        setSecondLoginButton(true);
        setDisabledLoginInput(true);
      })
      .catch((err) => {
        setError(true);
        setButtonLoader(false);
        // console.log(err);
        if (err.response.data.msg == 'terminated_account') {
          toast.error(t(`errors.${err.response.data.msg}`), {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
        if (err.response.data.msg != 'terminated_account') {
          toast.error(t('errors.loginError'), {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      });
  };

  const handleTwoFactor = () => {
    if (otpBid.length == 6) {
      setButtonLoader(true);
      userServices
        .userLogin2FA(otpBid)
        .then((response: any) => {
          localStorage.setItem('access_token', response.data.access_token);
          localStorage.setItem('user_id', response.data.user.id);
          localStorage.setItem('refresh_token', response.data.refresh_token);
          setButtonLoader(false);
          setIsLoading(true);
          
          // navigate(-1);
          return response;
        })
        .catch((err) => {
          setButtonLoader(false);
          if (err.response.data.msg == 'failed_verification') {
            toast.error(t(`errors.${err.response.data.msg}`), {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          }
          if (err.response.data.msg != 'failed_verification') {
            setError(true);
            toast.error(t('errors.login2Fa'), {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          }
        });
    }
  };
  // MISSING SNACKBAR
  const resendCode = () => {
    userServices
      .resendVerification()
      .then((response) => {
        toast.success('Die Anfrage für den Bestätigungscode wurde erfolgreich gesendet. ');
        // console.log(response);
      })
      .catch((error: any) => {
        // console.log(error);
      });
  };

  const handleLoginWithEnter = (event: any) => {
    if (event.key === 'Enter' && !secondLoginButton) {
      handleLoginButton();
    } else if (event.key === 'Enter' && secondLoginButton) {
      handleTwoFactor();
    }
  };

  if (isLoading) {
    setTimeout(() => {
      if (location.pathname == '/login') {
        navigate('/');
      } else {
        navigate(0);
      }
    }, 3200);
    return <Loading />;
  }

  return (
    <div className='loginContainer '>
      <ToastContainer autoClose={4000} />
      <div className=''>
        <div className='flex items-center justify-center pt-[64px] px-4 sm:px-6 lg:px-8 font-avenir55'>
          <div className='max-w-3xl w-full space-y-8 bg-white '>
            <div className='grid grid-cols-1 sm:grid-cols-2 divide-y-2 sm:divide-y-0 divide-x-0 sm:divide-x-2'>
              <div
                className='space-y-8 font-press-start sm:space-y-10 p-12 sm:pl-[47px] sm:pr-[80px] sm:py-[131px]'
                onKeyDown={handleLoginWithEnter}
              >
                <div className='block'>
                  <label className='pr-2 italic'>{t('login.email')}</label>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-5 w-5 absolute mt-2 fill-gray-500 hover:text-gray-900 '
                    viewBox='0 0 20 20'
                  >
                    <path d='M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z' />
                  </svg>
                  <input
                    type='text'
                    placeholder='example@email.com'
                    name='email'
                    value={input.email}
                    onChange={handleChange}
                    readOnly={disabledLoginInput}
                    className={`italic py-2 px-6 w-full text-base sm:text-sm text-gray-900 bg-transparent ${
                      error
                        ? 'border-0 border-b-2 border-auctionicRed'
                        : 'border-0 border-b-2 border-gray-300'
                    }  appearance-none focus:outline-none focus:ring-0 focus:border-black peer required`}
                  />
                </div>
                <div className='block'>
                  <label className='pr-2 italic '>{t('login.password')}</label>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-5 w-5 absolute mt-2 fill-gray-500 focus:text-gray-900'
                    viewBox='0 0 20 20'
                    stroke='white'
                  >
                    <path d='M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z' />
                  </svg>
                  <input
                    type={passwordShow ? 'text' : 'password'}
                    placeholder={t('login.password')}
                    name='password'
                    value={input.password}
                    readOnly={disabledLoginInput}
                    onChange={handleChange}
                    className={`italic py-2 px-6 w-full text-base sm:text-sm text-gray-900 bg-transparent ${
                      error
                        ? 'border-0 border-b-2 border-auctionicRed'
                        : 'border-0 border-b-2 border-gray-300'
                    } appearance-none focus:bg-transparent  focus:outline-none focus:ring-0 focus:border-black peer required`}
                  />

                  {passwordShow ? (
                    <button onClick={handlePasswordShow}>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth='1.5'
                        stroke='gray'
                        className='w-5 h-5 absolute -mt-3 -ml-6 '
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z'
                        />
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
                        />
                      </svg>
                    </button>
                  ) : (
                    <button onClick={handlePasswordShow}>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth='1.5'
                        stroke='gray'
                        className='w-5 h-5 absolute -mt-3 -ml-6 '
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88'
                        />
                      </svg>
                    </button>
                  )}
                </div>
                <div className='sm:float-right'>
                  <div className=''>
                    {!secondLoginButton && (
                      <button
                        className='bg-darkGray hover:bg-neutral-900 w-full px-14 py-2 rounded-md text-white shadow-md'
                        onClick={handleLoginButton}
                      >
                        {buttonLoader ? (
                          <div className='flex justify-center w-full'>
                            <SpinnerLoading />
                          </div>
                        ) : (
                          <p>{t('login.buttonLogin')}</p>
                        )}
                      </button>
                    )}
                    {secondLoginButton && (
                      <button
                        className='hidden sm:block bg-darkGray hover:bg-neutral-900 font-press-start w-full px-14 py-2 rounded-md text-white shadow-md transition-all ease-in-out duration-150'
                        onClick={handleTwoFactor}
                      >
                        {buttonLoader ? (
                          <div className='flex justify-center w-full'>
                            <SpinnerLoading />
                          </div>
                        ) : (
                          <p>{t('login.buttonLogin')}</p>
                        )}
                      </button>
                    )}
                  </div>
                  <div className='sm:float-right mt-5 '>
                    <Link
                      to={'/forgot/password'}
                      className='justify-end hover:text-gray-400 w-full font-press-start text-sm transition-all ease-in-out duration-200 '
                    >
                      {t('login.forgetPassword')}
                    </Link>
                  </div>
                </div>
              </div>

              <div
                className='flex justify-center py-10 sm:py-0'
                onKeyDown={handleLoginWithEnter}
              >
                {showTwoFactor ? (
                  <div
                    id='otpAnimate'
                    className='self-center space-y-5 sm:space-y-[30px]'
                  >
                    <div className='text-darkGray text-center font-semibold font-secondfont'>
                      {t('login.twoFactor')}
                    </div>
                    <div className='flex justify-center'>
                      <OtpInput
                        value={otpBid}
                        onChange={setOtpBid}
                        isInputNum={true}
                        numInputs={6}
                        ref={confirmationCodeRef}
                        inputStyle={{
                          width: '2.5rem',
                          height: '2.5rem',
                          margin: '0 0.2rem',
                          fontSize: '1.52rem',
                          borderRadius: 4,
                          border: '1px solid rgba(0,0,0,0.3)',
                          color: 'black',
                          inputMode: 'numeric',
                          autoComplete:'one-time-code'
                        }}
                        shouldAutoFocus={true}
                      />
                    </div>
                    <div className='flex justify-center sm:hidden px-12 py-5'>
                      {secondLoginButton && (
                        <button
                          className='flex justify-center bg-darkGray hover:bg-neutral-900 font-press-start p-2 w-full rounded-md text-white shadow-md transition-all ease-in-out duration-150'
                          onClick={handleTwoFactor}
                        >
                          {buttonLoader ? (
                            <SpinnerLoading />
                          ) : (
                            <p>{t('login.buttonLogin')}</p>
                          )}
                        </button>
                      )}
                    </div>
                    <div className='flex justify-center'>
                      <button
                        className='text-darkGray font-light flex space-x-3'
                        onClick={resendCode}
                      >
                        <span>{t('propertyDetails.resendVerification')}</span>
                        <svg
                          viewBox='0 0 512 512'
                          xmlns='http://www.w3.org/2000/svg'
                          className='h-4 w-4 self-center '
                          fill='#353535'
                        >
                          <path d='M480 256c0 123.4-100.5 223.9-223.9 223.9c-48.86 0-95.19-15.58-134.2-44.86c-14.14-10.59-17-30.66-6.391-44.81c10.61-14.09 30.69-16.97 44.8-6.375c27.84 20.91 61 31.94 95.89 31.94C344.3 415.8 416 344.1 416 256s-71.67-159.8-159.8-159.8C205.9 96.22 158.6 120.3 128.6 160H192c17.67 0 32 14.31 32 32S209.7 224 192 224H48c-17.67 0-32-14.31-32-32V48c0-17.69 14.33-32 32-32s32 14.31 32 32v70.23C122.1 64.58 186.1 32.11 256.1 32.11C379.5 32.11 480 132.6 480 256z' />
                        </svg>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className='self-center space-y-5 sm:space-y-[30px]'>
                    <div className='text-center sm:pl-12'>
                      <p className='text-center font-braga sm:text-[32px] sm:w-[150px] '>
                        {t('login.notMember')}
                      </p>
                    </div>
                    <button
                      id='buttonAnimateLogin'
                      onClick={() => navigate('/register')}
                      className='rounded-md  bg-darkGray font-press-start hover:bg-neutral-900 text-white px-20 py-3 sm:text-[16px] shadow-md hover:scale-x-105 transition-all ease-in-out duration-150'
                    >
                      {t('login.registerButton')}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='flex items-start pb-[64px] justify-center px-4 sm:px-6 lg:px-8  font-avenir55'>
          <div className='max-w-3xl w-full'>
            <div className=''>
              <button
                onClick={() => navigate('/')}
                className=' bg-white hover:bg-gray-100 px-2 py-2 mt-3 rounded-md'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='black'
                >
                  <path d='M10 19l-7-7m0 0l7-7m-7 7h18' />
                </svg>
              </button>
              <a
                href='/'
                className='text-white absolute ml-2 mt-5 sm:mt-5 hover:underline font-press-start'
              >
                {t('login.backButton')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export { Login };
