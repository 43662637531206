import React, { useState } from 'react';
import { userServices } from '../../services';
import { toast, ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SpinnerLoading } from '../LoadingTest/SpinnerLoading';

const Newsletter = () => {
  const { t } = useTranslation();
  const [buttonLoader, setButtonLoader] = useState(false);
  const [subscribe, setSubscribe] = useState( {
    email: ''
  });
  const checkEmailFormat = (email: any) => {
    return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
  };
  const handleSubscribe = () => {
    if (checkEmailFormat(subscribe.email)) {
      setButtonLoader(true);
      userServices
        .subscribeLetter(subscribe.email)
        .then((response: any) => {
          setButtonLoader(false);
          setSubscribe(response.data);
          toast.success((t('newsletter.successfullySubscribed')), {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        })
        .catch((error: any) => {
          // console.log(error);
          setButtonLoader(false);
          toast.error((t('newsletter.errorOccurred')), {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        });
    }
    else {
      setButtonLoader(false);
      toast.error((t('newsletter.invalidEmail')), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };
  return (
    <>
      <ToastContainer />
      <section className="bg-white min-h-screen pt-[10%] sm:pt-[12%]">
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
          <div className="mx-auto max-w-screen-md sm:text-center">
            <h2 className="mb-4 text-3xl tracking-tight font-extrabold text-darkGray sm:text-4xl">{t('newsletter.signUp')}</h2>
            <p className="mx-auto mb-8 max-w-2xl font-light text-darkGray md:mb-12 sm:text-xl">{t('newsletter.stayUpToDate')}</p>
            <form action="#">
              <div className="items-center mx-auto mb-3 space-y-4 max-w-screen-sm sm:flex sm:space-y-0">
                <div className="relative w-full">
                  <label htmlFor="email" className="hidden mb-2 text-sm font-medium text-darkGray">Email
                    address</label>
                  <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <svg className="w-5 h-5 text-darkGray" fill="currentColor" viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg">
                      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                    </svg>
                  </div>
                  <input
                    value={subscribe.email}
                    className="block p-3 pl-10 w-full text-sm text-darkGray bg-gray-50 rounded-lg border border-gray-300 sm:rounded-none sm:rounded-l-lg focus:ring-primary-500 focus:border-primary-500"
                    placeholder={t('newsletter.enterEmail')} type="email" id="email"
                    onChange={(e) => {
                      setSubscribe({
                        ...subscribe,
                        email: e.target.value
                      });
                    }}
                    required/>
                </div>
                <div>
                  <button type="submit"
                    disabled={subscribe.email?.length==0}
                    onClick={handleSubscribe}
                    className="flex py-3 px-5 w-full text-sm font-medium text-center text-white rounded-lg border cursor-pointer bg-auctionicRed border-primary-600 sm:rounded-none sm:rounded-r-lg hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 ">{t('newsletter.subscribe')} {buttonLoader ? <SpinnerLoading/>: ''}
                  </button>
                </div>
              </div>
              <div
                className="mx-auto max-w-screen-sm text-sm text-left text-darkGray newsletter-form-footer ">{t('newsletter.weCare')} <Link to={'/privacy/policy'}
                  className="font-medium text-primary-600 hover:underline">{t('newsletter.readPolicy')}</Link>.
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export { Newsletter };
