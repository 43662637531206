export const headers = {
  getHeaders,
  getAuthHeaders,
  getRefreshHeaders,
};

function getHeaders() {
  return {
    'Content-Type': 'application/json',
  };
}

function getAuthHeaders() {
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  };
}

function getRefreshHeaders() {
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('refresh_token')}`,
  };
}
