import { auctionicApi } from '../helpers/api';
import { headers } from '../helpers/headers';


const getAgreements = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/agreements', config);
};



export const agreementsServices = {
  getAgreements
};
