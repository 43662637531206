import React, { useEffect, useRef, useState } from 'react';
import { userServices } from '../../services';
import { Link, useNavigate } from 'react-router-dom';
import robot from '../../images/robot_ai.svg';
import { useTranslation } from 'react-i18next';
import Logo from '../../images/logoRed.svg';

type MessageType = 'user' | 'server';

interface Message {
  id: number;
  text: string;
  type: MessageType;
}

const suggestions = [
  {
    id: 1,
    question: 'suggestion1'
  },
  {
    id: 2,
    question: 'suggestion2'
  },
  {
    id: 3,
    question: 'suggestion3'
  },
  {
    id: 4,
    question: 'suggestion4'
  },
  {
    id: 5,
    question: 'suggestion5'
  }
];

const Support = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [loaderText, setLoaderText] = useState('.');
  const [isLoading, setIsLoading] = useState(false);
  const chatEndRef = useRef<HTMLDivElement | null>(null);

  // Add new state variables for the current suggestion and suggestion timer
  const [currentSuggestionIndex, setCurrentSuggestionIndex] = useState(0);
  const [suggestionTimer, setSuggestionTimer] = useState<any>(null);

  // ... previous useEffects
  
  // Effect to manage suggestions
  useEffect(() => {
    if (messages.length === 0) {
      // Start the timer if there are no messages
      setSuggestionTimer(
        setInterval(() => {
          setCurrentSuggestionIndex((prevIndex) => (prevIndex + 1) % suggestions.length);
        }, 5000) // 7 seconds
      );
    } else {
      // Clear the timer if messages appear
      if (suggestionTimer) {
        clearInterval(suggestionTimer);
        setSuggestionTimer(null);
      }
    }
  }, [messages]);

  const handleSuggestionClick = (suggestion: string) => {
    // setInputValue(suggestion); // set as user message
    handleSubmit2(suggestion); // trigger to go ask the backend
  };



  const { t } = useTranslation();
  useEffect(() => {
    smoothScrollToBottom();
  }, [messages]);
  
  useEffect(() => {
    if (isLoading) {
      animateLoader();
    }
  }, [isLoading, loaderText]);
  const animateLoader = () => {
    setTimeout(() => {
      switch (loaderText) {
      case '.':
        setLoaderText('..');
        break;
      case '..':
        setLoaderText('...');
        break;
      case '...':
        setLoaderText('.');
        break;
      default:
        setLoaderText('.');
      }
    }, 400); // Change this delay to make the animation faster or slower
  };

  useEffect(() => {
    window.scroll(0,0);
  }, []);


  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const smoothScrollToBottom = () => {
    if (chatEndRef.current) {
      const element = chatEndRef.current;
      const start = element.scrollTop;
      const end = element.scrollHeight - element.clientHeight;
      const change = end - start;
      const duration = 300; // Duration in ms
      let startTime: number;

      const animateScroll = (timestamp: number) => {
        if (!startTime) { startTime = timestamp;}
        const progress = timestamp - startTime;
        const easeInOutQuad = (t: number) => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
        const val = easeInOutQuad(progress / duration);
        element.scrollTop = start + change * val;
        if (progress < duration) {
          window.requestAnimationFrame(animateScroll);
        }
      };

      window.requestAnimationFrame(animateScroll);
    }
  };

  const handleSubmit2  = (inputString: string) => {
    setIsLoading(true);
    const userMessage: Message = {
      id: Date.now(),
      text: inputString,
      type: 'user',
    };
    setMessages((prev) => [...prev, userMessage]);
    const body = { 'request': inputString };
    userServices.requestSuppport(body).then((response: any) => {
      setIsLoading(false);
      const formattedText = response.data.replace(/\n/g, '<br />');
      const serverMessage: Message = {
        id: Date.now() + 1,
        text: formattedText,
        type: 'server',
      };
      setMessages((prev) => [...prev, serverMessage]);
    }).catch((error:any) => {
      setIsLoading(false);
      const serverMessage: Message = {
        id: Date.now() + 1,
        text: error.response.data.msg,
        type: 'server',
      };
      setMessages((prev) => [...prev, serverMessage]);
      console.log(error.msg);
    });
    setInputValue('');
    smoothScrollToBottom();
  };

  const handleSubmit = () => {
    if (inputValue != '') {
      setIsLoading(true);
      const userMessage: Message = {
        id: Date.now(),
        text: inputValue,
        type: 'user',
      };
      setMessages((prev) => [...prev, userMessage]);
      const body = { 'request': inputValue };
      userServices.requestSuppport(body).then((response: any) => {
        setIsLoading(false);
        const formattedText = response.data.replace(/\n/g, '<br />');
        const serverMessage: Message = {
          id: Date.now() + 1,
          text: formattedText,
          type: 'server',
        };
        setMessages((prev) => [...prev, serverMessage]);
      }).catch((error:any) => {
        setIsLoading(false);
        const serverMessage: Message = {
          id: Date.now() + 1,
          text: error.response.data.msg,
          type: 'server',
        };
        setMessages((prev) => [...prev, serverMessage]);
        console.log(error.msg);
      });
      setInputValue('');
      smoothScrollToBottom();
    }
  };

  const handleKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !event.shiftKey && !isLoading) {
      event.preventDefault();
      handleSubmit();
    }
  };
  // const scrollToBottom = () => {
  //   if (chatEndRef.current) {
  //     chatEndRef.current.scrollTop = chatEndRef.current.scrollHeight;
  //   }
  // };
  return (
    <div>
      <div className='flex justify-center m-5'>
        <div className="flex-shrink-0 p-5 justify-center">
          <img className="h-20 w-20 sm:justify-center" src={robot} alt="ChitChat Logo" />
        </div>
        <p className='self-center justify-start font-braga text-3xl mr-6'>{t('support.title')}</p>
      </div>
      <div className='flex flex-col items-center h-screen sm:min-h-[60%] sm:max-h-[60%] justify-center m-5 mt-[-7%]'>
        <div className='flex flex-col w-full sm:w-1/2 border border-gray-300 p-4 rounded-xl overflow-y-auto min-h-[60%] max-h-[60%]' ref={chatEndRef}>
          {messages.map((message) => (
            <div key={message.id} className={`mb-2 ${message.type === 'user' ? 'text-right' : ''}`}>
              {/* <span className={`inline-block p-2 rounded ${message.type === 'user' ? 'bg-gray-200 text-gray-800' : 'bg-red-400 text-white'}`}>{message.text}</span> */}
              <span
                className={`inline-block p-2 rounded ${message.type === 'user' ? 'bg-gray-200 text-gray-800' : 'bg-red-400 text-white'}`}
                dangerouslySetInnerHTML={{ __html: message.text }}
              ></span>
            </div>
          ))}
          {isLoading && <div className="mb-2 text-left bg-red-400 text-white inline-block p-2 rounded">{loaderText}</div>}
          {messages.length == 0 && (
            <div
              onClick={() => handleSuggestionClick(t(`support.${suggestions[currentSuggestionIndex].question}`))}
              className="mb-2 text-left mt-auto bg-auctionicRed text-white p-2 rounded cursor-pointer"
            >
              {t(`support.${suggestions[currentSuggestionIndex].question}`)}
            </div>
          )}
        </div>
        <div className='sm:flex w-full sm:w-1/2 mt-4'>
          <input type='text' value={inputValue} onKeyDown={handleKeydown} onChange={handleInputChange} className='border border-gray-300 p-2 rounded w-full mb-3 sm:mb-0 sm:w-[85%]' placeholder={t('support.type')} />
          <button disabled={isLoading} onClick={handleSubmit} className='sm:ml-2 p-2 bg-auctionicRed text-white rounded w-full sm:w-[15%]'>{t('support.send')}</button>
        </div>
      </div>
    </div>
  );
};


const ChatCard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="sm:flex w-[1024px] bg-gray-100 rounded-xl shadow-md hover:shadow-lg transition px-3 m-4 py-3">
      <div className='flex justify-center'>
        <div className="flex-shrink-0 p-5 justify-center">
          <img className="h-32 w-32 sm:justify-center" src={robot} alt="ChitChat Logo" />
        </div>
      </div>
      <div className='flex-1 flex items-center justify-center'>
        <div>
          <div className="flex items-center">
            <div className="ml-4">
              <div className="text-xl font-medium text-black">{t('support.title')}</div>
              <p className="text-gray-500">{t('support.description')}</p>
            </div>
          </div>
          <div className="flex mt-4 justify-end">
            <button
              onClick={() => navigate('/support')}
              className="bg-auctionicRed hover:bg-white hover:text-auctionicRed border-2 border-auctionicRed text-white font-bold py-2 px-4 rounded-lg"
            >
              {t('support.startChat')}
            </button>
          </div>
        </div>
        
      </div>
      
    </div>
  );
};

export { Support, ChatCard };
