import React, { useState } from 'react';
import Logo from '../../images/logoRed.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { userServices } from '../../services';
import { toast, ToastContainer } from 'react-toastify';
import { SpinnerLoading } from '../LoadingTest/SpinnerLoading';

function ForgotPassword() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [buttonLoader,setButtonLoader] = useState(false);
  const [input, setInput] = React.useState({
    email: '',
  });

  const handleSubmit = () => {
    setButtonLoader(true);
    userServices.forgetPassword(input).then((res) => {
      setButtonLoader(false);
      // console.log(res);
      toast.success(t('forgotPassword.resetLink'), {
        position: 'bottom-left',
      });
    }
    ).catch(() => {
      toast.error(t('forgotPassword.error'), {
        position: 'bottom-left',
      });
      setButtonLoader(false);
    });
  };
  return (
    <>
      <ToastContainer autoClose={3000} />
      <div className='bg-white flex justify-center pt-8'>
        <button onClick={() => navigate('/')}>
          <img src={Logo} alt='' />
        </button>
      </div>
      <div className='bg-white py-28 sm:px-32 sm:py-48 block sm:grid sm:grid-cols-2 min-h-screen'>
        <div id='error' className='px-8 sm:px-0 self-center'>
          <h1 className='sm:text-[45px] text-[26px] font-medium font-braga'>
            {t('resetPassword.forgot')}
          </h1>
          <hr className='sm:mr-48 border-red-600' />
          <p className=' pt-5 font-press-start'>{t('resetPassword.requestEmail')}</p>
        </div>
        <div className='block pt-8 px-10 sm:px-0 self-center'>
          <label className='pr-2 text-gray-500 font-press-start'>
            {t('resetPassword.email')}
          </label>
          <input
            type='email'
            name='email'
            value={input.email}
            onChange={(e) => setInput({ ...input, email: e.target.value })}
            className=' py-2 mb-5 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer required'
          />
          <div className='text-center pt-6'>
            <button onClick={handleSubmit} className='bg-red-500 hover:bg-gray-600 px-14 py-2 rounded-md text-white font-press-start'>
              {buttonLoader ? <SpinnerLoading /> : <p>{t('resetPassword.resetButton')}</p>}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
export { ForgotPassword };
