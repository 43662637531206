import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAuth from '../../../context/AuthContext';
import visa from '../../../images/visa.svg';
import mastercard from '../../../images/mastercard-alt.svg';
import twint from '../../../images/twint.svg';
import bexio from '../../../images/bexio-ag-logo-vector.svg';
import datatrans from '../../../images/datatrans.svg';

interface Props {
    onNext: () => void;
    onBack: () => void;
    reAdvertiseInput: any;
    propertyData: any;
    setReadvertiseInput: any;
  }

const UserInfoStep: React.FC<Props>  = ({ onNext, onBack, reAdvertiseInput, propertyData, setReadvertiseInput }) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [method, setMethod] = useState(reAdvertiseInput.payment.method);

  const changeMethod = (method_:string) =>{
    setMethod(method_);
    setReadvertiseInput((prev:any) => ({
      ...prev,
      payment:{
        ...prev.payment,
        method:method_ }
    }));
  };
  return (
    <div>
      <div
        className='ml-4 h-[100%] sm:ml-[10%] mr-4 sm:mr-[10%] lg:ml-[15%] lg:mr-[15%] mt-10 lg:mt-[35px] mb-4 overflow-hidden'
      >
        <div className='space-y-4 border-b-2 border-white py-5 bg-gray-100 p-5 rounded-lg'>
          <p className='text-[25px] font-semibold'>{t('dashboard.listingSection.reAdvertise.billingDetails')}</p>
          {user?.type=='individual' ? (
            <div className='flex sm:space-x-5'>
              <div className='flex space-x-1 mr-1'>
                <p className=''>{t('dashboard.listingSection.reAdvertise.firstName')}</p>
                <p className='font-semibold'>{user?.first_name} </p>
              </div>
              <div className='flex space-x-1'>
                <p className=''>{t('dashboard.listingSection.reAdvertise.lastName')}</p>
                <p className='capitalize font-semibold'>{user?.last_name}</p>
              </div>
            </div>
          ):(
            user?.type=='company' && (
              <div className='flex sm:space-x-5'>
                <div className='flex space-x-1'>
                  <p className=''>{t('dashboard.listingSection.reAdvertise.companyName')}</p>
                  <p className='font-semibold'>{user?.name}</p>
                </div>
              </div>
            )
          )}
          <div className='flex space-x-1'>
            <p className=''>{t('dashboard.listingSection.reAdvertise.address')}</p>
            <p className='font-semibold'>{user?.address.formatted}</p>
          </div>
          <div className='flex space-x-1'>
            <p className=''>{t('dashboard.listingSection.reAdvertise.phoneNumber')}</p>
            <p className='font-semibold'>{user?.phone}</p>
          </div>
          <div className='flex space-x-1'>
            <p className=''>{t('dashboard.listingSection.reAdvertise.email')}</p>
            <p className='font-semibold'>{user?.email}</p>
          </div>
        </div>
        <div className='border-b-2 border-white py-5 space-y-3 bg-gray-100 p-5 rounded-lg'>
          <p className='text-[25px] font-semibold'>{t('dashboard.listingSection.reAdvertise.chooseMethod')}</p>
          <div className='flex space-x-2 items-center'>
            <input
              type='radio'
              name='card'
              className='h-6 w-6 accent-auctionicRed'
              checked={method=='card'}
              onClick={() => changeMethod('card')}
              onChange={() => changeMethod('card')}
            />
            <div onClick={()=>changeMethod('card')} className={`grid justify-between items-center grid-flow-row sm:grid-flow-col border-2 border-white w-full ${method=='card'? 'bg-auctionicRed text-white':'bg-gray-100 ' } p-5 rounded-lg`}>
              <div className='sm:pb-0 pb-5 text-[20px] font-semibold'>{t('dashboard.listingSection.reAdvertise.card')}</div>
              <div className=''>

                <div className='flex items-center space-x-5 mb-5'>
                  <div>
                    <img src={visa} />
                  </div>
                  <div>
                    <img src={mastercard} />
                  </div>
                  <div>
                    <img src={twint} />
                  </div>
                </div>
                <div className='flex items-center justify-end'>
                  Powered by
                  <img src={datatrans} className='max-h-[35px] ml-5'/>
                </div>
              </div>
            </div>
          </div>
          <div className='flex space-x-2 items-center'>
            <input
              type='radio'
              name='bill'
              className='h-6 w-6 accent-auctionicRed'
              checked={method=='bill'}
              onClick={() => changeMethod('bill')}
              onChange={() => changeMethod('bill')}
            />
            <div onClick={()=>{changeMethod('bill');}} className={`border-2 border-white w-full grid justify-between grid-flow-row sm:grid-flow-col items-center ${method=='bill'? 'bg-auctionicRed text-white':'bg-gray-100' } p-5 rounded-lg`}>
              <div className='text-[20px] font-semibold'>{t('dashboard.listingSection.reAdvertise.bill')}</div>
              <div className={`flex ${method=='bill'? 'bg-auctionicRed text-white':'bg-gray-100'} justify-end`}>
                <div className='w-[200px] flex items-center justify-end'>
                  <p>Powered by</p>
                  <img src={bexio} className='ml-5 max-h-[75px]' />
                </div>
              </div>
            </div>
          </div>
          
        </div>

      </div>
      <div className="flex justify-between mt-4 px-6">
        <button className="px-4 py-2 bg-darkGray text-white rounded" onClick={onBack}>
          {t('dashboard.listingSection.reAdvertise.back')}
        </button>
        <button className="px-4 py-2 bg-darkGray text-white rounded" onClick={onNext}>
          {t('dashboard.listingSection.reAdvertise.advertise')}
        </button>
      </div>
    </div>
  );
};

export { UserInfoStep };
