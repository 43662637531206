import React, { useState } from 'react';
import Mail from '../../images/mail.svg';
import Phone from '../../images/phone.svg';
import { useTranslation } from 'react-i18next';
import { PropertyNotFound } from '../PropertyListing/PropertyNotFound';
import { utils } from '../../helpers/utils';
import house from '../../images/deal.svg';
import './Dashboard.css';
import { userServices } from '../../services/userServices';
import { DashboardLoading } from '../LoadingTest/DashboardLoading';
import { utilsProperty } from '../PropertyDetails/utils';

interface Props {
  handleNavigation: any;
  agreements: any;
  loadingClients: any;

}
const Clients = (props: Props) => {
  const { handleNavigation, agreements, loadingClients } = props;
  const loggedUserId = localStorage.getItem('user_id');
  // const [agreements, setAgreements] = useState<Array<Agreements>>([]);
  const [viewClientData, setViewClientData] = useState<Array<string>>([]);

  const { t } = useTranslation();

  // useEffect(() => {
  //   setLoading(true);
  //   agreementsServices
  //     .getAgreements()
  //     .then((response: any) => {
  //       setLoading(false);
  //       setAgreements(response.data);
  //       console.log(response.data);
  //       setLoading(false);
  //       return response;
  //     })
  //     .catch(() => {
  //       setLoading(false);
  //       console.log('failed to get the agreements');
  //     });
  // }, []);
  const isSeller = (data: any) => {
    if (Object.prototype.hasOwnProperty.call(data, 'seller_id')) {
      if (data.seller_id == loggedUserId) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  function formatChf(amount: number): string {
    if (amount != null) {
      const formattedAmount = amount.toLocaleString('de-CH', {
        style: 'currency',
        currency: 'CHF',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    
      return formattedAmount;
    }
    return '';
  }

  // ADD SNACKBAR ON THE FUNCTION BELOW
  const getInvoice = (id: number) => {
    userServices
      .getInvoiceByPaymentId(id)
      .then((response: any) => {
        if (response?.data?.link != '') {
          window.open(response?.data?.link, '_blank');
        } else {
          alert('There is no invoice for this property');
        }
      })
      .catch((error:any) => {
        // console.log(error);
      });
  };

  return (
    <div className=' mt-10 sm:mt-0 mx-4 sm:mx-0 text-darkGray'>
      <div className='rounded-lg px-2 sm:px-10 py-[24px]'>
        <div className='text-[20px] font-press-start border-b border-darkGray text-center sm:text-justify'>
          {t('dashboard.clients')}
        </div>
        {loadingClients ? (
          <>
            <DashboardLoading />
            <DashboardLoading />
            <DashboardLoading />
          </>

        ) : (
          <div className='grid grid-cols-1 mt-3'>
            {agreements?.length !== 0 ? (
              <div className=''>
                {agreements?.map((data: any) => {
                  return (
                    <div className='py-2' key={data.id}>
                      <div
                        id='client'
                        className={`block rounded-lg border shadow-md ${
                          viewClientData.includes(data.id)
                            ? 'bg-white text-darkGray transition-all ease-in-out duration-500 shadow-md shadow-gray-500 '
                            : 'transition-all ease-in-out duration-100 bg-transparent hover:border hover:shadow-lg'
                        }`}
                      >
                        <div className='ribbon'>
                          <span className='text'>
                            <strong className='font-braga text-base'>
                              {t('dashboard.clientsSection.auction')}
                            </strong>
                          </span>
                        </div>
                        <div className='py-5'>
                          <div className=' grid grid-cols-1 sm:grid-cols-5'>
                            <div className='self-center mx-3 sm:mx-0 sm:ml-5'>
                              <img
                                src={data.property_on_auction.header_pic}
                                alt=''
                                className='rounded-md bg-black sm:h-[100px] w-full sm:sm:w-[100px]'
                              />
                            </div>
                            <div className='self-center p-3 sm:p-0'>
                              <p className='text-[15px] font-bold '>
                                {utilsProperty.generateTitleAgreement(data,t)}
                              </p>
                              <p className='text-[15px] text-red-500 font-press-start'>
                                <p>
                                 CHF {utils.numberWithComma(data.property_on_auction.current_bid)}
                                </p>
                              </p>
                            </div>
                            <div className='sm:flex space-x-1 sm:justify-center sm:col-span-2 p-3 sm:p-0 '>
                              <div className='text-sm self-center'>{t('dashboard.clientsSection.status')}</div>
                              {isSeller(data) ? (
                                <>
                                  {data.property_on_auction.status === 6 ? (
                                    <span className='font-bold self-center'>
                                      {t('dashboard.clientsSection.pleaseMakeDecision')}
                                    </span>
                                  ) : data.property_on_auction.status === 7 ? (
                                    <span className='font-bold self-center'>
                                      {t('dashboard.clientsSection.declinedOffer')}
                                    </span>
                                  ) : data.property_on_auction.status === 8 ? (
                                    <span className='font-bold self-center'>
                                      {t('dashboard.clientsSection.acceptedBid')}
                                    </span>
                                  ) : data.property_on_auction.status === 9 ? (
                                    <span className='font-bold self-center animate-pulse text-green-600'>
                                      {t('dashboard.clientsSection.agreementReached')}
                                    </span>
                                  ) : (
                                    'No status'
                                  )}
                                </>
                              ) : (
                                <>
                                  {data.property_on_auction.status === 6 ? (
                                    <span className='font-bold self-center'>
                                      {t('dashboard.clientsSection.acceptOffer')}
                                    </span>
                                  ) : data.property_on_auction.status === 7 ? (
                                    <span className='font-bold self-center'>
                                      {t('dashboard.clientsSection.sellerDeclinedOffer')}
                                    </span>
                                  ) : data.property_on_auction.status === 8 ? (
                                    <span className='font-bold self-center'>
                                      {t('dashboard.clientsSection.sellerAcceptedBid')}
                                    </span>
                                  ) : data.property_on_auction.status === 9 ? (
                                    <span className='font-bold self-center animate-pulse text-green-600'>
                                      {t('dashboard.clientsSection.agreementReached')}
                                    </span>
                                  ) : (
                                    <span className='font-thin self-center border-b'></span>
                                  )}
                                </>
                              )}
                            </div>
                            <div className='self-center flex justify-end mr-5'>
                              <button
                                className={`px-3 py-3 text-white rounded-lg bg-darkGray ${
                                  viewClientData.includes(data.id)
                                    ? 'bg-darkGray transition-all ease-in-out duration-500'
                                    : 'bg-darkGray'
                                }`}
                                onClick={() => {
                                  if (viewClientData.includes(data.id)) {
                                    setViewClientData(
                                      viewClientData.filter(
                                        (_id: any) => data.id !== _id
                                      )
                                    );
                                  } else {
                                    setViewClientData([
                                      ...viewClientData,
                                      data.id,
                                    ]);
                                  }
                                }}
                              >
                                {viewClientData.includes(data.id) ? (
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 24 24'
                                    strokeWidth='2.5'
                                    stroke='currentColor'
                                    className={`w-4 h-4 ${
                                      viewClientData.includes(data.id) &&
                                      'text-white transition-all ease-in-out duration-1000'
                                    }`}
                                  >
                                    <path
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                      d='M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75'
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 24 24'
                                    strokeWidth='2.5'
                                    stroke='currentColor'
                                    className={`w-4 h-4 ${
                                      viewClientData.includes(data.id) &&
                                      'text-darkGray  transition-all ease-in-out duration-1000'
                                    }`}
                                  >
                                    <path
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                      d='M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75'
                                    />
                                  </svg>
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                        {viewClientData.includes(data.id) && (
                          <div>
                            <div className='p-5 rounded-b-md grid grid-cols-1 sm:grid-cols-2 divide-x-0 sm:divide-x sm:divide-darkGray'>
                              <div className='space-y-5'>
                                <div className=''>
                                  <div className='flex space-x-1'>
                                    <p className='text-sm place-self-center'>
                                      {t('dashboard.clientsSection.finishingDate')}
                                    </p>
                                    <span className='font-bold self-center'>
                                      {utils.formatDate(
                                        data.property_on_auction.finishing_date, t
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <div className=''>
                                  <div className='flex space-x-1'>
                                    <p className='text-sm place-self-center'>
                                      {t('dashboard.clientsSection.lastBid')}
                                    </p>
                                    <span className='font-bold self-center bg-gray-100 shadow-xl px-2 py-1 text-darkGray rounded-lg'>
                                     CHF {utils.numberWithComma(data.property_on_auction.current_bid)}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className=''>
                                <div className='my-2 sm:my-0 sm:ml-5'>
                                  <div className='flex space-x-1'>
                                    {isSeller(data) ? (
                                      <>
                                        {data.property_on_auction.status === 6 ? (
                                          <span className='font-bold'>
                                            {t('dashboard.clientsSection.lastOffer')}
                                          </span>
                                        ) : data.property_on_auction.status === 7 ? (
                                          <span className='font-bold self-center'>
                                            {t('dashboard.clientsSection.declinedOffer')}
                                          </span>
                                        ) : data.property_on_auction.status === 8 ? (
                                          <span className='font-bold self-center'>
                                            {t('dashboard.clientsSection.payProvision')}
                                          </span>
                                        ) : data.property_on_auction.status === 9 ? (
                                          <span className='font-bold self-center animate-pulse text-green-600'>
                                            {t('dashboard.clientsSection.agreementReached')}
                                          </span>
                                        ) : (
                                          'No status'
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {data.property_on_auction.status === 6 ? (
                                          <span className='font-bold self-center'>
                                            {t('dashboard.clientsSection.acceptOffer')}
                                          </span>
                                        ) : data.property_on_auction.status === 7 ? (
                                          <span className='font-bold self-center'>
                                            {t('dashboard.clientsSection.sellerDeclinedOffer')}
                                          </span>
                                        ) : data.property_on_auction.status === 8 ? (
                                          <span className='font-bold self-center'>
                                            {t('dashboard.clientsSection.sellerAcceptedBid')}
                                          </span>
                                        ) : data.property_on_auction.status === 9 ? (
                                          <span className='font-bold self-center animate-pulse text-green-600'>
                                            {t('dashboard.clientsSection.agreementReached')}
                                          </span>
                                        ) : (
                                          <span className='font-thin self-center border-b'></span>
                                        )}
                                      </>
                                    )}
                                  </div>
                                  {isSeller(data) ? (
                                    <>
                                      {data.property_on_auction.status === 8 && (
                                        <div className='sm:mt-5 flex justify-between bg-white rounded-2xl p-3'>
                                          <div className='flex space-x-2'>
                                            <p className='text-sm place-self-center'>
                                              {t('dashboard.clientsSection.provisionPrice')}{' '}
                                            </p>
                                            <span className='font-bold self-center bg-gray-50 px-2 py-1 text-darkGray rounded-lg shadow-sm shadow-gray-500'>
                                              {formatChf(data?.payment?.price)}
                                              {}
                                            </span>
                                          </div>
                                          <div className=''>
                                            <button
                                              className='flex bg-darkGray shadow-sm shadow-gray-500 px-3 py-1 rounded-md text-white hover:bg-auctionicRed hover:text-white transition-all ease-in-out duration-200'
                                              onClick={() =>
                                                getInvoice(data.payment.id)
                                              }
                                            >
                                              <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                fill='none'
                                                viewBox='0 0 24 24'
                                                strokeWidth='1.5'
                                                stroke='currentColor'
                                                className='w-6 h-6'
                                              >
                                                <path
                                                  strokeLinecap='round'
                                                  strokeLinejoin='round'
                                                  d='M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z'
                                                />
                                              </svg>
                                              <p className='self-center ml-0.5 mt-0.5'>
                                                {t('dashboard.clientsSection.seeInvoice')}
                                              </p>
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  ) : (
                                    ''
                                  )}
                                  {isSeller(data) ? (
                                    <>
                                      {data.property_on_auction.status ===
                                        9 && (
                                        <div className='sm:mt-5 flex justify-between bg-white rounded-2xl p-3'>
                                          <div className='block space-y-2'>
                                            {/* ÇMIMI I PROVIZIONIT DE VAJE PI N BEK-END..... PRAJTNIii.🤪🤪🤪   */}
                                            <div className='flex space-x-2'>
                                              <p className='text-sm place-self-center'>
                                                {t('dashboard.clientsSection.provisionPrice')}{' '}
                                              </p>
                                              <span className='font-bold self-center bg-gray-50 px-2 py-1 text-darkGray rounded-lg shadow-sm shadow-gray-500'>
                                                {formatChf(data?.payment?.price)}{' '}
                                              </span>
                                            </div>
                                            <div className='flex space-x-2'>
                                              <p className='text-sm place-self-center'>
                                                {t('dashboard.clientsSection.provisionStatus')}
                                              </p>
                                              <span
                                                className={`font-bold self-center px-2 py-1 rounded-lg text-sm ${data.payment.status === 'PAID' ? 'bg-[#00FFCD] text-darkGray ' : data.payment.status === 'DRAFT' ? 'bg-orange-500 text-darkGray' : data.payment.status === 'PENDING' ? 'bg-yellow-500 text-darkGray' : data.payment.status === 'CANCELED' ? 'bg-auctionicRed text-white' : ''
                                                }`}
                                              >
                                                {data.payment.status}
                                              </span>
                                            </div>
                                          </div>
                                          <div className=''>
                                            <button
                                              className='flex bg-darkGray shadow-sm shadow-gray-500 px-3 py-1 rounded-md text-white hover:bg-auctionicRed hover:text-white transition-all ease-in-out duration-200'
                                              onClick={() =>
                                                getInvoice(data.payment.id)
                                              }
                                            >
                                              <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                fill='none'
                                                viewBox='0 0 24 24'
                                                strokeWidth='1.5'
                                                stroke='currentColor'
                                                className='w-6 h-6'
                                              >
                                                <path
                                                  strokeLinecap='round'
                                                  strokeLinejoin='round'
                                                  d='M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z'
                                                />
                                              </svg>
                                              <p className='self-center ml-0.5 mt-0.5'>
                                                {t('dashboard.clientsSection.seeInvoice')}
                                              </p>
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>
                            </div>
                            <>
                              {data.property_on_auction.status === 9 && (
                                <div className='grid grid-cols-1 sm:grid-cols-2 mx-4 border-t border-darkGray'>
                                  <div className='flex justify-center mt-3 sm:mt-0'>
                                    <img
                                      src={house}
                                      alt=''
                                      className='w-[50px] h-[50px] self-center'
                                    />
                                    <p className='text-xs self-center font-bold'>
                                      {t('dashboard.clientsSection.congrats')}{' '}
                                    </p>
                                  </div>
                                  <div className=''>
                                    <div className='p-2'>
                                      <p className='text-base flex justify-center sm:justify-start font-bold'>
                                        {t('dashboard.contact')}
                                      </p>
                                      <div className='space-y-4 sm:space-y-0 '>
                                        <div className='block pt-[26px] sm:space-y-[8px]'>
                                          {isSeller(data) ? (
                                            <>
                                              {data.property_on_auction
                                                .status === 9 && (
                                                <div className='space-y-3'>
                                                  <div className='flex'>
                                                    <svg
                                                      xmlns='http://www.w3.org/2000/svg'
                                                      fill='none'
                                                      viewBox='0 0 24 24'
                                                      strokeWidth='1.5'
                                                      stroke='#FF002B'
                                                      className='w-5 h-5'
                                                    >
                                                      <path strokeLinecap='round' strokeLinejoin='round' d='M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z' />
                                                    </svg>
                                                    {data?.buyer_info?.type == 'individual' ?
                                                      <p className='text-sm pl-[10px]'>
                                                        {data.buyer_info.first_name + ' ' + data.buyer_info.last_name}
                                                      </p>:
                                                      <p className='text-sm pl-[10px]'>
                                                        {data?.buyer_info?.name}
                                                      </p>
                                                    }
                                                  </div>
                                                  <div className='flex ml-1'>
                                                    <img
                                                      src={Mail}
                                                      alt=''
                                                      className=''
                                                    />
                                                    <p className='text-sm pl-[10px]'>
                                                      {data.buyer_info.email}
                                                    </p>
                                                  </div>
                                                  <div className='flex ml-1'>
                                                    <img
                                                      src={Phone}
                                                      alt=''
                                                      className=''
                                                    />
                                                    <p className='text-sm pl-[10px]'>
                                                      {data.buyer_info.phone}
                                                    </p>
                                                  </div>
                                                  <div className='flex'>
                                                    <svg
                                                      xmlns='http://www.w3.org/2000/svg'
                                                      fill='none'
                                                      viewBox='0 0 24 24'
                                                      strokeWidth='2'
                                                      stroke='#FF002B'
                                                      className='w-5 h-5'
                                                    >
                                                      <path
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        d='M15 10.5a3 3 0 11-6 0 3 3 0 016 0z'
                                                      />
                                                      <path
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        d='M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z'
                                                      />
                                                    </svg>
                                                    {/* <svg width='24' height='24' viewBox='0 0 24 24' fill='none' className=''><path d='M12 2a8 8 0 00-8 8c0 5.41 7.07 11.24 7.37 11.49L12 22l.63-.51C12.93 21.24 20 15.41 20 10a8 8 0 00-8-8zm0 17.39c-2.13-1.9-6-6.05-6-9.39a6 6 0 1112 0c0 3.63-4.35 7.9-6 9.39z' fill='#333'></path><path d='M12 6a4 4 0 100 8 4 4 0 000-8zm0 6a2 2 0 110-4 2 2 0 010 4z' fill='#333'></path></svg> */}
                                                    <p className='text-sm pl-[10px]'>
                                                      {data.buyer_info.address}
                                                    </p>
                                                  </div>
                                                </div>
                                              )}
                                            </>
                                          ) : (
                                            <div className='space-y-3'>
                                              <div className='flex'>
                                                <svg
                                                  xmlns='http://www.w3.org/2000/svg'
                                                  fill='none'
                                                  viewBox='0 0 24 24'
                                                  strokeWidth='1.5'
                                                  stroke='#FF002B'
                                                  className='w-5 h-5'
                                                >
                                                  <path strokeLinecap='round' strokeLinejoin='round' d='M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z' />
                                                </svg>
                                                {data?.seller_info?.type == 'individual' ?
                                                  <p className='text-sm pl-[10px]'>
                                                    {data.seller_info.first_name + ' ' + data.seller_info.last_name}
                                                  </p>:
                                                  <p className='text-sm pl-[10px]'>
                                                    {data?.seller_info?.name}
                                                  </p>
                                                }
                                              </div>
                                              <div className='flex ml-1'>
                                                <img
                                                  src={Mail}
                                                  alt=''
                                                  className=''
                                                />
                                                <p className='text-sm pl-[10px]'>
                                                  {data.seller_info.email}
                                                </p>
                                              </div>
                                              <div className='flex ml-1'>
                                                <img
                                                  src={Phone}
                                                  alt=''
                                                  className=''
                                                />
                                                <p className='text-sm pl-[10px]'>
                                                  {data.seller_info.phone}
                                                </p>
                                              </div>
                                              <div className='flex'>
                                                <svg
                                                  xmlns='http://www.w3.org/2000/svg'
                                                  fill='none'
                                                  viewBox='0 0 24 24'
                                                  strokeWidth='1.5'
                                                  stroke='#FF002B'
                                                  className='w-4 h-4'
                                                >
                                                  <path
                                                    strokeLinecap='round'
                                                    strokeLinejoin='round'
                                                    d='M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25'
                                                  />
                                                </svg>
                                                <p className='text-sm pl-[10px]'>
                                                  {data.seller_info.address}
                                                </p>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                            <div className='sm:flex justify-between mx-4 border-t border-darkGray p-2'>
                              <div className='flex justify-center sm:justify-start space-x-1 mt-4 sm:mt-0'>
                                <p className='text-sm self-center'>
                                  {t('dashboard.clientsSection.propertyVisitors')}{' '}
                                </p>
                                <span className='flex space-x-1 font-bold'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 24 24'
                                    strokeWidth='2'
                                    stroke='currentColor'
                                    className='w-6 h-6 self-center'
                                  >
                                    <path
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                      d='M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z'
                                    />
                                    <path
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                      d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
                                    />
                                  </svg>
                                  <p className='flex mt-0.5 self-center'>
                                    {' '}
                                    {data.property_on_auction.views} {t('dashboard.clientsSection.views')}{' '}
                                  </p>
                                </span>
                              </div>
                              <div className='flex justify-center sm:justify-end mx-3'>
                                <button
                                  className='mt-2 w-full sm:w-auto sm:px-4 py-2 bg-auctionicRed text-white rounded-md hover:bg-gray-200 hover:text-darkGray transition-all ease-out duration-150'
                                  onClick={() =>
                                    handleNavigation(data.property_on_auction)
                                  }
                                >
                                  {t('dashboard.clientsSection.seeProperty')}
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <PropertyNotFound name={t('dashboard.clientsSection.noAgreementsFound')} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export { Clients };
