import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import img1 from '../../images/price-list-main.jpg';
import img2 from '../../images/price-list.jpg';

const PriceList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className='space-y-9 lg:h-[1050px] md:h-[1050px] h-[900px]'>
      <div>
        <div className='hidden sm:block relative bg-auctionicRed h-[400px]'>
          <img className=' object-cover sm:block relative w-full h-full opacity-60' src={img2} alt="" />
          <h1 className='absolute bottom-10 lg:left-[20%] md:left-[10%] left-[5%] text-white font-bold lg:text-7xl md:text-6xl text-5xl'>{t('listingItem.priceList.listingPackages')}</h1>
        </div>
        
        {/* phone view */}
        <div className='sm:hidden relative bg-auctionicRed'>
          <img className='relative object-cover w-full h-full opacity-60' src={img1} alt="" />
          <h1 className='absolute bottom-10 left-[5%] text-white font-bold text-4xl'>{t('listingItem.priceList.listingPackages')}</h1>
        </div>
      </div>

      <div className='lg:px-[20%] md:px-[10%] px-[5%]'>
        {t('listingItem.priceList.rangeOfPackages')}
      </div>
      <div className='grid grid-cols-3 lg:px-[20%] md:px-[10%] px-[5%] text-center'>
        <div>
          <ul>
            <li className='font-extrabold'>{t('listingItem.priceList.duration')}</li>
            <li>15 {t('listingItem.priceList.days')}</li>
            <li>30 {t('listingItem.priceList.days')}</li>
            <li>60 {t('listingItem.priceList.days')}</li>
            <li>90 {t('listingItem.priceList.days')}</li>
          </ul>
        </div>
        <div>
          <ul>
            <li className='font-extrabold'>{t('listingItem.priceList.from')}</li>
            <li>CHF 109.-</li>
            <li>CHF 159.-</li>
            <li>CHF 209.-</li>
            <li>CHF 279.-</li>
          </ul>
        </div>
        <div>
          <ul>
            <li className='font-extrabold'>{t('listingItem.priceList.to')}</li>
            <li>CHF 279.-</li>
            <li>CHF 459.-</li>
            <li>CHF 699.-</li>
            <li>CHF 799.-</li>
          </ul>
        </div>
      </div>
      <div className='italic lg:px-[20%] md:px-[10%] px-[5%]'>
        {t('listingItem.priceList.prices')}
      </div>
      <div className='flex justify-center pb-9'>
        <button
          onClick={() => navigate('/listingItem', {
            state: { type: 'auction' },
          })}
          className='bg-auctionicRed text-white hover:bg-darkGray hover:text-white text-lg px-3 py-2 rounded-lg'>{t('listingItem.priceList.createListing')}
        </button>
      </div>
    </div>
  );
};

export { PriceList };
