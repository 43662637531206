import React from 'react';
import { useTranslation } from 'react-i18next';


const CleaningAndHygiene = () => {
  const { t } = useTranslation();
  return (
    <div className='sm:my-[115px] my-10'>
      
      <div className='text-xl sm:text-[45px] text-center'>
        {t('services.cleaningAndHygieneServices')}
      </div>

      <div className='text-lg sm:text-[35px] text-center sm:mt-4 mt-2'>
        {t('services.cleaningENZLER.header_enzler')}
      </div>
      
      <div className='static pt-7 text-base sm-block'>
        <img className='hidden sm:block' src='https://www.enzler.com/media/wq2ax4c1/unternehmen-sauberkeit.jpg?width=1920&lazyload=false&height=645&preferFocalPoint=false&useCropDimensions=false&maxwidth=3840&maxheight=2500&quality=80&format=jpg&lazyloadPixelated=false&c.focalPoint=0.5%2C0.5&mode=crop&c.finalmode=crop&c.zoom=false' alt="" />
        <div className="hidden sm:block absolute mt-[-120px] ml-[60px] bg-[#669dc9] h-auto w-auto max-h-[270px] max-w-[500px] p-4">
          <h1 className='text-white font-extrabold text-2xl'>{t('services.cleaningENZLER.header_enzler')}</h1>
          <h1 className='text-white'>{t('services.cleaningENZLER.header2')}</h1>
          <p className='text-white text-sm pt-4'>
            {t('services.cleaningENZLER.paragraph1')}
            {/* Cleaning and hygiene are crucial success factors with ever increasing importance.
             The name Enzler has stood for quality and reliability for over 85 years.
             We set standards for cleaning and hygiene services with high hygienic and regulatory requirements,
             which require in-depth specialist knowledge,
             tried and tested experience and constant further training.
             Developing and implementing tailor-made, customer-specific solutions are important foundations of our success. */}
          </p>
        </div>
      </div>

      {/* phone */}
      <div className='sm:hidden'>
        <img src='https://www.enzler.com/media/wq2ax4c1/unternehmen-sauberkeit.jpg?width=1920&lazyload=false&height=645&preferFocalPoint=false&useCropDimensions=false&maxwidth=3840&maxheight=2500&quality=80&format=jpg&lazyloadPixelated=false&c.focalPoint=0.5%2C0.5&mode=crop&c.finalmode=crop&c.zoom=false' alt="" />
        <div className="bg-[#669dc9] h-full w-full p-4">
          <h1 className='text-white font-extrabold text-lg'> {t('services.cleaningENZLER.header_enzler')}</h1>
          <h1 className='text-white'>{t('services.cleaningENZLER.header2')}</h1>
          <p className='text-white text-sm pt-4'>
            {t('services.cleaningENZLER.paragraph1')}
          </p>
        </div>
      </div>
      
      <div className='sm:pt-[180px] pt-[30px] grid sm:grid-cols-2 grid-cols-1'>
        <div className='grid self-center sm:mr-7'>
          <h1 className='font-extrabold text-2xl'>{t('services.cleaningENZLER.section1Header')}</h1>
          <p className='text-sm pt-4'>
            {t('services.cleaningENZLER.section1Paragraph')}
          </p>
          <div className='bg-[#669dc9] hover:bg-[#2a78b8] rounded-full w-[140px] text-center text-white sm:mt-4 my-4 p-2' >
            <a href="https://www.enzler.com/de/">{t('services.cleaningENZLER.learnMore')}</a>
          </div>
        </div>
        <div>
          <img src='https://www.enzler.com/media/adbklm50/nachhaltigkeit.jpg?mode=crop&width=960&lazyload=false&height=768&preferFocalPoint=false&useCropDimensions=false&maxwidth=3840&maxheight=2500&quality=80&format=jpg&lazyloadPixelated=false&c.focalPoint=0.501666666666667%2C0.313575525812619&c.finalmode=crop&c.zoom=false' alt="" />
        </div>
      </div>
      
      <div className='pt-10 grid sm:grid-cols-2 grid-cols-1'>
        <div className='hidden sm:block'>
          <img src='https://www.enzler.com/media/sl4pa1ut/qualitaet.jpg?mode=crop&width=960&lazyload=false&height=768&preferFocalPoint=false&useCropDimensions=false&maxwidth=3840&maxheight=2500&quality=80&format=jpg&lazyloadPixelated=false&c.focalPoint=0.5%2C0.5&c.finalmode=crop&c.zoom=false' alt="" />
        </div>
        <div className='grid self-center sm:ml-7'>
          <h1 className='font-extrabold text-2xl'>{t('services.cleaningENZLER.section2Header')}</h1>
          <p className='text-sm pt-4'>
            {t('services.cleaningENZLER.section2Paragraph')}
          </p>
          <div className='bg-[#669dc9] hover:bg-[#2a78b8] rounded-full w-[140px] text-center text-white sm:mt-4 my-4 p-2' >
            <a href="https://www.enzler.com/de/">{t('services.cleaningENZLER.learnMore')}</a>
          </div>
        </div>
        {/* phone */}
        <div className='sm:hidden'>
          <img src='https://www.enzler.com/media/sl4pa1ut/qualitaet.jpg?mode=crop&width=960&lazyload=false&height=768&preferFocalPoint=false&useCropDimensions=false&maxwidth=3840&maxheight=2500&quality=80&format=jpg&lazyloadPixelated=false&c.focalPoint=0.5%2C0.5&c.finalmode=crop&c.zoom=false' alt="" />
        </div>
      </div>

      <div className='pt-10 grid sm:grid-cols-2 grid-cols-1'>
        <div className='grid self-center sm:mr-7'>
          <h1 className='font-extrabold text-2xl'>{t('services.cleaningENZLER.section3Header')}</h1>
          <p className='text-sm pt-4'>
            {t('services.cleaningENZLER.section3Paragraph')}
          </p>
          <div className='bg-[#669dc9] hover:bg-[#2a78b8] rounded-full w-[140px] text-center text-white sm:mt-4 my-4 p-2' >
            <a href="https://www.enzler.com/de/">{t('services.cleaningENZLER.learnMore')}</a>
          </div>
        </div>
        <div>
          <img src='https://www.enzler.com/media/yppp3cdj/umwelt.jpg?mode=crop&width=960&lazyload=false&height=768&preferFocalPoint=false&useCropDimensions=false&maxwidth=3840&maxheight=2500&quality=80&format=jpg&lazyloadPixelated=false&c.focalPoint=0.501666666666667%2C0.565&c.finalmode=crop&c.zoom=false' alt="" />
        </div>
      </div>

      <div className='grid justify-center'>
        <div className='bg-[#669dc9] hover:bg-[#2a78b8] rounded-full w-[170px] text-2xl text-center text-white p-4 sm:mt-[100px] mt-10' >
          <a href="https://www.enzler.com/de/">{t('services.cleaningENZLER.visitUs')}</a>
        </div>
      </div>
    
    </div>
  );
};

export { CleaningAndHygiene };
