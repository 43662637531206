import React, { useState } from 'react';
import SilverBanner from '../../images/plan_silver.svg';
import GoldBanner from '../../images/plan_gold.svg';
import PlatinumBanner from '../../images/plan_plat.svg';
import Stepper from './Stepper';
import { useNavigate } from 'react-router-dom';

interface Props {
  setPaymentTypeData: any;
  paymentTypeData: any;
  handleClickNext: any;
  steps: any;
  t: any;
  handleClickPrev: any;
  silverPrice:any;
  goldPrice:any;
  platinumPrice:any;
  handleShowPriceSilver:any;
  handleShowPriceGold:any;
  handleShowPricePlatinum:any;
  showRadio:any;
  rentPlans:any;
  handleChoice:any;
}

const SubscriptionRent = (props: Props) => {
  const {
    handleClickNext,
    steps,
    t,
    handleClickPrev,
    paymentTypeData,
    setPaymentTypeData,
    silverPrice,
    goldPrice,
    platinumPrice,
    handleShowPriceSilver,
    handleShowPriceGold,
    handleShowPricePlatinum,
    showRadio,
    rentPlans,
    handleChoice
  } = props;
  const [step] = useState(1);

  const navigate = useNavigate();
  return (
    <div className='bg-white sm:px-5 md:px-24 lg:px-24 px-2 sm:py-12 py-2 min-h-screen'>
      <div className='flex justify-between sm:pb-10 sm:pt-20 pb-5 pt-10'>
        <div className=''>
          <p className='text-[32px]'>{t('listingItem.createListing')}</p>
        </div>
        <div className='pt-2'>
          <button
            onClick={() => navigate(-1)}
            className='bg-darkGray w-[100px] h-[34px] text-center text-white rounded-md'
          >
            {t('listingItem.buttonCancel')}
          </button>
        </div>
      </div>
      <div className='border border-darkGray w-full ' />
      <div className=' grid grid-cols-1 sm:grid sm:grid-cols-1 md:grid md:grid-cols-1 lg:grid lg:grid-cols-2'>
        <div className='py-10'>
          <p className='text-[20px] pb-8 text-darkGray'>
            {t('listingItem.selectPlan')}
          </p>
          <div className='sm:px-0 space-y-16'>
            <div className='grid grid-cols-1 sm:space-y-10 space-y-20'>
              {}
              {/* Silver Card */}
              <div>
                <div
                  id='silverCard'
                  className={`' border cursor-pointer rounded-t-lg rounded-l-lg hover:scale-105 hover:ease-out duration-300 pt-4 sm:w-[468px] md:w-[468px] lg:w-[500px] leading-4' ${
                    showRadio === 'silver' ? 'bg-black scale-105' : 'bg-white'
                  }`}
                  onClick={() => handleChoice('silver')}
                >
                  <div className='pl-5'>
                    <div className=''>
                      <p
                        id='first'
                        className={
                          'text-[28px] font-serif ' +
                          (showRadio === 'silver' ? 'text-white' : 'text-darkGray')
                        }
                      >
                        {t('listingItem.silverPlan')}
                      </p>
                      <p
                        id='first'
                        className={
                          'text-[9px] ' +
                          (showRadio === 'silver' ? 'text-white' : 'text-darkGray')
                        }
                      >
                        {t('listingItem.silverHead')}
                      </p>
                    </div>
                    <div className='flex justify-between'>
                      <div
                        className={`'block sm:flex sm:space-x-7 ' ${
                          showRadio === 'silver' ? 'text-white' : 'text-darkGray'
                        }`}
                      >
                        <div className='pt-4 sm:pt-8 flex'>
                          <svg
                            id='second'
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 512 512'
                            className='h-6 w-6'
                            fill={showRadio === 'silver' ? 'red' : 'black'}
                          >
                            <path d='M447.1 32h-384C28.64 32-.0091 60.65-.0091 96v320c0 35.35 28.65 64 63.1 64h384c35.35 0 64-28.65 64-64V96C511.1 60.65 483.3 32 447.1 32zM111.1 96c26.51 0 48 21.49 48 48S138.5 192 111.1 192s-48-21.49-48-48S85.48 96 111.1 96zM446.1 407.6C443.3 412.8 437.9 416 432 416H82.01c-6.021 0-11.53-3.379-14.26-8.75c-2.73-5.367-2.215-11.81 1.334-16.68l70-96C142.1 290.4 146.9 288 152 288s9.916 2.441 12.93 6.574l32.46 44.51l93.3-139.1C293.7 194.7 298.7 192 304 192s10.35 2.672 13.31 7.125l128 192C448.6 396 448.9 402.3 446.1 407.6z' />
                          </svg>
                          <span id='first' className='text-[14px] pl-4'>
                            5 {t('listingItem.pictures')}
                          </span>
                        </div>
                        <div className='pt-4 sm:pt-8 flex'>
                          <svg
                            id='second'
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 384 512'
                            className='w-6 h-6'
                            fill={showRadio == 'silver' ? 'red' : 'black'}
                          >
                            <path d='M88 304H80V256H88C101.3 256 112 266.7 112 280C112 293.3 101.3 304 88 304zM192 256H200C208.8 256 216 263.2 216 272V336C216 344.8 208.8 352 200 352H192V256zM224 0V128C224 145.7 238.3 160 256 160H384V448C384 483.3 355.3 512 320 512H64C28.65 512 0 483.3 0 448V64C0 28.65 28.65 0 64 0H224zM64 224C55.16 224 48 231.2 48 240V368C48 376.8 55.16 384 64 384C72.84 384 80 376.8 80 368V336H88C118.9 336 144 310.9 144 280C144 249.1 118.9 224 88 224H64zM160 368C160 376.8 167.2 384 176 384H200C226.5 384 248 362.5 248 336V272C248 245.5 226.5 224 200 224H176C167.2 224 160 231.2 160 240V368zM288 224C279.2 224 272 231.2 272 240V368C272 376.8 279.2 384 288 384C296.8 384 304 376.8 304 368V320H336C344.8 320 352 312.8 352 304C352 295.2 344.8 288 336 288H304V256H336C344.8 256 352 248.8 352 240C352 231.2 344.8 224 336 224H288zM256 0L384 128H256V0z' />
                          </svg>
                          <span id='first' className='text-[14px] pl-4'>
                            PDF
                          </span>
                        </div>
                      </div>
                      <div className='float-right'>
                        <img src={SilverBanner} alt='' className='' />
                      </div>
                    </div>
                  </div>
                </div>
                {showRadio === 'silver' ? (
                  <div>
                    <div className='flex  space-x-5 pt-14'>
                      <div className='flex space-x-2'>
                        <label className='label'>
                          {rentPlans?.silver[15].duration} {t('listingItem.days')}
                          <input
                            id='offer1'
                            type='radio'
                            name='days'
                            value={rentPlans?.silver[15].price}
                            checked={paymentTypeData?.payment.plan_id === rentPlans?.silver[15].id}
                            className='h-6 w-6'
                            onChange={handleShowPriceSilver}
                            onClick={() =>
                              setPaymentTypeData((prev: any) => ({
                                ...prev,
                                duration: rentPlans?.silver['15'].duration,
                                payment: { method:'bill', plan_id: rentPlans?.silver['15'].id, price: rentPlans?.silver['15'].price, discount: 0, tier: 'silver' }
                              }))
                            }

                          />
                          <span className='checkmark'></span>
                        </label>
                      </div>
                      <div className='flex space-x-2'>
                        <label className='label'>
                          {rentPlans?.silver[30].duration} {t('listingItem.days')}
                          <input
                            id='offer2'
                            type='radio'
                            name='days'
                            className='h-6 w-6'
                            value={rentPlans?.silver[30].price}
                            checked={paymentTypeData?.payment.plan_id === rentPlans?.silver[30].id}
                            onChange={handleShowPriceSilver}
                            onClick={() =>
                              setPaymentTypeData((prev: any) => ({
                                ...prev,
                                duration: rentPlans?.silver['30'].duration,
                                payment: { method:'bill', plan_id: rentPlans?.silver['30'].id, price: rentPlans?.silver['30'].price, discount: 0, tier: 'silver' }
                              }))
                            }
                          />
                          <span className='checkmark'></span>
                        </label>
                      </div>
                      <div className='flex space-x-2 '>
                        <label className='label'>
                          {rentPlans?.silver[60].duration} {t('listingItem.days')}
                          <input
                            id='offer3'
                            type='radio'
                            name='days'
                            className='h-6 w-6'
                            value={rentPlans?.silver[60].price}
                            checked={paymentTypeData?.payment.plan_id === rentPlans?.silver[60].id}
                            onChange={handleShowPriceSilver}
                            onClick={() =>
                              setPaymentTypeData((prev: any) => ({
                                ...prev,
                                duration: rentPlans?.silver['60'].duration,
                                payment: { method:'bill', plan_id: rentPlans?.silver['60'].id, price: rentPlans?.silver['60'].price, discount: 0, tier: 'silver' }
                              }))
                            }
                          />
                          <span className='checkmark'></span>
                        </label>
                      </div>
                      <div className='flex space-x-2 '>
                        <label className='label'>
                          {rentPlans?.silver[90].duration} {t('listingItem.days')}
                          <input
                            id='offer3'
                            type='radio'
                            name='days'
                            className='h-6 w-6'
                            value={rentPlans?.silver[90].price}
                            checked={paymentTypeData?.payment.plan_id === rentPlans?.silver[90].id}
                            onChange={handleShowPriceSilver}
                            onClick={() =>
                              setPaymentTypeData((prev: any) => ({
                                ...prev,
                                duration: rentPlans?.silver['90'].duration,
                                payment: { method:'bill', plan_id: rentPlans?.silver['90'].id, price: rentPlans?.silver['90'].price, discount: 0, tier: 'silver' }
                              }))
                            }
                          />
                          <span className='checkmark'></span>
                        </label>
                      </div>
                    </div>
                    <div className='border-b-2 border-red-500 pt-2 text-2xl'>CHF {silverPrice}.00</div>
                  </div>
                ) : null}
              </div>
              {/* Gold Card */}
              <div>
                <div
                  id='goldCard'
                  className={`' border cursor-pointer rounded-t-lg rounded-l-lg hover:scale-105 hover:ease-out duration-300 pt-4 sm:w-[468px] md:w-[468px] lg:w-[500px] leading-4' ${
                    showRadio === 'gold' ? 'bg-black scale-105' : 'bg-white'
                  }`}
                  onClick={() => handleChoice('gold')}
                >
                  <div className='pl-5'>
                    <div className=''>
                      <p
                        id='first'
                        className={
                          'text-[28px] font-serif ' +
                          (showRadio === 'gold' ? 'text-white' : 'text-darkGray')
                        }
                      >
                        {' '}
                        {t('listingItem.goldPlan')}
                      </p>
                      <p
                        id='first'
                        className={
                          'text-[9px] ' +
                          (showRadio === 'gold' ? 'text-white' : 'text-darkGray')
                        }
                      >
                        {t('listingItem.goldHead')}
                      </p>
                    </div>
                    <div
                      className={`'block sm:flex sm:space-x-7 leading-4' ${
                        showRadio === 'gold' ? 'text-white' : 'text-darkGray'
                      }`}
                    >
                      <div className='pt-4 sm:pt-8 flex'>
                        <svg
                          id='second'
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 512 512'
                          className='h-6 w-6'
                          fill={showRadio === 'gold' ? 'red' : 'black'}
                        >
                          <path d='M447.1 32h-384C28.64 32-.0091 60.65-.0091 96v320c0 35.35 28.65 64 63.1 64h384c35.35 0 64-28.65 64-64V96C511.1 60.65 483.3 32 447.1 32zM111.1 96c26.51 0 48 21.49 48 48S138.5 192 111.1 192s-48-21.49-48-48S85.48 96 111.1 96zM446.1 407.6C443.3 412.8 437.9 416 432 416H82.01c-6.021 0-11.53-3.379-14.26-8.75c-2.73-5.367-2.215-11.81 1.334-16.68l70-96C142.1 290.4 146.9 288 152 288s9.916 2.441 12.93 6.574l32.46 44.51l93.3-139.1C293.7 194.7 298.7 192 304 192s10.35 2.672 13.31 7.125l128 192C448.6 396 448.9 402.3 446.1 407.6z' />
                        </svg>
                        <span id='first' className='text-[14px] pl-4'>
                          10 {t('listingItem.pictures')} / videos
                        </span>
                      </div>
                      <div
                        className={`'pt-4 sm:pt-8 flex leading-4' ${
                          showRadio === 'gold' ? 'text-white' : 'text-darkGray'
                        }`}
                      >
                        <svg
                          id='second'
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 384 512'
                          className='w-6 h-6'
                          fill={showRadio === 'gold' ? 'red' : 'black'}
                        >
                          <path d='M88 304H80V256H88C101.3 256 112 266.7 112 280C112 293.3 101.3 304 88 304zM192 256H200C208.8 256 216 263.2 216 272V336C216 344.8 208.8 352 200 352H192V256zM224 0V128C224 145.7 238.3 160 256 160H384V448C384 483.3 355.3 512 320 512H64C28.65 512 0 483.3 0 448V64C0 28.65 28.65 0 64 0H224zM64 224C55.16 224 48 231.2 48 240V368C48 376.8 55.16 384 64 384C72.84 384 80 376.8 80 368V336H88C118.9 336 144 310.9 144 280C144 249.1 118.9 224 88 224H64zM160 368C160 376.8 167.2 384 176 384H200C226.5 384 248 362.5 248 336V272C248 245.5 226.5 224 200 224H176C167.2 224 160 231.2 160 240V368zM288 224C279.2 224 272 231.2 272 240V368C272 376.8 279.2 384 288 384C296.8 384 304 376.8 304 368V320H336C344.8 320 352 312.8 352 304C352 295.2 344.8 288 336 288H304V256H336C344.8 256 352 248.8 352 240C352 231.2 344.8 224 336 224H288zM256 0L384 128H256V0z' />
                        </svg>
                        <span id='first' className='text-[14px] pl-4'>
                          2 PDF
                        </span>
                      </div>
                    </div>
                    <div className='flex justify-between'>
                      <div
                        className={`'block sm:flex sm:space-x-8 leading-4' ${
                          showRadio === 'gold' ? 'text-white' : 'text-darkGray'
                        }`}
                      >
                        <div className='flex pt-4 sm:pt-8'>
                          <svg
                            id='second'
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 448 512'
                            className='w-6 h-6'
                            fill={showRadio === 'gold' ? 'red' : 'black'}
                          >
                            <path d='M160 80C160 53.49 181.5 32 208 32H240C266.5 32 288 53.49 288 80V432C288 458.5 266.5 480 240 480H208C181.5 480 160 458.5 160 432V80zM0 272C0 245.5 21.49 224 48 224H80C106.5 224 128 245.5 128 272V432C128 458.5 106.5 480 80 480H48C21.49 480 0 458.5 0 432V272zM400 96C426.5 96 448 117.5 448 144V432C448 458.5 426.5 480 400 480H368C341.5 480 320 458.5 320 432V144C320 117.5 341.5 96 368 96H400z' />
                          </svg>
                          <span id='first' className='text-[14px] pl-4'>
                            {t('listingItem.ad_statistic')}
                          </span>
                        </div>
                      </div>
                      <div className='float-right'>
                        <img src={GoldBanner} alt='' className='' />
                      </div>
                    </div>
                  </div>
                </div>
                {showRadio === 'gold' ? (
                  <div>
                    <div className='flex  space-x-5 pt-14'>
                      <div className='flex space-x-2'>
                        <label className='label'>
                          {rentPlans?.gold[15].duration} {t('listingItem.days')}
                          <input
                            id='offer1'
                            type='radio'
                            name='days'
                            value={rentPlans?.gold[15].price}
                            checked={paymentTypeData?.payment.plan_id === rentPlans?.gold[15].id}
                            className='h-6 w-6'
                            onChange={handleShowPriceGold}
                            onClick={() =>
                              setPaymentTypeData((prev: any) => ({
                                ...prev,
                                duration: rentPlans?.gold['15'].duration,
                                payment: { method:'bill', plan_id: rentPlans?.gold['15'].id, price: rentPlans?.gold['15'].price, discount: 0, tier: 'gold' }

                              }))
                            }
                          />
                          <span className='checkmark'></span>
                        </label>
                      </div>
                      <div className='flex space-x-2'>
                        <label className='label'>
                          {rentPlans?.gold[30].duration} {t('listingItem.days')}
                          <input
                            id='offer2'
                            type='radio'
                            name='days'
                            className='h-6 w-6'
                            value={rentPlans?.gold[30].price}
                            checked={paymentTypeData?.payment.plan_id === rentPlans?.gold[30].id}
                            onChange={handleShowPriceGold}
                            onClick={() =>
                              setPaymentTypeData((prev: any) => ({
                                ...prev,
                                duration: rentPlans?.gold['30'].duration,
                                payment: { method:'bill', plan_id: rentPlans?.gold['30'].id, price: rentPlans?.gold['30'].price, discount: 0, tier: 'gold' }
                              }))
                            }
                          />
                          <span className='checkmark'></span>
                        </label>
                      </div>
                      <div className='flex space-x-2 '>
                        <label className='label'>
                          {rentPlans?.gold[60].duration} {t('listingItem.days')}
                          <input
                            id='offer3'
                            type='radio'
                            name='days'
                            className='h-6 w-6'
                            value={rentPlans?.gold[60].price}
                            checked={paymentTypeData?.payment.plan_id === rentPlans?.gold[60].id}
                            onChange={handleShowPriceGold}
                            onClick={() =>
                              setPaymentTypeData((prev: any) => ({
                                ...prev,
                                duration: rentPlans?.gold['60'].duration,
                                payment: { method:'bill', plan_id: rentPlans?.gold['60'].id, price: rentPlans?.gold['60'].price, discount: 0, tier: 'gold' }
                              }))
                            }
                          />
                          <span className='checkmark'></span>
                        </label>
                      </div>
                      <div className='flex space-x-2 '>
                        <label className='label'>
                          {rentPlans?.gold[90].duration} {t('listingItem.days')}
                          <input
                            id='offer3'
                            type='radio'
                            name='days'
                            className='h-6 w-6'
                            value={rentPlans?.gold[90].price}
                            checked={paymentTypeData?.payment.plan_id === rentPlans?.gold[90].id}
                            onChange={handleShowPriceGold}
                            onClick={() =>
                              setPaymentTypeData((prev: any) => ({
                                ...prev,
                                duration: rentPlans?.gold['90'].duration,
                                payment: { method:'bill', plan_id: rentPlans?.gold['90'].id, price: rentPlans?.gold['90'].price, discount: 0, tier: 'gold' }
                              }))
                            }
                          />
                          <span className='checkmark'></span>
                        </label>
                      </div>
                    </div>
                    <div className='border-b-2 border-red-500 pt-2 text-2xl'>CHF {goldPrice}.00</div>
                  </div>
                ) : null}
              </div>
              {/* Platinum */}
              <div>
                <div
                  onClick={() => handleChoice('platinum')}
                  id='platinumCard'
                  className={`' border cursor-pointer rounded-t-lg rounded-l-lg hover:scale-105 hover:ease-out duration-300 sm:w-[468px] md:w-[468px] lg:w-[500px] leading-4' ${
                    showRadio === 'platinum' ? 'bg-black scale-105' : 'bg-white'
                  }`}
                >
                  <div className='pl-5'>
                    <div className='flex justify-between'>
                      <div className='pt-4'>
                        <div className=''>
                          <p
                            id='first'
                            className={
                              'text-[28px] font-serif ' +
                              (showRadio === 'platinum'
                                ? 'text-white'
                                : 'text-darkGray')
                            }
                          >
                            {' '}
                            {t('listingItem.platinumPlan')}
                          </p>
                          <p
                            id='first'
                            className={
                              'text-[9px] ' +
                              (showRadio === 'platinum'
                                ? 'text-white'
                                : 'text-darkGray')
                            }
                          >
                            {t('listingItem.platinumHead')}
                          </p>
                        </div>

                        <div
                          className={`'block sm:flex sm:space-x-9 bg-transparent' ${
                            showRadio === 'platinum'
                              ? 'text-white'
                              : 'text-darkGray'
                          }`}
                        >
                          <div className='pt-4 sm:pt-6 flex'>
                            <svg
                              id='second'
                              xmlns='http://www.w3.org/2000/svg'
                              viewBox='0 0 512 512'
                              className='h-6 w-6'
                              fill={showRadio === 'platinum' ? 'red' : 'black'}
                            >
                              <path d='M447.1 32h-384C28.64 32-.0091 60.65-.0091 96v320c0 35.35 28.65 64 63.1 64h384c35.35 0 64-28.65 64-64V96C511.1 60.65 483.3 32 447.1 32zM111.1 96c26.51 0 48 21.49 48 48S138.5 192 111.1 192s-48-21.49-48-48S85.48 96 111.1 96zM446.1 407.6C443.3 412.8 437.9 416 432 416H82.01c-6.021 0-11.53-3.379-14.26-8.75c-2.73-5.367-2.215-11.81 1.334-16.68l70-96C142.1 290.4 146.9 288 152 288s9.916 2.441 12.93 6.574l32.46 44.51l93.3-139.1C293.7 194.7 298.7 192 304 192s10.35 2.672 13.31 7.125l128 192C448.6 396 448.9 402.3 446.1 407.6z' />
                            </svg>
                            <span id='first' className='text-[14px] pl-4'>
                              17 {t('listingItem.pictures')} / videos
                            </span>
                          </div>
                          <div className='pt-4 sm:pt-6 flex'>
                            <svg
                              id='second'
                              xmlns='http://www.w3.org/2000/svg'
                              viewBox='0 0 384 512'
                              className='w-6 h-6'
                              fill={showRadio === 'platinum' ? 'red' : 'black'}
                            >
                              <path d='M88 304H80V256H88C101.3 256 112 266.7 112 280C112 293.3 101.3 304 88 304zM192 256H200C208.8 256 216 263.2 216 272V336C216 344.8 208.8 352 200 352H192V256zM224 0V128C224 145.7 238.3 160 256 160H384V448C384 483.3 355.3 512 320 512H64C28.65 512 0 483.3 0 448V64C0 28.65 28.65 0 64 0H224zM64 224C55.16 224 48 231.2 48 240V368C48 376.8 55.16 384 64 384C72.84 384 80 376.8 80 368V336H88C118.9 336 144 310.9 144 280C144 249.1 118.9 224 88 224H64zM160 368C160 376.8 167.2 384 176 384H200C226.5 384 248 362.5 248 336V272C248 245.5 226.5 224 200 224H176C167.2 224 160 231.2 160 240V368zM288 224C279.2 224 272 231.2 272 240V368C272 376.8 279.2 384 288 384C296.8 384 304 376.8 304 368V320H336C344.8 320 352 312.8 352 304C352 295.2 344.8 288 336 288H304V256H336C344.8 256 352 248.8 352 240C352 231.2 344.8 224 336 224H288zM256 0L384 128H256V0z' />
                            </svg>
                            <span id='first' className='text-[14px] pl-4'>
                              3 PDF
                            </span>
                          </div>
                        </div>
                        <div
                          className={`'block sm:flex sm:space-x-9 bg-transparent' ${
                            showRadio === 'platinum'
                              ? 'text-white'
                              : 'text-darkGray'
                          }`}
                        >
                          <div className='pt-4 sm:pt-8 flex'>
                            <svg
                              id='second'
                              xmlns='http://www.w3.org/2000/svg'
                              viewBox='0 0 448 512'
                              className='w-6 h-6'
                              fill={showRadio === 'platinum' ? 'red' : 'black'}
                            >
                              <path d='M160 80C160 53.49 181.5 32 208 32H240C266.5 32 288 53.49 288 80V432C288 458.5 266.5 480 240 480H208C181.5 480 160 458.5 160 432V80zM0 272C0 245.5 21.49 224 48 224H80C106.5 224 128 245.5 128 272V432C128 458.5 106.5 480 80 480H48C21.49 480 0 458.5 0 432V272zM400 96C426.5 96 448 117.5 448 144V432C448 458.5 426.5 480 400 480H368C341.5 480 320 458.5 320 432V144C320 117.5 341.5 96 368 96H400z' />
                            </svg>
                            <span id='first' className='text-[14px] pl-4'>
                              {t('listingItem.ad_statistic')}
                            </span>
                          </div>
                          <div
                            className={`'pt-4 sm:pt-7 flex bg-transparent ' ${
                              showRadio === 'platinum'
                                ? 'text-white'
                                : 'text-darkGray'
                            }`}
                          >
                            <svg
                              id='second'
                              xmlns='http://www.w3.org/2000/svg'
                              viewBox='0 0 576 512'
                              className='w-6 h-6'
                              fill={showRadio === 'platinum' ? 'red' : 'black'}
                            >
                              <path d='M528 0h-480C21.5 0 0 21.5 0 48v320C0 394.5 21.5 416 48 416h192L224 464H152C138.8 464 128 474.8 128 488S138.8 512 152 512h272c13.25 0 24-10.75 24-24s-10.75-24-24-24H352L336 416h192c26.5 0 48-21.5 48-48v-320C576 21.5 554.5 0 528 0zM512 352H64V64h448V352z' />
                            </svg>
                            <span id='first' className='text-[14px] pl-4'>
                              {t('listingItem.social_media')}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='flex justify-end'>
                        <img src={PlatinumBanner} alt='' className='h-fit' />
                      </div>
                    </div>
                  </div>
                </div>
                {showRadio === 'platinum' ? (
                  <div>
                    <div className='flex  space-x-5 pt-14'>
                      <div className='flex space-x-2'>
                        <label className='label'>
                          {rentPlans?.platinum[15].duration} {t('listingItem.days')}
                          <input
                            id='offer1'
                            type='radio'
                            name='days'
                            className='h-6 w-6'
                            value={rentPlans?.platinum[15].price}
                            checked={paymentTypeData?.payment?.plan_id === rentPlans?.platinum[15].id}
                            onChange={handleShowPricePlatinum}
                            onClick={() =>
                              setPaymentTypeData((prev: any) => ({
                                ...prev,
                                duration: rentPlans?.platinum['15'].duration,
                                payment: { method:'bill', plan_id: rentPlans?.platinum['15'].id, price: rentPlans?.platinum['15'].price, discount: 0, tier: 'platinum' }
                              }))
                            }
                          />
                          <span className='checkmark'></span>
                        </label>
                      </div>
                      <div className='flex space-x-2'>
                        <label className='label'>
                          {rentPlans?.platinum[30].duration} {t('listingItem.days')}
                          <input
                            id='offer2'
                            type='radio'
                            name='days'
                            className='h-6 w-6'
                            value={rentPlans?.platinum[30].price}
                            checked={paymentTypeData?.payment?.plan_id === rentPlans?.platinum[30].id}
                            onChange={handleShowPricePlatinum}
                            onClick={() =>
                              setPaymentTypeData((prev: any) => ({
                                ...prev,
                                duration: rentPlans?.platinum['30'].duration,
                                payment: { method:'bill', plan_id: rentPlans?.platinum['30'].id, price: rentPlans?.platinum['30'].price, discount: 0, tier: 'platinum' }                              }))
                            }
                          />
                          <span className='checkmark'></span>
                        </label>
                      </div>
                      <div className='flex space-x-2 '>
                        <label className='label'>
                          {rentPlans?.platinum[60].duration} {t('listingItem.days')}
                          <input
                            id='offer3'
                            type='radio'
                            name='days'
                            className='h-6 w-6'
                            value={rentPlans?.platinum[60].price}
                            checked={paymentTypeData?.payment.plan_id === rentPlans?.platinum[60].id}
                            onChange={handleShowPricePlatinum}
                            onClick={() =>
                              setPaymentTypeData((prev: any) => ({
                                ...prev,
                                duration: rentPlans?.platinum['60'].duration,
                                payment: { method:'bill', plan_id: rentPlans?.platinum['60'].id, price: rentPlans?.platinum['60'].price, discount: 0, tier: 'platinum' }
                              }))
                            }
                          />
                          <span className='checkmark'></span>
                        </label>
                      </div>
                      <div className='flex space-x-2 '>
                        <label className='label'>
                          {rentPlans?.platinum[90].duration} {t('listingItem.days')}
                          <input
                            id='offer3'
                            type='radio'
                            name='days'
                            className='h-6 w-6'
                            value={rentPlans?.platinum[90].price}
                            checked={paymentTypeData?.payment.plan_id === rentPlans?.platinum[90].id}
                            onChange={handleShowPricePlatinum}
                            onClick={() =>
                              setPaymentTypeData((prev: any) => ({
                                ...prev,
                                duration: rentPlans?.platinum['90'].duration,
                                payment: { method:'bill', plan_id: rentPlans?.platinum['90'].id, price: rentPlans?.platinum['90'].price, discount: 0, tier: 'platinum' }
                              }))
                            }
                          />
                          <span className='checkmark'></span>
                        </label>
                      </div>
                    </div>
                    <div className='border-b-2 border-red-500 pt-2 text-2xl'>CHF {platinumPrice}.00</div>
                    <p className='text-[24px]'></p>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className='hidden sm:block'>
          <Stepper steps={steps} activeStep={step} />
        </div>
      </div>
      <div className='pt-12 flex justify-between'>
        <button
          className='hover:scale-125 hover:ease-in duration-300'
          onClick={handleClickPrev}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-6 w-6'
            fill='none'
            viewBox='0 0 24 24'
            stroke='black'
          >
            <path d='M10 19l-7-7m0 0l7-7m-7 7h18' />
          </svg>
        </button>
        {showRadio != '' ? (
          <button
            className='bg-darkGray w-[100px] h-[34px] text-center text-white rounded-md hover:scale-105 hover:ease-in duration-300 '
            onClick={handleClickNext}
          >
            {t('listingItem.buttonNext')}
          </button>
        ) : (
          <button
            disabled
            className='bg-gray-200 w-[100px] h-[34px] text-center text-white rounded-md hover:scale-105 hover:ease-in duration-300 '
          >
            {t('listingItem.buttonNext')}
          </button>
        )}
      </div>
      <div className='col-span-0 sm:col-span-1 block sm:hidden'>
        <Stepper steps={steps} activeStep={step} />
      </div>
    </div>
  );
};
export { SubscriptionRent };
