import { ReAdvertiseInput } from '../config';
import { auctionicApi, auctionicApiWa } from '../helpers/api';
import { headers } from '../helpers/headers';


const getAllProperties = () => {
  const config = { headers: headers.getHeaders() };
  return auctionicApi.get('/properties/active_property_on_auction', config);
};

const createProperty = (property: any, type: any) => {
  const body = { property, type };
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.post('/property', body, config);
};

const addImages = (propertyId: number, files: Array<File>) => {
  const formData = new FormData();
  files.forEach((file) => formData.append('images', file));
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.post(
    `/properties/images/${propertyId}`,
    formData,
    config
  );
};

const addDocuments = (propertyId: number, files: Array<File>) => {
  const formData = new FormData();
  files.forEach((file) => formData.append('documents', file));
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.post(
    `/properties/documents/${propertyId}`,
    formData,
    config
  );
};

const addVideo = (propertyId: number, files: Array<File>) => {
  const formData = new FormData();
  files.forEach((file) => formData.append('videos', file));
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.post(
    `/properties/videos/${propertyId}`,
    formData,
    config
  );
};

const getAuctionProperties = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get(`/properties/active_property_on_auction`, config);
};

const getRentProperties = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/properties/active_property_on_rent', config);
};

const getSaleProperties = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/properties/active_property_on_sale', config);
};

const getAuctionProperty = (id: number) => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get(`/property_auction/${id}`, config);
};

const getRentDetailsProperties = (id: number) => {
  return auctionicApiWa.get(`property_rent/${id}`);
};

const getRentDetailsPropertiesAuth = (id: number) => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get(`property_rent/${id}`, config);
};

const getSaleDetailsProperties = (id: number) => {
  return auctionicApiWa.get(`property_sale/${id}`);
};

const getSaleDetailsAuth = (id: number) => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApiWa.get(`property_sale/${id}`, config);
};

const getListingProperties = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/type/all', config);
};

const requestBid = (propertyId: any, price: any) => {
  const body = { property_on_auction_id: propertyId, price: parseInt(price) };
  const config = { headers: headers.getAuthHeaders() };

  return auctionicApi.post('/request_bid', body, config);
};

const confirmRequestBid = (propertyId: number, price: number, code: string) => {
  const body = { property_on_auction_id: propertyId, price: price, code: code };
  const config = { headers: headers.getAuthHeaders() };

  return auctionicApi.post('/confirm_request_bid', body, config);
};

const subscriptionAuction = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/plans/auction', config);
};

const subscriptionRent = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/plans/rent', config);
};

const subscriptionSale = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/plans/sale', config);
};

const getCharachteristics = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/char/all', config);
};

const addPropertyToFavourites = (propertyId: number) => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.post(`/favorite_property/${propertyId}`, {}, config);
};

const removePropertyToFavourites = (propertyId: number) => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.delete(`/favorite_property/${propertyId}`, config);
};

const getFavouriteProperties = (_id?: number) => {
  const config = { headers: headers.getAuthHeaders() };
  if (_id) {
    return auctionicApi.get(`/favorite_property/${_id}`, config);
  }
  return auctionicApi.get(`/favorite_property`, config);
};

const getTrendingProperties = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/property/trending', config);
};
const getPropertiesbyId = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/user/property_list', config);
};

const getFavoriteProperty = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/favorite_property', config);
};

const filterAuctionProperties = (data: any) => {
  const body = { data };
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.post('/property/auction/search', body, config);
};
const filterRentProperties = (data: any) => {
  const body = { data };
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.post('/property/rent/search', body, config);
};

const filterSaleProperties = (data: any) => {
  const body = { data };
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.post('/property/sale/search', body, config);
};

const getDiscount = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/discount', config);
};

const requestBuyNow = (data: any) => {
  const body = data;
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.post('/request_buy_now', body, config);
};

const confirmBuyNow = (data: any) => {
  const body = data;
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.post('/confirm_buy_now', body, config);
};

const requestAcceptBid = (data: any) => {
  const body = data;
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.post('/properties/request_accept_bid', body, config);
};

const confirmAcceptBid = (data: any) => {
  const body = data;
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.post('properties/confirm_accept_bid', body, config);
};

const requestDeclineBid = (data: any) => {
  const body = data;
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.post('/properties/request_decline_bid', body, config);
};

const confirmDeclineBid = (data: any) => {
  const body = data;
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.post('/properties/confirm_decline_bid', body, config);
};

const generateInvoice = (invoice: number) => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get(`/invoice/${invoice}`, config);
};

const editRentProperty = (propertyId: number, body: any) => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.post(`/property_rent/edit/${propertyId}`, body, config);
};

const editSaleProperty = (propertyId: number, body: any) => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.post(`/property_sale/edit/${propertyId}`, body, config);
};

const editAuctionProperty = (propertyId: number, body: any) => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.post(`/property_auction/edit/${propertyId}`, body, config);
};

const deactivateSaleProperty = (propertyId: number) => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get(`/deactivate/sale/${propertyId}`, config);
};

const reactivateSaleProperty = (propertyId: number) => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get(`/reactivate/sale/${propertyId}`, config);
};

const deactivateRentProperty = (propertyId: number) => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get(`/deactivate/rent/${propertyId}`, config);
};

const reactivateRentProperty = (propertyId: number) => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get(`/reactivate/rent/${propertyId}`, config);
};

const contactSale = (body: any) => {
  const config = { headers: headers.getHeaders() };
  return auctionicApi.post(`/client_to_client`, body, config);
};

const contactRent = (body: any) => {
  const config = { headers: headers.getHeaders() };
  return auctionicApi.post(`/client_to_client`, body, config);
};

const reAdvertise = (body: any) => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.post('/readvertise', body, config);
};

export const propertyServices = {
  getAllProperties,
  createProperty,
  addImages,
  getAuctionProperties,
  getRentProperties,
  getSaleProperties,
  getRentDetailsProperties,
  getAuctionProperty,
  getSaleDetailsProperties,
  getListingProperties,
  requestBid,
  confirmRequestBid,
  subscriptionAuction,
  subscriptionRent,
  subscriptionSale,
  getCharachteristics,
  addPropertyToFavourites,
  getFavouriteProperties,
  removePropertyToFavourites,
  getTrendingProperties,
  addDocuments,
  getPropertiesbyId,
  getFavoriteProperty,
  filterAuctionProperties,
  filterRentProperties,
  filterSaleProperties,
  getDiscount,
  addVideo,
  requestBuyNow,
  confirmBuyNow,
  requestAcceptBid,
  confirmAcceptBid,
  requestDeclineBid,
  confirmDeclineBid,
  generateInvoice,
  getRentDetailsPropertiesAuth,
  getSaleDetailsAuth,
  editRentProperty,
  editSaleProperty,
  editAuctionProperty,
  deactivateSaleProperty,
  deactivateRentProperty,
  reactivateRentProperty,
  reactivateSaleProperty,
  contactSale,
  contactRent,
  reAdvertise,
};
