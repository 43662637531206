import {
  createContext,
  useContext,
} from 'react';
import { User } from '../config';

interface AuthContextType {
    user: User;
    loading: boolean;
    token: string | null;
    error: any;
    auth: () => void;
    logout: () => void;
}

export const AuthContext = createContext<AuthContextType>(
    {} as AuthContextType
);

export default function useAuth() {
  return useContext(AuthContext);
}
