import React, { useEffect, useState } from 'react';
import './Mortgage.css';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MortgagePropertyCard } from './MortgagePropertyCard';
import { mortgageServices } from '../../services/mortgageServices';
import { GetMortgageInterest } from '../../config';
const Mortgage = () => {
  const [prevPropertyPrice, setPrevPropertyPrice] = useState('');
  const [totalPrice, setTotalPrice] = useState('');
  const [prevTotalPrice, setPrevTotalPrice] = useState('');
  const [ownFunds, setOwnFunds] = useState('');
  const [prevOwnFunds, setPrevOwnFunds] = useState('');
  const [propertyDetails, setPropertyDetails] = useState(null as any);
  const [annualIncome, setAnnualIncome] = useState('');
  const [prevAnnualIncome, setPrevAnnualIncome] = useState('');
  const [showCalculator, setShowCalculator] = useState(false);
  const [prevLimitOwn, setPrevLimitOwn] = useState('');
  const location: any = useLocation();
  const { t } = useTranslation();
  const [limitOwn, setLimitOwn] = useState(Number);
  const limit = (20 / 100) * Number(totalPrice);
  const [prevFirstMortgage, setPrevFirstMortgage] = useState('');
  const [prevSecondMortgage, setPrevSecondMortgage] = useState('');
  const [prevInterestCosts, setPrevInterestCosts] = useState('');
  const [prevAncillaryCosts, setPrevAncillaryCosts] = useState('');
  const [duration, setDuration] = useState('');
  const [interestRate, setInterestRate] = useState<Array<GetMortgageInterest>>();
  const [interes, setInterest]= useState(1);
  const [prevMonthlyMortgage, setPrevMonthlyMortgage] = useState('');
  const [prevMonthlyMaintenance, setPrevMonthlyMaintenance] = useState('');
  const [prevMonthlyTotal, setPrevMonthlyTotal] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    mortgageServices
      .getInterestRate()
      .then((response: any) => {
        setInterestRate(response.data);
        response.data?.filter((item:any)=> {
          if (item.number_of_years == 2) {
            setInterest(item.rate);
            setDuration('2');
          }
        });
        return response;
      })
      .catch((error: any) => {
        // console.log('Failed', error);
      });
  }, []);

  const handleDurationChange = (e:any) => {
    const { value } = e.target;
    interestRate?.filter((item:any)=> {
      if (item.number_of_years == value) {
        setInterest(item.rate);
      }
      setDuration(value);
    });
  };

  const rest = Number(totalPrice) - Number(ownFunds);
  const sixofRest = (6 / 100) * Number(rest);
  const afford = sixofRest * 3;
  const firstMortgage = (66 / 100) * Number(totalPrice);
  const secondMortgage = Number(totalPrice) - Number(ownFunds) - firstMortgage;
  const ancillaryCosts = (2.5 / 100) * Number(totalPrice);
  const monthlyMortgageCosts =((Number(rest) * (interes/100))/12);
  const monthlyMaintenanceCosts = (Number(totalPrice) * (1/100)) / 12;
  const totalMonthlyCosts = monthlyMortgageCosts + monthlyMaintenanceCosts;
  

  const interestCalculate = (interes / 100) * Number(rest);
  const interestCosts = interestCalculate * Number(duration);

  const handleFormattedInput = (e: any) => {
    const { name, value } = e.target;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // if (error.includes(name)) {
    //   setError(error.filter((e) => e !== name));
    // }
    if (name == 'minimumOwnFunds') {
      const formattedValue = (Number(value.replace(/\D/g, '')) || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g,'\'');
      const unformattedValue = (Number(value.replace(/\D/g, '')) || '').toString();
      setOwnFunds(unformattedValue);
      setPrevOwnFunds(formattedValue);
    }
    if (name == 'totalPrice') {
      const unformattedValue = (Number(value.replace(/\D/g, '')) || '').toString();
      setTotalPrice(unformattedValue);
      setTotalPrice((state) => {
        return state;
      });
    }
    if (name == 'annualIncome') {
      const formattedValue = (Number(value.replace(/\D/g, '')) || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g,'\'');
      const unformattedValue = (Number(value.replace(/\D/g, '')) || '').toString();
      setAnnualIncome(unformattedValue);
      setPrevAnnualIncome(formattedValue);
    }
  };

  useEffect(() => {
    if (location.state != null) {
      setPropertyDetails(location.state.property);
      handlePropertyDetailsPrice();
    }
  });

  useEffect(() => {
    setLimitOwn(limit); // Updating the limit after the total changes
  }, [limit]);

  useEffect(() => {
    const total = totalPrice;
    const formattedTotal = (Number(total.replace(/\D/g, '')) || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g,'\'');
    setPrevTotalPrice(formattedTotal); // Updating the preview on total
  }, [totalPrice]);

  useEffect(() => {
    const limit1 = limitOwn.toString().split('.');
    const formattedLimit = (Number(limit1[0].replace(/\D/g, '')) || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g,'\'');
    setPrevLimitOwn(formattedLimit); // Updating the preview of the limit
  }, [limitOwn]);

  useEffect(() => {
    const firstmortgage = firstMortgage.toString().split('.');
    const formattedFirstMortgage = (Number(firstmortgage[0].replace(/\D/g, '')) || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g,'\'');
    setPrevFirstMortgage(formattedFirstMortgage); // Updating the preview of the first mortgage
  }, [firstMortgage]);

  useEffect(() => {
    const secondmortgage = secondMortgage.toString().split('.');
    const formattedSecondMortgage = (Number(secondmortgage[0].replace(/\D/g, '')) || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g,'\'');
    setPrevSecondMortgage(formattedSecondMortgage); // Updating the preview of the second mortgage
  }, [secondMortgage]);

  useEffect(() => {
    const ownfunds = ownFunds.toString().split('.');
    const formattedOwnFunds = (Number(ownfunds[0].replace(/\D/g, '')) || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g,'\'');
    setPrevOwnFunds(formattedOwnFunds); // Updating the preview of the own funds
  }, [ownFunds]);

  useEffect(() => {
    const interestcosts = interestCosts.toString().split('.');
    const formattedInterestCosts = (Number(interestcosts[0].replace(/\D/g, '')) || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g,'\'');
    setPrevInterestCosts(formattedInterestCosts); // Updating the preview of the interest cost
  }, [interestCosts]);

  useEffect(() => {
    const ancillarycosts = ancillaryCosts.toString().split('.');
    const formattedAncillaryCosts = (Number(ancillarycosts[0].replace(/\D/g, '')) || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g,'\'');
    setPrevAncillaryCosts(formattedAncillaryCosts); // Updating the preview of the interest cost
  }, [ancillaryCosts]);

  useEffect(()=>{
    const monthlyMortgageCosts1 = monthlyMortgageCosts.toString().split('.');
    const formattedMonthlyMortgageCosts1 = (Number(monthlyMortgageCosts1[0].replace(/\D/g, '')) || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g,'\'');
    setPrevMonthlyMortgage(formattedMonthlyMortgageCosts1);
  },[monthlyMortgageCosts]);

  useEffect(()=>{
    const monthlyMaintenanceCosts1 = monthlyMaintenanceCosts.toString().split('.');
    const formattedMonthlyMaintenanceCosts1 = (Number(monthlyMaintenanceCosts1[0].replace(/\D/g, '')) || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g,'\'');
    setPrevMonthlyMaintenance(formattedMonthlyMaintenanceCosts1);
  },[monthlyMaintenanceCosts]);

  useEffect(()=>{
    const monthlyTotalCosts1 = totalMonthlyCosts.toString().split('.');
    const formattedMonthlyTotalCosts1 = (Number(monthlyTotalCosts1[0].replace(/\D/g, '')) || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g,'\'');
    setPrevMonthlyTotal(formattedMonthlyTotalCosts1);
  },[totalMonthlyCosts]);

  const handlePropertyDetailsPrice = () => {
    if (propertyDetails != null) {
      const value = propertyDetails.current_bid.toString();
      const formattedValue = (Number(value.replace(/\D/g, '')) || '').toLocaleString();
      const unformattedValue = (Number(value.replace(/\D/g, '')) || '').toString();
      setPrevPropertyPrice(formattedValue);
      setTotalPrice(unformattedValue);
    }
  };
  
  const ResultAfford = () => {
    const result = annualIncome;
    if (Number(result) < afford) {
      return (
        <div
          className='flex p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800'
          role='alert'
        >
          <svg
            aria-hidden='true'
            className='flex-shrink-0 inline w-5 h-5 mr-3'
            fill='currentColor'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z'
              clipRule='evenodd'
            ></path>
          </svg>
          <span className='sr-only'>{t('mortgage.info')}</span>
          <div>
            <span className='font-medium'>{t('mortgage.cannotAfford')}</span>
            {t('mortgage.withYourCurrent')}
          </div>
        </div>
      );
    } else {
      return (
        <div
          className='flex p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800'
          role='alert'
        >
          <svg
            aria-hidden='true'
            className='flex-shrink-0 inline w-5 h-5 mr-3'
            fill='currentColor'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z'
              clipRule='evenodd'
            ></path>
          </svg>
          <span className='sr-only'>{t('mortgage.info')}</span>
          <div>
            <span className='font-medium'>{t('mortgage.congrats')}</span>{' '}
            {t('mortgage.withThese')}
          </div>
        </div>
      );
    }
  };

  return (
    <div className='min-h-screen font-press-start'>
      <div className='bg-white pt-32 sm:px-12 px-10'>
        <h1 className='sm:text-[30px] font-semibold sm:font-medium text-xl font-press-start'>
          {t('mortgage.calculate')}
        </h1>
        <hr className='border-red-600 sm:w-1/2 w-full mt-2'/>
      </div>
      <div className='sm:grid sm:grid-cols-2 block bg-white sm:gap-10 sm:px-10 min-h-screen '>
        <div id='error' className='sm:px-10 px-0 sm:pt-[80px] '>
          <div className='block pt-8 px-10 sm:px-0 py-10 sm:py-0'>
            <label className='pr-2 text-gray-500 '>
              {t('mortgage.purchase')}
            </label>
            {
              propertyDetails?.current_bid ? (
                <input
                  name='totalPrice'
                  disabled={true}
                  type='text'
                  value={prevPropertyPrice}
                  className=' py-2 mb-5 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer required'/>
              ) : (
                <input
                  name='totalPrice'
                  type='text'
                  value={prevTotalPrice}
                  onChange={handleFormattedInput}
                  className=' py-2 mb-5 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer required'
                />
              )
            }
            <div>
              <div className='flex justify-between'>
                <label className='pr-2  text-gray-500'>
                  {t('mortgage.ownFunds')}
                </label>
                <button className='button'>
                  <span className='icon'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-9 w-9 text-red-500 ml-1'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                      strokeWidth='2'
                      fill='transparent'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                      />
                    </svg>
                  </span>
                  <span className='text'>
                    {t('mortgage.ownFunds1')} <br/> {t('mortgage.ownFunds2')}
                  </span>
                </button>
              </div>
              <input
                name='minimumOwnFunds'
                type='text'
                // min={limitOwn}
                value={prevOwnFunds}
                placeholder={`${t('mortgage.minimumOwnFunds')} ${prevLimitOwn}`}
                onChange={handleFormattedInput}
                className=' py-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer required'
              />
            </div>
            <div className='mt-4 sm:mt-0'>
              <label className='text-gray-500'>{t('mortgage.anual')}</label>
              <input
                name='annualIncome'
                type='text'
                value={prevAnnualIncome}
                onChange={handleFormattedInput}
                className=' py-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer required'
              />
            </div>
            <label className='pr-2 text-gray-500 '>
              {t('mortgage.duration')}
            </label>
            <select
              value={duration}
              onChange={handleDurationChange}
              className='py-2 mb-5 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer required'
            >
              {Array.from({ length: 14 }, (_, i) => i + 2).map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
            {duration.length !== 0 && (
              <p>
                {t('mortgage.marketInterest')} {interes}
              </p>
            )}
            <div className='text-center pt-6'>
              <button
                className='bg-red-500 hover:bg-gray-600 px-14 py-2 rounded-md text-white'
                onClick={() => setShowCalculator(true)}
              >
                {t('mortgage.buttonCalculate')}
              </button>
            </div>
          </div>
        </div>
        <div className='sm:pt-[80px] px-5 sm:px-0 font-press-start'>
          {annualIncome.length !== 0 && <ResultAfford/>}
          {showCalculator && (
            <div>
              <div className='relative pt-5 pb-2'>
                <div className='overflow-hidden h-5 mb-4 text-xs flex rounded bg-[#ffb3bf]'>
                  <div
                    style={{ width: `${firstMortgage}%` }}
                    className='shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-[#ff4d6b] border-r-2 border-r-white'
                  ></div>
                  <div
                    style={{ width: `${secondMortgage}%` }}
                    className='shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-[#cc0022] border-r-white border-r-2'
                  ></div>
                  <div
                    style={{ width: `${ownFunds}%` }}
                    className='shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-[#99001a] border-r-2 border-r-white'
                  ></div>
                  <div
                    style={{ width: `${interestCosts}%` }}
                    className='shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-[#801123] border-r-2 border-r-white'
                  ></div>
                  <div
                    style={{ width: `${ancillaryCosts}%` }}
                    className='shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-[#4d000d] border-r-2 border-r-white'
                  ></div>
                </div>
              </div>
              <div className='flex justify-between font-medium pb-1'>
                <div className='flex items-center space-x-3'>
                  <div className='w-4 h-4 bg-[#ff4d6b]'/>
                  <span className='text-md font-press-start font-medium'>
                    {t('mortgage.firstMortgage')}
                  </span>
                </div>
                <p>CHF {prevFirstMortgage}</p>
              </div>
              <div className='flex justify-between font-medium pb-1'>
                <div className='flex items-center space-x-3'>
                  <div className='w-4 h-4 bg-[#cc0022]'/>
                  <span className='text-md font-press-start font-medium'>
                    {t('mortgage.secondMortgage')}
                  </span>
                </div>
                <p>CHF {prevSecondMortgage}</p>
              </div>
              <div className='flex justify-between font-medium pb-1'>
                <div className='flex items-center space-x-3'>
                  <div className='w-4 h-4 bg-[#99001a]'/>
                  <span className='text-md font-press-start font-medium'>
                    {t('mortgage.ownFunds')}
                  </span>
                </div>
                <p>CHF {prevOwnFunds}</p>
              </div>
              <div className='flex justify-between font-medium pb-1'>
                <div className='flex items-center space-x-3'>
                  <div className='w-4 h-4 bg-[#801123]'/>
                  <span className='text-md font-press-start font-medium'>
                    {t('mortgage.interestCosts')}
                  </span>
                </div>
                <p>CHF {prevInterestCosts}</p>
              </div>
              <div className='flex justify-between font-medium pb-1'>
                <div className='flex items-center space-x-3'>
                  <div className='w-4 h-4 bg-[#4d000d]'/>
                  <span className='text-md font-press-start font-medium'>
                    {t('mortgage.averageAncillary')}
                  </span>
                </div>
                <p>CHF {prevAncillaryCosts}</p>
              </div>
            </div>
          )}

          <div className='bg-gray-100 rounded-md px-7 py-5  mt-7 font-press-start'>
            <p className='font-secondfont text-base font-semibold'>
              {t('mortgage.monthlyTotal')}
            </p>
            <div className='flex justify-between font-press-start pt-3 text-sm font-normal space-y-2'>
              <p>{t('mortgage.monthlyMortgage')}</p>
              <p>CHF {prevMonthlyMortgage}-.-</p>
            </div>
            <div className='flex justify-between font-press-start font-normal text-sm'>
              <p>{t('mortgage.maintenance')}</p>
              <p>CHF {prevMonthlyMaintenance} -.-</p>
            </div>
            <hr className='border-red-500 mt-2 mb-2'/>
            <div className='flex justify-between pt-2 text-base font-press-start font-semibold'>
              <p>{t('mortgage.yourMonthly')}</p>
              <p>CHF {prevMonthlyTotal}-.-</p>
            </div>
          </div>
          {propertyDetails && (
            <MortgagePropertyCard image={propertyDetails?.property.header_pic} title={propertyDetails?.property.title} size_rooms={propertyDetails?.property.size_rooms} living_space={propertyDetails?.property.living_space} address={propertyDetails?.property.address.formatted} id={propertyDetails?.id}/>
          )}
        </div>
      </div>
    </div>
  );
};

export { Mortgage };
