import React, { useState } from 'react';
import { utils } from '../../helpers/utils';
import { propertyServices } from '../../services/propertyServices';
import { BuyNowDialog } from './BuyNowDialog';
import { t } from 'i18next';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface Props {
  propertyDetails: any;
  user: any;
}

const BuyNow = (props: Props) => {
  const { propertyDetails, user } = props;
  const [openBuyNow, setOpenBuyNow] = useState(false);
  const handleOpenBuyNow = () => {
    setOpenBuyNow(!openBuyNow);
  };
  const [form, setForm] = useState(0);
  const [buttonLoader,setButtonLoader] = useState(false);
  const [inputData, setInputData] = useState({
    property_on_auction_id: propertyDetails.id,
    price: propertyDetails?.buy_now_price,
    buy_now: true,
    code: '',
  });

  const handleBuyNowRequest = () => {
    // if (inputData.code.length == 6) {
    setButtonLoader(true);
    propertyServices
      .requestBuyNow(inputData)
      .then((response: any) => {
        setButtonLoader(false);
        setInputData(response.data);
        setForm((form: any) => form + 1);
      })
      .catch((error: any) => {
        setButtonLoader(false);
        if (error.response.data.msg =='auction_locked') {
          // console.log('auction_locked', 'Auction is locked, somebody making a bid');
          toast.error(t(`errors.${error.response.data.msg}`), {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          setForm(100);
        }
        if (error.response.data.msg =='auction_time_passed') {
          // console.log('auction_time_passed', 'The time for the auction has passed');
          toast.error(t(`errors.${error.response.data.msg}`), {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          setForm(100);
        }
        if (error.response.data.msg != 'auction_locked' && error.response.data.msg != 'auction_time_passed') {
          setForm(100);
        }
      });
    // }
  };
  const confirmBuyNowRequest = () => {
    if (inputData.code.length == 6) {
      setButtonLoader(true);
      propertyServices
        .confirmBuyNow(inputData)
        .then((response: any) => {
          setButtonLoader(false);
          setInputData(response.data);
          setForm((form: any) => form + 1);
        })
        .catch((error: any) => {
          setButtonLoader(false);
          if (error.response.data.msg == 'failed_verification') {
            // console.log('failed_verification', 'The code you entered is incorrect');
            // Add toast
            toast.error(t(`errors.${error.response.data.msg}`), {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          }
          if (error.response.data.msg != 'failed_verification') {
            // console.log(error.response.data.msg);
            setForm(100);
          }
        });
    }
  };
  const reloadPage = () =>{
    location.reload();
  };
  return (
    <div className='border border-[#d2cccc] rounded-lg bg-[#e0e0e0] mx-3 my-4 sm:mx-0 sm:my-0'>
      <div className='border-b border-[#d2cccc] py-3 font-bold text-[18px] text-center'>
        {t('propertyDetails.buyNowPrice')} : CHF {utils.numberWithComma(propertyDetails?.buy_now_price)}
      </div>
      <div className='flex justify-center self-center my-4'>
        <button
          className='bg-darkGray px-10 py-2 text-white flex text-center rounded-md'
          onClick={handleOpenBuyNow}
          disabled={user.type== 'individual' && (!user.email_confirmed || !user.phone_confirmed || !user.id_card_confirmed) || user.type === 'company' && (!user.email_confirmed || !user?.phone_confirmed || !user.address_confirmed)}
        >
          {t('propertyDetails.buyNow')}
        </button>
      </div>
      {openBuyNow ? (
        <BuyNowDialog
          form={form}
          setForm={setForm}
          handleOpenBuyNow={handleOpenBuyNow}
          propertyDetails={propertyDetails}
          inputData={inputData}
          setInputData={setInputData}
          handleBuyNowRequest={handleBuyNowRequest}
          confirmBuyNowRequest={confirmBuyNowRequest}
          reloadPage={reloadPage}
          buttonLoader={buttonLoader}
        />
      ) : null}
    </div>
  );
};

export { BuyNow };
