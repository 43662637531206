import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { utils } from '../../../helpers/utils';
import { SilverCard } from './SilverCard';
import { GoldCard } from './GoldCard';
import { PlatinCard } from './PlatinCard';

interface Props {
    onNext: () => void;
    onBack: () => void;
    reAdvertiseInput: any;
    propertyData: any;
  }

const AdvertisingDetailsStep: React.FC<Props>  = ({ onNext, onBack, reAdvertiseInput, propertyData }) => {
  const { t } = useTranslation();
  console.log(propertyData?.payment?.plan.tier);
  return (
    <div>
      <div
        className='ml-4 h-[100%] sm:ml-[10%] mr-4 sm:mr-[10%] lg:ml-[15%] lg:mr-[15%] mt-10 lg:mt-[35px] mb-4 overflow-hidden'
      >
        <div className='space-y-4 border-b-2 border-white py-5 bg-gray-100 p-5 rounded-lg'>
          <p className='text-[25px] font-semibold'>{t('dashboard.listingSection.reAdvertise.adInfo')}</p>
          <div className='grid grid-cols-2'>
            <div className='block space-y-4'>
              <div className='flex space-x-1'>
                <p className=''>{t('dashboard.listingSection.reAdvertise.paymentType')}:</p>
                <p className='font-semibold'>{t(`listingItem.${reAdvertiseInput.type}`)}</p>
              </div>
              <div className='flex space-x-1'>
                <p className=''>{t('dashboard.listingSection.reAdvertise.tierType')}:</p>
                <p className='capitalize font-semibold'>{propertyData?.payment?.plan.tier}</p>
              </div>
              <div className='flex space-x-1'>
                <p className=''>{t('dashboard.listingSection.reAdvertise.duration')}:</p>
                <p className='uppercase font-semibold'>{reAdvertiseInput?.duration} {' '}<span className=''>{t('dashboard.listingSection.reAdvertise.days')}</span></p>
              </div>
              <div className='flex space-x-1'>
                <p className=''>{t('dashboard.listingSection.reAdvertise.price')}:</p>
                <p className='uppercase font-semibold'>CHF {reAdvertiseInput?.payment?.price}.00</p>
              </div>
              <div className='flex space-x-1'>
                <p className=''>{t('dashboard.listingSection.reAdvertise.startDate')}:</p>
                <p className='uppercase font-semibold'>{utils.formatDate(reAdvertiseInput.start_date, t)}</p>
              </div>
            </div>
            <div>
              { propertyData?.payment?.plan.tier ==='silver' ?
                <PlatinCard /> :
                propertyData?.payment?.plan.tier === 'gold' ?
                  <GoldCard /> :
                  <PlatinCard />
              }
            </div>
          </div>
        </div>
      </div>
      <div className='h-full grid items-end'>
        <div className="flex justify-between mt-4 px-6 items-end">
          <button className="px-4 py-2 bg-darkGray text-white rounded" onClick={onBack}>
            {t('dashboard.listingSection.reAdvertise.back')}
          </button>
          <button className="px-4 py-2 bg-darkGray text-white rounded" onClick={onNext}>
            {t('dashboard.listingSection.reAdvertise.next')}
          </button>
        </div>
      </div>
    </div>
  );
};

export { AdvertisingDetailsStep };
