import React from 'react';
import './Error.css';
import Error1 from '../../images/error1.png';
import Error2 from '../../images/error2.png';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Error404() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  window.scrollTo(0, 0);
  return (
    <div className='bg-white py-28 sm:flex sm:justify-between sm:px-56 sm:py-56 md:block lg:block xl:flex min-h-screen '>
      {/* Text */}
      <div id='error' className='px-10 sm:px-0 self-center'>
        <h1 className='text-[58px] font-braga'>Error 404</h1>
        <hr className='sm:mr-48 border-red-600' />
        <p className=' pt-5 font-press-start'>{t('error.errorMessage')}</p>
        <div className='pt-32'>
          <button onClick={() => navigate('/')} className=''>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 512 512'
              fill='black'
              className='w-6 h-6 absolute'
            >
              <path d='M9.375 233.4l128-128c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L109.3 224H480c17.69 0 32 14.31 32 32s-14.31 32-32 32H109.3l73.38 73.38c12.5 12.5 12.5 32.75 0 45.25c-12.49 12.49-32.74 12.51-45.25 0l-128-128C-3.125 266.1-3.125 245.9 9.375 233.4z' />
            </svg>
            <p className='pl-8 font-press-start'>{t('error.returnMessage')}</p>
            {/* <hr className='border-red-600 w-56 mt-2' /> */}
          </button>
        </div>
      </div>

      {/* Image */}
      <div className='sm:px-0 py-8 self-center'>
        <img src={Error1} alt='' className='absolute' />
        <img src={Error2} alt='' className='relative pl-12 py-10' />
      </div>
    </div>
  );
}
export { Error404 };
